import { Checkbox, CircularProgress, Flex, Link, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { Container, HeaderContainer } from './styles'
import { LayoutFull } from '../../Layouts/LayoutFull'
import { Button } from '../../../components/Button'

interface iAberturaPesquisa {
  id: string
  nomePesquisa: string
  msgAbertura?: string
  dataInicio?: string
  dataFim?: string
  status: number
  logoEmpresa: string
  traducoes?: iTraducao[]
  modoPesquisa?: number
  avaliacaoId?: string
}

interface iTraducao {
  idioma: string
  texto: string
}

export const AberturaPesquisa: React.FC = () => {
  const param = useParams<{ tokenId: string }>()
  const nav = useNavigate()
  const [idioma, setIdoma] = useState<string>('pt-BR')
  const [labelBtn, setLabelBtn] = useState<string>('Responder pesquisa')
  const [isAccepted, setIsAccepted] = useState<Boolean>(false)
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [Pesquisa, setPesquisa] = useState<iAberturaPesquisa>({
    id: '',
    nomePesquisa: '',
    dataInicio: '',
    dataFim: '',
    msgAbertura: '',
    status: 0,
    logoEmpresa: ''
  })

  function obterTraduzido(ptBR: string, enUs: string, esES: string): string {
    return idioma === 'pt-BR'
      ? ptBR
      : idioma === 'en-US'
        ? enUs
        : esES
  }

  function getPesquisa(): void {
    setIsLoading(true)
    appApi.get(`TokenPesquisa/Abertura/${param.tokenId ?? ''}`)
      .then(res => {
        setPesquisa(res.data)
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setPesquisa({ ...Pesquisa, status: -1 })
        setIsLoading(false)
      })
  }

  function ObterData(data: string): string {
    data = data.replace('às', '-')
    if (idioma === 'en-US') {
      const [dia, mes, ano] = data.split('/')
      return `${mes}/${dia}/${ano}`
    }
    return data
  }

  useEffect(() => {
    getPesquisa()
  }, [])

  return (
    <LayoutFull>
      {isLoading && (
        <HeaderContainer>
          <h4>
            <br />
            <CircularProgress m={4} isIndeterminate color='green.300' />
            Carregando sua pesquisa
          </h4>

          <img alt='Logo da empresa' src={Pesquisa.logoEmpresa ?? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'} />
        </HeaderContainer>)}

      {!isLoading && (
        <HeaderContainer>
          <h1>{Pesquisa.nomePesquisa}</h1>
          <img alt='Logo da empresa' src={Pesquisa.logoEmpresa ?? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'} />
        </HeaderContainer>)}
      {!isLoading && (
        <Container>
          {Pesquisa.traducoes && (
            <Flex direction={'column'}>
              <h4>{obterTraduzido('Selecione o idioma da pesquisa (Other languages, otros idiomas) ', 'Select survey language (Outros idiomas, otros idiomas)', 'Seleccione el idioma de encusta (Other languages, outros idiomas)')}</h4>
              <Select mb={'1.8rem'} onChange={(e) => {
                setIdoma(e.target.value)
                setLabelBtn(Pesquisa.traducoes?.find(i => i.idioma === e.target.value)?.texto ?? 'Responder a pesquisa em português')
              }}
                value={idioma} >
                <option value={'pt-BR'} >Responder a pesquisa em português (pt-BR)</option>
                {Pesquisa.traducoes.map(p => (
                  <option value={p.idioma}>{p.texto} ({p.idioma})</option>
                )
                )}
              </Select>
            </Flex>

          )}

          <div>
            {Pesquisa.status === -1 && (
              <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                <h1>{obterTraduzido('Esta pesquisa não está mais disponível', 'This survey is no longer available', 'Esta encuesta ya no está disponible')}</h1>
              </Flex>
            )}

            {Pesquisa.status === 0 && (
              <>
                <main>
                  <section>
                    <span dangerouslySetInnerHTML={{ __html: Pesquisa.msgAbertura?.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">') ?? '' }}></span>
                    <br />
                    <br />
                  </section>
                  <p>
                    {obterTraduzido(' Essa pesquisa estará disponível de',
                      'This survey will be available from',
                      'Esta encuesta estará disponible desde')} <strong>{ObterData(Pesquisa.dataInicio ?? '')}
                      {obterTraduzido(' até ', ' to ', ' hasta ')} {ObterData(Pesquisa.dataFim ?? '')}.</strong>
                  </p>
                  <br />
                  <p>{obterTraduzido('Para iniciar o preenchimento da pesquisa, clique no botão abaixo',
                    'To start answering the survey, click the button below',
                    'Para comenzar a completar la encuesta, haga clic en el botón de abajo')}

                  </p>
                  <br />
                  <p>{obterTraduzido('Ao clicar, você estará concordando com nossa política de privacidade, disponível nesse',
                    'By clicking, you are agreeing to our privacy policy, available at this',
                    'Al hacer clic, aceptas nuestra política de privacidad, disponible en este')}
                    &nbsp;link:&nbsp;
                    <Link
                      href="https://sah.arquiteturahumana.com.br/Privacidade"
                      isExternal
                      fontWeight={'bold'}
                      textDecoration={'underline'}
                    >
                      {obterTraduzido(
                        ' Política de privacidade.',
                        ' Privacy Policy.',
                        ' Política de privacidad.')}
                    </Link>

                  </p>
                  <br />
                  <Checkbox
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setIsAccepted(e.target.checked)}
                  >{obterTraduzido(
                    'Aceito os termos e política de privacidade',
                    'I accept the terms and privacy policy',
                    'Acepto los términos y política de privacidad')}</Checkbox>
                </main>
                <Flex marginTop={'1rem'} direction={'column'} gap='2rem'>

                  <h5>{obterTraduzido(
                    'Você precisa aceitar os termos e política de privacidade para continuar.',
                    'You must accept the terms and privacy policy, before continue survey.',
                    'Debe aceptar los términos y la política de privacidad para continuar.')}</h5>

                  {isAccepted && (
                    <Button
                      VarColor='v2'
                      onClick={() => nav({
                        pathname: Pesquisa.modoPesquisa !== 1
                          ? '/RespostaPesquisa'
                          : '/Rh/PesquisaPercepcao/PesquisaIndicativa/RespostaPesquisa'
                      },
                      { state: { tokenId: param.tokenId, idioma: idioma } })}
                    >{labelBtn}</Button>
                  )}

                  {!isAccepted && (
                    <Button
                      VarColor='v2'
                      isDisabled
                    >{labelBtn}</Button>
                  )}
                </Flex>
              </>
            )}

            {(Pesquisa.status === 1) && (/// Não iniciada
              <main>
                <section>
                  <span dangerouslySetInnerHTML={{ __html: Pesquisa.msgAbertura?.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">') ?? '' }} ></span>
                  <br />
                  <br />
                </section>
                <p>
                  {obterTraduzido(
                    'Essa pesquisa estará disponível em',
                    'This survey will be available at',
                    'Esta encuesta estará disponible en')} <strong>{ObterData(Pesquisa.dataInicio ?? '')}
                    {obterTraduzido(' e se encerrará em', 'and will close in', 'y se cerrará en')} {ObterData(Pesquisa.dataFim ?? '')}.</strong>
                </p>
                <p>{obterTraduzido('A partir da data de início, você poderá responder neste mesmo link.',
                  'From the start date, you will be able to respond through this same link.',
                  'A partir de la fecha de inicio, podrá responder a través de este mismo enlace.')}

                </p>
                <p>{obterTraduzido('Te vejo em breve', 'hasta luego', 'see you later')}</p>
              </main>
            )}

            {Pesquisa.status === 2 && (
              <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                <h1>{obterTraduzido('Pesquisa já encerrada', 'Survey already closed', 'Encuesta ya cerrada')}</h1>
              </Flex>
            )}

            {Pesquisa.status === 3 && (
              <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                <h2>Você já respondeu essa pesquisa!</h2>
                <h3>Estamos aguardando os demais participantes responderem!</h3>

                {Pesquisa.traducoes && (

                  <>
                    <br />
                    <br />    <br />
                    <br />
                    <h2>You already answered this survey!</h2>
                    <h3>  We are waiting for the other participants to respond!</h3>
                    <br />
                    <br />
                    <h2>¡Ya respondiste esta encuesta!</h2>
                    <h3>¡Estamos esperando a que los demás participantes respondan!</h3>
                    <br />
                  </>
                )}
              </Flex>
            )}
          </div>
        </Container>)}
    </LayoutFull>
  )
}
