import { Flex, Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaCog, FaEye, FaPaperPlane, FaPlus, FaSave, FaSearch } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iGestaoConvite } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { BoxCount } from './Components/BoxCount'
import { CircleText } from './Components/CircleText'
import { ModalConviteEmail } from './Components/ModalConviteEmail'
import { ModalConviteUsuario } from './Components/ModalConviteUsuario'
import { ModalLinkAberto } from './Components/ModalLinkAberto'
import { TableConvites } from './Components/Tabela'

type iParamProps = {
  pesquisaId: string
}

interface iAberturaPesquisa {
  dataInicio?: string
  hInicio?: string
}

export const ConvitesPesquisaInterna: React.FC = () => {
  const param = useParams<iParamProps>()
  const nav = useNavigate()
  const toast = useToast()
  const { pathname } = useLocation()

  const [ModalUsuarioIsOpen, setModalUsuarioIsOpen] = useState(false)
  const [ModalEmailIsOpen, setModalEmailIsOpen] = useState(false)
  const [ModalLinkAbertoIsOpen, setModalLinkAbertoIsOpen] = useState(false)

  const [Model, setModel] = useState<iGestaoConvite>()
  const [TxtPesquisa, setTxtPesquisa] = useState('')
  const [PesquisaNaoIniciada, setPesquisaNaoIniciada] = useState<boolean>(false)

  function getConvites(): void {
    appApi.get(`PesquisaInternaConvite/${param.pesquisaId as string}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function EnviarConvite(id: string): void {
    appApi.post(`PesquisaInternaConvite/${param.pesquisaId as string}/Enviar`, [id])
      .then(() => {
        getConvites()
        if (Model?.convites.find(e => e.id === id)?.status !== 'Enviado') {
          toast({
            title: 'Convite enviado com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
      })
      .catch(err => console.log(err))
  }

  function EnviarConvitePorStatus(query: string): void {
    appApi.post(`PesquisaInternaConvite/${param.pesquisaId as string}/status?status=${query}`)
      .then(() => {
        toast({
          title: 'Convites enviados com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getConvites()
      })
      .catch(err => console.log(err))
  }

  function getPesquisa(): void {
    appApi.get(`PesquisaInterna/${param.pesquisaId as string}/Configuracao`)
      .then((res: AxiosResponse<iAberturaPesquisa>) => {
        const DataString = `${res?.data?.dataInicio ?? ''} ${res?.data?.hInicio ?? ''}`
        setPesquisaNaoIniciada(new Date(Date.parse(DataString)).getTime() > new Date().getTime())
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getConvites()
    getPesquisa()
  }, [])

  return (
        <Body>
            {
                (Model) && (
                    <>
                        <ModalConviteUsuario
                            isOpen={ModalUsuarioIsOpen}
                            rows={Model.convites.filter(e => e.colaborador)}
                            paramId={param.pesquisaId as string}
                            onClose={() => setModalUsuarioIsOpen(false)}
                            Reload={getConvites}
                        />
                        <ModalConviteEmail
                            isOpen={ModalEmailIsOpen}
                            rows={Model.convites.filter(e => !e.colaborador)}
                            paramId={param.pesquisaId as string}
                            onClose={() => setModalEmailIsOpen(false)}
                            Reload={getConvites}
                        />
                    </>
                )
            }

            <ModalLinkAberto
                pesquisaId={param.pesquisaId as string}
                isOpen={ModalLinkAbertoIsOpen}
                onClose={() => setModalLinkAbertoIsOpen(false)}
            />

            <WhiteBox>
                <h1>{Model?.titulo}</h1>
                <Flex flexDirection={'column'} border={'1.10px solid var(--Azul)'} marginTop={'2rem'} borderRadius={'24px'} padding={'1rem'} >
                    <Flex justifyContent={'space-between'} >
                        <h3 style={{ color: 'var(--a4)' }}>Resumo convites</h3>
                        {
                            (Model) && (
                                <Flex gap={'0rem 2rem'} justifyContent={'end'} flexDir={'row'}>
                                    <CircleText
                                        titulo={Model.linkAberto + Model.convites.filter(e => e.status === 'Respondido').length}
                                        fontsize='16px'
                                        varColorCircle='v3'
                                        varColorText='Preto'
                                    >Respostas</CircleText>

                                    <CircleText
                                        titulo={Model.convites.filter(e => e.status === 'Enviado').length}
                                        fontsize='16px'
                                        varColorCircle='Rosa'
                                        varColorText='Preto'
                                    >Convites enviados</CircleText>

                                    <CircleText
                                        titulo={Model.convites.filter(e => e.status === 'Não enviado').length}
                                        fontsize='16px'
                                        varColorCircle='c5'
                                        varColorText='Preto'
                                    >Novos convites a enviar</CircleText>

                                    <CircleText
                                        titulo={Model.convites.filter(e => e.status === 'Erro').length}
                                        fontsize='16px'
                                        varColorCircle='secundario5'
                                        varColorText='Preto'
                                    >Convites com erro</CircleText>
                                </Flex>
                            )
                        }
                    </Flex>

                    {
                        (Model) && (
                            <Flex marginTop={'1rem'} gap={'0rem 2rem'}>
                                <BoxCount
                                    convites={Model?.convites.filter(e => e.colaborador)}
                                    tipo='usuario'
                                >
                                    <Button
                                        size={'md'}
                                        VarColor={'Green2'}
                                        leftIcon={<FaPlus />}
                                        onClick={() => setModalUsuarioIsOpen(true)}
                                    >Adicionar convite</Button>
                                </BoxCount>

                                <BoxCount
                                    convites={Model?.convites.filter(e => !e.colaborador)}
                                    tipo='email'
                                >
                                    <Button
                                        size={'md'}
                                        VarColor={'Green2'}
                                        leftIcon={<FaPlus />}
                                        onClick={() => setModalEmailIsOpen(true)}
                                    >Adicionar convite</Button>
                                </BoxCount>

                                <BoxCount
                                    convites={[]}
                                    linkAberto={Model.linkAberto}
                                    tipo='qrcode'
                                >
                                    <Button onClick={() => setModalLinkAbertoIsOpen(true)}
                                        size={'md'} VarColor={'Blue2'}
                                        leftIcon={<FaEye color='white' />}
                                    >Visualizar link</Button>
                                </BoxCount>
                            </Flex>
                        )
                    }
                </Flex>

                {
                    (Model && Model.convites?.length > 0) && (
                        <>
                            <Flex justifyContent={'space-between'} margin={'2rem 0rem'}>
                                <h3>Lista de convidados</h3>
                                <InputGroup width={'30%'}>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<FaSearch color='var(--Gray4)' />}
                                    />
                                    <Input
                                        onChange={(e) => setTxtPesquisa(e.target.value)}
                                        borderColor={'var(--Gray4)'}
                                        type='text'
                                        placeholder='Buscar participante'
                                    />
                                </InputGroup>
                            </Flex>
                            <TableConvites
                                rows={Model.convites.filter((e) => {
                                  if (TxtPesquisa === '') return e
                                  else if (e.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) return e
                                  return null
                                })}
                                onCheck={() => { }}
                                onSend={EnviarConvite}
                                anonima={Model.anonima}
                            />
                        </>
                    )
                }
                <Flex flexDirection={'row'} justifyContent={'end'} marginTop={'1rem'} gap='1rem' >
                    <Button onClick={() => nav(-1)} leftIcon={<FaAngleLeft />} size={'lg'} VarColor='c6'>Voltar</Button>
                    <Button leftIcon={<FaCog />} size={'lg'} VarColor='Green2'
                        onClick={() => nav(pathname.replace('Convites', 'Configuracao'))}>Configuração da pesquisa</Button>
                    <Button isDisabled={!Model?.convites.some(e => e.liberado)} leftIcon={<FaPaperPlane />} size={'lg'} VarColor='Green2' onClick={() => EnviarConvitePorStatus('1')}>Reenviar convites não respondidos</Button>
                    <Button isDisabled={!Model?.convites.some(r => r.status === 'Não enviado')}
                        leftIcon={PesquisaNaoIniciada && !Model?.convites.some(e => e.liberado) ? <FaSave /> : <FaPaperPlane />} size={'lg'} VarColor='Green2' onClick={() => EnviarConvitePorStatus('0')}>{PesquisaNaoIniciada && !Model?.convites.some(e => e.liberado) ? 'Salvar' : 'Disparar novos'}</Button>
                </Flex>
            </WhiteBox>
        </Body>
  )
}
