import styled from 'styled-components'

export const Container = styled.div`
display: flex;
background: var(--c2);
border-radius: 0.625rem;
box-shadow: var(--SombraBtns);
min-height: 6rem;
max-height: 15rem;
width: 100%;
`

export const RadiusAmarelo = styled.div`
 background:var(--terc5);
 width: 1rem;
 height: 100%;
 border-top-left-radius:0.625rem ;
 border-bottom-left-radius:0.625rem ;
 margin-right: .5rem;
`

export const ColumnText = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
span,h4{
    color: var(--a5);
}
`
