/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MetaButton } from '../../../../components/Buttons/MetaButton'
import { iItemIntegracao } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { Checklist } from '../../Components/Checklist'
import { PDFImersao } from '../../Components/Pdf'
import { TextoImersao } from '../../Components/Texto'
import { VideoImersao } from '../../Components/Video'

type ParamProps = {
  imersaoId: string
  itemId: string
}

export const PrevisualizarImersaoIndividual: React.FC = () => {
  const { itemId, imersaoId } = useParams<ParamProps>()

  const nav = useNavigate()

  const [Item, setItem] = useState<iItemIntegracao>({} as iItemIntegracao)

  function GetItemById(): void {
    appApi.get(`ImersaoExterna/${imersaoId as string}/Item/${itemId as string}`)
      .then(response => {
        setItem(response.data)
      }).catch(err => console.error(err))
  }

  useEffect(() => {
    GetItemById()
  }, [])

  return (
    <Body>
      {
        (Item.tipo === 0) && (
          <Checklist
              item={Item}
              isVisible
          />
        )
      }
      {
        (Item.tipo === 1) && (
          <VideoImersao
            item={Item}
            isVisible
          />
        )
      }
      {
        (Item.tipo === 2) && (
          <TextoImersao
            item={Item}
            isVisible
          />
        )
      }

      {
        (Item.tipo === 3) && (
          <PDFImersao
            uri={Item.arquivo?.uri}
            isVisible
          />
        )
      }
      <Flex marginTop={'2rem'} justifyContent={'center'}>
        <MetaButton
          onClick={() => nav(-1)}
          bg='v2'
          size='md'
        >Finalizar pré-visualização</MetaButton>
      </Flex>
    </Body>
  )
}
