import { Flex } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FaDoorOpen, FaUserPlus } from 'react-icons/fa'
import { IDepartment } from '../../../../../../../../interfaces'
import { parseJwt } from '../../../../../../../../services/token'
import { EmployeesOrgChart } from '../EmployeesOrgChart'

import { Container, Content } from './styles'

interface IDepartmentCardProps {
  department: IDepartment
  onOpenModalAddEmploye: (id: string) => void
}

export const DepartmentCard: React.FC<IDepartmentCardProps> = ({
  department,
  onOpenModalAddEmploye
}) => {
  const [isFolded, setIsFolded] = useState(true)

  const hasChild = useMemo(
    () =>
      department?.colaboradores?.find(
        (colaborador) => colaborador.subordinados?.length
      ),
    [department.colaboradores]
  )

  useEffect(() => {
    if (hasChild) {
      setIsFolded(false)
    } else {
      setIsFolded(true)
    }
  }, [hasChild])

  const handleFoldToggle = useCallback(() => {
    if (hasChild) {
      setIsFolded((prevState) => !prevState)
    }
  }, [hasChild])

  return (
    <Container>
      <Content isActive={!isFolded}>
        <Flex gap={'2rem'} justifyContent={'space-between'} alignItems={'center'} mb='1rem'>
          <Flex alignItems={'center'}>
            <Flex marginRight={'1rem'}>
              <FaDoorOpen size={'2rem'} color='white' onClick={handleFoldToggle} />
            </Flex>
            <span>{department.nome}</span>
          </Flex>
          {parseJwt().adm === 'True' &&
            <Flex>
              <FaUserPlus color='var(--Roxo)' cursor={'pointer'} onClick={() => onOpenModalAddEmploye(department.id)} size={'2rem'} />
            </Flex>
          }
        </Flex>

        {!!department?.colaboradores?.length && (
          <main>
            <EmployeesOrgChart
              isFolded={isFolded}
              employees={department.colaboradores}
            />
          </main>
        )}
      </Content>
    </Container>
  )
}
