import { Flex } from '@chakra-ui/react'
import { InputHTMLAttributes, useEffect, useState } from 'react'
import { iTag } from '../../interfaces'
import { appApi } from '../../services/appApi'
import { Tag } from '../Tag'
import { Container, FlexTags, InputTag, MenuDrop } from './styles'

interface ITagInput extends InputHTMLAttributes<HTMLInputElement> {
  isGrey?: boolean
  Selecionadas: string[]
  addTag: (tag: string) => void
  removeTag: (id: string) => void
  tipo: number
}

export const TagContainer: React.FC<ITagInput> = ({ isGrey, Selecionadas, tipo, addTag, removeTag, ...rest }) => {
  const [Txt, setTxt] = useState('')
  const [Tags, setTags] = useState<iTag[]>([])

  function getTags(create?: boolean): void {
    appApi.get(`Tag?tipo=${tipo}`)
      .then(res => {
        const data: iTag[] = res.data
        setTags(data)
        if (create) {
          const buscar = data.find(e => e.texto === Txt)
          if (buscar !== undefined) {
            addTag(buscar.id)
          }
        }
      })
      .catch(err => console.log(err))
  }

  function CriarTag(): void {
    appApi.post('Tag', { texto: Txt, tipo: tipo })
      .then(() => {
        getTags(true)
        setTxt('')
      }).catch(err => console.error(err))
  }

  useEffect(() => {
    getTags()
  }, [])

  return (
    <>
      {
        (Tags.filter(e => Selecionadas.find(r => r === e.id) !== undefined).length > 0) && (
          <FlexTags>
            {
              Tags.filter(e => Selecionadas.find(r => r === e.id) !== undefined).map((e: iTag, i) => {
                return (
                  <Tag hasX key={i} onDelete={() => removeTag(e.id)}>{e.texto}</Tag>
                )
              })
            }
          </FlexTags>

        )
      }
      <Container>
        <Flex gap={'1rem'}>
          <InputTag theme={isGrey ? 'c2' : 'c1'}>
            <input autoComplete='off' type={'text'} placeholder='Digite TAG' {...rest} onChange={e => setTxt(e.target.value)} />
            <button onClick={CriarTag}>+</button>
          </InputTag>
        </Flex>
        <Flex position={'relative'} width={'100%'}>
          <MenuDrop>
            {
              Tags.filter((e: iTag) => {
                if (Txt === '' || Selecionadas.find(r => r === e.id) !== undefined) {
                  return null
                } else if (e.texto.toLocaleLowerCase().includes(Txt.toLocaleLowerCase())) {
                  return e
                }
                return null
              }).slice(0, 5).map((e: iTag, i) => {
                return (
                  <li onClick={() => addTag(e.id)} key={i}>{e.texto}</li>
                )
              })
            }
          </MenuDrop>
        </Flex>
      </Container>
    </>
  )
}
