import { Flex, Text } from '@chakra-ui/react'
import { Button } from '../../../../../../components/Button'
import { ModalBase } from '../../../../../../components/ModalBase'

interface iModalComunicacao {
  isOpen: boolean
  onClose: () => void
}

export const ModalTag: React.FC<iModalComunicacao> = ({ isOpen, onClose }) => {
  return (
    <ModalBase Titulo='Como utilizar as TAGs' isOpen={isOpen} onClose={onClose} >
      <Flex maxWidth={'53.125rem'} borderTop={'1px solid var(--Gray4)'} borderBottom={'1px solid var(--Gray4)'} padding={'1rem'}>
        <Text>
          Para deixar seus e-mails com uma cara mais personalizada nós criamos algumas TAGs que podem lhe ajudar.
          <br />
          <br />
          Todos os e-mails devem conter a TAG <strong>[LINK]</strong> onde o respondente irá clicar para acessar a pesquisa, caso você não insira essa TAG o link será apresentado ao final do e-mail.
          <br />
          <br />
          Você também pode personalizar com o nome da pessoa que será avaliada com a TAG <strong>[NOME DO AVALIADO]</strong> ou ainda com o nome do próprio respondente que está recebendo o e-mail com a TAG <strong>[NOME DO RESPONDENTE]</strong>.
          <br />
          <br />
          Outra TAG que pode ser utilizadas é <strong>[DATA INICIO]</strong>, para a data do feedback.
          <br />
          <br />
          <strong>Não se esqueça que as TAGs devem ser sempre inseridas entre colchetes [ ], conforme estão escritas no texto acima.</strong>
          <br />
        </Text>
      </Flex>
      <Flex padding={'1rem'} justifyContent={'center'} alignItems={'center'}>
        <Button onClick={onClose} size={'lg'} VarColor='Azul'>Ok</Button>
      </Flex>
    </ModalBase>
  )
}
