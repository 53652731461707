import { Box, Divider, Flex, FormControl, FormLabel, Img, Input, Select, Text } from '@chakra-ui/react'
import { WhiteBox } from '../../../components/WhiteBox'
import { Body } from '../../Layouts/Body'
import { BoxColor, InfoPDI, MediaGeralContainer, OutlineColumn, OutlineContainer, StatusContainer, Titulo } from './styles'
import { Button } from '../../../components/Button'
import { FaChartBar, FaPeopleArrows, FaUserCheck, FaUsers } from 'react-icons/fa'
import { GaugeChart } from './Components/GaugeChart'
import LogoStake from '../../../assets/PesquisaStakeholders.svg'
import { useState, useEffect } from 'react'
import { appApi } from '../../../services/appApi'
import { iNomeId } from '../../../interfaces'
import { useNavigate } from 'react-router-dom'
import IconeOutros from '../../../assets/IconeOutros.svg'
import IconeStake from '../../../assets/IconeStake.svg'
import IconeLider from '../../../assets/IconeLider.svg'
import IconeCritico from '../../../assets/IconeCritico.svg'
import { ImgSah } from '../../../components/ImgSah'
import { parseJwt } from '../../../services/token'
import { PropInvalida } from '../../../Utils/Helper'
import { MediaAvaliacoesChart } from './Components/MediaAvaliacoesArea'
import { MediaAvaliacoesChartGestor } from './Components/MediaAvaliacoesGestor'

interface iStatusCount {
  totalElaboracao: number
  totalAndamento: number
  totalFinalizadas: number
}

interface iMediaCount {
  nome: string
  media: number
}

interface iModel {
  statusCount: iStatusCount
  totalPdiAndamento: number
  totalPdiAprovacao: number
  pesquisaSelecionada?: string
  pesquisas: iNomeId[]
}

interface iStatusCategoria {
  total: number
  totalRespondido: number
  nome: string
}

interface iCountGeral {
  totalAvaliacoes: number
  totalRespostas: number
  totalAvaliadores: number
  totalAdesao: number
}

interface iResumoResultado {
  id: string
  nomePesquisa: string
  statusCategorias: iStatusCategoria[]
  countGeral: iCountGeral
  mediaStakeholders: iMediaCount
  mediaAutoavaliacao: iMediaCount
  maiorMedia: iMediaCount
  menorMedia: iMediaCount
  departamentos: iNomeId[]
}

export interface iPropsGrafico {
  competencias: string[]
  dados: number[]
  grafico: number[]
  fator: number
  escala: string
  id: string
}
export interface iPropsGraficoGestor {
  competencias: string[]
  avatar: string[]
  gestoresId: string[]
  gestoresNome: string[]
  dados: number[]
  grafico: number[]
  fator: number
  escala: string
  id: string
}

export const DashboardDesempenho: React.FC = () => {
  const nav = useNavigate()

  const [Model, setModel] = useState<iModel>()
  const [Resumo, setResumo] = useState<iResumoResultado>()
  const [PesquisaId, setPesquisaId] = useState<string>('')
  const [GestorId, setGestorId] = useState<string>('')
  const [DepartamentoId, setDepartamentoId] = useState<string>('')
  const [IsLoading, setIsLoading] = useState(false)
  const [IsLoadingGestor, setIsLoadingGestor] = useState(false)
  const [DadosGrafico, setDadosGrafico] = useState<iPropsGrafico[]>()
  const [DadosGraficoGestor, setDadosGraficoGestor] = useState<iPropsGraficoGestor[]>()
  const [DataIncio, setDataIncio] = useState('')
  const [DataFim, setDataFim] = useState('')

  function getDash(): void {
    setIsLoading(true)
    appApi.get('PesquisaAnaliseDesempenho/Desempenho/Dashboard')
      .then(res => {
        setModel(res.data)
        if (res.data.pesquisaSelecionada) {
          getResumo(res.data.pesquisaSelecionada, '')
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function getResumo(pesqusaiId: string, departamentoId: string): void {
    setIsLoading(true)
    setPesquisaId(pesqusaiId)
    setDepartamentoId(departamentoId)
    setResumo(undefined)
    appApi.get(`AvaliacaoPesquisa/${pesqusaiId}/ResumoResultadoGeral?departamentoId=${departamentoId}`)
      .then(res => {
        setIsLoading(false)
        setResumo(res.data)
      })
      .catch(err => console.log(err))
  }

  function getIcon(nome: string): any {
    switch (nome) {
      case 'Autoavaliação': return <FaUserCheck size={28} style={{ marginRight: '.5rem' }} />
      case 'Pares': return <FaPeopleArrows size={28} style={{ marginRight: '.5rem' }} />
      case 'Lider': return <Img height={'2rem'} marginRight={'.5rem'} src={IconeLider} />
      case 'Liderados': return <FaUsers size={28} style={{ marginRight: '.5rem' }} />
      case 'Outros': return <img src={IconeOutros} />
      default: return <FaUserCheck size={28} style={{ marginRight: '.5rem' }} />
    }
  }

  function getIconAvaliados(nome: string): any {
    switch (nome) {
      case 'Autoavaliação': return <FaUserCheck size={60} />
      case 'Pares': return <FaPeopleArrows size={60} />
      case 'lider': return <Img height={'4rem'} marginRight={'.5rem'} src={IconeCritico} />
      case 'Liderados': return <FaUsers size={60} />
      case 'Outros': return <img src={IconeOutros} />
      default: return <FaUserCheck size={60} />
    }
  }

  function getDadosGrafico(dataInicio?: string, dataFim?: string): void {
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/ResultadoPorDepartamento?dataInicio=${dataInicio ?? ''}&dataFim=${dataFim ?? ''}&`)
      .then((res) => {
        setDadosGrafico(res.data)
        setIsLoading(false)
      }).catch(err => console.log(err))
  }
  function getDadosGraficoGestor(gestorId: string): void {
    setGestorId(gestorId)
    setIsLoadingGestor(true)
    appApi.get(`AvaliacaoPesquisa/ResultadoPorGestor?gestorId=${gestorId}`)
      .then((res) => {
        setDadosGraficoGestor(res.data)
        setIsLoadingGestor(false)
      }).catch(err => console.log(err))
  }

  function handleButtonClick(): void {
    getDadosGrafico(DataIncio, DataFim)
  }

  useEffect(() => {
    if (GestorId.length > 4) getDadosGraficoGestor(GestorId)
  }, [GestorId])

  useEffect(() => {
    setIsLoadingGestor(false)
  }, [DadosGraficoGestor])

  useEffect(() => {
    getDadosGrafico()
    getDadosGraficoGestor('00000000-0000-0000-0000-000000000000')
    getDash()
  }, [])

  return (
        <Body isLoading={IsLoading}>
            <WhiteBox>
                <Flex gap={'1rem'}>
                    <OutlineContainer theme={'Azul'}>
                        <Flex justifyContent={'center'}>
                            <StatusContainer>
                                <img src={LogoStake} />
                                <Flex marginLeft={'1rem'} flexDir={'column'}>
                                    {
                                        (Model) && (
                                            <h2>{(Model?.statusCount?.totalFinalizadas + Model?.statusCount?.totalAndamento)} Pesquisas por competência</h2>
                                        )
                                    }
                                    <strong>Desenvolvidas no último ano</strong>
                                    {parseJwt().adm === 'True' && <Button onClick={() => nav('/Rh/AnaliseDesempenho/Criar')} marginTop={'1rem'} VarColor='Azul'>Criar nova pesquisa</Button>}
                                </Flex>
                                <hr />
                                <Flex flexDir={'column'}>
                                    <strong>{Model?.statusCount?.totalElaboracao ?? 0} Em elaboração</strong>
                                    <strong>{Model?.statusCount?.totalAndamento ?? 0} Em andamento</strong>
                                    <strong>{Model?.statusCount?.totalFinalizadas ?? 0} Finalizadas</strong>
                                </Flex>
                            </StatusContainer>
                        </Flex>
                    </OutlineContainer>

                    <Flex gap={'1rem'}>
                        <OutlineContainer theme={'Azul'}>
                            <InfoPDI>
                                <h1>{Model?.totalPdiAprovacao}</h1>
                                <strong>PDI aguardando aprovação</strong>
                            </InfoPDI>
                        </OutlineContainer>

                        <OutlineContainer theme={'Azul'}>
                            <InfoPDI>
                                <h1>{Model?.totalPdiAndamento}</h1>
                                <strong>PDI em andamento</strong>
                            </InfoPDI>
                        </OutlineContainer>
                    </Flex>
                </Flex>
            </WhiteBox>

            {
                (Model?.pesquisas && Model?.pesquisas?.length > 0) && (
                    <WhiteBox margin='1rem 0 0 0'>
                        <Flex marginBottom={'1.5rem'} gap={'1rem'} width='70%'>
                            <FormControl>
                                <FormLabel fontSize={'xl'}>Pesquisa</FormLabel>
                                <Select onChange={(e) => getResumo(e.target.value, DepartamentoId)} value={PesquisaId} borderColor={'var(--Gray4)'}>
                                    {
                                        Model?.pesquisas?.map((e) => {
                                          return (
                                                <option key={e.id} value={e.id}>{e.nome}</option>
                                          )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl isDisabled={Resumo && Resumo.countGeral.totalRespostas === 0}>
                                <FormLabel fontSize={'xl'}>Área</FormLabel>
                                <Select onChange={(e) => getResumo(PesquisaId, e.target.value)} value={DepartamentoId} borderColor={'var(--Gray4)'}>
                                    <option value={''}>Todas</option>
                                    {
                                        Resumo?.departamentos?.map((e) => {
                                          return (
                                                <option key={e.id} value={e.id}>{e.nome}</option>
                                          )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Flex>

                        {
                            (Resumo) && (
                                <>
                                    <Flex marginBottom={'1rem'}>
                                        <h1>{Resumo.nomePesquisa}</h1>
                                        {parseJwt().adm === 'True' && <Button
                                            isDisabled={Resumo.countGeral.totalRespostas === 0}
                                            VarColor='Azul' onClick={() => nav(`/Rh/AnaliseDesempenho/ResultadoGeral/${Resumo.id}`)}
                                            size={'lg'} marginLeft={'1rem'}
                                            leftIcon={<FaChartBar />}
                                        >Visualizar resultados</Button>}
                                    </Flex>
                                    <Flex width={'100%'} gap='1rem' justifyContent={'space-between'} marginBottom={'1.5rem'}>
                                        <OutlineContainer theme={'Azul'}>
                                            <Flex height={'100%'} justifyContent={'center'}>
                                                <Flex alignItems={'center'}>
                                                    <OutlineColumn>
                                                        <h3>{Resumo.countGeral.totalAvaliacoes}</h3>
                                                        <h3>Avaliados</h3>
                                                    </OutlineColumn>

                                                    <OutlineColumn>
                                                        <h3>{Resumo.countGeral.totalAvaliadores}</h3>
                                                        <h3>Avaliadores</h3>
                                                    </OutlineColumn>

                                                    <OutlineColumn>
                                                        <h3>{Resumo.countGeral.totalRespostas}</h3>
                                                        <h3>Respostas</h3>
                                                    </OutlineColumn>

                                                </Flex>
                                                <Flex alignItems={'center'} width='15rem'>
                                                    <GaugeChart id='sa' val={Resumo.countGeral.totalAdesao} />
                                                </Flex>
                                            </Flex>
                                        </OutlineContainer>

                                        <OutlineContainer theme={'Azul'}>
                                            <h4 style={{ color: 'var(--a4)', marginBottom: '.5rem' }}>Respostas por categoria</h4>
                                            <Flex justifyContent={'center'} color={'var(--c7)'} flexWrap='wrap' gap={'1rem'}>
                                                {
                                                    Resumo?.statusCategorias.map((e) => {
                                                      return (
                                                            <Flex flexDir={'column'} >
                                                                <Flex>
                                                                    {getIcon(e.nome)}
                                                                    <strong>{e.totalRespondido}/{e.total}</strong>
                                                                </Flex>
                                                                <strong>{e.nome}</strong>
                                                            </Flex>
                                                      )
                                                    })
                                                }
                                            </Flex>

                                        </OutlineContainer>
                                    </Flex>
                                    {
                                        (Resumo.countGeral.totalRespostas > 0) && (
                                            <Flex gap={'1rem'}>
                                                <OutlineContainer theme={'Azul'}>
                                                    <Flex marginBottom={'1rem'} justifyContent={'center'}>
                                                        <strong>Média geral</strong>
                                                    </Flex>
                                                    <MediaGeralContainer>
                                                        <Flex>
                                                            <Flex alignItems={'center'} marginRight='1rem' flexDir={'column'}>
                                                                <img src={IconeStake} />
                                                                <span>Stakeholders</span>
                                                            </Flex>
                                                            <Flex flexDir={'column'}>
                                                                <h3>Nota média</h3>
                                                                <h2>{Resumo?.mediaStakeholders.media.toFixed(2)}</h2>
                                                            </Flex>
                                                        </Flex>
                                                        {
                                                            (!PropInvalida(Resumo?.mediaAutoavaliacao)) && (
                                                                <>
                                                                    <hr />
                                                                    <Flex>
                                                                        <Flex alignItems={'center'} marginRight='1rem' flexDir={'column'}>
                                                                            <FaUserCheck size={60} />
                                                                            <span>Autoavaliação</span>
                                                                        </Flex>
                                                                        <Flex flexDir={'column'}>
                                                                            <h3>Nota média</h3>
                                                                            <h2>{Resumo?.mediaAutoavaliacao.media.toFixed(2)}</h2>
                                                                        </Flex>
                                                                    </Flex>
                                                                </>
                                                            )
                                                        }
                                                    </MediaGeralContainer>
                                                </OutlineContainer>
                                                {
                                                    (Resumo.maiorMedia !== null || Resumo.menorMedia !== null) && (
                                                        <Flex gap={'1rem'}>
                                                            <OutlineContainer theme={'Azul'}>
                                                                <Flex width={'13rem'} marginBottom={'1rem'} justifyContent={'center'}>
                                                                    <strong>Grupo que melhor avaliou</strong>
                                                                </Flex>
                                                                <BoxColor theme={'Azul'}>
                                                                    <Flex marginRight={'1rem'} alignItems={'center'} flexDir={'column'}>
                                                                        {Resumo.maiorMedia !== null && getIconAvaliados(Resumo?.maiorMedia.nome)}
                                                                        <span>{Resumo?.maiorMedia?.nome}</span>
                                                                    </Flex>
                                                                    <Flex flexDir={'column'}>
                                                                        <span>Nota média</span>
                                                                        <h2>{Resumo?.maiorMedia?.media.toFixed(2)}</h2>
                                                                    </Flex>
                                                                </BoxColor>
                                                            </OutlineContainer>
                                                            <OutlineContainer theme={'Azul'}>
                                                                <Flex width={'13rem'} marginBottom={'1rem'} justifyContent={'center'}>
                                                                    <strong>Grupo mais crítico</strong>
                                                                </Flex>
                                                                <BoxColor theme={'terc3'}>
                                                                    <Flex marginRight={'1rem'} alignItems={'center'} flexDir={'column'}>
                                                                        {Resumo.menorMedia !== null && getIconAvaliados(Resumo?.menorMedia.nome)}
                                                                        <span>{Resumo?.menorMedia?.nome}</span>
                                                                    </Flex>
                                                                    <Flex flexDir={'column'}>
                                                                        <span>Nota média</span>
                                                                        <h2>{Resumo?.menorMedia?.media.toFixed(2)}</h2>
                                                                    </Flex>
                                                                </BoxColor>
                                                            </OutlineContainer>
                                                        </Flex>
                                                    )
                                                }

                                            </Flex>
                                        )
                                    }
                                </>
                            )
                        }

                    </WhiteBox>
                )
            }

            {Model?.pesquisas && Model?.pesquisas?.length > 0 && DadosGrafico !== undefined && (
                <>
                    <WhiteBox margin='1rem 0 0 0'>
                        <Titulo>Gráfico de desempenho por área</Titulo>
                        <Flex gap={'1rem'} marginBottom={'1rem'} alignItems={'center'}>
                            <Box>
                                <label>Data de início:</label>
                                <Input
                                    type="date"
                                    value={DataIncio}
                                    onChange={(event) => setDataIncio(event.target.value)}
                                />
                            </Box>

                            <Box>
                                <label>Data de término:</label>
                                <Input
                                    type="date"
                                    value={DataFim}
                                    min={DataIncio}
                                    onChange={(event) => setDataFim(event.target.value)}
                                />
                            </Box>
                            <Button mt={'1.4rem'} VarColor={'Green1'} isLoading={IsLoading} size='sm' onClick={handleButtonClick}>Confirmar</Button>
                        </Flex>

                        {DadosGrafico?.length === 0 && (
                            <Text fontWeight='bold' alignSelf={'center'} mt='1rem'>Não há resultados disponíveis</Text>
                        )}

                        {DadosGrafico?.map(e => {
                          return (
                                <>
                                    <Flex gap={'1rem'}>
                                        <MediaAvaliacoesChart informacoes={e} />
                                    </Flex>
                                    <Divider my={'2rem'} borderColor={'var(--c3)'} orientation='horizontal' />
                                </>
                          )
                        })}
                    </WhiteBox>

                </>
            )}
            {DadosGraficoGestor !== null && !IsLoadingGestor && (
                <>
                    <WhiteBox margin='1rem 0 0 0'>
                        <Titulo>Gráfico de desempenho por gestor</Titulo>
                        {DadosGraficoGestor?.map((e, i) => {
                          return (
                                <>
                                    <Flex gap={'1rem'} marginBottom={'1rem'} alignItems={'center'}>
                                        {(e.gestoresId.length > 1 && i === 0) &&
                                            <>
                                                <Text my={'0.2rem'}>Filtrar por gestor:</Text>
                                                <Select value={GestorId} onChange={(e) => setGestorId(e.target.value)}>
                                                    {e.gestoresId.map((a, i) => {
                                                      return (
                                                            <option value={a}>{e.gestoresNome[i]}</option>
                                                      )
                                                    })}
                                                </Select>
                                            </>
                                        }
                                    </Flex>
                                    <Flex gap={'1rem'}>
                                        <MediaAvaliacoesChartGestor informacoes={e} key={e.id}/>
                                    </Flex>
                                    <Divider my={'2rem'} borderColor={'var(--c3)'} orientation='horizontal' />
                                </>
                          )
                        })}
                    </WhiteBox>

                </>
            )}

            {
                (Model?.pesquisas && Model?.pesquisas?.length === 0 && !IsLoading) && (
                    <ImgSah entidade='pesquisa'>
                        {
                            (parseJwt().adm === 'True') && (
                                <Button
                                    VarColor='Azul'
                                    size={'lg'}
                                    onClick={() => nav('/Rh/AnaliseDesempenho/SelecionarModelo')}
                                >Criar nova pesquisa</Button>
                            )
                        }
                    </ImgSah>
                )
            }
        </Body>
  )
}
