import styled from 'styled-components'
import { Flex } from '../../../../../styles/styledGlobal'

export const Main = styled.div`
display: flex;
flex-direction: column;
max-width: 34.375rem;
h3{
    color: var(--a3);
}

span:last-child{
    color: var(--a3);
    font-family: 'Poppins','sans-serif';
}
`

export const Description = styled.span`
margin-left: 1rem;
`

export const WelcomeContainer = styled(Flex)`
align-items: center;
margin-bottom: 1rem;
`

export const VideoBox = styled.video`
min-width:110px;
max-width:110px;
height: 110px;
margin-right: 1rem;
background-repeat: no-repeat;
background-size: contain;
background-position: center;
border-radius: .5rem;
border: 1px solid var(--c5);
`
