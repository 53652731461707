/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Avatar, AvatarGroup, Box, Flex, FormLabel, Grid, GridItem, Image, Input, InputGroup, InputLeftElement, Select, Text, Tooltip, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEye, FaFileUpload, FaSave, FaSearch } from 'react-icons/fa'
import { MdGroups, MdOutlinePlayCircle, MdOutlineStopCircle } from 'react-icons/md'
import { Button } from '../../../components/Button'
import { WhiteBox } from '../../../components/WhiteBox'
import { Body } from '../../Layouts/Body'
import { EixoNineBox, EixoUnicoNineBox } from './styles'
import seta from './assets/seta.svg'
import azul from './assets/azul.svg'
import laranja from './assets/laranja.svg'
import verde from './assets/verde.svg'
import verdeClaro from './assets/verdeClaro.svg'
import vermelho from './assets/vermelho.svg'

import { useNavigate } from 'react-router-dom'
import { NavLine } from '../../../components/NavLine'
import { appApi } from '../../../services/appApi'
import { iNineBox, iNomeId, iQuadrante } from '../../../interfaces'
import { ObterCorQuadrantePorNome } from '../../../components/PerfilColaborador/indesx'
import { PropInvalida, useQuery } from '../../../Utils/Helper'
import { ModalComite } from './components/ModalComite'
import { PopUpAviso } from './components/PopUpAviso'
import { ModalCadastroEmMassa } from './components/ModalCadastroEmMassa'
import { parseJwt } from '../../../services/token'

interface iColaborador {
  id: string
  avatar: string
  nome: string
  departamentoId: string
  tags: string[]
  squads: string[]
}

export const NineBox: React.FC = () => {
  const isAdm = parseJwt().adm === 'True'
  const toast = useToast()
  const query = useQuery()
  const nav = useNavigate()

  const [QuadranteAtual, setQuadranteAtual] = useState<string>()
  const [Colaboradores, setColaboradores] = useState<iColaborador[]>([])

  const [QuadranteSelecionado, setQuadranteSelecionado] = useState<iQuadrante>()

  const [TextoPesquisa, setTextoPesquisa] = useState<string>('')
  const [Area, setArea] = useState<string>()
  const [Squad, setSquad] = useState<string>()
  const [Tag, setTag] = useState<string>()

  const [Tags, setTags] = useState<iNomeId[]>([])
  const [Squads, setSquads] = useState<iNomeId[]>([])
  const [Areas, setAreas] = useState<iNomeId[]>([])

  const [ModalComiteIsOpen, setModalComiteIsOpen] = useState(false)
  const [Model, setModel] = useState<iNineBox>({
    padrao: false,
    id: '',
    titulo: '',
    tags: [],
    squads: [],
    areas: [],
    tipoMeta: 1,
    configuracaoEixoX: {
      titulo: '',
      descricao: ''
    },
    configuracaoEixoY: {
      titulo: '',
      descricao: ''
    },
    eixosX: [],
    eixosY: [],
    quadrantes: []
  })

  const [Comite, setComite] = useState<string[]>()

  const [PopUpIsOpen, setPopUpIsOpen] = useState(false)
  const [ModalCsvIsOpen, setModalCsvIsOpen] = useState(false)

  function onDragDrop(id: string): void {
    const quadrantes = [...Model.quadrantes]
    const pos = quadrantes.findIndex(r => r.id === QuadranteAtual)
    if (pos === -1) return

    if (!quadrantes[pos].colaboradores?.some(t => t === id)) {
      if (quadrantes[pos].colaboradores) {
        quadrantes[pos].colaboradores?.push(id)
      } else {
        quadrantes[pos].colaboradores = [id]
      }
    } else {
      quadrantes[pos].colaboradores = quadrantes[pos].colaboradores?.filter(r => r !== id)
    }
    setModel({ ...Model, quadrantes: quadrantes })
    setQuadranteAtual(undefined)
  }

  function onZoneChange(id: string): void {
    const quadrantes = [...Model.quadrantes]
    const ondeEstapresente = quadrantes.filter(t => t.colaboradores?.some(w => w === id))
    ondeEstapresente.forEach((r) => {
      const posQuad = quadrantes.findIndex(t => t.id === r.id)
      if (posQuad !== -1) {
        quadrantes[posQuad].colaboradores = quadrantes[posQuad].colaboradores?.filter(q => q !== id)
      }
    })

    const pos = quadrantes.findIndex(r => r.id === QuadranteAtual)
    if (pos !== -1) {
      if (quadrantes[pos].colaboradores) {
        quadrantes[pos].colaboradores?.push(id)
      } else {
        quadrantes[pos].colaboradores = [id]
      }
    }
    setModel({ ...Model, quadrantes: quadrantes })
  }

  function ObterDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => setAreas(res.data))
      .catch(err => console.log(err))
  }

  function ObterSquads(): void {
    appApi.get('Tag/Select?tipo=2')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }

  function ObterTags(): void {
    appApi.get('Tag/Select?tipo=0')
      .then(res => setTags(res.data))
      .catch(err => console.log(err))
  }

  function ObterColaboradores(): void {
    appApi.get('Colaborador/Select/NineBox')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterNineBoxPadrao(): void {
    appApi.get('NineBox/Padrao')
      .then(res => setModel(res.data))
      .catch(({ response }) => {
        console.log(response.status)
        if (response.status === 404) {
          setPopUpIsOpen(true)
        }
      })
  }

  function SalvarTudo(quadranteId?: string): void {
    appApi.put('NineBox', Model)
      .then(() => {
        toast({
          title: '9Box geral atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        if (quadranteId) {
          nav(`?quadranteId=${quadranteId ?? ''}`)
        }
      }
      )
      .catch(err => console.log(err))
  }

  function ObterSvg(nome: string): any {
    switch (Model.quadrantes.findIndex(t => t.titulo === nome).toString()) {
      case '0': return azul
      case '1': return verdeClaro
      case '2': return verde
      case '3': return laranja
      case '4': return azul
      case '5': return verdeClaro
      case '6': return vermelho
      case '7': return laranja
      case '8': return azul
    }
  }

  function ObterComite(): void {
    appApi.get('Comite')
      .then(res => setComite(res.data))
      .catch(err => console.log(err))
  }

  function CriarComite(participantes: string[]): void {
    appApi.post('Comite', participantes)
      .then(() => {
        setComite(participantes)
        toast({
          title: 'Comitê criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalComiteIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  function AtualizarComite(participantes: string[]): void {
    appApi.put('Comite', participantes)
      .then(() => {
        setComite(participantes)
        toast({
          title: 'Comitê atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalComiteIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  function EncerrarComite(): void {
    appApi.patch('Comite/Encerrar')
      .then(() => {
        setComite(undefined)
        toast({
          title: 'Comitê encerrado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalComiteIsOpen(false)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ObterDepartamentos()
    ObterTags()
    ObterSquads()
    ObterColaboradores()
    ObterNineBoxPadrao()
    ObterComite()
  }, [])

  useEffect(() => {
    const val = query.get('quadranteId')
    if (val) {
      setQuadranteSelecionado(Model.quadrantes.find(r => r.id === val))
    } else {
      setQuadranteSelecionado(undefined)
    }
  }, [query])

  return (
    <Body>
      <ModalCadastroEmMassa
        reload={ObterNineBoxPadrao}
        isOpen={ModalCsvIsOpen}
        onRequestClose={() => setModalCsvIsOpen(false)}
      />

      {ModalComiteIsOpen &&
        <ModalComite
          onClose={() => setModalComiteIsOpen(false)}
          onCreate={CriarComite}
          participantes={Comite}
          onEncerrar={EncerrarComite}
          onUpdate={AtualizarComite}
        />}
      {PopUpIsOpen && <PopUpAviso />}
      <NavLine options={
        [
          { nome: 'Desempenho' },
          { nome: '9Box', path: '/Desempenho/9box' },
          QuadranteSelecionado ? { nome: 'Enigma' } : { nome: '' }
        ]} />
      <Flex mt={'1.5rem'} mb={'2.4rem'} justifyContent={'space-between'}>
        <Box as='h1' color={'var(--a4)'} flex={'1'}>9Box</Box>
        {Comite &&
          <Button
            VarColor='Azul'
            leftIcon={<FaFileUpload />}
            py={'0.75rem'}
            px={'2rem'}
            onClick={() => setModalCsvIsOpen(true)}
          >Subir dados em massa</Button>
        }

        {PropInvalida(Comite) &&
          <Tooltip
            padding='2'
            borderRadius='4'
            placement='top'
            color={'white'}
            background={'var(--a4)'}
            label='Antes de subir o csv você deve iniciar um comitê!'
            hasArrow
            arrowSize={15}
          >
            <div>
              <Button
                VarColor='Azul'
                isDisabled
                leftIcon={<FaFileUpload />}
                py={'0.75rem'}
                px={'2rem'}
              >Subir dados em massa</Button>
            </div>
          </Tooltip>
        }
      </Flex>
      <Grid gridTemplateColumns={'repeat(4, 1fr)'} gap={'2rem'}>
        <GridItem display={'flex'} justifyContent={'flex-end'} flexDirection={'column'}>
          <FormLabel>Área</FormLabel>
          <Select isDisabled={!PropInvalida(Tag) || !PropInvalida(Squad)} onChange={(e) => setArea(e.target.value === '' ? undefined : e.target.value)} backgroundColor={'var(--c1)'}>
            <option value={''}>Todas</option>
            {Areas.filter(r => Colaboradores.some(t => t.departamentoId === r.id)).map((e) => {
              return (
                <option key={e.id} value={e.id}>{e.nome}</option>
              )
            })}
          </Select>
        </GridItem>
        <GridItem display={'flex'} justifyContent={'flex-end'} flexDirection={'column'}>
          <FormLabel>Squad</FormLabel>
          <Select isDisabled={!PropInvalida(Area) || !PropInvalida(Tag)} onChange={(e) => setSquad(e.target.value === '' ? undefined : e.target.value)} backgroundColor={'var(--c1)'}>
            <option value={''}>Todos</option>
            {Squads.filter(e => e.nome !== '').map((e) => {
              return (
                <option key={e.id} value={e.id}>{e.nome}</option>
              )
            })}
          </Select>
        </GridItem>
        <GridItem display={'flex'} justifyContent={'flex-end'} flexDirection={'column'}>
          <FormLabel>Tag</FormLabel>
          <Select isDisabled={!PropInvalida(Area) || !PropInvalida(Squad)} onChange={(e) => setTag(e.target.value === '' ? undefined : e.target.value)} backgroundColor={'var(--c1)'}>
            <option value={''}>Todas</option>
            {Tags.filter(e => e.nome !== '').map((e) => {
              return (
                <option key={e.id} value={e.id}>{e.nome}</option>
              )
            })}
          </Select>
        </GridItem>
        <GridItem>
          <Box as={'div'} display={'flex'} fontSize={'1.5rem'} color={'#fff'} backgroundColor={'var(--Azul)'} p={'1rem'} alignItems={'center'} justifyContent={'center'} gap={'1rem'} borderRadius={'1rem'} maxHeight={'5.375rem'}>
            <div style={{ fontSize: '3.25rem' }}>
              <MdGroups />
            </div>
            <Box as='div'>
              <Text lineHeight={'1.438rem'} fontWeight={'700'}>{Colaboradores.length}</Text>
              <Text lineHeight={'1.875rem'}>Colaboradores</Text>
            </Box>
          </Box>
        </GridItem>
      </Grid>
      {PropInvalida(QuadranteSelecionado) && (
        <WhiteBox margin='2rem 0 0 0'>
          <Flex mb={'2rem'} alignItems={'center'} justifyContent='space-between'>
            <Text fontWeight={'700'} color={'var(--Gray2)'} fontSize={'1.25rem'}>Visão de grupo</Text>
            <Flex justifyContent={'end'} gap={'1rem'} width='60%'>
              {isAdm && !PropInvalida(Comite) && <Button
                leftIcon={<MdOutlineStopCircle />}
                VarColor='Red'
                onClick={() => setModalComiteIsOpen(true)}
              >Comitê de calibração em andamento</Button>}

              {isAdm && PropInvalida(Comite) && <Button
                leftIcon={<MdOutlinePlayCircle />}
                VarColor='Green1'
                onClick={() => setModalComiteIsOpen(true)}
              >Iniciar comitê de calibração</Button>}

              <InputGroup maxWidth={'20rem'}>
                <InputLeftElement
                  pointerEvents='none'
                  children={<FaSearch color='var(--c5)' />}
                />
                <Input
                  borderColor={'var(--Gray4)'}
                  onChange={(e) => setTextoPesquisa(e.target.value)}
                  type={'text'}
                  placeholder={'Buscar colaborador'}
                />
              </InputGroup>
            </Flex>
          </Flex>

          <Box pointerEvents={!isAdm ? 'none' : 'unset'} as='div' mb={'2rem'} width={'49rem'} backgroundColor={'var(--c5)'} borderRadius={'1rem'} px={'1rem'} mx={'auto'}>
            <Text
              my={'.5rem'}
              color={'var(--Gray2)'}
              textAlign={'center'}
              pt={'.3rem'}
              fontWeight={'700'}
            >COLABORADORES NÃO AVALIADOS</Text>
            <Box mb={'1.5rem'} onDragEnter={() => setQuadranteAtual(undefined)}>
              <AvatarGroup max={26} size='sm'>
                {Colaboradores.filter(t => !Model.quadrantes.some(y => y.colaboradores?.some(q => q === t.id)))
                  .filter((r) => {
                    if ((Squad && !r.squads.includes(Squad)) ?? (Tag && !r.tags.includes(Tag)) ?? (Area && r.departamentoId !== Area)) {
                      return null
                    } else if (TextoPesquisa === '') {
                      return r
                    } else if (r.nome.toLowerCase().includes(TextoPesquisa.toLocaleLowerCase())) {
                      return r
                    }
                    return null
                  }).map((c, i) => {
                    return (
                      <Avatar
                        key={c.id}
                        onDragEnd={() => onDragDrop(c.id)}
                        draggable
                        src={c?.avatar === 'https://sigah.blob.core.windows.net/assets/user.png' ? '' : c?.avatar}
                        name={c?.nome}
                        size='sm'
                        ml={i !== 0 ? '-4px' : ''}
                        _hover={{
                          brightness: '1.25',
                          transition: '.2s',
                          opacity: '.85',
                          boxShadow: '0 2px 8px 1px #00000075'
                        }}
                      />
                    )
                  })}
              </AvatarGroup>
            </Box>
          </Box>
          <div>
            <Flex flexDirection={'column'} alignItems={'center'}>
              <Flex justifyContent={'center'}>
                <Box as='div' height={'38.75rem'} display={'flex'} alignItems={'center'} justifyContent={'center'} width={'7.25rem'}>
                  <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'} transform={'rotate(-90deg)'}>
                    <Text display={'flex'} justifyContent={'center'} gap={'1rem'} fontWeight={'900'} fontSize={'3rem'}><span>{Model.configuracaoEixoY.titulo.toUpperCase()}</span></Text>
                    <Text fontSize={'1.25rem'} textAlign={'center'}>{Model.configuracaoEixoY.descricao}</Text>
                    <Image src={seta} width='41.75rem' marginRight={'3rem'} />
                  </Box>
                </Box>
                <Box as='div'>
                  <Grid gridTemplateColumns={'repeat(1, 1fr)'} gap={'1rem'}>
                    {Model.eixosY.map((e, i) => {
                      return (
                        <Grid key={i} gridTemplateColumns={'repeat(4, 1fr)'} gap={'1rem'}>
                          <GridItem alignSelf={'center'}>
                            <Text textAlign={'center'} color={'var(--Azul)'} display={'flex'} justifyContent={'center'} gap={'1rem'} fontWeight={'700'} fontSize={'1.5rem'}>
                              <span>{e.titulo}</span>
                            </Text>
                            <Text
                              textAlign={'center'}
                              fontSize={'0.75rem'}
                              color={'var(--Azul)'}
                            >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '' : '%'}</Text>
                          </GridItem>
                          {Model.quadrantes.filter(t => t.eixoY === e.id).map((q, i2) => {
                            return (
                              <EixoNineBox
                                onDragEnter={() => setQuadranteAtual(q.id)}
                                key={i2}
                                theme={ObterCorQuadrantePorNome(Model.quadrantes, q.titulo)}
                              >
                                <Text fontWeight={'600'} color={'var(--c1)'} mt={'1rem'} mb={'.5rem'}>{q.titulo}</Text>
                                <Box flex={'1'} pl={'.8rem'} alignSelf={'start'}>
                                  {q.colaboradores?.slice(0, 22).filter((r) => {
                                    const colab = Colaboradores.find(t => t.id === r)
                                    if ((Squad && !colab?.squads.includes(Squad)) ?? (Tag && !colab?.tags.includes(Tag)) ?? (Area && colab?.departamentoId !== Area)) {
                                      return null
                                    } else if (TextoPesquisa === '') {
                                      return r
                                    } else if (colab?.nome.toLowerCase().includes(TextoPesquisa.toLocaleLowerCase())) {
                                      return r
                                    }
                                    return null
                                  }).map((c: string, i3) => {
                                    const colab = Colaboradores.find(t => t.id === c)
                                    if (PropInvalida(colab)) return null
                                    return (
                                      <Avatar
                                        key={i3}
                                        onDragEnd={() => onZoneChange(c)}
                                        src={colab?.avatar === 'https://sigah.blob.core.windows.net/assets/user.png' ? '' : colab?.avatar}
                                        name={Colaboradores.find(t => t.id === c)?.nome ?? ''}
                                        size='sm'
                                        draggable
                                        ml={i3 !== 0 ? '-4px' : ''}
                                        pointerEvents={!isAdm ? 'none' : 'unset'}
                                        _hover={{
                                          cursor: 'pointer',
                                          brightness: '1.25',
                                          transition: '.2s',
                                          opacity: '.85',
                                          boxShadow: '0 2px 8px 1px #00000075'
                                        }}
                                        onDoubleClick={() => { nav(`/Desempenho/9Box/${colab?.id}`); SalvarTudo(undefined) }}
                                      />
                                    )
                                  })}

                                  <AvatarGroup
                                    size='sm'
                                    max={1}
                                    position={'absolute'}
                                    top={'7.275rem'}
                                    left={'10.9rem'}
                                    spacing={'-0.188rem'}
                                  >
                                    {q.colaboradores?.slice(22, q.colaboradores.length).map((c, i3) => {
                                      const colab = Colaboradores.find(t => t.id === c)
                                      if (PropInvalida(colab)) return null
                                      return (
                                        <Avatar
                                          key={i3}
                                          onDragEnd={() => onZoneChange(c)}
                                          src={colab?.avatar === 'https://sigah.blob.core.windows.net/assets/user.png' ? '' : colab?.avatar}
                                          name={colab?.nome}
                                          size='sm'
                                          ml={i3 !== 0 ? '-4px' : ''}
                                          pointerEvents={!isAdm ? 'none' : 'unset'}
                                          _hover={{
                                            cursor: 'pointer',
                                            brightness: '1.25',
                                            transition: '.2s',
                                            opacity: '.85',
                                            boxShadow: '0 2px 8px 1px #00000075'
                                          }}
                                          onDoubleClick={() => { nav(`/Desempenho/9Box/${colab?.id}`); SalvarTudo(undefined) }}
                                        />
                                      )
                                    })}
                                  </AvatarGroup>
                                </Box>
                                {PropInvalida(Comite) && isAdm && q.colaboradores && q.colaboradores.length > 0 &&
                                  <Box
                                    height={'1rem'}
                                    display={'flex'}
                                    width={'100%'}
                                    justifyContent={'flex-end'}
                                    alignItems={'flex-end'}
                                  >
                                    <Box
                                      overflow={'hidden'}
                                      color={'var(--c1)'}
                                      pt={'1rem'}
                                      pl={'1.4rem'}
                                      backgroundImage={ObterSvg(q.titulo)}
                                      backgroundSize={'cover'}
                                      cursor={'pointer'}
                                      backgroundPosition={'center'}
                                      transition={'all .2s'}
                                      borderRadius={'0 0 1rem 0'}
                                      width={'2.8rem'}
                                      height={'2.4rem'}
                                      _hover={{ height: '3rem', width: '3.5rem', paddingTop: '1.25rem', paddingLeft: '1.75rem' }}
                                    >
                                      <Tooltip
                                        padding='2'
                                        borderRadius='4'
                                        placement='top'
                                        color={'white'}
                                        background={'var(--a4)'}
                                        label='Antes de prosseguir você deve iniciar um comitê!'
                                        hasArrow
                                        arrowSize={15}
                                      >
                                        <div>
                                          <FaEye className='disabled' />
                                        </div>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                }
                                {isAdm && Comite && q.colaboradores && q.colaboradores.length > 0 && (
                                  <Box
                                    height={'1rem'}
                                    display={'flex'}
                                    width={'100%'}
                                    justifyContent={'flex-end'}
                                    alignItems={'flex-end'}
                                  >
                                    <Box
                                      overflow={'hidden'}
                                      color={'var(--c1)'}
                                      pt={'1rem'}
                                      pl={'1.4rem'}
                                      backgroundImage={ObterSvg(q.titulo)}
                                      backgroundSize={'cover'}
                                      cursor={'pointer'}
                                      backgroundPosition={'center'}
                                      transition={'all .2s'}
                                      borderRadius={'0 0 1rem 0'}
                                      width={'2.8rem'}
                                      height={'2.4rem'}
                                      _hover={{ height: '3rem', width: '3.5rem', paddingTop: '1.25rem', paddingLeft: '1.75rem' }}
                                      onClick={() => SalvarTudo(q.id)}>
                                      <FaEye />
                                    </Box>
                                  </Box>
                                )}
                              </EixoNineBox>
                            )
                          })}
                        </Grid>
                      )
                    })}
                    <Grid gridTemplateColumns={'repeat(4, 1fr)'} gap={'1rem'}>
                      <GridItem></GridItem>
                      {Model.eixosX.map((e, i) => {
                        return (
                          <GridItem key={i} alignSelf={'center'}>
                            <Text
                              textAlign={'center'}
                              color={'var(--Azul)'}
                              display={'flex'}
                              justifyContent={'center'}
                              gap={'1rem'}
                              fontWeight={'700'}
                              fontSize={'1.5rem'}
                            >
                              <span>{e.titulo}</span>
                            </Text>
                            <Text
                              textAlign={'center'}
                              fontSize={'0.75rem'}
                              color={'var(--Azul)'}
                            >De {e.rangeMinimo}{Model.tipoMeta.toString() === '1' ? '.0' : '%'} até {e.rangeMaximo}{Model.tipoMeta.toString() === '1' ? '.0' : '%'}</Text>
                          </GridItem>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Box>
              </Flex>
              <Flex pl={'11.5rem'} my={'2rem'}>
                <Box as='div' color={'var(--Azul)'} display={'flex'} flexDirection={'column'}>
                  <Image width={'56rem'} src={seta} />
                  <Text display={'flex'} justifyContent={'center'} gap={'1rem'} fontWeight={'900'} fontSize={'3rem'}><span>{Model.configuracaoEixoX.titulo.toUpperCase()}</span></Text>
                  <Text fontSize={'1.25rem'} textAlign={'center'}>{Model.configuracaoEixoX.descricao}</Text>
                </Box>
              </Flex>
            </Flex>
          </div>
          {isAdm &&
            <Flex justifyContent={'flex-end'} gap={'1rem'} my={'2rem'}>
              <Button
                VarColor='Gray4'
                onClick={ObterNineBoxPadrao}
              >Cancelar alterações</Button>
              {
                PropInvalida(Comite) &&
                <Tooltip
                  padding='2'
                  borderRadius='4'
                  placement='top'
                  color={'white'}
                  background={'var(--a4)'}
                  label='Para salvar você deve iniciar um comitê!'
                  hasArrow
                  arrowSize={15}
                >
                  <div>
                    <Button
                      VarColor='Green2'
                      disabled
                      leftIcon={<FaSave />}
                    >Salvar definição</Button>
                  </div>
                </Tooltip>
              }
              {!PropInvalida(Comite) &&
                <Button
                  VarColor='Green2'
                  leftIcon={<FaSave />}
                  onClick={() => SalvarTudo(undefined)}
                >Salvar definição</Button>
              }
            </Flex>
          }
        </WhiteBox>
      )}
      {!PropInvalida(QuadranteSelecionado) && (
        <WhiteBox margin='2rem 0 0 0'>
          <Flex alignItems={'center'}>
            <Text flex={'1'} fontWeight={'700'} color={'var(--Gray2)'} fontSize={'1.25rem'}>Visão de grupo</Text>
          </Flex>
          <div>
            <Button
              onClick={() => nav('/Desempenho/9Box')}
              VarColor='Gray4'
              my={'1rem'}
            >Voltar</Button>
          </div>
          <EixoUnicoNineBox
            theme={ObterCorQuadrantePorNome(Model.quadrantes, QuadranteSelecionado?.titulo ?? '')}
          >
            <Box as='h2' color={'var(--c1)'} fontWeight={'600'} mb={'1rem'} textAlign={'center'}>{QuadranteSelecionado?.titulo}</Box>
            <Box flex={'1'} pl={'.8rem'} alignSelf={'start'} width={'100%'}>
              {QuadranteSelecionado?.colaboradores?.map((e: string, i: number) => {
                const colab = Colaboradores.find(t => t.id === e)
                return (
                  <Avatar
                    key={i}
                    onClick={() => nav(`/Desempenho/9Box/${Colaboradores.find(t => t.id === e)?.id ?? ''}`)}
                    src={colab?.avatar === 'https://sigah.blob.core.windows.net/assets/user.png' ? '' : colab?.avatar}
                    name={colab?.nome ?? ''}
                    size='lg'
                    ml={i !== 0 ? '-4px' : ''}
                    _hover={{
                      cursor: 'pointer',
                      brightness: '1.25',
                      transition: '.2s',
                      opacity: '.85',
                      boxShadow: '0 2px 8px 1px #00000075'
                    }}
                  />
                )
              })}
            </Box>
          </EixoUnicoNineBox>
        </WhiteBox>
      )}
    </Body>
  )
}
