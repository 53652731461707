import styled from 'styled-components'
import { Flex } from '../../../styles/styledGlobal'

export const Main = styled(Flex)`
justify-content: space-between;
margin-top: 1.5rem;
section{
    width: 49%;
}

h2{
    font-family: 'Poppins','sans-serif';
    font-weight: 500;
    font-size: 1.75rem;
    color: var(--a4);
    margin-bottom:2rem; 
}

img{
    width: 49%;
    height: 35rem;
}
`
