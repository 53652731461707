import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
font-family: 'Poppins','sans-serif';
color: var(--a5);

h3{
    margin-bottom: 0.5rem;
}

span{
    font-weight: 500;
}
`

export const IconText = styled.div`
display: flex;
align-items: center;
margin-bottom: 0.5rem;
margin-left: 1.125rem;
svg{
    :first-child {
        margin-right: 0.5rem;
    } 
    :last-child {
        margin-left: .5rem;
        margin-bottom: .1rem;
    }
}
`
