import { useEffect, useState } from 'react'
import { Body } from '../../Layouts/Body'
import { iNineBoxAvaliacao, iNomeId, iPerfilColaborador, iProcessoOnboard } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Main, MainColumn } from './styles'
import { useParams } from 'react-router-dom'
import { PerfilColaborador } from '../../../components/PerfilColaborador/indesx'
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { ModalSucess } from './Components/ModalSucess'
import { useQuery } from '../../../Utils/Helper'
import { TabOnboard } from './Components/TabOnboard'
import { TabPDI } from './Components/TabPDI'
import { TabNineBox } from './Components/TabNineBox'
import { parseJwt } from '../../../services/token'
import { TabReuniaoOne } from './Components/TabReuniaoOne'
import { iLinhaTabela } from '../../Jornada/ReuniaoOne/Dashboard/Individual'

interface iDadoTabelaPDI {
  id: string
  dataFim: string
  origem: string
  totalAcoes: number
}

interface iDadoTabelaPDIAlterada extends iDadoTabelaPDI {
  totalConcluidas: number
  ultimaAvaliacao?: number
  temAcaoAtrasada: boolean
  temAcaoAlterada?: boolean
}

interface iDadoTabelaAndamento extends iDadoTabelaPDI {
  totalConcluidas: number
  ultimaAvaliacao?: number
  temAcaoAtrasada: boolean
}

export interface iTabelasPDIs {
  emAndamento: iDadoTabelaAndamento[]
  aprovacao: iDadoTabelaPDI[]
  emEdicao: iDadoTabelaPDIAlterada[]
}

type iLocationProp = {
  colaboradorId: string
  pdiId: string
}

export const VisulaizarTrilhaColaborador: React.FC = () => {
  const isAdm = parseJwt().adm === 'True'
  const { colaboradorId } = useParams<iLocationProp>()
  const query = useQuery()

  const [PDIs, setPDIs] = useState<iTabelasPDIs>({
    aprovacao: [],
    emAndamento: [],
    emEdicao: []
  })
  const [Reunioes, setReunioes] = useState<iLinhaTabela[]>([])
  const [ProcessoEmAndamento, setProcessoEmAndamento] = useState<iProcessoOnboard>()
  const [ProcessosFinalizados, setProcessosFinalizados] = useState<iProcessoOnboard[]>([])
  const [Colaborador, setColaborador] = useState<iPerfilColaborador>({
    avatar: '',
    email: '',
    id: '',
    lider: {
      avatar: '',
      nome: '',
      id: ''
    },
    liderados: [],
    nome: '',
    nomeCargo: '',
    nomeDepartamento: '',
    pares: [],
    celular: ''
  })
  const [Rh, setRh] = useState<iNomeId[]>([])

  const [ModalSucessIsOpen, setModalSucessIsOpen] = useState(false)

  const [ModelNineBox, setModelNineBox] = useState<iNineBoxAvaliacao>({
    comiteAtivo: false,
    nineBox: {
      id: '',
      padrao: false,
      areas: [],
      eixosX: [],
      eixosY: [],
      quadrantes: [],
      tags: [],
      squads: [],
      tipoMeta: 0,
      titulo: '',
      configuracaoEixoX: {
        descricao: '',
        titulo: ''
      },
      configuracaoEixoY: {
        descricao: '',
        titulo: ''
      }
    },
    comites: []
  })

  function getTrilha(): void {
    setProcessoEmAndamento(undefined)
    appApi.get(`ProcessoOnboard/Colaborador/${colaboradorId as string}`)
      .then(response => {
        if (response.status === 200) {
          setProcessoEmAndamento(response.data)
        }
      }).catch(err => {
        console.log(err)
        setProcessoEmAndamento(undefined)
      })
  }

  function ObterProcessos(): void {
    appApi.get(`ProcessoOnboard/${colaboradorId as string}/Processos`)
      .then(res => setProcessosFinalizados(res.data))
      .catch(err => console.log(err))
  }

  function getColaboador(): void {
    appApi.get(`Colaborador/${colaboradorId as string}/Perfil`)
      .then(response => setColaborador(response.data))
      .catch(err => console.log(err))
  }

  function LoadNineBox(): void {
    getColaboador()
    ObterNineBox()
  }

  function getPDIS(): void {
    appApi.get(`PDI/Perfil/${colaboradorId as string}`)
      .then(res => {
        setPDIs(res.data)
      })
      .catch(err => console.log(err))
  }

  function Load(): void {
    getTrilha()
    ObterProcessos()
    getColaboador()
  }

  function ObterRh(): void {
    appApi.get('Colaborador/Rh')
      .then(res => setRh(res.data))
      .catch(err => console.log(err))
  }

  function ObterNineBox(): void {
    appApi.get(`NineBox/Colaborador/${colaboradorId ?? ''}`)
      .then(res => setModelNineBox(res.data))
      .catch(err => console.log(err))
  }

  function ObterReunioes(): void {
    appApi.get(`ReuniaoOne/Perfil/${colaboradorId ?? ''}`)
      .then(res => {
        if (res.status === 200) {
          setReunioes(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    Load()
    getPDIS()
    ObterRh()
    if (isAdm) {
      ObterNineBox()
    }
    ObterReunioes()
  }, [colaboradorId])

  return (
    <Body>
      <ModalSucess
        isOpen={ModalSucessIsOpen}
        onRequestClose={() => setModalSucessIsOpen(false)}
      />

      <Main>
        <PerfilColaborador
          Colaborador={Colaborador}
          nineBox={ModelNineBox.nineBox}
          reload={getColaboador}
        />

        <MainColumn padding='1rem 1rem' width='60%'>
          <Tabs defaultIndex={query.get('pdiId') ? 1 : 0}>
            <TabList>
              <Tab color={'black'} bg={'none'}>Jornada</Tab>
              <Tab color={'black'} bg={'none'}>PDI</Tab>

              {ModelNineBox && ModelNineBox?.nineBox?.quadrantes?.length > 0 && (isAdm || Colaborador.avaliacaoNineBox) && <Tab color={'black'} bg={'none'}>9Box</Tab>}
              {Reunioes.length > 0 && <Tab color={'black'} bg={'none'}>Reunião 1:1</Tab>}
            </TabList>

            <TabPanels>
              <TabPanel>
                <TabOnboard
                  Load={Load}
                  colaborador={Colaborador}
                  processoEmAndamento={ProcessoEmAndamento}
                  processosFinalizados={ProcessosFinalizados}
                  rh={Rh}
                />
              </TabPanel>
              <TabPanel padding={'1rem 0'}>
                <TabPDI getPdis={getPDIS} pdis={PDIs} />
              </TabPanel>
              {ModelNineBox && ModelNineBox?.nineBox?.quadrantes?.length > 0 &&
                <TabPanel p={'0'} pt={'1rem'}>
                  <TabNineBox
                    colaborador={Colaborador}
                    model={ModelNineBox}
                    reload={LoadNineBox}
                  />
                </TabPanel>}
                <TabPanel>
                  <TabReuniaoOne tabela={Reunioes}/>
                </TabPanel>
            </TabPanels>
          </Tabs>
        </MainColumn>
      </Main>
    </Body>
  )
}
