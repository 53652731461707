import { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { Container, HeadBox } from './styles'

interface iTextBoxProps{
  isFirst?: boolean
  Titulo: string
  Pergunta: string
}

export const TextBox: React.FC<iTextBoxProps> = ({ isFirst, Pergunta, Titulo }) => {
  const [isOpen, setisOpen] = useState(false)
  return (
    <Container open={isOpen} isFisrt={isFirst}>
        <HeadBox open={isOpen}>
            <h4>{Titulo}</h4>
                <AiOutlinePlus onClick={() => setisOpen(!isOpen)} size={24}/>
        </HeadBox>
        <span>{Pergunta}</span>
    </Container>
  )
}
