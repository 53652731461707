import { Flex, FormControl, FormLabel, Input, Switch, Text, Textarea } from '@chakra-ui/react'
import { FaMinusCircle } from 'react-icons/fa'

interface iAcao{
  id: string
  dataInicio: string
  dataFim: string
  hora: string
  assunto: string
  titulo: string
  descricao: string
  motivo: string
  solicitarAjustes: boolean
  comentario?: string
}

interface iAcaoProps {
  acao: iAcao
  onRemove: (id: string) => void
  onUpdate: (acao: iAcao) => void
}

export const Acao: React.FC<iAcaoProps> = ({ acao, onRemove, onUpdate }) => {
  return (
    <Flex gap={'1rem'} flexDir={'column'} borderRadius={'1rem'} padding={'1rem'} border={'1px solid var(--Gray4)'}>
      <Flex marginBottom={'1rem'} justifyContent={'space-between'}>
        <Flex alignItems={'center'} gap={'.5rem'}>
          <Text fontWeight={'500'}>Solicitar ajustes</Text>
          <Switch
            onChange={(e) => onUpdate({ ...acao, solicitarAjustes: e.target.checked })}
            isChecked={acao.solicitarAjustes}
          />
        </Flex>
        <FaMinusCircle onClick={() => onRemove(acao.id)} cursor={'pointer'} size={20} color='var(--terc3)' />
      </Flex>
      <FormControl>
        <FormLabel>Assunto a ser tratado</FormLabel>
        <Input
          isReadOnly
          value={acao.assunto}
          borderColor={'var(--Gray4)'}
        />
      </FormControl>

      <Flex gap={'1rem'}>
        <FormControl>
          <FormLabel>Data para iniciar</FormLabel>
          <Input
            isReadOnly
            type={'date'}
            borderColor={'var(--Gray4)'}
            value={acao.dataInicio}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Data para finalizar</FormLabel>
          <Input
            isReadOnly
            type={'date'}
            borderColor={'var(--Gray4)'}
            value={acao.dataFim}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Quantas horas dedicar</FormLabel>
          <Flex alignItems={'center'}>
            <Input
              isReadOnly
              marginRight={'.5rem'}
              type={'number'}
              borderColor={'var(--Gray4)'}
              value={acao.hora}
            />
            <span>Horas</span>
          </Flex>
        </FormControl>
      </Flex>

      <Flex flexDir={'column'}>
        <Text color={'var(--Gray2)'} fontWeight='500'>O que vou fazer?</Text>
        <Text color={'var(--Gray2)'} fontWeight='400'>{acao.titulo}</Text>
      </Flex>

      <Flex flexDir={'column'}>
        <Text color={'var(--Gray2)'} fontWeight='500'>Come será realizada essa ação</Text>
        <Text color={'var(--Gray2)'} fontWeight='400'>{acao.descricao}</Text>
      </Flex>

      <Flex flexDir={'column'}>
        <Text color={'var(--Gray2)'} fontWeight='500'>Qual o motivo para se trabalhar essa competência?</Text>
        <Text color={'var(--Gray2)'} fontWeight='400'>{acao.motivo}</Text>
      </Flex>
      <FormControl>
        <FormLabel>Deixe uma observação para seu colaborador</FormLabel>
        <Textarea onChange={(e) => {
          onUpdate({ ...acao, comentario: e.target.value })
        }} borderColor={'var(--Gray4)'} />
      </FormControl>
    </Flex>
  )
}
