import styled, { css } from 'styled-components'
import { Flex } from '../../../../../styles/styledGlobal'

export const BoxCards = styled.div`
border-top: 2px solid var(--c5);
border-bottom: 2px solid var(--c5);
padding: 1.5rem 0rem;
border-bottom-style: dashed;
border-top-style: dashed;
margin:1.5rem 0rem 1rem 0rem;
`

export const DivPadding = styled.div`
padding: 1rem 0rem 1rem 0rem;
svg{
    cursor: pointer;
    color: var(--c6);
    height: 97.5%;
    background: var(--c2);
    padding: 0.2rem;

    &:hover{
      background: var(--c4);
      color: var(--c1);
      box-shadow: var(--SombraBtns);
    }

    &:active{
      box-shadow:none;
    }
}

.Direita{
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.Esquerda{
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
`

export const ContainerBody = styled.div`
display: flex;
padding: 1rem 0rem 1rem ;
overflow-x: auto;
scroll-behavior: smooth;

/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`

export const Card = styled(Flex)<{selecionado?: boolean}>`
background: #${props => props.theme};
justify-content: center;
align-items: center;
box-sizing: border-box;
font-family: 'poppins', sans-serif;
text-align: center;
flex-direction: column;
height: 13rem;
min-width: 10rem;
max-width: 10rem;
border-radius: 0.625rem;
border-width: 0.125rem;
margin-right: 1rem;
font-size:1rem;
padding: 0.5rem;
color: var(--c1);
font-weight: 600;

&:hover{
    border: solid var(--terc6);
    cursor: pointer;
    transform: translate(0rem,-0.375rem);
    transition: all 0.3s ease;
    box-shadow: 0rem 0.375rem 0.5rem rgba(0, 0, 0, 0.25);
}

  ${props => props.selecionado && css`
    border: solid var(--terc6);
 `}
`

export const ButtonMenu = styled.a<{margin?: string, selected: boolean}>`
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 0.75rem;
 font-weight: 500;
 color: var(--a1);
 background:none;
 padding: var(--mdPaddingBtn);
 font-size: var(--mdFontBtn);
 height: var(--mdHeigthBtn);
 line-height: 1px;
 border: 2px solid var(--a1);
 margin: ${props => props.margin};

 ${props => !props.selected && css`
    &:hover{
      transition:var(--transitionBtns);
      background:var(--a1);
      color: white;
    }
 `}

 ${props => props.selected && css`
     background: var(--a1);
     color:white;
 `}
`
