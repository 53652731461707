/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Flex, TableContainer, Table, Thead, Tr, Tbody, Box, Menu, MenuButton, MenuList, MenuItem, Text, Avatar } from '@chakra-ui/react'
import { AiOutlineMail } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaChevronDown, FaChevronUp, FaCog, FaEye, FaPen, FaRegComment, FaSyncAlt } from 'react-icons/fa'
import { OutlineContainer, OutlineTable, StyledSpan } from './styles'
import { MdOutlineCancel } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { iLinhaTabela } from '../..'
import { Button } from '../../../../../../components/Button'
import { urlLocal } from '../../../../../../services/appApi'
import { parseJwt } from '../../../../../../services/token'

interface iProps {
  feedbacks: iLinhaTabela[]
  onReenviar?: (id: string) => void
  onExcluir?: (id: string) => void
}
export const TabListaFeedback: React.FC<iProps> = ({ feedbacks, onExcluir, onReenviar }) => {
  const { cid, role } = parseJwt()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [UltimoFiltro, setUltimoFiltro] = useState<number>()
  const [Ordenacao, setOrdenacao] = useState(false)
  const [Feedbacks, setFeedbacks] = useState(feedbacks)

  function OrdenarTabela(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
  }

  function Ordenar(): void {
    const copy = [...feedbacks]
    if (UltimoFiltro === 1) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.criador.info1.localeCompare(a.criador.info1)
        } else if (Ordenacao) {
          return a.criador.info1.localeCompare(b.criador.info1)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.convidado.info1.localeCompare(a.convidado.info1)
        } else if (Ordenacao) {
          return a.convidado.info1.localeCompare(b.convidado.info1)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 3) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.comentarios > a.comentarios ? 1 : -1
        } else if (Ordenacao) {
          return a.comentarios > b.comentarios ? 1 : -1
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 4) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.nome.localeCompare(a.nome)
        } else if (Ordenacao) {
          return a.nome.localeCompare(b.nome)
        } else {
          return 0
        }
      })
    }

    if (UltimoFiltro === 6) {
      copy.sort((a, b) => {
        const data1 = a.dataInicio === 'Data não definida' ? Ordenacao ? ['31', '12', '2000'] : ['31', '10', '2000'] : a.dataInicio.split('/', 3)
        const data2 = b.dataInicio === 'Data não definida' ? Ordenacao ? ['31', '12', '2000'] : ['31', '10', '2000'] : b.dataInicio.split('/', 3)
        if (!Ordenacao) {
          return new Date(parseInt(data1[2]), parseInt(data1[1]), parseInt(data1[0])) > new Date(parseInt(data2[2]), parseInt(data2[1]), parseInt(data2[0])) ? 1 : -1
        } else if (Ordenacao) {
          return new Date(parseInt(data1[2]), parseInt(data1[1]), parseInt(data1[0])) < new Date(parseInt(data2[2]), parseInt(data2[1]), parseInt(data2[0])) ? 1 : -1
        }
        return 0
      })
    }
    setFeedbacks(copy)
  }

  function ObterBotao(id: string, status: number): any {
    return (
    <Flex justifyContent={'center'}>
      {status === 0 &&
        <Button
          fontSize={'12px'}
          VarColor='c5'
          rightIcon={<FaSyncAlt />}
        >Aguarde</Button>
      }

      {status === 1 &&
        <Button
          fontSize={'12px'}
          VarColor='Azul'
          rightIcon={<FaPen />}
          onClick={() => window.open(`${urlLocal}/Feedback/Abertura/${id}`, '_blank')}
        >Responder</Button>
      }

      {status === 2 &&
        <Button
          fontSize={'12px'}
          VarColor='Rosa'
          rightIcon={<FaEye />}
          onClick={() => nav(`${pathname.replace('/Dashboard', '')}/Visualizar/${id}`)}
        >Visualizar</Button>
      }
    </Flex>)
  }

  useEffect(() => {
    Ordenar()
  }, [Ordenacao, UltimoFiltro, feedbacks])

  return (
    <OutlineContainer>
      <OutlineTable>
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <th>Organizador {UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th>Convidado {UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th>Nome do feedback {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th>Data {UltimoFiltro === 6 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(6)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(6)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th>Comentários {UltimoFiltro === 3 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
                <th></th>
              </Tr>
            </Thead>
            <Tbody>
              {Feedbacks.map(e => {
                return (
                  <Tr key={e.id}>
                    <td>
                      <StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
                        <Avatar name={e.criador.info1} src={e.criador.avatar} size={'sm'} />
                        <Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
                          {e.criador.info1}
                        </Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
                        <Avatar name={e.convidado.info1} src={e.convidado.avatar} size={'sm'} />
                        <Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
                          {e.convidado.info1}
                        </Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.nome.length > 12 ? e.nome.substring(0, 12) + '...' : e.nome}</Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.dataInicio}</Text>
                      </StyledSpan>
                    </td>
                    <td>
                      <StyledSpan>
                        <Text
                          color={'Gray1'}
                          fontSize={'12px'}
                          fontWeight={'700'}
                          display='flex'
                          alignItems={'center'}
                          gap={'.5rem'}
                        >{e.comentarios} <FaRegComment size={20} /></Text>
                      </StyledSpan>
                    </td>
                    <td>
                      {ObterBotao(e.id, e.status)}
                    </td>

                    <td>
                      <Box width={'2.7rem'} height={'62.5px'}>
                        <Flex marginRight={'1rem'} pl={'.4rem'} pt={'1.4rem'} position={'absolute'} bgColor={'var(--Gray6)'}>
                        {role !== '0' &&
                          <Menu>
                            <MenuButton as={Button} _focus={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} backgroundColor={'transparent'} _hover={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} color={'var(--c7)'} padding={'0px'} mr={'-.5rem'}><BsThreeDotsVertical /></MenuButton>
                            <MenuList padding={'0'} boxShadow={'0px 0px 12px rgb(0,0,0,0.3)'} bgColor={'var(--Gray6)'} borderRadius={'.5rem'}>
                              {e.criador.id === cid &&
                                <MenuItem
                                  onClick={() => nav(`${pathname.replace('Dashboard', `Configuracao/Editar/${e.id}`)}`)}
                                  borderRadius={'.5rem .5rem 0 0'}
                                  color={'var(--g1)'}
                                  _focus={{ backgroundColor: 'var(--c1)' }}
                                  backgroundColor={'var(--c1)'}
                                  _hover={{
                                    backgroundColor: 'var(--Azul)',
                                    color: '#ffff'
                                  }}
                                  gap={'1rem'}>
                                  <FaCog />
                                  <span>Configuração</span>
                                </MenuItem>
                              }
                              {e.status === 1 &&
                                <MenuItem
                                  color={'var(--g1)'}
                                  _focus={{ backgroundColor: 'var(--c1)' }}
                                  backgroundColor={'var(--c1)'}
                                  _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }}
                                  gap={'1rem'}
                                  onClick={() => onReenviar !== undefined ? onReenviar(e.id) : ''}
                                >
                                  <AiOutlineMail />
                                  <span>Reenviar convite</span>
                                </MenuItem>
                              }

                                <MenuItem
                                  borderRadius={'0 0.5rem .5rem'}
                                  color={'var(--g1)'}
                                  _focus={{ backgroundColor: 'var(--c1)' }}
                                  backgroundColor={'var(--c1)'}
                                  _hover={{ backgroundColor: 'var(--Red)', color: '#ffff' }}
                                  gap={'1rem'}
                                  onClick={() => onExcluir !== undefined ? onExcluir(e.id) : ''}
                                >
                                  <MdOutlineCancel />
                                  <span>Excluir</span>
                                </MenuItem>
                            </MenuList>
                          </Menu>
                        }
                        </Flex>
                      </Box>
                    </td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </OutlineTable>
    </OutlineContainer>
  )
}
