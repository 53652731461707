import { GreenButton } from '../../../../../components/Buttons/GreenButton'
import { FaMapMarkerAlt, FaAngleDown, FaAngleUp, FaUserCheck } from 'react-icons/fa'
import { BackgroundCollapse, CardBody, ColumnText, HeaderSection, PreViewParticipante, RadiusAmarelo, SectionAssociados, SectionBody } from './styles'
import { useState } from 'react'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { iAvaliado, iCardAvaliador } from '../../../../../interfaces'
import { CardAssociado } from '../CardAssociado'
import { Avatar, Flex } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
import { Button } from '../../../../../components/Button'

interface iCardProps {
  enviado: boolean
  ciclica: boolean
  Avaliacao: iAvaliado
  onDeleteAvaliacao: (avaliacaoId: string) => void
  onDeleteAvaliador: (avaliacaoId: string, avaliadorId: string) => void
  onOpenModal: (tipo: number, avaliacaoId: string) => void
  onReenviarAvaliador: (avaliacaoId: string, avaliadorId?: string) => void
}

export const CardParticipante: React.FC<iCardProps> = ({ Avaliacao, enviado, ciclica, onDeleteAvaliacao, onDeleteAvaliador, onOpenModal, onReenviarAvaliador }) => {
  const [isOpen, setisOpen] = useState(false)
  const hasAlto = Avaliacao.participantes.find(e => e === 0) !== undefined
  function ObterTotal(): number {
    let i: number = 0
    if (Avaliacao.pares) {
      i += Avaliacao.pares !== undefined ? Avaliacao.pares.length : 0
    }

    if (Avaliacao.lider) {
      i += Avaliacao.lider !== undefined ? Avaliacao.lider.length : 0
    }

    if (Avaliacao.altaLideranca) {
      i += Avaliacao.altaLideranca !== undefined ? Avaliacao.altaLideranca.length : 0
    }

    if (Avaliacao.liderados) {
      i += Avaliacao.liderados !== undefined ? Avaliacao.liderados.length : 0
    }

    if (Avaliacao.outros) {
      i += Avaliacao.outros !== undefined ? Avaliacao.outros.length : 0
    }

    if (hasAlto) {
      i++
    }
    return i
  }
  return (
        <BackgroundCollapse className={Avaliacao.encerrada ? 'disabled' : ''} active={isOpen}>
            <PreViewParticipante>
                <RadiusAmarelo />
                <CardBody column={hasAlto ? 3 : 2}>
                    <Flex>
                        <Avatar src={Avaliacao.avatar} marginRight={'1rem'} name={Avaliacao.nome} />
                        <ColumnText>
                            <h4>{Avaliacao.nome}</h4>
                            <span>Avaliado</span>
                            {Avaliacao.dataInicio && <span>Inicio: {Avaliacao.dataInicio}</span>}
                        </ColumnText>
                    </Flex>
                    {
                        (hasAlto) && (
                            <Flex alignItems={'center'}>
                                <FaUserCheck size={35} style={{ marginRight: '.5rem', color: 'var(--a5)' }} />
                                <ColumnText>
                                    <h4>Contém</h4>
                                    <h3>Autoavaliação</h3>
                                </ColumnText>
                            </Flex>
                        )
                    }

                    <Flex alignItems={'center'}>
                        <FaMapMarkerAlt size={35} style={{ marginRight: '.5rem', color: 'var(--a5)' }} />
                        <ColumnText>
                            <h3>{ObterTotal()}</h3>
                            <span>Avaliadores</span>
                        </ColumnText>
                    </Flex>
                </CardBody>
                <Flex>
                    <Flex alignItems={'center'}>
                        <MetaButton bg='vg4' size='md' onClick={() => setisOpen(!isOpen)}>{!isOpen ? 'Visualizar' : 'Minimizar'}{!isOpen ? <FaAngleDown className='IconRigth' /> : <FaAngleUp className='IconRigth' />}</MetaButton>
                    </Flex>
                    <div>
                        <MdDelete
                            onClick={() => onDeleteAvaliacao(Avaliacao.id)}
                            style={{ margin: '.5rem', color: 'var(--terc3)', cursor: 'pointer' }}
                        />
                    </div>
                </Flex>
            </PreViewParticipante>
            <Flex flexDir={'column'} padding='1rem 1rem 0rem 1rem'>
                {enviado && !ciclica && (
                    <Flex mb={'1rem'}>
                        <Button VarColor='v2' onClick={() => onReenviarAvaliador(Avaliacao.id)}>Reenviar autoavaliação</Button>
                    </Flex>
                )}
                <SectionAssociados>
                    <HeaderSection>
                        <h3 style={{ color: 'var(--a5)' }}>Líder({Avaliacao.lider !== null ? Avaliacao.lider?.length : 0})</h3>
                        <GreenButton isNav={false} Size='md' OnClick={() => onOpenModal(1, Avaliacao.id)}>Adicionar</GreenButton>
                    </HeaderSection>
                    <SectionBody>
                        {
                            Avaliacao.lider?.map((e: iCardAvaliador, i) => {
                              return (
                                    <CardAssociado
                                        key={i}
                                        enviado={enviado}
                                        AvaliacaoId={Avaliacao.id}
                                        Avaliador={e}
                                        onDelete={onDeleteAvaliador}
                                        onReenviar={onReenviarAvaliador}
                                        tipo={1}
                                    />
                              )
                            })
                        }
                    </SectionBody>
                </SectionAssociados>

                <SectionAssociados>
                    <HeaderSection>
                        <h3 style={{ color: 'var(--a5)' }}>Pares({Avaliacao.pares !== null ? Avaliacao.pares?.length : 0})</h3>
                        <GreenButton isNav={false} Size='md' OnClick={() => onOpenModal(2, Avaliacao.id)}>Adicionar</GreenButton>
                    </HeaderSection>
                    <SectionBody>
                        {
                            Avaliacao.pares?.map((e: iCardAvaliador, i) => {
                              return (
                                    <CardAssociado
                                        key={i}
                                        enviado={enviado}
                                        AvaliacaoId={Avaliacao.id}
                                        Avaliador={e}
                                        onDelete={onDeleteAvaliador}
                                        onReenviar={onReenviarAvaliador}
                                        tipo={2}
                                    />
                              )
                            })
                        }
                    </SectionBody>
                </SectionAssociados>

                <SectionAssociados>
                    <HeaderSection>
                        <h3 style={{ color: 'var(--a5)' }}>Outros({Avaliacao.outros !== null ? Avaliacao.outros?.length : 0})</h3>
                        <GreenButton isNav={false} Size='md' OnClick={() => onOpenModal(4, Avaliacao.id)}>Adicionar</GreenButton>
                    </HeaderSection>
                    <SectionBody>
                        {
                            Avaliacao.outros?.map((e: iCardAvaliador, i) => {
                              return (
                                    <CardAssociado
                                        key={i}
                                        enviado={enviado}
                                        AvaliacaoId={Avaliacao.id}
                                        Avaliador={e}
                                        onDelete={onDeleteAvaliador}
                                        onReenviar={onReenviarAvaliador}
                                        tipo={4}
                                    />
                              )
                            })
                        }
                    </SectionBody>
                </SectionAssociados>

                <SectionAssociados>
                    <HeaderSection>
                        <h3 style={{ color: 'var(--a5)' }}>Alta liderança({Avaliacao.altaLideranca !== null ? Avaliacao.altaLideranca?.length : 0})</h3>
                        <GreenButton isNav={false} Size='md' OnClick={() => onOpenModal(6, Avaliacao.id)}>Adicionar</GreenButton>
                    </HeaderSection>
                    <SectionBody>
                        {
                            Avaliacao.altaLideranca?.map((e: iCardAvaliador, i) => {
                              return (
                                    <CardAssociado
                                        key={i}
                                        enviado={enviado}
                                        AvaliacaoId={Avaliacao.id}
                                        Avaliador={e}
                                        onDelete={onDeleteAvaliador}
                                        onReenviar={onReenviarAvaliador}
                                        tipo={6}
                                    />
                              )
                            })
                        }
                    </SectionBody>
                </SectionAssociados>

                <SectionAssociados>
                    <HeaderSection>
                        <h3 style={{ color: 'var(--a5)' }}>Liderados({Avaliacao.liderados !== null ? Avaliacao.liderados?.length : 0})</h3>
                        <GreenButton isNav={false} Size='md' OnClick={() => onOpenModal(3, Avaliacao.id)}>Adicionar</GreenButton>
                    </HeaderSection>
                    <SectionBody>
                        {
                            Avaliacao.liderados?.map((e: iCardAvaliador, i) => {
                              return (
                                    <CardAssociado
                                        key={i}
                                        enviado={enviado}
                                        AvaliacaoId={Avaliacao.id}
                                        Avaliador={e}
                                        onDelete={onDeleteAvaliador}
                                        onReenviar={onReenviarAvaliador}
                                        tipo={3}
                                    />
                              )
                            })
                        }
                    </SectionBody>
                </SectionAssociados>
            </Flex>
        </BackgroundCollapse>
  )
}
