/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-base-to-string */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'
import { IDadoGrafico2 } from '../..'
import { FaMedal, FaTools, FaHeart, FaFrown } from 'react-icons/fa'

interface iChartProps {
  id: string
  val: IDadoGrafico2[]
}

export const GaugeChartIndicativa: React.FC<iChartProps> = ({ id, val }) => {
  const valorOrdenado = val.sort((a, b) => parseFloat(b.media) - parseFloat(a.media))

  function ObterCor(value: IDadoGrafico2[]): string[] {
    const arr: string[] = []
    value?.forEach(e => {
      if (e.melhoria) {
        arr.push('var(--Red)')
      } else {
        arr.push('var(--Azul)')
      }
    })

    return arr
  }
  function ObterValorPos(value: IDadoGrafico2[]): string[] {
    const arr: string[] = []
    value?.forEach(e => arr.push(e.media))

    return arr
  }

  function GerarGrafico(): void {
    const options = {
      series: [{
        data: ObterValorPos(valorOrdenado)
      }],
      grid: {
        show: false
      },
      chart: {
        type: 'bar',
        height: valorOrdenado.length * 80,
        width: 1000,
        stacked: true
      },
      annotations: {
        xaxis: [
          {
            x: 0,
            strokeDashArray: 0,
            borderColor: 'var(--c5)',
            fillColor: '#c2c2c2',
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      dataLabels: {
        enabled: true,
        position: 'start',
        offsetX: 55,
        offsetY: -20,
        formatter: function (value: any, series: any) {
          return valorOrdenado[series.dataPointIndex].media + ' - ' + valorOrdenado[series.dataPointIndex].nome
        },
        style: {
          colors: ['#000']
        }
      },
      colors: ObterCor(valorOrdenado),
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          barHeight: '20px',
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (value: any, series: any) {
              return valorOrdenado[series.dataPointIndex].nome + ' - '
            }
          },
          marker: {
            show: false
          }
        }
      },
      xaxis: {
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        max: 1.2,
        min: -1.2
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [val])

  return (
    <div id={id}></div>
  )
}
