import { Flex, Grid, Text, useToast } from '@chakra-ui/react'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'
import { ModalBody } from './styles'
import { appApi, urlApi } from '../../../../../services/appApi'
import { ObterToken, PropInvalida } from '../../../../../Utils/Helper'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  reload: () => void
}

export const ModalCadastroEmMassa: React.FC<iModal> = ({ onRequestClose, isOpen, reload }) => {
  const { pesquisaId } = useParams<{pesquisaId: string}>()
  const toast = useToast()

  const [Arquivo, setArquivo] = useState<FormData>()
  const [Nome, setNome] = useState('')
  const [Erros, setErros] = useState<string[]>([])

  const AtualizarArquivo = (arquivo: any): void => {
    const formdata = new FormData()
    formdata.append('arquivo', arquivo.target.files[0])
    setArquivo(formdata)
    setNome(arquivo.target.files[0].name)
    toast({
      title: 'Arquivo selecionado com sucesso!',
      status: 'success',
      isClosable: false,
      position: 'top-right',
      duration: 4000
    })
  }

  function EnviarEmMassa(): void {
    appApi.post(`PesquisaIndicativa/${pesquisaId ?? ''}/Csv`, Arquivo)
      .then(res => {
        toast({
          title: 'Avaliados cadastrados na pesquisa com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })

        if (res.data.length > 0) {
          setErros(res.data)
        } else {
          reload()
          onRequestClose()
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <ModalBase Titulo='Cadastrar participantes em massa' isOpen={isOpen} onClose={onRequestClose} Width='65rem'>
      <ModalBody>
        <span><strong>Instruções para gerar o arquivo no excel:</strong>
          Baixe o arquivo de exemplo e preencha com os dados abaixo da linha de título.
          Após isso, clique em "Salvar como..." e selecione o formato
          <b>CSV UTF-8 (Delimitado por vírgulas).</b>
          As colunas que estão marcadas com “*” são campos obrigatórios.
          Por favor, realize o preenchimento completo para correto envio.
          <small>Obrigatório a inserção do endereço de email</small>
        </span>

        <label htmlFor='Ifile'>{Arquivo ? Nome : 'Selecione um arquivo'}</label>
        <input onChange={(e) => AtualizarArquivo(e)} id='Ifile' type={'file'} />
        {Erros?.length > 0 &&
          <Flex flexDir={'column'} width={'100%'} border={'1px solid var(--Red)'} borderRadius='1rem' mt={'1rem'} as={'ul'} padding='1rem 1rem 1rem 2rem'>
            <Text textAlign={'center'} fontWeight='bold' mb={'1rem'}>Emails com erro</Text>
            <Grid templateColumns='repeat(4, 1fr)' gap={6}>
              {Erros?.map((e) => {
                return (
                  <li>{e}</li>
                )
              })}
            </Grid>
          </Flex>}
      </ModalBody>
      <Flex justifyContent={'end'} padding='1rem' gap={'1rem'}>
        <Button
          onClick={() => { window.location.href = `${urlApi}/PesquisaIndicativa/Csv?token=${ObterToken()}` }}
          VarColor='Azul'
        >Baixar exemplo para preenchimento</Button>

        <Button
          isDisabled={PropInvalida(Arquivo)}
          onClick={EnviarEmMassa}
          VarColor='Green2'
        >Enviar arquivo</Button>

      </Flex>
    </ModalBase>
  )
}
