import { useEffect, useState } from 'react'
import { iImg } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Description, Main, VideoBox, WelcomeContainer } from './styles'
import { Flex, Image } from '@chakra-ui/react'
import { PropInvalida } from '../../../../../Utils/Helper'

export interface iArquivo {
  tipo: number
  titulo: string
  descricao: string
  arquivo: iImg
  checklist: any
  envioEmail: any
}

interface iModel{
  videoIntroducao: iArquivo
  textoBoasVindas: string
}

export const CardPrimeiroAcesso: React.FC<{isOpen: boolean}> = ({ isOpen }) => {
  const [Model, setModel] = useState<iModel>()

  const get = (): void => {
    appApi.get('PrimeiroAcesso/ByEmpresaId').then(response => {
      if (response.status === 200) {
        setModel(response.data)
      }
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Model)) {
      get()
    }
  }, [isOpen])
  return (
        <Main>
            <h3>Texto de boas vindas</h3>
            <WelcomeContainer>
              <Flex maxHeight={'8rem'} maxWidth={'8rem'} margin={'1rem 1rem 0 0'}>
                <Image width={'100%'} height='100%' src={localStorage.getItem('logo') ?? ''}/>
              </Flex>
                <Description>{Model?.textoBoasVindas}</Description>
            </WelcomeContainer>

            <h3>Vídeo introdutório</h3>
            {Model &&
              <Flex>
                  <VideoBox src={Model.videoIntroducao.arquivo.uri} />
                  <Flex flexDir={'column'}>
                      <span>{Model.videoIntroducao.arquivo.name}</span>
                      <small>Tamanho:{Model.videoIntroducao.arquivo.size}</small>
                  </Flex>
              </Flex>
            }
        </Main>
  )
}
