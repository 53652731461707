import { HTMLAttributes } from 'react'
import { FaSignal } from 'react-icons/fa'
import { iEscala, iRespostaEscala } from '../../interfaces'
import { parseJwt } from '../../services/token'
import { Container } from './styles'
interface iBoxProps extends HTMLAttributes<HTMLDivElement>{
  Selecionado?: boolean
  Escala: iEscala
}

export const BoxEscala: React.FC<iBoxProps> = ({ Selecionado, Escala, onClick }) => {
  return (
        <Container
            active={Selecionado}
            onClick={onClick}
        >
            <FaSignal size={32} />
            <div>
                <h4>{Escala.nome}</h4>
                {parseJwt().eid !== '02e3591d-d8c0-42e9-8aba-2c0cdcc3e034' &&
                <ul>
                    {
                      Escala.respostas.map((e: iRespostaEscala, i) => {
                        return (
                          <li key={i}>{e.resposta} {i !== Escala.respostas.length - 1 ? `( ${e.peso} )` : ''}</li>
                        )
                      })
                    }
                </ul>}

                {parseJwt().eid === '02e3591d-d8c0-42e9-8aba-2c0cdcc3e034' &&
                <ul>
                    {
                      Escala.respostas.map((e: iRespostaEscala, i) => {
                        return (
                          <li key={i}>{e.resposta} {`( ${e.peso} )`}</li>
                        )
                      })
                    }
                </ul>}
            </div>
        </Container>
  )
}
