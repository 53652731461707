/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Flex, TableContainer, Table, Thead, Tr, Tbody, Avatar, Box, Menu, MenuButton, MenuList, MenuItem, Text } from '@chakra-ui/react'
import { AiOutlineMail } from 'react-icons/ai'
import { BsArrowRepeat, BsThreeDotsVertical } from 'react-icons/bs'
import { FaChevronDown, FaChevronUp, FaEye } from 'react-icons/fa'
import { OutlineContainer, OutlineTable, StyledSpan } from './styles'
import { MdOutlineCancel } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { iLinhaTabela } from '../../../Individual'
import { ObterToken, PropInvalida } from '../../../../../../../Utils/Helper'
import { Button } from '../../../../../../../components/Button'
import { urlApi } from '../../../../../../../services/appApi'
import { CsvButton } from '../../../../../../../components/Buttons/CsvButton'

interface iProps {
  reunioes: iLinhaTabela[]
  individual: boolean
  telaColaborador?: string
  onReenviar?: (id: string) => void
  onRemarcar?: (id: string) => void
  onExcluir?: (id: string) => void
}
export const TabListaPorReuniao: React.FC<iProps> = ({ reunioes, individual, telaColaborador, onExcluir, onRemarcar, onReenviar }) => {
  const nav = useNavigate()
  const [UltimoFiltro, setUltimoFiltro] = useState<number>()
  const [Ordenacao, setOrdenacao] = useState(false)
  const [Reunioes, setReunioes] = useState(reunioes)

  function OrdenarTabela(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
  }

  function Ordenar(): void {
    const copy = [...reunioes]
    if (UltimoFiltro === 1) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.criador.info1.localeCompare(a.criador.info1)
        } else if (Ordenacao) {
          return a.criador.info1.localeCompare(b.criador.info1)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.convidado.info1.localeCompare(a.convidado.info1)
        } else if (Ordenacao) {
          return a.convidado.info1.localeCompare(b.convidado.info1)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 3) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.tipo.localeCompare(a.tipo)
        } else if (Ordenacao) {
          return a.tipo.localeCompare(b.tipo)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 4) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.assunto.localeCompare(a.assunto)
        } else if (Ordenacao) {
          return a.assunto.localeCompare(b.assunto)
        } else {
          return 0
        }
      })
    }
    // Condição errada
    // if (UltimoFiltro === 5) {
    //   copy.sort((a, b) => {
    //     if (!Ordenacao) {
    //       return b.status.localeCompare(ObterNomeTipo(a.status))
    //     } else if (Ordenacao) {
    //       return ObterNomeTipo(a.status).localeCompare(ObterNomeTipo(b.status))
    //     } else {
    //       return 0
    //     }
    //   })
    // }
    if (UltimoFiltro === 6) {
      copy.sort((a, b) => {
        const data1 = a.dataInicio === 'Data não definida' ? Ordenacao ? ['31', '12', '2000'] : ['31', '10', '2000'] : a.dataInicio.split('/', 3)
        const data2 = b.dataInicio === 'Data não definida' ? Ordenacao ? ['31', '12', '2000'] : ['31', '10', '2000'] : b.dataInicio.split('/', 3)
        if (!Ordenacao) {
          return new Date(parseInt(data1[2]), parseInt(data1[1]), parseInt(data1[0])) > new Date(parseInt(data2[2]), parseInt(data2[1]), parseInt(data2[0])) ? 1 : -1
        } else if (Ordenacao) {
          return new Date(parseInt(data1[2]), parseInt(data1[1]), parseInt(data1[0])) < new Date(parseInt(data2[2]), parseInt(data2[1]), parseInt(data2[0])) ? 1 : -1
        }
        return 0
      })
    }
    setReunioes(copy)
  }

  function ObterNomeStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'Aguardando'
      case '1':
        return 'Aceita'
      case '2':
        return 'Recusada'
      case '3':
        return 'Reagendada'
      case '4':
        return 'atrasada'
      case '5':
        return 'Finalizada'
      default: return ''
    }
  }

  function ObterCorStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'var(--Gray3)'
      case '1':
        return 'var(--Azul)'
      case '2':
        return 'var(--Rosa)'
      case '3':
        return 'var(--Purple1)'
      case '4':
        return 'var(--Red)'
      case '5':
        return 'var(--Green3)'
      default: return ''
    }
  }

  useEffect(() => {
    Ordenar()
  }, [Ordenacao, UltimoFiltro, reunioes])

  return (
		<OutlineContainer style={{ border: individual ? 'none' : '2px solid var(--Gray5)', padding: individual ? 'none' : '1rem' }}>
			{!individual &&
				<Flex>
					<CsvButton onClick={() => window.location.href = `${urlApi}/ReuniaoOne/Csv/Colaborador?token=${ObterToken()}`} />
				</Flex>
			}
			<OutlineTable>
				<TableContainer>
					<Table variant='simple'>
						<Thead>
							<Tr>
								{(telaColaborador === 'solicitadas') && (
									<th>Organizador {UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />} </th>
								)}
								{(telaColaborador === 'criadas') && (
									<th>Convidado {UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								)}
								{(!telaColaborador?.length) && (
									<>
										<th>Organizador {UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
										<th>Convidado {UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
									</>
								)}
								<th>Tipo {UltimoFiltro === 3 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Assunto {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Status {UltimoFiltro === 5 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(5)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(5)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Data {UltimoFiltro === 6 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(6)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(6)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
							</Tr>
						</Thead>
						<Tbody>
							{Reunioes.map(e => {
							  return (
									<Tr key={e.id}>
										{(telaColaborador === 'solicitadas') && (
											<td>
												<StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
													<Avatar src={e.criador.avatar} size={'sm'} />
													<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
														{e.criador.info1}
													</Text>
												</StyledSpan>
											</td>
										)}
										{(telaColaborador === 'criadas') && (
											<td>
												<StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
													<Avatar src={e.convidado.avatar} size={'sm'} />
													<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
														{e.convidado.info1}
													</Text>
												</StyledSpan>
											</td>
										)}
										{(!telaColaborador?.length) && (
											<>
												<td>
													<StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
														<Avatar name={e.criador.info1} src={e.criador.avatar} size={'sm'} />
														<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
															{e.criador.info1}
														</Text>
													</StyledSpan>
												</td>
												<td>
													<StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
														<Avatar name={e.convidado.info1} src={e.convidado.avatar} size={'sm'} />
														<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
															{e.convidado.info1}
														</Text>
													</StyledSpan>
												</td>
											</>
										)}
										<td>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.tipo}</Text>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.assunto.length > 12 ? e.assunto.substring(0, 12) + '...' : e.assunto}</Text>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<Text
													borderRadius={'6px'}
													color={'#fff'}
													bgColor={ObterCorStatus(e.status)}
													px={'.5rem'} h={'1.4rem'}
													fontSize={'12px'}
													fontWeight={'400'}
												>
													{ObterNomeStatus(e.status)}
												</Text>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.dataInicio}</Text>
											</StyledSpan>
										</td>
										{PropInvalida(telaColaborador) &&
											<td>
												<Box width={'2.7rem'} height={'62.5px'}>
													<Flex marginRight={'1rem'} pl={'.4rem'} pt={'1.4rem'} position={'absolute'} bgColor={'var(--Gray6)'}>
														<Menu>
															<MenuButton as={Button} _focus={{ backgroundColor: 'transparent' }} _active={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} backgroundColor={'transparent'} _hover={{ backgroundColor: 'transparent', boxShadow: '0px 0px 5px rgb(0,0,0,0.3)' }} color={'var(--c7)'} padding={'0px'} mr={'-.5rem'}><BsThreeDotsVertical /></MenuButton>
															<MenuList padding={'0'} boxShadow={'0px 0px 12px rgb(0,0,0,0.3)'} mt={'4rem'} bgColor={'var(--Gray6)'} borderRadius={'.5rem'}>
																<MenuItem onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${e.id}`)} borderRadius={'.5rem .5rem 0 0'} color={'var(--g1)'} _focus={{ backgroundColor: 'var(--c1)' }} backgroundColor={'var(--c1)'} _hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }} gap={'1rem'}>
																	<FaEye />
																	<span>Visualizar</span>
																</MenuItem>
																{e.status === 1 &&
																	<MenuItem
																		color={'var(--g1)'}
																		_focus={{ backgroundColor: 'var(--c1)' }}
																		backgroundColor={'var(--c1)'}
																		_hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }}
																		gap={'1rem'}
																		onClick={() => onReenviar !== undefined ? onReenviar(e.id) : ''}
																	>
																		<AiOutlineMail />
																		<span>Reenviar convite</span>
																	</MenuItem>
																}

																{e.status === 1 &&
																	<MenuItem
																		color={'var(--g1)'}
																		_focus={{ backgroundColor: 'var(--c1)' }}
																		backgroundColor={'var(--c1)'}
																		_hover={{ backgroundColor: 'var(--Azul)', color: '#ffff' }}
																		gap={'1rem'}
																		onClick={() => onRemarcar !== undefined ? onRemarcar(e.id) : ''}
																	>
																		<BsArrowRepeat />
																		<span>Remarcar</span>
																	</MenuItem>
																}
																<MenuItem
																	borderRadius={'0 0.5rem .5rem'}
																	color={'var(--g1)'}
																	_focus={{ backgroundColor: 'var(--c1)' }}
																	backgroundColor={'var(--c1)'}
																	_hover={{ backgroundColor: 'var(--Red)', color: '#ffff' }}
																	gap={'1rem'}
																	onClick={() => onExcluir !== undefined ? onExcluir(e.id) : ''}
																>
																	<MdOutlineCancel />
																	<span>Excluir</span>
																</MenuItem>
															</MenuList>
														</Menu>
													</Flex>
												</Box>
											</td>
										}
									</Tr>
							  )
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</OutlineTable>
		</OutlineContainer>
  )
}
