import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { FaCheck, FaClipboardList, FaMapMarkerAlt } from 'react-icons/fa'
import { iCardEtapa } from '../../../../../../../interfaces'
import { appApi } from '../../../../../../../services/appApi'
interface iCardReuniao {
  etapa: iCardEtapa
}

interface iModel {
  descricao: string
  local: string
  feedback: boolean
}

export const CardNovaReuniaoOne: React.FC<iCardReuniao> = ({ etapa }) => {
  const [Model, setModel] = useState<iModel>({
    descricao: '',
    local: '',
    feedback: false
  })

  function ObterEtapa(): void {
    appApi.get(`ReuniaoOne/${etapa.interacoes[0].idExterno ?? ''}/Detalhe`)
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ObterEtapa()
  }, [])

  return (
    <Container>
      <FlexIcon>
        <FaMapMarkerAlt size={30} />
        <div>
          <h3>Local da reunião</h3>
          <strong>{Model.local}</strong>
        </div>
      </FlexIcon>

      {Model.feedback &&
        <FlexIcon>
          <FaCheck color='var(--Green1)' size={24} />
          <h3>Feedback</h3>
        </FlexIcon>
      }
      {
        (Model.descricao !== null) && (
          <FlexIcon>
            <FaClipboardList size={30} />
            <div>
              <h3>Descrição</h3>
              <small dangerouslySetInnerHTML={{ __html: Model.descricao }}></small>
            </div>
          </FlexIcon>
        )
      }
    </Container>
  )
}
