/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../../services/appApi'
import {
  Container,
  HeaderContainer
} from './styles'
import { LayoutFull } from '../../../Layouts/LayoutFull'
import { MultiplaEscolha } from './components/MultiplaEscolha'
import { CaixaDeSelecao } from './components/CaixaDeSelecao'
import { Discursiva } from './components/Discursiva'
import { Button } from '../../../../components/Button'

type LocationProps = {
  paramId: string
}

interface iFormResponse {
  nomePesquisa: string
  txtEncerramento?: string
  linkRedirect?: string
  questoes: iFormQuestaoResponse[]
}

interface iFormQuestaoResponse {
  id: string
  tipo: number
  enunciado: string
  obrigatoria: boolean
  alternativas?: iFormAlternativaResponse[]
  minCaixaSelecao?: number
  maxCaixaSelecao?: number
}

interface iFormAlternativaResponse {
  id: string
  texto: string
}

interface iResposta {
  questaoId: string
  alternativaId: string
}

interface iRespostaDiscursiva {
  questaoId: string
  texto: string
}

export const PrevisualizarPesquisaInterna: React.FC = () => {
  const nav = useNavigate()
  const param = useParams<LocationProps>()
  const toast = useToast()
  const [Model, setModel] = useState<iFormResponse>()
  const [RespostasObjetiva, setRespostasObjetiva] = useState<iResposta[]>([])
  const [RespostasCaixaSelecao, setRespostasCaixaSelecao] = useState<iResposta[]>([])
  const [RespostasDiscursivas, setRespostasDiscursivas] = useState<iRespostaDiscursiva[]>([])
  const [Erros, setErros] = useState<string[]>([])

  function getFormulario(): void {
    appApi.get(`PesquisaInterna/${param.paramId as string}/Previsualizar`)
      .then(resp => setModel(resp.data)).catch(err => console.log(err))
    console.log(param.paramId)
  }

  function SelRespostaObjetiva(questaoId: string, alternativaId: string): void {
    if (!isSelectQuestao(questaoId)) {
      setRespostasObjetiva(oldArray => [...oldArray, { questaoId: questaoId, alternativaId: alternativaId }])
    } else {
      if (!isSelect(questaoId, alternativaId)) {
        UpdateRespostaObjetiva(questaoId, alternativaId)
      }
    }
  }

  function UpdateRespostaObjetiva(questaoId: string, alternativaId: string): void {
    const respostas = [...RespostasObjetiva]
    const pos = respostas.findIndex(e => e.questaoId === questaoId)
    if (pos !== -1) {
      respostas[pos].alternativaId = alternativaId
      setRespostasObjetiva(respostas)
    }
  }

  const isSelect = (perguntaId: string, alternativaId: string): boolean => RespostasObjetiva.find(e => e.questaoId === perguntaId && e.alternativaId === alternativaId) !== undefined
  const isSelectQuestao = (perguntaId: string): boolean => RespostasObjetiva.find(e => e.questaoId === perguntaId) !== undefined

  const hasError = (perguntaId: string): boolean => Erros.find(e => e === perguntaId) !== undefined

  function SelecionarMultiplaEscolha(questaoId: string, alternativaId: string): void {
    if (RespostasCaixaSelecao.find(r => r.questaoId === questaoId && r.alternativaId === alternativaId) === undefined) {
      setRespostasCaixaSelecao(oldArray => [...oldArray, { questaoId: questaoId, alternativaId: alternativaId }])
    } else {
      const copy = [...RespostasCaixaSelecao].filter(e => e.alternativaId !== alternativaId)
      setRespostasCaixaSelecao(copy)
    }
  }

  function UpdateDiscursiva(id: string, upTexto: string): void {
    console.log(RespostasDiscursivas)
    const pos = RespostasDiscursivas.findIndex(e => e.questaoId === id)
    if (pos !== -1) {
      const copy = [...RespostasDiscursivas]
      copy[pos].texto = upTexto
      setRespostasDiscursivas(copy)
    } else {
      setRespostasDiscursivas(oldArray => [...oldArray,
        {
          questaoId: id, texto: upTexto
        }])
    }
  }

  function ValidarTodasRespostas(): boolean {
    const naoRespondidas = Model?.questoes.filter(r => r.obrigatoria).map((e) => {
      if (RespostasObjetiva.find(r => r.questaoId === e.id) === undefined && RespostasCaixaSelecao.find(r => r.questaoId === e.id) === undefined && RespostasDiscursivas?.find(r => r.questaoId === e.id) === undefined) {
        return e.id
      }
      return null
    })
    const filtrado = naoRespondidas?.filter(e => e !== null)
    if (filtrado !== null && filtrado !== undefined && filtrado?.length > 0) {
      setErros(filtrado as string[])
      toast({
        title: 'Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    const CaixaSelecaoInvalidas = Model?.questoes.filter(r => r.obrigatoria && r.maxCaixaSelecao !== undefined && r.maxCaixaSelecao !== null)
      .map((e) => {
        const respostas = RespostasCaixaSelecao.filter(r => r.questaoId === e.id)
        if (!(respostas.length >= (e.minCaixaSelecao ?? 0) && respostas.length <= (e.maxCaixaSelecao ?? 0))) {
          return e.id
        }
        return null
      })
    if (CaixaSelecaoInvalidas !== null && CaixaSelecaoInvalidas !== undefined && CaixaSelecaoInvalidas?.filter(r => r !== null).length > 0) {
      setErros(CaixaSelecaoInvalidas?.filter(e => e !== null) as string[])
      toast({
        title: 'Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    return true
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <LayoutFull>
      <HeaderContainer onClick={ValidarTodasRespostas}>
        <h1>{Model?.nomePesquisa}</h1>
        <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' />
      </HeaderContainer>
          <Container>
            {
              Model?.questoes?.map((e: iFormQuestaoResponse, i) => {
                if (e.tipo === 0) {
                  return (
                    <MultiplaEscolha
                      key={i}
                      questao={e}
                      isSelect={isSelect}
                      SelecionarQuestao={SelRespostaObjetiva}
                      erro={hasError(e.id)}
                    />
                  )
                } else if (e.tipo === 5) {
                  return (
                    <CaixaDeSelecao
                      key={i}
                      questao={e}
                      Selecionar={SelecionarMultiplaEscolha}
                      erro={hasError(e.id)}
                    />
                  )
                } else if (e.tipo === 1) {
                  return (
                    <Discursiva
                      key={e.id}
                      questao={e}
                      erro={hasError(e.id)}
                      UpdateText={UpdateDiscursiva}
                    />
                  )
                }
                return null
              })
            }
          <Flex marginTop={'1rem'} justifyContent={'flex-end'}>
            <Button
              VarColor='c4'
              size={'lg'}
              onClick={() => nav(-1)}
            >Voltar</Button>
          </Flex>
          </Container>
    </LayoutFull>
  )
}
