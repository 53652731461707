import styled from 'styled-components'

export const Container = styled.div`
margin-right: .5rem;

label{
    color: var(--a4);
    cursor: pointer;
}

input[type=radio]{
    margin-right: 0.3rem;
}

font-weight: 500;
`
