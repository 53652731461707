import { Body } from '../../../../Layouts/Body'
import { Main } from './styles'
import { Flex } from '@chakra-ui/react'

interface iTextProps{
  Titulo: string
  Descricao?: string
  Url?: string
  isVisible: boolean
}

export const TextoImersao: React.FC<iTextProps> = ({ Titulo, Descricao, Url, isVisible }) => {
  if (!isVisible) {
    return null
  }

  return (
    <Body>
        <Main>
            <img src={Url}/>
            <Flex flexDir={'column'}>
                <h2>{Titulo}</h2>
                <span dangerouslySetInnerHTML={{ __html: Descricao ?? '' }}></span>
            </Flex>
        </Main>
    </Body>
  )
}
