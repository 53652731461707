import {
  ColumnNotas, EmployContainer, EmployeBody,
  EmployeHeader,
  PerfilContainer
} from './styles'
import {
  Avatar, CircularProgress,
  CircularProgressLabel, Flex, Input, Text, useToast
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { MdBlock } from 'react-icons/md'
import { useState, useEffect } from 'react'
import { appApi } from '../../../../../services/appApi'
import { converterData, PropInvalida, useQuery } from '../../../../../Utils/Helper'
import { FaClock, FaEdit, FaEye, FaSave, FaTrash } from 'react-icons/fa'
import { Column } from '../../../../../styles/styledGlobal'
import { Button } from '../../../../../components/Button'
import { iOnboard } from '../..'
import { parseJwt } from '../../../../../services/token'

interface iCardProps {
  color: string
  processo: iOnboard
  onReload?: () => void
  onOpenModalDelete: (id: string) => void
  loading: boolean
}

export interface iPI {
  urlSelo: string
  perfil: string
  assessmentId: string
  hide: boolean
}

export const CardFuncionario: React.FC<iCardProps> = ({ processo, color, onReload, onOpenModalDelete, loading }) => {
  const query = useQuery()
  const toast = useToast()
  const nav = useNavigate()
  const [Editar, setEditar] = useState(false)
  const [NovaData, setNovaData] = useState<string>()
  const [Model, setModel] = useState<iOnboard>({
    id: '',
    andamentoPercentual: 0,
    nomeTrilha: '',
    status: 0,
    colaborador: {
      avatar: '',
      email: '',
      id: '',
      nome: '',
      ativo: true
    }
  })

  useEffect(() => {
    setModel(processo)
  }, [processo])

  function getColor(numero: number): string {
    if (numero < 50) {
      return '#FE7B1E'
    } else if (numero >= 50 && numero < 75) {
      return '#6DA7A8'
    } else if (numero >= 75 && numero < 100) {
      return '#1961E0'
    } else {
      return '#89DB81'
    }
  }

  function ModoEditarOn(): void {
    setEditar(true)
    setNovaData(converterData(Model.colaborador.dataDesligamento ?? '').toISOString().split('T')[0])
  }

  function Salvar(): void {
    appApi.patch(`ProcessoOnboard/${Model.colaborador.id}/Desligamento/?dataDesligamento=${NovaData ?? ''}`)
      .then(() => {
        setEditar(false)
        setNovaData(undefined)
        toast({
          title: 'Data editada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        if (onReload !== undefined) {
          onReload()
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <EmployContainer>
      <Flex flexDir={'column'}>
        <EmployeHeader>
          <Flex>
            <Avatar marginRight={'1rem'} name={Model.colaborador.nome} src={Model.colaborador.avatar} />
            <Flex flexDir={'column'}>
              <span>{Model.colaborador.nome}</span>
              <small>{Model.colaborador.nomeArea}</small>
            </Flex>
          </Flex>
          {
            Model.status === 4
              ? <MdBlock size={38} color={'var(--terc1)'} />
              : <CircularProgress value={Model.andamentoPercentual} color={getColor(Model.andamentoPercentual)}>
                <CircularProgressLabel top={'45%'}>{Model.andamentoPercentual.toFixed(0)}%</CircularProgressLabel>
              </CircularProgress>
          }
        </EmployeHeader>
        <Flex direction={'column'} >
          <Text fontWeight={500} color={color}>{Model.nomeTrilha}</Text>
          {Model.status === 2 &&
            <Text fontWeight={500} color={'var(--Red)'}><FaClock /> Atrasada</Text>
          }

        </Flex>
      </Flex>

      <Flex flexDir={'column'} gap='.5rem'>
        {
          (!PropInvalida(Model.dataConclusao)) && (
            <Text fontWeight={'bold'} color='var(--c7)'>
              {Model.status === 4 ? 'Interrupção: ' : 'Conclusão: '} {Model.dataConclusao}
            </Text>
          )
        }
        {
          (!PropInvalida(Model.colaborador.dataDesligamento)) && (query.get('tipo')?.toString() === '3') && (
            <Flex alignItems={'center'} gap={'1rem'}>
              <Text
                fontWeight={'bold'}
                color='var(--c7)'
              >{'Desligamento: '}
                {
                  !Editar
                    ? Model.colaborador.dataDesligamento
                    : <Input
                      _focus={{ border: 'none' }}
                      height={'1.5rem'}
                      width={'10rem'}
                      border={'none'}
                      type='date'
                      onChange={(e) => setNovaData(e.target.value)}
                      value={NovaData}
                      max={(Model.status === 1 || Model.status === 2) ? undefined : new Date().toISOString().split('T')[0]}
                    />
                }
              </Text>
              {
                (!Editar && query.get('tipo')?.toString() === '3') && (
                  <FaEdit
                    onClick={() => ModoEditarOn()}
                    title='Editar data de desligamento'
                    cursor={'pointer'}
                    color='var(--terc10)'
                  />
                )
              }

              {
                (Editar) && (
                  <FaSave
                    onClick={() => Salvar()}
                    title='Salvar data de desligamento'
                    cursor={'pointer'}
                    color='var(--v2)'
                  />
                )
              }
            </Flex>
          )
        }
      </Flex>

      <EmployeBody>
        {
          (processo.colaborador.urlSelf) && (
            <PerfilContainer>
              <span>Perfil</span>
              <img src={processo.colaborador.urlSelf ?? ''} />
            </PerfilContainer>
          )
        }

        {

          <ColumnNotas>
              <Column style={{ display: Model.mediaStakeholders === null ? 'none' : '' }}>
                <small>Média dos stakeholders</small>
                <span>{Model.mediaStakeholders?.toFixed(2)}</span>
              </Column>
              <Column style={{ display: Model.mediaAutoAvaliacao === 0 || Model.mediaAutoAvaliacao === null ? 'none' : '' }}>
                <small>Média da autoavaliação</small>
                <span>{Model.mediaAutoAvaliacao?.toFixed(2)}</span>
              </Column>
          </ColumnNotas>

        }

      </EmployeBody>

      <Flex justifyContent='space-between' alignItems='center'>
        <Button
          isDisabled={!Model.colaborador.ativo && (Model.status === 3)}
          onClick={() => nav(`/Rh/VisualizarPerfil/${Model.colaborador.id}`)}
          bg='vg3'
          size='md'
          VarColor={color.split('--')[1].split(')')[0]}
          leftIcon={<FaEye />}
        >Visualização completa</Button>
        {Model.status !== 3 && Model.status !== 4 && parseJwt().adm === 'True' && (
          <Button isLoading={loading} onClick={() => onOpenModalDelete(Model.colaborador.id)} alignItems='center' colorScheme="red">
            <FaTrash style={{ color: 'white' }} />
          </Button>
        )}
      </Flex>
    </EmployContainer>
  )
}
