import { Avatar, Flex, Img } from '@chakra-ui/react'
import { FaCheckCircle, FaClipboardList, FaRegTimesCircle } from 'react-icons/fa'
import { Container, ColumnText, RadiusAmarelo } from './styles'
import IAvaliadores from '../../../../../../assets/IAvaliadores.svg'
import { iPesquisa } from '../..'

interface iCardProps {
  pesquisa: iPesquisa
}

export const CardPesquisa: React.FC<iCardProps> = ({ pesquisa }) => {
  return (
        <Container>
            <RadiusAmarelo />
            <Flex width={'100%'} justifyContent={'space-between'} pr='5rem'>
                <Flex alignItems={'center'}>
                    <Avatar marginRight='.5rem' name={pesquisa.participante.nome} src={pesquisa.participante.avatar} />
                    <ColumnText>
                        <h4 style={{ width: '10rem' }}>{pesquisa.participante.nome}</h4>
                        {
                            (pesquisa.participante.complemento !== null) && (
                                <span title={pesquisa.participante.complemento}>{pesquisa.participante.complemento?.substring(0, 18)}</span>
                            )
                        }
                    </ColumnText>
                </Flex>

                <Flex alignItems={'center'}>
                    <Img width={'40px'} height={'40px'} src={IAvaliadores} />
                    <ColumnText>
                        <h3>{pesquisa.avaliadores}</h3>
                        <span>Avaliadores</span>
                    </ColumnText>
                </Flex>

                <Flex alignItems={'center'}>
                    <FaClipboardList size={35} style={{ marginRight: '.5rem', color: 'var(--a5)' }} />
                    <ColumnText>
                        <h3>{pesquisa.respostas}</h3>
                        <span>Respostas</span>
                    </ColumnText>
                </Flex>

                {
                    (pesquisa.autoavaliacao !== null || pesquisa.lider !== null) && (
                        <Flex justifyContent={'center'} alignItems={'start'} flexDir={'column'}>
                            {
                                (pesquisa.autoavaliacao !== null) && (
                                    <Flex pl={'.5rem'} borderLeft={'1px solid var(--Gray4)'} marginBottom={'.3rem'} alignItems='center'>
                                        {(pesquisa.autoavaliacao) && <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />}
                                        {!pesquisa.autoavaliacao && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                                        <small>Autoavaliação</small>
                                    </Flex>
                                )
                            }
                            {
                                (pesquisa.lider !== null) && (
                                    <Flex pl={'.5rem'} borderLeft={'1px solid var(--Gray4)'} marginBottom={'.3rem'} alignItems='center'>
                                        {pesquisa.lider && <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />}
                                        {!pesquisa.lider && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                                        <small>Gestor</small>
                                    </Flex>
                                )
                            }
                        </Flex>
                    )
                }
            </Flex>
        </Container>
  )
}
