/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Checkbox, Text, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, useToast, Box, InputGroup, InputRightElement } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleRight, FaPlus, FaSave, FaTimes } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { FindUser } from '../../../../components/FindBox'
import { NavLine } from '../../../../components/NavLine'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iEtapa, iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { DeleteElementByPosition, FluxoEtapa, PropInvalida, SubstringRoute, UpdateElementByPosition, useQuery, ValidarEtapa } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { BotaoSocial, Legenda } from './styles'
import LogoZoom from './assets/logo-zoom.svg'
import LogoMicrosoft from './assets/microsoft-teams-logo.svg'
import { parseJwt } from '../../../../services/token'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { useAuth } from '../../../../contexts/AuthContext'
import { ElementoTooltip } from '../../../../components/ElementoTooltip'
import { MdOutlineClose } from 'react-icons/md'

export interface iModel {
  participanteId?: string
  baseId?: string
  pesquisaId?: string
  alvoPesquisaId?: string
  periodicidade?: number
  assunto: string
  local: string
  descricao: string
  duracao: number
  tipoId: string
  lembrete: boolean
  presencial: boolean
  datasDisponiveis: string[]
}

export const ConfigReuniaoOne: React.FC = () => {
  const { Permissoes } = useAuth()
  const { pathname } = useLocation()
  const { reuniaoId, etapaId, trilhaId, processoId } = useParams<{ reuniaoId: string, etapaId: string, trilhaId: string, processoId: string }>()
  const toast = useToast()
  const isTrilha = FluxoEtapa(pathname)
  const isEditar = pathname.includes('Editar')
  const nav = useNavigate()
  const query = useQuery()

  const [AdicionarPesquisa, setAdicionarPesquisa] = useState(false)
  const [Ciclica, setCiclica] = useState(false)
  const [AdicionarFeedback, setAdicionarFeedback] = useState(false)
  const [ReuniaoOutra, setReuniaoOutra] = useState(false)
  const [OutroAtivo, setOutroAtivo] = useState('')
  const [IsLoading, setIsLoading] = useState(false)

  const [NovaFuncao, setNovaFuncao] = useState({
    on: false,
    texto: ''
  })

  const [Model, setModel] = useState<iModel>({
    assunto: '',
    datasDisponiveis: [
      new Date().toISOString().split('T')[0] + 'T00:00'
    ],
    descricao: '',
    duracao: 5,
    lembrete: true,
    local: '',
    presencial: false,
    tipoId: '',
    participanteId: pathname.includes('Processo') ? processoId : undefined
  })
  const [Etapa, setEtapa] = useState<iEtapa>({})

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Reunioes, setReunioes] = useState<iNomeId[]>([])
  const [Pesquisas, setPesquisas] = useState<iNomeId[]>([])
  const [Tipos, setTipos] = useState<iNomeId[]>([])

  function SelecionarUsuario(id: string): void {
    setModel({ ...Model, participanteId: id })
  }

  function AdicionaData(): void {
    setModel({ ...Model, datasDisponiveis: [...Model.datasDisponiveis, '00-00-00T00:00'] })
  }

  function getColaboradores(): void {
    appApi.get('ReuniaoOne/Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterReunioes(): void {
    appApi.get(`ReuniaoOne/Select?colaboradorId=${Model.participanteId}`)
      .then(res => {
        if (res.status === 200) {
          setReunioes(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function ObterPesquisas(id: string): void {
    setModel({ ...Model, alvoPesquisaId: id })
    appApi.get(`ReuniaoOne/Pesquisas?colaboradorId=${id}`)
      .then(res => {
        if (res.status === 200) {
          setPesquisas(res.data)
        } else {
          if (parseJwt().cid !== id) {
            toast({
              title: 'Nenhuma pesquisa encontrada para este participante, talvez você não tenha permissão para visualizar!',
              status: 'warning',
              isClosable: false,
              position: 'top-right',
              duration: 3000
            })
          }
          setPesquisas([])
        }
      })
      .catch(err => console.log(err))
  }

  function ObterTipos(): void {
    appApi.get('TipoXEmpresa/Select?tipo=2')
      .then(res => {
        setTipos(res.data)
      })
      .catch(err => console.log(err))
  }

  function CriarTipos(): void {
    appApi.post('TipoXEmpresa', {
      texto: NovaFuncao.texto,
      tipo: 2
    })
      .then(() => {
        toast({
          title: 'Tipo criado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setNovaFuncao({
          on: false,
          texto: ''
        })
        ObterTipos()
      })
      .catch(err => console.log(err))
  }

  function CriarReuniao(): void {
    if (!isTrilha) {
      const form: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined
      }

      if (PropInvalida(form.tipoId)) {
        toast({
          title: 'Selecione um tipo para a sua reunião!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }

      appApi.post(`ReuniaoOne?temFeedback=${AdicionarFeedback}`, form)
        .then(res => {
          toast({
            title: 'Reunião criada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          if (AdicionarFeedback) {
            nav(pathname.replace('Criar', `Feedback/${res.data as string}/Formularios`))
          } else {
            nav(pathname.replace('Criar', `Visualizar/${res.data as string}`))
          }
        })
        .catch(err => console.log(err))
    } else if (pathname.includes('Trilha')) {
      if (!ValidarEtapa(Etapa)) {
        toast({
          title: 'Preencha os campos de etapa',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }
      const formReuniao: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        datasDisponiveis: []
      }

      const form = {
        reuniao: formReuniao,
        etapa: Etapa
      }
      appApi.post(`ReuniaoOneEtapa/${trilhaId}?temFeedback=${AdicionarFeedback}`, form)
        .then(res => {
          if (AdicionarFeedback) {
            nav(pathname.replace('Criar', `Feedback/${res.data as string}/Formularios`))
          } else {
            toast({
              title: 'Etapa criada com sucesso!',
              status: 'success',
              isClosable: false,
              position: 'top-right',
              duration: 4000
            })

            nav(pathname.replace('/NovaReuniaoOne/Criar', ''))
          }
        })
        .catch(err => console.log(err))
    } else if (pathname.includes('Processo')) {
      const formReuniao: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        datasDisponiveis: [],
        alvoPesquisaId: !PropInvalida(Model.pesquisaId) ? Model.participanteId : undefined
      }

      const form = {
        reuniao: formReuniao,
        etapa: Etapa
      }

      appApi.post(`ReuniaoOneEtapaColaborador/${processoId}?temFeedback=${AdicionarFeedback}`, form)
        .then(res => {
          if (AdicionarFeedback) {
            nav(pathname.replace('Criar', `Feedback/${res.data as string}/Formularios`))
          } else {
            toast({
              title: 'Etapa criada com sucesso!',
              status: 'success',
              isClosable: false,
              position: 'top-right',
              duration: 4000
            })

            nav(-1)
          }
        })
        .catch(err => console.log(err))
    }
  }

  function AtualizarReuniao(): void {
    if (!isTrilha) {
      const form: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined
      }

      if (PropInvalida(form.tipoId)) {
        toast({
          title: 'Selecione um tipo para a sua reunião!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        return
      }

      appApi.put(`ReuniaoOne/${reuniaoId}/Configuracao`, form)
        .then(res => {
          toast({
            title: 'Reunião atualizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          nav(-1)
        })
        .catch(err => console.log(err))
    } else if (pathname.includes('Trilha')) {
      const formReuniao: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        datasDisponiveis: []
      }

      const form = {
        reuniao: formReuniao,
        etapa: Etapa
      }
      appApi.put(`ReuniaoOneEtapa/${etapaId}`, form)
        .then(() => {
          toast({
            title: 'Etapa atualizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })

          nav(-1)
        })
        .catch(err => console.log(err))
    } else if (pathname.includes('Processo')) {
      const formReuniao: iModel = {
        ...Model,
        periodicidade: Ciclica ? Model.periodicidade : undefined,
        baseId: ReuniaoOutra ? Model.baseId : undefined,
        pesquisaId: AdicionarPesquisa ? Model.pesquisaId : undefined,
        datasDisponiveis: [],
        alvoPesquisaId: !PropInvalida(Model.pesquisaId) ? Model.participanteId : undefined
      }

      const form = {
        reuniao: formReuniao,
        etapa: Etapa
      }
      appApi.put(`ReuniaoOneEtapaColaborador/${processoId}/Etapa/${etapaId}`, form)
        .then(() => {
          toast({
            title: 'Etapa atualizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })

          nav(-1)
        })
        .catch(err => console.log(err))
    }
  }

  function ObterReuniao(id: string): void {
    setIsLoading(true)
    if (!isTrilha) {
      appApi.get(`ReuniaoOne/${id}/Configuracao`)
        .then(res => {
          const data: iModel = res.data
          setModel({
            ...Model,
            baseId: id,
            assunto: data.assunto,
            descricao: data.descricao,
            duracao: data.duracao,
            lembrete: data.lembrete,
            local: data.local,
            participanteId: data.participanteId,
            tipoId: data.tipoId,
            presencial: data.presencial,
            periodicidade: data.periodicidade,
            pesquisaId: data.pesquisaId,
            alvoPesquisaId: data.alvoPesquisaId
          })
          if (!PropInvalida(data.pesquisaId)) {
            setAdicionarPesquisa(true)
          }

          if (!PropInvalida(data.periodicidade)) {
            setCiclica(true)
          }
          setIsLoading(false)
        })
        .catch(err => console.log(err))
    } else if (pathname.includes('Trilha')) {
      appApi.get(`ReuniaoOneEtapa/${etapaId}`)
        .then(res => {
          const dataReuniao: iModel = res.data.reuniao
          const dataEtapa: iEtapa = res.data.etapa
          setModel({
            ...Model,
            baseId: id,
            assunto: dataReuniao.assunto,
            descricao: dataReuniao.descricao,
            duracao: dataReuniao.duracao,
            lembrete: dataReuniao.lembrete,
            local: dataReuniao.local,
            participanteId: dataReuniao.participanteId,
            tipoId: dataReuniao.tipoId,
            presencial: dataReuniao.presencial,
            periodicidade: dataReuniao.periodicidade,
            pesquisaId: dataReuniao.pesquisaId
          })
          if (!PropInvalida(dataReuniao.pesquisaId)) {
            setAdicionarPesquisa(true)
          }
          setEtapa(dataEtapa)
          setIsLoading(false)
        })
        .catch(err => console.log(err))
    } else if (pathname.includes('Processo')) {
      appApi.get(`ReuniaoOneEtapaColaborador/${processoId}/Etapa/${etapaId}`)
        .then(res => {
          const dataReuniao: iModel = res.data.reuniao
          const dataEtapa: iEtapa = res.data.etapa
          setModel({
            ...Model,
            baseId: id,
            assunto: dataReuniao.assunto,
            descricao: dataReuniao.descricao,
            duracao: dataReuniao.duracao,
            lembrete: dataReuniao.lembrete,
            local: dataReuniao.local,
            participanteId: dataReuniao.participanteId,
            tipoId: dataReuniao.tipoId,
            presencial: dataReuniao.presencial,
            periodicidade: dataReuniao.periodicidade,
            pesquisaId: dataReuniao.pesquisaId,
            alvoPesquisaId: dataReuniao.alvoPesquisaId
          })
          if (!PropInvalida(dataReuniao.pesquisaId)) {
            setAdicionarPesquisa(true)
          }
          setEtapa(dataEtapa)
          setIsLoading(false)
        })
        .catch(err => console.log(err))
    }
  }

  function AtualizarModelEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  useEffect(() => {
    ObterTipos()
    if (pathname.includes('Editar') || query.get('reuniaoId')) {
      ObterReuniao(reuniaoId ?? query.get('reuniaoId') ?? '')
    }
    getColaboradores()
  }, [])

  useEffect(() => {
    if (Model.participanteId && !isTrilha) {
      ObterReunioes()
    }
  }, [Model.participanteId])

  useEffect(() => {
    if (AdicionarPesquisa) {
      ObterPesquisas(Model.participanteId ?? '')
    }
  }, [AdicionarPesquisa])

  return (
    <Body>
      <NavLine
        options={[
          {
            nome: 'Reunião 1:1',
            path: `${SubstringRoute(pathname, 'Criar')}Dashboard/Individual`
          },
          { nome: 'Solicitação de reunião 1:1' }
        ]}
      />
      <WhiteBox>
        <h1>Solicitação de reunião 1:1</h1>
        {!isTrilha &&
          <Flex flexDir={'column'}>
            <Checkbox mt={'1rem'} onChange={() => setCiclica(!Ciclica)} isChecked={Ciclica}>Reunião recorrente</Checkbox>
            {(Ciclica) && (
              <Select mt={'1rem'} value={Model.periodicidade} onChange={(e) => setModel({ ...Model, periodicidade: parseInt(e.currentTarget.value) })}>
                <option value={''}>Nenhuma</option>
                <option value={'0'}>Diário</option>
                <option value={'1'}>Semanal</option>
                <option value={'2'}>Quinzenal</option>
                <option value={'3'}>Mensal</option>
                <option value={'4'}>Bimestral</option>
                <option value={'5'}>Trimestral</option>
                <option value={'6'}>Semestral</option>
              </Select>
            )}
          </Flex>
        }

        {!isTrilha &&
          <Flex mt={'2rem'} flexDirection={'column'}>
            <Text fontSize={'20px'} fontWeight={'700'} mb={'.5rem'}>Participante</Text>
            <FindUser
              isDisabled={isEditar}
              lista={Colaboradores}
              onChoice={(e) => {
                SelecionarUsuario(e)
                setReuniaoOutra(false)
                setAdicionarPesquisa(false)
              }}
              placeholder='Digite o nome do participante'
              selecionado={Model.participanteId}
            />
          </Flex>
        }

        {!isTrilha && !isEditar &&
          <Flex flexDir={'column'}>
            <Checkbox
              mt={'2rem'}
              mb={'1rem'}
              onChange={() => setReuniaoOutra(!ReuniaoOutra)}
              isDisabled={!Model.participanteId || Reunioes.length === 0}
              isChecked={ReuniaoOutra && !!Model.participanteId}
            >Criar reunião a partir de outra já realizada com esse participante</Checkbox>
            {(ReuniaoOutra) &&
              (<>
                <Text mb={'.5rem'}>Selecione uma reunião</Text>
                <Select value={Model.baseId} onChange={(e) => {
                  setModel({ ...Model, baseId: e.target.value })
                  ObterReuniao(e.target.value)
                }} w={'42rem'}>
                  <option value={''}>Nenhuma</option>
                  {Reunioes.map((e, i) => {
                    return (
                      <option value={e.id} key={i}>{e.nome}</option>
                    )
                  })}
                </Select>
                <Legenda>A nova reunião iniciada já será incrementada com os tópicos que não foram concluídos da última reunião e com os próximos passos acrescentados como tópicos</Legenda>
              </>)
            }

            {Model.participanteId && Reunioes.length === 0 &&
              <Text color={'var(--Red)'}>Não há reuniões criadas para esse colaborador</Text>
            }
          </Flex>
        }

        {isTrilha &&
          <Flex mt={'1rem'} flexDir={'column'}>
            <CreateEtapaBox
              EditEtapa={Etapa}
              ignoreId={etapaId}
              variant='Etapa'
              onModelChange={AtualizarModelEtapa}
              keyWord='reunião 1:1'
            />
          </Flex>
        }
        <FormControl my={'2rem'}>
          <FormLabel fontSize={'xl'}>Assunto da reunião</FormLabel>
          <Input
            value={Model.assunto}
            type={'text'}
            placeholder={'Digite o assunto da reunião'}
            borderColor={'var(--Gray4)'}
            onChange={(e) => setModel({ ...Model, assunto: e.target.value })}
          />
        </FormControl>

        <Flex flexDir={'column'} mb='1.5rem'>
          <Flex flexDir={'column'}>
            <Flex>
              <FormLabel fontSize={'1.3rem'} marginBottom='0.25rem'>Selecione o tipo da reunião</FormLabel>

              {!NovaFuncao.on && Permissoes.adicionarTipo &&
                <Button
                  ml={'1rem'}
                  onClick={() => setNovaFuncao({ ...NovaFuncao, on: true })}
                  leftIcon={<FaPlus />}
                  size={'xs'}
                  backgroundColor={'#1aa0bb'}
                >Adicionar novo tipo</Button>
              }

              {NovaFuncao.on &&
                <Button
                  ml={'1rem'}
                  onClick={() => setNovaFuncao({
                    on: false,
                    texto: ''
                  })}
                  leftIcon={<FaTimes />}
                  size={'xs'}
                  backgroundColor={'#ea2010'}
                >Cancelar</Button>
              }
            </Flex>
            {NovaFuncao.on &&
              <InputGroup>
                <InputRightElement cursor={'pointer'} onClick={CriarTipos}>
                  <FaSave color='var(--Green2)' />
                </InputRightElement>
                <Input
                  borderColor={'var(--Gray4)'}
                  placeholder='Digite o nome'
                  onChange={(e) => setNovaFuncao({ ...NovaFuncao, texto: e.target.value })}
                />
              </InputGroup>
            }

          </Flex>
          {!NovaFuncao.on &&
            <Select
              borderColor={'var(--Gray4)'}
              onChange={(e) => setModel({ ...Model, tipoId: e.target.value })}
              value={Model.tipoId}
            >
              <option value={''}>Nenhum</option>
              {Tipos.map((r, i) => {
                return (
                  <option key={i} value={r.id}>{r.nome}</option>
                )
              })}
            </Select>
          }
        </Flex>

        <Flex flexDirection={'column'}>
          <Flex alignItems={'start'} gap='1rem'>
            {!isTrilha && !isEditar &&
              <Flex flexDir={'column'}>
                <FormLabel fontSize={'xl'}>Data e hora</FormLabel>
                <Flex flexDir={'column'} gap={'1rem'}>
                  {Model.datasDisponiveis.map((e, i) => {
                    return (
                      <Flex gap={'.5rem'} alignItems={'center'} key={i}>
                        <Input
                          borderColor={'var(--Gray4)'}
                          w={'14.5rem'}
                          value={e}
                          onChange={(data) => setModel({ ...Model, datasDisponiveis: UpdateElementByPosition(Model.datasDisponiveis, i, data.target.value) })}
                          type={'datetime-local'}
                        />
                        {i > 0 &&
                        <ElementoTooltip label='Excluir' bg={'var(--Red)'}>
                          <MdOutlineClose
                            onClick={() => setModel({ ...Model, datasDisponiveis: DeleteElementByPosition([...Model.datasDisponiveis], i) })}
                          cursor={'pointer'} color='var(--Red)'
                          />
                        </ElementoTooltip>
                        }
                      </Flex>
                    )
                  })}
                </Flex>
                <Button
                  px={'2rem'}
                  py={'.5rem'}
                  w={'12rem'}
                  h={'1.7rem'}
                  mt={'1rem'}
                  onClick={AdicionaData}
                  fontSize={'0.625rem'}
                  VarColor='Rosa'
                  leftIcon={<FaPlus />}
                >Adicionar outra opção de data</Button>
              </Flex>
            }

            <FormControl>
              <FormLabel fontSize={'xl'}>Duração</FormLabel>
              <Select w={'14.5rem'} value={Model.duracao} onChange={(e) => setModel({ ...Model, duracao: parseInt(e.target.value) })} borderColor={'var(--Gray4)'}>
                <option value={'5'}>5 minutos</option>
                <option value={'10'}>10 minutos</option>
                <option value={'15'}>15 minutos</option>
                <option value={'30'}>30 minutos</option>
                <option value={'60'}>1 hora</option>
                <option value={'120'}>2 horas</option>
                <option value={'180'}>3 horas</option>
                <option value={'240'}>4 horas</option>
                <option value={'300'}>5 horas</option>
                <option value={'360'}>6 horas</option>
                <option value={'420'}>7 horas</option>
                <option value={'480'}>8 horas</option>
                <option value={'540'}>9 horas</option>
                <option value={'600'}>10 horas</option>
              </Select>
            </FormControl>
          </Flex>
          <Legenda>Ao adicionar outra opção de data o convidado poderá escolher uma delas para aceitar a reunião</Legenda>
        </Flex>

        <Box my={'2rem'} display={'flex'} flexDirection={'column'}>
          <Checkbox isChecked={!Model.lembrete} onChange={() => setModel({ ...Model, lembrete: !Model.lembrete })}>Desabilitar lembretes do envio e push</Checkbox>
          <Legenda>Ao desabilitar o lembrete você não receberá notificações momentos antes da reunião</Legenda>
        </Box>
        <RadioGroup onChange={() => setModel({ ...Model, presencial: !Model.presencial })} value={Model.presencial ? '1' : '0'}>
          <FormLabel fontSize={'xl'}>Local</FormLabel>
          <Stack direction={'row'} borderColor={'var(--Gray4)'}>
            <Radio value={'0'}>Online</Radio>
            <Radio value={'1'}>Presencial</Radio>
          </Stack>
        </RadioGroup >
        {(!Model.presencial) && (
          <Flex gap={'1rem'} my={'1rem'} alignItems={'center'}>
            <ElementoTooltip
              label={!Permissoes.teams ? 'Teams necessita de integração, vá até a configuração da empresa!' : ''}
            >
              <BotaoSocial
                isDisabled={!Permissoes.teams}
                varColor={'#5059C9'}
                selected={Model.local === 'microsoft'}
                onClick={() => {
                  setIsLoading(true)
                  setModel({
                    ...Model,
                    local: 'microsoft',
                    descricao: '<p>Reunião do Microsoft Teams</p><p>Ingressar no seu computador, aplicativo móvel ou dispositivo de sala</p><p>[LINK]</p>'
                  })
                  setOutroAtivo('')
                  setTimeout(() => setIsLoading(false), 10)
                }}>
                <img src={LogoMicrosoft} alt="" />
              </BotaoSocial>
            </ElementoTooltip>
            <ElementoTooltip
              label={!Permissoes.zoom ? 'Zoom necessita de integração, vá até a configuração da empresa!' : ''}
            >
              <BotaoSocial
                varColor={'#008DFF'}
                selected={Model.local === 'zoom'}
                onClick={() => {
                  setIsLoading(true)
                  setModel({
                    ...Model,
                    local: 'zoom',
                    descricao: '<p>Reunião do Zoom</p><p>Ingressar no seu computador, aplicativo móvel ou dispositivo de sala</p><p>[LINK]</p>'
                  })
                  setOutroAtivo('')
                  setTimeout(() => setIsLoading(false), 10)
                }}
                isDisabled={!Permissoes.zoom}
              >
                <img src={LogoZoom} alt="" />
              </BotaoSocial>
            </ElementoTooltip>
            <RadioGroup display={'flex'} alignItems={'center'} onChange={(e) => { setOutroAtivo(e); setModel({ ...Model, local: '' }) }} value={OutroAtivo}>
              <Radio value={'outro'}>Outro</Radio>
            </RadioGroup>
          </Flex>
        )}
        {(Model.presencial || OutroAtivo) && (
          <Input
            my={'1rem'}
            value={Model.local}
            onChange={(e) => setModel({ ...Model, local: e.target.value })}
            placeholder={Model.presencial ? 'Digite o local da reunião' : 'Cole o link da reunião aqui'}
            flexDir={'column'}
          />
        )}
        <Flex flexDir={'column'} mt={'.5rem'}>
          <FormControl>
            <FormLabel fontSize={'xl'}>Descrição</FormLabel>
            {((!IsLoading)) && (
              <EditorTexto
                initialValue={Model.descricao}
                placeholder='Deixe uma descrição aqui'
                className='editorSt-v3'
                isLoading={IsLoading}
                onChange={(e: string) => setModel({ ...Model, descricao: e })}
              />
            )}
          </FormControl>
          <small>Digite um texto com informações importantes ou pauta para registro do que será tratado na reunião</small>
        </Flex>
        {!isEditar &&
          <Flex flexDir={'column'} mt={'2rem'}>
            <Checkbox onChange={() => setAdicionarFeedback(!AdicionarFeedback)}>Acrescentar feedback</Checkbox>
            <Legenda>As configurações para o feedback irão aparecer logo após a criação da reunião</Legenda>
          </Flex>
        }

        {!pathname.includes('Trilha') &&
          <Flex flexDir={'column'} mt={'1rem'} mb={'2rem'}>
            <Checkbox
              isChecked={AdicionarPesquisa && !!Model.participanteId}
              onChange={(e) => { e.target.checked ? setAdicionarPesquisa(e.target.checked) : setAdicionarPesquisa(e.target.checked); setModel({ ...Model, pesquisaId: '' }) }}
              isDisabled={!Model.participanteId}
            >Acrescentar pesquisa de desempenho ou percepção</Checkbox>
            {(AdicionarPesquisa && Model.participanteId) && (
              <>
                <Flex mt={'1rem'} flexDir={'column'}>
                  <FormLabel>Quem é o alvo dessa pesquisa</FormLabel>
                  <RadioGroup
                    onChange={ObterPesquisas}
                    value={Model.alvoPesquisaId}
                  >
                    <Stack gap={'1rem'} direction='row'>
                      {!isTrilha &&
                        <Radio value={parseJwt().cid}>Eu</Radio>
                      }
                        <Radio value={Model.participanteId}>Participante</Radio>

                    </Stack>
                  </RadioGroup>
                </Flex>
                <FormControl mt={'1rem'}>
                  <FormLabel>Selecione uma pesquisa</FormLabel>
                  <Select
                    value={Model.pesquisaId ?? ''}
                    onChange={(e) => setModel({ ...Model, pesquisaId: e.target.value })}
                    mt={'.5rem'}
                    w={'42rem'}
                  >
                    <option value={''}>Nenhuma</option>
                    {Pesquisas.map((e, i) => {
                      return (
                        <option value={e.id} key={i}>{e.nome}</option>
                      )
                    })}
                  </Select>
                </FormControl>
                <Legenda>Só são apresentadas pesquisas que você ou o participante participaram como avaliado</Legenda>
              </>
            )}
          </Flex>
        }

        <Flex marginTop={'2rem'} justifyContent={'end'}>
          <Button
            VarColor='c5'
            marginRight={'1rem'}
            size={'lg'}
            onClick={() => nav(-1)}
          >Cancelar</Button>
          {!isEditar &&
            <Button
              onClick={CriarReuniao}
              VarColor='v2'
              rightIcon={<FaAngleRight />}
              size={'lg'
              }>Solicitar</Button>
          }

          {isEditar &&
            <Button
              onClick={AtualizarReuniao}
              VarColor='v2'
              rightIcon={<FaAngleRight />}
              size={'lg'
              }>Atualizar {isTrilha ? 'etapa' : 'reunião'}</Button>
          }
        </Flex>
      </WhiteBox>
    </Body>
  )
}
