import { MenuList } from '@chakra-ui/react'

export const StyledMenuList: React.FC = ({ children }) => {
  return (
        <MenuList bg={'white'} borderRadius={'.5rem'} boxShadow={'0px 2px 8px rgba(0, 0, 0, 0.25)'} zIndex={100}>
            {
                children
            }
        </MenuList>
  )
}
