import { Box, Button, Flex, HStack, Text, InputGroup, InputLeftElement, Input, VStack, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaBriefcase, FaEnvelope, FaSearch, FaUsers } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { parseJwt } from '../../../services/token'
import { AutorizarPI, PropInvalida } from '../../../Utils/Helper'
import { Body } from '../../Layouts/Body'
import { IperfilDto, TabelaPerfis } from '../components/TabelaPerfis'

interface IListaPerfilDTO{
  quantidade: number
  cargos: number
  perfis: IperfilDto[]
}
export const numberFormatter = Intl.NumberFormat('pt-BR')
interface IRankPerfil{
  perfil: string
  quantidade: number
}
export const DashboardPi: React.FC = () => {
  const navigate = useNavigate()
  const [IsLoading, setIsLoading] = useState(false)
  const [Perfis, setPerfis] = useState<IListaPerfilDTO>({
    perfis: [],
    quantidade: 0,
    cargos: 0
  })
  const [QuantidadePerfil, setQuantidadePerfil] = useState<number>(0)
  const [Busca, setBusca] = useState('')
  const [Qtd, setQtd] = useState(25)
  const [Ranking, setRanking] = useState<IRankPerfil[]>([])
  const [PaginaAtual, setPaginaAtual] = useState(0)
  const [dataInicio, setDataInicio] = useState('')
  const [dataFim, setDataFim] = useState('')

  useEffect(() => {
    appApi.get('AssessmentPi/Assessment/Ranking').then(res => {
      if (res.status === 200) {
        const data: IRankPerfil[] = res.data
        setRanking(data)
      }
    }).catch(err => console.log(err))

    appApi.get(`AssessmentPi/Assessment?consultoria=${parseJwt().consultoria}`).then(res => {
      if (res.status === 200) {
        const data: IListaPerfilDTO = res.data
        setPerfis(data)
        console.log(data)
        console.log('data entrando')
      }
    }).catch(err => console.log(err))

    appApi.get(`AssessmentPi/Assessment/Count?tamanho=${Qtd}&pagina=${PaginaAtual}&?consultoria=${parseJwt().consultoria}`).then(res => {
      if (res.status === 200) {
        const data: number = res.data
        setQuantidadePerfil(data)
        console.log(data)
      }
    }).catch(err => {
      console.log(err)
      setQuantidadePerfil(0)
    })

    // setPerfil({
    //   perfil: {
    //     dataPesquisa: new Date(),
    //     nome: 'Zeca Guerreiro',
    //     perfil: '10',
    //     id: 'dsadas',
    //     urlImgPerfil: 'https://sigah.blob.core.windows.net/assets/piData/27beb508-f65b-4216-8982-d409491011d1_tbn.png'
    //   },
    //   outrosCargos: [{ jobId: 'aaaa', nome: 'Manobrista' }]
    // }
    // )

    // setPerfis([...Perfis, Perfil])
  }, [])

  // useEffect(() => {
  //   appApi.get(`AssessmentPi/Assessment/?busca=${Busca}`).then(res => {
  //     if (res.status === 200) {
  //       const data: IListaPerfilDTO = res.data
  //       setPerfis(data)
  //       console.log(data)
  //       console.log('data entrando')
  //     }
  //   }).catch(err => console.log(err))
  // }, [Busca])
  function buscar(): void {
    setIsLoading(true)
    appApi.get(`AssessmentPi/Assessment/?busca=${Busca}&tamanho=${Qtd}&pagina=${PaginaAtual}&dataInicio=${dataInicio}&dataFim=${dataFim}&consultoria=${parseJwt().consultoria}`).then(res => {
      if (res.status === 200) {
        const data: IListaPerfilDTO = res.data
        setPerfis(data)
        console.log(data)
        console.log('data entrando')
        setIsLoading(false)
      }
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    buscar()
  }, [PaginaAtual, Qtd])

  useEffect(() => {
    if (!AutorizarPI()) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    buscar()
  }, [dataInicio, dataFim])

  return <Body isLoading={IsLoading}>

  <Flex direction={'column'} justify='center'>
    <Flex width='70rem' maxWidth='90vw' justifyContent={'space-between'}>

    <Text fontWeight='medium' mb='1rem' fontSize='1.8rem' >{'Assessment PI®'}</Text>
    <InputGroup width={'15rem'} >
    <InputLeftElement bgColor='white' color='gray.300' borderLeft={'1px'} borderTop={'1px'} borderBottom={'1px'}
    borderRadius='sm' borderColor={'gray.200'}
      pointerEvents='none'
      children={<FaSearch size={'.8rem'} color='gray.300' />}
      />
    <Input bgColor='white' type='tel' placeholder='Busca' value={Busca} onChange={(e) => setBusca(e.target.value)} onKeyPress={e => {
      if (e.key === 'Enter') { buscar() }
    }} />
  </InputGroup>
      </Flex>
  <Box width='70rem' maxWidth='90vw' bg='white' borderRadius='lg'>

  <Flex justifyContent={'space-between'} p='1.5rem'>
    <Text fontWeight='bold' fontSize='1.8rem' >Dashboard</Text>

    <HStack spacing={4}>
    <Button leftIcon={<FaEnvelope size='.8rem'/>}
    onClick={() => navigate('/AssessmentPi/Send')}
    bg='var(--Azul)' width='10rem' height='2.5rem' size='xs' >Enviar avaliação</Button>
   {parseJwt().consultoria === 'False' && (

    <Button leftIcon={<FaBriefcase size='.8rem'/>}
    onClick={() => navigate('/AssessmentPi/JobTarget/Create')}
    bg='var(--Azul)' width='10rem' height='2.5rem' size='xs' >Novo cargo</Button>
   )}
    </HStack>
  </Flex>

  <Flex justifyContent='space-between' px='1rem'>
      <Flex border={'2px'} borderColor={'var(--Roxo)'} color={'var(--Roxo)'} width='320px' height={'197px'} borderRadius='2xl'
      flexDir={'column'} justifyContent={'center'}>

      <VStack>

      <Text>Total de perfis na empresa</Text>
      <HStack>
        <FaUsers size={'2.5rem'}/>
      <Text fontSize={'4xl'}>{numberFormatter.format(QuantidadePerfil)}</Text>
      </HStack>
      </VStack>

      </Flex>
      <Flex border={'2px'} borderColor={'var(--Roxo)'} color={'var(--Roxo)'} width='320px' height={'197px'} borderRadius='2xl'
      flexDir={'column'} justifyContent={'center'}>

      <VStack>

      <Text>Cargos</Text>
      <HStack>
        <FaBriefcase size={'2.3rem'}/>
      <Text fontSize={'4xl'}>{Perfis.cargos}</Text>
      </HStack>
      </VStack>

      </Flex>
      <Flex border={'2px'} borderColor={'var(--Roxo)'} color={'var(--Roxo)'} width='320px' height={'197px'} borderRadius='2xl'
      flexDir={'column'} justifyContent={'center'}>

      <VStack my='1rem'>

      <Text>Perfis mais encontrados</Text>
      <VStack width={'80%'} spacing='1' >
        <Flex width={'100%'}justifyContent={'space-between'} px='1rem'>
          <Text width={'2.5rem'} fontWeight={'medium'} textAlign='center' color={'gray.700'} fontSize={'sm'}>N°</Text>
          <Text width={'4.5rem'} fontWeight={'medium'} textAlign='center' color={'gray.700'} fontSize={'sm'}>Total</Text>
        </Flex>
      <VStack width={'100%'} spacing='1' >
{Ranking.map((i, k) => (
        <Flex width={'100%'}justifyContent={'space-between'} px='1rem' bgColor={'rgba(25, 97, 224, 0.1)'}>
          <Text fontWeight={'medium'} width={'2.5rem'} textAlign='center' color={'gray.700'} fontSize={'sm'}>P. {i.perfil}</Text>
          <Text fontWeight={'medium'} width={'4.5rem'} textAlign='center' color={'gray.700'} fontSize={'sm'}>{numberFormatter.format(i.quantidade)}</Text>
        </Flex>))}

      </VStack>

      </VStack>

      </VStack>

      </Flex>

  </Flex>

{(Busca === '' && PropInvalida(dataInicio) && PropInvalida(dataFim)) && (<Text m='1.5rem' fontWeight='bold' fontSize='1.8rem' >Todos os perfis</Text>)}
{(Busca !== '' || dataInicio !== '' || dataFim !== '') && (<Text m='1.5rem' fontWeight='bold' fontSize='1.8rem' >Perfis encontrados: {Perfis.quantidade}</Text>)}

<HStack ml='1rem'>

  <Text>De</Text>
<Input width={'16rem'}
 placeholder="data início"
 size="md"
 type="date"
 value={dataInicio}
 onChange={(s => setDataInicio(s.target.value))}
/>
<HStack color='var(--c7)' mr={4}>
 <Text>até</Text>
    </HStack>
<Input width={'16rem'}
 onChange={(s => setDataFim(s.target.value))}
 placeholder="data início"
 size="md"
 type="date"
 value={dataFim}
/>
 </HStack>

  <TabelaPerfis
  isLoading={false}
  next={() => setPaginaAtual(PaginaAtual < Math.round(Perfis.quantidade / Qtd) ? PaginaAtual + 1 : Math.round(Perfis.quantidade / 25))}
  numeroPaginas={Math.round(Perfis.quantidade / Qtd)}
  paginaAtual={PaginaAtual}
  perfis={Perfis.perfis}
  prev = {() => setPaginaAtual(PaginaAtual > 0 ? PaginaAtual - 1 : 0)}

  />
  <Flex justifyContent={'end'} m='1.5rem'>

  <HStack align={'center'}>
  <Text fontSize={'xs'} fontWeight='medium'> Quantidade de perfis por página</Text>
    <Select onChange={e => setQtd(Number.parseInt(e.target.value))} size={'xs'} width={'4rem'} >
      <option value='25'>25</option>
      <option value='50'>50</option>
      <option value='100'>100</option>
    </Select>
  </HStack>
  </Flex>
  </Box>
  </Flex>
  </Body>
}
