/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'
import { IDadoGrafico } from '../..'

interface iChartProps{
  id: string
  val: IDadoGrafico[]
  val2: IDadoGrafico[]
}

export const GaugeChartComparativo: React.FC<iChartProps> = ({ id, val, val2 }) => {
  const valorOrdenado = val.sort((a, b) => parseFloat(b.media) - parseFloat(a.media))
  const valorComparativoOrdenado = val2.sort((a, b) => parseFloat(b.media) - parseFloat(a.media))
  const valoresJuntos = valorComparativoOrdenado.concat(valorOrdenado).sort((a, b) => Number(b.media) - Number(a.media))

  function ObterCor(value: IDadoGrafico[]): string[] {
    const arr: string[] = []
    value?.forEach(e => {
      if (Number(e.media) < 0) {
        arr.push('var(--Red)')
      } else {
        arr.push('var(--Azul)')
      }
    })

    return arr
  }
  function ObterValorPos(value: IDadoGrafico[]): string[] {
    const arr: string[] = []
    value?.forEach(e => arr.push(e.media))

    return arr
  }

  function GerarGrafico(): void {
    const options = {
      series: [{ data: ObterValorPos(valoresJuntos) }],
      grid: {
        show: false
      },
      chart: {
        type: 'bar',
        height: valoresJuntos.length * 80,
        width: 1000,
        stacked: true
      },
      annotations: {
        xaxis: [
          {
            x: 0,
            strokeDashArray: 0,
            borderColor: 'var(--c5)',
            fillColor: '#c2c2c2',
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      dataLabels: {
        enabled: true,
        position: 'start',
        offsetX: 55,
        offsetY: -20,
        formatter: function (value: any, series: any) {
          return valoresJuntos[series.dataPointIndex].media + ' - ' + valoresJuntos[series.dataPointIndex].nome
        },
        style: {
          colors: ['#000']
        }
      },
      colors: ObterCor(valoresJuntos),
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          barHeight: '20px',
          dataLabels: {
            position: 'bottom'
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function (value: any, series: any) {
              return valoresJuntos[series.dataPointIndex].nome + ' - '
            }
          },
          marker: {
            show: false
          }
        }
      },
      xaxis: {
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        max: 1.2,
        min: -1.2
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [val])

  return (
    <div id={id}></div>
  )
}
