import { Flex, Text, Textarea } from '@chakra-ui/react'
import { useState } from 'react'
import { Button } from '../../../../../../components/Button'
import { ModalBase } from '../../../../../../components/ModalBase'

interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
}

interface iModaProps{
  onRequestClose: () => void
  FinalizarAcao: (pdiId: string, acaoId: string, comentario: string) => void
  isOpen: boolean
  id: string
  acao?: iAcao
}

export const ModalFinalizarAcao: React.FC<iModaProps> = ({ isOpen, acao, id, onRequestClose, FinalizarAcao }) => {
  const [Comentario, setComentario] = useState('')
  return (
        <ModalBase Titulo='Você está prestes a finalizar essa ação...' isOpen={isOpen} onClose={onRequestClose} Width='60rem'>
            <Flex flexDir={'column'} padding={'1rem'}>
              <Text color={'var(--Azul)'} fontWeight='500' fontSize={'1.25rem'}>{acao?.assunto}</Text>
              <Flex gap={'2rem'} marginBottom={'1rem'}>
                <Text color={'var(--Azul)'} fontWeight='500' fontSize={'1.25rem'}>Data de inicio: {acao?.dataInicio}</Text>
                <Text color={'var(--Azul)'} fontWeight='500' fontSize={'1.25rem'}>Data de fim: {acao?.dataEntrega}</Text>
              </Flex>
              <Flex flexDir={'column'} marginBottom='1rem'>
                <Text color={'var(--Azul)'} fontWeight='700' fontSize={'1.5rem'}>{acao?.titulo}</Text>
                <Text color={'var(--Azul)'} fontWeight='400' fontSize={'1.125rem'}>{acao?.descricao}</Text>
              </Flex>
              <Flex flexDir={'column'}>
                <Text color={'var(--Gray2)'} fontWeight='500' fontSize={'1.25rem'}>Deixe um comentário sobre a conclusão desta ação</Text>
                <Textarea
                  placeholder='Digite o comentário aqui'
                  height={'10rem'}
                  borderColor='var(--Gray4)'
                  onChange={(e) => setComentario(e.target.value)}
                />
              </Flex>
            </Flex>
            <Flex padding={'1rem'} justifyContent={'end'} gap='1rem'>
              <Button onClick={onRequestClose} VarColor='c4'>Fechar</Button>
              <Button onClick={() => FinalizarAcao(id, acao?.id as string, Comentario)} VarColor='Green2'>Finalizar ação</Button>
            </Flex>
        </ModalBase>
  )
}
