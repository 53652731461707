import styled from 'styled-components'
import { Flex } from '../../../styles/styledGlobal'

export const WelcomeText = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 2rem;

span,h2{
    color: var(--a4);
}
`

export const SubtitleLg = styled.span<{marginBottom?: string}>`
margin-bottom:${props => props.marginBottom};
font-weight: 700;
font-size: 1.25rem;
color: var(--a3);
`

export const SubtitleMd = styled.span`
font-weight: 600;
color: var(--a4);
`

export const Main = styled.div`
display: flex;
flex-direction: column;
h2{
    color: var(--a4);
}
`

export const FlexBackBtn = styled(Flex)`
margin: 1rem 0rem;
height: 3rem;
`
