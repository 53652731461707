import styled from 'styled-components'

export const Container = styled.div<{width?: string}>`
display: flex;
padding: .2rem;
border-radius: 1rem;
background: var(--DegradeAzul);
width: 11.25rem;
text-align: center;

span{
    background-image: var(--DegradeAzul);
    background-clip: text;
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    color: black;
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 1.5rem;
}

svg{
    color: var(--DegradeAzul);
    margin-right: .5rem;
}
`

export const ContainerButton = styled.div`
display: flex;
background-color: white;
flex-direction: column;
border-radius: .8rem;
padding: 1rem;
align-items: center;
width: 100%;

&:hover{
    background-color: var(--DegradeAzul);
    cursor: pointer;
    transition: all .4s ease;
    span{
        color: white !important;
        background-image: white;
        -webkit-background-clip: text; 
        -webkit-text-fill-color: white;
        color: white;
    }
}
`
