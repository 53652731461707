import React from 'react'
import { TreeNode } from 'react-organizational-chart'
import { IDepartment } from '../../../../../../../../interfaces'
import { DepartmentCard } from '../DepartmentCard'

interface IChartNodeProps {
  departments: IDepartment[]
  onOpenModalAddEmploye: (id: string) => void
}

export const ChartNode: React.FC<IChartNodeProps> = ({ departments, onOpenModalAddEmploye }) => {
  return (
    <>
      {departments.map((department) =>
        !department.departamentoFilhos?.length
          ? (
          <TreeNode
            key={department.id}
            label={<DepartmentCard onOpenModalAddEmploye={onOpenModalAddEmploye} department={department} />}
          />
            )
          : (
          <TreeNode
            key={department.id}
            label={<DepartmentCard onOpenModalAddEmploye={onOpenModalAddEmploye} department={department} />}
          >
            <ChartNode onOpenModalAddEmploye={onOpenModalAddEmploye} departments={department.departamentoFilhos} />
          </TreeNode>
            )
      )}
    </>
  )
}
