/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { iNomeId, iEtapa } from '../../interfaces'
import { appApi } from '../../services/appApi'
import { ObjetoVazio, PropInvalida } from '../../Utils/Helper'
import { Button } from '../Button'
import { IsOffboarding } from '../TimeLineOnboard'
import { WhiteBox } from '../WhiteBox'
import { ContainerFlex, LabelTitle } from './styles'

interface iProps {
  onModelChange?: (form: iEtapa) => void
  onGreyAction?: () => void
  onGreenAction?: (etapa: iEtapa) => void
  keyWord: string
  EditEtapa?: iEtapa
  ignoreId?: string
  variant?: 'Box' | 'Etapa'
  isPdi?: boolean
}

export const CreateEtapaBox: React.FC<iProps> = ({ keyWord, EditEtapa, ignoreId, variant, isPdi, onModelChange, onGreyAction, onGreenAction }) => {
  const { id, processoId, trilhaId, etapaId } = useParams<{ id: string, trilhaId: string, processoId: string, etapaId?: string }>()
  const [InicioEtapa, setInicioEtapa] = useState('')
  const [Model, setModel] = useState<iEtapa>()
  const [Offboarding, setOffboarding] = useState(false)
  const [Etapas, setEtapas] = useState<iNomeId[]>([])
  const [Load, setLoad] = useState(false)
  const { pathname } = useLocation()
  function getEtapas(): void {
    const url = (trilhaId ?? id) ? `Etapa/Select/${trilhaId ?? id ?? ''}?etapaId=${etapaId ?? ''}` : processoId ? `EtapaColaborador/Select/${processoId}?etapaId=${etapaId ?? ''}` : ''
    appApi.get(url)
      .then(res => {
        setEtapas(res.data)
        setLoad(true)
      })
      .catch(err => console.log(err))
  }

  function Atualizar(etapa: iEtapa): void {
    let convert: iEtapa = etapa
    if (!Offboarding) {
      if (InicioEtapa === '1') {
        convert = {
          tempoDuracao: etapa.tempoDuracao,
          tempoInicio: etapa.tempoInicio
        }
      } else if (InicioEtapa === '0') {
        convert = {
          etapaPai: etapa.etapaPai,
          tempoDuracao: etapa.tempoDuracao
        }
      } else if (InicioEtapa === '2') {
        convert = {
          dataInicio: etapa.dataInicio,
          dataFim: etapa.dataFim,
          tempoDuracao: etapa.tempoDuracao
        }
      } else if (InicioEtapa === '3') {
        console.log('hehe')
      }
    } else {
      if (InicioEtapa === '1') {
        convert = {
          tempoDuracao: etapa.tempoDuracao,
          tempoAntesDesligamento: etapa.tempoAntesDesligamento
        }
      } else if (InicioEtapa === '2') {
        convert = {
          tempoDuracao: etapa.tempoDuracao,
          tempoAposDesligamento: etapa.tempoAposDesligamento
        }
      } else if (InicioEtapa === '0') {
        convert = {
          etapaPai: etapa.etapaPai
        }
      } else if (InicioEtapa === '3') {
        convert = {
          dataInicio: etapa.dataInicio,
          dataFim: etapa.dataFim
        }
      }
    }
    if (onModelChange !== undefined) {
      onModelChange(convert)
    } else {
      setModel(convert)
    }
  }

  useEffect(() => {
    if (!ObjetoVazio(EditEtapa) && Load) {
      if (!PropInvalida(EditEtapa?.tempoAntesDesligamento)) {
        setInicioEtapa('1')
      } else if (!PropInvalida(EditEtapa?.tempoAposDesligamento)) {
        setInicioEtapa('2')
      } else if (!PropInvalida(EditEtapa?.etapaPai)) {
        setInicioEtapa('0')
      } else if (!PropInvalida(EditEtapa?.tempoInicio)) {
        setInicioEtapa('1')
      }
      setModel(EditEtapa)
    }
  }, [Load, EditEtapa])

  useEffect(() => {
    getEtapas()
    IsOffboarding(!!trilhaId || !!id, id ?? trilhaId ?? processoId ?? '').then(res => setOffboarding(res))
  }, [])

  useEffect(() => {
    if (Etapas.length > 0) {
      setLoad(true)
    }
  }, [Etapas])

  if (variant === 'Etapa') {
    return (
      <Flex flexDir={'column'}>
        <LabelTitle>Como definir a data da {keyWord}?</LabelTitle>
        {!Offboarding && <Flex flexDir={'column'}>
          <RadioGroup onChange={setInicioEtapa} value={InicioEtapa}>
            <Stack direction='column'>
              {pathname.includes('Processo') && !pathname.includes('eNps') && (
                <>
                  <Radio borderColor={'var(--Gray4)'} value='2'>Data escolhida pelo calendário</Radio>
                  {InicioEtapa === '2' &&
                    <Flex flexDir={'column'} gap={'.5rem'} justifyContent={'center'}>
                      <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                        <FormLabel mb={'0'} marginRight={'1.785rem'} >{keyWord.includes('reunião') ? 'Data da ' : 'Início da '}{keyWord}</FormLabel>
                        <Input
                          width={'15rem'}
                          type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                          min={new Date().toISOString().slice(0, 16)}
                          value={Model?.dataInicio ?? ''}
                          onChange={(e) => { Atualizar({ ...Model, dataInicio: e.target.value }) }}
                          borderColor='var(--Gray4)'
                          isInvalid={false}
                        />
                      </FormControl>
                      {<FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                        <FormLabel mb={'0'} marginRight={'1.785rem'} >{keyWord.includes('reunião') ? 'Data máxima da ' : 'Fim da '}{keyWord}</FormLabel>
                        <Input
                          width={'15rem'}
                          type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                          min={Model?.dataInicio}
                          value={Model?.dataFim ?? ''}
                          onChange={(e) => { Atualizar({ ...Model, dataFim: e.target.value }) }}
                          borderColor='var(--Gray4)'
                          isInvalid={false}
                        />
                      </FormControl>
                      }
                    </Flex>}
                </>
              )}
              <Radio borderColor={'var(--Gray4)'} value='1'>Data definida após o inicio da trilha (exemplo: a cada 30 dias após o início da trilha)</Radio>
              {InicioEtapa === '1' && <div>
                <ContainerFlex>
                  <span>A partir de
                    <Input
                      margin={'0 .5rem'}
                      borderColor={'var(--Gray4)'}
                      type={'number'}
                      min={'1'}
                      placeholder='Digite quantos dias'
                      value={Model?.tempoInicio ?? ''}
                      onChange={(e) => { Atualizar({ ...Model, tempoInicio: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                    />
                    Dias de trilha</span>
                </ContainerFlex>
                <ContainerFlex>
                  <span>Máximo de
                    <Input
                      margin={'0 .5rem'}
                      borderColor={'var(--Gray4)'}
                      type={'number'}
                      min={'1'}
                      placeholder='Digite quantos dias'
                      value={Model?.tempoDuracao ?? ''}
                      onChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                    />
                    Dias de trilha</span>
                </ContainerFlex>
              </div>}
              <Radio borderColor={'var(--Gray4)'} value='0'>Após a conclusão de alguma etapa (exemplo: 5 dias após a pesquisa de stakeholders)</Radio>
              {InicioEtapa === '0' &&
                <Flex flexDir={'column'} gap={'.5rem'}>
                  <FormControl>
                    <FormLabel margin={0}>Selecione a etapa</FormLabel>
                    <Select borderColor={'var(--Gray4)'} value={Model?.etapaPai ?? ''} onChange={(e) => Atualizar({ ...Model, etapaPai: e.target.value })}>
                      <option value={''}>Nenhuma</option>
                      {
                        Etapas.filter(e => e.id !== ignoreId).map((e: iNomeId, i) => {
                          return (
                            <option key={i} value={e.id}>{e.nome}</option>
                          )
                        })
                      }
                    </Select>
                    {(!isPdi) &&
                    <Box display={'flex'} fontWeight={'700'} alignItems={'center'} gap={'.5rem'} my={'1rem'}>Máximo de
                      <Input
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        w={'14rem'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoDuracao ?? ''}
                        onChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                      />
                      dias após a liberação da etapa
                    </Box>
                    }
                  </FormControl>
                  {isPdi &&
                    <FormControl>
                      <FormLabel margin={'0'}>Prazo para subir PDI</FormLabel>
                      <Flex alignItems={'center'}>
                        <Input
                          margin={'0'}
                          width='15rem'
                          borderColor={'var(--Gray4)'}
                          type={'number'}
                          placeholder='Digite quantos dias'
                          value={Model?.tempoDuracao ?? ''}
                          onChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                        />
                        <Text ml={'.5rem'}>Dias</Text>
                      </Flex>
                    </FormControl>}
                </Flex>}
            </Stack>
          </RadioGroup>
        </Flex>
        }

        {Offboarding && <Flex flexDir={'column'}>
          <RadioGroup onChange={setInicioEtapa} value={InicioEtapa}>
            <Stack direction='column'>
              {pathname.includes('Processo') && (
                <>
                  <Radio borderColor={'var(--Gray4)'} value='3'>Data escolhida pelo calendário</Radio>
                  {InicioEtapa === '3' &&
                    (<Flex flexDir={'column'} gap={'.5rem'} justifyContent={'center'}>
                      <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                        <FormLabel mb={'0'} marginRight={'1.785rem'} >{keyWord.includes('reunião') ? 'Data da ' : 'Início da '}{keyWord}</FormLabel>
                        <Input
                          width={'15rem'}
                          type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                          min={new Date().toISOString().slice(0, 16)}
                          value={Model?.dataInicio ?? ''}
                          onChange={(e) => { Atualizar({ ...Model, dataInicio: e.target.value }) }}
                          borderColor='var(--Gray4)'
                          isInvalid={false}
                        />
                      </FormControl>
                      <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                        <FormLabel mb={'0'} marginRight={'1.785rem'} >{keyWord.includes('reunião') ? 'Data máxima da ' : 'Fim da '}{keyWord}</FormLabel>
                        <Input
                          width={'15rem'}
                          type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                          min={Model?.dataInicio}
                          value={Model?.dataFim ?? ''}
                          onChange={(e) => { Atualizar({ ...Model, dataFim: e.target.value }) }}
                          borderColor='var(--Gray4)'
                          isInvalid={false}
                        />
                      </FormControl>
                    </Flex>)}
                </>
              )}
              <Radio borderColor={'var(--Gray4)'} value='1'>Datas definidas antes do desligamento do colaborador (exemplo: 10 dias antes do desligamento)</Radio>
              {InicioEtapa === '1' &&
                <Flex flexDir={'column'} gap='.5rem'>
                  <ContainerFlex>
                    <span>
                      <Input
                        margin={'0 .5rem'}
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoAntesDesligamento ?? ''}
                        onChange={(e) => Atualizar({ ...Model, tempoAntesDesligamento: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) })}
                      />
                      antes do desligamento
                    </span>
                  </ContainerFlex>

                  <ContainerFlex>
                    <span>
                      <Input
                        margin={'0 .5rem'}
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoDuracao ?? ''}
                        onChange={(e) => Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) })}
                      />
                      de duração
                    </span>
                  </ContainerFlex>
                </Flex>
              }

              <Radio borderColor={'var(--Gray4)'} value='2'>Datas definidas após o desligamento do colaborador (exemplo: 15 dias após o desligamento)</Radio>
              {InicioEtapa === '2' &&
                <Flex flexDir={'column'} gap='.5rem'>
                  <ContainerFlex>
                    <span>
                      <Input
                        margin={'0 .5rem'}
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoAposDesligamento ?? ''}
                        onChange={(e) => Atualizar({ ...Model, tempoAposDesligamento: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) })}
                      />
                      após o desligamento
                    </span>
                  </ContainerFlex>

                  <ContainerFlex>
                    <span>
                      <Input
                        margin={'0 .5rem'}
                        borderColor={'var(--Gray4)'}
                        type={'number'}
                        placeholder='Digite quantos dias'
                        value={Model?.tempoDuracao ?? ''}
                        onChange={(e) => Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) })}
                      />
                      de duração
                    </span>
                  </ContainerFlex>
                </Flex>
              }

              <Radio borderColor={'var(--Gray4)'} value='0'>Após a conclusão de alguma etapa (exemplo: 5 dias após a pesquisa de stakeholders)</Radio>
              {InicioEtapa === '0' && <div>
                <h4>Selecione o período de liberação da pesquisa</h4>
                <Select borderColor={'var(--Gray4)'} value={Model?.etapaPai ?? ''} onChange={(e) => Atualizar({ ...Model, etapaPai: e.target.value })}>
                  <option value={''}>Selecione uma etapa</option>
                  {
                    Etapas.filter(e => e.id !== ignoreId).map((e: iNomeId, i) => {
                      return (
                        <option key={i} value={e.id}>{e.nome}</option>
                      )
                    })
                  }
                </Select>
                <Box display={'flex'} fontWeight={'700'} alignItems={'center'} gap={'.5rem'} my={'1rem'}>Máximo de
                  <Input
                    borderColor={'var(--Gray4)'}
                    type={'number'}
                    w={'14rem'}
                    placeholder='Digite quantos dias'
                    value={Model?.tempoDuracao ?? ''}
                    onChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                  />
                  dias após a liberação da etapa
                </Box>
              </div>}
            </Stack>
          </RadioGroup>
        </Flex>
        }
      </Flex>
    )
  } else {
    return (
      <>
        <WhiteBox>
          <Flex justifyContent={'space-between'} height={'25rem'} flexDir={'column'}>
            <Flex flexDir={'column'}>
              <LabelTitle>Como definir a data da {keyWord}?</LabelTitle>
              {!Offboarding && <Flex flexDir={'column'}>
                <RadioGroup onChange={setInicioEtapa} value={InicioEtapa}>
                  <Stack direction='column'>
                    {pathname.includes('Processo') && !pathname.includes('eNps') && (
                      <>
                        <Radio borderColor={'var(--Gray4)'} value='2'>Data escolhida pelo calendário</Radio>
                        {InicioEtapa === '2' &&
                          <Flex flexDir={'column'} gap={'.5rem'} justifyContent={'center'}>
                            <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                              <FormLabel marginRight={'1rem'} mb={'0'} >{keyWord.includes('reunião') ? 'Data da ' : 'Início da '}{keyWord}</FormLabel>
                              <Input
                                width={'15rem'}
                                type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                                min={new Date().toISOString().slice(0, 16)}
                                value={Model?.dataInicio ?? ''}
                                onChange={(e) => { Atualizar({ ...Model, dataInicio: e.target.value }) }}
                                borderColor='var(--Gray4)'
                                isInvalid={false}
                              />
                            </FormControl>
                            {
                              (
                                <FormControl alignItems={'center'} margin={'.2rem 0'} as={Flex} >
                                  <FormLabel mb={'0'} marginRight={'1.785rem'} >{keyWord.includes('reunião') ? 'Data máxima da ' : 'Fim da '}{keyWord}</FormLabel>
                                  <Input
                                    width={'15rem'}
                                    type={keyWord.includes('reunião') ? 'date' : 'datetime-local'}
                                    min={Model?.dataInicio}
                                    value={Model?.dataFim ?? ''}
                                    onChange={(e) => { Atualizar({ ...Model, dataFim: e.target.value }) }}
                                    borderColor='var(--Gray4)'
                                    isInvalid={false}
                                  />
                                </FormControl>
                              )
                            }
                          </Flex>}
                      </>
                    )}
                    <Radio value='1'>Data definida após o inicio da trilha (exemplo: a cada 30 dias após o início da trilha)</Radio>
                    {InicioEtapa === '1' && <div>
                      <ContainerFlex>
                        <span>A partir de
                          <Input
                            margin={'0 .5rem'}
                            borderColor={'var(--Gray4)'}
                            type={'number'}
                            min={'1'}
                            placeholder='Digite quantos dias'
                            value={Model?.tempoInicio ?? ''}
                            onChange={(e) => { Atualizar({ ...Model, tempoInicio: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                          />
                          Dias de trilha</span>
                      </ContainerFlex>
                      <ContainerFlex>
                        <span>Máximo de
                          <Input
                            margin={'0 .5rem'}
                            borderColor={'var(--Gray4)'}
                            type={'number'}
                            min={'1'}
                            placeholder='Digite quantos dias'
                            value={Model?.tempoDuracao ?? ''}
                            onChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                          />
                          Dias de trilha</span>
                      </ContainerFlex>
                    </div>}
                    <Radio value='0'>Após a conclusão de alguma etapa (exemplo: 5 dias após a pesquisa de stakeholders)</Radio>
                    {InicioEtapa === '0' && <div>
                      <h4>Selecione o período de liberação da pesquisa</h4>
                      <Select borderColor={'var(--Gray4)'} value={Model?.etapaPai ?? ''} onChange={(e) => Atualizar({ ...Model, etapaPai: e.target.value })}>
                        <option value={''}>Selecione uma etapa</option>
                        {
                          Etapas.filter(e => e.id !== ignoreId).map((e: iNomeId, i) => {
                            return (
                              <option key={i} value={e.id}>{e.nome}</option>
                            )
                          })
                        }
                      </Select>
                      <Box display={'flex'} fontWeight={'700'} alignItems={'center'} gap={'.5rem'} my={'1rem'}>Máximo de
                        <Input
                          borderColor={'var(--Gray4)'}
                          type={'number'}
                          w={'14rem'}
                          placeholder='Digite quantos dias'
                          value={Model?.tempoDuracao ?? ''}
                          onChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                        />
                        dias após a liberação da etapa</Box>
                    </div>}
                  </Stack>
                </RadioGroup>
              </Flex>
              }

              {Offboarding && <Flex flexDir={'column'}>
                <RadioGroup onChange={setInicioEtapa} value={InicioEtapa}>
                  <Stack direction='column'>
                    <Radio borderColor={'var(--Gray4)'} value='1'>Datas definidas antes do desligamento do colaborador (exemplo: 10 dias antes do desligamento)</Radio>
                    {InicioEtapa === '1' &&
                      <Flex flexDir={'column'} gap='.5rem'>
                        <ContainerFlex>
                          <span>
                            <Input
                              margin={'0 .5rem'}
                              borderColor={'var(--Gray4)'}
                              type={'number'}
                              placeholder='Digite quantos dias'
                              value={Model?.tempoAntesDesligamento ?? ''}
                              onChange={(e) => Atualizar({ ...Model, tempoAntesDesligamento: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) })}
                            />
                            antes do desligamento
                          </span>
                        </ContainerFlex>

                        <ContainerFlex>
                          <span>
                            <Input
                              margin={'0 .5rem'}
                              borderColor={'var(--Gray4)'}
                              type={'number'}
                              placeholder='Digite quantos dias'
                              value={Model?.tempoDuracao ?? ''}
                              onChange={(e) => Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) })}
                            />
                            de duração
                          </span>
                        </ContainerFlex>
                      </Flex>
                    }

                    <Radio borderColor={'var(--Gray4)'} value='2'>Datas definidas após o desligamento do colaborador (exemplo: 15 dias após o desligamento)</Radio>
                    {InicioEtapa === '2' &&
                      <Flex flexDir={'column'} gap='.5rem'>
                        <ContainerFlex>
                          <span>
                            <Input
                              margin={'0 .5rem'}
                              borderColor={'var(--Gray4)'}
                              type={'number'}
                              placeholder='Digite quantos dias'
                              value={Model?.tempoAposDesligamento ?? ''}
                              onChange={(e) => Atualizar({ ...Model, tempoAposDesligamento: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) })}
                            />
                            após o desligamento
                          </span>
                        </ContainerFlex>

                        <ContainerFlex>
                          <span>
                            <Input
                              margin={'0 .5rem'}
                              borderColor={'var(--Gray4)'}
                              type={'number'}
                              placeholder='Digite quantos dias'
                              value={Model?.tempoDuracao ?? ''}
                              onChange={(e) => Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) })}
                            />
                            de duração
                          </span>
                        </ContainerFlex>
                      </Flex>
                    }

                    <Radio borderColor={'var(--Gray4)'} value='0'>Após a conclusão de alguma etapa (exemplo: 5 dias após a pesquisa de stakeholders)</Radio>
                    {InicioEtapa === '0' && <div>
                      <h4>Selecione o período de liberação da pesquisa</h4>
                      <Select borderColor={'var(--Gray4)'} value={Model?.etapaPai ?? ''} onChange={(e) => Atualizar({ ...Model, etapaPai: e.target.value })}>
                        <option value={''}>Selecione uma etapa</option>
                        {
                          Etapas.filter(e => e.id !== ignoreId).map((e: iNomeId, i) => {
                            return (
                              <option key={i} value={e.id}>{e.nome}</option>
                            )
                          })
                        }
                      </Select>
                      <Box display={'flex'} fontWeight={'700'} alignItems={'center'} gap={'.5rem'} my={'1rem'}>Máximo de
                        <Input
                          borderColor={'var(--Gray4)'}
                          type={'number'}
                          w={'14rem'}
                          placeholder='Digite quantos dias'
                          value={Model?.tempoDuracao ?? ''}
                          onChange={(e) => { Atualizar({ ...Model, tempoDuracao: isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value) }) }}
                        />
                        dias após a liberação da etapa
                      </Box>
                    </div>}
                  </Stack>
                </RadioGroup>
              </Flex>
              }
            </Flex>
          </Flex>
        </WhiteBox>
        <Flex marginTop={'1rem'} justifyContent={'end'} gap={'1rem'}>
          <Button size='lg' onClick={onGreyAction} VarColor='c6'>Voltar</Button>
          <Button size='lg' VarColor='v2' onClick={() => onGreenAction !== undefined ? onGreenAction(Model ?? {}) : ''}>{EditEtapa !== undefined ? 'Atualizar' : 'Criar'} Etapa</Button>
        </Flex>
      </>
    )
  }
}
