/* eslint-disable @typescript-eslint/consistent-type-assertions */
import Modal from 'react-modal'
import { Avatar, Box, Checkbox, Flex, FormControl, FormLabel, Input, Tooltip, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { IEmployee, IJobTitle, iNomeId, iPutColaborador } from '../../interfaces'
import { appApi, SigahApi } from '../../services/appApi'
import { TagContainer } from '../TagContainer'
import { TextRadio } from '../TextRadio'
import {
  DashedBox, ModalBody,
  ModalContent, ModalFooter, ModalHeader, TitleTipoUser
} from './styles'
import { FindUser } from '../FindBox'
import { MdClose } from 'react-icons/md'
import { CompleteTagContainer } from '../CompleteTagContainer'
import { InputFormControl } from '../InputFormControl'
import { FaBriefcase, FaPlus, FaTimes } from 'react-icons/fa'
import { CreateCargo } from '../createCargo'
import { RiSendPlaneFill } from 'react-icons/ri'
import { Button } from '../Button'
import { Column } from '../../styles/styledGlobal'
import { MetaButton } from '../Buttons/MetaButton'

Modal.setAppElement('#root')

interface ICreateEmployeeModalProps {
  isOpen: boolean
  colaborador?: IEmployee
  onRequestClose: () => void
  onUpdateEmployee: (upEmployee: iPutColaborador) => void
  ReenviarPrimeiroAcesso: () => void
}

export const UpdateColaboradorModal: React.FC<ICreateEmployeeModalProps> = ({
  isOpen,
  colaborador,
  onRequestClose,
  onUpdateEmployee,
  ReenviarPrimeiroAcesso
}) => {
  const toast = useToast()

  const [Adicionar, setAdicionar] = useState(false)
  const [NomeIsInValid, setNomeIsInValid] = useState(false)
  const [CPFIsInValid, setCPFIsInValid] = useState(false)
  const [EmailIsInValid, setEmailIsInValid] = useState(false)
  const [EmailExist, setEmailExist] = useState(false)
  const [CpfExist, setCpfExist] = useState(false)
  const [DataIngressoIsInValid, setDataIngressoIsInValid] = useState(false)

  const [Departamentos, setDepartamentos] = useState<iNomeId[]>([])
  const [Cargos, setCargos] = useState<iNomeId[]>([])
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])

  const [TagsSelecionadas, setTagsSelecionadas] = useState<string[]>([])
  const [ModalPrimeiroAcessoIsOpen, setModalPrimeiroAcessoIsOpen] = useState(false)
  const [Model, setModel] = useState<iPutColaborador>({
    id: '',
    nome: '',
    sobrenome: '',
    email: '',
    cpf: '',
    celular: '',
    departamentoId: '',
    dataIngresso: '',
    tipoUsuario: 0,
    administrador: false,
    cargoId: ''
  })
  const [NovaFuncao, setNovaFuncao] = useState(false)

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    if (Model.avatar !== undefined) {
      appApi.post(`Colaborador/uploadfile?url=${Model.avatar}`, formdata).then(resposnse => {
        setModel({ ...Model, avatar: resposnse.data.uri })
      }).catch(e => console.log(e))
    } else {
      appApi.post('Colaborador/uploadfile', formdata).then(resposnse => {
        setModel({ ...Model, avatar: resposnse.data.uri })
      }).catch(e => console.log(e))
    }
  }

  function getDepartamentos(): void {
    appApi.get('Departamento')
      .then(res => setDepartamentos(res.data))
      .catch(err => console.log(err))
  }

  function getCargos(): void {
    appApi.get('Cargo')
      .then(res => setCargos(res.data))
      .catch(err => console.log(err))
  }

  function getColaboradores(id: string): void {
    appApi.get(`Colaborador/Select/Gestor/?departamentoId=${id}`)
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function addTag(tag: string): void {
    setTagsSelecionadas(oldArray => [...oldArray, tag])
  }

  function removeTag(id: string): void {
    setTagsSelecionadas(TagsSelecionadas.filter(r => r !== id))
  }

  function SelecionarGestor(id: string): void {
    setModel({ ...Model, gerenteId: id })
  }

  function SelecionarCargo(id: string): void {
    setAdicionar(true)
    setModel({ ...Model, cargoId: id })
  }

  function SelecionarArea(id: string): void {
    setModel({ ...Model, departamentoId: id })
  }

  function onCreateCargo(obj: IJobTitle): void {
    const form = {
      nome: obj.nome
    }
    appApi.post('Cargo', form).then(() => {
      getCargos()
      setNovaFuncao(false)
      toast({
        title: 'Cargo criado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
    }).catch(err => {
      toast({
        title: 'Não foi possível criar o cargo',
        description: 'Tente novamente',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      console.log(err)
    }
    )
  }

  function VerificarPreenchimento(): boolean {
    setNomeIsInValid(false)
    setCPFIsInValid(false)
    setEmailIsInValid(false)
    setDataIngressoIsInValid(false)
    if (Model.nome === '') {
      setNomeIsInValid(true)
      toast({
        title: 'Nome não foi informado',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    if (Model.email === '') {
      setEmailIsInValid(true)
      toast({
        title: 'E-mail não foi informado',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    if (Model.cpf === '') {
      setCPFIsInValid(true)
      toast({
        title: 'CPF não foi informado',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (Model.dataIngresso === '') {
      setDataIngressoIsInValid(true)
      toast({
        title: 'Data de admissão não foi informada',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (EmailExist) {
      setEmailIsInValid(true)
      toast({
        title: 'E-mail já cadastrado. Cadastre um novo e-mail ou edite o colaborador já cadastrado.',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (CpfExist) {
      setCPFIsInValid(true)
      toast({
        title: 'CPF já cadastrado. Verifique o colaborador e procure o administrador caso necessário.',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    return true
  }

  function InternalOnUpdate(): void {
    if (VerificarPreenchimento()) {
      onUpdateEmployee({
        id: colaborador?.id ?? '',
        email: Model.email.toLowerCase(),
        nome: Model.nome,
        sobrenome: Model.sobrenome,
        avatar: Model.avatar,
        departamentoId: Model.departamentoId === '' ? Departamentos[0].id : Model.departamentoId,
        cargoId: Model.cargoId === '' ? Cargos[0].id : Model.cargoId,
        gerenteId: Model.gerenteId && Model.gerenteId !== '' ? Model.gerenteId : undefined,
        celular: Model.celular,
        cpf: Model.cpf.replace('.', '').replace('-', ''),
        dataIngresso: Model.dataIngresso,
        tipoUsuario: Model.tipoUsuario,
        tags: TagsSelecionadas,
        administrador: Model.administrador
      })
    }
  }

  function VerificarEmail(): void {
    setEmailExist(false)
    setEmailIsInValid(false)
    SigahApi.get(`api/Onboard/Usuario/Email?email=${Model.email.toLowerCase()}`)
      .then(res => {
        setEmailExist(true)
        setEmailIsInValid(true)
      })
      .catch(err => console.log(err))
  }

  function VerificarCPF(): void {
    setCpfExist(false)
    setCPFIsInValid(false)
    SigahApi.get(`api/Onboard/Usuario/Cpf?Cpf=${Model.cpf}`)
      .then(res => {
        setCpfExist(true)
        setCPFIsInValid(true)
      })
      .catch(err => console.log(err))
  }

  function onClosePrimeiroAcesso(): void {
    setModalPrimeiroAcessoIsOpen(false)
  }

  function onOpenPrimeiroAcesso(): void {
    setModalPrimeiroAcessoIsOpen(true)
  }

  useEffect(() => {
    getDepartamentos()
    getCargos()
  }, [])

  useEffect(() => {
    if (colaborador) {
      getColaboradores(colaborador.departamento?.id ?? '')
      setModel({
        dataIngresso: colaborador.dataIngresso,
        celular: colaborador.celular,
        cpf: colaborador.cpf,
        sobrenome: colaborador.sobrenome,
        tipoUsuario: colaborador.tipoUsuario,
        administrador: colaborador.administrador,
        departamentoId: colaborador.departamentoId ?? '',
        cargoId: colaborador.cargoId ?? '',
        email: colaborador.email,
        id: colaborador.id,
        nome: colaborador.nome,
        avatar: colaborador.avatar,
        gerenteId: colaborador.gerenteId
      })
      setTagsSelecionadas(colaborador.tags ?? [])
    }
  }, [colaborador])

  const handleRadioTipoUsuarioClick = (e: React.ChangeEvent<HTMLInputElement>): void => setModel({ ...Model, tipoUsuario: parseInt(e.currentTarget.value) })
  const isRadioTipoUsuarioelected = (value: string): boolean => Model.tipoUsuario === parseInt(value)

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-scroll"
    >
      {(!ModalPrimeiroAcessoIsOpen) && (
        <ModalContent>
          <Flex justifyContent={'end'}>
          <MdClose cursor={'pointer'} size={24} onClick={onRequestClose} />
        </Flex>
        <ModalHeader>
          <h2>Atualizar colaborador</h2>
          <DashedBox htmlFor='m-file'>
            <Avatar src={Model.avatar} />
            <small>Clique para selecionar ou arraste e solte uma imagem aqui</small>
            <input id='m-file' onChange={(e) => { AtualizarArquivo(e) }} type={'file'} />
          </DashedBox>
        </ModalHeader>
        <ModalBody>
          <InputFormControl
            label='Nome'
            isInvalid={NomeIsInValid}
            onChange={(e: any) => setModel({ ...Model, nome: e.target.value })}
            placeholder='Digite o nome'
            value={Model.nome}
          />

          <InputFormControl
            label='Sobrenome'
            onChange={(e: any) => setModel({ ...Model, sobrenome: e.target.value })}
            placeholder='Digite o sobrenome'
            value={Model.sobrenome}
          />

          <InputFormControl
            label='E-mail'
            isInvalid={EmailIsInValid}
            onBlur={() => Model.email && Model.email !== colaborador?.email && VerificarEmail()}
            onChange={(e: any) => setModel({ ...Model, email: e.target.value })}
            placeholder='Digite o e-mail'
            value={Model.email}
            error={EmailExist}
            errorMessage='O email já foi cadastrado'
          />

          <InputFormControl
            label='CPF'
            isInvalid={CPFIsInValid}
            onChange={(e: any) => setModel({ ...Model, cpf: e.target.value })}
            onBlur={() => Model.cpf && Model.cpf !== colaborador?.cpf && VerificarCPF()}
            placeholder='Digite o CPF'
            error={CpfExist}
            value={Model.cpf}
            errorMessage='O CPF já foi cadastrado'
          />

          <InputFormControl
            label='Celular'
            value={Model.celular}
            onChange={(e: any) => setModel({ ...Model, celular: e.target.value })}
            placeholder='Digite o celular'
          />

          {
            Cargos && (
              <Flex flexDir={'column'}>
                <Flex>
                <FormLabel fontSize={'1.3rem'} marginBottom='0.25rem' >Função
                </FormLabel>

                <Button ml={'1rem'} onClick={() => setNovaFuncao(!NovaFuncao)}
                leftIcon={NovaFuncao ? <FaTimes/> : <FaPlus/>} size={'xs'} backgroundColor={NovaFuncao ? '#ea2010' : '#1aa0bb'}>{NovaFuncao ? 'Cancelar' : 'Nova função'}</Button>
                </Flex>
                {!NovaFuncao && (<FindUser
                  lista={Cargos}
                  onChoice={SelecionarCargo}
                  placeholder='Digite o nome da função'
                  isNotUser
                  adicionar={Adicionar}
                  leftIcon={<FaBriefcase size={'1.3rem'}/>}
                  selecionado={Model.cargoId}
                />)}
                {NovaFuncao && (<CreateCargo
                 onCreateJobTitle={onCreateCargo}
                 />)}
              </Flex>
            )}
            {
            Departamentos && (
              <Flex flexDir={'column'}>
                <Flex>
                <FormLabel fontSize={'1.3rem'} marginBottom='0.25rem' >Área
                </FormLabel>

                {/* <Button ml={'1rem'} onClick={() => setNovaFuncao(!NovaFuncao)}
                leftIcon={NovaFuncao ? <FaTimes/> : <FaPlus/>} size={'xs'} backgroundColor={NovaFuncao ? '#ea2010' : '#1aa0bb'}>{NovaFuncao ? 'Cancelar' : 'Nova função'}</Button> */}
                </Flex>
                <FindUser
                  lista={Departamentos}
                  onChoice={SelecionarArea}
                  placeholder='Digite o nome da área'
                  isNotUser
                  leftIcon={<FaBriefcase size={'1.3rem'}/>}
                  selecionado={Model.departamentoId}
                />
                {/* {NovaFuncao && (<CreateCargo
                 onCreateJobTitle={onCreateCargo}
                 />)} */}
              </Flex>
            )}

          {
            (Colaboradores) && (
              <Flex flexDir={'column'}>
                <FormLabel fontSize={'1.125rrem'} marginBottom='0.25rem'>Gestor</FormLabel>
                <FindUser
                  lista={Colaboradores.filter(e => e.id !== Model.id)}
                  onChoice={SelecionarGestor}
                  placeholder='Digite o nome do gestor'
                  selecionado={Model.gerenteId}
                />
              </Flex>
            )
          }

          <TagContainer
            isGrey
            addTag={addTag}
            removeTag={removeTag}
            Selecionadas={TagsSelecionadas}
            tipo={0}
          />

          <FormControl>
            <FormLabel fontSize={'xl'}>Data de admissão</FormLabel>
            <Input
              width={'15rem'}
              isInvalid={DataIngressoIsInValid}
              type={'date'}
              borderColor={'var(--Gray4)'}
              onChange={(e) => setModel({ ...Model, dataIngresso: e.target.value })}
              value={Model.dataIngresso.split('T')[0]}
            />
          </FormControl>

          <Flex flexDir={'column'}>
            <TitleTipoUser>Nível de usuário</TitleTipoUser>
            <Flex flexDir={'column'} marginLeft={'.5rem'}>

              <TextRadio
                label='Gestor da área'
                name='lb-gestorarea'
                checked={isRadioTipoUsuarioelected('2')}
                onChange={handleRadioTipoUsuarioClick}
                value='2'
              />

              <TextRadio
                label='Gestor'
                name='lb-gestor'
                checked={isRadioTipoUsuarioelected('1')}
                onChange={handleRadioTipoUsuarioClick}
                value='1'
              />

              <TextRadio
                label='Colaborador'
                name='lb-colab'
                checked={isRadioTipoUsuarioelected('0')}
                onChange={handleRadioTipoUsuarioClick}
                value='0'
              />
            </Flex>
          </Flex>

          <FormControl marginBottom={'1rem'}>
            <FormLabel>Squads</FormLabel>
            <TagContainer
              isGrey
              addTag={addTag}
              removeTag={removeTag}
              Selecionadas={TagsSelecionadas}
              tipo={2}
              placeholder={'Digite o nome do squad'}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Papéis</FormLabel>
            <CompleteTagContainer
              isGrey
              addTag={addTag}
              removeTag={removeTag}
              selecionadas={TagsSelecionadas}
            />
          </FormControl>

          <Flex alignItems={'center'} marginTop={'1rem'}>
            <Checkbox borderColor={'var(--Gray4)'} size='lg' onChange={(e) => setModel({ ...Model, administrador: e.target.checked })} isChecked={Model.administrador} marginRight={'1rem'} />
            <FormLabel margin={0}>Marque para dar permissão de administrador ao usuário</FormLabel>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Tooltip placement='top' label={colaborador?.primeiroAcesso ? 'Reenviar email de primeiro acesso' : 'Enviar email de primeiro acesso'} hasArrow bg={colaborador?.primeiroAcesso ? 'var(--Gray3)' : 'var(--Azul)'} color={'white'} p={'.5rem'} mr={'.4rem'}>
            <Box>
              <Button VarColor={colaborador?.primeiroAcesso ? 'Gray3' : 'Azul' } p={'0 1rem'} mr={'.7rem'} isDisabled={Date.parse(Model.dataIngresso) > new Date().getTime()} onClick={onOpenPrimeiroAcesso}><RiSendPlaneFill />
              </Button>
            </Box>
          </Tooltip>
          <Button VarColor='v2' onClick={InternalOnUpdate}>Atualizar colaborador</Button>
        </ModalFooter>
      </ModalContent>
      )}
      {(ModalPrimeiroAcessoIsOpen) && (
        <ModalContent>
          <Flex justifyContent={'space-between'} mb={'1rem'}>
            <h2 style={{ color: 'var(--secundario5)' }}>Atenção!</h2>
            <MdClose size={24} onClick={onRequestClose} />
          </Flex>
            <Column style={{ gap: '.5rem', width: '25.5rem' }}>
              <strong>Ao reenviar o primeiro acesso a senha do colaborador será resetada novamente. Deseja reenviar mesmo assim?</strong>
              <Flex justifyContent={'end'} gap={'1rem'} mt={'.5rem'}>
                  <MetaButton bg='c5' size='md' onClick={onClosePrimeiroAcesso}>Cancelar</MetaButton>
                  <MetaButton bg='v2' size='md' onClick={() => { ReenviarPrimeiroAcesso(); setModalPrimeiroAcessoIsOpen(false) }}>Continuar</MetaButton>
              </Flex>
          </Column>
        </ModalContent>
      )}
    </Modal>
  )
}
