import { Flex, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react'
import { useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { iConfiguracaoEixo } from '../../../../interfaces'
import { Button } from '../../../Button'
import { ModalBase } from '../../../ModalBase'

interface iProps {
  eixo?: iConfiguracaoEixo
  tipo: string
  isOpen: boolean
  onClose: () => void
  onUpdate: (eixo: iConfiguracaoEixo) => void
}

export const ModalTextoEixo: React.FC<iProps> = ({ isOpen, onClose, onUpdate, eixo, tipo }) => {
  const [Model, setModel] = useState<iConfiguracaoEixo>(eixo ?? {
    descricao: '',
    titulo: ''
  })
  return (
    <ModalBase Width='43rem' Titulo={`Eixo ${tipo}`} isOpen={isOpen} onClose={onClose}>
      <Flex gap={'1rem'} flexDir={'column'} padding='0 1rem 1rem 1rem'>
        <FormControl>
          <FormLabel>Título</FormLabel>
          <Input type="text" value={Model.titulo} onChange={(e) => setModel({ ...Model, titulo: e.target.value })} />
        </FormControl>
        <FormControl>
          <FormLabel>Descrição</FormLabel>
          <Textarea height={'8.25rem'} value={Model.descricao} onChange={(e) => setModel({ ...Model, descricao: e.target.value })} />
        </FormControl>
        <Flex gap={'1rem'} justifyContent={'flex-end'}>
          <Button w={'8.5rem'} mr={'1rem'} backgroundColor={'var(--Gray4)'} onClick={onClose}>Cancelar</Button>
          <Button leftIcon={<FaSave />} w={'8.5rem'} backgroundColor={'var(--Green2)'} onClick={() => onUpdate(Model)}>Salvar</Button>
        </Flex>
      </Flex>
    </ModalBase>
  )
}
