import styled, { css } from 'styled-components'
import { Column, Flex } from '../../../../../styles/styledGlobal'

export const ColumnText = styled(Column)`
justify-content: start;
span,h4{
    color: var(--a5);
}

span{
font-weight: 400;
font-size: 0.875rem;
}
`

export const PreViewParticipante = styled.div`
display: flex;
background: var(--c2);
border-radius: 0.625rem;
height: 6rem;
`

export const RadiusAmarelo = styled.div`
 background:var(--terc5);
 width: 1rem;
 height: 100%;
 border-top-left-radius:0.625rem ;
 border-bottom-left-radius:0.625rem ;
 margin-right: .5rem;
`

export const BackgroundCollapse = styled.div<{active?: boolean}>`
background: var(--c1);
border-radius: 0.625rem;
box-shadow: var(--SombraBtns);
overflow-y: hidden;
max-height: 6rem;

${props => props.active && css`
    transition: max-height 7.2s;
    max-height: 800rem;
`}
`

export const SectionAssociados = styled.div`
border-radius: 0.938rem;
width: 100%;
border: 0.125rem solid var(--c5);
box-sizing: border-box;
padding: 1rem;
margin-bottom: 1rem;
`

export const HeaderSection = styled(Flex)`
justify-content: space-between;
align-items: center;
margin-bottom: 1rem;

h3{
    color: var(--a5);
}
`

export const SectionBody = styled.div`
display: grid;
grid-template-columns: repeat(4,1fr);
gap: 2rem 1rem;
`

export const CardBody = styled.div<{column: number}>`
display: grid;
grid-template-columns: repeat(${props => props.column},1fr);
gap: 0rem 1rem;
width: 100%;

& > div{
display: flex;
align-items: center;
}
`
