import { Avatar, Box, Flex, Grid, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text, useToast } from '@chakra-ui/react'
import { FaSave } from 'react-icons/fa'
import { Button } from '../../../../../components/Button'
import { useEffect, useState } from 'react'
import { NineBox } from '../../../../../components/NineBox'

import { ModalAlterarQuadrante } from '../ModalAlterarQuadrante'
import { iNineBoxAvaliacao, iPerfilColaborador } from '../../../../../interfaces'
import { PropInvalida } from '../../../../../Utils/Helper'
import { appApi } from '../../../../../services/appApi'
import { parseJwt } from '../../../../../services/token'

interface iProps {
  model: iNineBoxAvaliacao
  colaborador: iPerfilColaborador
  reload: () => void
}

interface iNota {
  notaY: number
  notaX: number
}

export interface iPostAvliacao {
  colaboradorId: string
  boxId: string
  notaY: number
  notaX: number
  quandranteId: string
  comite: string[]
  comentario?: string
}

export const TabNineBox: React.FC<iProps> = ({ model, colaborador, reload }) => {
  const toast = useToast()
  const isAdm = parseJwt().adm === 'True'
  const [NomeQuadrante, setNomeQuadrante] = useState<string>()
  const [IsOpenAlterarQuadrante, setIsOpenAlterarQuadrante] = useState(false)
  const [Notas, setNotas] = useState<iNota>({
    notaX: 0,
    notaY: 0
  })

  const [NotaXInconentente, setNotaXInconentente] = useState(false)
  const [NotaYInconentente, setNotaYInconentente] = useState(false)

  const [VisualizarTudo, setVisualizarTudo] = useState(false)

  function onOpenAlterarQuadrante(): void {
    setIsOpenAlterarQuadrante(true)
  }

  function onCloseAlterarQuadrante(): void {
    setIsOpenAlterarQuadrante(false)
  }

  function SelecionarQuadrante(nome: string, notaX?: number, notaY?: number): void {
    setNomeQuadrante(nome)
    VerificarIncoerencia(nome, notaX, notaY)
  }

  function VerificarIncoerencia(nome: string, notaX?: number, notaY?: number): void {
    setNotaXInconentente(false)
    setNotaYInconentente(false)
    const quadrante = model.nineBox.quadrantes.find(e => e.titulo === nome)
    if (quadrante) {
      const configx = model.nineBox.eixosX.find(e => e.id === quadrante.eixoX)
      const configy = model.nineBox.eixosY.find(e => e.id === quadrante.eixoY)
      if (notaX && notaY && configx && configy) {
        if (!(notaX >= configx?.rangeMinimo && notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(notaY >= configy?.rangeMinimo && notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      } else if (configx && configy) {
        if (!(Notas.notaX >= configx?.rangeMinimo && Notas.notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(Notas.notaY >= configy?.rangeMinimo && Notas.notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      }
    }
  }

  function AlterarNota(notas: iNota): void {
    setNotas(notas)
    const configY = model.nineBox.eixosY.find(r => notas.notaY >= r.rangeMinimo && notas.notaY <= r.rangeMaximo)
    const configX = model.nineBox.eixosX.find(r => notas.notaX >= r.rangeMinimo && notas.notaX <= r.rangeMaximo)
    if (configY && configX) {
      const quadrante = model.nineBox.quadrantes.find(r => r.eixoX === configX.id && r.eixoY === configY.id)
      if (quadrante) {
        setNomeQuadrante(quadrante.titulo)
        if (NotaXInconentente || NotaYInconentente) {
          VerificarIncoerencia(quadrante.titulo)
        }
      }
    }
  }

  function AvaliarColaborador(comite: string[], comentario?: string): void {
    const form: iPostAvliacao = {
      boxId: model.nineBox.id,
      colaboradorId: colaborador.id,
      comentario: comentario,
      comite: comite,
      notaX: Notas.notaX,
      notaY: Notas.notaY,
      quandranteId: model.nineBox.quadrantes.find(e => e.titulo === NomeQuadrante)?.id ?? ''
    }

    appApi.post('Colaborador/NineBox', form)
      .then(() => {
        if (PropInvalida(colaborador?.avaliacaoNineBox)) {
          toast({
            title: 'Avaliação computada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          toast({
            title: 'Avaliação alterada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
        reload()
        onCloseAlterarQuadrante()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (!PropInvalida(colaborador.avaliacaoNineBox)) {
      setNotas({
        notaX: colaborador.avaliacaoNineBox?.notaX ?? 0,
        notaY: colaborador.avaliacaoNineBox?.notaY ?? 0
      })
      SelecionarQuadrante(colaborador?.avaliacaoNineBox?.tituloQuadrante ?? '', colaborador?.avaliacaoNineBox?.notaX, colaborador?.avaliacaoNineBox?.notaY)
    }
  }, [colaborador])

  return (
    <Flex flexDir={'column'}>
      <ModalAlterarQuadrante
        comiteAtivo={model.comiteAtivo}
        onClose={onCloseAlterarQuadrante}
        isOpen={IsOpenAlterarQuadrante}
        colaborador={colaborador}
        onAction={AvaliarColaborador}
      />
      <Box as='h1'>Definição de quadrante</Box>
      <small style={{ color: 'var(--c7)' }}>Aqui você pode mudar o quadrante do colaborador manualmente sem considerar a definição por sua nota</small>
      <Flex my={'2rem'} gap={'1rem'}>
        <Flex flexDirection={'column'}>
          <Box mb={'.5rem'} fontWeight='500' color={NotaYInconentente ? 'var(--Red)' : ''}>
            {model.nineBox.configuracaoEixoY.titulo}
          </Box>
          <NumberInput
            isInvalid={NotaYInconentente}
            onChange={(e) => AlterarNota({ ...Notas, notaY: parseFloat(e) })}
            value={Notas.notaY}
            width={'5.9rem'}
            isDisabled={!isAdm}
            min={0}
            max={200}
            step={0.1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Flex flexDirection={'column'}>
          <Box mb={'.5rem'} color={NotaXInconentente ? 'var(--Red)' : ''} fontWeight='500'>
            {model.nineBox.configuracaoEixoX.titulo}
          </Box>
          <NumberInput
            isInvalid={NotaXInconentente}
            onChange={(e) => AlterarNota({ ...Notas, notaX: parseFloat(e) })}
            width={'5.9rem'}
            isDisabled={!isAdm}
            value={Notas.notaX}
            min={0}
            max={200}
            step={0.1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Flex>
      <NineBox variant='avaliar' model={model.nineBox}
        quadranteSelecionado={NomeQuadrante}
        selecionarQuadrante={SelecionarQuadrante}
      />

      <Flex gap={'1rem'} flexDir={'column'} mb='1rem'>
        {model.comites.slice(0, VisualizarTudo ? model.comites.length : 2).map((r, i) => {
          return (
            <Flex flexDir={'column'} borderRadius={'1rem'} border={'1px solid var(--Gray4)'} padding='1rem' key={i}>
              <Text color={'var(--Gray1)'} fontWeight={'bold'} fontSize={'0.75rem'}>Alterado por {r.responsavel.nome} em {r.dataAvaliacao}</Text>
              {PropInvalida(r.avaliacaoAnterior) && (
                    <Text
                      color={'var(--Gray1)'}
                      fontWeight={'bold'}
                      fontSize={'0.75rem'}
                    >Avaliou no quadrante {r.avaliacaoAtual}
                    </Text>
              )}

              {!PropInvalida(r.avaliacaoAnterior) &&
                <Text
                  color={'var(--Gray1)'}
                  fontWeight={'bold'}
                  fontSize={'0.75rem'}
                >Alterou de {r.avaliacaoAnterior} para {r.avaliacaoAtual}
                </Text>
              }
              <Flex flexDir={'column'} my={'.5rem'} padding={'1rem'} bg='var(--Gray6)'>
                <Text fontWeight={'700'} fontSize={'0.875rem'} color={'var(--Gray1)'}>Comentário</Text>
                <Text>{r.comentario}</Text>
              </Flex>

              <Text mb={'.5rem'} fontWeight={'700'}>Comitê que realizou a calibração</Text>
              <Grid gap={4} templateColumns='repeat(3, 1fr)'>
                {r.comite.map((c, i2) => {
                  return (
                    <Flex gap={'1rem'} key={i2}>
                      <Avatar size={'sm'} src={c.avatar} name={c.nome} />
                      <Flex flexDir={'column'}>
                        <Text>{c.nome}</Text>
                        <Text>{c.complemento}</Text>
                      </Flex>
                    </Flex>
                  )
                })}
              </Grid>
            </Flex>
          )
        })}
      </Flex>
      {!VisualizarTudo && model.comites.length > 2 &&
        <Text cursor={'pointer'} onClick={() => setVisualizarTudo(true)} fontWeight={'700'} textDecoration='underline' color={'var(--Azul)'}>Mostrar alterações anteriores </Text>
      }
      {parseJwt().adm === 'True' &&
        <Flex justifyContent={'flex-end'} gap={'1rem'} mb={'2rem'}>
          <Button
            VarColor='Green2'
            leftIcon={<FaSave />}
            onClick={onOpenAlterarQuadrante}
          >Salvar</Button>
        </Flex>}
    </Flex>
  )
}
