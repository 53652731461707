import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { iItemIntegracao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { ModalSucess } from '../Components/ModalSucess'
import { PDFImersao } from './Components/Pdf'
import { TextoImersao } from './Components/Texto'
import { VideoImersao } from './Components/Video'
import { FlexCenter, Main, NavElipse } from './styles'

type navProps ={
  interacaoId: string
  etapaId: string
}

export const ImersaoColaborador: React.FC = () => {
  const { etapaId, interacaoId } = useParams<navProps>()
  const nav = useNavigate()

  const [Switch, setSwitch] = useState(0)

  const [Imersao, setImersao] = useState<iItemIntegracao[]>([])
  const [PopUpisOpen, setPopUpisOpen] = useState(false)

  function GetImersoesById(): void {
    appApi.get(`Imersao/${interacaoId as string}`)
      .then(response => {
        setImersao(response.data.itensIntegracao)
      }).catch(err => console.error(err))
  }

  function onRequestClosePopUp(): void {
    setPopUpisOpen(false)
    nav('/Colaborador')
  }

  function ConcluirEtapaImersao(): void {
    appApi.patch(`EtapaColaborador/${etapaId as string}/Concluir`)
      .then(res => {
        console.log(res.data)
        setPopUpisOpen(true)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetImersoesById()
  }, [])

  return (
    <Main>
      <ModalSucess
        isOpen={PopUpisOpen}
        onRequestClose={onRequestClosePopUp}
        onConfirm={onRequestClosePopUp}
        Titulo={'Imersão cultural'}
      />
      {
        Imersao.map((e: iItemIntegracao, i) => {
          if (e.tipo === 1) {
            return (
              <VideoImersao
                key={i}
                Descricao={e.descricao}
                Titulo={e.titulo}
                Url={e.arquivo?.uri}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 2) {
            return (
              <TextoImersao
                key={i}
                Titulo={e.titulo}
                Descricao={e.descricao}
                Url={e.arquivo?.uri}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 3) {
            return (
              <PDFImersao
                key={i}
                isVisible={i === Switch}
                uri={e.arquivo?.uri}
              />
            )
          }
          return null
        })
      }

      <NavElipse>
        {
          Imersao.map((e: iItemIntegracao, i) => {
            return (
              <div
                key={i}
                title={e.titulo}
                onClick={() => setSwitch(i)}
                style={{ background: Switch === i ? 'var(--c7)' : 'var(--c5)' }}>
              </div>
            )
          })
        }
      </NavElipse>

      <FlexCenter>
        {
          Switch === 0
            ? <MetaButton onClick={() => nav('/Colaborador')} bg='c5' size='md' styles={{ marginRight: '2rem' }}>Voltar</MetaButton>
            : <MetaButton onClick={() => setSwitch(Switch - 1)} bg='c5' size='md' styles={{ marginRight: '2rem' }}>Voltar</MetaButton>
        }

        {
          Switch === Imersao.length - 1
            ? <MetaButton onClick={ConcluirEtapaImersao} bg='v2' size='md'>Finalizar</MetaButton>
            : <MetaButton onClick={() => setSwitch(Switch + 1)} bg='v2' size='md'>Próximo</MetaButton>
        }
      </FlexCenter>
    </Main>
  )
}
