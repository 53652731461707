import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: var(--Azul);
color: var(--c1);
border-radius: 0.75rem;
height: 8.625rem;
width: 10.925rem;
box-shadow: var(--SombraBtns);

div{
    width: min-content;
    margin-left: .5rem;
}
`

export const StyledTd = styled.td`
    color: ${props => props.theme};
    font-weight: bold;
`

export const TableDiv = styled.div`
display: flex;
justify-content: center;
table, th, td {
  border: 0.063rem solid var(--c6);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

table{
    width: 100%;
}

th,td{
    max-width: 12rem;
    padding: .5rem 1rem .5rem 1rem;
}

td{
    transition: 2s;
    text-align: center;
}

th{
    background: var(--Roxo);
    color: var(--c1);
    font-weight: 500;
}
tr:last-child, tr:last-child:hover{
    background: var(--c3);
}
td:first-child{
    background: var(--RoxoOpaco25);
    color: var(--Preto);
    font-weight: bold;
}
.dropDownTr{
    background: var(--RoxoOpaco15) !important;
    color: var(--Preto);
    font-weight: bold;
}
tr:hover{
background: var(--RoxoOpaco25);
}

tr:not(:first-child){
color: var(--Preto);
}
`

export const ComentariosContainer = styled.div`
display: flex;
justify-content: start;
width: 100%;
background: var(--c2);
padding: .5rem;
width: 100%;
flex-direction: column;

h3{
    color: var(--Gray3);
    margin-bottom: 1rem;
}
`

export const Titulo = styled.h2`
font-family: 'Poppins';
font-weight: 500;
font-size: 28px;
color: #2C4D8A;
margin-bottom: 1rem;
`
export const TabelaPersonalizada = styled.table`
    gap: 0;
    border-collapse: collapse;
    border: 1px solid #b9b9b9;
    overflow: hidden;
    text-align: center;
    font-weight: 700;
    box-shadow: 0 0 0 1px #00000050;
    width: 100%;
    -webkit-border-radius: 16px;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
    td {
        border: 1px solid #b9b9b9;
        text-align: center;
        padding-top: .5rem;
        padding-bottom: .5rem;
        min-width: 6.5rem;
    }
    thead > tr {
        background-color: var(--Azul);
        color: #fff;
        th {
            border-left: 1px solid #b9b9b9;
            border-right: 1px solid #b9b9b9;
            :nth-child(2){
                border-left: 1px solid #b9b9b9;
                border-right: 1px solid #b9b9b9;
            }
            :nth-child(3){
                border-left: 1px solid #b9b9b9;
                border-right: 1px solid #b9b9b9;
            }
        }
    }
    tbody > tr {
        :first-child{
            background-color: #b9b9b980;
            td {
                border: 1px solid #00000050;
            }
        }
        td {
            :first-child {
                background-color: #b9b9b950;
                border: 1px solid #00000050;
            }
        }
            td{  
                :nth-child(7) {
                    font-size: 12px;
                }
                :nth-child(8) {
                    font-size: 14px;
                }
        }
    }
`
export const TabelaIndividual = styled.table`
    margin-top: 1rem;
    overflow: hidden;
    text-align: center;
    font-weight: 700;
    width: 100%;
    border-collapse: collapse;
    border-radius: 1rem;
    box-shadow: 0 0 0 1px #00000050;
    -webkit-border-radius: 16px;
    -moz-border-radius: 1rem;
    td{
        border: 1px solid #00000050;
        padding: .8rem;
    }
    th{
        border: 1px solid #00000050;
        padding: 0rem .8rem 0rem .8rem;
    }
    thead > tr {
        background-color: #b9b9b970;
        th {
            :first-child {
                background-color: var(--Azul);
                color: #fff;
            }
        }
    }
    svg {
        font-size: 22px;
        margin: 0 .5rem;
    }
`
export const TabelaAvaliacoes = styled.table`
    border-collapse: collapse;
    width: 100%;
    tr {
        padding: 1rem 1.5rem 1rem 1.5rem;
        :first-child {
            padding: 0;
        }
    }
    tbody > tr {
        background-color: #EDF2F7;
        padding: 1rem 1.5rem 1rem 1.5rem;
        :first-child {
            padding: 0;
        }
        :nth-child(even) {
            background: #fff;
        }
    }
    td {
        font-size: 14px;
        padding: 1rem 1.5rem 1rem 1.5rem;
        :first-child {
            text-align: start;
            padding: 1rem 2.25rem 1rem 2.25rem; 
        }
        svg {
            font-size: 22px;
        }
        text-align: center;
    }
    th {
        color: var(--Gray2);
        font-size: 12px;
        padding: 1rem 1.5rem 1rem 1.5rem; 
        :first-child {
            text-align: start;
            padding: 1rem 2.25rem 1rem 2.25rem; 
        }
        text-align: center;
    }
`
