/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Checkbox, Flex, FormControl, FormLabel, Grid, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { FaChevronDown, FaChevronUp, FaClipboardList } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { InfoBox, TabelaAvaliacoes } from './styles'
import { useLocation, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { iCompetenciaPDI, iNomeId } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeTrilha.svg'
import { Button } from '../../../components/Button'
import { PropInvalida } from '../../../Utils/Helper'
import { ImgSah } from '../../../components/ImgSah'
import { Recomendacao } from './components/Recomendacao'
import { GaugeChartGeral } from './components/GaugeChart'
import { RecomendacaoMedia } from './components/RecomendacaoMedia'
import { GaugeChartComparativo } from './components/GaugeChartComparativo'

export interface iColaboradorRecomedacao {
  nome: string
  cargo: string
  departamento: string
  id: string
  avatar: string
  media: number
}

interface iModel {
  nomePesquisa: string
  notaMaximaEscala: number
  totalAvaliadores: number
  totalRespostas: number
  totalAvaliacoes: number
  filtroDepartamento: iNomeId[]
  filtroCargo: iNomeId[]
  ciclica: boolean
  autoAvalDestaques: boolean
  recomendacoesApontamentos: iRecomendacoes[]
  recomendacoesMedia: iRecomendacoes[]
  pesquisasComparativo: iComparativoResponse[]
  tabelaVisaoGeral: iTabela
  avaliadores: number[]
}
interface iTabela {
  geralAvaliados: iLinhaTabela[]
  autoavaliacoes: iLinhaTabela[]
  tipoAvaliadores: iLinhaTabela2[]
}

interface iComparativoResponse {
  id: string
  nome: string
}
interface iLinhaTabela {
  competencia: string
  melhor: string
  pior: string
  maisMotiva: string
  menosMotiva: string
  priorizado: string
}

interface iLinhaTabela2 {
  competencia: string
  avaliador: iNotaAvaliador[]
}

interface iNotaAvaliador {
  tipo: string
  melhor: number
  pior: number
}

interface iRecomendacoes {
  tipo: number
  individual: boolean
  cards: iCompetenciaPDI[]
}
export interface IDadoGrafico {
  media: string
  nome: string
}

interface iModelFiltro {
  departamentoId?: string
  cargoId?: string
  gestor?: string
  dataInicio?: string
  dataFim?: string
  auto?: boolean
}

export const ResultadoGeralIndicativa: React.FC = () => {
  const ModelDefault: iModel = {
    autoAvalDestaques: false,
    ciclica: false,
    filtroCargo: [],
    filtroDepartamento: [],
    nomePesquisa: '',
    notaMaximaEscala: 0,
    totalAvaliadores: 0,
    totalRespostas: 0,
    totalAvaliacoes: 0,
    recomendacoesApontamentos: [],
    recomendacoesMedia: [],
    pesquisasComparativo: [],
    tabelaVisaoGeral: { autoavaliacoes: [], tipoAvaliadores: [], geralAvaliados: [] },
    avaliadores: []
  }

  const { pesquisaId } = useParams<{ pesquisaId: string }>()

  const [UltimoFiltro, setUltimoFiltro] = useState<number>()
  const [Ordenacao, setOrdenacao] = useState(false)
  const [TabelaOrdenada, setTabelaOrdenada] = useState(0)
  const [Grafico, setGrafico] = useState<IDadoGrafico[]>([])
  const [Model, setModel] = useState<iModel>(ModelDefault)
  const [ModelFiltro, setModelFiltro] = useState<iModelFiltro>()
  const [PesquisaComparativo, setPesquisaComparativo] = useState<string>(ModelDefault.pesquisasComparativo[0]?.id)
  const [IsLoading, setIsLoading] = useState(false)

  const { pathname } = useLocation()

  function GerarQuery(): string {
    let query = ''
    if (ModelFiltro?.departamentoId) {
      query = `departamentoId=${ModelFiltro.departamentoId}`
    }

    if (ModelFiltro?.cargoId) {
      query = query + `${query.length > 0 ? '&' : ''}cargoId=${ModelFiltro.cargoId}`
    }

    if (ModelFiltro?.gestor) {
      query = query + `${query.length > 0 ? '&' : ''}gestor=${ModelFiltro.gestor === '0'}`
    }

    if (ModelFiltro?.dataInicio) {
      query = query + `${query.length > 0 ? '&' : ''}dataInicio=${ModelFiltro.dataInicio}`
    }

    if (ModelFiltro?.dataFim) {
      query = query + `${query.length > 0 ? '&' : ''}dataFim=${ModelFiltro.dataFim}`
    }

    if (ModelFiltro?.auto) {
      query = query + `${query.length > 0 ? '&' : ''}auto=${ModelFiltro.auto}`
    }
    return query
  }

  function getResultado(): void {
    setIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${pesquisaId as string}/ResultadoGeralIndicativa?${GerarQuery()}`)
      .then(res => {
        setModel(res.data)
        setGrafico(res.data.graficoMediaGeralCompetencias)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function OrdenarTabela(filtro: number, tabelaOrdenacao: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setTabelaOrdenada(tabelaOrdenacao)
    setUltimoFiltro(filtro)
  }

  function Ordenar(): void {
    let copy: iLinhaTabela[] = []
    let copy2: iLinhaTabela2[] = []
    if (TabelaOrdenada === 1) {
      copy = [...Model.tabelaVisaoGeral.autoavaliacoes]
    }
    if (TabelaOrdenada === 2) {
      copy2 = [...Model.tabelaVisaoGeral.tipoAvaliadores]
    }
    if (TabelaOrdenada === 3) {
      copy = [...Model.tabelaVisaoGeral.geralAvaliados]
    }
    if (UltimoFiltro === 1) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return b.competencia.localeCompare(a.competencia)
        } else if (Ordenacao) {
          return a.competencia.localeCompare(b.competencia)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.melhor) > parseFloat(b.melhor) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.melhor) > parseFloat(a.melhor) ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 3) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.pior) > parseFloat(b.pior) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.pior) > parseFloat(a.pior) ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 4) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.maisMotiva) > parseFloat(b.maisMotiva) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.maisMotiva) > parseFloat(a.maisMotiva) ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 5) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.menosMotiva) > parseFloat(b.menosMotiva) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.menosMotiva) > parseFloat(a.menosMotiva) ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 6) {
      copy?.sort((a, b) => {
        if (!Ordenacao) {
          return parseFloat(a.priorizado) > parseFloat(b.priorizado) ? 1 : -1
        } else if (Ordenacao) {
          return parseFloat(b.priorizado) > parseFloat(a.priorizado) ? 1 : -1
        }
        return 0
      })
    }

    if (TabelaOrdenada === 1) {
      setModel({ ...Model, tabelaVisaoGeral: { ...Model.tabelaVisaoGeral, autoavaliacoes: copy } })
    }
    if (TabelaOrdenada === 2) {
      setModel({ ...Model, tabelaVisaoGeral: { ...Model.tabelaVisaoGeral, tipoAvaliadores: copy2 } })
    }
    if (TabelaOrdenada === 3) {
      setModel({ ...Model, tabelaVisaoGeral: { ...Model.tabelaVisaoGeral, geralAvaliados: copy } })
    }
  }

  function IconeOrdenar(filtro: number, tabelaOrdenacao: number): React.ReactElement {
    if (UltimoFiltro === filtro && !Ordenacao) {
      return (
        <FaChevronUp onClick={() => OrdenarTabela(filtro, tabelaOrdenacao)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />
      )
    } else {
      return (
        <FaChevronDown onClick={() => OrdenarTabela(filtro, tabelaOrdenacao)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />
      )
    }
  }

  function ObterNomeAvaliador(tipo: number): string {
    const data = {
      nomeAvaliador: ''
    }

    switch (tipo) {
      case 6:
        data.nomeAvaliador = 'Alta liderança'
        break
      case 4:
        data.nomeAvaliador = 'Outros'
        break
      case 3:
        data.nomeAvaliador = 'Liderados'
        break
      case 2:
        data.nomeAvaliador = 'Pares'
        break
      case 1:
        data.nomeAvaliador = 'Líderes'
        break
      case 0:
        data.nomeAvaliador = 'Autoavaliação'
        break
      default:
        break
    }

    return data.nomeAvaliador
  }

  useEffect(() => {
    Ordenar()
  }, [Ordenacao, UltimoFiltro])

  useEffect(() => {
    getResultado()
  }, [ModelFiltro])

  return (
    <Body isLoading={IsLoading}>
      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: `${pathname.substring(0, 36).replace('ResultadoGeral', 'Gestao')}`
          },
          {
            nome: Model?.nomePesquisa ?? '',
            path: -1
          },
          {
            nome: 'Resultado geral',
            path: ''
          }
        ]} />
      </Flex>

      <Flex alignItems={'center'} justifyContent={'space-between'} flexWrap='wrap'>
        <Flex width={'50%'} flexDir={'column'}>
          <h1>{Model?.nomePesquisa}</h1>
        </Flex>
      </Flex>

      <Flex marginTop={'1rem'} gap={'1rem'} alignItems='end'>
        <FormControl>
          <FormLabel fontSize={'xl'}>Área</FormLabel>
          <Select isDisabled={!PropInvalida(ModelFiltro?.cargoId ?? '')} onChange={(e) => setModelFiltro({ ...ModelFiltro, departamentoId: e.target.value })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            {
              Model?.filtroDepartamento?.map((e) => {
                return (
                  <option value={e.id} key={e.id}>{e.nome}</option>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'xl'}>Cargo</FormLabel>
          <Select isDisabled={!PropInvalida(ModelFiltro?.departamentoId ?? '')} value={ModelFiltro?.cargoId} onChange={(e) => setModelFiltro({ ...ModelFiltro, cargoId: e.target.value })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            {
              Model?.filtroCargo?.map((e) => {
                return (
                  <option value={e.id} key={e.id}>{e.nome}</option>
                )
              })
            }
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'xl'}>Gestor</FormLabel>
          <Select value={ModelFiltro?.gestor} onChange={(e) => setModelFiltro({ ...ModelFiltro, gestor: e.target.value })} bg={'white'} borderColor='var(--Gray4)'>
            <option value={''}>Todos</option>
            <option value={'0'}>Sim</option>
            <option value={'1'}>Não</option>
          </Select>
        </FormControl>

        {
          (Model?.ciclica) && (
            <>
              <FormControl>
                <FormLabel fontSize={'xl'}>Data de início</FormLabel>
                <Input value={ModelFiltro?.dataInicio} onChange={(e) => setModelFiltro({ ...ModelFiltro, dataInicio: e.target.value })} bg={'white'} borderColor='var(--Gray4)' type={'date'} />
              </FormControl>

              <FormControl>
                <FormLabel fontSize={'xl'}>Data de fim</FormLabel>
                <Input value={ModelFiltro?.dataFim} onChange={(e) => setModelFiltro({ ...ModelFiltro, dataFim: e.target.value })} bg={'white'} borderColor='var(--Gray4)' type={'date'} />
              </FormControl>
            </>
          )
        }

        <Flex>
          <Button onClick={getResultado} VarColor='Azul' size={'md'}>Filtrar</Button>
        </Flex>
      </Flex>
      {Model.totalRespostas && (
        <Flex margin={'1rem 0'} gap={'1rem'}>
          <InfoBox>
            <Flex width={'2rem'}>
              <img src={IconeAvaliadores} />
            </Flex>
            <div>
              <Text align={'start'} marginBottom={'-0.5rem'} fontSize={'2rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.totalAvaliacoes}</Text>
              <span>Avaliados</span>
            </div>
          </InfoBox>
          <InfoBox>
            <Flex width={'2rem'}>
              <FaClipboardList size={40} />
            </Flex>
            <div>
              <Text align={'start'} marginBottom={'-0.5rem'} fontSize={'2rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{Model?.totalAvaliadores}</Text>
              <span>Avaliadores</span>
            </div>
          </InfoBox>
          <InfoBox>
            <div>
              <Text align={'start'} marginBottom={'-0.5rem'} fontSize={'2rem'} fontFamily={'Roboto'} fontWeight={'bold'} >{((Model.totalRespostas / (Model.totalAvaliadores)) * 100).toFixed(0)}%</Text>
              <span>Adesão</span>
            </div>
          </InfoBox>
        </Flex>
      )}
      {
        (Model?.totalRespostas ?? -1 > 0) && (
          <Tabs marginTop={'1rem'} variant='enclosed'>
            <TabList >
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Destaques</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Visão geral</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Gráfico</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Comparativo</Tab>
            </TabList>
            <TabPanels background={'white'} borderBottomLeftRadius='1rem' borderBottomRightRadius='1rem'>
              <TabPanel padding={'2rem'}>
                {/* <Checkbox fontWeight={'400'} onChange={(e) => setModelFiltro({ ...ModelFiltro, auto: e.target.checked })}>Considerar autoavaliação nos destaques e nas necessidades de desenvolvimento</Checkbox> */}
                {Model.recomendacoesApontamentos?.map(e => {
                  return (
                    <Box my={'2rem'}>
                      <>
                        <Text mb={'2rem'} fontSize={'24px'}>Competências com {e.tipo === 0 ? 'maiores' : 'mais'} <strong>{e.tipo === 0 ? 'destaques positivos,' : 'necessidade de desenvolvimento,'}</strong> considerando a quantidade de apontamentos individuais</Text>
                        <Grid gap={'1.2rem'} templateColumns={'repeat(3, 1fr)'}>
                          {e.cards?.map((card, i) => {
                            return (
                              <Recomendacao pos={i + 1} recomendacao={card} tipo={e.tipo} />
                            )
                          })}
                        </Grid>
                      </>
                    </Box>
                  )
                })}
                {Model.recomendacoesMedia?.map(e => {
                  return (
                    <Box my={'2rem'}>
                      <>
                        <Text mb={'2rem'} fontSize={'24px'}>Competências com {e.tipo === 0 ? 'maiores' : 'mais'} <strong>{e.tipo === 0 ? 'destaques positivos,' : 'necessidade de desenvolvimento,'}</strong> considerando a média de apontamentos</Text>
                        <Grid gap={'1.2rem'} templateColumns={'repeat(3, 1fr)'}>
                          {e.cards?.map((card, i) => {
                            return (
                              <RecomendacaoMedia pos={i + 1} recomendacao={card} tipo={e.tipo} />
                            )
                          })}
                        </Grid>
                      </>
                    </Box>
                  )
                })}
              </TabPanel>
              <TabPanel>
                <Flex justifyContent={'space-between'} my={'1rem'} alignItems={'center'} fontSize={'22px'}><Text fontWeight={'700'}>Visão das competências considerando todos os avaliados</Text> <Checkbox onChange={(e) => setModelFiltro({ ...ModelFiltro, auto: e.target.checked })} fontWeight={'400'}>Considerar autoavaliação na melhor e pior</Checkbox></Flex>
                <Box p={'0 1rem'} border={'1px solid #b9b9b970'} borderRadius={'1rem'}>
                  <TabelaAvaliacoes>
                    <thead>
                      <tr>
                        <th>Competências {IconeOrdenar(1, 3)}</th>
                        <th>Melhor {IconeOrdenar(2, 3)}</th>
                        <th>Pior {IconeOrdenar(3, 3)}</th>
                        <th>Mais se motiva {IconeOrdenar(4, 3)}</th>
                        <th>Menos se motiva {IconeOrdenar(5, 3)}</th>
                        <th>Foi priorizado {IconeOrdenar(6, 3)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Model.tabelaVisaoGeral.geralAvaliados?.map(e => {
                        return (
                          <tr>
                            <td>{e.competencia}</td>
                            <td style={{ color: 'green' }}>{e.melhor === '0' ? '-' : e.melhor}</td>
                            <td style={{ color: 'red' }}>{e.pior === '-0' ? '-' : e.pior}</td>
                            <td style={{ color: 'green' }}>{e.maisMotiva === '0' ? '-' : e.maisMotiva}</td>
                            <td style={{ color: 'red' }}>{e.menosMotiva === '-0' ? '-' : e.menosMotiva}</td>
                            <td>{e.priorizado === '0' ? '-' : e.priorizado}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </TabelaAvaliacoes>
                </Box>
                <Text fontWeight={'700'} mt={'2rem'} mb={'1rem'} fontSize={'22px'}>Visão das competências considerando somente autoavaliações</Text>
                <Box p={'0 1rem'} border={'1px solid #b9b9b970'} borderRadius={'1rem'}>
                  <TabelaAvaliacoes>
                    <thead>
                      <tr>
                        <th>Competências {IconeOrdenar(1, 1)}</th>
                        <th>Melhor {IconeOrdenar(2, 1)}</th>
                        <th>Pior {IconeOrdenar(3, 1)}</th>
                        <th>Mais se motiva {IconeOrdenar(4, 1)}</th>
                        <th>Menos se motiva {IconeOrdenar(5, 1)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Model.tabelaVisaoGeral.autoavaliacoes?.map(e => {
                        return (
                          <tr>
                            <td>{e.competencia}</td>
                            <td style={{ color: 'green' }}>{e.melhor === '0' ? '-' : e.melhor}</td>
                            <td style={{ color: 'red' }}>{e.pior === '-0' ? '-' : e.pior}</td>
                            <td style={{ color: 'green' }}>{e.maisMotiva === '0' ? '-' : e.maisMotiva}</td>
                            <td style={{ color: 'red' }}>{e.menosMotiva === '-0' ? '-' : e.menosMotiva}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </TabelaAvaliacoes>
                </Box>
                <Text fontWeight={'700'} mt={'2rem'} mb={'1rem'} fontSize={'22px'}>Visão das competências considerando cada tipo de avaliadores</Text>
                <Box p={'0 1rem'} border={'1px solid #b9b9b970'} borderRadius={'1rem'}>
                  <TabelaAvaliacoes>
                    <thead>
                      <tr>
                        <th>Competências</th>
                        {Model.avaliadores.map((e, i) => {
                          return (
                            <th>{ObterNomeAvaliador(e)}</th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {Model.tabelaVisaoGeral.tipoAvaliadores?.map(e => {
                        return (
                          <tr>
                            <td width={100 / e.avaliador.length} >{e.competencia}</td>
                            {e.avaliador?.map((r, i) => {
                              return (
                                <td>
                                  <Flex justifyContent='center' gap={'1rem'} >
                                    {r.melhor === 0 && r.pior === 0
                                      ? (
                                        <Text>-</Text>
                                        )
                                      : (
                                        <>
                                          <Text color={'green'}>{r.melhor}</Text>
                                          <Text color={'red'}>{r.pior}</Text>
                                        </>
                                        )}

                                  </Flex>
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </TabelaAvaliacoes>
                </Box>
                <Flex direction={'column'} my={'1rem'}>

                  <Flex justifyContent={'center'} gap={'1rem'}>

                    <Flex gap={'1rem'}><Box w={'1.625rem'} h={'1.625rem'} bgColor={'var(--Green1)'}></Box> Melhores competências</Flex>

                    <Flex gap={'1rem'}><Box w={'1.625rem'} h={'1.625rem'} bgColor={'var(--Red)'}></Box> Piores competências</Flex>

                  </Flex>

                </Flex>
              </TabPanel>
              <TabPanel>
                <Text fontSize={'22px'} fontWeight={'700'}>Média geral das competências</Text>

                <Flex direction={'column'} my={'1rem'}>
                  <Flex justifyContent={'center'} gap={'1rem'}>
                    <Flex gap={'1rem'}><Box w={'1.625rem'} h={'1.625rem'} bgColor={'var(--Red)'}></Box> Competências priorizadas para serem desenvolvidas</Flex>
                    <Flex gap={'1rem'}><Box w={'1.625rem'} h={'1.625rem'} bgColor={'var(--Azul)'}></Box> Demais competências</Flex>
                  </Flex>
                </Flex>

                <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
                  <GaugeChartGeral
                    id={'dsada'}
                    val={Grafico}
                  />
                </Flex>
              </TabPanel>
              <TabPanel>
                <FormControl my={'2rem'}>
                  <FormLabel>Selecione a pesquisa que deseja comparar</FormLabel>
                  <Select value={PesquisaComparativo} onChange={(e) => setPesquisaComparativo(e.target.value)}>
                    {Model.pesquisasComparativo?.map(e => {
                      <option value={''}>Selecionar</option>
                      return (
                        <option value={e.id}>{e.nome}</option>
                      )
                    })}
                  </Select>
                </FormControl>
                <Text fontSize={'22px'} fontWeight={'700'}>Média geral das competências - Comparativo</Text>
                <Flex w={'100%'} justifyContent={'center'} position={'relative'}>

                  <GaugeChartComparativo
                    id={'dsadasdadsa'}
                    val={Grafico}
                    val2={[
                      { media: '-0.32', nome: 'Desenvolvimento' },
                      { media: '0.73', nome: 'Teste' },
                      { media: '-0.88', nome: 'Velocidade' },
                      { media: '0.47', nome: 'Agilidade' }
                    ]
                    }
                  />
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )
      }
      {
        (Model?.totalRespostas === 0) && (
          <ImgSah textoPersonalizado='Não encontramos resultados com os filtros selecionados' />
        )
      }
    </Body>
  )
}
