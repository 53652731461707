/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-return-assign */
import { Body } from '../../Layouts/Body'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { WhiteBox } from '../../../components/WhiteBox'
import { Column } from '../../../styles/styledGlobal'
import { AtributoContainer } from './Components/AtributoContainer'
import { AvaregeBox } from './Components/AvaregeBox'
import { GaugeChart } from './Components/GaugeChart'
import { MenuButton } from './Components/MenuButton'
import { OutlineBox } from '../Components/OutlineBox'
import {
  AtribGridContainer, ButtonMenu, ColumnEngajamento,
  ColumnMedia, GreyBox, HeadAtributoContainer, PulseContainer, StyledSpan
} from './styles'
import { Flex, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SigahApi, urlSigah } from '../../../services/appApi'
import { useNavigate } from 'react-router-dom'
import { ObterToken } from '../../../Utils/Helper'

export interface PulseDashboard {
  totalPesquisas: number
  pesquisasElaboracao: number
  pesquisasEncerradas: number
  pesquisasAndamento: number
}

export interface AtributosAfetamEngajamento {
  nome: string
  urlImagem: string
  media: number
}

export interface PecDashboard {
  statusPec: number
  idUltimaPec: string
  adesaoPec: number
  mediaEngajamento: number
  mediaEngajamentoAtual: number
  colaboradores: number
  respostas: number
  atributosAfetamEngajamento: AtributosAfetamEngajamento[]
  pesquisasEmAndamento: iPesquisasAndamento[]
}

export interface AtributosDestaque {
  nome: string
  urlImagem: string
  media: number
}

export interface AtributosPontosAtencao {
  nome: string
  urlImagem: string
  media: number
}

export interface FeedbackDashboard {
  atributosDestaques: AtributosDestaque[]
  atributosPontosAtencao: AtributosPontosAtencao[]
}

export interface iModel {
  pulseDashboard: PulseDashboard
  pecDashboard: PecDashboard
  feedbackDashboard: FeedbackDashboard
}
interface iPesquisasAndamento {
  nome: string
  id: string
}

export const EngajamentoDashboard: React.FC = () => {
  const nav = useNavigate()
  const [Model, setModel] = useState<iModel>()
  const Route = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}`
  const redirectPage = (ct: string, at: string, paramId?: string): any => window.location.href = `${Route}&ct=${ct}&at=${at}&paramId=${paramId ?? ''}`
  const [Loading, setLoading] = useState<boolean>(true)
  const [CurrentPECSelected, setCurrentPECSelected] = useState<string>('')
  function HandleSelectValue(e: React.ChangeEvent<HTMLSelectElement>): void {
    setCurrentPECSelected(e.target.value)
  }

  function getDashboard(): void {
    let sigahUrl = ''
    if (CurrentPECSelected !== '') {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      sigahUrl = `api/Sah/v1/Dashboard?idPesquisaPec=${CurrentPECSelected}`
    } else {
      sigahUrl = 'api/Sah/v1/Dashboard'
    }
    SigahApi.get(sigahUrl, {
      headers: {
        authorization: `bearer ${localStorage.getItem('token') as string}`
      }
    })
      .then(res => {
        if (res.status === 200 && typeof res.data !== 'string') {
          setModel(res.data)
          setTimeout(() => { setLoading(false) }, 300)
        }
      })
      .catch(err => { console.log(err); setLoading(false) })
  }
  useEffect(() => {
    setLoading(true)
    getDashboard()
    console.log('Console log do UseEffect do Model:')
    console.log(Model)
  }, [CurrentPECSelected])

  return (
        <Body isLoading={Loading}>
            <WhiteBox>
                <h1 onClick={() => console.log(CurrentPECSelected)}>Engajamento</h1>
                <ButtonMenu>
                    <MenuButton onClick={() => redirectPage('PesquisasPEC', 'Index')} bg='v2'>Pesquisa de engajamento</MenuButton>
                    <MenuButton onClick={() => redirectPage('PesquisaPulse', 'Index')} bg='Musgo'>Pesquisa Pulse</MenuButton>
                    <MenuButton onClick={() => redirectPage('FeedbackOrganizacao', 'Index')} bg='AzulOpaco'>Radar</MenuButton>
                    <MenuButton onClick={() => nav('/Engajamento/eNPS')} bg='Azul'>eNPS</MenuButton>
                </ButtonMenu>
                {(Model?.pecDashboard?.pesquisasEmAndamento?.length ?? 0) > 0 && <Select width={'16rem'} mb={'1rem'} mt={'.5rem'} ml={'1.6rem'} defaultValue={CurrentPECSelected === '' ? Model?.pecDashboard.idUltimaPec : CurrentPECSelected} onChange={(e) => HandleSelectValue(e)}>
                    {Model?.pecDashboard.pesquisasEmAndamento.map((e) => {
                      return (
                        <option key={e.id} value={e.id}>{e.nome}</option>
                      )
                    })}
                </Select>}
                {
                  (Model?.pecDashboard !== undefined && !Loading) && (
                    <OutlineBox>
                        <Flex justifyContent={'space-between'}>
                            <ColumnEngajamento>
                                <div>
                                    <img src='https://sigah.blob.core.windows.net/onboard/511c60a4-0328-48b0-bfbe-47db82b324a8.png' />
                                </div>
                                <MetaButton bg='Azul' size='sm' margin='1rem 0rem 0rem 0rem' onClick={() => redirectPage('PesquisasPEC', 'Participantes', Model?.pecDashboard.idUltimaPec)}>Gestão de convites</MetaButton>
                            </ColumnEngajamento>
                            <Column>
                                <h3>Pesquisa de engajamento em andamento...</h3>
                                <Flex>
                                    <GreyBox margin='0rem 1rem 0rem 0rem'>
                                        <span>{Model?.pecDashboard.colaboradores}</span>
                                        <span>Colaboradores</span>
                                    </GreyBox>

                                    <GreyBox>
                                        <span>{Model?.pecDashboard.respostas}</span>
                                        <span>Respostas</span>
                                    </GreyBox>
                                </Flex>
                            </Column>
                            <Flex alignItems={'center'}>
                                <Flex width={'15rem'} flexDir={'column'} alignItems='center'>
                                    <GaugeChart val={Model?.pecDashboard.adesaoPec ?? 0} id='as1' />
                                    <span style={{ color: 'var(--Gray3)', fontWeight: 700 }}>Adesão (%)</span>
                                </Flex>
                                <Flex width={'15rem'} flexDir={'column'} alignItems='center'>
                                    <GaugeChart MediaEngajamento val={Model?.pecDashboard.mediaEngajamento ?? 0} id='a21' />
                                    <span style={{ color: 'var(--Gray3)', fontWeight: 700 }}>Média engajamento</span>
                                </Flex>
                            </Flex>
                        </Flex>
                    </OutlineBox>
                  )
                }

                {
                    (Model?.pulseDashboard && !Loading) && (
                        <Flex margin='1rem 0rem'>
                            <OutlineBox margin='0rem 2rem 0rem 0rem'>
                        <ColumnMedia>
                            <h4 style={{ textAlign: 'center' }}>Média de engajamento da última pesquisa</h4>
                            <GaugeChart MediaEngajamento val={Model?.pecDashboard.mediaEngajamentoAtual ?? 0} id='as3' />
                        </ColumnMedia>
                            </OutlineBox>

                            <OutlineBox width='100%'>
                        <PulseContainer>
                            <img src='https://sigah.blob.core.windows.net/onboard/adf0174b-656a-4c9a-9afd-7bfa4db8905a.png' />
                            <Column>
                                <strong><h3>{Model?.pulseDashboard?.totalPesquisas} Pulses</h3>desenvolvidas no último ano</strong>
                                <MetaButton bg='Azul' size='md' margin='1rem 0rem 0rem 0rem' onClick={() => redirectPage('PesquisaPulse', 'MontarPesquisa')}>Criar nova pulse</MetaButton>
                            </Column>
                        </PulseContainer>
                        <Column>
                            <StyledSpan color='Orange'>{Model.pulseDashboard.pesquisasAndamento} Pulse em elaboração</StyledSpan>
                            <StyledSpan margin='.5rem 0rem' color='Green2'>{Model.pulseDashboard.pesquisasElaboracao} Pulse em elaboração</StyledSpan>
                            <StyledSpan color='Azul'>{Model.pulseDashboard.pesquisasEncerradas} Pulses encerradas</StyledSpan>
                        </Column>
                            </OutlineBox>
                        </Flex>
                    )
                }

                {
                    (Model?.pecDashboard.atributosAfetamEngajamento) && (
                        <OutlineBox width='100%' margin='1rem 0rem'>
                            <Flex flexDir={'column'} width={'100%'}>
                                <HeadAtributoContainer>
                                    <h3>Atributos qua mais afetaram o engajamento na última pesquisa</h3>
                                    <MetaButton bg='Azul' size='md' onClick={() => redirectPage('RespostaPesquisas', 'Calculo')}>Visualizar resultados</MetaButton>
                                </HeadAtributoContainer>
                                <AtribGridContainer>
                                    {
                                        Model?.pecDashboard.atributosAfetamEngajamento.map((e, i) => {
                                          return (
                                                <AtributoContainer key={i} size='md' column color='Roxo' url={e.urlImagem ?? ''}>
                                                    <span>{e.nome}</span>
                                                </AtributoContainer>
                                          )
                                        })
                                    }
                                </AtribGridContainer>
                            </Flex>
                        </OutlineBox>
                    )
                }

                {
                    (Model?.feedbackDashboard !== undefined && (Model?.feedbackDashboard.atributosDestaques.length > 0 || Model?.feedbackDashboard.atributosPontosAtencao.length > 0)) && (
                        <OutlineBox>
                            <Column>
                                <Flex justifyContent={'space-between'}>
                                    <h3>Radar</h3>
                                    <MetaButton bg='Azul' size='sm' onClick={() => redirectPage('FeedbackOrganizacao', 'Index')}>Visualizar dashboard completo</MetaButton>
                                </Flex>
                                <Flex>
                                    <OutlineBox borderColor='Green3' margin='1rem 1rem 0rem 0rem'>
                                        <Column>
                                            <h3>Atributos mais bem avaliados</h3>
                                            <Flex gap={'1rem'} justifyContent={'space-between'}>
                                                {
                                                    Model?.feedbackDashboard.atributosDestaques.map((e, i) => {
                                                      return (
                                                            <AvaregeBox key={i} nome={e.nome} media={e.media} url={e.urlImagem ?? ''} />
                                                      )
                                                    })
                                                }
                                            </Flex>
                                        </Column>
                                    </OutlineBox>

                                    <OutlineBox borderColor='Orange' margin='1rem 0rem 0rem 0rem'>
                                        <Column>
                                            <h3>Atributos com pontos de atenção</h3>
                                            <Flex gap={'1rem'} justifyContent={'space-between'}>
                                                {
                                                    Model?.feedbackDashboard.atributosPontosAtencao.map((e, i) => {
                                                      return (
                                                            <AvaregeBox key={i} nome={e.nome} media={e.media} url={e.urlImagem ?? ''} />
                                                      )
                                                    })
                                                }
                                            </Flex>
                                        </Column>
                                    </OutlineBox>
                                </Flex>
                            </Column>
                        </OutlineBox>
                    )
                }
            </WhiteBox>
        </Body>
  )
}
