import { Content } from './styles'

interface MetaButtonProps{
  size: string
  color: string
  margin?: string
  onClick?: () => void
  onmouseOver?: any
  onmouseOut?: any
}

export const OutlineMetaButton: React.FC<MetaButtonProps> = ({ children, margin, color, size, onClick, onmouseOver, onmouseOut }) => {
  return (
        <Content
          color={color}
          fontsize={size + 'FontBtn'}
          heigth={size + 'HeigthBtn'}
          padding={size + 'PaddingBtn'}
          onClick={onClick}
          onMouseOver={onmouseOver}
          onMouseOut={onmouseOut}
          margin={margin}
        >
            {children}
        </Content>
  )
}
