/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, FormControl, FormLabel, Input, Select, Switch } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCopy, FaTrash } from 'react-icons/fa'
import { iPutQuestaoFlexivel, iQuestaoFlexivel } from '../../../../../../interfaces'
import { Container } from './styles'
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai'

interface iQuestaoProps{
  questao: iQuestaoFlexivel
  onDelete: (id: string) => void
  onDuplicar: (id: string) => void
  onUpdate: (questao: iPutQuestaoFlexivel, id: string) => void
  onAcima: () => void
  onAbaixo: () => void
  verificaSetas: () => string
}

export const Questao: React.FC<iQuestaoProps> = ({ questao, children, onDelete, onUpdate, onDuplicar, onAcima, onAbaixo, verificaSetas }) => {
  const [Obrigatoria, setObrigatoria] = useState(false)
  const [Tipo, setTipo] = useState('0')
  const [Enunciado, setEnunciado] = useState('')

  useEffect(() => {
    setObrigatoria(questao.obrigatoria)
    setEnunciado(questao.enunciado)
    setTipo(questao.tipo.toString())
  }, [])

  function AbstracatUpdate(tipo?: string): void {
    onUpdate({
      enunciado: Enunciado,
      obrigatoria: Obrigatoria,
      tipo: tipo !== undefined ? parseInt(tipo) : parseInt(Tipo)
    }, questao.id)
  }

  return (
          <Container style={{ marginLeft: '1.5rem', position: 'relative' }}>
            <Box as='span' position={'absolute'} left={'-1.7rem'} top={'1.3rem'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'start'}>
                      {(verificaSetas() !== '0') && (verificaSetas() !== '3') && (<AiFillCaretUp onClick={onAcima}/>)}
                      {(verificaSetas() !== '1') && (verificaSetas() !== '3') && (<AiFillCaretDown onClick={onAbaixo}/>)}
            </Box>
            <Flex gap={'1.5rem'}>
                <FormControl width={'35%'}>
                    <FormLabel fontSize={'xl'}>Formato da pergunta</FormLabel>
                    <Select onChange={(e) => {
                      setTipo(e.target.value)
                      AbstracatUpdate(e.target.value)
                    }} value={Tipo} borderColor={'var(--Gray4)'}>
                        <option value={'0'}>Múltipla escolha</option>
                        <option value={'5'}>Caixa de seleção</option>
                        <option value={'1'}>Discursiva</option>
                    </Select>
                </FormControl>

                <FormControl width={'65%'}>
                    <FormLabel fontSize={'xl'} display={'flex'} justifyContent={'space-between'} flexDirection={'row'}>Pergunta</FormLabel>
                    <Input
                        onBlur={() => AbstracatUpdate()}
                        onChange={(e) => setEnunciado(e.target.value)}
                        value={Enunciado}
                        borderColor={'var(--Gray4)'}
                        placeholder={'Escreva a pergunta'}
                    />
                </FormControl>
            </Flex>
            <Flex margin={'1rem 0rem'}>
                {children}
            </Flex>

            <Flex justifyContent={'end'}>
                <FormControl width={'fit-content'} display='flex' alignItems='center'>
                    <Switch onBlur={() => AbstracatUpdate()} onChange={(e) => setObrigatoria(e.target.checked)} isChecked={Obrigatoria} size={'lg'} marginRight={'.5rem'} id='email-alerts' />
                    <FormLabel htmlFor='email-alerts' mb='0'>
                        Obrigatória
                    </FormLabel>
                </FormControl>
                <Flex marginLeft={'1.5rem'} gap={'0rem 1rem'}>
                    <FaCopy onClick={() => onDuplicar(questao.id)} cursor={'pointer'} color='var(--Azul)' size={25} />
                    <FaTrash cursor={'pointer'} onClick={() => onDelete(questao.id)} color='var(--terc3)' size={25} />
                </Flex>
            </Flex>
        </Container>
  )
}
