import { Avatar, Box, Flex, Image, Input, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai'
import { FaPenSquare, FaRegComment, FaSave, FaTrashAlt } from 'react-icons/fa'
import { FiLink } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { iPontosDiscussao } from '../..'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'
import { parseJwt } from '../../../../../../services/token'

interface iProps {
  statusReuniao: number
  model: iPontosDiscussao
  onConcluir: (id: string) => void
  onAtualizar: (id: string, texto: string) => void
  onExcluir: (id: string) => void
}

export const CardPontoDiscussao: React.FC<iProps> = ({ model, statusReuniao, onConcluir, onExcluir, onAtualizar }) => {
  const nav = useNavigate()
  const [Edit, setEdit] = useState(false)
  const [Texto, setTexto] = useState('')

  function InternalAtualizar(): void {
    setEdit(false)
    onAtualizar(model.id, Texto)
  }
  useEffect(() => {
    if (Edit) {
      setTexto(model.titulo)
    }
  }, [Edit])
  return (
        <Flex bgColor={'var(--Gray6)'} p={'1rem'} my={'.5rem'} alignItems={'center'} justifyContent='space-between'>
            <Flex gap={'1rem'} alignItems={'center'}>
                {!model.concluido && !model.proximoPasso &&
                    <AiOutlineCheckCircle
                        onClick={() => onConcluir(model.id)}
                        cursor={'pointer'}
                        size={30}
                        style={{
                          color: 'var(--Gray4)'
                        }}
                    />}

                {model.concluido && !model.proximoPasso &&
                    <AiFillCheckCircle
                        onClick={() => onConcluir(model.id)}
                        cursor={'pointer'}
                        size={30}
                        style={{
                          color: 'var(--Green2)'
                        }}
                    />
                }
                <Flex alignItems={'center'} gap={'.5rem'}>
                    {model.tipo === 1 && <Image src='https://sigah.blob.core.windows.net/onboard/icones/Group 116_d2fd30c1-9812-4037-8a47-715f9afb39fe.png' />}
                    {model.tipo === 2 && <Avatar size={'sm'} name={model.titulo} src={model.avatar} />}
                    <Box gap={'1rem'}>
                        {!Edit &&
                            <Text
                                onClick={() => nav(`?pontoId=${model.id}`)}
                                color={'var(--Gray1)'}
                                textDecor={'underline'}
                                cursor={'pointer'}
                            >{model.titulo}</Text>
                        }
                         {Edit &&
                            <Input
                                value={Texto}
                                borderColor={'var(--Gray4)'}
                                onChange={(e) => setTexto(e.target.value)}
                                height='1.8rem'
                            />
                         }
                        <Text fontSize={'12px'}>{model.descricao}</Text>
                    </Box>
                </Flex>
            </Flex>
            <Flex gap={'1rem'}>
                <Flex alignItems={'center'} gap={'.5rem'}>
                    {model.comentarios.length}
                    <FaRegComment />
                </Flex>
                <Flex alignItems={'center'} gap={'.5rem'}>
                    {model.anexos.length}
                    <FiLink />
                </Flex>
                {(model.tipo !== 1 &&
                    statusReuniao !== 5 &&
                    model.criadorId === parseJwt().cid) &&
                    <Flex
                        cursor={'pointer'}
                        alignItems={'center'}
                        color={'var(--Red)'}
                        gap='.5rem'
                    >
                        {!Edit &&
                            <ElementoTooltip
                                label='Editar'
                            >
                                <FaPenSquare
                                    onClick={() => setEdit(true)}
                                    color='var(--terc2)'
                                />
                            </ElementoTooltip>
                        }

                        {Edit &&
                            <ElementoTooltip
                                label='Salvar'
                            >
                                <FaSave
                                    onClick={InternalAtualizar}
                                    color='var(--Green2)'
                                />
                            </ElementoTooltip>
                        }
                        {model.anexos.length === 0 &&
                            model.comentarios.length === 0 &&
                            <FaTrashAlt
                                onClick={() => onExcluir(model.id)}
                            />
                        }
                    </Flex>
                }
            </Flex>
        </Flex>
  )
}
