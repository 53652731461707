import styled from 'styled-components'

export const Background = styled.div`
background: #${props => props.theme};
height: 15rem;
min-width: 12rem;
max-width: 12rem;
border-radius: 0.625rem;
color: var(--c1);
font-weight: 400;
box-sizing: border-box;
border-width: 0.125rem;
font-family: 'Roboto', sans-serif;
margin-right: 1rem;
text-align: center;
word-break: break-word;
padding: 0.5rem;

&:hover{
    border: solid var(--terc6);
    cursor: pointer;
    transform: translate(0rem,-0.375rem);
    transition: all 0.3s ease;
    box-shadow: 0rem 0.375rem 0.5rem rgba(0, 0, 0, 0.25);
}
`

export const DropMenu = styled.ul`
list-style-type: none;
background: white;
box-shadow: var(--SombraBackground);
border-radius: .5rem;
position: absolute;
overflow-y: hidden;
max-height: 0;
display: flex;
flex-direction: column;
gap: .2rem;


li{
    padding: .5rem;
    cursor: pointer;

    svg{
        margin-right:.3rem;
    }
}

li:hover{
    background: var(--c2);
    font-weight: 500;
}

li[data-menu="Duplicar"]{
    color: var(--Gray3);
}

li[data-menu="Excluir"]{
    color: var(--terc3);
}

li[data-menu="Editar"]{
    color: var(--terc10);
}
`

export const Menu = styled.div`
display: flex; 
flex-direction:column;
align-items:end;
min-width: 100%;
width: 3rem;
cursor: pointer;
&:hover{
    ul{
        transition: all .4s ease;
        max-height: 8.897rem;
    }
}
`

export const Descricao = styled.span`
    font-weight: 300;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
`
