/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Drawer, DrawerBody,
  DrawerOverlay, DrawerContent, DrawerHeader, Flex, Select, FormControl, FormLabel, Input, Text, Divider
} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { Button } from '../../../../../../components/Button'
import { iNomeId } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'

interface iFiltro {
  dataInicio?: string
  dataFim?: string
  status?: string
  area?: string
  squad?: string
  cargo?: string
  origem?: string
  acao?: string
}

interface iDrawProps {
  isOpen: boolean
  onClose: () => void
  onFilter: (filtro: iFiltro) => void
}

export const DrawerFiltro: React.FC<iDrawProps> = ({ isOpen, onClose, onFilter }) => {
  const [Tags, setTags] = useState<iNomeId[]>([])
  const [Departamentos, setDepartamentos] = useState<iNomeId[]>([])
  const [Cargos, setCargos] = useState<iNomeId[]>([])

  const [Filtro, setFiltro] = useState<iFiltro>({
    area: '',
    cargo: '',
    dataFim: '',
    dataInicio: '',
    origem: '',
    squad: '',
    status: '',
    acao: ''
  })

  function GetTags(): void {
    appApi.get('Tag/Select/?tipo=2')
      .then(res => setTags(res.data))
      .catch(err => console.log(err))
  }

  function GetDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => setDepartamentos(res.data))
      .catch(err => console.log(err))
  }

  function GetCargos(): void {
    appApi.get('Cargo/Select')
      .then(res => setCargos(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    GetTags()
    GetDepartamentos()
    GetCargos()
  }, [])

  return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            size={'md'}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader as={Flex} width={'100%'} justifyContent={'space-between'} borderBottom={'2px solid var(--c3)'} borderBottomWidth='1px'>
                    <Text>Filtros</Text>
                    <MdClose onClick={onClose} size={24} />
                </DrawerHeader>

                <DrawerBody>
                    <Flex gap={'1rem'} flexDir={'column'}>
                        <Flex gap={'1rem'}>
                            <FormControl>
                                <FormLabel>Início</FormLabel>
                                <Input onChange={(e) => setFiltro({ ...Filtro, dataInicio: e.target.value })} type={'date'} borderColor={'var(--Gray4)'} />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Fim</FormLabel>
                                <Input onChange={(e) => setFiltro({ ...Filtro, dataFim: e.target.value })} type={'date'} borderColor={'var(--Gray4)'} />
                            </FormControl>
                        </Flex>
                        <Divider orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Status PDI</FormLabel>
                            <Select onChange={(e) => setFiltro({ ...Filtro, status: e.target.value })}>
                                <option value={''}>Todos</option>
                                <option value={'5'}>Em andamento</option>
                                <option value={'7'}>Concluido</option>
                            </Select>
                        </FormControl>
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Status Ações</FormLabel>
                            <Select onChange={(e) => setFiltro({ ...Filtro, acao: e.target.value })}>
                                <option value={''}>Todos</option>
                                <option value={'1'}>Aguardando aprovação</option>
                                <option value={'2'}>Aguardando alteração</option>
                                <option value={'5'}>Em andamento</option>
                                <option value={'6'}>Atrasada</option>
                                <option value={'7'}>Concluída</option>
                            </Select>
                        </FormControl>
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Área</FormLabel>
                            <Select onChange={(e) => setFiltro({ ...Filtro, area: e.target.value })}>
                                <option value={''}>Todas</option>
                                {
                                    Departamentos.map((e, i) => {
                                      return (
                                        <option key={i} value={e.id}>{e.nome}</option>
                                      )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Squad</FormLabel>
                            <Select onChange={(e) => setFiltro({ ...Filtro, squad: e.target.value })}>
                                <option value={''}>Todos</option>
                                {
                                    Tags.map((e, i) => {
                                      return (
                                            <option key={i} value={e.id}>{e.nome}</option>
                                      )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Função</FormLabel>
                            <Select onChange={(e) => setFiltro({ ...Filtro, cargo: e.target.value })}>
                                <option value={''}>Todas</option>
                                {
                                    Cargos.map((e, i) => {
                                      return (
                                        <option key={i} value={e.id}>{e.nome}</option>
                                      )
                                    })
                                }
                            </Select>
                        </FormControl>
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Origem</FormLabel>
                            <Select onChange={(e) => setFiltro({ ...Filtro, origem: e.target.value })}>
                                <option value={''}>Todas</option>
                                <option value={'0'}>Pesquisa</option>
                                <option value={'1'}>Feedback</option>
                                <option value={'2'}>Outra</option>
                            </Select>
                        </FormControl>

                        <Flex gap={'1rem'} justifyContent={'end'}>
                            <Button
                                VarColor='c4'
                                onClick={onClose}
                            >Cancelar
                            </Button>
                            <Button
                                VarColor='v2'
                                onClick={() => onFilter(Filtro)}
                            >Filtrar
                            </Button>
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
  )
}
