/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex } from '@chakra-ui/react'
import { HTMLAttributes, useState } from 'react'
import { Container, ContainerButton } from './styles'

interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  Titulo: string
  Subtitulo: string
  Icon?: any
  HoverIcon?: any
  width?: string
}

export const ButtonOption: React.FC<ButtonProps> = ({ Titulo, Subtitulo, Icon, HoverIcon, width, onClick }) => {
  const [Hover, setHover] = useState(false)
  return (
    <Container
      width={width}
      onMouseLeave={() => setHover(false)}
      onMouseOver={() => setHover(true)}
      onClick={onClick}
    >
      <ContainerButton>
        {
          !Hover ? Icon : HoverIcon

        }
        <Flex flexDirection={'column'}>
          <span>{Titulo}</span>
          <span>{Subtitulo}</span>
        </Flex>
      </ContainerButton>
    </Container>
  )
}
