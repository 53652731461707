/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-tabs */
import { Box, Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { FaEye, FaPlus } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { NavLine } from '../../../../components/NavLine'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iPutQuestaoCaixaSelecao, iPutQuestaoFlexivel, iQuestaoFlexivel } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { InsertElementByPosition, PropInvalida, SubstringRoute } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { Questao } from './Components/Questao'
import { CaixaDeSelecao } from './Components/Questao/Components/CaixaDeSelecao'
import { Discursiva } from './Components/Questao/Components/Discursiva'
import { MultiplaEscolha } from './Components/Questao/Components/MultiplaEscolha'

interface iForm {
  nome: string
  questoes: iQuestaoFlexivel[]
}

export const EditarFormularioFeedback: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const { formularioId, feedbackId } = useParams<{ formularioId: string, feedbackId: string }>()

  const [Formulario, setFormulario] = useState<iForm>({
    nome: '',
    questoes: []
  })

  const [NomeIsInvalid, setNomeIsInvalid] = useState(false)
  const [QuestaoIsInvalid, setQuestaoIsInvalid] = useState(false)

  function ObterFormulario(update?: boolean): void {
    appApi.get(`FormularioFeedback/${formularioId as string}`)
      .then(res => {
        if (!PropInvalida(update) || update) {
          setFormulario({ ...Formulario, questoes: res.data.questoes })
        } else {
          setFormulario(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function AdicionarQuestao(): void {
    appApi.post(`FormularioFeedback/${formularioId as string}/Questao`)
      .then(() => ObterFormulario(true))
      .catch(err => console.log(err))
  }

  function UpdatePropsEmComumQuestao(questao: iPutQuestaoFlexivel, id: string): void {
    appApi.put(`FormularioFeedback/${formularioId as string}/questao/${id}`, questao)
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          const pos = filtrado.questoes.findIndex(e => e.id === id)
          if (pos !== -1) {
            filtrado.questoes[pos].enunciado = questao.enunciado
            filtrado.questoes[pos].tipo = questao.tipo
            filtrado.questoes[pos].obrigatoria = questao.obrigatoria
            setFormulario(filtrado)
          }
        }
      })
      .catch(err => console.log(err))
  }

  function UpdateCaixaDeSelecao(questaoId: string, form: iPutQuestaoCaixaSelecao): void {
    appApi.put(`FormularioFeedback/${formularioId as string}/questao/${questaoId}/CaixaSelecao`, form)
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          const pos = filtrado.questoes.findIndex(e => e.id === questaoId)
          if (pos !== -1) {
            filtrado.questoes[pos].maxCaixaSelecao = form.maxCaixaSelecao
            filtrado.questoes[pos].minCaixaSelecao = form.minCaixaSelecao
            setFormulario(filtrado)
          }
        }
      })
      .catch(err => console.log(err))
  }

  function DeletarQuestao(id: string): void {
    appApi.delete(`FormularioFeedback/${formularioId as string}/questao/${id}`)
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          filtrado.questoes = filtrado.questoes.filter(e => e.id !== id)
          setFormulario({
            nome: '',
            questoes: []
          })
          setFormulario(filtrado)
        }
      })
      .catch(err => console.log(err))
  }

  function DuplicarQuestao(id: string): void {
    appApi.post(`FormularioFeedback/${formularioId as string}/questao/${id}/Duplicar`)
      .then(() => ObterFormulario(true))
      .catch(err => console.log(err))
  }

  function AdicionarAlternativa(id: string): void {
    appApi.post(`FormularioFeedback/${formularioId as string}/questao/${id}/Alternativa`)
      .then(() => ObterFormulario(true))
      .catch(err => console.log(err))
  }

  function RemoverAlternativa(questaoId: string, alternativaId: string): void {
    appApi.delete(`FormularioFeedback/${formularioId as string}/questao/${questaoId}/Alternativa/${alternativaId}`)
      .then(() => ObterFormulario(true))
      .catch(err => console.log(err))
  }

  function UpdateTextoAlternativa(questaoId: string, alternativaId: string, texto: string): void {
    appApi.put(`FormularioFeedback/${formularioId as string}/questao/${questaoId}/Alternativa/${alternativaId}`, { texto: texto })
      .then(() => {
        if (Formulario !== undefined) {
          const filtrado = { ...Formulario }
          const pos = filtrado.questoes.findIndex(e => e.id === questaoId)
          if (pos !== -1) {
            const posAlternativa = filtrado.questoes[pos].alternativas.findIndex(e => e.id === alternativaId)
            if (posAlternativa !== -1) {
              filtrado.questoes[pos].alternativas[posAlternativa].texto = texto
              setFormulario(filtrado)
            }
          }
          console.log(filtrado)
        }
      })
      .catch(err => console.log(err))
  }

  function verificaSetas(direcao: 'cima'|'baixo', pos: number): boolean {
    if (direcao === 'cima' && PropInvalida(Formulario.questoes[pos - 1])) {
      return false
    }

    if (direcao === 'baixo' && PropInvalida(Formulario.questoes[pos + 1])) {
      return false
    }
    return true
  }

  function Salvar(): void {
    setNomeIsInvalid(false)
    setQuestaoIsInvalid(false)

    if (Formulario.nome.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome do formulário deve conter no mínimo 5 caracteres!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Formulario.questoes.some(r => r.enunciado.length === 0)) {
      setQuestaoIsInvalid(true)
      toast({
        title: 'Existem questões sem enunciado definido, procure por campos em vermelho!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    appApi.put(`FormularioFeedback/${formularioId ?? ''}`, {
      nome: Formulario.nome,
      questoes: Formulario.questoes.map(e => e.id)
    })
      .then(() => {
        toast({
          title: 'Formulário atualizado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        nav(`${SubstringRoute(pathname, 'EditarFormulario')}Configuracao`)
      }
      )
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ObterFormulario()
  }, [])

  return (
    <Body>
      <NavLine options={[
        { nome: 'Reuniao 1:1', path: `${SubstringRoute(pathname, 'Feedback')}Dashboard/Individual` },
        { nome: 'Feedback' },
        { nome: 'Formularios', path: -1 },
        { nome: 'Editando formulário' }
      ]}
      />
      <WhiteBox>
        <h2>Adicione as questões para o formulário de feedback</h2>
        <Box my={'2rem'}>
          <FormControl>
            <FormLabel>Nome do feedback</FormLabel>
            <Input
              value={Formulario?.nome}
              onChange={(e) => setFormulario({ ...Formulario, nome: e.target.value })}
              type='text'
              placeholder='Digite o nome para o feedback'
              isInvalid={NomeIsInvalid}
            />
          </FormControl>
        </Box>
        {
          (Formulario) && (
            <Flex flexDirection={'column'} gap={'2rem 0rem'}>
              {
                Formulario?.questoes.map((e, i) => {
                  return (
                    <Questao
                      key={e.id}
                      onDelete={DeletarQuestao}
                      onUpdate={UpdatePropsEmComumQuestao}
                      onDuplicar={DuplicarQuestao}
                      questao={e}
                      index={i}
                      isInvalid={QuestaoIsInvalid}
                      onAcima={() =>
                        setFormulario(
                          {
                            ...Formulario,
                            questoes: InsertElementByPosition(Formulario.questoes.filter(t => t.id !== e.id),
                              i - 1, { ...e })
                          })}
                      onAbaixo={() =>
                        setFormulario(
                          {
                            ...Formulario,
                            questoes: InsertElementByPosition(Formulario.questoes.filter(t => t.id !== e.id),
                              i + 1, { ...e })
                          })}
                      verificaSetas={verificaSetas}
                    >
                      {
                        e.tipo === 0
                          ? <MultiplaEscolha
                            onCreate={AdicionarAlternativa}
                            onDelete={RemoverAlternativa}
                            onUpdate={UpdateTextoAlternativa}
                            questao={e}
                          />
                          : e.tipo === 1
                            ? <Discursiva
                              questao={e} />
                            : <CaixaDeSelecao
                              onCreate={AdicionarAlternativa}
                              onDelete={RemoverAlternativa}
                              onUpdate={UpdateTextoAlternativa}
                              onUpdateMaxMin={UpdateCaixaDeSelecao}
                              questao={e}
                            />
                      }
                    </Questao>
                  )
                })
              }
               <Flex gap={'1rem'}>
                <Button
                  VarColor='Azul'
                  onClick={AdicionarQuestao}
                  rightIcon={<FaPlus />}
                >Adicionar pergunta</Button>

                <Button
                  VarColor='Blue3'
                  onClick={() => nav(`/Feedback/Preenchimento/${feedbackId ?? ''}/Previsualizar`)}
                  rightIcon={<FaEye />}
                >Pré-visualizar formulário</Button>
              </Flex>
            </Flex>
          )
        }

        <Flex justifyContent={'end'} gap='1rem'>
          <Button size={'lg'} VarColor='Gray3' onClick={() => nav(-1)}>Voltar</Button>
          <Button onClick={Salvar} size={'lg'} VarColor='Green2'>Salva e continuar</Button>
        </Flex>
      </WhiteBox>
    </Body>
  )
}
