import { MdMenu } from 'react-icons/md'
import { Body } from '../../Layouts/Body'
import { WhiteBox } from '../../../components/WhiteBox'
import { QuestionBox, ResumoBox } from './styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Checkbox, Flex, useToast } from '@chakra-ui/react'
import { ModalDelete } from '../../../components/ModalDelete'
import { Button } from '../../../components/Button'
import { FaEye } from 'react-icons/fa'
import { ModalDuplicarForm } from '../../../components/ModalDuplicarForm'
import { useQuery } from '../../../Utils/Helper'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RelatorioPDF } from './RelatorioPDF'
import { iEscala } from '../../../interfaces'

export interface iModel {
  id: string
  totalCompetencias: number
  totalQuestoes: number
  nomeEscala: string
  status: number
  questoes: Questao[]
  justificativa: boolean
  exibirCompetencia: boolean
  exibirPeso: boolean
}

export interface Questao {
  id: string
  avatar: string
  pergunta: string
  nomeCompetencia: string
}
export interface iFormularioPdf {
  nome: string
  escala: iEscala
  competencias: iCompetenciaPdf[]
  participantes: number[]
}

export interface iCompetenciaPdf {
  nome: string
  icone: string
  questoes: string[]
  discursiva: boolean
}

export const VisualizarFormulario: React.FC = () => {
  const toast = useToast()
  const path = useLocation().pathname
  const query = useQuery()

  const [IsLoading, setIsLoading] = useState(false)
  const { pesquisaId } = useParams()
  const [NovoFormId, setNovoFormId] = useState('')
  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)

  const [FormularioPdf, setFormularioPdf] = useState<iFormularioPdf>({
    competencias: [{ nome: '', questoes: [], icone: '', discursiva: false }],
    escala: { fator: 0, id: '', nome: '', respostas: [] },
    nome: '',
    participantes: []
  })

  const [Formulario, setFormulario] = useState<iModel>({
    id: '',
    nomeEscala: '',
    questoes: [],
    status: 0,
    totalCompetencias: 0,
    totalQuestoes: 0,
    justificativa: false,
    exibirCompetencia: false,
    exibirPeso: false
  })

  const nav = useNavigate()

  const [AprovarFormularioIsOpen, setAprovarFormularioIsOpen] = useState(false)

  function AprovarFormulario(): void {
    appApi.put(`Formulario/${Formulario.id}/Aprovar`).then(() => {
      if (path.includes('Trilha') || path.includes('Processo')) {
        nav(`${path.replace('VisualizarFormulario', 'EnviarPesquisa')}`)
      } else {
        nav(`${path.replace('VisualizarFormulario', 'Participantes')}`)
      }
    }
    ).catch(() => {
      toast({
        title: 'Não foi inserida nenhuma questão no formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 3000
      })
    })
  }

  function DuplicarEEditarForm(Nome: string): void {
    const form = { texto: Nome }
    setIsLoading(true)
    appApi.post(`FormularioPesquisa/Copy/${Formulario.id}?pesquisaId=${pesquisaId ?? ''}`, form).then(resp => {
      setNovoFormId(resp.data)
    }).catch(err => console.log(err))
  }

  function onCloseModalDuplicar(): void {
    setModalDuplicarIsOpen(false)
  }

  function AtualizarExibirCompetencia(): void {
    appApi.patch(`Formulario/${Formulario.id}/ExibirCompetencia`)
      .then()
      .catch(err => console.log(err))
  }
  function AtualizarExibirPeso(): void {
    appApi.patch(`Formulario/${Formulario.id}/ExibirPeso`)
      .then()
      .catch(err => console.log(err))
  }

  function getFormulario(): void {
    appApi.get(`FormularioPesquisa/${pesquisaId as string}/Visualizar`).then(resp => {
      setFormulario(resp.data)
    }).catch(err => console.log(err))
  }
  function getFormularioPdf(): void {
    appApi.get(`FormularioPesquisa/${pesquisaId as string}/Pdf`).then(resp => {
      setFormularioPdf(resp.data)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormulario()
    getFormularioPdf()
  }, [])

  useEffect(() => {
    setIsLoading(false)
    if (NovoFormId !== '') {
      nav(`/Rh/AnaliseDesempenho/Formulario/${pesquisaId ?? ''}`)
    }
  }, [NovoFormId])

  return (
      <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={AprovarFormularioIsOpen}
        message='Após aprovar o formulário você não poderá mais editá-lo. Deseja continuar?'
        onRequestClose={() => setAprovarFormularioIsOpen(false)}
        onConfirm={AprovarFormulario}
      />
      <ModalDuplicarForm
        isOpen={ModalDuplicarIsOpen}
        onCreate={(e) => DuplicarEEditarForm(e)}
        onClose={onCloseModalDuplicar}
      />

      <Flex justifyContent={'space-between'} margin='2rem 0rem'>
        <Flex flexDir={'column'} justifyContent='end'>
          <h1>Verifique e aprove o formulário</h1>
        </Flex>
        <ResumoBox>
          <h3>Resumo do formulário</h3>
          <span>Escala selecionada:<strong> {Formulario.nomeEscala}</strong></span>
          <span>Competências selecionadas:<strong> {Formulario.totalCompetencias}</strong></span>
          <span>Total de questões:<strong> {Formulario.totalQuestoes}</strong></span>
          <span>Comentários nas questões:<strong> {Formulario.justificativa ? 'Ativado' : 'Desativado'}</strong></span>
        </ResumoBox>
      </Flex>

      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <h2 style={{ color: '#2C4D8A' }}>Essas são todas as questões selecionadas</h2>
            <PDFDownloadLink
            document={
              <RelatorioPDF
                model={FormularioPdf}
                questoes={Formulario.questoes}
              />
            }
            fileName={'Formulario_Pesquisa_Percepcao.pdf'}
          >
            {({ loading }) =>
              loading
                ? (
                  <Button VarColor='Azul' isLoading>Baixar formulário</Button>
                  )
                : <Button VarColor='Azul'>Baixar formulário</Button>

            }
          </PDFDownloadLink>
        </Flex>
        {/* <SubtitleMd>Você pode alterar a ordem das questões arrastando-as pelo canto esquerdo</SubtitleMd> */}
        <section style={{ marginTop: '1rem' }}>
          {
            Formulario.questoes.map((e: Questao, index) => {
              return (
                <div key={index}>
                  <QuestionBox>
                    <Flex>
                      <MdMenu size={30} />
                    </Flex>
                    <Flex flexDir={'column'} minWidth='9rem' maxWidth='9rem' alignItems={'center'} textAlign='center' margin='0rem 1rem'>
                      <img src={e.avatar} />
                      <small>{e.nomeCompetencia}</small>
                    </Flex>
                    <span>{e.pergunta}</span>
                  </QuestionBox>
                </div>
              )
            })
          }

        </section>
        <Flex justifyContent={'space-between'}>
          <Button
            leftIcon={<FaEye/>}
            onClick={() => nav({ pathname: 'Previsualizar' }, { state: { pesquisaId: pesquisaId } })}
            VarColor='vg3' size={'lg'}
          >Pré-visualizar</Button>
          <Flex gap={'1rem'}>
            {!query.get('leitura') && (
              <Checkbox isChecked={Formulario.exibirPeso} onChange={(e) => { setFormulario({ ...Formulario, exibirPeso: e.target.checked }); AtualizarExibirPeso() }}>Mostrar o peso das alternativas</Checkbox>
            )}
            {!query.get('leitura') && (
              <Checkbox isChecked={Formulario.exibirCompetencia} onChange={(e) => { setFormulario({ ...Formulario, exibirCompetencia: e.target.checked }); AtualizarExibirCompetencia() }}>Mostrar nome da competência acima da questão</Checkbox>
            )}
          </Flex>
        </Flex>
      </WhiteBox>
      <Flex marginTop={'1rem'} justifyContent={'end'} gap={'1rem'}>
        {
          (Formulario.status === 2) && (
            <Button VarColor='c6' size='lg' onClick={() => nav(-1)}>Voltar</Button>
          )
        }

        {
          (path.includes('Trilha')) && (
            <Button
              size='lg'
              VarColor='Azul'
              onClick={() => nav(`${path.replace('VisualizarFormulario', 'Formularios')}`)}
            >Alterar formulário selecionado</Button>
          )
        }

        {
          (Formulario.status !== 2) && (
            <>
              <Button
                VarColor='Purple1'
                size='lg'
                onClick={() => nav(`${path.replace('VisualizarFormulario', 'Formulario')}`)}
              >Editar formulário</Button>
              <Button
                size='lg'
                VarColor='v2'
                onClick={() => setAprovarFormularioIsOpen(true)}
                isDisabled={Formulario.questoes.length === 0}
              >Aprovar formulário</Button>
            </>
          )
        }

        {
          (Formulario.status === 2) && (

            (!query.get('leitura') && !path.includes('Trilha') && !path.includes('Processo')) && (
              <>
                <Button
                  size='lg'
                  VarColor='Azul'
                  onClick={() => setModalDuplicarIsOpen(true) }
                >Duplicar</Button>
                <Button
                  size='lg'
                  VarColor='v2'
                  onClick={() => nav(`${path.replace('VisualizarFormulario', 'Participantes')}`)}
                >Visualizar participantes</Button>
              </>
            )

          )
        }

        {
          (Formulario.status === 2 && (path.includes('Trilha') || path.includes('Processo'))) && (

            <Button
              size='lg'
              VarColor='v2'
              onClick={() => nav(`${path.replace('VisualizarFormulario', 'EnviarPesquisa')}`)}
            >Configurar envio da pesquisa</Button>
          )
        }
      </Flex>
    </Body>
  )
}
