import { Avatar, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, Text, useToast } from '@chakra-ui/react'
import { FaPlus } from 'react-icons/fa'
import { Button } from '../../../../../components/Button'
import { FindUser } from '../../../../../components/FindBox'
import { ModalBase } from '../../../../../components/ModalBase'
import { iNomeId, iPostProcessoOnboard } from '../../../../../interfaces'
import { CardPDI } from '../../../../PDI/Criar/styles'
import { useState, useEffect } from 'react'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'
import { useLocation } from 'react-router-dom'

interface iModal {
  onRequestClose: () => void
  onCreate: (form: iPostProcessoOnboard) => void
}

interface iModel {
  colaboradores: iNomeId[]
  rh: iNomeId[]
  trilhas: iNomeId[]
}

export const ModalAddProcesso: React.FC<iModal> = ({ onRequestClose, onCreate }) => {
  const { search } = useLocation()
  const tipo = search.split('tipo=')[1] ?? '0'
  const toast = useToast()

  const [Model, setModel] = useState<iModel>({
    colaboradores: [],
    rh: [],
    trilhas: []
  })
  const [SelecionadoId, setSelecionadoId] = useState<string>()
  const [TrilhaId, setTrilhaId] = useState<string>()
  const [Selecionado, setSelecionado] = useState<iNomeId>()
  const [TipoInicio, setTipoInicio] = useState('0')
  const [DataInicio, setDataInicio] = useState<string>()
  const [ResponsavelId, setResponsavelId] = useState<string>()

  const [ResponsavelIsInValid, setResponsavelIsInValid] = useState(false)
  const [ColaboradorIsInValid, setColaboradorIsInValid] = useState(false)
  const [TrilhaIsInValid, setTrilhaIsInValid] = useState(false)

  const [IsLoading, setIsLoading] = useState(false)

  function SeleionarColaborador(id: string): void {
    setSelecionadoId(id)
    if (id.length > 3) {
      setSelecionado(Model.colaboradores.find(e => e.id === id))
    } else {
      setSelecionado(undefined)
    }
  }

  function SeleionarResponsavel(id: string): void {
    setResponsavelId(id)
  }

  function InternalOnCreate(): void {
    setResponsavelIsInValid(false)
    setColaboradorIsInValid(false)
    setTrilhaIsInValid(false)

    if (PropInvalida(SelecionadoId)) {
      setColaboradorIsInValid(true)
      toast({
        title: 'Selecione um colaborador!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (PropInvalida(TrilhaId)) {
      setTrilhaIsInValid(true)
      toast({
        title: 'Selecione uma trilha!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (PropInvalida(ResponsavelId)) {
      setResponsavelIsInValid(true)
      toast({
        title: 'Selecione um responsável!',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    setIsLoading(true)
    const form: iPostProcessoOnboard = {
      colaboradorId: SelecionadoId ?? '',
      responsavelId: ResponsavelId ?? '',
      trilhaId: TrilhaId ?? '',
      data: DataInicio
    }
    onCreate(form)
  }

  function get(): void {
    appApi.get(`ProcessoOnboard/Gestao/Modal?tipo=${tipo}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    get()
  }, [])

  return (
    <ModalBase Titulo='Adicionar colaborador a uma trilha' isOpen onClose={onRequestClose} Width="60rem">
      <Flex gap={'1rem'} borderTop={'1px solid var(--Gray4)'} padding={'1rem 1rem 1rem'} flexDir={'column'}>
        <FormControl>
          <FormLabel>Selecione um colaborador cadastrado</FormLabel>
          <FindUser
            placeholder='Nome do colaborador'
            onChoice={SeleionarColaborador}
            lista={Model.colaboradores}
            isInvalid={ColaboradorIsInValid}
          />
        </FormControl>
        <Flex gap={'1rem'} alignItems='center'>
          <CardPDI width='30rem'>
            <Avatar size={'lg'} marginRight={'1rem'} src={Selecionado?.avatar} name={Selecionado?.nome} />
            <Flex textAlign={'center'} flexDir={'column'}>
              <span>{Selecionado?.nome}</span>
              <strong>{Selecionado?.complemento}</strong>
            </Flex>
          </CardPDI>

          <FormControl>
            <FormLabel>Selecione uma trilha</FormLabel>
            <Select isInvalid={TrilhaIsInValid} placeholder='Selecione uma trilha' onChange={(e) => setTrilhaId(e.target.value)} borderColor={'var(--Gray4)'}>
              {
                Model.trilhas.map((e, i) => {
                  return (
                    <option key={i} value={e.id}>{e.nome}</option>
                  )
                })
              }
            </Select>
          </FormControl>
        </Flex>
        <Flex flexDir={'column'}>
          <RadioGroup onChange={(e) => setTipoInicio(e)} value={TipoInicio}>
            <Stack direction='column'>
              <Radio value='0'>{(tipo === '1' || tipo === '2') && ('Iniciar trilha de desenvolvimento de acordo com a data de ingresso')}
              {(tipo === '0') && ('Iniciar onboarding de acordo com a data de ingresso')}
              {(tipo === '3') && ('Iniciar offboarding de acordo com a data de ingresso')}</Radio>
              <Radio value='1'>Iniciar com uma data personalizada</Radio>
            </Stack>
          </RadioGroup>

          {
            (TipoInicio === '1') && (
              <Input
                margin={'1rem 0 0 1rem'}
                width={'15rem'}
                type={'date'}
                onChange={(e) => setDataInicio(e.target.value)}
                borderColor='var(--Gray4)'
              />
            )
          }

          <Flex flexDir={'column'} margin='1rem 0'>
            <Text fontSize={'1.125rem'} fontWeight={'500'} marginBottom={'.5rem'}>
              {(tipo === '1' || tipo === '2') && (
                'Responsável do RH por essa trilha de desenvolvimento:'
              )}
              {(tipo === '0') && (
                'Responsável do RH por este onboarding:'
              )}
            </Text>
            <FindUser
              isInvalid={ResponsavelIsInValid}
              onChoice={SeleionarResponsavel}
              lista={Model.rh}
              placeholder='Nome do responsável'
            />
          </Flex>
        </Flex>
        <Flex gap={'1rem'} justifyContent={'end'}>
          <Button VarColor='c4' onClick={onRequestClose}>Cancelar</Button>
          <Button onClick={InternalOnCreate} isLoading={IsLoading} leftIcon={<FaPlus />} VarColor='Azul'>Adicionar colaborador a trilha</Button>
        </Flex>
      </Flex>
    </ModalBase>
  )
}
