import styled, { css } from 'styled-components'

export const Container = styled.div<{active?: boolean}>`
  cursor: pointer;
  border-radius: 0.625rem;
  display: flex;
  background: var(--c2);
  padding: 1.5rem;
  margin: 1rem 1rem 1rem 1rem;
  width: 30rem;

  ${props => props.active && css`
     box-shadow: var(--SombraBackground);`
  }

  ${props => props.active && css`
    border:0.313rem solid var(--v1);`
  }

  svg{
      margin-right: 1.5rem;
      color: var(--vg4);
  }
  
  ul{
      margin-left: 1.5rem;
  }
  
  h4{
      font-size: 1.25rem;
      margin-bottom: .5rem;
      color: var(--a4);
  }
  
  &:hover{
      background: #E7FFE4;
      font-weight: bold;
  }
  
  `
