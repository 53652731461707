import { Box, Text } from '@chakra-ui/react'

interface iProps {
  statusNome: string
  statusQuantidade: number
  VarColor: string
}

export const BoxReunioesStatus: React.FC<iProps> = ({ statusNome, statusQuantidade, VarColor }) => {
  return (
    <Box w={'13rem'} borderRadius={'1.5rem'} h={'11.75rem'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} color={`var(--${VarColor})`} border={`2px solid var(--${VarColor})`} fontWeight={'600'}>
      <Text fontSize={'16px'}>{statusNome}</Text>
      <Text fontSize={'64px'}>{statusQuantidade}</Text>
    </Box>
  )
}
