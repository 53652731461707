import { CardParticipante, Container } from './styles'
import { Button } from '../../../../../components/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { iColaboradorBase } from '../../../../../interfaces'
import { Avatar, Flex } from '@chakra-ui/react'

interface iDadoTabela {
  id: string
  origem: string
  totalAcoes: number
  dataFim: string
  colaborador?: iColaboradorBase
}

interface iTabelaProps {
  slice: number
  dados: iDadoTabela[]
}

export const TabelaAprovacao: React.FC<iTabelaProps> = ({ dados, slice }) => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  const colaboradorId = pathname.split('/').pop()

  function PaginaPDI(e: iDadoTabela): void {
    if (colaboradorId) {
      nav(`/Rh/VisualizarPerfil/${e.colaborador?.id ?? colaboradorId}/?pdiId=${e.id}`)
      return
    }
    nav(`/?pdiId=${e.id}`)
  }
  return (
    <Container>
      <thead>
        <tr>
          {dados.some(dado => dado.colaborador) && <th>Colaborador</th>}
          <th>Origem</th>
          <th>Quantidade de ações</th>
          <th>Data final</th>
        </tr>
      </thead>
      <tbody>
        {
          dados.slice(0, slice).map((e: iDadoTabela, i) => {
            return (
              <tr key={i}>
                {dados.some(dado => dado.colaborador) &&
                  <th>
                    <Flex justifyContent={'center'} gap='1rem' marginBottom='.5rem' >
                      <CardParticipante>
                        <Avatar height={'2.5rem'} width={'2.5rem'} marginRight={'.5rem'} size={'sm'} name={e?.colaborador?.nome} src={e?.colaborador?.avatar} />
                        <Flex flexDir={'column'} alignItems='flex-start'>
                          <span style={{ fontSize: '14px', textAlign: 'start' }}>{e?.colaborador?.nome}</span>
                          <strong style={{ fontSize: '14px', textAlign: 'start' }}>{e?.colaborador?.nomeDepartamento}</strong>
                        </Flex>
                      </CardParticipante>
                    </Flex>
                  </th>
                }
                <th>{e.origem}</th>
                <th>{e.totalAcoes}</th>
                <th>{e.dataFim}</th>
                <th>
                  <Button size={'sm'}
                    VarColor='Rosa' onClick={() => PaginaPDI(e)}
                  >Aprovar</Button>
                </th>
              </tr>
            )
          })
        }
      </tbody>
    </Container>
  )
}
