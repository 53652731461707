/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box, Flex, FormLabel, Grid, Input, Radio, RadioGroup, Stack, Text, Tooltip, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaComment, FaEye, FaPen, FaPencilAlt, FaPlus, FaSave } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { NavLine } from '../../../../components/NavLine'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iCompetenciaPDI, iImg } from '../../../../interfaces'
import { appApi, urlLocal } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { PropInvalida, useQuery, ConverterDataHora, SubstringRoute } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { iAcao } from '../../../PDI/Dashboard/Individual'
import { AcaoCard } from '../../../PDI/Dashboard/Individual/Components/Acao'
import { BoxConfiguracao } from './components/BoxConfiguracao'
import { CardPesquisa } from './components/CardPesquisa'
import { CardPontoDiscussao } from './components/CardPontoDiscussao'
import { CardReuniao } from './components/CardReuniao'
import { ModalRecusarAgendamento } from './components/ModalRecusarAgendamento'
import { ModalReagendar } from './components/ModalReagendar'
import { VisualizarPontoDiscussao } from './components/VisualizarPontoDiscussao'
import { CardFeedback } from './components/CardFeedback'
import { ModalDelete } from '../../../../components/ModalDelete'
import { ModalVisualizarComentarios } from '../../../PDI/Dashboard/Individual/Components/ModalVisualizarComentarios'
import { ModalFinalizarAcao } from '../../../PDI/Dashboard/Individual/Components/ModalFinalizarAcao'
import { iAvatarXInformacoes } from '../Dashboard/Individual'
import { Recomendacao } from './components/Recomendacao'

export interface iModel {
  configuracao: iConfiguracao
  datasDisponiveis?: string[]
  pontosDiscussao: iPontosDiscussao[]
  reunioes: iReuniao[]
  onboard: boolean
  pesquisa?: iPesquisa
  feedback?: iFeedback
  pdi?: iPDI
}

interface iPDI{
  id: string
  acoes?: iAcao[]
}

export interface iFeedback {
  id: string
  nome: string
  dataEnvio: string
  criador: iAvatarXInformacoes
  convidado: iAvatarXInformacoes
  respondente: number
  visualizarResposta: boolean
}

export interface iConfiguracao {
  tipo: string
  status: number
  criador: iAvatarXInformacoes
  convidado: iAvatarXInformacoes
  assunto: string
  local: string
  descricao: string
  data: string
  duracao: number
  liberarFinalizar: boolean
}

export interface iParticipante {
  id: string
  nome: string
  avatar: string
  complemento: string
}

export interface iPontosDiscussao {
  id: string
  concluido: boolean
  proximoPasso: boolean
  comentarios: iComentario[]
  anexos: iAnexo[]
  tipo: number
  titulo: string
  avatar: string
  descricao: string
  criadorId: string
}

export interface iComentario {
  id: string
  colaborador: iAvatarXInformacoes
  comentario: string
}

export interface iAnexo {
  id: string
  colaborador: iAvatarXInformacoes
  anexo: iImg
}

export interface iReuniao {
  id: string
  assunto: string
  status: number
  participante: iParticipante
  dataInicio: string
}

export interface iPesquisa {
  id: string
  nome: string
  dataInicio: string
  dataFim: string
  autoavaliacao: boolean
  lider: boolean
  avaliadores: number
  respostas: number
  participante: iParticipante
  recomendacoes?: iCompetenciaPDI[]
}

export interface iModalAgendamentoInput{
  excluirConvite?: boolean
  comentario?: string
  datas?: string[]
}

interface iModalFializar {
  pdiId: string
  acao: iAcao
}

function FormatarDataCSharp(data: string): string {
  const [day, month, yearComHora] = data.split('/')
  const year = yearComHora.split(' ')[0]
  const [Hours, Minutes] = yearComHora.split(' ')[1].split(':')
  return `${year}-${month}-${day}T${Hours}:${Minutes}`
}

export const AssuntoReuniaoOne: React.FC = () => {
  const { reuniaoId } = useParams<{ reuniaoId: string }>()
  const { pathname } = useLocation()
  const nav = useNavigate()
  const toast = useToast()
  const query = useQuery()

  const [DataSelecionada, setDataSelecionada] = useState<string>()
  const [Model, setModel] = useState<iModel>({
    onboard: false,
    configuracao: {
      assunto: '',
      data: '',
      descricao: '',
      duracao: 0,
      local: '',
      liberarFinalizar: false,
      convidado: {
        avatar: '',
        info1: '',
        id: ''
      },
      criador: {
        avatar: '',
        info1: '',
        id: ''
      },
      status: 4,
      tipo: 'Padrão'
    },
    pontosDiscussao: [],
    reunioes: []
  })
  const [PontoDiscussaoSelecionado, setPontoDiscussaoSelecionado] = useState<iPontosDiscussao>()

  const [CriarPonto, setCriarPonto] = useState(false)
  const [CriarPasso, setCriarPasso] = useState(false)
  const [AddComentario, setAddComentario] = useState(false)
  const [Texto, setTexto] = useState('')

  const [ModalRecusarAgendamentoIsOpen, setModalRecusarAgendamentoIsOpen] = useState(false)
  const [ModalReagendamentoIsOpen, setModalReagendamentoIsOpen] = useState(false)
  const [ModalDeletarIsOpen, setModalDeletarIsOpen] = useState(false)
  const [DeletarFeedbackIsOpen, setDeletarFeedbackIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  const [ModalVisualizarIsOpen, setModalVisualizarIsOpen] = useState(false)
  const [ModalFinalizarAcaoIsOpen, setModalFinalizarAcaoIsOpen] = useState(false)
  const [Acao, setAcao] = useState<iModalFializar>()

  const [AceitarIsLoading, setAceitarIsLoading] = useState(false)

  const isHost = Model.configuracao.criador.id === parseJwt().cid
  function Carregar(): void {
    appApi.get(`ReuniaoOne/${reuniaoId ?? ''}/Visualizar`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function ConcluirPontoDiscussao(id: string): void {
    appApi.put(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${id}/Concluir`)
      .then(() => {
        const pos = Model.pontosDiscussao.findIndex(e => e.id === id)
        if (pos === -1) return
        const copy = [...Model.pontosDiscussao]
        copy[pos].concluido = !copy[pos].concluido
        setModel({ ...Model, pontosDiscussao: copy })

        toast({
          title: 'Ponto de discussão concluido com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => console.log(err))
  }

  function ExcluirPontoDiscussao(): void {
    appApi.delete(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${Id}`)
      .then(() => {
        setModel({ ...Model, pontosDiscussao: [...Model.pontosDiscussao.filter(r => r.id !== Id)] })
        toast({
          title: 'Ponto de discussão excluido com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }).catch(err => console.log(err))
  }

  function ResponderSolicitacao(aceita: boolean): void {
    if (aceita) {
      setAceitarIsLoading(true)
      appApi.patch(`ReuniaoOne/${reuniaoId}/Aceitar?dataEscolhida=${FormatarDataCSharp(DataSelecionada ?? '')}`)
        .then(() => {
          setAceitarIsLoading(false)
          toast({
            title: 'Reunião agendada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          Carregar()
        })
        .catch(() => {
          toast({
            title: 'Erro ao agendar a reunião reunião!',
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          setAceitarIsLoading(false)
        })
    } else {
      setModalRecusarAgendamentoIsOpen(true)
    }
  }

  function FinalizarReuniao(): void {
    if (!Model.onboard) {
      appApi.patch(`ReuniaoOne/${reuniaoId ?? ''}/Finalizar`)
        .then(() => {
          toast({
            title: 'Reuniao finalizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          Carregar()
        })
        .catch(err => console.log(err))
    } else {
      appApi.patch(`EtapaColaborador/${Model.configuracao.convidado.id}/Etapa/${reuniaoId ?? ''}/Concluir`)
        .then(() => {
          toast({
            title: 'Reuniao finalizada com sucesso!',
            status: 'success',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
          Carregar()
        })
        .catch(err => console.log(err))
    }
  }

  function CriarPontoDiscussao(proximoPasso: boolean): void {
    appApi.post(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao?proximoPasso=${proximoPasso}`, {
      texto: Texto
    })
      .then(() => {
        toast({
          title: 'Ponto de discussão criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setCriarPasso(false)
        setCriarPonto(false)
        setTexto('')
        //   setModel({
        //   ...Model,
        //   pontosDiscussao: [...Model.pontosDiscussao, res.data]
        // })
        Carregar()
      }
      )
      .catch(err => console.log(err))
  }

  function AdionarComentario(): void {
    appApi.post(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${PontoDiscussaoSelecionado?.id ?? ''}/Comentario`, {
      texto: Texto
    })
      .then(() => {
        toast({
          title: 'Comentário criado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setAddComentario(false)
        setTexto('')
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function AdionarAnexo(event: any): void {
    const formdata = new FormData()
    formdata.append('arquivo', event.target.files[0])

    appApi.post(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${PontoDiscussaoSelecionado?.id ?? ''}/Anexo`, formdata)
      .then(() => {
        toast({
          title: 'Anexo adicionado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function ExcluirItemPontoDiscussao(id: string, variant: 'comentario' | 'anexo'): void {
    const tipo = variant === 'anexo' ? 'Anexo' : 'Comentario'
    appApi.delete(`ReuniaoOne/${reuniaoId ?? ''}/PontoDiscussao/${PontoDiscussaoSelecionado?.id ?? ''}/${tipo}/${id}`)
      .then(() => {
        toast({
          title: `${tipo} excluido com successo!`,
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        const pos = Model.pontosDiscussao.findIndex(e => e.id === PontoDiscussaoSelecionado?.id ?? '')
        if (pos !== -1) {
          const copyArr = [...Model.pontosDiscussao]
          if (Model.pontosDiscussao[pos].anexos) {
            copyArr[pos].anexos = copyArr[pos].anexos.filter(r => r.id !== id)
            setModel({ ...Model, pontosDiscussao: copyArr })
          }
          if (Model.pontosDiscussao[pos].comentarios) {
            copyArr[pos].comentarios = copyArr[pos].comentarios.filter(r => r.id !== id)
            setModel({ ...Model, pontosDiscussao: copyArr })
          }
        }
      })
      .catch(err => console.log(err))
  }

  function RecusarConvite(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${reuniaoId}/Recusar`, form)
      .then(() => {
        toast({
          title: 'Reunião recusada com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalRecusarAgendamentoIsOpen(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function ReagendarReuniao(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${reuniaoId}/Reagendar`, form)
      .then(() => {
        toast({
          title: 'Solicitação de reagendamento com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModalReagendamentoIsOpen(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function ReenviarFeedback(): void {
    appApi.post(`Feedback/${Model.feedback?.id}/ReenviarConvite`)
      .then(() => {
        toast({
          title: 'Convite de feedback renviado com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => console.log(err))
  }

  function VerificarBotao(nome: string): boolean {
    if (nome === 'responder') {
      return (isHost && (Model.feedback?.respondente === 0 || Model.feedback?.respondente === 2) && !Model.feedback.criador.info2?.includes('Respondido')) ||
      (!isHost && (Model.feedback?.respondente === 1 || Model.feedback?.respondente === 2) && !Model.feedback.convidado.info2?.includes('Respondido'))
    } else if (nome === 'visuzaliar resposta') {
      return (isHost && (Model.feedback?.criador.info2?.includes('Respondido') ?? false)) ||
      (!isHost && (Model.feedback?.visualizarResposta ?? false) && (Model.feedback?.convidado.info2?.includes('Respondido') ?? false))
    }
    return false
  }

  function onOpenModalDelete(id: string): void {
    setId(id)
    setModalDeletarIsOpen(true)
  }

  function onCloseModalDelete(): void {
    setId(undefined)
    setModalDeletarIsOpen(false)
  }

  function onOpenDeletarFeedback(id: string): void {
    setId(id)
    setDeletarFeedbackIsOpen(true)
  }

  function onCloseDeletarFeedback(): void {
    setId(undefined)
    setDeletarFeedbackIsOpen(false)
  }

  function onOpenModalVisualizarComentario(id: string, acao: iAcao): void {
    setModalVisualizarIsOpen(true)
    setAcao({ ...Acao, acao: acao, pdiId: id })
  }

  function onCloseModalVisualizarComentario(): void {
    setAcao(undefined)
    setModalVisualizarIsOpen(false)
  }

  function onOpenModalFinalizarAcao(id: string, acao: iAcao): void {
    setAcao({ ...Acao, acao: acao, pdiId: id })
    setModalFinalizarAcaoIsOpen(true)
  }

  function onCloseModalFinalizarAcao(): void {
    setAcao(undefined)
    setModalFinalizarAcaoIsOpen(false)
  }

  function FinalizarAcao(pdiId: string, acaoId: string, comentario: string): void {
    appApi.post(`PDI/${pdiId}/Acao/${acaoId}/Finalizar`, { texto: comentario })
      .then(() => {
        Carregar()
        onCloseModalFinalizarAcao()
      })
      .catch(err => console.log(err))
  }

  function ExcluirFeedback(): void {
    appApi.delete(`Feedback/${Id ?? ''}`)
      .then(() => Carregar())
      .catch(err => console.log(err))
  }

  function AtualizarTituloPontoDiscussao(id: string, texto: string): void {
    appApi.put(`ReuniaoOne/${reuniaoId}/PontoDiscussao/${id}`, {
      texto: texto
    })
      .then(() => {
        const pos = Model.pontosDiscussao.findIndex(e => e.id === id)
        if (pos === -1) return
        const copy = [...Model.pontosDiscussao]
        copy[pos].titulo = texto
        setModel({ ...Model, pontosDiscussao: copy })

        toast({
          title: 'Titulo atualizado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    Carregar()
  }, [])

  useEffect(() => {
    if (Model.pontosDiscussao.length > 0 && query.get('pontoId')) {
      setPontoDiscussaoSelecionado(Model.pontosDiscussao.find(r => r.id === query.get('pontoId')))
    } else {
      setPontoDiscussaoSelecionado(undefined)
    }
  }, [Model, query])

  return (
    <Body>
      <ModalVisualizarComentarios
        isOpen={ModalVisualizarIsOpen}
        onRequestClose={onCloseModalVisualizarComentario}
        acao={Acao?.acao}
      />

      <ModalFinalizarAcao
        isOpen={ModalFinalizarAcaoIsOpen}
        onRequestClose={onCloseModalFinalizarAcao}
        FinalizarAcao={FinalizarAcao}
        id={Acao?.pdiId as string}
        acao={Acao?.acao}
      />

      {ModalDeletarIsOpen && <ModalDelete
        isOpen
        message='Tem certeza que deseja excluir esse item? todos os comentários e anexos vinculados serão apagados do sistema!'
        onRequestClose={onCloseModalDelete}
        onConfirm={ExcluirPontoDiscussao}
        center
      />}

      {DeletarFeedbackIsOpen && <ModalDelete
        isOpen
        message='Tem certeza que deseja desvincular e excluir o feedback dessa reunião? Todas as informações relacionadas serão permanentemente excluídas!'
        onRequestClose={onCloseDeletarFeedback}
        onConfirm={ExcluirFeedback}
      />}

      {ModalRecusarAgendamentoIsOpen &&
        <ModalRecusarAgendamento
          alvo={parseJwt().cid === Model.configuracao.convidado.id ? Model.configuracao.criador : Model.configuracao.convidado}
          onClose={() => setModalRecusarAgendamentoIsOpen(false)}
          onRecusar={RecusarConvite}
        />
      }

      {ModalReagendamentoIsOpen &&
        <ModalReagendar
          alvo={parseJwt().cid === Model.configuracao.convidado.id ? Model.configuracao.criador : Model.configuracao.convidado}
          onReagendar={ReagendarReuniao}
          onClose={() => setModalReagendamentoIsOpen(false)}
        />
      }

      <NavLine options={[
        { nome: 'Reunião 1:1', path: `${SubstringRoute(pathname, 'Visualizar')}Dashboard/Individual` },
        { nome: 'Assunto da reunião' }
      ]}
      />

      <Flex gap={'1rem'}>
        <Flex flexDirection={'column'} gap='1rem' width={'100%'}>
          {PropInvalida(PontoDiscussaoSelecionado) &&
            <WhiteBox>
              {Model.configuracao.status !== 2 && !PropInvalida(Model.datasDisponiveis) && Model.datasDisponiveis && Model.datasDisponiveis.length > 0 &&
                <Flex mb={'1rem'} flexDir={'column'} alignItems={'center'}>
                  <h3>Envie a resposta dessa solicitação</h3>
                  <RadioGroup
                    onChange={(e) => Model.datasDisponiveis && setDataSelecionada(Model.datasDisponiveis[parseInt(e)])}
                    mt={'1rem'}
                  >
                    <FormLabel>Datas disponíveis</FormLabel>
                    <Stack direction={'column'}>
                      {
                        Model.datasDisponiveis.map((e, i) => {
                          return (
                            <Radio key={i} value={i.toString()}>{e}</Radio>
                          )
                        })
                      }
                    </Stack>
                  </RadioGroup>
                  <Flex mt={'1rem'} gap={'1rem'}>
                    <Box
                      as={Button}
                      height='inherit'
                      bg='var(--Green1)'
                      fontWeight={'500'}
                      padding={'1rem 2rem'}
                      color='white'
                      fontSize={'1.25rem'}
                      borderRadius='0.75rem'
                      cursor={'pointer'}
                      className={PropInvalida(DataSelecionada) ? 'disabled' : ''}
                      onClick={() => ResponderSolicitacao(true)}
                      isLoading={AceitarIsLoading}
                    >Aceitar</Box>
                    <Box
                      bg='var(--Red)'
                      fontWeight={'500'}
                      padding={'1rem 2rem'}
                      color='white'
                      fontSize={'1.25rem'}
                      borderRadius='0.75rem'
                      cursor={'pointer'}
                      onClick={() => ResponderSolicitacao(false)}
                    >Recusar</Box>
                  </Flex>
                </Flex>
              }
              <Flex mb={'1rem'} justifyContent={'space-between'} alignItems={'start'}>
                <Flex flexDir={'column'}>
                  <h3>{Model.configuracao.assunto}</h3>
                  <Text mt={'1rem'} fontSize={'14px'}>Local:
                  {!Model.configuracao.local.includes('http') &&
                  <strong> {Model.configuracao.local}</strong>
                  }
                  {Model.configuracao.local.includes('http') &&
                    <a
                      style={{ textDecoration: 'underline' }}
                      href={Model.configuracao.local}
                    > {Model.configuracao.local}</a>
                  }
                  </Text>
                </Flex>
                <Flex>
                  {isHost && Model.configuracao.liberarFinalizar &&
                    <Button
                      VarColor='Green2'
                      w={'7.8rem'}
                      h={'2.75rem'}
                      onClick={FinalizarReuniao}
                    >Finalizar</Button>
                  }
                </Flex>

                {Model.configuracao.status === 5 &&
                  <Button
                    VarColor='Azul'
                    h={'2.75rem'}
                    onClick={() => nav(`${SubstringRoute(pathname, 'Visualizar')}Criar?reuniaoId=${reuniaoId}`)}
                  >Solicitar nova reunião</Button>
                }
              </Flex>

              {!PropInvalida(Model.feedback) &&
                <Box border={'1px solid var(--Gray5)'} p={'1rem'} display={'flex'} justifyContent={'space-between'} mb={'1rem'} borderRadius={'1rem'}>
                  <Box>
                    <h4>Feedback</h4>
                    <Flex gap={'2rem'} my={'.5rem'} flexWrap={'wrap'}>
                      {(Model.feedback?.respondente === 0 || Model.feedback?.respondente === 2) &&
                        <CardFeedback
                          host={true}
                          participante={Model.feedback?.criador ?? {
                            avatar: '',
                            id: '',
                            info1: ''
                          }}/>
                      }
                      {(Model.feedback?.respondente === 1 || Model.feedback?.respondente === 2) &&
                        <CardFeedback
                          host={!isHost}
                          onReenviar={ReenviarFeedback}
                          blockReenviar={(ConverterDataHora(Model.feedback?.dataEnvio ?? '') > new Date())}
                          participante={Model.feedback?.convidado ?? {
                            avatar: '',
                            id: '',
                            info1: ''
                          }}/>
                      }
                    </Flex>
                    <Text fontSize={'12px'} fontWeight={'400'} color={'var(--Gray2)'}>{Model.feedback?.dataEnvio}</Text>
                  </Box>
                  {VerificarBotao('responder') &&
                    <Button
                      onClick={() => window.open(`${urlLocal}/Feedback/Abertura/${Model.feedback?.id ?? ''}`, '_blank')}
                      VarColor='Rosa'
                      fontSize={'12px'}
                      h={'2.375rem'}
                      rightIcon={<FaPencilAlt />}
                    >Responder formulário</Button>
                  }

                  {VerificarBotao('visuzaliar resposta') &&
                    <Button
                      VarColor='a1'
                      fontSize={'12px'}
                      h={'2.375rem'}
                      rightIcon={<FaEye />}
                      onClick={() => nav(`Feedback/${Model.feedback?.id ?? ''}/Visualizar`)}
                    >Visualizar formulário</Button>
                  }
                </Box>
              }

              <Box border={'1px solid var(--Gray5)'} p={'1rem'} borderRadius={'1rem'} mb={'1rem'}>
                <h4>Pontos para discussão</h4>
                {Model.pontosDiscussao.filter(e => !e.proximoPasso).map((e, i) => {
                  return (
                    <CardPontoDiscussao
                      key={i}
                      model={e}
                      onConcluir={ConcluirPontoDiscussao}
                      onExcluir={onOpenModalDelete}
                      onAtualizar={AtualizarTituloPontoDiscussao}
                      statusReuniao={Model.configuracao.status}
                    />
                  )
                })}
                {!CriarPonto && Model.configuracao.status !== 5 && <Button
                  fontSize={'12px'}
                  w={'100%'}
                  VarColor='Azul'
                  mt={'.5rem'}
                  rightIcon={<FaPlus />}
                  onClick={() => setCriarPonto(true)}
                >Adicionar discussão</Button>}
                {CriarPonto &&
                  <Flex gap={'.5rem'} flexDir={'column'}>
                    <Flex gap={'1rem'}>
                      <Input
                        placeholder='Titulo do ponto de discussão'
                        borderColor={'var(--Gray4)'}
                        onChange={(e) => setTexto(e.target.value)}
                      />
                      <Button onClick={() => CriarPontoDiscussao(false)} VarColor='Green2' rightIcon={<FaSave />}>Salvar</Button>
                    </Flex>
                    <Button
                      fontSize={'12px'}
                      w={'100%'}
                      VarColor='c4'
                      mt={'.5rem'}
                      onClick={() => setCriarPonto(false)}
                    >Cancelar</Button>
                  </Flex>
                }
              </Box>

              <Box border={'1px solid var(--Gray5)'} p={'1rem'} borderRadius={'1rem'}>
                <h4>Próximos passos</h4>
                {Model.pontosDiscussao.filter(e => e.proximoPasso).map((e, i) => {
                  return (
                    <CardPontoDiscussao
                      key={i}
                      model={e}
                      statusReuniao={Model.configuracao.status}
                      onConcluir={ConcluirPontoDiscussao}
                      onExcluir={onOpenModalDelete}
                      onAtualizar={AtualizarTituloPontoDiscussao}
                    />
                  )
                })}
                {!CriarPasso && Model.configuracao.status !== 5 && <Button
                  fontSize={'12px'}
                  w={'100%'}
                  VarColor='Azul'
                  mt={'.5rem'}
                  rightIcon={<FaPlus />}
                  onClick={() => setCriarPasso(true)}
                >Adicionar tópico</Button>}
                {CriarPasso &&
                  <Flex gap={'.5rem'} flexDir={'column'}>
                    <Flex gap={'1rem'}>
                      <Input
                        placeholder='Titulo do ponto de discussão'
                        borderColor={'var(--Gray4)'}
                        onChange={(e) => setTexto(e.target.value)}
                      />
                      <Button onClick={() => CriarPontoDiscussao(true)} VarColor='Green2' rightIcon={<FaSave />}>Salvar</Button>
                    </Flex>
                    <Button
                      fontSize={'12px'}
                      w={'100%'}
                      VarColor='c4'
                      mt={'.5rem'}
                      onClick={() => setCriarPasso(false)}
                    >Cancelar</Button>
                  </Flex>
                }
              </Box>
            </WhiteBox>
          }

          {PontoDiscussaoSelecionado &&
            <WhiteBox>
              <Flex mb={'1rem'} justifyContent={'space-between'} alignItems={'center'}>
                <Flex flexDir={'column'}>
                  <h3>{Model.configuracao.assunto}</h3>
                  <Text mt={'1rem'} fontSize={'14px'}>Local:
                  {!Model.configuracao.local.includes('http') &&
                  <strong> {Model.configuracao.local}</strong>
                  }
                  {Model.configuracao.local.includes('http') &&
                    <a style={{ textDecoration: 'underline' }} href={Model.configuracao.local}> {Model.configuracao.local}</a>
                  }
                  </Text>
                </Flex>
              </Flex>
              <Flex mb={'1rem'}>
                <Button onClick={() => nav(-1)} VarColor='Gray3'>Voltar</Button>
              </Flex>

              <Flex flexDir={'column'}>
              <Flex mb={'1rem'}>
                { PontoDiscussaoSelecionado.tipo === 1 &&
                  <h3>{Model.pesquisa?.nome}</h3>
                }

                {/* { PontoDiscussaoSelecionado.tipo === 2 &&
                  <Flex>
                      <h3>{PontoDiscussaoSelecionado.titulo}</h3>
                  </Flex>
                } */}
              </Flex>
                {Model.pesquisa && PontoDiscussaoSelecionado.tipo === 1 &&
                  <>
                    <CardPesquisa pesquisa={Model.pesquisa} />
                    {
                      (!PropInvalida(Model.pesquisa.recomendacoes)) && (
                        <Flex flexDir={'column'} mt='1rem' gap='1rem'>
                          <h3>Potencial para melhorias</h3>
                          <Grid templateColumns='repeat(2, 1fr)' gap={10}>
                            {
                              Model?.pesquisa?.recomendacoes?.map((e, i) => {
                                return (
                                  <Recomendacao pos={i + 1} recomendacao={e} key={i} />
                                )
                              })
                            }
                          </Grid>
                        </Flex>
                      )
                    }
                  </>
                }

                <Flex gap={'.5rem'} flexDir={'column'} mt={'1rem'}>
                  {PontoDiscussaoSelecionado?.comentarios.map((e, i) => {
                    return (
                      <VisualizarPontoDiscussao
                        key={i}
                        comentario={e}
                        participante={e.colaborador}
                        variant='comentario'
                        onExcluir={ExcluirItemPontoDiscussao}
                      />
                    )
                  })}
                  {PontoDiscussaoSelecionado?.anexos.map((e, i) => {
                    return (
                      <VisualizarPontoDiscussao
                        key={i}
                        anexo={e}
                        participante={e.colaborador}
                        variant='anexo'
                        onExcluir={ExcluirItemPontoDiscussao}
                      />
                    )
                  })}

                  {AddComentario &&
                  <Flex gap={'1rem'}>
                    <Input
                      placeholder='Digite um comentário'
                      borderColor={'var(--Gray4)'}
                      onChange={(e) => setTexto(e.target.value)}
                    />

                    <Button
                      onClick={AdionarComentario}
                      VarColor='Green2'
                      rightIcon={<FaSave />}
                    >Salvar</Button>
                  </Flex>}
                  <Flex gap={'1rem'}>
                    <Button
                      width={'50%'}
                      py='12px'
                      VarColor='Blue1'
                      onClick={() => setAddComentario(true)}
                      leftIcon={<FaComment />}
                    >Adicionar comentário</Button>

                    {PontoDiscussaoSelecionado.anexos.length < 2 &&
                      <Button
                        width={'50%'}
                        py='12px'
                        VarColor='Purple1'
                        leftIcon={<FaComment />}
                        cursor='pointer'
                      ><label htmlFor='file' style={{ cursor: 'pointer' }}>Adicionar anexo</label>
                      <input id='file' style={{ display: 'none' }} onChange={AdionarAnexo} type={'file'}/>
                      </Button>
                    }
                    {PontoDiscussaoSelecionado.anexos.length === 2 &&
                    <Tooltip
                      placement='top'
                      label='Você só pode adicionar no máximo 2 anexos por ponto de discussão!'
                      hasArrow
                      bg={'var(--Azul)'}
                      color={'white'}
                      p={'.5rem'}
                      mr={'.4rem'}
                    >
                      <Box width={'50%'}>
                        <Button
                          width={'100%'}
                          py='12px'
                          VarColor='Purple1'
                          isDisabled
                          leftIcon={<FaComment />}
                          cursor='pointer'
                        >Adicionar anexo</Button>
                      </Box>
                    </Tooltip>
                    }

                  </Flex>
                </Flex>
              </Flex>
            </WhiteBox>
          }

          {PontoDiscussaoSelecionado?.tipo === 1 && !PropInvalida(Model.pdi?.acoes) &&
            <WhiteBox>
              <Flex flexDir={'column'} gap='1rem'>
                <Flex justifyContent={'space-between'} alignItems='center'>
                  <h3>PDI da pesquisa</h3>
                  <Button onClick={() => nav(`/Desempenho/PDI/Editar/${Model.pdi?.id}`)} VarColor='Azul' leftIcon={<FaPen />}>Editar PDI</Button>
                </Flex>
                <Grid gap={'1rem'} templateColumns='repeat(3, 1fr)'>
                  {
                    Model.pdi?.acoes?.map((a, i2) => {
                      return (
                        <AcaoCard
                          key={i2}
                          onOpenComentario={onOpenModalVisualizarComentario}
                          onOpenFinalizar={onOpenModalFinalizarAcao}
                          acao={a}
                          pdiId={Model.pdi?.id ?? ''}
                        />
                      )
                    })
                  }
                </Grid>
              </Flex>
            </WhiteBox>
          }

          {!PropInvalida(Model.reunioes) && Model.reunioes.length > 0 &&
            <WhiteBox>
              <h3>Outras reuniões 1:1 com a mesma pessoa</h3>
              <Grid mt={'1rem'} templateColumns={'repeat(3, 1fr)'} gap={'1rem'}>
                {Model.reunioes?.map((e, i) => {
                  return (
                    <CardReuniao key={i} model={e} />
                  )
                })}
              </Grid>
            </WhiteBox>
          }

        </Flex>
        <BoxConfiguracao
          model={Model}
          onReagendar={() => setModalReagendamentoIsOpen(true)}
          onDeleteFeedback={onOpenDeletarFeedback}
        />
      </Flex>
    </Body>
  )
}
