import { Flex, Text, Box, Avatar, Tooltip } from '@chakra-ui/react'
import { FaPencilAlt, FaSync, FaClipboardList, FaCheckCircle, FaRegTimesCircle, FaTrash, FaPlus, FaCog } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { iModel } from '../..'
import { Button } from '../../../../../../components/Button'
import { WhiteBox } from '../../../../../../components/WhiteBox'
import { appApi } from '../../../../../../services/appApi'
import { parseJwt } from '../../../../../../services/token'
import { PropInvalida, SubstringRoute, transformarDuracao } from '../../../../../../Utils/Helper'
import { Legenda } from '../../../Configuracao/styles'
import { DropText } from './components/DropText'

interface iProps {
  model: iModel
  onReagendar: () => void
  onDeleteFeedback: (id: string) => void
}

export const BoxConfiguracao: React.FC<iProps> = ({ model, onReagendar, onDeleteFeedback }) => {
  const { reuniaoId } = useParams<{ reuniaoId: string }>()
  const nav = useNavigate()
  const { pathname } = useLocation()

  function ObterNomeStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'Aguardando'
      case '1':
        return 'Aceita'
      case '2':
        return 'Recusada'
      case '3':
        return 'Reagendada'
      case '4':
        return 'atrasada'
      case '5':
        return 'Finalizada'
      default: return ''
    }
  }

  function ObterCorStatus(status: number): string {
    switch (status.toString()) {
      case '0':
        return 'var(--Gray3)'
      case '1':
        return 'var(--Azul)'
      case '2':
        return 'var(--Rosa)'
      case '3':
        return 'var(--Purple1)'
      case '4':
        return 'var(--Red)'
      case '5':
        return 'var(--Green3)'
      default: return ''
    }
  }

  function CriarFeedback(): void {
    appApi.post(`Feedback?reuniaoId=${reuniaoId ?? ''}&participanteId=${model.configuracao.convidado.id}&gestorId=${model.configuracao.criador.id}`)
      .then(res => nav(`${SubstringRoute(pathname, 'Visualizar')}Feedback/${res.data as string ?? ''}/Formularios`))
      .catch(err => console.log(err))
  }

  return (
    <WhiteBox styles={{ minWidth: '18.5rem', maxWidth: '18.5rem' }}>
      <Flex alignItems={'center'} justifyContent='space-between'>
        <h4>Configurações da 1:1</h4>
        {model.configuracao.status !== 5 && model.configuracao.criador.id === parseJwt().cid &&
          <Tooltip
            placement='top'
            label='Editar reunião'
            hasArrow
            bg={'var(--Azul)'}
            color={'white'}
            p={'.5rem'}
            mr={'.4rem'}
          >
            <div>
              <FaCog
                cursor={'pointer'}
                size={25}
                color='var(--a1)'
                onClick={() => nav(`${pathname.replace('Visualizar', 'Editar')}`)}
              />
            </div>
          </Tooltip>
        }
      </Flex>
      <Flex gap={'3.5rem'} my={'1rem'}>
        <Box>
          <Text fontSize={'14px'} fontWeight={'700'}>Status</Text>
          <Text borderRadius={'6px'} color={'#fff'} bgColor={ObterCorStatus(model.configuracao.status)} px={'.5rem'} fontSize={'12px'} fontWeight={'400'} h={'1.4rem'}>
            {ObterNomeStatus(model.configuracao.status)}
          </Text>
        </Box>
        <Box borderLeft={'1px solid var(--Gray4)'} pl={'.5rem'}>
          <Text fontSize={'14px'} fontWeight={'700'}>Tipo</Text>
          <Legenda>{model.configuracao.tipo}</Legenda>
        </Box>
      </Flex>
      <hr style={{ border: '1px solid #d0d0d083', margin: '.5rem 0' }} />
      <Text mb={'.5rem'} fontSize={'0.875rem'} fontWeight={'700'}>Participantes</Text>

      <Flex gap={'.4rem'} alignItems={'center'} mb={'.5rem'}>
        <Avatar size={'sm'} src={model.configuracao.criador.avatar} name={model.configuracao.criador.info1} />
        <Box color={'var(--Gray3)'}>
          <Text fontSize={'14px'} fontWeight={'400'}>
            {model.configuracao.criador.info1}
          </Text>
          <Text mt={'-.3rem'} fontSize={'12px'} fontWeight={'700'}>
            Organizador
          </Text>
        </Box>
      </Flex>

      <Flex gap={'.4rem'} alignItems={'center'} mb={'.5rem'}>
        <Avatar size={'sm'} src={model.configuracao.convidado.avatar} name={model.configuracao.convidado.info1} />
        <Box color={'var(--Gray3)'}>
          <Text fontSize={'14px'} fontWeight={'400'}>
            {model.configuracao.convidado.info1}
          </Text>
          <Text mt={'-.3rem'} fontSize={'12px'} fontWeight={'700'}>
            Convidado
          </Text>
        </Box>
      </Flex>
      <DropText titulo='Data - Duração'>
        <Flex flexDirection={'column'}>
          <Legenda style={{ marginTop: '0' }}>{PropInvalida(model.configuracao.data) ? 'Data não definida' : model.configuracao.data}</Legenda>
          <Legenda style={{ marginTop: '0' }}>{transformarDuracao(model.configuracao.duracao)}</Legenda>
          {model.configuracao.status === 1 && <Button
            VarColor={'Rosa'}
            w={'6.4rem'}
            h={'1.8rem'}
            mt={'.6rem'}
            fontSize={'10px'}
            onClick={onReagendar}
            rightIcon={<FaSync style={{ marginLeft: '-5px', color: '#fff', marginRight: '-5px' }} />}
          >Reagendar</Button>}
        </Flex>
      </DropText>

      <DropText titulo='Descrição'>
        <Flex flexDirection={'column'}>
          <Legenda style={{ marginTop: '0' }} dangerouslySetInnerHTML={{ __html: model.configuracao.descricao }}></Legenda>
        </Flex>
      </DropText>

      <DropText titulo='Feedback'>
        {!PropInvalida(model.feedback) && model.feedback &&
          <Flex flexDirection={'column'} gap='.5rem' color={'var(--Gray3)'}>
            <Legenda style={{ marginTop: '0' }}>{model.feedback.nome}</Legenda>
            <Flex gap={'.5rem'}>
              <Avatar src={model.feedback.criador.avatar} size='sm' name={model.feedback.criador.info1} />
              <Flex alignItems={'center'} gap={'.3rem'}>
                <Box>
                  <Text mb={'-.5rem'} fontSize={'14px'} fontWeight={'400'}>{model.feedback.criador.info1}</Text>
                  <Legenda>{model.feedback.criador.info2}</Legenda>
                </Box>
              </Flex>
            </Flex>

            <Flex gap={'.5rem'}>
              <Avatar src={model.feedback.convidado.avatar} size='sm' name={model.feedback.convidado.info1} />
              <Flex alignItems={'center'} gap={'.3rem'}>
                <Box>
                  <Text mb={'-.5rem'} fontSize={'14px'} fontWeight={'400'}>{model.feedback.convidado.info1}</Text>
                  <Legenda>{model.feedback.convidado.info2}</Legenda>
                </Box>
              </Flex>
            </Flex>
            {model.feedback.criador.id === parseJwt().cid &&
              <Flex gap={'1rem'} justifyContent='center'>
                <Button
                  VarColor={'Azul'}
                  w={'5rem'}
                  h={'1.8rem'}
                  mt={'.6rem'}
                  fontSize={'10px'}
                  onClick={() => nav(`${SubstringRoute(pathname, 'Visualizar')}Feedback/${model.feedback?.id ?? ''}/Configuracao/Editar`)}
                  rightIcon={<FaPencilAlt style={{ marginLeft: '-5px', color: '#fff', marginRight: '-5px' }}
                  />}
                >Editar</Button>

                <Button
                  VarColor={'Red'}
                  w={'5rem'}
                  h={'1.8rem'}
                  mt={'.6rem'}
                  fontSize={'10px'}
                  onClick={() => onDeleteFeedback(model.feedback?.id ?? '')}
                  rightIcon={<FaTrash style={{ marginLeft: '-5px', color: '#fff', marginRight: '-5px' }}
                  />}
                >Excluir</Button>
              </Flex>
            }
          </Flex>
        }
        {PropInvalida(model.feedback) &&
          <Flex padding={'0 0 .5rem 0'} justifyContent='center' width={'100%'}>
            <Button
                VarColor='Green2'
                isDisabled={model.configuracao.status === 5 || model.configuracao.criador.id !== parseJwt().cid}
                rightIcon={<FaPlus />}
                size='sm'
                onClick={CriarFeedback}
            >Adicionar</Button>
          </Flex>
        }
      </DropText>

      {!PropInvalida(model.pesquisa) && model.pesquisa &&
        <DropText titulo='Pesquisa de percepção ativa'>
          <Flex flexDirection={'column'} color={'var(--Gray3)'}>
            <Text color={'var(--Gray3)'}>{model.pesquisa.nome}</Text>
            <Legenda style={{ marginTop: '0' }}>Início: {model.pesquisa.dataInicio}</Legenda>
            <Legenda style={{ marginTop: '0' }}>Fim: {model.pesquisa.dataFim}</Legenda>
            <Flex mt={'.8rem'} color={'var(--Gray2)'} gap={'1rem'}>
              <Flex gap={'.5rem'} alignItems='center'>
                <FaClipboardList size={32} />
                <div>
                  <Text fontWeight={'bold'} color={'var(--Gray2)'}>{model.pesquisa.respostas}/{model.pesquisa.avaliadores}</Text>
                  <span>Respostas</span>
                </div>
              </Flex>
              <Flex gap={'1rem'} pl={'.5rem'} borderLeft={'1px solid var(--Gray4)'}>
                <Flex justifyContent={'center'} alignItems={'start'} flexDir={'column'}>
                  {
                    (!PropInvalida(model.pesquisa.autoavaliacao)) && (
                      <Flex marginBottom={'.3rem'} alignItems='center'>
                        {model.pesquisa.autoavaliacao && <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />}
                        {!model.pesquisa.autoavaliacao && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                        <small>Autoavaliação</small>
                      </Flex>
                    )
                  }
                  {
                    (!PropInvalida(model.pesquisa.lider)) && (
                      <Flex marginBottom={'.3rem'} alignItems='center'>
                        {model.pesquisa.lider && <FaCheckCircle color='var(--v2)' style={{ marginRight: '.3rem' }} />}
                        {!model.pesquisa.lider && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                        <small>Gestor</small>
                      </Flex>
                    )
                  }
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </DropText>
      }
    </WhiteBox>
  )
}
