import styled, { css } from 'styled-components'

export const Main = styled.div`
margin-top: 2rem;

&>div{
    padding: 2rem;
}

.sah_Img{
  transform: scaleX(-1);
}
`

export const ScrollContainer = styled.div`
width: 75rem;
overflow-x: auto;
display: flex;
border:1px solid var(--Gray3);
padding:2rem;
border-radius: 8px;
scroll-snap-align: center;
`

export const StyledButton = styled.a<{margin?: string, selected?: boolean}>`
 display: flex;
 align-items: center;
 justify-content: center;
 border-radius: 0.75rem;
 font-weight: 500;
 color: var(--a1);
 background:none;
 padding: var(--mdPaddingBtn);
 font-size: var(--mdFontBtn);
 height: var(--mdHeigthBtn);
 line-height: 1px;
 border: 2px solid var(--a1);
 margin: ${props => props.margin};

 ${props => !props.selected && css`
    &:hover{
      transition:var(--transitionBtns);
      background:var(--a1);
      color: white;
    }
 `}

 ${props => props.selected && css`
     background: var(--a1);
     color:white;
 `}
`
