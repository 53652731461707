import styled from 'styled-components'

export const Container = styled.div`
display: flex;
align-items: center;
background: var(--c2);

small{
    font-weight: bold;
    font-size: 14px;
}
`

export const VideoBox = styled.video`
min-width:110px;
max-width:110px;
height: 110px;
margin-right: 1rem;
background-repeat: no-repeat;
background-size: contain;
background-position: center;
border-radius: .5rem;
border: 1px solid var(--c5);
`
