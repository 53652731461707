import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { FaClock, FaEye } from 'react-icons/fa'
import { IoArrowUndoSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { iDadoReuniao } from '../..'
import { Button } from '../../../../../components/Button'
import { transformarDuracao } from '../../../../../Utils/Helper'

interface iProps{
  model: iDadoReuniao
}
export const CardReuniaoOne: React.FC<iProps> = ({ model }) => {
  const nav = useNavigate()

  return (
        <Box border={'2px solid var(--Gray4)'} p={'.7rem'} borderRadius={'1.5rem'}>
            <Flex alignItems={'center'} justifyContent={'space-around'}>
                <Flex gap={'.5rem'} alignItems={'center'}>
                    <Avatar name={model.colaborador.info1} src={model.colaborador.avatar} />
                    <Box>
                        <Text fontSize={'12px'} fontWeight={'400'}>Solicitante</Text>
                        <Text fontWeight={'600'}>{model.colaborador.info1}</Text>
                    </Box>
                </Flex>
                <Box mx={'.5rem'} px={'1rem'} borderLeft={'1px solid var(--Gray5)'} borderRight={'1px solid var(--Gray5)'}>
                    <Text fontSize={'12px'} fontWeight={'400'}>Tipo</Text>
                    <Text fontWeight={'600'}>{model.tipo}</Text>
                </Box>
                <Box mr={'.5rem'}>
                    <Text fontSize={'12px'} fontWeight={'700'}>Data: {model.dataInicio}</Text>
                    <Text fontSize={'12px'} fontWeight={'700'}>Duração: {transformarDuracao(model.duracao)}</Text>
                </Box>
                {(model.status === 0 || model.status === 3) &&
                    <Button
                        w={'9rem'}
                        h={'2.4rem'}
                        fontSize={'12px'}
                        VarColor='Azul'
                        leftIcon={<IoArrowUndoSharp />}
                        onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${model.id}`)}
                    >Enviar resposta</Button>
                }

                {model.status === 2 &&
                    <Button
                        w={'6.875rem'}
                        h={'2.4rem'}
                        fontSize={'12px'}
                        VarColor='Red'
                        leftIcon={<FaClock />}
                    >Atrasada</Button>
                }

                {(model.status === 1) &&
                    <Button
                        w={'6.875rem'}
                        h={'2.4rem'}
                        fontSize={'12px'}
                        VarColor='Rosa'
                        leftIcon={<FaEye />}
                        onClick={() => nav(`/Jornada/NovaReuniaoOne/Visualizar/${model.id}`)}
                    >Visualizar</Button>
                }
            </Flex>
            <Box color={'#2D3748'} fontWeight={'600'} mt={'.6rem'}>
                <Text>{model.assunto}</Text>
                <Text dangerouslySetInnerHTML={{ __html: model.descricao }} fontSize={'12px'}></Text>
            </Box>
        </Box>
  )
}
