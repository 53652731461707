/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Avatar, Divider, Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { ImgSah } from '../../../../components/ImgSah'
import { ModalDelete } from '../../../../components/ModalDelete'
import { WhiteBox } from '../../../../components/WhiteBox'
import { appApi } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { Body } from '../../../Layouts/Body'
import { ModalComentario } from './Components/ModalComentario'
import { ModalRemarcar } from './Components/ModalRemarcar'
import { ModalReuniao } from './Components/ModalReuniao'
import { TabelaHistorico } from './Components/Tabela'
import { OutlineContainer } from './styles'

interface iDashboard {
  reunioes: iProximaReuniao[]
  reunioesAtrasadas: iProximaReuniao[]
  principal: iReuniaoPrincipal
  reuniaoHistorico: iReuniaoHistorico[]
}

interface iProximaReuniao {
  id: string
  dataInicio: string
  nomeParticipante: string
  assunto: string
}

interface iReuniaoPrincipal {
  id: string
  dataInicio: string
  diaSemana: string
  nomeParticipante: string
  assunto: string
  avatar: string
}

interface iReuniaoHistorico {
  id: string
  nomeParticipante: string
  nomeDepartamento: string
  tipoReuniao: string
  avatar: string
  dataConcluido: string
  feedback: string
  removido: boolean
}

interface iModelModal {
  id: string
  texto: string
}

export const DashboardReuniaoOne: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { pathname } = useLocation()

  const [Model, setModel] = useState<iDashboard>()

  const [ModalComentarioIsOpen, setModalComentarioIsOpen] = useState(false)
  const [ModalRemarcarIsOpen, setModalRemarcarIsOpen] = useState(false)
  const [ModalFeedbackIsOpen, setModalFeedbackIsOpen] = useState(false)
  const [Feedback, setFeedback] = useState('')

  const [ModelModal, setModelModal] = useState<iModelModal>()
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  const [IsLoading, setIsLoading] = useState(false)

  function getDashboard(): void {
    setIsLoading(true)
    appApi.get('ReuniaoOneOld/Dashboard')
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function ConcluirReuniaoPrincipal(texto: string): void {
    const param = ModelModal?.id ?? Model?.principal.id
    appApi.patch(`ReuniaoOneOld/${param}/Concluir`, { texto: texto })
      .then(() => {
        getDashboard()
        setModalComentarioIsOpen(false)
        if (ModalRemarcarIsOpen) {
          onCloseModalRemarcar()
        }
      })
      .catch(err => console.log(err))
  }

  function onRequestClose(): void {
    setFeedback('')
    setModalFeedbackIsOpen(false)
  }

  function onRequestOpen(texto: string): void {
    setFeedback(texto)
    setModalFeedbackIsOpen(true)
  }

  function onOpenModalRemarcar(id: string, text: string): void {
    setModelModal({
      id: id,
      texto: text
    })
    setModalRemarcarIsOpen(true)
  }

  function onCloseModalRemarcar(): void {
    setModelModal(undefined)
    setModalRemarcarIsOpen(false)
  }

  function RemarcarReuniao(): void {
    nav(`${pathname}/Configuracao/${ModelModal?.id}/Remarcar`)
  }

  function Excluir(): void {
    appApi.delete(`ReuniaoOneOld/${Id ?? ''}`)
      .then(() => {
        toast({
          title: 'Reunião excluida com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        getDashboard()
      })
      .catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    getDashboard()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalReuniao
        onClose={onRequestClose}
        isOpen={ModalFeedbackIsOpen}
        texto={Feedback}
      />
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir essa reunião?'
        onConfirm={Excluir}
        onRequestClose={onCloseModalDelete}
      />
      <ModalRemarcar
        isOpen={ModalRemarcarIsOpen}
        onRemarcar={RemarcarReuniao}
        onConcluir={() => setModalComentarioIsOpen(true)}
        onRequestClose={onCloseModalRemarcar}
        titulo={`Reunião atrasada - ${ModelModal?.texto}`}
      />

      <ModalComentario
        onSend={ConcluirReuniaoPrincipal}
        isOpen={ModalComentarioIsOpen}
        onClose={() => setModalComentarioIsOpen(false)}
      />
      <WhiteBox>
        <Flex marginBottom={'2rem'} justifyContent={'space-between'}>
          <h1>Reunião 1:1</h1>
          <Button
            onClick={() => nav('Configuracao')}
            VarColor='Azul' size={'lg'}
          >Solicitar reunião 1:1</Button>
        </Flex>
        {
          (Model) && (
            <Flex>
              <Flex flexDir={'column'} gap={'2rem 0'} width={'100%'}>
                {
                  (Model?.principal.dataInicio !== null) && (
                    <OutlineContainer>
                      <Flex justifyContent={'center'}>
                        <Flex flexDir={'column'} borderRight={'1px solid var(--Gray2)'} paddingRight={'1rem'} marginRight={'1rem'}>
                          <h3>Próxima reunião</h3>
                          <h2 className='gradText'>{Model.principal.dataInicio}</h2>
                          <span className='gradText'>{Model.principal.diaSemana}</span>
                        </Flex>
                        <Flex>
                          <Avatar
                            marginRight={'1rem'}
                            size={'lg'}
                            src={Model.principal.avatar}
                            name={Model.principal.nomeParticipante}
                          />
                          <Flex flexDir={'column'}>

                            <Flex flexDirection={'column'} marginBottom={'1rem'}>
                              <small style={{ fontWeight: 700, color: 'var(--Gray1)' }}>Participante</small>
                              <span style={{ color: 'var(--Gray1)' }}>{Model.principal.nomeParticipante}</span>
                            </Flex>

                            <Flex flexDirection={'column'}>
                              <small>Assunto</small>
                              <span>{Model.principal.assunto}</span>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex marginTop={'1rem'} justifyContent={'center'}>
                      {
                      (parseJwt().adm === 'True' || (parseJwt().role === '1' || parseJwt().role === '2')) && (
                        <Button onClick={() => setModalComentarioIsOpen(true)} VarColor='v2'>Concluir reunião</Button>
                      )
                      }
                      </Flex>
                    </OutlineContainer>
                  )
                }

                {
                  (Model?.reuniaoHistorico !== undefined && Model?.reuniaoHistorico.length > 0) && (
                    <OutlineContainer>
                      <h3>Histórico</h3>
                      <TabelaHistorico onOpenFeedback={onRequestOpen} reunioes={Model?.reuniaoHistorico} />
                    </OutlineContainer>
                  )
                }
              </Flex>

              {
                (Model.reunioes.length > 0 || Model.reunioesAtrasadas.length > 0) && (
                  <Flex marginLeft={'1rem'} width={'40%'}>
                    <OutlineContainer>
                      {
                        (Model.reunioes.length > 0) && (
                          <>
                            <h3>Próximas reuniões</h3>
                            <ul>
                              {
                                Model?.reunioes.map((e) => {
                                  return (
                                    <li key={e.id}>
                                      <strong>{e.dataInicio}</strong> - {e.assunto} <strong>{e.nomeParticipante}</strong>
                                      <MdClose
                                        cursor={'pointer'}
                                        color='var(--Red)'
                                        onClick={() => onOpenModalDelete(e.id)}
                                      />
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </>
                        )
                      }

                      {
                        (Model.reunioesAtrasadas.length > 0) && (
                          <>
                            {
                              (Model.reunioes.length < 0) && (
                                <Divider marginBottom={'1rem'} />
                              )
                            }
                            <h3>Reuniões atrasadas</h3>
                            <ul>
                              {
                                Model?.reunioesAtrasadas.map((e) => {
                                  return (
                                    <li
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                      key={e.id}
                                    >
                                      <strong onClick={() => onOpenModalRemarcar(e.id, e.assunto)}>{e.dataInicio}</strong> - {e.assunto} <strong>{e.nomeParticipante}</strong>
                                      <MdClose
                                        cursor={'pointer'}
                                        color='var(--Red)'
                                        onClick={() => onOpenModalDelete(e.id)}
                                      />
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </>
                        )
                      }
                    </OutlineContainer>
                  </Flex>
                )
              }
            </Flex>
          )
        }

        {
          (!Model && !IsLoading) && (
            <ImgSah entidade='reunião'>
              <Button
                onClick={() => nav('Configuracao')}
                VarColor='Azul' size={'lg'}
              >Solicitar reunião 1:1</Button>
            </ImgSah>
          )
        }
      </WhiteBox>
    </Body>
  )
}
