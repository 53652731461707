import {
  Column, FileBox,
  FirstDiv, LabelFile,
  LabelSelecionado, GuideText, BoxIntegracao, BoxHeader, VideoBox
} from './styles'
import { FaDownload, FaEye, FaFolder, FaMinusCircle } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import {
  Button, Flex, FormControl, FormLabel,
  Input, Spinner, useToast
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { iItemIntegracao } from '../../../../../../interfaces'
import { appApi } from '../../../../../../services/appApi'
import { EditorTexto } from '../../../../../../components/EditorTexto/Index'

interface ITipoCardIntegracaoVideo {
  position: number
  id: string
  ItemIntegracao: iItemIntegracao
  onDelete: (id: string) => void
  onUpdateInput: (item: iItemIntegracao) => void
}

export const CardVideo: React.FC<ITipoCardIntegracaoVideo> = ({ ItemIntegracao, id, position, onDelete, onUpdateInput }) => {
  const toast = useToast()
  const nav = useNavigate()

  const [Titulo, setTitulo] = useState('')
  const [Descricao, setDescricao] = useState('')
  const [Arquivo, setArquivo] = useState('')
  const [SpinnerOn, setSpinnerOn] = useState(false)

  const [IsLoading, setIsLoading] = useState(false)

  const [Tamanho, setTamanho] = useState('')
  const [NomeArquivo, setNomeArquivo] = useState('')

  useEffect(() => {
    if (ItemIntegracao.descricao !== undefined) {
      setIsLoading(true)
      setDescricao(ItemIntegracao.descricao)
    }
    setTitulo(ItemIntegracao.titulo)

    if (ItemIntegracao.arquivo !== null) {
      if (ItemIntegracao.arquivo !== undefined) {
        setArquivo(ItemIntegracao.arquivo.uri)
        setTamanho(ItemIntegracao.arquivo.size)
        setNomeArquivo(ItemIntegracao.arquivo.name)
      }
    }
  }, [])

  const AtualizarArquivo = (event: any): void => {
    setSpinnerOn(true)
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    appApi.post(`Imersao/${id}/Item/${ItemIntegracao.id}/uploadfile`, formdata).then(resposnse => {
      setTamanho(resposnse.data.size)
      setArquivo(resposnse.data.uri)
      setNomeArquivo(resposnse.data.name)
      toast({
        title: 'Arquivo cadastrado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 2000
      })
      setSpinnerOn(false)
    }).catch(() => {
      toast({
        title: 'Vídeo excedeu o limite de 25 MB',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 2000
      })
      setSpinnerOn(false)
    })
  }

  return (
    <BoxIntegracao>
      <BoxHeader>
        <span>{position}. Arquivo de vídeo</span>
        <FaMinusCircle onClick={() => onDelete(ItemIntegracao.id)}/>
      </BoxHeader>
      <Flex margin={'1rem 0rem 1rem 0rem'}>
        <FirstDiv>
          {
            <FileBox>
              {
                Arquivo.length === 0
                  ? <>
                    <FaDownload color='var(--c7)' size={35} />
                    {
                        SpinnerOn
                          ? <Spinner my={'2rem'}></Spinner>
                          : <>
                        <GuideText>Arraste e solte o arquivo de vídeo para fazer o envio</GuideText>
                        <small>(MP4 ou MOV)</small>
                        <small>Tamanho limite: 25 MB</small>
                        <input id={ItemIntegracao.id} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} /></>
                      }
                    <LabelFile theme={'4578D6'} htmlFor={ItemIntegracao.id}><FaFolder />Selecionar arquivo</LabelFile>
                  </>
                  : <>
                    {
                      SpinnerOn
                        ? <Spinner my={'2rem'}></Spinner>
                        : <Flex marginBottom={'1rem'}>
                      <VideoBox src={Arquivo} />
                      <Column>
                        <span>{NomeArquivo}</span>
                        <small>Tamanho: {Tamanho}</small>
                      </Column>
                    </Flex>
                    }
                    <div>
                      <input id={ItemIntegracao.id} type={'file'} onChange={(e) => { AtualizarArquivo(e) }} />
                      <LabelSelecionado htmlFor={ItemIntegracao.id}><FaFolder />Selecionar arquivo</LabelSelecionado>
                    </div>
                  </>
              }
            </FileBox>
          }
        </FirstDiv>

        <Flex width={'100%'} height={'100%'} flexDirection={'column'}>
          <FormControl>
            <FormLabel fontSize={'lg'}>Titulo</FormLabel>
            <Input
              value={Titulo}
              borderColor={'var(--Gray4)'}
              onChange={(e) => { setTitulo(e.target.value) }}
              type="text"
              placeholder='Propósito, missão, visão, valores'
              onBlur={() => onUpdateInput({
                id: ItemIntegracao.id,
                titulo: Titulo,
                descricao: Descricao,
                tipo: ItemIntegracao.tipo
              })}
            />
          </FormControl>

          <FormControl height={'100%'} marginTop={'1rem'}>
            <FormLabel fontSize={'lg'} >Descrição</FormLabel>
            <EditorTexto
              isLoading={IsLoading}
              initialValue={Descricao}
              placeholder='Deixe um texto para aparecer junto o video'
              className='editorSt-v2'
              onChange={(e: string) => { setDescricao(e) }}
              onBlur={() => onUpdateInput({
                id: ItemIntegracao.id,
                titulo: Titulo,
                descricao: Descricao.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">'),
                tipo: ItemIntegracao.tipo
              })}
            />
          </FormControl>
        </Flex>
      </Flex>

      <Flex justifyContent={'end'}>
        <Button
          leftIcon={<FaEye />}
          fontWeight={'500'}
          borderRadius={'12px'}
          bg={'var(--Rosa)'}
          _hover={{ bg: 'var(--Rosa)' }}
          onClick={() => nav(`Item/${ItemIntegracao.id}`)}
        >Pré-visualizar</Button>
      </Flex>
    </BoxIntegracao>
  )
}
