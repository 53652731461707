import styled from 'styled-components'
import { Column } from '../../../../styles/styledGlobal'

interface eixosNineBox {
  opacity: number
}

export const ColumnTexts = styled(Column)`
color: var(--a3);
h3{
    margin-bottom:.5rem;
}

span:not(:last-child){
margin-bottom: .3rem;
}
`

export const ImgPerfil = styled.img`
cursor: pointer;
&:hover {
    filter: brightness(0.5);
}
`
export const EixoNineBox = styled.div<eixosNineBox>`
  width: 8.75rem;
  height: 8.75rem;
  border-radius: 1rem;
  padding-top: 0.85rem;
  color: #fff;
  font-size: 16px;
  gap: .25rem;
  opacity: ${props => props.opacity};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
export const DashBox = styled.div`
margin-bottom: 1.5rem;
border:0.063rem solid var(--${props => props.theme});
border-style: dashed;
padding: .75rem;
border-radius: 0.938rem;
`
