import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { iEtapa, iNomeId } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Main, StyledLabel } from './styles'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Checkbox, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, useToast, Text } from '@chakra-ui/react'
import { ValidarEtapa } from '../../../Utils/Helper'
import { CreateEtapaBox } from '../../../components/CreateEtapaBox'
import { Button } from '../../../components/Button'
import { TimelineCriarPesquisa } from '../components/TimelineCriarPesquisa'
import { Legenda } from '../../Jornada/ReuniaoOne/Configuracao/styles'
import { FaChevronRight } from 'react-icons/fa'
import { WhiteBox } from '../../../components/WhiteBox'
import { FindUser } from '../../../components/FindBox'

interface iPesquisaPercepcao {
  nomePesquisa: string
  participantes: number[]
  escalaId: string
  responsaveisPrioridades: number[]
  responsavelId: string | undefined
  maximoCompetencias: number
  dataInicio: string
  dataFim: string
  aparecerCompetencia?: boolean
  liberarAvaliacaoAutomatica?: boolean
  permitirAlterarPeso?: boolean
  comentario?: boolean
  pesosAvaliadores: iPesosAvaliadores[]
  encerrarAutomatica?: boolean
}

interface iPesosAvaliadores {
  tipo: number
  peso: number
}

type iLocationProps = {
  trilhaId: string
  processoId: string
  colaboradorId: string
  etapaId: string
}

export const CriarPesquisaIndicativa: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const path = useLocation().pathname
  const { trilhaId, processoId, etapaId, colaboradorId } = useParams<iLocationProps>()

  const ctDefault = path.includes('Desempenho') ? 'AnaliseDesempenhoIndicativa' : path.includes('Indicativa') ? 'Indicativa' : ''
  const trilha = path.includes('Trilha') ? `Trilha/${trilhaId as string}` : path.includes('Processo') ? `Processo/${colaboradorId as string}` : undefined

  const pesquisaDefault: iPesquisaPercepcao = {
    nomePesquisa: '',
    participantes: [],
    escalaId: '31979e4c-89b1-4402-b64d-5e809d9cf768',
    responsaveisPrioridades: [0],
    responsavelId: '',
    comentario: false,
    aparecerCompetencia: false,
    liberarAvaliacaoAutomatica: false,
    permitirAlterarPeso: false,
    maximoCompetencias: 2,
    dataInicio: '',
    dataFim: '',
    encerrarAutomatica: false,
    pesosAvaliadores: [{ tipo: 6, peso: 1 }, { tipo: 4, peso: 1 }, { tipo: 3, peso: 1 }, { tipo: 2, peso: 1 }, { tipo: 1, peso: 1 }]
  }

  const [Pesquisa, setPesquisa] = useState<iPesquisaPercepcao>(pesquisaDefault)

  const [Etapa, setEtapa] = useState<iEtapa>({})

  const urlPai = processoId ? 'PesquisaPercepcaoEtapaColaborador' : 'PesquisaPercepcaoEtapa'

  const [isValid, setisValid] = useState(true)

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])

  const [OutroPrioridade, setOutroPrioridade] = useState(false)

  const handleCheckboxParticipanteClick = (value: number): void => {
    if (Pesquisa.participantes.find(e => e === value) !== undefined) {
      if (value === 7) {
        setPesquisa({ ...Pesquisa, participantes: [] })
      } else {
        const copy = [...Pesquisa.participantes].filter(e => e !== value)
        setPesquisa({ ...Pesquisa, participantes: copy })
      }
    } else {
      if (value === 7) {
        setPesquisa({ ...Pesquisa, participantes: [0, 1, 2, 3, 4, 6] })
      } else {
        const copy = [...Pesquisa.participantes]
        copy.push(value)
        setPesquisa({ ...Pesquisa, participantes: copy })
      }
    }
  }

  const handleCheckboxPrioridadeClick = (value: number): void => {
    if (Pesquisa.responsaveisPrioridades.find(e => e === value) !== undefined) {
      const copy = [...Pesquisa.responsaveisPrioridades].filter(e => e !== value)
      setPesquisa({ ...Pesquisa, responsaveisPrioridades: copy })
      if (value === 3) setOutroPrioridade(false)
    } else {
      const copy = [...Pesquisa.responsaveisPrioridades]
      copy.push(value)
      setPesquisa({ ...Pesquisa, responsaveisPrioridades: copy })
      if (value === 3) setOutroPrioridade(true)
    }
  }

  function criarPesquisa(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.dataInicio === '' && Pesquisa.dataInicio === '') {
      toast({
        title: 'Selecione uma data para a pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.participantes.length === 0) {
      toast({
        title: 'Selecione no mínimo um stakeholder',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.responsaveisPrioridades.length === 0) {
      toast({
        title: 'Selecione quem definirá as prioridades a serem trabalhadas',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!OutroPrioridade) {
      Pesquisa.responsavelId = undefined
    }
    appApi.post(`Pesquisa${ctDefault}`, Pesquisa)
      .then(response => {
        nav(`${path.replace('Criar', 'Demografia')}/${response.data as string}`)
      }).catch(({ response }) => {
        toast({
          title: response.data,
          status: 'error',
          position: 'top-right',
          isClosable: false
        })
      })
  }

  function CreateEtapaComposta(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.participantes.length === 0) {
      toast({
        title: 'Selecione no mínimo um stakeholder',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.escalaId === '') {
      toast({
        title: 'Selecione uma escala para esta pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Pesquisa,
      etapa: Etapa
    }

    appApi.post(`${urlPai}/${trilhaId ?? processoId ?? ''}`, form)
      .then(res => {
        nav(`/Rh/Pesquisa${ctDefault}/Formularios/${res.data as string}${trilha !== undefined ? `/${trilha}` : ''}`)
      })
      .catch(err => console.log(err))
  }

  function getById(): void {
    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.get(`${urlPai}/${param}`)
      .then(res => {
        setPesquisa(res.data.pesquisa)
        setEtapa(res.data.etapa)
      })
      .catch(err => console.log(err))
  }

  function UpdateEtapaComposta(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Pesquisa,
      etapa: Etapa
    }

    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.put(`${urlPai}/${param}`, form)
      .then(() => {
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function AtualizarEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function AtualizarPeso(tipoAvaliado: number, peso: number): void {
    setPesquisa({
      ...Pesquisa,
      pesosAvaliadores: [...Pesquisa?.pesosAvaliadores.filter(p => p.tipo !== tipoAvaliado), { tipo: tipoAvaliado, peso: peso }]
    })
  }

  useEffect(() => {
    if (etapaId) {
      getById()
    }
    getColaboradores()
  }, [])

  return (
    <Body>
      <TimelineCriarPesquisa color={['var(--Green1)']} />
      <Main>
        <h1>Criar pesquisa</h1>
        {
          (trilhaId ?? processoId) && (
            <WhiteBox margin='2rem 0'>
              <CreateEtapaBox
                EditEtapa={Etapa}
                ignoreId={etapaId}
                variant='Etapa'
                onModelChange={AtualizarEtapa}
                keyWord='pesquisa'
              />
            </WhiteBox>
          )
        }

        <WhiteBox>
          <FormControl marginBottom={'1rem'} isInvalid={!isValid} >
            <FormLabel fontSize={'x-large'}>Nome da pesquisa</FormLabel>
            <Input
              borderColor={'var(--Gray4)'}
              type={'text'}
              placeholder={'Digite um nome'}
              value={Pesquisa.nomePesquisa}
              onChange={(e) => setPesquisa({ ...Pesquisa, nomePesquisa: e.target.value })}
            />
          </FormControl>
          <Flex gap={'2rem'} alignItems={'end'} my={'1rem'}>
            <Box marginBottom={'1rem'}>
              <FormLabel fontSize={'x-large'}>Início</FormLabel>
              <Input
                w={'14.4rem'}
                borderColor={'var(--Gray4)'}
                type={'datetime-local'}
                min={new Date().toISOString().slice(0, 16)}
                onChange={(e) => setPesquisa({ ...Pesquisa, dataInicio: e.target.value })}
              />
            </Box>
            <Box marginBottom={'1rem'}>
              <FormLabel fontSize={'x-large'}>Fim</FormLabel>
              <Input
                w={'14.4rem'}
                borderColor={'var(--Gray4)'}
                type={'datetime-local'}
                min={Pesquisa?.dataInicio}
                onChange={(e) => setPesquisa({ ...Pesquisa, dataFim: e.target.value })}
              />
            </Box>
            <Checkbox pb={'1.7rem'} value={!Pesquisa?.encerrarAutomatica ? 0 : 1} flex={'1'}
              onChange={(e) => setPesquisa({ ...Pesquisa, encerrarAutomatica: !Pesquisa?.encerrarAutomatica })}>Encerrar quando todos os participantes responderem </Checkbox>
          </Flex>
          <Flex flexDir={'column'}>
            <StyledLabel>Defina os stakeholders</StyledLabel>
            <Stack direction={'column'} gap={'.75rem'} my={'1rem'}>
              <Checkbox
                borderColor={'var(--Gray4)'}
                isChecked={Pesquisa.participantes.includes(0)}
                onChange={() => handleCheckboxParticipanteClick(0)}
              >Autoavaliação</Checkbox>

              <Checkbox
                borderColor={'var(--Gray4)'}
                isChecked={Pesquisa.participantes.includes(1)}
                onChange={() => handleCheckboxParticipanteClick(1)}
              >Líder</Checkbox>

              <Flex gap={'1rem'}>
                <Checkbox
                  borderColor={'var(--Gray4)'}
                  isChecked={Pesquisa.participantes.includes(2)}
                  onChange={() => handleCheckboxParticipanteClick(2)}>
                  Pares
                </Checkbox>

              </Flex>

              <Flex gap={'1rem'}>
                <Checkbox
                  borderColor={'var(--Gray4)'}
                  isChecked={Pesquisa.participantes.includes(3)}
                  onChange={() => handleCheckboxParticipanteClick(3)}
                >Liderados</Checkbox>
              </Flex>

              <Flex gap={'1rem'}>
                <Checkbox
                  borderColor={'var(--Gray4)'}
                  isChecked={Pesquisa.participantes.includes(4)}
                  onChange={() => handleCheckboxParticipanteClick(4)}
                >Outros</Checkbox>
              </Flex>

              <Flex gap={'1rem'}>
                <Checkbox
                  borderColor={'var(--Gray4)'}
                  isChecked={Pesquisa.participantes.includes(6)}
                  onChange={() => handleCheckboxParticipanteClick(6)}
                >Alta liderança</Checkbox>
              </Flex>

              <Flex gap={'1rem'}>
                <Checkbox
                  borderColor={'var(--Gray4)'}
                  isChecked={Pesquisa.participantes.includes(7)}
                  onChange={() => handleCheckboxParticipanteClick(7)}
                >Todos</Checkbox>
              </Flex>
            </Stack>
          </Flex>

          <StyledLabel style={{ marginTop: '1rem' }}>Quem irá definir as prioridades a serem trabalhadas?</StyledLabel>
          <Legenda style={{ margin: 0, marginLeft: '2px' }}>Você pode definir mais de uma opção (Administradores sempre poderão alterar)</Legenda>
          <Stack direction={'row'} gap={'1rem'}>
            <Checkbox
              borderColor={'var(--Gray4)'}
              isChecked={Pesquisa.responsaveisPrioridades.includes(0)}
              onChange={() => handleCheckboxPrioridadeClick(0)}
            >Automático</Checkbox>
            <Checkbox
              borderColor={'var(--Gray4)'}
              isChecked={Pesquisa.responsaveisPrioridades.includes(1)}
              onChange={() => handleCheckboxPrioridadeClick(1)}
            >Gestor</Checkbox>
            <Checkbox
              borderColor={'var(--Gray4)'}
              isChecked={Pesquisa.responsaveisPrioridades.includes(2)}
              onChange={() => handleCheckboxPrioridadeClick(2)}
            >RH</Checkbox>
            <Flex gap={'.7rem'}>
              <Checkbox
                borderColor={'var(--Gray4)'}
                isChecked={Pesquisa.responsaveisPrioridades.includes(3)}
                onChange={() => handleCheckboxPrioridadeClick(3)}
              >
                Outro:
              </Checkbox>

              {OutroPrioridade &&
                <Flex flexDir={'column'}>
                  <FindUser
                    lista={Colaboradores}
                    onChoice={(e) => setPesquisa((prevState) => ({
                      ...prevState,
                      responsavelId: e
                    }))}
                    placeholder='Digite o nome do participante'
                    selecionado={Pesquisa.responsavelId}
                  />
                </Flex>
              }
            </Flex>
          </Stack>
          <StyledLabel style={{ margin: '1rem 0' }}>Campo para comentário ao final da seleção das competências do avaliador?</StyledLabel>
          <RadioGroup value={Pesquisa?.comentario ? '1' : '2'} onChange={(e) => setPesquisa({ ...Pesquisa, comentario: e === '1' })}>
            <Stack direction={'row'}>
              <Radio value={'1'}>Sim</Radio>
              <Radio value={'2'}>Não</Radio>
            </Stack>
          </RadioGroup>
          <StyledLabel style={{ marginTop: '2.5rem' }}>Máximo de competências que cada avaliador poderá selecionar</StyledLabel>
          <Legenda style={{ margin: 0, marginLeft: '2px' }}>O máximo é metade do número de competencias adicionadas</Legenda>
          <Select defaultValue={'2'} value={Pesquisa.maximoCompetencias} my={'.5rem'} onChange={(e) => setPesquisa({ ...Pesquisa, maximoCompetencias: Number(e.target.value) })}>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6</option>
          </Select>
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} color={'var(--Azul)'} gap={'.3rem'} borderRadius={'1.25rem'} marginBottom={'1rem'} px={'0'}>
                <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
                  Configurações avançadas
                  <AccordionIcon />
                </Flex>
                <hr style={{ border: '0.031rem solid var(--Azul)', flex: '1' }}></hr>
              </AccordionButton>
              <AccordionPanel gap={'1rem'}>
                <Flex direction='column' gap={'1rem'} mb={'1.5rem'}>
                  <Checkbox
                    value={!Pesquisa?.aparecerCompetencia ? 0 : 1}
                    onChange={() => setPesquisa({ ...Pesquisa, aparecerCompetencia: !Pesquisa?.aparecerCompetencia })}
                  >Aparecer o nome da competência na hora de responder a pesquisa</Checkbox>
                  <Checkbox
                    value={!Pesquisa?.liberarAvaliacaoAutomatica ? 0 : 1}
                    onChange={() => setPesquisa({ ...Pesquisa, liberarAvaliacaoAutomatica: !Pesquisa?.liberarAvaliacaoAutomatica })}
                  >Liberar avaliação completa usando priorização automática</Checkbox>
                </Flex>
                <Text fontWeight={'700'}>Definição do peso da resposta por tipo de avaliador</Text>
                <Flex gap={'2rem'}>
                  <Flex gap={'.5rem'} alignItems={'center'}>
                    <Text>Líder</Text>
                    <Select my={'.5rem'} onChange={(e) => AtualizarPeso(1, Number(e.target.value))}
                    >
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </Select>
                  </Flex>
                  <Flex gap={'.5rem'} alignItems={'center'}>
                    <Text>Pares</Text>
                    <Select my={'.5rem'} onChange={(e) => AtualizarPeso(2, Number(e.target.value))}>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </Select>
                  </Flex>
                  <Flex gap={'.5rem'} alignItems={'center'}>
                    <Text>Liderados</Text>
                    <Select my={'.5rem'} onChange={(e) => AtualizarPeso(3, Number(e.target.value))}>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </Select>
                  </Flex>
                  <Flex gap={'.5rem'} alignItems={'center'}>
                    <Text>Outros</Text>
                    <Select my={'.5rem'} onChange={(e) => AtualizarPeso(4, Number(e.target.value))}>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </Select>
                  </Flex>
                  <Flex gap={'.5rem'} alignItems={'center'}>
                    <Text style={{ whiteSpace: 'nowrap' }}>Alta liderança</Text>
                    <Select my={'.5rem'} onChange={(e) => AtualizarPeso(6, Number(e.target.value))}>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                    </Select>
                  </Flex>
                </Flex>
                <Checkbox mt={'1rem'}
                  value={!Pesquisa?.permitirAlterarPeso ? 0 : 1}
                  onChange={() => setPesquisa({ ...Pesquisa, permitirAlterarPeso: !Pesquisa?.permitirAlterarPeso })}
                >Permitir mudança de pesos na hora da priorização das competências</Checkbox>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </WhiteBox>

        <Flex justifyContent={'end'} marginTop='1rem' gap={'1rem'}>
          <Button
            size={'lg'}
            VarColor={'c6'}
            onClick={() => nav(-1)}
          >Voltar</Button>
          {
            (trilhaId === undefined && etapaId === undefined && processoId === undefined) && (
              <Button
                onClick={criarPesquisa}
                size={'lg'}
                VarColor={'v2'}
                rightIcon={<FaChevronRight />}
              >Criar pesquisa</Button>
            )
          }

          {
            ((trilhaId ?? processoId) && etapaId === undefined) && (
              <Button
                onClick={CreateEtapaComposta}
                size={'lg'}
                VarColor={'v2'}
              >Criar etapa</Button>
            )
          }

          {
            (etapaId) && (
              <Button
                onClick={UpdateEtapaComposta}
                size={'lg'}
                VarColor={'v2'}

              >Atualizar etapa</Button>
            )
          }
        </Flex>
      </Main>
    </Body>
  )
}
