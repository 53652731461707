/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { MdClose, MdGroup } from 'react-icons/md'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { iNomeId, iTag } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Header } from './styles'
import { Flex, FormControl, FormLabel, Input, Select, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { FindUser } from '../../../../../components/FindBox'
import { BsFillPersonFill } from 'react-icons/bs'

interface iModalAddAvaliado {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (avaliadoId: string, squadId?: string) => void
}

export const ModalAdicionarAvaliado: React.FC<iModalAddAvaliado> = ({ isOpen, onConfirm, onRequestClose }) => {
  Modal.setAppElement('#root')

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Areas, setAreas] = useState<iNomeId[]>([])
  const [Tags, setTags] = useState<iNomeId[]>([])
  const [Selecionado, setSelecionado] = useState('')
  const [Adicionado, setAdicionado] = useState<boolean>(false)
  const [EmailSelecionado, setEmailSelecionado] = useState<string>()

  // const [Squad, setSquad] = useState(false)
  const [Squads, setSquads] = useState<iTag[]>([])
  const [TipoFiltro, setTipoFiltro] = useState('0')
  const [SquadId, setSquadId] = useState<string>()

  function SelecionarGestor(id: string): void {
    setSelecionado(id)
    setEmailSelecionado(Colaboradores?.filter(e => e.id === id)[0]?.email)
  }

  function ObterDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => setAreas(res.data))
      .catch(err => console.log(err))
  }

  function ObterColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterSquads(): void {
    appApi.get('Tag/Select?tipo=2')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }

  function ObterTags(): void {
    appApi.get('Tag/Select?tipo=0')
      .then(res => setTags(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    ObterDepartamentos()
    ObterTags()
    ObterSquads()
    ObterColaboradores()
  }, [])

  useEffect(() => {
    if (!Selecionado) {
      setEmailSelecionado('')
    }
  }, [Selecionado])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Tabs>
      <Header>
        <Flex justifyContent={'space-between'}>
          <div>
            <h2>Adicione um avaliado</h2>
            <span>O usuário deve estar cadastrado no sistema para ser adicionado como participante na pesquisa. No entanto, seus stakeholders podem ser adicionados apenas com o e-mail, posteriormente na lista de participantes</span>
          </div>
          <MdClose color='var(--a3)' cursor={'pointer'} onClick={onRequestClose} />
        </Flex>
        <TabList gap={'1rem'} border={'none'} my={'.5rem'}>
          <Tab gap={'1rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.8rem'} bgColor={'var(--Azul)'}><BsFillPersonFill /> Convidar participante único</Tab>
          <Tab gap={'1rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.8rem'} bgColor={'var(--Azul)'}><MdGroup /> Convidar por área/squad/tag</Tab>
          <Tab gap={'1rem'} _selected={{ opacity: '100%', color: '#fff' }} opacity={'50%'} borderRadius={'.8rem'} bgColor={'var(--Azul)'}><MdGroup />Convidar todos os colaboradores</Tab>
        </TabList>
      </Header>
        <TabPanels>
          <TabPanel>
            <Flex gap={'1rem'} flexDir={'column'} padding={'1rem'}>
              <Flex flexDir={'column'}>
                <FormLabel>Nome do colaborador</FormLabel>
                <FindUser
                  lista={Colaboradores}
                  onChoice={SelecionarGestor}
                  placeholder='Digite o nome do participante'
                  selecionado={Selecionado}
                  adicionado={Adicionado}
                  setAdicionado={setAdicionado}
                />
              </Flex>

              <FormControl gap={'.5rem'} as={Flex} flexDirection={'column'}>
                <FormLabel fontSize={'md'} margin={0}>Email</FormLabel>
                <Input value={EmailSelecionado} placeholder='Digite o email do participante'/>
              </FormControl>
            </Flex>
          </TabPanel>
          <TabPanel>
          <Flex gap={'1rem'} flexDir={'column'} padding={'1rem'}>
            <FormControl gap={'.5rem'} as={Flex} flexDirection={'column'}>
              <FormLabel fontSize={'md'} margin={0}>Selecione a forma que deseja adicionar</FormLabel>
              <Select value={TipoFiltro} onChange={(e) => setTipoFiltro(e.target.value)}>
                <option value="0">Área/Squad/Tag</option>
                <option value="1">Área</option>
                <option value="2">Squad</option>
                <option value="3">Tag</option>
              </Select>
            </FormControl>
            {TipoFiltro === '1' && (
              <FormControl gap={'.5rem'} as={Flex} flexDirection={'column'}>
                <FormLabel fontSize={'md'} margin={0}>Área</FormLabel>
                <Select value={SquadId} onChange={(e) => setSquadId(e.target.value)}>
                  {Squads.map(e => {
                    return (
                      <option value={e.id}>{e.texto}</option>
                    )
                  })}
                </Select>
              </FormControl>
            )}
            {TipoFiltro === '2' && (
              <FormControl gap={'.5rem'} as={Flex} flexDirection={'column'}>
                <FormLabel fontSize={'md'} margin={0}>Squad</FormLabel>
                <Select value={SquadId} onChange={(e) => setSquadId(e.target.value)}>
                  {Squads.map(e => {
                    return (
                      <option value={e.id}>{e.texto}</option>
                    )
                  })}
                </Select>
              </FormControl>
            )}
            {TipoFiltro === '3' && (
              <FormControl gap={'.5rem'} as={Flex} flexDirection={'column'}>
                <FormLabel fontSize={'md'} margin={0}>Tag</FormLabel>
                <Select value={SquadId} onChange={(e) => setSquadId(e.target.value)}>
                  {Squads.map(e => {
                    return (
                      <option value={e.id}>{e.texto}</option>
                    )
                  })}
                </Select>
              </FormControl>
            )}
          </Flex>
          </TabPanel>
          <TabPanel></TabPanel>
        </TabPanels>
      </Tabs>
      <Flex alignItems={'center'} justifyContent={'end'} padding={'0rem 1rem 1rem 0rem'}>
        <MetaButton
          size='lg'
          bg='v2'
          onClick={() => {
            onConfirm(Selecionado, SquadId)
            setAdicionado(true)
          }}
        >Adicionar</MetaButton>
      </Flex>
    </Modal>
  )
}
