import { Button, Divider, Flex, FormLabel, Input, Text, Switch, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSave, FaTimes, FaTimesCircle } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { iPermissao } from '../../../contexts/AuthContext'
import { appApi, SigahApi } from '../../../services/appApi'
import { parseJwt } from '../../../services/token'
import { Body } from '../../Layouts/Body'
import { CriarContrato, IContrato } from '../CriarEmpresa/CriarContrato'

interface IEmpresa{

  nome: string
  razao: string
  cnpj: string
  telefone: string
  email: string
  segmento: string
}
interface IEmpresaDTO{

  nome: string
  razaoSocial: string
  cnpj: string
  telefone: string
  email: string
  seguimento: string
  plano: number
  contrato: IcontratoDto
}
interface IcontratoDto{
  inicio: Date
  fim: Date

  fee: IFee
}
interface IFee{
  maximo: 300
}

type IdModel ={

  id: string
}
type navProps ={
  empresaId: string
}
export const EditarEmpresa: React.FC = () => {
  const { empresaId } = useParams<navProps>()
  const [isLoading, setIsLoading] = useState(true)
  const [DataInicio, setDataInicio] = useState<Date>(new Date())
  const toast = useToast()
  // const [criarContrato, setCriarContrato] = useBoolean()
  const [Permissao, setPermissao] = useState<iPermissao>()
  const [Contrato, setContrato] = useState<IContrato>({
    desempenho: false,
    engajamento: false,
    fee: 0,
    fim: new Date(),
    inicio: new Date(),
    jornada: false,
    opp: '0',
    plano: false

  })
  const [Empresa, setEmpresa] = useState<IEmpresa>({
    nome: '',
    razao: '',
    cnpj: '',
    email: '',
    segmento: '',
    telefone: ''
  })

  useEffect(() => {
    if (Permissao) {
      setContrato({
        ...Contrato,
        desempenho: Permissao.desempenho,
        jornada: Permissao.jornada,
        engajamento: Permissao.engajamento,
        opp: Permissao.contratoAtual?.opp ?? '',
        inicio: Permissao.contratoAtual?.inicio ?? DataInicio,
        fim: Permissao.contratoAtual?.fim ?? new Date(),
        plano: Permissao.contratoAtual?.plano ?? false,
        fee: Permissao.contratoAtual?.fee ?? 5
      })
      // setFormC(true)
      setIsLoading(false)
    }
    if ((parseJwt().eid !== 'acbf6a22-154e-480d-a465-61b990faa8bd' && parseJwt().eid !== 'c5d7423c-5fbc-4917-a667-c2a2ec9693d7')) {
      navigate('/')
    }
  }, [Permissao])

  function OnSave(dados: IContrato): void {
    setContrato(dados)
  }
  const [formC, setFormC] = useState(false)
  const navigate = useNavigate()

  function salvar(): void {
    SigahApi.post<IdModel>(`/Adm/EditarEmpresaExt?eid=${empresaId ?? ''}`, {
      Nome: Empresa.nome,
      CNPJ: Empresa.cnpj,
      Email: Empresa.email,
      RazaoSocial: Empresa.razao,
      Telefone: Empresa.telefone,
      Segmento: Empresa.segmento,
      Plano: formC ? Contrato.plano : undefined,
      Fee: formC ? Contrato.fee : 5,
      Inicio: formC ? Contrato.inicio : undefined,
      Fim: formC ? Contrato.fim : undefined

    }).then((res) => {
      appApi.post('/Permissoes/editar', {
        eid: empresaId,
        plano: formC ? Contrato.plano : undefined,
        jornada: Contrato.jornada,
        engajamento: Contrato.engajamento,
        desempenho: Contrato.desempenho,
        opp: Contrato.opp,
        inicio: formC ? Contrato.inicio : undefined,
        fim: formC ? Contrato.fim : undefined,
        fee: formC ? Contrato.fee : 5
      }).then((res2) => {
        toast({
          title: 'Empresa editada com sucesso',
          position: 'top-right',
          variant: 'left-accent',
          status: 'success',
          duration: 9000,
          isClosable: false
        })
        navigate('/CentralComando')
      }
      ).catch(() => toast({
        title: 'Erro ao editar Permissões',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      }))
    }
    ).catch(
      () => toast({
        title: 'Erro ao editar Empresa',
        position: 'top-right',
        variant: 'left-accent',
        status: 'error',
        duration: 9000,
        isClosable: false
      })
    )
  }

  useEffect(() => {
    if (!empresaId) {
      navigate('/CentralComando')
    }

    SigahApi.get<IEmpresaDTO>(`Adm/GetEmpresa?eid=${empresaId ?? ''}`)
      .then(res => {
        setEmpresa({
          cnpj: res.data.cnpj,
          email: res.data.email,
          nome: res.data.nome,
          telefone: res.data.telefone,
          razao: res.data.razaoSocial,
          segmento: res.data.seguimento
        })
        console.log(res.data)
        setFormC(!(res.data.contrato === undefined || res.data.contrato === null))
        console.log(!(res.data.contrato === undefined || res.data.contrato === null))
        setDataInicio(res.data.contrato ? res.data.contrato.inicio : new Date())
      })
      .catch(err => console.log(err))

    appApi.get(`Permissoes/?eid=${empresaId ?? ''}`)
      .then(res => {
        console.log(res.data)
        setPermissao(res.data)
      }
      )
      .catch(err => console.log(err))
  }, [])

  return (
<Body isLoading={isLoading}>

<Flex direction={'column'} padding={'1rem'} borderRadius={'2xl'} boxShadow={'base'} backgroundColor={'white'}>

<h1>Editar empresa</h1>
<h4>Informações gerais da empresa</h4>
<FormLabel mt={'1rem'}>Nome fantasia</FormLabel>
<Input value={Empresa.nome} onChange={(e) => setEmpresa({ ...Empresa, nome: e.target.value })} placeholder='Digite o nome fantasia'/>

<FormLabel mt={'1rem'}>Razao social</FormLabel>
<Input value={Empresa.razao} onChange={(e) => setEmpresa({ ...Empresa, razao: e.target.value })}placeholder='Digite a Razao social'/>

<FormLabel mt={'1rem'}>CNPJ</FormLabel>
<Input value={Empresa.cnpj} onChange={(e) => setEmpresa({ ...Empresa, cnpj: e.target.value })} placeholder='Digite o CNPJ'/>

<FormLabel mt={'1rem'}>Telefone</FormLabel>
<Input value={Empresa.telefone} onChange={(e) => setEmpresa({ ...Empresa, telefone: e.target.value })} placeholder='Digite o Telefone'/>

<FormLabel mt={'1rem'}>E-mail do responsável</FormLabel>
<Input value={Empresa.email} onChange={(e) => setEmpresa({ ...Empresa, email: e.target.value })} placeholder='Digite o E-mail do responsável'/>

<FormLabel mt={'1rem'}>Segmento</FormLabel>
<Input value={Empresa.segmento} onChange={(e) => setEmpresa({ ...Empresa, segmento: e.target.value })} placeholder='Digite o segmento da empresa'/>

<Divider my={'2rem'}/>

<h4>Tipo de contrato</h4>

<Flex alignItems={'center'}>
  <Text mr='1rem'>FREE</Text>
  <Switch isChecked={formC} onChange={(e) => setFormC(e.target.checked)}/>
  <Text ml='1rem'>Contrato</Text>
  </Flex>

{formC && !isLoading &&
 <CriarContrato
desempenho={Contrato.desempenho}
engajamento={Contrato.engajamento}
fim={Contrato.fim}
inicio={Contrato.inicio}
fee={Contrato.fee}
jornada={Contrato.jornada}
opp={Contrato.opp}
plano={Contrato.plano}
OnSave={OnSave}

/>
}

{/* {!criarContrato && <Button onClick={() => {
  setCriarContrato.toggle()
  onToggle()
}} my={'2rem'} size={'sm'} width={'10rem'} backgroundColor={'var(--Azul)'} leftIcon={<FaPlus/>}>Adicionar contrato</Button>} */}

{formC && <Button leftIcon={<FaTimesCircle/>} onClick={() => {
  // setCriarContrato.toggle()
  setFormC(false)
}} my={'2rem'} size={'sm'} width={'10rem'} backgroundColor={'var(--Red)'} >Cancelar</Button>}

<Flex justifyContent={'end'}>
<Button onClick={() => navigate('/CentralComando')} mr={'1rem'} leftIcon={<FaTimes/>} size={'lg'} width={'10rem'} backgroundColor={'var(--c7)'}>Voltar</Button>
<Button onClick={salvar} leftIcon={<FaSave/>} size={'lg'} width={'10rem'} backgroundColor={'var(--Green1)'}>Salvar</Button>
</Flex>

</Flex>
</Body>

  )
}
