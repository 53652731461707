/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { CardColaborador, ColumnRespostaRh, ColumnTitulo, Container, ContainerCollapse } from './styles'
import { useState } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { appApi } from '../../../../services/appApi'
import { Button } from '../../../../components/Button'
import { parseJwt } from '../../../../services/token'
import { useNavigate } from 'react-router-dom'

interface Chamado {
  id: string
  dataCriacao: string
  texto: string
  status: number
  atualizacoes: Atualizacao[]
  colaborador: Colaborador
}

interface Atualizacao {
  colaborador: Colaborador
  resposta: string
  dataCriacao: string
  rh: true
}

interface Colaborador {
  id: string
  nome: string
  avatar: string
  nomeCargo: string
}

interface iBoxProps {
  chamado: Chamado
  responsavel: boolean
  reload: () => void
}

export const BoxMensagem: React.FC<iBoxProps> = ({ chamado, responsavel, reload }) => {
  const nav = useNavigate()
  const [Open, setOpen] = useState(false)
  const [Text, setText] = useState('')

  function putChamadoRH(): void {
    const form = {
      texto: Text
    }
    appApi.put(`Chamado/${chamado.id}`, form)
      .then(() => {
        reload()
        setText('')
      })
      .catch(err => console.log(err))
  }

  function EncerrarChamado(): void {
    appApi.patch(`Chamado/${chamado.id}/Encerrar`)
      .then(reload)
      .catch(err => console.log(err))
  }

  return (
    <Container active={chamado.atualizacoes.length > 1}>

      <Flex padding={'1rem'} justifyContent={chamado.status === 0 ? 'space-between' : 'end'}>
        {chamado.status === 0 && (responsavel || parseJwt().adm === 'True') && (
          <Button onClick={EncerrarChamado} VarColor='Rosa'>Encerrar chamado</Button>
        )}
        {
          (chamado.colaborador !== null && responsavel) && (
            <CardColaborador>
              <Avatar size={'lg'} marginRight={'1rem'} src={chamado.colaborador.avatar} name={chamado.colaborador.nome} />
              <Flex textAlign={'center'} flexDir={'column'}>
                <span>{chamado.colaborador.nome}</span>
                <strong>{chamado.colaborador.nomeCargo}</strong>
              </Flex>
            </CardColaborador>
          )
        }

        {
          (chamado.colaborador !== null && parseJwt().adm === 'True') && (
            <CardColaborador>
              <Avatar onClick={() => nav(`/Rh/VisualizarPerfil/${chamado.colaborador.id}`)} size={'lg'} marginRight={'1rem'} src={chamado.colaborador.avatar} name={chamado.colaborador.nome} />
              <Flex textAlign={'center'} flexDir={'column'}>
                <span>{chamado.colaborador.nome}</span>
                <strong>{chamado.colaborador.nomeCargo}</strong>
              </Flex>
            </CardColaborador>
          )
        }
      </Flex>
      <Flex padding={'0 1.5rem 1.5rem 1.5rem'} flexDir='column' gap={'1rem'}>
        <Flex>
          <Avatar marginRight={'.5rem'} src={chamado.colaborador.avatar} />
          <ColumnTitulo>
            <span>{chamado.dataCriacao}</span>
            <span>{chamado.texto}</span>
          </ColumnTitulo>
        </Flex>
        {
          chamado.atualizacoes.slice(0, 1).map((e, i) => {
            if (e.rh) {
              return (
                <Flex key={i}>
                  <Avatar marginRight={'.5rem'} src={e.colaborador.avatar} />
                  <ColumnRespostaRh >
                    <span>{e.dataCriacao}</span>
                    <span>{e.resposta}</span>
                  </ColumnRespostaRh>
                </Flex>
              )
            } else {
              return (
                <Flex key={i}>
                  <Avatar marginRight={'.5rem'} src={chamado.colaborador.avatar} />
                  <ColumnTitulo>
                    <span>{e.dataCriacao}</span>
                    <span>{e.resposta}</span>
                  </ColumnTitulo>
                </Flex>
              )
            }
          })
        }
        <ContainerCollapse active={Open}>
          {
            chamado.atualizacoes.slice(1, chamado.atualizacoes.length).map((e, i) => {
              if (e.rh) {
                return (
                  <Flex key={i}>
                    <Avatar marginRight={'.5rem'} src={e.colaborador.avatar} />
                    <ColumnRespostaRh>
                      <span>{e.dataCriacao}</span>
                      <span>{e.resposta}</span>
                    </ColumnRespostaRh>
                  </Flex>
                )
              } else {
                return (
                  <Flex key={i}>
                    <Avatar marginRight={'.5rem'} src={e.colaborador.avatar} />
                    <ColumnTitulo>
                      <span>{e.dataCriacao}</span>
                      <span>{e.resposta}</span>
                    </ColumnTitulo>
                  </Flex>
                )
              }
            })
          }
        </ContainerCollapse>
        {chamado.status === 0 && (
          <FormControl>
            <FormLabel fontSize={'xl'}>Mensagem</FormLabel>
            <Flex gap={'1rem'}>
              <Input value={Text} onChange={(e) => setText(e.target.value)} bg={'white'} placeholder='Digite sua resposta' borderColor={'var(--Gray4)'} />
              <Button VarColor='Azul' onClick={putChamadoRH}>Enviar</Button>
            </Flex>
          </FormControl>
        )}
      </Flex>
      {
        (chamado.atualizacoes.length > 1) && (
          <Flex onClick={() => setOpen(!Open)} bg={'var(--Rosa)'} justifyContent={'center'}>
            {
              !Open
                ? <>
                  <span>Visualizar respostas</span>
                  <FaAngleDown />
                </>
                : <>
                  <span>Recolher visualização</span>
                  <FaAngleUp />
                </>
            }
          </Flex>
        )
      }

      {
        (chamado.atualizacoes.length < 1) && (
          <Flex></Flex>
        )
      }
    </Container>
  )
}
