/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-tabs */
import { Accordion, Box, Checkbox, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Select, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronLeft, FaChevronRight, FaEdit, FaPlus, FaSearch } from 'react-icons/fa'
import { IoCloseOutline } from 'react-icons/io5'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { ElementoTooltip } from '../../../components/ElementoTooltip'
import { ModalDelete } from '../../../components/ModalDelete'
import { WhiteBox } from '../../../components/WhiteBox'
import { iSelecao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { TimelineCriarPesquisa } from '../components/TimelineCriarPesquisa'
import { ModalCompetencia } from './components/ModalCompetencia'

export interface iCompetenciaQuestao {
  nome: string
  icone: string
  questao: iQuestao
  id: string
  sistema: boolean
  dataCriacao: string
}

export interface iSelecoes {
  selecoes: iId[]
}
interface iId {
  competenciaId: string
}

interface iQuestao {
  id: string
  tipo: number
  enunciado: string
  doSistema: boolean
}

type iLocationProps = {
  formularioId: string

}

export const FormularioIndicativa: React.FC = () => {
  const nav = useNavigate()
  const path = useLocation().pathname
  const { formularioId } = useParams<iLocationProps>()
  const [Filter, setFilter] = useState<string>()
  const [SlicePorPagina, setSlicePorPagina] = useState<number>(15)

  const [Model, setModel] = useState<iCompetenciaQuestao[]>()
  const [CompetenciasSelecionadas, setCompetenciasSelecionadas] = useState<iCompetenciaQuestao[]>()
  const [Icones, setIcones] = useState<string[]>([])
  const [IsOpenModal, setIsOpenModal] = useState(false)
  const [Selecionado, setSelecionado] = useState<iCompetenciaQuestao>()
  const [Titulo, setTitulo] = useState('')
  const [ModalIsOpen, setModalIsOpen] = useState(false)
  const [Search, setSearch] = useState('')
  const [PaginaAtual, setPaginaAtual] = useState(1)

  function onCloseModal(): void {
    setIsOpenModal(false)
  }

  function onOpenModal(categoria?: string): void {
    setSelecionado(undefined)
    setIsOpenModal(true)
    setTitulo('Criar competência')
  }

  function onOpenModalEdit(competencia: iCompetenciaQuestao): void {
    setSelecionado(competencia)
    setIsOpenModal(true)
    setTitulo('Editar competência')
  }

  function onSelectCompetencia(comp: iCompetenciaQuestao): void {
    if (CompetenciasSelecionadas?.some(e => e.id === comp.id && e.questao === comp.questao)) {
      setCompetenciasSelecionadas(CompetenciasSelecionadas.filter(e => e.id !== comp.id && e.questao !== comp.questao))
    } else {
      setCompetenciasSelecionadas(CompetenciasSelecionadas ? [...CompetenciasSelecionadas, comp] : [comp])
    }
    SelecionarQuestao(comp.id, comp.questao.id)
  }

  function SelecionarQuestao(CompetenciaId: string, QuestaoId: string): void {
    const form: iSelecao = {
      competenciaId: CompetenciaId,
      questoesId: [
        QuestaoId
      ]
    }
    appApi.put(`Formulario/${formularioId as string}/competencia`, form)
      .catch(err => console.log(err))
  }

  function onRemoveCompetencia(id: string): void {
    setCompetenciasSelecionadas(CompetenciasSelecionadas?.filter(e => e.id !== id))

    appApi.delete(`Formulario/${formularioId as string}/competencia/${id}`).then(response => {
      setIcones((response.data as string[]).filter(e => e.includes('svg')))
    }).catch(err => console.log(err))
  }

  function onCreate(e: boolean): void {
    if (e) getCompetencias()
  }

  function getImgs(): void {
    appApi.get('Assets').then(response => {
      setIcones((response.data as string[]).filter(e => e.includes('svg')))
    }).catch(err => console.log(err))
  }

  function getCompetencias(): void {
    appApi.get('Competencia/categorias').then(response => {
      setModel(response.data)
      getFormulario()
    }).catch(err => console.log(err))
  }

  function getFormulario(): void {
    appApi.get(`Formulario/Indicativa?Id=${formularioId as string}`).then((res) => {
      setCompetenciasSelecionadas(res.data)
    }).catch(err => console.log(err))
  }

  function AprovarFormulario(): void {
    nav(`${path.replace('Formulario', 'VisualizarFormulario').replace(`/${formularioId as string}`, '')}`)
  }

  function ChangePage(valor: number): void {
    if (PaginaAtual === 1 && valor === -1 && Model?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length) {
      setPaginaAtual(Math.ceil(Model?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length / SlicePorPagina))
    } else if (Model?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length && PaginaAtual === Math.ceil(Model?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length / SlicePorPagina) && valor === 1) {
      setPaginaAtual(1)
    } else {
      setPaginaAtual(PaginaAtual + valor)
    }
  }

  function FilterBy(tipo: number): void {
    const arr = [...Model ?? []]

    if (tipo === 2) {
      arr.sort((a, b) => {
        return a.nome.toLocaleLowerCase().localeCompare(b.nome, 'pt-BR') > b.nome.toLocaleLowerCase().localeCompare(b.nome, 'pt-BR') ? 1 : -1
      })
    } else if (tipo === 3) {
      arr.sort((a, b) => {
        return b.nome.toLocaleLowerCase().localeCompare(b.nome, 'pt-BR') > a.nome.toLocaleLowerCase().localeCompare(b.nome, 'pt-BR') ? 1 : -1
      })
    } else if (tipo === 4) {
      arr.sort((a, b) => {
        return Date.parse(a.dataCriacao) > Date.parse(b.dataCriacao) ? 1 : -1
      })
    }
    setModel(arr)
  }

  useEffect(() => {
    getImgs()
    getCompetencias()
  }, [])

  useEffect(() => {
  }, [Search])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalIsOpen}
        message={'Após aprovar este formulário, você não poderá mais editar as questões. Deseja continuar?'}
        onRequestClose={() => setModalIsOpen(false)}
        onConfirm={AprovarFormulario}
      />

      <ModalCompetencia
        competencia={Selecionado}
        titulo={Titulo}
        icones={Icones}
        isOpen={IsOpenModal}
        onClose={onCloseModal}
        onCreate={(e) => onCreate(e)}
      />
      <TimelineCriarPesquisa color={['var(--Green1)', 'var(--Alaranjado)']} />
      <WhiteBox>
        <h1>Criando formulário</h1>
        <Text my={'1rem'} fontSize={'18px'}>Selecione as competências no menu lateral para serem adicionadas na pesquisa. Você pode alterar sua descrição para melhor compreenção da competência durante a pesquisa</Text>
        <Flex gap={'1rem'}>
          <Box display={'flex'} flexDirection={'column'} w={'50rem'} p={'1rem'} border={'1px solid var(--Azul)'} borderRadius={'1rem'}>
            <Flex justifyContent={'space-between'} mb={'1rem'}>
              <Text as={'h3'} fontWeight={'700'} color={'var(--Blue1)'}>Todas competências</Text>
              <Button fontSize={'12px'} VarColor='Azul' leftIcon={<FaPlus />} onClick={(e) => onOpenModal('3')}>Adicionar nova competência</Button>
            </Flex>

            <Flex justifyContent={'space-between'} alignItems={'flex-end'} mb={'1rem'}>

              <Flex w={'100%'} gap={'2rem'}>
                <Flex direction={'column'} >
                  <FormLabel fontSize={'xl'}>Ordenação</FormLabel>
                  <Select size='md'value={Filter} onChange={(e) => {
                    setFilter(e.target.value)
                    if (e.target.value === '2') {
                      FilterBy(2)
                    } else if (e.target.value === '3') {
                      FilterBy(3)
                    } else if (e.target.value === '4') {
                      FilterBy(4)
                    }
                  }} borderColor={'var(--Gray4)'}>
                    <option value={'2'}>A-Z</option>
                    <option value={'3'}>Z-A</option>
                    <option value={'4'}>Criação</option>
                  </Select>
                </Flex>

              </Flex>

              <InputGroup width={'17rem'}>
                <InputLeftElement
                  pointerEvents='none'
                  children={<FaSearch color='var(--Gray4)' />}
                />
                <Input
                  onChange={(e) => setSearch(e.target.value)}
                  borderColor={'var(--Gray4)'}
                  type='text'
                  placeholder='Busca'
                  bg={'white'}
                />
              </InputGroup>

            </Flex>

            <Accordion allowMultiple>
              {
                Model?.filter((e) => {
                  if (Search === '') {
                    return e
                  } else if (e.nome.toLocaleLowerCase().includes(Search.toLocaleLowerCase())) {
                    return e
                  }
                  return null
                }).slice((PaginaAtual - 1) * SlicePorPagina, (PaginaAtual * SlicePorPagina)).map((r, i) => {
                  const blockComp = CompetenciasSelecionadas?.some(e => e.id === r.id && e.questao?.id !== r.questao?.id)
                  return (

                    <WhiteBox styles={{ marginBottom: '1rem', opacity: blockComp ? '0.5' : '1', justifyContent: 'start' }} >
                      <ElementoTooltip label={blockComp ? 'Uma questão da competência já foi aescolhida.' : ''} bg={'red'}>
                        <Flex w={'100%'} alignItems={'center'} gap={'1rem'} justifyContent='space-between' >
                          <Checkbox w={'100%'} isDisabled={blockComp} isChecked={CompetenciasSelecionadas?.some(e => e.id === r.id && e.questao === r.questao)} borderColor={'var(--Gray3)'} key={r.id} my={'.2rem'} onChange={() => onSelectCompetencia(r)} >
                            <Flex alignItems={'center'} pl={'.5rem'}>
                              <div>
                                <Text fontWeight={'400'}>{r.nome}</Text>
                                <Text fontWeight={'400'} fontSize={'12px'}>{r.questao.enunciado}</Text>
                              </div>
                            </Flex>
                          </Checkbox>
                          <Flex>
                            <FaEdit onClick={() => onOpenModalEdit(r)} size={22} cursor={'pointer'} />
                          </Flex>
                        </Flex>
                      </ElementoTooltip>
                    </WhiteBox>
                  )
                })
              }
            </Accordion>

            <Flex mb={'1rem'}>
              {(Model?.length) &&
                <Flex mt={'.75rem'} gap={'.2rem'} fontSize={'14px'} color={'#fff'} justifyContent={'center'} w={'100%'}>
                  <Box cursor={'pointer'} onClick={() => ChangePage(-1)} bgColor={'var(--Azul)'} display={'flex'} alignItems={'center'} px={'.4rem'} py={'.2rem'} borderRadius={'6px 0 0 6px'}>
                    <FaChevronLeft />
                  </Box>
                  <Box bgColor={'var(--Azul)'} display={'flex'} alignItems={'center'} px={'.6rem'} py={'.2rem'} pb={'.4rem'}>
                    {`${PaginaAtual}/${Math.ceil(Model?.filter(e => e.nome.toLowerCase().includes(Search.toLowerCase())).length / SlicePorPagina)}`}
                  </Box>
                  <Box cursor={'pointer'} onClick={() => ChangePage(+1)} bgColor={'var(--Azul)'} display={'flex'} alignItems={'center'} px={'.4rem'} py={'.2rem'} borderRadius={'0px 6px 6px 0'}>
                    <FaChevronRight />
                  </Box>
                </Flex>
              }

            </Flex>
            <Flex alignItems={'center'} justifyContent='end' gap={'0.5rem'} >
              <Text fontSize={'xs'} fontWeight='medium'> Quantidade de competências por página</Text>

              <Select w='15' size={'xs'} value={SlicePorPagina} onChange={(e) => {
                setSlicePorPagina(Number(e.target.value))
              }} width={'30%'} borderColor={'var(--Gray4)'} >
                <option value={15}>15</option>
                <option value={30}>30</option>
                <option value={60}>60</option>
              </Select>
            </Flex>

          </Box>
          <Box>
            <Text as={'h3'} fontWeight={'700'} color={'var(--Blue1)'}>Competências selecionadas</Text>
            {CompetenciasSelecionadas?.map(a => {
              return (
                <Flex gap={'.5rem'} borderRadius={'4px'} px={'1rem'} py={'.5rem'} my={'.5rem'} boxShadow={'0 4px 4px #00000025'} justifyContent={'space-between'}>
                  <Text flex={'1'}>{a.nome}</Text>
                  {/* <Flex>
                    <FaEdit onClick={() => onOpenModalEdit(a)} cursor={'pointer'} size={18} />
                  </Flex> */}
                  <IoCloseOutline cursor={'pointer'} size={22} onClick={() => onRemoveCompetencia(a.id)} />
                </Flex>
              )
            })}
          </Box>
        </Flex>

      </WhiteBox >
      <Flex justifyContent={'end'} mt={'1.5rem'} gap={'1rem'}>
        <Button h={'3rem'} VarColor='Gray3' onClick={() => nav(-1)}>
          Voltar
        </Button>
        <Button
          h={'3rem'}
          onClick={() => AprovarFormulario()}
          size={'lg'}
          VarColor={'v2'}
          rightIcon={<FaChevronRight />}
        >Continuar</Button>
      </Flex>
    </Body >
  )
}
