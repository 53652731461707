/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Checkbox, Flex, Link, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { appApi } from '../../../../services/appApi'
import { LayoutFull } from '../../../Layouts/LayoutFull'
import { Container, HeaderContainer } from './styles'

interface iAberturaPesquisa {
  nomePesquisa: string
  msgAbertura?: string
  dataInicio?: string
  dataFim?: string
  status: number
}

export const AberturaPreenchimentoFeedback: React.FC = () => {
  const { feedbackId } = useParams<{ feedbackId: string }>()
  const { pathname } = useLocation()
  const state = useLocation().state as {gestor: boolean}
  const nav = useNavigate()
  const [isAccepted, setIsAccepted] = useState<boolean>(false)
  const [isAcceptedAnonimo, setIsAcceptedAnonimo] = useState<boolean>(false)
  const [Formulario, setFormulario] = useState<iAberturaPesquisa>()

  function getAbertura(): void {
    appApi.get(`Feedback/${feedbackId ?? ''}/Abertura?previsualizar=${pathname.includes('Previsualizar')}&gestor=${state?.gestor ?? false}`)
      .then(res => setFormulario(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getAbertura()
  }, [])

  return (
        <LayoutFull>
            <HeaderContainer>
                <h1>{Formulario?.nomePesquisa}</h1>
                <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' />
            </HeaderContainer>
            <Container>
                <div>
                    {(Formulario?.status === 0) && (
                        <>
                            <main>
                                <section>
                                    <span dangerouslySetInnerHTML={{ __html: Formulario.msgAbertura?.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">') ?? '' }}></span>
                                </section>
                                <br />

                                <p>Esse formulário não é anônimo!</p>
                                <br />
                                <p> Disponível de <strong>{Formulario?.dataInicio}{Formulario.dataFim && ` até ${Formulario.dataFim}`}.</strong>
                                </p>
                                <br />
                                <p>
                                    Para iniciar o preenchimento do formulário, aceite os termos e clique no botão abaixo.
                                </p>
                                <br />
                                <p>
                                    Ao clicar, você estará concordando com nossa política de
                                    privacidade, disponível nesse&nbsp;link:&nbsp;
                                    <Link
                                        href="https://sah.arquiteturahumana.com.br/Privacidade"
                                        isExternal
                                        fontWeight={'bold'}
                                        textDecoration={'underline'}
                                    > Política de privacidade
                                    </Link>
                                </p>
                                <br />
                                <Stack>
                                    <Checkbox
                                        borderColor={'var(--Gray4)'}
                                        onChange={(e) => setIsAccepted(e.target.checked)}
                                    >Aceito os termos e política de privacidade</Checkbox>

                                    <Checkbox
                                        borderColor={'var(--Gray4)'}
                                        onChange={(e) => setIsAcceptedAnonimo(e.target.checked)}
                                    >Estou ciente que o formulário não é anônimo</Checkbox>
                                </Stack>
                            </main>
                            <Flex marginTop={'1rem'}>
                                {(!pathname.includes('Previsualizar')) && (
                                    <Button
                                        onClick={() => nav(`${pathname.replace('Abertura', 'Preenchimento')}`)}
                                        VarColor='v2'
                                        size={'lg'}
                                        isDisabled={(!isAccepted || !isAcceptedAnonimo)}
                                    >Responder formulário</Button>
                                )}
                                {(pathname.includes('Previsualizar')) && (
                                    <Button
                                        VarColor='c4'
                                        size={'lg'}
                                        onClick={() => nav(-1)}
                                    >Voltar</Button>
                                )}
                            </Flex>
                        </>
                    )}

                    {(Formulario?.status === 1) && (/// Não iniciada
                        <main>
                            <section>
                                <span dangerouslySetInnerHTML={{ __html: Formulario.msgAbertura?.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">') ?? '' }}></span>
                                <br />
                                <br />
                            </section>
                            <p>
                                Esse formulário estará disponível em <strong>{Formulario.dataInicio}{Formulario.dataFim ? `e se encerrará até ${Formulario.dataFim}.` : '.'}</strong>
                            </p>
                            <p>
                                A partir da data de início, você poderá responder neste mesmo
                                link.
                            </p>
                            <p>Te vejo em breve</p>
                        </main>
                    )}

                    {Formulario?.status === 2 && (
                        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                            <h1>Pesquisa já encerrada</h1>
                        </Flex>
                    )}

                    {Formulario?.status === 3 && (
                        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                            <h2>Obrigado pela participação!!</h2>
                            <h3>Você já respondeu este formulário</h3>
                        </Flex>
                    )}
                </div>
            </Container>
        </LayoutFull>
  )
}
