/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Flex, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { WhiteBox } from '../../../components/WhiteBox'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { TimelineCriarPesquisa } from '../components/TimelineCriarPesquisa'
import { BoxMultiText } from '../EnviarPesquisa/Components/BoxMultiText'
import { ModalTag } from '../EnviarPesquisa/Components/ModalTag'

interface iModel {
  dataInicio?: string
  dataFim?: string
  textosAbertura: iTextosPesquisa[]
  textosConvite: iTextosPesquisa[]
  textosEncerramento: iTextosPesquisa[]
}

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

export const TextosIndicativa: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)
  // const [IsLoading, setIsLoading] = useState(false)

  const { pesquisaId } = useParams()
  const toast = useToast()
  const ctDefault = pathname.includes('Desempenho') ? 'AnaliseDesempenhoIndicativa' : pathname.includes('Indicativa') ? 'Indicativa' : ''

  const rotaDefault = pathname.includes('Desempenho') ? 'AnaliseDesempenho' : 'PesquisaPercepcao'

  const textPadraoEmail = `<p>Olá, [NOME DO RESPONDENTE]!</p><p>Você está recebendo o convite para o preenchimento da pesquisa de ${pathname.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'perpepção'} do(a) [NOME DO AVALIADO].</p><p>A pesquisa é simples e rápida.</p><p>O prazo para preenchimento é até o dia [DATA FIM]</p><p>[Link]</p><p>Qualquer problema no preenchimento da pesquisa, favor contatar o responsável.</p><p>A sua participação é fundamental para o sucesso dessa iniciativa.</p><p>Contamos com você!</p>`
  const textPadraoAbertura = `<p>Bem-vindo a pesquisa de ${pathname.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'percepção'} do [NOME DO AVALIADO].</p><br/> <p>A pesquisa é rápida e fácil, mas seja sincero e atento para o melhor desenvolvimento do avaliado.</p><br/> <p>Contamos com sua participação!</p>`
  const textPadraoEncerramento = '<strong>Obrigado por participar!</strong>'

  const [Model, setModel] = useState<iModel>({
    textosAbertura: [{ texto: textPadraoAbertura, participantes: [0, 1, 2, 3, 4, 6] }],
    textosEncerramento: [{ texto: textPadraoEncerramento, participantes: [0, 1, 2, 3, 4, 6] }],
    textosConvite: [{ texto: textPadraoEmail, participantes: [0, 1, 2, 3, 4, 6] }]
  })

  function UpdateTexto(tipo: number): void {
    if (tipo === 0) {
      appApi.put(`Pesquisa${ctDefault}/TextoEmail/${pesquisaId as string}`, Model.textosConvite)
        .then(response => {
          if (response.status === 200) {
            toast({
              title: 'Texto salvo com sucesso',
              status: 'success',
              isClosable: false,
              position: 'top-right'
            })
          } else {
            alert('Erro ao alterar o texto')
          }
        }).catch(err => console.log(err))
    } else if (tipo === 1) {
      appApi.put(`Pesquisa${ctDefault}/TextoAbertura/${pesquisaId as string}`, Model.textosAbertura)
        .then(response => {
          if (response.status === 200) {
            toast({
              title: 'Texto salvo com sucesso',
              status: 'success',
              isClosable: false,
              position: 'top-right'
            })
          } else {
            alert('Erro ao alterar o texto')
          }
        }).catch(err => console.log(err))
    } else if (tipo === 2) {
      appApi.put(`Pesquisa${ctDefault}/TextoEncerramento/${pesquisaId as string}`, Model.textosEncerramento).then(response => {
        if (response.status === 200) {
          toast({
            title: 'Texto salvo com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          alert('Erro ao alterar o texto')
        }
      }).catch(err => console.log(err))
    }
  }

  function getPesquisa(): void {
    appApi.get(`Pesquisa${ctDefault}/${pesquisaId as string}/Textos`)
      .then((res) => {
        setModel(res.data)
      })
      .catch((err) => { console.log(err) })
  }

  function handleContinuar(): void {
    appApi.put(`Pesquisa${ctDefault}/${pesquisaId as string}/Enviar`, Model)
      .then(() => {
        toast({
          title: 'Pesquisa programada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        navigate(`/Rh/${rotaDefault}/Gestao`)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getPesquisa()
  }, [])

  return (
    <Body>
      <ModalTag
        onClose={() => setModalTagIsOpen(false)}
        isOpen={ModalTagIsOpen}
      />

      <TimelineCriarPesquisa color={['var(--Green2)', 'var(--Alaranjado)', 'var(--terc9)', 'var(--RoxoClaro)']} />
      <h1>Enviar convites</h1>

      <WhiteBox margin='1rem 0rem'>
        <BoxMultiText
          titulo='Texto do convite para preenchimento da pesquisa'
          descricao='Esse texto será apresentado como e-mail ou notificação antes do respondente abrir a pesquisa.'
          textoPadrao={textPadraoEmail}
          openModalTag={() => setModalTagIsOpen(true)}
          model={Model.textosConvite}
          onChange={(e) => setModel({ ...Model, textosConvite: e })}
          onSave={() => UpdateTexto(0)}
        />
      </WhiteBox>

      <WhiteBox margin='1rem 0rem'>
        <BoxMultiText
          titulo='Texto de abertura da pesquisa'
          descricao='O respondente irá visualizar esse texto antes de começar a preencher a pesquisa, para visualizar exatamente como irá ficar, você pode pré-visualizar clicando no botão abaixo da caixa de edição de texto.'
          textoPadrao={textPadraoAbertura}
          openModalTag={() => setModalTagIsOpen(true)}
          model={Model.textosAbertura}
          onChange={(e) => setModel({ ...Model, textosAbertura: e })}
          onSave={() => UpdateTexto(1)}
        />
      </WhiteBox>

      <WhiteBox margin='1rem 0rem'>
        <BoxMultiText
          titulo='Texto de encerramento da pesquisa'
          descricao='Esse texto será apresentado quando o respondente terminar de responder uma pesquisa.'
          textoPadrao={textPadraoEncerramento}
          openModalTag={() => setModalTagIsOpen(true)}
          model={Model.textosEncerramento}
          onChange={(e) => setModel({ ...Model, textosEncerramento: e })}
          onSave={() => UpdateTexto(2)}
        />
      </WhiteBox>

      <Flex justifyContent={'end'} mt={'1.5rem'} gap={'1rem'}>
        <Button h={'3rem'} VarColor='Gray3' onClick={() => navigate(-1)}>
          Voltar
        </Button>
        <Button
          h={'3rem'}
          onClick={() => handleContinuar()}
          size={'lg'}
          VarColor={'v2'}
          rightIcon={<FaChevronRight />}
        >Enviar convites</Button>
      </Flex>

    </Body>
  )
}
