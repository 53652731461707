import styled, { css } from 'styled-components'

export const Container = styled.div`
hr{
    height: 1.5rem;
    width: .5rem;
    background: #${props => props.theme};
    border: none;
    margin-left: 2.8rem;
}
`

export const Content = styled.div`
display: flex;
align-items: center;
`

export const Card = styled.div<{disabled: boolean}>`
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    border-radius:2.5rem;
    min-height: 80px;
    padding-left: 2em;
    padding-right: 2rem;
    background: #${props => props.theme};
    box-shadow: var(--SombraBackground);

    svg{
        margin-left: 1rem;
    }

    &:hover{
        box-shadow: var(--SombraBtns);
        filter: brightness(.9);
    }

    ${props => props.disabled && css`
        opacity: 50%;
        pointer-events: none;
    `
  }
`

export const Circle = styled.div`
border: solid #${props => props.theme};
box-sizing: border-box;
display: flex;
justify-content:center ;
align-items: center;
font-family: 'Poppins','sans-serif';
text-align: center;
background: var(--c1);
color: var(--a3);
border-width: 0.3rem;
min-height: 6rem;
min-width: 6rem;
border-radius: 4rem;
font-size: 1rem;
font-weight: 800;
`

export const FlexElipse = styled.div`
display: flex;
height: 5.313rem;
align-items: center;
margin-left: .5rem;

div{
    margin-right:.3rem;
}

div:nth-child(1){
    height: 0.5rem;
    width: 0.5rem;
    background: var(--c4);
    border-radius: .5rem;
}

div:nth-child(2){
    height: 0.625rem;
    width: 0.625rem;
    background: var(--c6);
    border-radius: 0.625rem;
}

div:nth-child(3){
    height: .75rem;
    width: .75rem;
    background:#${props => props.color};
    border-radius: .75rem;
}
`
