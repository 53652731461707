/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Avatar, AvatarBadge, Flex, FormControl, FormLabel, Grid, Image, Menu, MenuButton, MenuItem, MenuList, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip, Text, Link, Box, Checkbox, NumberInput, NumberInputField, Img } from '@chakra-ui/react'
import { FaClipboard, FaEye, FaFileDownload, FaFrown, FaHeart, FaMedal, FaPlus, FaRegComment, FaSave, FaThumbsDown, FaTools } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { ComentariosContainer, InfoBox, StyledTd, TabelaAvaliacoes, TabelaIndividual, TabelaPersonalizada, TableDiv, Titulo } from './styles'
import { Recomendacao } from './components/Recomendacao'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi, urlApi } from '../../../services/appApi'
import { ReactNode, useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { iColaboradorBase, iCompetencia, iCompetenciaPDI, iEscala, iNomeId } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeAvaliadores.svg'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ModalPDI } from './components/ModalPDI'
import { DistinctString, PropInvalida } from '../../../Utils/Helper'
import { DashBox } from '../../../components/PerfilColaborador/styles'
import { Button } from '../../../components/Button'
import { AiFillTool, AiOutlineRise } from 'react-icons/ai'
import { WhiteBox } from '../../../components/WhiteBox'
import { GaugeChartIndicativa } from './components/GaugeChart'
import { RelatorioPDF } from './components/RelatorioPDF'
import IconLocation from './assets/iconLocation.svg'
import esquerdo from './assets/esquerdo.svg'
import direito from './assets/direito.svg'
import { AxiosResponse } from 'axios'
import { parseJwt } from '../../../services/token'

interface iResultadoIndividualIndicativa {
  tabelaPotenciaisMelhorias: iPotenciaisMelhorias
  tabelaAvaliacoes: iLinhaTabelaAvaliacoes[]
  discursivas: iDiscursiva[]
  tabelaRecomendacoes: iRecomendacoes
  resumoCompetencias: iCompetenciaPDI[]
  recomendacoes: iCompetenciaPDI[]
  grafico: IDadoGrafico2[]
  itemHistorico: iItemHistorico[]
  pesquisaId: string
  avaliado: iColaboradorBase
  nomePesquisa: string
  notaMaximaEscala: number
  totalAvaliadores: number
  totalRespostas: number
  mediaGeral: number
  participantes: number[]
  graficoHierarquia: IGrafico
  graficoIndividual: IGrafico
  recomendacoesAvaliacao: iCompetenciaPDI[]
  tabela: iTabela
  graficoComparativo: IGrafico
  graficoComparativoAutoavaliacao: IGrafico
  filtro: iNomeId[]
  comparativoId?: string
  pdf?: iPDF
  pdi: boolean
  pdiAvaliacao: boolean
  liberarResultado: number
  cores: iCorTabela[]
  responsaveisPrioridades: number[]
  responsavelPrioridade: string
}

// Inicio Potenciais melhorias
interface iPotenciaisMelhorias {
  responsaveisPrioridades: number[]
  responsavelId: string
  pesosAvaliadores: iPesosAvaliadores[]
  alterarPesos: boolean
  respostas: iResposta[]
  autoavaliacao: iAutoavaliacao[]
  stakes: number[]
}

interface iPesosAvaliadores {
  tipo: number
  peso: number
}

interface iResposta {
  notasStakes: iNotasStake[]
  notaPonderada: number
  competencia: string
  competenciaId: string
  priorizada: boolean
}

interface iAutoavaliacao {
  notas: number
  competencia: string
  competenciaId: string
}
interface iNotasStake {
  stake: string
  notas: number
  autoAvaliacao: boolean
}

// Fim Potenciais melhorias
export interface iDiscursiva {
  tipoAvaliador: number
  titulo: string
  texto: string
}

export interface IDadoGrafico {
  medias: number[]
  nome: string
}
export interface IDadoGrafico2 {
  media: string
  nome: string
  selo?: number[]
  melhoria: boolean
}
export interface IGrafico {
  competencias: string[]
  dados: IDadoGrafico[]
  definicoesCompetencias: string[]
}

export interface iCorTabela {
  eixo: string
  notasPositivas: number[]
  notasNegativas: number[]
  recomendacoes: number[]
}

export interface iLinhaTabela {
  competencia: string
  autoAvaliacao: string
  gestor?: string
  pares?: string
  outros?: string
  liderados?: string
  altalideranca?: string
  mediaSemAuto: string
  questoes: iLinhaTabela[]
}
export interface iLinhaTabela2 {
  competencia: string
  autoAvaliacao: string
  destaque?: number
  motiva?: number
  gestor?: string
  pares?: string
  outros?: string
  liderados?: string
  altalideranca?: string
  mediaSemAuto: string
  pontuacaoPonderada: string
  questoes: iLinhaTabela2[]
  priorizar: boolean
}

export interface iTabela {
  linhas: iLinhaTabela[]
}
export interface iTabela2 {
  linhas: iLinhaTabela2[]
  pesos: iPesos
  recomendacoes: iRecomendacoes
  tabelaAvaliacoes: iLinhaTabelaAvaliacoes[]
  discursivas: iDiscursiva[]
  itemHistorico: iItemHistorico[]
}

interface iLinhaTabelaAvaliacoes {
  competencia: string
  selos?: iSelos
}

interface iSelos {
  autoAvaliacao: iSelo[]
  liderImediato: iSelo[]
  pares: iSelo[]
  liderados: iSelo[]
  outros: iSelo[]
  altaLideranca: iSelo[]
}

interface iSelo {
  selo?: number
  quantidade: number
}
interface iItemHistorico {
  pesquisa: string
  avaliacaoId: string
  data: string
  destaques: iDestaqueHistorico[]
  potencialMelhoria: iDestaqueHistorico[]
}
interface iDestaqueHistorico {
  competencia: string
  avatar: string
}
interface iRecomendacoes {
  melhorias: iRecomendacao[]
  destaques: iRecomendacao[]
  outros: iRecomendacao[]
}

interface iRecomendacao {
  nome: string
  nota: string
  selos: number[]
}

interface iPesos {
  lider: string
  pares: string
  liderados: string
  outros: string
}

export interface iPI {
  urlSelo: string
  perfil: string
  assessmentId: string
  hide: boolean
}

interface iPostPDI {
  pesquisaId: string
  colaboradorId: string
  competencias: string[]
}

interface iPDF {
  escala: iEscala
  competencias: iCompetencia[]
  melhorCompetencia: iCompetencia
}

export interface iMediaAbsoluta {
  mediaAltaLideranca: number
  mediaPares: number
  mediaAutoAval: number
  mediaGestor: number
  mediaLiderados: number
  mediaOutros: number
  mediaSemAuto: number
}

export const ResultadoIndividualIndicativa: React.FC = () => {
  const PotenciaisMelhoriasDefault: iPotenciaisMelhorias = {
    responsaveisPrioridades: [],
    responsavelId: '',
    pesosAvaliadores: [],
    alterarPesos: false,
    respostas: [],
    stakes: [],
    autoavaliacao: []
  }

  const RecomendacoesDefault: iRecomendacoes = {
    melhorias: [],
    destaques: [],
    outros: []
  }

  const ModeloDefault: iResultadoIndividualIndicativa = {
    tabelaPotenciaisMelhorias: PotenciaisMelhoriasDefault,
    tabelaAvaliacoes: [],
    discursivas: [],
    tabelaRecomendacoes: RecomendacoesDefault,
    recomendacoes: [],
    resumoCompetencias: [],
    grafico: [],
    itemHistorico: [],
    pdi: false,
    pdiAvaliacao: false,
    cores: [],
    liberarResultado: 1,
    avaliado: {
      id: '',
      nome: ''
    },
    pdf: {
      melhorCompetencia: {
        discursiva: false,
        doSistema: false,
        icone: '',
        id: '',
        nome: '',
        questoes: []
      },
      competencias: [],
      escala: {
        id: '',
        fator: 0,
        nome: '',
        respostas: []
      }
    },
    filtro: [],
    graficoComparativo: {
      competencias: [],
      dados: [],
      definicoesCompetencias: []
    },
    graficoComparativoAutoavaliacao: {
      competencias: [],
      dados: [],
      definicoesCompetencias: []
    },
    graficoHierarquia: {
      competencias: [],
      dados: [],
      definicoesCompetencias: []
    },
    graficoIndividual: {
      competencias: [],
      dados: [],
      definicoesCompetencias: []
    },
    mediaGeral: 0,
    nomePesquisa: '',
    notaMaximaEscala: 0,
    participantes: [],
    pesquisaId: '',
    recomendacoesAvaliacao: [],
    tabela: {
      linhas: []
    },
    totalAvaliadores: 0,
    totalRespostas: 0,
    responsaveisPrioridades: [],
    responsavelPrioridade: ''
  }

  const { avaliacaoId } = useParams<{ avaliacaoId: string }>()
  const [ModoEdicao, setModoEdicao] = useState(false)
  const { pathname } = useLocation()
  const [PotenciaisMelhorias, setPotenciaisMelhorias] = useState<iPotenciaisMelhorias>(PotenciaisMelhoriasDefault)
  const [CompetenciasPriorizadas, setCompetenciasPriorizadas] = useState<string[]>([])
  const [Modelo, setModelo] = useState<iResultadoIndividualIndicativa>(ModeloDefault)

  const [Grafico1, setGrafico1] = useState<string>()
  const [Grafico2, setGrafico2] = useState<string>()
  const [PI, setPI] = useState<iPI>()

  const nav = useNavigate()
  const redirectPage = (url: string): any => { window.location.href = url }

  const [GraficoHierarquia, setGraficoHierarquia] = useState(Modelo.graficoHierarquia.dados.length === 2)

  const [ModalPDIsOpen, setModalPDIsOpen] = useState(false)
  const [ModalComentariosIsOpen, setModalComentariosIsOpen] = useState(false)

  const [Competencia, setCompetencia] = useState<string>()

  const [FiltroCompetencia, setFiltroCompetencia] = useState<string>('')
  const [FiltroTipo, setFiltroTipo] = useState<string>('')

  const [IsLoading, SetIsLoading] = useState(false)

  const [Grafico, setGrafico] = useState<IDadoGrafico2[]>([])

  function ObterIconeGrafico(index: number, media: string, value?: number[]): any {
    if (value && value[0] === 4) {
      return <><FaMedal style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '42%' : '46%'}`, color: 'var(--Green2)' }} /> {value[1] && value[1] === 1 ? <FaTools style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: '#66046B' }} /> : value[1] === 2 ? <FaHeart style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Azul)' }} /> : <FaFrown style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Orange)' }} />}</>
    } else if (value && value[0] === 1) {
      return <><FaTools style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '42%' : '46%'}`, color: '#66046B' }} /> {value[1] && value[1] === 4 ? <FaMedal style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Green2)' }} /> : value[1] === 2 ? <FaHeart style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Azul)' }} /> : <FaFrown style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Orange)' }} />}</>
    } else if (value && value[0] === 2) {
      return <><FaHeart style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '42%' : '46%'}`, color: 'var(--Azul)' }} /> {value[1] && value[1] === 4 ? <FaMedal style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Green2)' }} /> : value[1] === 1 ? <FaTools style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: '#66046B' }} /> : <FaFrown style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Orange)' }} />}</>
    } else if (value && value[0] === 3) {
      return <><FaFrown style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '42%' : '46%'}`, color: 'var(--Orange)' }} /> {value[1] && value[1] === 4 ? <FaMedal style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Green2)' }} /> : value[1] === 1 ? <FaTools style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: '#66046B' }} /> : <FaHeart style={{ position: 'absolute', top: `${(index * 69) + 57}`, transform: 'translate(-50%, 0)', left: `${parseFloat(media) >= 0 ? '40%' : '48%'}`, color: 'var(--Azul)' }} />}</>
    } else {
      return <></>
    }
  }

  function ObterIcone(obj: iSelo, usarQuantidade: boolean): any {
    if (obj.selo === 4) {
      return <Flex gap={'.5rem'} alignItems={'center'}>{usarQuantidade ? obj.quantidade : ''} <FaMedal style={{ color: 'var(--Green2)' }} /></Flex>
    } else if (obj.selo === 1) {
      return <Flex gap={'.5rem'} alignItems={'center'}>{usarQuantidade ? obj.quantidade : ''} <FaTools style={{ color: '#66046B' }} /></Flex>
    } else if (obj.selo === 2) {
      return <Flex gap={'.5rem'} alignItems={'center'}>{usarQuantidade ? obj.quantidade : ''} <FaHeart style={{ color: 'var(--Azul)' }} /></Flex>
    } else if (obj.selo === 3) {
      return <Flex gap={'.5rem'} alignItems={'center'}>{usarQuantidade ? obj.quantidade : ''} <FaFrown style={{ color: 'var(--Orange)' }} /></Flex>
    } else {
      return <></>
    }
  }

  function getResultadoIndicativa(): void {
    SetIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId as string}/ResultadoIndividualIndicativa`)
      .then((res: AxiosResponse<iResultadoIndividualIndicativa>) => {
        setPotenciaisMelhorias(res.data.tabelaPotenciaisMelhorias)
        setCompetenciasPriorizadas(res.data.tabelaPotenciaisMelhorias.respostas.filter(e => e.priorizada).map(e => e.competenciaId))
        setGrafico(res.data.grafico)
        setModelo(res.data)
        SetIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function handleMedia(): iMediaAbsoluta[] {
    const medias = {
      mediaAltaLideranca: 0,
      mediaPares: 0,
      mediaAutoAval: 0,
      mediaGestor: 0,
      mediaLiderados: 0,
      mediaOutros: 0,
      mediaSemAuto: 0
    }
    Modelo.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.pares ?? '')
      medias.mediaPares = medias.mediaPares + (isNaN(parse) ? 0 : parse)
    })
    Modelo.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.autoAvaliacao ?? '')
      medias.mediaAutoAval = medias.mediaAutoAval + (isNaN(parse) ? 0 : parse)
    })
    Modelo.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.gestor ?? '')
      medias.mediaGestor = medias.mediaGestor + (isNaN(parse) ? 0 : parse)
    })
    Modelo.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.liderados ?? '')
      medias.mediaLiderados = medias.mediaLiderados + (isNaN(parse) ? 0 : parse)
    })
    Modelo.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.mediaSemAuto ?? '')
      medias.mediaSemAuto = medias.mediaSemAuto + (isNaN(parse) ? 0 : parse)
    })
    Modelo.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.outros ?? '')
      medias.mediaOutros = medias.mediaOutros + (isNaN(parse) ? 0 : parse)
    })
    Modelo.tabela.linhas.forEach((e): void => {
      const parse = parseFloat(e.altalideranca ?? '')
      medias.mediaAltaLideranca = medias.mediaAltaLideranca + (isNaN(parse) ? 0 : parse)
    })
    const mediasCalculado = [{
      mediaAltaLideranca: medias.mediaAltaLideranca / Modelo.tabela.linhas.filter(r => r.altalideranca).length,
      mediaPares: medias.mediaPares / Modelo.tabela.linhas.filter(r => r.pares).length,
      mediaAutoAval: medias.mediaAutoAval / Modelo.tabela.linhas.filter(r => r.autoAvaliacao).length,
      mediaGestor: medias.mediaGestor / Modelo.tabela.linhas.filter(r => r.gestor).length,
      mediaLiderados: medias.mediaLiderados / Modelo.tabela.linhas.filter(r => r.liderados).length,
      mediaOutros: medias.mediaOutros / Modelo.tabela.linhas.filter(r => r.outros).length,
      mediaSemAuto: medias.mediaSemAuto / Modelo.tabela.linhas.filter(r => r.mediaSemAuto).length
    }]
    return mediasCalculado
  }

  const Medias = handleMedia()

  function TemColuna(tipo: string): boolean {
    if (Modelo?.graficoHierarquia !== undefined) {
      return Modelo?.graficoHierarquia.dados.find(e => e.nome === tipo) !== undefined
    } else {
      return false
    }
  }

  function CriarBasePDI(opcao: string): void {
    const form: iPostPDI = {
      colaboradorId: Modelo?.avaliado?.id ?? '',
      pesquisaId: Modelo?.pesquisaId ?? '',
      competencias: Modelo?.recomendacoes.map((e) => {
        return e.id
      }) ?? []
    }
    if (opcao === '1') {
      appApi.post('PDI/Pesquisa/Recomendacao', form).then(res => {
        nav(`/Desempenho/PDI/Editar/${res.data as string}`)
      }).catch(err => console.log(err))
    } else {
      appApi.post('PDI/Pesquisa/Continuar', form).then(res => {
        nav(`/Desempenho/PDI/Editar/${res.data as string}`)
      }).catch(err => console.log(err))
    }
  }

  function getPerfilPI(): void {
    SetIsLoading(true)
    appApi.get(`https://sah.icecloud.com.br/api/integracao/sahpi/?email=${Modelo?.avaliado.email}`)
      .then(res => {
        if (res.data.assessmentId !== '5b9802ac-8d59-4f70-93a3-4716ed4cf8a0') {
          setPI(res.data)
        }
      }).catch(err => {
        console.log(err)
      })
    SetIsLoading(false)
  }

  function ObterFiltroQuestao(): string[] {
    return DistinctString(Modelo?.discursivas?.map((e) => {
      if (e.titulo?.split('-').length > 1) {
        return e.titulo?.split('-')[1].trim()
      } else {
        return e.titulo?.split('-')[0].trim()
      }
    }))
  }

  function ObterNomeAvaliador(tipo: number): string {
    const data = {
      nomeAvaliador: ''
    }

    switch (tipo) {
      case 6:
        data.nomeAvaliador = 'Alta liderança'
        break
      case 4:
        data.nomeAvaliador = 'Outros'
        break
      case 3:
        data.nomeAvaliador = 'Liderados'
        break
      case 2:
        data.nomeAvaliador = 'Pares'
        break
      case 1:
        data.nomeAvaliador = 'Líderes'
        break
      default:
        break
    }

    return data.nomeAvaliador
  }

  function ObterMotivacao(tipo: number): string {
    const data = {
      motivacao: ''
    }

    switch (tipo) {
      case 1:
        data.motivacao = 'Mais motiva'
        break
      case -1:
        data.motivacao = 'Menos motiva'
        break
      default:
        break
    }

    return data.motivacao
  }

  function ObterDestaque(tipo: number): string {
    const data = {
      motivacao: ''
    }

    switch (tipo) {
      case 1:
        data.motivacao = 'Destaque'
        break
      case -1:
        data.motivacao = 'Necessita de melhora'
        break
      default:
        break
    }

    return data.motivacao
  }

  function SalvarPesoAvaliador(tipo: number, peso: number): void {
    const copy = [...PotenciaisMelhorias.pesosAvaliadores]
    const pos = copy.findIndex(e => e.tipo === tipo)
    if (pos !== -1) {
      copy[pos] = {
        peso: peso,
        tipo: tipo
      }
      setPotenciaisMelhorias({ ...PotenciaisMelhorias, pesosAvaliadores: copy })
    }
  }

  function SalvarPriorizacoes(id: string): void {
    const copy = [...CompetenciasPriorizadas]
    const pos = copy.findIndex(e => e === id)

    if (pos !== -1) {
      setCompetenciasPriorizadas([...CompetenciasPriorizadas].filter(e => e !== id))
    } else {
      setCompetenciasPriorizadas([...CompetenciasPriorizadas, id])
    }
  }

  function AtualizarCampos(): void {
    const data = {
      prioridades: CompetenciasPriorizadas,
      pesos: PotenciaisMelhorias.pesosAvaliadores
    }

    appApi.put(`AvaliacaoPesquisa/${avaliacaoId as string}/Salvar/Campos`, data).then((res) => { Load() }).catch((err) => { console.log(err) })
  }

  function SalvarAlteracoes(): void {
    setModoEdicao(false)
    AtualizarCampos()
  }

  function ObterCorPonderada(nota: number): string {
    switch (true) {
      case nota > 0.3:
        return '#6FCF97'
      case nota > -0.3 && nota < 0.3:
        return '#E0E0E0'
      case nota < -0.3:
        return '#f6b881'
      default:
        return ''
    }
  }

  function NavegarResultado(id: string): void {
    nav(pathname.replace(avaliacaoId ?? '', id))
  }

  function DefinirPrioridades(): boolean {
    if ((Modelo.tabelaPotenciaisMelhorias.responsavelId === parseJwt().eid) || (Modelo.tabelaPotenciaisMelhorias.responsaveisPrioridades.includes(Number(parseJwt().role)) || parseJwt().adm === 'true')) {
      return true
    }

    return false
  }

  function ObterColaboradorPrioridade(): boolean {
    console.log(parseJwt())

    switch (true) {
      case parseJwt().adm === 'True':
        return true
      case parseJwt().cid === Modelo?.responsavelPrioridade:
        return true
      default:
        return false
    }
  }

  function Load(): void {
    getResultadoIndicativa()
  }

  function ObterBackgroundFundo(nota: number, tipo: number): string {
    if (tipo === 1) {
      switch (true) {
        case nota === 0:
          return ''
        case nota > 0:
          return 'var(--Green2Opaco)'
        case nota < 0:
          return 'var(--MostardaOpaco)'
        default:
          return ''
      }
    }

    if (tipo === 2) {
      switch (true) {
        case nota === 0:
          return ''
        case nota > 0:
          return 'var(--Green2Opaco)'
        case nota < 0:
          return '#F6B881'
        default:
          return ''
      }
    }

    if (tipo === 3) {
      switch (true) {
        case nota === 0:
          return ''
        case nota > 0:
          return '#8CB0F0'
        case nota < 0:
          return 'var(--MostardaOpaco)'
        default:
          return ''
      }
    }

    return ''
  }

  useEffect(() => {
    getResultadoIndicativa()
  }, [avaliacaoId])

  useEffect(() => {
    if (Modelo?.avaliado.email !== undefined && PropInvalida(Modelo?.avaliado.perfilPI)) {
      getPerfilPI()
    }
  }, [Modelo?.avaliado.email])

  useEffect(() => {
    if (Modelo.graficoHierarquia.dados.length === 2) {
      setGraficoHierarquia(true)
    }
  }, [Modelo])

  useEffect(() => {
    const copy = [...Modelo.itemHistorico]
    copy.sort((a, b) => {
      const data1 = a.data.split('/', 3)
      const data2 = b.data.split('/', 3)
      return new Date(parseInt(data1[2]), parseInt(data1[1]), parseInt(data1[0])) > new Date(parseInt(data2[2]), parseInt(data2[1]), parseInt(data2[0])) ? 1 : -1
    })
    setModelo({ ...Modelo, itemHistorico: copy })
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalPDI
        recomendacoes={Modelo?.recomendacoes.length}
        isOpen={ModalPDIsOpen}
        onCreate={CriarBasePDI}
        onRequestClose={() => setModalPDIsOpen(false)}
        avaliacaoPDI={Modelo.pdiAvaliacao}
      />

      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: `${pathname.replace(`ResultadoIndividual/${avaliacaoId as string}`, 'Gestao')}`
          },
          {
            nome: Modelo?.nomePesquisa ?? '',
            path: -1
          }, {
            nome: Modelo?.avaliado?.nome ?? '',
            path: ''
          }
        ]} />
      </Flex>

      <Flex marginBottom={'2rem'} justifyContent={'space-between'} flexFlow='nowrap'>
        <Flex flexDir={'column'} marginBottom='1rem'>
          <Flex>
            <Avatar
              marginRight={'1rem'}
              size={'2xl'}
              name={Modelo?.avaliado.nome}
              src={Modelo?.avaliado.avatar}
            />
            <div>
              <h3>Avaliado</h3>
              <h1>{Modelo?.avaliado.nome}</h1>
              <strong>{Modelo?.avaliado?.nomeDepartamento}</strong>
            </div>
          </Flex>
        </Flex>
        <Flex gap={'1rem'}>
          {
            (PI && !PI.hide) && (
              <DashBox style={{ position: 'relative', width: '10.925rem', height: '8.625rem', border: '2px solid var(--Azul)', backgroundColor: 'var(--c1)' }}>
                <Flex justifyContent={'center'}>
                  <Text fontSize={'14px'} color={'var(--Gray2)'} fontWeight={'700'}>Perfil comportamental</Text>
                </Flex>
                <Flex flexDirection={'column'}>
                  <Flex flexDir='column' width='100%' alignItems={'center'}>
                    {
                      (PI.perfil) && (
                        <Text fontSize={'14px'} fontWeight={'500'}>{PI.perfil}</Text>
                      )
                    }
                    {
                      (PI.urlSelo) && (
                        <Image mt={'.3rem'} width={'2.5rem'} src={PI.urlSelo} />
                      )
                    }
                  </Flex>
                  <Menu>
                    <MenuButton display={'flex'} alignItems={'center'} py={'.5rem'} as={Button} border={'1px solid var(--Blue2)'} color={'var(--Blue2)'} bgColor='var(--c1)' height={'18px'} fontSize={'12px'} _active={{ bgColor: 'var(--Azul)', color: 'white' }} _hover={{ bgColor: 'var(--Azul)', color: 'white' }} mt={'.5rem'}>
                      Baixar Relatorios
                    </MenuButton>
                    <MenuList bgColor='var(--Azul)' mt={'8.6rem'}>
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={`https://sah.icecloud.com.br/api/Files/api/pdf?id=${PI.assessmentId}&tipo=2`}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Perfil comportamental
                        </Box>
                      </MenuItem>
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=2`}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Quadro desenvolvimento pessoal
                        </Box>
                      </MenuItem>
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=3`}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Placard
                        </Box>
                      </MenuItem>
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=4`}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Quadro desenvolvimento gestores
                        </Box>
                      </MenuItem>

                    </MenuList>
                  </Menu>
                </Flex>
              </DashBox>
            )
          }
          {(Modelo?.avaliado.perfilPI) && (
            <DashBox style={{ position: 'relative', width: '10.925rem', height: '8.625rem', border: '2px solid var(--Azul)', backgroundColor: 'var(--c1)' }}>
              <Flex justifyContent={'center'}>
                <Text fontSize={'14px'} color={'var(--Gray2)'} fontWeight={'700'}>Perfil comportamental</Text>
              </Flex>
              <Flex flexDirection={'column'}>
                <Flex flexDir='column' width='100%' alignItems={'center'}>
                  {
                    (Modelo?.avaliado.perfilPI?.nomeSelo) && (
                      <Text fontWeight={'500'}>{Modelo?.avaliado.perfilPI?.nomeSelo}</Text>
                    )
                  }
                  {
                    (Modelo?.avaliado.perfilPI?.urlSelo) && (
                      <Image mt={'.3rem'} width={'2.5rem'} src={Modelo?.avaliado.perfilPI.urlSelo} />
                    )
                  }
                </Flex>
                <Menu>
                  <MenuButton display={'flex'} alignItems={'center'} py={'.5rem'} as={Button} border={'1px solid var(--Blue2)'} color={'var(--Blue2)'} bgColor='var(--c1)' height={'18px'} fontSize={'12px'} _active={{ bgColor: 'var(--Azul)', color: 'white' }} _hover={{ bgColor: 'var(--Azul)', color: 'white' }} mt={'.5rem'}>
                    Baixar Relatorios
                  </MenuButton>
                  <MenuList bgColor='var(--Azul)' mt={'8.6rem'}>
                    {
                      (!PropInvalida(Modelo?.avaliado.perfilPI?.urlResultado)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo?.avaliado.perfilPI?.urlResultado}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Perfil comportamental
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo?.avaliado.perfilPI?.urlQuadro)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo?.avaliado.perfilPI?.urlQuadro}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento pessoal
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo?.avaliado.perfilPI?.urlPlacard)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo?.avaliado.perfilPI?.urlPlacard}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Placard
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo?.avaliado.perfilPI?.urlQuadroGestor)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo?.avaliado.perfilPI?.urlQuadroGestor}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento gestores
                          </Box>
                        </MenuItem>
                      )
                    }

                  </MenuList>
                </Menu>
              </Flex>
            </DashBox>
          )}
          {(Modelo.avaliado.perfilPI) && (
            <DashBox style={{ position: 'relative', width: '10.925rem', height: '8.625rem', border: '2px solid var(--Azul)', backgroundColor: 'var(--c1)' }}>
              <Flex justifyContent={'center'}>
                <Text fontSize={'14px'} color={'var(--Gray2)'} fontWeight={'700'}>Perfil comportamental</Text>
              </Flex>
              <Flex flexDirection={'column'}>
                <Flex flexDir='column' width='100%' alignItems={'center'}>
                  {
                    (Modelo.avaliado.perfilPI?.nomeSelo) && (
                      <Text fontWeight={'500'}>{Modelo.avaliado.perfilPI?.nomeSelo}</Text>
                    )
                  }
                  {
                    (Modelo.avaliado.perfilPI?.urlSelo) && (
                      <Image mt={'.3rem'} width={'2.5rem'} src={Modelo.avaliado.perfilPI.urlSelo} />
                    )
                  }
                </Flex>
                <Menu>
                  <MenuButton display={'flex'} alignItems={'center'} py={'.5rem'} as={Button} border={'1px solid var(--Blue2)'} color={'var(--Blue2)'} bgColor='var(--c1)' height={'18px'} fontSize={'12px'} _active={{ bgColor: 'var(--Azul)', color: 'white' }} _hover={{ bgColor: 'var(--Azul)', color: 'white' }} mt={'.5rem'}>
                    Baixar Relatorios
                  </MenuButton>
                  <MenuList bgColor='var(--Azul)' mt={'8.6rem'}>
                    {
                      (!PropInvalida(Modelo.avaliado.perfilPI?.urlResultado)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo.avaliado.perfilPI?.urlResultado}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Perfil comportamental
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo.avaliado.perfilPI?.urlQuadro)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo.avaliado.perfilPI?.urlQuadro}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento pessoal
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo.avaliado.perfilPI?.urlPlacard)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo.avaliado.perfilPI?.urlPlacard}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Placard
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Modelo.avaliado.perfilPI?.urlQuadroGestor)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Modelo.avaliado.perfilPI?.urlQuadroGestor}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento gestores
                          </Box>
                        </MenuItem>
                      )
                    }

                  </MenuList>
                </Menu>
              </Flex>
            </DashBox>
          )}
          <InfoBox>
            <Flex width={'2rem'}>
              <Image src={IconeAvaliadores} />
            </Flex>
            <div>
              <h3>{Modelo?.totalRespostas}/{Modelo.totalAvaliadores}</h3>
              <span>Avaliadores</span>
            </div>
          </InfoBox>
        </Flex>
      </Flex>

      {(ModoEdicao) && (
        <WhiteBox>
          <Text fontSize={'24px'} fontWeight={'700'} mb={'1rem'} color={'var(--a4)'}>Potenciais para melhorias</Text>

          <TabelaPersonalizada >
            <thead>
              <tr>
                <th></th>
                <th colSpan={(PotenciaisMelhorias?.stakes?.length ?? 0) + 1}>Stakeholders</th>
                <th colSpan={2}>Autoavaliação</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontSize: '14px' }}>Competência</td>
                {PotenciaisMelhorias?.stakes.map((stakes) => {
                  return (
                    <td style={{ fontSize: '14px' }}>{ObterNomeAvaliador(stakes)}</td>
                  )
                })}
                <td style={{ fontSize: '14px' }}>Pontuação <br /> ponderada</td>
                <td style={{ fontSize: '14px' }}>Destaques/Melhorias</td>
                <td style={{ fontSize: '14px' }}>O que motiva?</td>
                <td style={{ fontSize: '14px' }}>Priorizar</td>
              </tr>

              {PotenciaisMelhorias?.respostas.map((e) => {
                return (
                  <tr>
                    <td style={{ fontSize: '14px' }}>{e.competencia}</td>

                    {e.notasStakes.filter(e => e.stake !== 'AutoAvaliacao').map((r, i) => {
                      return (
                        <td style={{ fontSize: '12px', background: ObterBackgroundFundo(r.notas, 1) }}>{r.notas !== 0 ? r.notas : ''}</td>
                      )
                    })}

                    <td style={{ fontSize: '12px', background: e.competencia ? ObterCorPonderada(e.notaPonderada) : '' }}>{e.notaPonderada.toFixed(2)}</td>

                    {e.notasStakes.filter(e => e.stake === 'AutoAvaliacao' && !e.autoAvaliacao).map((r, i) => {
                      return (
                        <>
                          <td style={{ fontSize: '12px', background: ObterBackgroundFundo(r.notas, 2) }}>{ObterDestaque(r.notas)}</td>

                        </>
                      )
                    })}

                    {e.notasStakes.filter(e => e.stake === 'AutoAvaliacao' && e.autoAvaliacao).map((r, i) => {
                      return (
                        <td style={{ fontSize: '12px', background: ObterBackgroundFundo(r.notas, 3) }}>{ObterMotivacao(r.notas)}</td>
                      )
                    })}

                    {!PotenciaisMelhorias?.respostas.some(e => e.notasStakes.some(r => r.autoAvaliacao)) &&
                      <>
                        <td></td>
                        <td></td>
                      </>
                    }

                    <td>
                      <Checkbox isDisabled={DefinirPrioridades()} isChecked={CompetenciasPriorizadas.some(compId => compId === e.competenciaId)} mt={'.2rem'} onChange={() => SalvarPriorizacoes(e.competenciaId)} />
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </TabelaPersonalizada>

          <Box mt={'2.2rem'}>
            <Text mb={'.4rem'} fontWeight={'600'}>Pesos definidos para os tipos de avaliadores</Text>

            <Flex justifyContent={'flex-start'} w={'60%'} gap='.5rem' opacity={!PotenciaisMelhorias?.alterarPesos ? '.5' : '1'}>
              {PotenciaisMelhorias?.pesosAvaliadores.map((e, i) => {
                return (
                  <FormControl key={i} gap={'.5rem'} alignItems={'center'} display={'flex'}>
                    <FormLabel m={'0'} fontSize={'12px'} fontWeight={'400'}>{ObterNomeAvaliador(e.tipo)}</FormLabel>
                    <Box w='3rem'>
                      <NumberInput isDisabled={!PotenciaisMelhorias?.alterarPesos || parseJwt().adm === 'false'} fontSize={'12px'} p={0} m={0} onChange={(peso) => SalvarPesoAvaliador(e.tipo, Number(peso))} value={e.peso}>
                        <NumberInputField fontSize={'12px'} p={0} m={0} textAlign='center' />
                      </NumberInput>
                    </Box>
                  </FormControl>
                )
              })}
            </Flex>

            <Flex mt={'2rem'} justifyContent={'space-between'}>
              <Button fontSize={'16px'} VarColor='c6' onClick={() => setModoEdicao(false)}>Voltar</Button>
              <Button fontSize={'16px'} VarColor='Green1' rightIcon={<FaSave />} onClick={SalvarAlteracoes}>Salvar priorização</Button>
            </Flex>

          </Box>
        </WhiteBox>
      )}
      {(!ModoEdicao) && (
        <>
          {(Modelo.recomendacoes.length > 0) && (
            <>
              <Flex justifyContent={'start'}>
                <h3>Competências a desenvolver</h3>
              </Flex>

              <Grid templateColumns='repeat(2, 1fr)' gap={10} margin={'1rem'} bg={'white'} padding='2rem' borderRadius={'1rem'}>
                {
                  Modelo?.recomendacoes.map((e, i) => {
                    return (
                      <Recomendacao pos={i + 1} recomendacao={e} key={i} />
                    )
                  })
                }
              </Grid>
            </>
          )}

          <Flex gap={'1rem'} marginTop={'1rem'}>
            <PDFDownloadLink
              document={
                <RelatorioPDF
                  temColuna={TemColuna}
                  temWorkShop={false}
                  Grafico1={Grafico1}
                  Grafico2={Grafico2}
                  model={Modelo}
                  medias={Medias}
                  tipoPesquisa={pathname.includes('AnaliseDesempenho') ? 'Pesquisa de análise e desempenho' : 'Pesquisa de percepção'}
                />
              }
              fileName={`Relatorio_Pesquisa_Percepcao_${Modelo?.avaliado.nome.replace(/\s/g, '_')}.pdf`}
            >
              {({ loading }) =>
                loading
                  ? (
                    <Button VarColor='Rosa' isLoading leftIcon={<FaFileDownload />}>Relatório</Button>
                    )
                  : (
                    <Button VarColor='Rosa' leftIcon={<FaFileDownload />}>Relatório</Button>
                    )
              }
            </PDFDownloadLink>
            <Button onClick={() => redirectPage(`${urlApi}/AvaliacaoPesquisa/${avaliacaoId as string}/baseAvaliacaoCsv/?token=${localStorage.getItem('token') as string}`)} VarColor='Azul' leftIcon={<FaFileDownload />}>Base</Button>
            {/* {
              (pathname.includes('AnaliseDesempenho')) && (
                <Button
                  fontWeight={'500'}
                  onClick={() => {
                    Modelo.pdi ? setModalPDIsOpen(true) : CriarBasePDI('1')
                  }}
                  leftIcon={<FaClipboard />} VarColor='Azul'
                >{Modelo.pdiAvaliacao ? 'Editar PDI' : 'Adicionar PDI'}</Button>
              )
            } */}
            {ObterColaboradorPrioridade() && (
              <Button VarColor='Gray3' leftIcon={<AiFillTool />} onClick={() => setModoEdicao(true)}>Modificar competências para melhoria</Button>
            )}
          </Flex>

          <Tabs marginTop={'1rem'} variant='enclosed'>
            <TabList >
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Resultado individual</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Tabela de avaliações</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Gráfico</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Comentários</Tab>
              <Tab backgroundColor={'white'} color={'var(--c6)'}>Histórico</Tab>
            </TabList>

            <TabPanels background={'white'} borderBottomLeftRadius='1rem' borderBottomRightRadius='1rem'>
              <TabPanel px={'2rem'}>

                <TabelaIndividual>
                  <thead>
                    <tr>
                      <th>Resumo dos resultados</th>
                      <th>Competência</th>
                      <th>Pontuação ponderada dos <br />stakeholders</th>
                      <th>Autoavaliação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Modelo?.tabelaRecomendacoes?.melhorias?.map((e, i) => {
                      return (
                        <tr>
                          {i === 0 ? <td rowSpan={Modelo?.tabelaRecomendacoes?.melhorias.length}>Competências a serem trabalhadas</td> : <></>}
                          <td>{e.nome}</td>
                          <td>{e.nota}</td>
                          <td>{e.selos.map(a => {
                            if (a === 4) {
                              return (<FaMedal style={{ color: 'var(--Green2)' }} />)
                            } else if (a === 1) {
                              return (<FaTools style={{ color: '#66046B' }} />)
                            } else if (a === 2) {
                              return (<FaHeart style={{ color: 'var(--Azul)' }} />)
                            } else if (a === 3) {
                              return (<FaFrown style={{ color: 'var(--Orange)' }} />)
                            } else {
                              return null
                            }
                          })}</td>
                        </tr>
                      )
                    })}
                    {Modelo?.tabelaRecomendacoes?.outros?.map((e, i) => {
                      return (
                        <tr>
                          {i === 0 ? <td rowSpan={Modelo?.tabelaRecomendacoes?.outros.length}>Outras oportunidades de melhoria segundo os stakeholders</td> : <></>}
                          <td>{e.nome}</td>
                          <td>{e.nota}</td>
                          <td>{e.selos.map(a => {
                            if (a === 4) {
                              return (<FaMedal style={{ color: 'var(--Green2)' }} />)
                            } else if (a === 1) {
                              return (<FaTools style={{ color: '#66046B' }} />)
                            } else if (a === 2) {
                              return (<FaHeart style={{ color: 'var(--Azul)' }} />)
                            } else if (a === 3) {
                              return (<FaFrown style={{ color: 'var(--Orange)' }} />)
                            } else {
                              return null
                            }
                          })}</td>
                        </tr>
                      )
                    })}
                    {Modelo?.tabelaRecomendacoes?.destaques?.map((e, i) => {
                      return (
                        <tr>
                          {i === 0 ? <td rowSpan={Modelo?.tabelaRecomendacoes?.destaques.length}>Destaques positivos segundo stakeholders</td> : <></>}
                          <td>{e.nome}</td>
                          <td>{e.nota}</td>
                          <td>{e.selos.map(a => {
                            if (a === 4) {
                              return (<FaMedal style={{ color: 'var(--Green2)' }} />)
                            } else if (a === 1) {
                              return (<FaTools style={{ color: '#66046B' }} />)
                            } else if (a === 2) {
                              return (<FaHeart style={{ color: 'var(--Azul)' }} />)
                            } else if (a === 3) {
                              return (<FaFrown style={{ color: 'var(--Orange)' }} />)
                            } else {
                              return null
                            }
                          })}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </TabelaIndividual>

                <Box my={'2rem'}>
                  <Flex gap={'1rem'} justifyContent={'center'} mb={'.5rem'}>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaMedal style={{ color: 'var(--Green2)' }} />  Que você considera ser um destaque positivo</Text>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaTools style={{ color: '#66046B' }} />  Que você considera ter mais necessidade de desenvolvimento</Text>
                  </Flex>
                  <Flex gap={'1rem'} justifyContent={'center'}>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaHeart style={{ color: 'var(--Azul)' }} />  Que você mais se motiva</Text>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaFrown style={{ color: 'var(--Orange)' }} />  Que você menos se motiva</Text>
                  </Flex>
                </Box>

                {Modelo.resumoCompetencias?.some(e => e.status === 0) && (

                  <Box borderBottom={'1px solid #b9b9b970'} pb={'1rem'}>
                    <Flex
                      mb={'1rem'}
                      alignItems={'center'}
                      gap={'1rem'}
                      fontSize={'26px'}
                      fontWeight={'700'}
                      color={'var(--Roxo)'}
                    >
                      <FaTools /> Competências para serem trabalhadas
                    </Flex>
                    <Box>
                      {Modelo.resumoCompetencias?.filter(e => e.status === 0)?.map(e => {
                        return (
                          <Flex fontWeight={'600'} alignItems={'center'} gap={'1rem'}>
                            <Box>
                              <Img w={'7.75rem'} h={'7.75rem'} src={e.avatar} />
                              <Text mt={0} fontSize={'.95rem'} textAlign={'center'}>{e.nome}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight={'800'}>Descrição: {e.descricao}</Text>
                              {e.pergunta}
                            </Box>
                          </Flex>
                        )
                      })}
                    </Box>
                  </Box>
                )}

                {Modelo.resumoCompetencias?.some(e => e.status === 1) && (

                  <Box borderBottom={'1px solid #b9b9b970'} mt={'1rem'} pb={'1rem'}>
                    <Flex
                      mb={'1rem'}
                      alignItems={'center'}
                      gap={'1rem'}
                      fontSize={'26px'}
                      fontWeight={'700'}
                      color={'var(--Green2)'}
                    >
                      <FaMedal /> Destaques positivos segundo seus stakeholders
                    </Flex>

                    <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
                      {Modelo.resumoCompetencias?.filter(e => e.status === 1)?.map(e => {
                        return (
                          <Flex fontWeight={'600'} alignItems={'center'} gap={'1rem'}>
                            <Box>
                              <Img w={'6.75rem'} h={'6.75rem'} src={e.avatar} />
                              <Text mt={0} fontSize={'.85rem'} textAlign={'center'}>{e.nome}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight={'800'}>Descrição: {e.descricao}</Text>
                            </Box>
                          </Flex>
                        )
                      })}
                    </Grid>
                  </Box>
                )}

                {Modelo.resumoCompetencias?.some(e => e.status === 2) && (

                  <Box borderBottom={'1px solid #b9b9b970'} mt={'1rem'} pb={'1rem'}>
                    <Flex
                      mb={'1rem'}
                      alignItems={'center'}
                      gap={'1rem'}
                      fontSize={'26px'}
                      fontWeight={'700'}
                      color={'var(--Azul)'}>
                      <FaHeart /> Você mais se motiva
                    </Flex>
                    <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
                      {Modelo.resumoCompetencias?.filter(e => e.status === 2)?.map(e => {
                        return (
                          <Flex fontWeight={'600'} alignItems={'center'} gap={'1rem'}>
                            <Box>
                              <Img w={'6.75rem'} h={'6.75rem'} src={e.avatar} />
                              <Text mt={0} fontSize={'.85rem'} textAlign={'center'}>{e.nome}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight={'800'}>Descrição: {e.descricao}</Text>
                            </Box>
                          </Flex>
                        )
                      })}
                    </Grid>
                  </Box>
                )}

                {Modelo.resumoCompetencias?.some(e => e.status === 3) && (

                  <Box borderBottom={'1px solid #b9b9b970'} mt={'1rem'} pb={'1rem'}>
                    <Flex
                      mb={'1rem'}
                      alignItems={'center'}
                      gap={'1rem'}
                      fontSize={'26px'}
                      fontWeight={'700'}
                      color={'var(--Orange)'}>
                      <FaFrown /> Você menos se motiva
                    </Flex>
                    <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
                      {Modelo.resumoCompetencias?.filter(e => e.status === 3)?.map(e => {
                        return (
                          <Flex fontWeight={'600'} alignItems={'center'} gap={'1rem'}>
                            <Box>
                              <Img w={'6.75rem'} h={'6.75rem'} src={e.avatar} />
                              <Text mt={0} fontSize={'.85rem'} textAlign={'center'}>{e.nome}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight={'800'}>Descrição: {e.descricao}</Text>
                            </Box>
                          </Flex>
                        )
                      })}
                    </Grid>
                  </Box>
                )}

                {Modelo.resumoCompetencias?.some(e => e.status === 4) && (
                  <Box mt={'1rem'} pb={'1rem'}>
                    <Flex
                      mb={'1rem'}
                      alignItems={'center'}
                      gap={'1rem'}
                      fontSize={'26px'}
                      fontWeight={'700'}
                      color={'var(--Gray3)'}>
                      <AiOutlineRise /> Outras oportunidades de desenvolvimento
                    </Flex>
                    <Grid gridTemplateColumns={'repeat(2, 1fr)'} gap={'1.5rem'}>
                      {Modelo.resumoCompetencias?.filter(e => e.status === 4)?.map(e => {
                        return (
                          <Flex fontWeight={'600'} alignItems={'center'} gap={'1rem'}>
                            <Box>
                              <Img w={'6.75rem'} h={'6.75rem'} src={e.avatar} />
                              <Text mt={0} fontSize={'.85rem'} textAlign={'center'}>{e.nome}</Text>
                            </Box>
                            <Box>
                              <Text fontWeight={'800'}>Descrição: {e.descricao}</Text>
                            </Box>
                          </Flex>
                        )
                      })}
                    </Grid>
                  </Box>

                )}

              </TabPanel>

              <TabPanel px={'2rem'}>
                <h3>Visão das competências considerando cada tipo de avaliadores</h3>
                <Box padding={'.5rem'} border={'1px solid #b9b9b970'} borderRadius={'.75rem'} mt={'1rem'}>
                  <TabelaAvaliacoes>
                    <thead>
                      <tr>
                        <th>Competências</th>
                        {Modelo.tabelaAvaliacoes.some(e => e.selos?.autoAvaliacao) && (<th>Autoavaliação</th>)}
                        {Modelo.tabelaAvaliacoes.some(e => e.selos?.liderImediato) && (<th>Líder imediato</th>)}
                        {Modelo.tabelaAvaliacoes.some(e => e.selos?.pares) && (<th>Pares</th>)}
                        {Modelo.tabelaAvaliacoes.some(e => e.selos?.liderados) && (<th>Liderados</th>)}
                        {Modelo.tabelaAvaliacoes.some(e => e.selos?.outros) && (<th>Outros</th>)}
                        {Modelo.tabelaAvaliacoes.some(e => e.selos?.altaLideranca) && (<th>Alta Liderança</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {Modelo.tabelaAvaliacoes.map((e) => {
                        return (
                          <tr>
                            <td width={120 / Modelo.tabelaAvaliacoes.length}>{e.competencia}</td>
                            {e.selos?.autoAvaliacao !== null && (
                              <td>
                                <Flex
                                  justifyContent={'center'}
                                  gap={'.5rem'}
                                >
                                  {e.selos?.autoAvaliacao?.map(a => { return (ObterIcone(a, e.selos?.autoAvaliacao.some(qtd => qtd.quantidade > 1) ?? true)) })}
                                </Flex>
                              </td>
                            )}

                            {e.selos?.liderImediato !== null && (
                              <td>
                                <Flex justifyContent={'center'}
                                  gap={'.5rem'}
                                >
                                  {e.selos?.liderImediato?.map(a => { return (ObterIcone(a, e.selos?.liderImediato.some(qtd => qtd.quantidade > 1) ?? true)) })}
                                </Flex>
                              </td>
                            )}

                            {e.selos?.pares !== null && (
                              <td>
                                <Flex justifyContent={'center'}
                                  gap={'.5rem'}
                                >
                                  {e.selos?.pares?.map(a => { return (ObterIcone(a, e.selos?.pares.some(qtd => qtd.quantidade > 1) ?? true)) })}</Flex>
                              </td>
                            )}

                            {e.selos?.liderados !== null && (
                              <td>
                                <Flex
                                  justifyContent={'center'}
                                  gap={'.5rem'}
                                >
                                  {e.selos?.liderados?.map(a => { return (ObterIcone(a, e.selos?.liderados.some(qtd => qtd.quantidade > 1) ?? true)) })}
                                </Flex>
                              </td>
                            )}

                            {e.selos?.outros !== null && (
                              <td>
                                <Flex
                                  justifyContent={'center'}
                                  gap={'.5rem'}
                                >
                                  {e.selos?.outros?.map(a => { return (ObterIcone(a, e.selos?.outros.some(qtd => qtd.quantidade > 1) ?? true)) })}
                                </Flex>
                              </td>
                            )}

                            {e.selos?.altaLideranca !== null && (
                              <td>
                                <Flex
                                  justifyContent={'center'}
                                  gap={'.5rem'}
                                >
                                  {e.selos?.altaLideranca?.map(a => { return (ObterIcone(a, e.selos?.altaLideranca.some(qtd => qtd.quantidade > 1) ?? true)) })}
                                </Flex>
                              </td>
                            )}

                          </tr>
                        )
                      })}

                    </tbody>
                  </TabelaAvaliacoes>
                </Box>
                <Box my={'1rem'}>
                  <Flex gap={'1rem'} justifyContent={'center'} mb={'.5rem'}>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaMedal style={{ color: 'var(--Green2)' }} />  Que você considera ser um destaque positivo</Text>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaTools style={{ color: '#66046B' }} />  Que você considera ter mais necessidade de desenvolvimento</Text>
                  </Flex>
                  <Flex gap={'1rem'} justifyContent={'center'}>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaHeart style={{ color: 'var(--Azul)' }} />  Que você mais se motiva</Text>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaFrown style={{ color: 'var(--Orange)' }} />  Que você menos se motiva</Text>
                  </Flex>
                </Box>
              </TabPanel>

              <TabPanel px={'2rem'}>

                <Flex direction={'column'} my={'1rem'}>
                  <Flex justifyContent={'center'} gap={'1rem'}>
                    <Flex gap={'1rem'}><Box w={'1.625rem'} h={'1.625rem'} bgColor={'var(--Red)'}></Box> Competências priorizadas para serem desenvolvidas</Flex>
                    <Flex gap={'1rem'}><Box w={'1.625rem'} h={'1.625rem'} bgColor={'var(--Azul)'}></Box> Demais competências</Flex>
                  </Flex>
                </Flex>

                <Box mb={'1rem'}>
                  <Flex gap={'1rem'} justifyContent={'center'} mb={'.5rem'}>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaMedal style={{ color: 'var(--Green2)' }} />  Que você considera ser um destaque positivo</Text>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaTools style={{ color: '#66046B' }} />  Que você considera ter mais necessidade de desenvolvimento</Text>
                  </Flex>
                  <Flex gap={'1rem'} justifyContent={'center'}>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaHeart style={{ color: 'var(--Azul)' }} />  Que você mais se motiva</Text>
                    <Text display={'flex'} gap={'.5rem'} alignItems={'center'}><FaFrown style={{ color: 'var(--Orange)' }} />  Que você menos se motiva</Text>
                  </Flex>
                </Box>

                <Flex w={'100%'} justifyContent={'center'} position={'relative'}>
                  {Grafico.sort((a, b) => parseFloat(b.media) - parseFloat(a.media)).map((e, i) => {
                    if (e.selo) {
                      return (ObterIconeGrafico(i, e.media, e.selo))
                    } else {
                      return (<></>)
                    }
                  })}

                  <GaugeChartIndicativa
                    id={'dalinho'}
                    val={Grafico}
                  />

                </Flex>
              </TabPanel>

              {(Modelo && Modelo?.discursivas?.length > 0)
                ? (
                  <TabPanel>
                    {!Modelo?.discursivas?.every(e => PropInvalida(e.tipoAvaliador)) && <Flex gap={'1rem'} marginBottom='1rem' width='50%'>
                      <FormControl>
                        <FormLabel>Questões</FormLabel>
                        <Select onChange={(e) => setFiltroCompetencia(e.target.value)}>
                          <option value={''}>Todos</option>
                          {
                            ObterFiltroQuestao().map((e, i) => {
                              return (
                                <option value={e} key={i}>{e}</option>
                              )
                            })
                          }
                        </Select>
                      </FormControl>

                      <FormControl>
                        <FormLabel>Tipo do avaliador</FormLabel>
                        <Select onChange={(e) => setFiltroTipo(e.target.value)}>
                          <option value={''}>Todos</option>
                          <option value={'0'}>Autoavaliação</option>
                          <option value={'1'}>Líder</option>
                          <option value={'2'}>Par</option>
                          <option value={'3'}>Liderado</option>
                          <option value={'6'}>Alta liderança</option>
                          <option value={'4'}>Outro</option>
                        </Select>
                      </FormControl>
                    </Flex>}
                    <h3>Comentários: {Modelo?.discursivas?.filter((e) => {
                      if (FiltroCompetencia === '' && FiltroTipo === '') {
                        return e
                      } else if (!PropInvalida(e.tipoAvaliador) && FiltroCompetencia !== '' && FiltroTipo !== '') {
                        if (e.titulo?.includes(FiltroCompetencia) && FiltroTipo === e.tipoAvaliador.toString()) {
                          return e
                        }
                        return null
                      } else if (!PropInvalida(e.tipoAvaliador) && ((FiltroCompetencia !== '' && e.titulo?.includes(FiltroCompetencia)) || (FiltroTipo !== '' && FiltroTipo === e.tipoAvaliador.toString()))) {
                        return e
                      } else if (PropInvalida(e.tipoAvaliador) && FiltroCompetencia === 'Comentários') {
                        return e
                      }
                      return null
                    }).length}</h3>
                    <Flex marginTop={'1rem'} flexDir={'column'} gap='1rem'>
                      {
                        Modelo?.discursivas?.filter((e) => {
                          if (FiltroCompetencia === '' && FiltroTipo === '') {
                            return e
                          } else if (!PropInvalida(e.tipoAvaliador) && FiltroCompetencia !== '' && FiltroTipo !== '') {
                            if (e.titulo?.includes(FiltroCompetencia) && FiltroTipo === e.tipoAvaliador.toString()) {
                              return e
                            }
                            return null
                          } else if (!PropInvalida(e.tipoAvaliador) && ((FiltroCompetencia !== '' && e.titulo?.includes(FiltroCompetencia)) || (FiltroTipo !== '' && FiltroTipo === e.tipoAvaliador.toString()))) {
                            return e
                          } else if (PropInvalida(e.tipoAvaliador) && FiltroCompetencia === 'Comentários') {
                            return e
                          }
                          return null
                        }).map((e, i) => {
                          return (
                            <ComentariosContainer key={i}>
                              <strong>{e.titulo}</strong>
                              <span>{e.texto}</span>
                            </ComentariosContainer>
                          )
                        })
                      }
                    </Flex>
                  </TabPanel>
                  )
                : (
                  <TabPanel>
                    <Flex>
                      <h4>Não há comentários</h4>
                    </Flex>
                  </TabPanel>
                  )}

              {(Modelo?.itemHistorico.length > 0)
                ? (
                  <TabPanel>
                    {Modelo?.itemHistorico?.map((e, i) => {
                      if (((i + 2) % 2) === 0) {
                        return (
                          <Flex m={'0 2rem'}>
                            <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-end'} w={'45%'} fontWeight={'600'} pt={'.2rem'}>
                              {e.data}
                            </Box>
                            <Box w={'5%'} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'.5rem'}>
                              <Box m={'0'} p={'.2rem'} w={'2rem'} h={'2rem'} d={'flex'} justifyContent={'center'} alignItems={'center'} bgColor={'var(--Gray1)'} borderRadius={'50%'} color={'#fff'}>
                                <Img src={IconLocation} />
                              </Box>
                              <Box height={'200px'} borderRadius={'100px'} bgColor={'#D9D9D9'} w={'.5rem'}></Box>
                            </Box>
                            <Box w={'44%'} ml={'1%'} position={'relative'}>
                              <Img src={esquerdo} position={'absolute'} top={'0'} left={'-2'} />
                              <Box boxShadow={'0 0px 6px #00000030'} borderRadius={'0 .5rem .5rem .5rem'} p={'1rem'}>
                                <Flex justifyContent={'space-between'} mb={'1rem'}>
                                  <Text fontSize={'20px'} color={'var(--Roxo)'}>{e.pesquisa}</Text>
                                  <Button onClick={() => NavegarResultado(e.avaliacaoId)} VarColor='Azul' leftIcon={<FaEye />} fontSize={'12px'} h={'2rem'} borderRadius={'.5rem'}>Resultados</Button>
                                </Flex>
                                <Flex gap={'1rem'} justifyContent={'space-between'}>
                                  <Box>
                                    <Flex gap={'.5rem'} alignItems={'center'}><FaMedal style={{ color: 'var(--Green2)' }} /> Destaque</Flex>
                                    {e.destaques.map(destaque => {
                                      return (
                                        <Flex my={'.4rem'} alignItems={'center'} ml={'.75rem'} fontSize={'12px'} gap={'.5rem'} fontWeight={'600'}><Avatar w={'2rem'} h={'2rem'} bgColor={'transparent'} src={destaque.avatar} /> {destaque.competencia}</Flex>
                                      )
                                    })}
                                  </Box>
                                  <Box>
                                    <Flex gap={'.5rem'} alignItems={'center'}><FaTools style={{ color: '#66046B' }} /> Potencial de melhoria</Flex>
                                    {e.potencialMelhoria.map(pMelhoria => {
                                      return (
                                        <Flex my={'.4rem'} alignItems={'center'} ml={'.75rem'} fontSize={'12px'} gap={'.5rem'} fontWeight={'600'}><Avatar w={'2rem'} h={'2rem'} bgColor={'transparent'} src={pMelhoria.avatar} /> {pMelhoria.competencia}</Flex>
                                      )
                                    })}
                                  </Box>
                                </Flex>
                              </Box>
                            </Box>
                          </Flex>
                        )
                      } else {
                        return (
                          <Flex m={'0 2rem'} my={'.5rem'}>
                            <Box w={'44%'} mr={'1%'} position={'relative'}>
                              <Img src={direito} position={'absolute'} top={'0'} right={'-2'} />
                              <Box boxShadow={'0 0px 6px #00000030'} borderRadius={'.5rem 0 .5rem .5rem'} p={'1rem'}>
                                <Flex justifyContent={'space-between'} mb={'1rem'}>
                                  <Text fontSize={'20px'} color={'var(--Roxo)'}>{e.pesquisa}</Text>
                                  <Button onClick={() => NavegarResultado(e.avaliacaoId)} VarColor='Azul' leftIcon={<FaEye />} fontSize={'12px'} h={'2rem'}>Resultados</Button>
                                </Flex>
                                <Flex gap={'1rem'} justifyContent={'space-between'}>
                                  <Box>
                                    <Flex gap={'.5rem'} alignItems={'center'}><FaMedal style={{ color: 'var(--Green2)' }} /> Destaque</Flex>
                                    {e.destaques.map(destaque => {
                                      return (
                                        <Flex my={'.4rem'} alignItems={'center'} ml={'.75rem'} fontSize={'12px'} gap={'.5rem'} fontWeight={'600'}><Avatar w={'2rem'} h={'2rem'} bgColor={'transparent'} src={destaque.avatar} /> {destaque.competencia}</Flex>
                                      )
                                    })}
                                  </Box>
                                  <Box>
                                    <Flex gap={'.5rem'} alignItems={'center'}><FaTools style={{ color: '#66046B' }} /> Potencial de melhoria</Flex>
                                    {e.potencialMelhoria.map(pMelhoria => {
                                      return (
                                        <Flex my={'.4rem'} alignItems={'center'} ml={'.75rem'} fontSize={'12px'} gap={'.5rem'} fontWeight={'600'}><Avatar w={'2rem'} h={'2rem'} bgColor={'transparent'} src={pMelhoria.avatar} /> {pMelhoria.competencia}</Flex>
                                      )
                                    })}
                                  </Box>
                                </Flex>
                              </Box>
                            </Box>
                            <Box w={'5%'} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'.5rem'}>
                              <Box m={'0'} p={'.2rem'} w={'2rem'} h={'2rem'} d={'flex'} justifyContent={'center'} alignItems={'center'} bgColor={'var(--Gray1)'} borderRadius={'50%'} color={'#fff'}>
                                <Img src={IconLocation} />
                              </Box>
                              <Box height={'200px'} borderRadius={'100px'} bgColor={'#D9D9D9'} w={'.5rem'}></Box>
                            </Box>
                            <Box display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} w={'45%'} fontWeight={'600'} pt={'.2rem'}>
                              {e.data}
                            </Box>
                          </Flex>
                        )
                      }
                    })}
                  </TabPanel>
                  )
                : (
                  <TabPanel>
                    <Flex>
                      <h4>Não há outras avaliações</h4>
                    </Flex>
                  </TabPanel>
                  )}

            </TabPanels>
          </Tabs>
        </>
      )}
    </Body>
  )
}
