import styled from 'styled-components'
import { Column } from '../../styles/styledGlobal'

export const DashBox = styled.div`
margin-bottom: 1.5rem;
border:0.063rem solid var(--${props => props.theme});
border-style: dashed;
padding: 0.5rem;
border-radius: 0.938rem;

.DashTitle{
font-weight: bold;
color: var(--a3);
margin-bottom:.5rem;
}

.PartSubtitle{
    font-weight: bold;
    font-size: 0.875rem;
    transform:rotate(90deg)
}
`

export const CardParticipante = styled.div`
border-radius: 1rem;
display: flex;
align-items: center;
justify-content: space-between;
width: fit-content;
background: var(--c2);
border: 0.125rem solid var(--v3);
width: 10.8rem;
padding: 0.5rem;
`

export const ImgAssociado = styled.img`
width:2.5rem;
height:2.5rem;
border-radius: 1.25rem;
`

export const BtnPerfil = styled.a`
border: 0.125rem solid var(--${props => props.theme});
color: var(--${props => props.theme});
border-radius: 0.625rem;
background: none;
font-size: 0.875rem;
font-family: 'Roboto','sans-serif';
font-weight: bold;
height: 1.875rem;
display: flex;
justify-content: center;
align-items: center;
margin: 1rem 0rem 0rem 0rem;

&:hover{
    background: var(--${props => props.theme});
    color: white;
}
`

export const RepositoryBox = styled.div`
border: 0.063rem solid var(--c4);
padding: .5rem;
border-radius: 0.938rem;
color: var(--c7);

.folder-c:nth-child(2n+1){
    background: var(--c2);
}

.folder-c:nth-child(n){
    align-items: center;
    padding: 1rem;
}

.folder-c{
    justify-content: space-between;

    h5:hover{
        text-decoration: underline;
        cursor: pointer;
    }
}

svg{
    margin-right: 1rem;
}
`

export const ColorCircle = styled.div`
height: 0.688rem;
width: 0.688rem;
border-radius: 0.344rem;
background-color: var(--${props => props.theme});
`

export const MainColumn = styled(Column)<{padding: string, width: string, margin?: string}>`
padding: ${props => props.padding};
margin: ${props => props.margin};
width: ${props => props.width};
box-shadow: var(--SombraBackground);
background: white;
`

export const ColumnTexts = styled(Column)`
color: var(--a3);
h3{
    margin-bottom:.5rem;
}

span:not(:last-child){
margin-bottom: .3rem;
}
`

export const ImgPerfil = styled.label`
cursor: pointer;
&:hover {
    filter: brightness(0.5);
}

input[type=file]{
    display: none;
}
`
