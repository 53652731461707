/* eslint-disable no-return-assign */
import { Flex } from '@chakra-ui/react'
import {
  FaChartPie,
  FaCheckCircle, FaClipboard, FaCog, FaCopy, FaEnvelope
  , FaEye, FaPen,
  FaRegStopCircle,
  FaTrash, FaUser, FaFileCsv
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { urlApi } from '../../../../../../services/appApi'
import { parseJwt } from '../../../../../../services/token'
import { ObterToken } from '../../../../../../Utils/Helper'
import { OutlineButton } from '../OutlineButton'

interface iGestaoPesquisaInterna {
  id: string
  nomePesquisa: string
  dataInicio: string
  dataFim?: string
  ciclo: string
  statusPesquisa: string
  statusForm: string
  convites: number
  respostas: number
  anonima: boolean
  trilhaId: string
}

interface iCardProps {
  pesquisa: iGestaoPesquisaInterna
  onDelete: (id: string) => void
  onEncerrar: (id: string) => void
  onCopy: (id: string) => void
}

export const CardGestao: React.FC<iCardProps> = ({ pesquisa, onDelete, onEncerrar, onCopy }) => {
  const nav = useNavigate()
  return (
        <Flex border={'2px solid var(--Azul)'} flexDir={'column'} padding={'1rem'} borderRadius={'24px'}>
            <Flex justifyContent={'space-between'}>
                <Flex alignItems={'center'} height={'fit-content'} marginBottom={'.8rem'}>
                    <FaClipboard size={25} style={{ marginRight: '.5rem' }} color="var(--a2)" />
                    <h3>{pesquisa.nomePesquisa}</h3>
                </Flex>
                <Flex marginBottom={'.5rem'}>
                    <h4>Status: <strong> {pesquisa.statusPesquisa}</strong></h4>
                </Flex>
            </Flex>

            <Flex justifyContent={'space-between'} width={'100%'} mb={'0.5rem'}>
                <Flex flexDir={'column'}>
                    <span>Início: {pesquisa.dataInicio || 'Pesquisa não configurada'}</span>
                    <span style={{ margin: '.5rem 0' }}>Fim: {pesquisa.dataFim ?? 'Indefinido'}</span>
                    <span>Ciclo:  {pesquisa.ciclo}</span>
                </Flex>
                <Flex flexDir={'column'} alignItems={'end'} justifyContent={'end'}>
                    <Flex flexDir={'column'} justifyContent={'center'}>
                        <Flex fontWeight={'400'} color={'black'}>
                            <FaUser color='var(--Gray2)' style={{ marginRight: '.5rem' }} />
                            <span>Convites: {pesquisa.convites}</span>
                        </Flex>
                        <Flex fontWeight={'400'} color={'black'} margin={'1rem 0rem'}>
                            <FaCheckCircle color='var(--v2)' style={{ marginRight: '.5rem' }} />
                            <span>Respostas: {pesquisa.respostas}</span>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex gap={'0 .5rem'} justifyContent={'flex-end'}>
                {
                    (pesquisa.statusForm === 'Aprovado' && pesquisa.statusPesquisa !== 'Finalizado') && (
                        <OutlineButton
                            varColor='Green2'
                            leftIcon={<FaEnvelope />}
                            onClick={pesquisa.trilhaId === null
                              ? () => nav(`/Jornada/PesquisaInterna/Convites/${pesquisa.id}`)
                              : () => nav(`/Jornada/Configuracao/Trilha/${pesquisa.trilhaId}/PesquisaInterna/Convites/${pesquisa.id}`)}
                        >
                            Gestão de envios
                        </OutlineButton>
                    )
                }

                {
                    (pesquisa.trilhaId === null && pesquisa.statusForm === 'Aprovado' && pesquisa.statusPesquisa !== 'Finalizado' && pesquisa.dataInicio === null) &&
                    <OutlineButton
                        varColor='Green2'
                        leftIcon={<FaCog />}
                        onClick={() => nav(`/Jornada/PesquisaInterna/Configuracao/${pesquisa.id}`)}
                    >
                        Configurar envios
                    </OutlineButton>
                }

                {
                    (((!pesquisa.anonima && pesquisa.respostas > 0) || (pesquisa.anonima && pesquisa.respostas >= 5)) || (pesquisa.trilhaId && pesquisa.respostas > 0 && pesquisa.statusPesquisa === 'Em andamento')) &&
                    <OutlineButton
                        varColor='Green2'
                        leftIcon={<FaChartPie />}
                        onClick={() => nav(`/Jornada/PesquisaInterna/Resultado/${pesquisa.id}`)}
                    >
                        Resultado
                    </OutlineButton>
                }

                {
                    (parseJwt().adm === 'True' && (((!pesquisa.anonima && pesquisa.respostas > 0) || (pesquisa.anonima && pesquisa.respostas >= 5)) || (pesquisa.trilhaId && pesquisa.respostas > 0 && pesquisa.statusPesquisa === 'Em andamento'))) &&
                    <OutlineButton
                        varColor='Green2'
                        leftIcon={<FaFileCsv />}
                        onClick={() => window.location.href = `${urlApi}/PesquisaInterna/Csv?pesquisaId=${pesquisa.id}&token=${ObterToken()}`}
                    >
                        Baixar base
                    </OutlineButton>
                }

                {
                    (pesquisa.trilhaId === null && pesquisa.statusForm === 'Aprovado' && pesquisa.statusPesquisa === 'Em andamento') &&
                    <OutlineButton
                        onClick={() => onEncerrar(pesquisa.id)}
                        varColor='terc3'
                        leftIcon={<FaRegStopCircle />}
                    >
                        Encerrar pesquisa
                    </OutlineButton>
                }

                <OutlineButton
                    onClick={() => onCopy(pesquisa.id)}
                    varColor='Azul'
                    leftIcon={<FaCopy />}
                >
                    Duplicar pesquisa
                </OutlineButton>

                {
                    pesquisa.statusForm !== 'Aprovado' &&
                    <OutlineButton
                        varColor='Blue1'
                        leftIcon={<FaPen />}
                        onClick={() => nav(`/Jornada/PesquisaInterna/Formulario/${pesquisa.id}`)}
                    >
                        Editar pesquisa
                    </OutlineButton>
                }
                {
                    (pesquisa.statusForm === 'Aprovado' && pesquisa.dataInicio !== null) && (
                        <OutlineButton varColor='Rosa' onClick={() => nav(`${pesquisa.id}/Previsualizar`)} leftIcon={<FaEye />}>
                            Pré-visualizar formulário
                        </OutlineButton>
                    )
                }
                {
                    pesquisa.trilhaId === null && pesquisa.statusPesquisa !== 'Finalizado' &&
                    <OutlineButton
                        varColor='Red' leftIcon={<FaTrash />}
                        onClick={() => onDelete(pesquisa.id)}
                    >Excluir</OutlineButton>
                }
            </Flex>
        </Flex>
  )
}
