import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { MdClose } from 'react-icons/md'
import { Header } from './styles'
import { Flex, FormControl, FormLabel, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react'
import { iTag } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Button } from '../../../../../components/Button'

interface iModalAddAvaliado {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (squadId: string, opcao: string) => void
}

export const ModalAdicionarPorSquad: React.FC<iModalAddAvaliado> = ({ isOpen, onConfirm, onRequestClose }) => {
  Modal.setAppElement('#root')

  const [Squads, setSquads] = useState<iTag[]>([])
  const [SquadId, setSquadId] = useState<string>()

  const [IsLoading, setIsLoading] = useState(false)

  const [Opcao, setOpcao] = useState('0')

  function ObterSquads(): void {
    appApi.get('Tag?tipo=2')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }

  function InternalConfirm(): void {
    setIsLoading(true)
    onConfirm(SquadId ?? '', Opcao)
  }

  useEffect(() => {
    ObterSquads()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Header>
        <Flex justifyContent={'space-between'}>
          <div>
            <h2>Adicionando via squad</h2>
            <span>Adicione os colaboradores de um squad para uma pesquisa. Você definirá o squad que deseja adicionar e como serão adicionados seus stakeholders</span>
          </div>
          <MdClose color='var(--a3)' cursor={'pointer'} onClick={onRequestClose} />
        </Flex>
      </Header>
      <Flex flexDir={'column'} padding={'1rem'} gap={'1.5rem'}>
        <FormControl>
          <FormLabel>Squads</FormLabel>
          <Select placeholder='selecione um squad disponível na lista' onChange={(e) => setSquadId(e.target.value)}>
            {
              Squads.map((e, i) => {
                return (
                  <option key={i} value={e.id}>{e.texto}</option>
                )
              })
            }
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Adicionar stakeholders por</FormLabel>
          <RadioGroup value={Opcao} onChange={setOpcao}>
            <Stack direction='column'>
              <Radio value='0'>Hierarquia do organograma tradicional</Radio>
              <Radio value='1'>Hierarquia do squad (somente líderes e pares são adicionados automaticamente)</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </Flex>
      <Flex alignItems={'center'} justifyContent={'end'} padding={'0rem 1rem 1rem 0rem'}>
        <Button
          size='lg'
          VarColor='v2'
          isLoading={IsLoading}
          onClick={InternalConfirm}
        >Adicionar</Button>
      </Flex>
    </Modal>
  )
}
