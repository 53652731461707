import { Flex, Input } from '@chakra-ui/react'
import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { MetaButton } from '../Buttons/MetaButton'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (dataFim: string) => void
  message: string
  center?: boolean
  status?: boolean
}

export const ModalReabrir: React.FC<props> = ({ isOpen, center, onRequestClose, onConfirm, message, status }) => {
  Modal.setAppElement('#root')
  const [Status, setStatus] = useState(status ?? false)
  const [DataFim, setDataFim] = useState('')
  return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => { onRequestClose(); if (!status) { setStatus(false) } }}
            overlayClassName='react-modal-overlay'
            className='react-modal-content-poupUp'
        >
            <Flex>
                <MdClose size={24} onClick={onRequestClose} />
            </Flex>

            {!Status && (
                <Flex flexDir={'column'}>
                    <h2 style={{ color: 'var(--secundario5)' }}>Atenção!</h2>
                    <span style={{ textAlign: center ? 'center' : 'unset' }}>{message}</span>
                    <Flex>
                        <MetaButton bg='c5' size='md' onClick={() => { onRequestClose(); if (!status) { setStatus(false) } }}>Cancelar</MetaButton>
                        <MetaButton bg='v2' size='md' onClick={() => { setStatus(true) }}>Continuar</MetaButton>
                    </Flex>
                </Flex>
            )}

            {Status && (
                <Flex flexDir={'column'}>
                    <h2 style={{ color: 'var(--secundario5)' }}>Defina a data de término:</h2>
                    <Input
                        width={'15rem'}
                        type={'datetime-local'}
                        min={new Date().toISOString().slice(0, 16)}
                        borderColor={'var(--Gray4)'}
                        onChange={(e) => setDataFim(e.target.value)}
                    />
                    <Flex>
                        <MetaButton bg='v2' size='md' onClick={() => { onConfirm(DataFim); onRequestClose(); if (!status) { setStatus(false) } }}>Confirmar</MetaButton>
                    </Flex>
                </Flex>
            )}

        </Modal>
  )
}
