import { Avatar, Box, Flex, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FaCheck, FaChevronDown, FaChevronUp, FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { MdBlock } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../../contexts/AuthContext'
import { IEmployee } from '../../../../../../interfaces'
import { parseJwt } from '../../../../../../services/token'
import { PropInvalida } from '../../../../../../Utils/Helper'

import {
  AvatarColumn,
  Container,
  SmallColumn,
  SmallColumnHeader
} from './styles'

interface IEmployeeTableProps {
  employeeList: IEmployee[]
  onEdit: (employee: IEmployee) => void
  onDelete: (employee: IEmployee) => void
}

export const EmployeeTable: React.FC<IEmployeeTableProps> = ({
  employeeList,
  onEdit,
  onDelete
}) => {
  const [Employee, setEmployee] = useState(employeeList)
  const [Ordenacao, setOrdenacao] = useState(false)
  const [UltimoFiltro, setUltimoFiltro] = useState<number>()

  function OrdenarTabela(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
  }
  useEffect(() => {
    const copy = [...employeeList]
    if (UltimoFiltro === 1) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.nome.localeCompare(a.nome)
        } else if (Ordenacao) {
          return a.nome.localeCompare(b.nome)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.email.localeCompare(a.email)
        } else if (Ordenacao) {
          return a.email.localeCompare(b.email)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 3) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.cargo.nome.localeCompare(a.cargo.nome)
        } else if (Ordenacao) {
          return a.cargo.nome.localeCompare(b.cargo.nome)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 4) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.departamento.nome.localeCompare(a.departamento.nome)
        } else if (Ordenacao) {
          return a.departamento.nome.localeCompare(b.departamento.nome)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 5) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return a.tipoUsuario - b.tipoUsuario
        } else if (Ordenacao) {
          return b.tipoUsuario - a.tipoUsuario
        } else {
          return 0
        }
      })
    }
    setEmployee(copy)
  }, [Ordenacao, UltimoFiltro, employeeList])
  const { Permissoes } = useAuth()
  const nav = useNavigate()
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th> </th>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(1)}>Nome {UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp fontSize={'16px'} style={{ marginTop: '6px' }}/> : <FaChevronDown fontSize={'16px'} style={{ marginTop: '6px' }}/>}</Flex></th>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(2)}>Email {UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp fontSize={'16px'} style={{ marginTop: '6px' }}/> : <FaChevronDown fontSize={'16px'} style={{ marginTop: '6px' }}/>}</Flex></th>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(3)}>Função {UltimoFiltro === 3 && !Ordenacao ? <FaChevronUp fontSize={'16px'} style={{ marginTop: '6px' }}/> : <FaChevronDown fontSize={'16px'} style={{ marginTop: '6px' }}/>}</Flex></th>
            <th><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(4)}>Área {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp fontSize={'16px'} style={{ marginTop: '6px' }}/> : <FaChevronDown fontSize={'16px'} style={{ marginTop: '6px' }}/>}</Flex></th>
            <SmallColumnHeader><Flex alignItems={'center'} gap={'.5rem'} cursor={'pointer'} onClick={() => OrdenarTabela(5)}>Gestor {UltimoFiltro === 5 && !Ordenacao ? <FaChevronUp fontSize={'16px'} style={{ marginTop: '6px' }}/> : <FaChevronDown fontSize={'16px'} style={{ marginTop: '6px' }}/>}</Flex></SmallColumnHeader>
            {parseJwt().adm === 'True' && <th> </th>}
          </tr>
        </thead>

        <tbody>
          {Employee.map((employee) => (
            <tr key={employee.id} >
              <AvatarColumn color={employee.primeiroAcesso ? 'var(--Green3)' : 'var(--Orange)'} onClick={() => {
                if (Permissoes.jornada && employee.dataDesligamento) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}?criar=true`)
                } else if (Permissoes.jornada) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <div>
                  <Avatar
                    cursor={'pointer'}
                    src={employee.avatar}
                    name={employee.nome}
                    border={employee.primeiroAcesso ? '2px solid var(--Green3)' : '2px solid var(--Orange)'}
                  />
                </div>
              </AvatarColumn>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                if (Permissoes.jornada && employee.dataDesligamento) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}?criar=true`)
                } else if (Permissoes.jornada) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <strong>{employee.nome}</strong>
              </td>
              <td style={{ cursor: 'pointer' }} onClick={() => {
                if (Permissoes.jornada && employee.dataDesligamento) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}?criar=true`)
                } else if (Permissoes.jornada) {
                  nav(`/Rh/VisualizarPerfil/${employee.id}`)
                }
              }}>
                <span>{employee.email}</span>
              </td>
              <td>
                <span>{employee.cargo?.nome}</span>
              </td>
              <td>
                <span>{employee.departamento?.nome}</span>
              </td>
              <SmallColumn isActive={employee.gestorDeArea}>
                <FaCheck />
              </SmallColumn>
              {
                parseJwt().adm === 'True' &&
                <SmallColumn>
                  <div>
                    {PropInvalida(employee.dataDesligamento) && <button type="button" onClick={() => onEdit(employee)}>
                      <FiEdit />
                    </button>}
                    {PropInvalida(employee.dataDesligamento) &&
                      <button type="button" onClick={() => onDelete(employee)}>
                        <FaTrashAlt size={24} />
                      </button>}
                    {!PropInvalida(employee.dataDesligamento) &&
                      <Tooltip placement='top' label={`Colaborador será desligado no dia ${employee.dataDesligamento ?? ''}`} hasArrow bg={'var(--Azul)'} color={'white'} p={'.5rem'} mr={'.4rem'}>
                        <Box>
                          <button type='button'>
                            <MdBlock style={{ opacity: '50%', color: 'red' }} size={30} />
                          </button>
                        </Box>
                    </Tooltip>
                    }
                  </div>
                </SmallColumn>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  )
}
