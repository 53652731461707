import { Flex } from '@chakra-ui/react'

interface iEmojiProps{
  icon: any
  texto: string
  onClick: () => void
  disabled?: boolean
}

export const Emoji: React.FC<iEmojiProps> = ({ icon, texto, disabled, onClick }) => {
  return (
        <Flex onClick={onClick} opacity={disabled ? '.6' : ''} cursor={'pointer'} flexDirection={'column'} textAlign={'center'}>
            <img style={{ height: '4rem', marginBottom: '.5rem' }} src={icon} />
            <h4>{texto}</h4>
        </Flex>
  )
}
