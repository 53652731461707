import styled, { css } from 'styled-components'

interface IContentProps {
  isActive: boolean
}

interface IAvatarContainerProps {
  backgroundImg: string
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const Content = styled.div<IContentProps>`
  background: var(--white);
  padding: 0.25rem;
  border-radius: 0.25rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 0.75rem;
    color: var(--a5);
  }

  ${(props) =>
    props.isActive &&
    css`
      border: 1px solid var(--Mostarda);
    `}

  footer {
    width: 6rem;
    padding: 0.25rem;
    background: var(--c5);
    border-radius: 0 0 0.25rem 0.25rem;

    text-align: left;
    line-height: 1rem;

    margin-top: 0.125rem;
  }
`

export const AvatarContainer = styled.div<IAvatarContainerProps>`
  height: 7rem;
  width: 6rem;
  border-radius: 0.25rem 0.25rem 0 0;
  background: var(--c2);
  background-image: url(${(props) => props.backgroundImg});
  background-size: 6rem 7rem;
  background-repeat: no-repeat;

  display: flex;
  align-items: flex-end;

  div {
    padding: 0.125rem 0.25rem;
    background: var(--a5);

    text-align: left;
    flex: 1;
    margin-bottom: 0.25rem;

    p {
      font-size: 0.75rem;
      color: var(--c1);
      text-transform: capitalize;
    }
  }
`
