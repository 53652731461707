import React from 'react'

import { Container } from './styles'

interface ISectionHeaderProps {
  title: string
  showFilters?: boolean
}

export const SectionHeader: React.FC<ISectionHeaderProps> = ({
  title,
  showFilters = false
}) => {
  return (
    <Container>
      <div>
        <h3>{title}</h3>
      </div>
      {showFilters && (
        <aside>
          <input type="text" />
          <input type="text" />
          <input type="text" />
        </aside>
      )}
    </Container>
  )
}
