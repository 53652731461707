import { Box, Button, Flex, HStack, Spinner, Text } from '@chakra-ui/react'
import { CardColaborador } from './components/CardColaborador'
// import imgPerfil from '../../../../assets/pi/perfil.png'

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { PropInvalida } from '../../../../Utils/Helper'

interface IPerfis{
  buscaInterna?: String
  perfis: IperfilDto[]
  paginaAtual: number
  numeroPaginas: number
  isLoading: boolean
  next: VoidFunction
  prev: VoidFunction
}

interface IperfilBa{
  id?: string
  nome: string
  dataPesquisa: string
  perfil: string
  urlImgPerfil?: string
  selo?: string
  urlImgSelo?: string
  urlPdfPerfil?: string
  urlPdfQuadroPessoal?: string
  urlPdfQuadroGestores?: string
  urlPdfPlacard?: string
}
export interface IperfilDto{
  perfil: IperfilBa
  outrosCargos: IOutrosCargos[]
}

interface IOutrosCargos{
  nome: string
  jobId: string
  adaptativo?: boolean
  perfisAdaptativos: string[]
  perfisAderentes: string[]
  numAderentes: number
  numAdaptativos: number

}
// interface IPerfis{
//   jobTarget: IJobTarget
//   numeroPerfis: number
//   numeroPerfisAdaptativos: number
//   numeroPerfisAderentes: number
//   numeroPerfisNaoAderentes: number
//   perfis: IperfilDto[]

// }
export function formatarData(data: string): string {
  const dataStr = data.split('T')[0]
  const dataArr = dataStr.split('-')
  return `${dataArr[2]}/${dataArr[1]}/${dataArr[0]}`
}
export const TabelaPerfis: React.FC<IPerfis> = ({ buscaInterna, numeroPaginas, paginaAtual, perfis, next, prev, isLoading }: IPerfis) => {
  // function obterTipoPerfil(perfil: string): number {
  //   if (job?.jobTarget !== undefined) {
  //     if (job.jobTarget.perfisAderentes.includes(perfil)) return 0
  //     if (job.jobTarget.perfisAdaptativos.includes(perfil)) return 1
  //   }
  //   return 2
  // }
  const numberFormatter = Intl.NumberFormat('pt-BR')
  console.log('componente da tabela')
  console.log(perfis)
  return (
    <>
    <Flex mr={4} mt={0} justifyContent='end'>

    {numeroPaginas > 1 &&

(<HStack mt={0} mx={5} borderTop='2px'
 borderLeft='2px' borderRight='2px' borderTopLeftRadius='2xl' borderTopRightRadius='2xl' p={2} borderColor='var(--c4)' backgroundColor='#fcfbf4'>
      <Text>Página:</Text>
      <Button size='xs' onClick={prev} bgColor='var(--a1)'><FaArrowLeft/></Button>
      <Text>{numberFormatter.format(paginaAtual + 1)} / {numberFormatter.format(numeroPaginas)}</Text>
      <Button size='xs' onClick={next} bgColor='var(--a1)'><FaArrowRight/></Button>

      </HStack>
)}
</Flex>
 <Box border='2px' borderRadius='8px' borderColor='var(--c4)' mb={4} mx={4} px={4}>

  <Flex flexDir='column'>

<Flex justifyContent='space-between' mt={0} p={4} color='var(--Gray3)'>
<Text fontSize='12px' width='12rem' textAlign='center'
fontWeight='bold'
>Colaborador</Text>
<Text fontSize='12px' width='7rem' textAlign='center'
fontWeight='bold'
>Data da pesquisa</Text>
<Text fontSize='12px' width='6rem' textAlign='center'
fontWeight='bold'
></Text>
<Text fontSize='12px' width='14rem' textAlign='start'
fontWeight='bold'
>Perfil</Text>
<Text fontSize='12px' width='6rem' textAlign='center'
fontWeight='bold'
>Aderência</Text>
<Text
fontSize='12px' width='12rem' textAlign='center'
fontWeight='bold'
>Outros cargos aderentes</Text>

</Flex>
<Flex flexDir='column' overflowY='auto' height='22rem'>
  <Box alignSelf='end'>
      {isLoading && (

        <HStack>
      <Text m={2}>Carregando perfis...</Text>
      <Spinner
  thickness='4px'
  speed='0.65s'
  emptyColor='gray.200'
  color='red.500'
  size='lg'
/>
</HStack>
      )}

  </Box>
{perfis.map((j, i) => {
  return (<CardColaborador
    key={j.perfil.id}
    id={j.perfil.id ?? ''}
    imgPerfil={j.perfil.urlImgPerfil}
    avatar={''}
    cargos={j.outrosCargos}
    dataPesquisa={formatarData(j.perfil.dataPesquisa)}
    nome={j.perfil.nome}
    tipo={PropInvalida(j.outrosCargos) ? 2 : j.outrosCargos.length > 0 ? 0 : 2}
    perfil={j.perfil.perfil}
    par={i % 2 !== 0}
    selo={j.perfil.selo}
    urlSelo = {j.perfil.urlImgSelo}
    />)
})}

  </Flex>
  </Flex>
  </Box>
  </>
  )
}
