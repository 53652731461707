import { useLocation } from 'react-router-dom'
import { iEtapa } from '../interfaces'
import { parseJwt } from '../services/token'

export function ObterCorEtapaPeloTipo(Tipo: number): string {
  let cor = ''
  switch (Tipo) {
    case 0:
      cor = '67C0A2'
      break

    case 1:
      cor = 'B265FF'
      break

    case 2:
      cor = '00B496'
      break

    case 3:
      cor = '7879F1'
      break

    case 4:
      cor = '6DA0FF'
      break

    case 5:
      cor = 'EC9C00'
      break

    case 6:
      cor = '00B45A'
      break

    case 7:
      cor = '365ad0'
      break

    case 8:
      cor = 'EB5757'
      break

    case 9:
      cor = 'E18787'
      break

    case 10:
      cor = '365ad0'
      break
    default:return 'C9C9C9'
  }
  return cor
}

export function ObterPercentual(qtdObservada: number, total: number): number {
  return qtdObservada * 100 / total
}

export function ObterCorFundoEtapaPeloTipo(Tipo: number): string {
  let cor = ''
  switch (Tipo) {
    case 0:
      cor = 'DBF0E9'
      break

    case 1:
      cor = 'F6EDFF'
      break

    case 2:
      cor = 'E6FEFA'
      break

    case 3:
      cor = 'F6EDFF'
      break

    case 4:
      cor = 'F0F5FE'
      break

    case 5:
      cor = 'F9F3DC'
      break

    case 6:
      cor = 'c9F3DC'
      break

    case 7:
      cor = 'adbcf7'
      break
    case 9:
      cor = 'E187874D'
      break
    case 10:
      cor = '1961E00D'
      break
    default: return 'white'
  }
  return cor
}

export function ObterToken(): string {
  const token = localStorage.getItem('token')
  return `bearer ${token as string}`
}

export function DistinctString(array: string[]): string[] {
  return array.filter((value, index, array) => array.indexOf(value) === index)
}

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search)
}

export function ValidarEtapa(Etapa: iEtapa): boolean {
  if (PropInvalida(Etapa)) {
    return false
  }

  if (PropInvalida(Etapa.dataInicio) && PropInvalida(Etapa.dataFim) && PropInvalida(Etapa.etapaPai) && PropInvalida(Etapa.tempoDuracao) && PropInvalida(Etapa.tempoInicio) && PropInvalida(Etapa.tempoAntesDesligamento) && PropInvalida(Etapa.tempoAposDesligamento)) {
    return false
  }

  if (PropInvalida(Etapa.dataInicio) && PropInvalida(Etapa.dataFim) && PropInvalida(Etapa.etapaPai) && (PropInvalida(Etapa.tempoDuracao) || (PropInvalida(Etapa.tempoInicio) && PropInvalida(Etapa.tempoAntesDesligamento) && PropInvalida(Etapa.tempoAposDesligamento)))) {
    return false
  }

  return true
}

export function ValidarSenha(senha: string): boolean {
  return /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.{6,})/.test(senha)
}

export function ValidarEmail(email: string): boolean {
  if (email === undefined || email === null || email.length > 120 || email.length < 5 || !email.includes('@')) {
    return false
  }

  return true
}

export function ValidaCPF(cpf: string): boolean {
  cpf = cpf.replace(/[\s.-]*/igm, '')
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }
  let soma = 0
  let resto
  for (let i = 1; i <= 9; i++) { soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i) }
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (let i2 = 1; i2 <= 10; i2++) { soma = soma + parseInt(cpf.substring(i2 - 1, i2)) * (12 - i2) }
  resto = (soma * 10) % 11
  if ((resto === 10) || (resto === 11)) resto = 0
  if (resto !== parseInt(cpf.substring(10, 11))) return false

  return true
}

export function DeleteElementByPosition<T>(array: T[], index: number): T[] {
  array.splice(index, 1)
  return array
}

export function InsertElementByPosition<T>(arr: T[], index: number, newItem: T): T[] {
  return [...arr.slice(0, index), newItem, ...arr.slice(index)]
}

export function ArrayToChunks<T>(array: T[], size: number): T[][] {
  const chunks: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size)
    chunks.push(chunk)
  }
  return chunks
}

export function PropInvalida(value: any): boolean {
  return value === '' || value == null || value === undefined
}

export const ObjetoVazio = (objectName: any): boolean => {
  return objectName && Object.keys(objectName).length === 0
}

export function converterData(val: string, plusDay?: number): Date {
  const [day, month, year] = val.split('/')
  if (plusDay) {
    const date = new Date(+year, +month - 1, +day + plusDay)
    return date
  } else {
    const date = new Date(+year, +month - 1, +day)
    return date
  }
}

export function ConverterDataHora(val: string): Date {
  const [day, month, yearComHora] = val.split('/')
  const year = yearComHora.split(' ')[0]
  const [Hours, Minutes] = yearComHora.split(' ')[1].split(':')
  return new Date(+year, +month - 1, +day, +Hours, +Minutes)
}

export function UpdateElementByPosition<T>(arr: T[], index: number, upItem: T): T[] {
  const copy = [...arr]
  copy[index] = upItem
  return copy
}

export function transformarDuracao(tempo: number): string {
  const horas = (tempo / 60).toString().split('.')[0]
  const minutos = tempo % 60
  const str = `${horas} ${parseInt(horas) > 1 ? 'horas' : 'hora'} ${minutos > 0 ? `e ${minutos} minutos` : ''}`
  if (str.includes('0 hora')) {
    return str.replace('0 hora e', '')
  }
  return str
}

export function SubstringRoute(route: string, stop: string): string {
  return route.split(stop)[0]
}

export function AutorizarPI(): boolean {
  const jwt = parseJwt()
  const empresa = jwt.eid === 'acbf6a22-154e-480d-a465-61b990faa8bd' || jwt.eid === 'c5d7423c-5fbc-4917-a667-c2a2ec9693d7'
  const consultoria = jwt.consultoria === 'True'
  return empresa || jwt.uid === 'd87c1f61-9d40-4d38-ad30-ff4362a65360' || consultoria
}

export function FluxoEtapa(rota: string): boolean {
  rota = rota.toLowerCase()
  return rota.includes('processo') || rota.includes('trilha')
}
