/* eslint-disable no-return-assign */
import { Flex } from '@chakra-ui/react'
import {
  FaCalendarAlt,
  FaChartBar,
  FaCheckCircle,
  FaClipboard, FaEnvelope,
  FaEye,
  FaPen,
  FaRegStopCircle, FaTrash, FaUser
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { iPesquisaNps } from '../../../../../../interfaces'
import { urlSigah } from '../../../../../../services/appApi'
import { ObterToken } from '../../../../../../Utils/Helper'
import { OutlineButton } from '../OutlineButton'
import { PieChart } from '../PieChart'

interface iCardProps {
  pesquisa: iPesquisaNps
  encerrarPesquisa: (id: string) => void
  onDelete: (id: string) => void
}

export const CardGestao: React.FC<iCardProps> = ({ pesquisa, onDelete, encerrarPesquisa }) => {
  const nav = useNavigate()
  const Route = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}`
  const redirectPage = (ct: string, at: string, param: string): any => window.location.href = `${Route}&ct=${ct}&at=${at}&paramId=${param}`

  return (
        <Flex border={'2px solid var(--Azul)'} padding={'1rem'} borderRadius={'24px'}>
            <Flex justifyContent={'space-between'} width={'100%'}>
                <Flex flexDir={'column'}>
                    <Flex alignItems={'center'} height={'fit-content'} marginBottom={'.8rem'}>
                        <FaClipboard size={25} style={{ marginRight: '.5rem' }} color="var(--a2)" />
                        <h4>{pesquisa.descricao}</h4>
                    </Flex>
                    {pesquisa.dataCriacao && <span>Criação: {pesquisa.dataCriacao}</span>}
                </Flex>
                <Flex flexDir={'column'} alignItems={'end'} justifyContent={'end'}>
                    <Flex>
                        <Flex>
                            {
                                (pesquisa.graficoNps !== undefined && pesquisa.graficoNps !== null) && (
                                    <PieChart
                                        id={`chart-${pesquisa.id}`}
                                        detratores={pesquisa.graficoNps.detratores}
                                        neutros={pesquisa.graficoNps.neutros}
                                        promotores={pesquisa.graficoNps.promotores}
                                        nps={pesquisa.graficoNps.nps.toFixed(0)}
                                    />
                                )
                            }
                        </Flex>
                        <Flex flexDir={'column'} justifyContent={'center'}>
                            <Flex fontWeight={'400'} color={'black'}>
                                <FaUser color='var(--Gray2)' style={{ marginRight: '.5rem' }} />
                                <span>Convites: {pesquisa.convites}</span>
                            </Flex>
                            <Flex fontWeight={'400'} color={'black'} margin={'1rem 0rem'}>
                                <FaCheckCircle color='var(--Green2)' style={{ marginRight: '.5rem' }} />
                                <span>Respostas: {pesquisa.respostas}</span>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex gap={'1rem'}>
                            <OutlineButton
                                varColor='Green2'
                                leftIcon={<FaEye />}
                                onClick={() => redirectPage('PesquisaPulse', 'VisualizarFormulario', pesquisa.id)}
                            >
                                Visualizar formulário
                            </OutlineButton>
                        {!pesquisa.formularioAprovado &&
                            <OutlineButton
                                varColor='Green2'
                                leftIcon={<FaPen />}
                                onClick={() => redirectPage('PesquisaPulse', 'EditarFormulario', pesquisa.id)}
                            >
                                Editar pesquisa
                            </OutlineButton>
                        }

                        {pesquisa.dataInicio === null && pesquisa.formularioAprovado &&
                            <OutlineButton
                                varColor='Green2'
                                leftIcon={<FaCalendarAlt />}
                                onClick={() => redirectPage('PesquisaPulse', 'Periodo', pesquisa.id)}
                            >
                                Marcar datas
                            </OutlineButton>
                        }

                        {!pesquisa.encerrada && pesquisa.formularioAprovado && pesquisa.dataInicio !== null && !pesquisa.onboarding &&
                            <OutlineButton
                                varColor='Green2'
                                leftIcon={<FaEnvelope />}
                                onClick={() => redirectPage('PesquisaPulse', 'Participantes', pesquisa.id)}
                            >
                                Gestão de envios
                            </OutlineButton>
                        }

                        {pesquisa.respostas >= 5 &&
                            <OutlineButton
                                varColor='Green2'
                                leftIcon={<FaChartBar />}
                                onClick={() => nav(`/Jornada/eNps/Resultado/${pesquisa.id}`)}
                            >
                                Resultados
                            </OutlineButton>
                        }

                        {pesquisa.respostas >= 5 && !pesquisa.encerrada && !pesquisa.onboarding &&
                            <OutlineButton
                                onClick={() => encerrarPesquisa(pesquisa.id)}
                                varColor='Red' leftIcon={<FaRegStopCircle />}
                            >
                                Encerrar pesquisaasdas
                            </OutlineButton>
                        }

                        {!pesquisa.encerrada && !pesquisa.onboarding &&
                            <OutlineButton
                                onClick={() => onDelete(pesquisa.id)}
                                varColor='Red' leftIcon={<FaTrash />}
                            >
                                Excluir
                            </OutlineButton>
                        }

                        {/* <OutlineButton
                            varColor='Blue1'
                            leftIcon={<FaCopy />}
                            onClick={() => nav(`/CriarImersao/${pesquisa.id}`)}
                        >
                            Duplicar pesquisa
                        </OutlineButton> */}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}
