import styled from 'styled-components'

export const SectionOnboard = styled.div`
display: flex;
flex-direction: column;
& > div {
    margin-top: 1rem;
}
`

export const ColumnTitle = styled.div`
display: flex;
flex-direction: column;
margin: 1rem 0rem;

h2{
    color:var(--a3);
}
`
