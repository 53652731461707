/* eslint-disable @typescript-eslint/no-misused-promises */
import { Alert, AlertDescription, Box, Button, Flex, FormControl, FormLabel, Input, Switch, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Title, Container } from './styles'
import LogoSah from '../../../assets/LogoSah.png'
import { ValidaCPF, ValidarEmail } from '../../../Utils/Helper'
import { SigahApi, appApi } from '../../../services/appApi'
import { LayoutFull } from '../../Layouts/LayoutFull'
import { useNavigate } from 'react-router-dom'

interface iLogin {
  email: string
}

export const EsqueciSenha: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const { register, handleSubmit } = useForm()
  const [Cpf, setCpf] = useState<boolean>()
  const [EmailEnviado, setEmailEnviado] = useState<boolean>(false)
  const [Continuar, setContinuar] = useState<boolean>(false)
  const [Celular, setCelular] = useState<string>('(11) XXXX - 1443')
  const [CPF, setCPF] = useState<string>('')
  const [Erros, setErrors] = useState<number[]>([])

  function EnviarSenha(cpf: string): void {
    appApi.post('Auth/EsqueciSenhaCpf', { Email: Cpf ? cpf.replaceAll('.', '').replaceAll(',', '').replaceAll('-', '') : cpf, NovaSenha: '' }).then(res => {
      toast({
        title: 'Senha criada com sucesso.',
        description: 'Um SMS foi enviado com sua nova senha!\nEm alguns minutos você receberá em seu celular!',
        status: 'success',
        isClosable: false,
        position: 'bottom',
        duration: 9000
      })
      nav(-1)
    }).catch(_err => {
      toast({
        title: 'Não foi possível restaurar sua senha.',
        description: 'Verifique os dados e tente mais tarde',
        status: 'error',
        isClosable: false,
        position: 'bottom',
        duration: 4000
      })
    }
    )
  }

  function EsqueciSenha(form: any): void {
    const data: iLogin = form
    setErrors([])

    if (!Cpf && !ValidarEmail(data.email)) {
      setErrors(oldArray => [...oldArray, 0])
      return
    }
    if (Cpf) {
      if (!ValidaCPF(data.email)) {
        setCPF(data.email)
        toast({
          title: 'CPF inválido',
          status: 'error',
          isClosable: false,
          position: 'bottom',
          duration: 4000
        })
        return
      }

      // validar na api do sigah
      appApi.post('Auth/ConsultaUsuario', { cpf: Cpf ? data.email.replaceAll('.', '').replaceAll(',', '').replaceAll('-', '') : data.email }).then(res => {
        setCelular(res.data.celular)
        setEmailEnviado(res.data.email)
        setCPF(data.email)
        setContinuar(true)
      }).catch(_err => {
        toast({
          title: 'Não foi possível restaurar sua senha.',
          description: 'Verifique os dados e tente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'bottom',
          duration: 4000
        })
      }
      )
    }

    if (!Cpf) {
      SigahApi.post('api/Onboard/EsqueciSenha', data)
        .then(res => {
          toast({
            title: 'Email para redefinir a senha enviado com sucesso',
            status: 'success',
            isClosable: false,
            position: 'bottom',
            duration: 4000
          })
          nav(-1)
        })
        .catch(() => {
          toast({
            title: 'Email inválido',
            status: 'error',
            isClosable: false,
            position: 'bottom',
            duration: 4000
          })
        })
    }
  }

  return (
    <LayoutFull>
      <Container>
        <Flex flexDirection={'column'}>
          <Flex marginBottom={'2rem'}>
            <img onClick={() => nav(-1)} src={LogoSah} width={'370px'} />
          </Flex>

          <Title>
            {!Continuar && <>
              <h2>Olá,</h2>
              <h2>Recupere seu acesso!</h2>
            </>}
            {Continuar && EmailEnviado && <>
              <h2>Olá,</h2>
              <h3>Um email com as instruções para redefinir sua senha foi enviado.</h3>
            </>}
            {Continuar && !EmailEnviado &&
              <Flex direction={'column'}>

                <h3>Uma senha será enviada por SMS para o número abaixo:</h3>
                <Box my={'1rem'} color={'var(--Rosa)'} alignContent={'center'} textAlign={'center'}>

                  <h3>{Celular}</h3>
                </Box>
                <Box my={'.1rem'} color={'var(--a3)'} alignContent={'center'} textAlign={'center'}>
                  <h4>Caso este não seja seu número, contate seu RH para atualizar.</h4>
                </Box>
              </Flex>
            }
          </Title>
          {!Continuar && <FormControl mb='1rem' display='flex' alignItems='center' justifyContent={'end'}>
            <FormLabel mb={0} htmlFor='email-alerts' >
              Utilizar CPF?
            </FormLabel>
            <Switch isChecked={Cpf} onChange={(e) => setCpf(e.target.checked)} id='email-alerts' />
          </FormControl>}

          <Flex marginBottom={'2rem'} flexDirection={'column'}>
            {
              Erros.length > 0 && (
                <Alert marginBottom={'1rem'} borderRadius={'md'} status='error'>
                  <AlertDescription fontWeight={'semibold'}>Campos inválidos</AlertDescription>
                </Alert>
              )
            }

            {!Continuar &&
              <form onSubmit={handleSubmit(EsqueciSenha)}>
                <FormControl marginBottom={'1rem'} isInvalid={Erros.includes(0)} >
                  <Input
                    borderColor={'var(--Gray4)'}
                    type={Cpf ? 'text' : 'email'}
                    {...register('email')}
                    placeholder={Cpf ? 'CPF' : 'Email'}
                    id='lb-1'
                  />
                </FormControl>
                <Flex flexDirection={'column'}>
                  <Button
                    bg={'var(--DegradeAzul)'}
                    type='submit'
                    size={'lg'}
                    _hover={{ bg: 'var(--DegradeAzul)' }}
                    _active={{ bg: 'var(--DegradeAzul)' }}
                  >Enviar</Button>
                  <Button my={'1rem'}
                    bg={'var(--c7)'}
                    onClick={() => { nav(-1) }}
                    size={'lg'}
                    _hover={{ bg: 'var(--c4)' }}
                    _active={{ bg: 'var(--c4)' }}
                  >Voltar</Button>
                </Flex>
              </form>
            }
            {Continuar &&
              <Flex flexDirection={'column'} width={'22rem'} alignSelf={'center'}>
                {!EmailEnviado &&
                  <Button
                    bg={'var(--DegradeAzul)'}
                    onClick={() => { EnviarSenha(CPF) }}
                    size={'lg'}
                    _hover={{ bg: 'var(--DegradeAzul)' }}
                    _active={{ bg: 'var(--DegradeAzul)' }}
                  >Enviar senha por SMS</Button>
                }
                <Button my={'1rem'}
                  bg={'var(--c7)'}
                  onClick={() => { nav(-1) }}
                  size={'lg'}
                  _hover={{ bg: 'var(--c4)' }}
                  _active={{ bg: 'var(--c4)' }}
                >Voltar</Button>
              </Flex>
            }
          </Flex>
        </Flex>
      </Container>
    </LayoutFull>
  )
}
