import { FaCalendarDay, FaClipboard } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { GreenButton } from '../../../components/Buttons/GreenButton'
import { WhiteBox } from '../../../components/WhiteBox'
import { IconColumn } from './styles'
import { appApi } from '../../../services/appApi'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import AutoPP from '../../../assets/AutoPP.svg'
import IconeHierarquia from '../../../assets/IconeHierarquia.svg'
import SuperioresPP from '../../../assets/SuperioresPP.svg'
import LiderPP from '../../../assets/LiderPP.svg'
import ParesPP from '../../../assets/ParesPP.svg'
import GestorPP from '../../../assets/GestorPP.svg'
import OutrosPP from '../../../assets/OutrosPP.svg'
import { Flex } from '@chakra-ui/react'

interface iResumoPesquisaData {
  nomePesquisa: string
  dataInicio: string
  dataFim: string
  lideres: number
  pares: number
  liderados: number
  altaLideranca: number
  superiores: number
  outros: number
  autoavaliacoes: number
  avaliacoes: number
  avaliadoresDiferentes: number
}

type navProps = {
  pesquisaId: string
}

export const ResumoPesquisaPercepcao: React.FC = () => {
  const { pesquisaId } = useParams<navProps>()
  const { pathname } = useLocation()

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const [Pesquisa, setPesquisa] = useState<iResumoPesquisaData>({} as iResumoPesquisaData)

  function getInfoPesquisa(): void {
    appApi.get(`PesquisaPercepcao/${pesquisaId as string}/Resumo`)
      .then(response => setPesquisa(response.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getInfoPesquisa()
  }, [])

  return (
        <Body>
            <WhiteBox styles={{ marginTop: '2rem' }}>
                <h1 style={{ marginBottom: '1rem' }}>Resumo da pesquisa</h1>
                <Flex>
                    <IconColumn style={{ borderRight: '0.25rem solid var(--c4)', paddingRight: '2rem', marginLeft: '2rem' }}>
                        <div>
                            <FaClipboard color='var(--a3)' />
                            <span>{Pesquisa.nomePesquisa}</span>
                        </div>

                        <div>
                            <FaCalendarDay color='var(--secundario2)'/>
                            <span>Início da pesquisa:<strong> {Pesquisa.dataInicio}</strong></span>
                        </div>

                        <div>
                            <FaCalendarDay color='var(--secundario4)'/>
                            <span>Final da pesquisa:<strong> {Pesquisa.dataFim}</strong></span>
                        </div>

                        <div>
                            <FaClipboard />
                            <span><strong>{Pesquisa.avaliacoes}</strong> Avalia{Pesquisa.avaliacoes > 1 ? 'ções' : 'ção'}</span>
                        </div>
                        <div>
                            <img src={IconeHierarquia} style={{ paddingRight: '0.200rem' }}/>
                            <span><strong>{Pesquisa.avaliadoresDiferentes}</strong> Avaliador{Pesquisa.avaliadoresDiferentes > 1 ? 'es' : ''} diferente{Pesquisa.avaliadoresDiferentes > 1 ? 's' : ''}</span>
                        </div>
                    </IconColumn>

                    <IconColumn style={{ paddingLeft: '4rem' }}>
                        <div>
                    <img src={AutoPP} style={{ paddingRight: '0.800rem' }}/>
                            <span><strong>{Pesquisa.autoavaliacoes}</strong> Auto avalia{Pesquisa.autoavaliacoes > 1 ? 'ções' : 'ção'}</span>
                        </div>

                        <div>
                        <img src={GestorPP} style={{ paddingRight: '0.980rem' }} />
                            <span><strong>{Pesquisa.superiores}</strong> Líder{Pesquisa.superiores > 1 ? 'es' : ''}</span>
                        </div>

                        <div>
                        <img src={ParesPP} style={{ paddingRight: '0.800rem' }} />
                            <span><strong>{Pesquisa.pares}</strong> Par{Pesquisa.pares > 1 ? 'es' : ''}</span>
                        </div>

                        <div>
                            <img src={LiderPP} style={{ paddingRight: '0.800rem' }} />
                            <span><strong>{Pesquisa.liderados}</strong> Liderado{Pesquisa.liderados ? 's' : ''}</span>
                        </div>

                        <div>
                            <img src={SuperioresPP} style={{ paddingRight: '0.800rem' }}/>
                            <span><strong>{Pesquisa.altaLideranca}</strong> Alta liderança</span>
                        </div>

                        <div>
                            <img src={OutrosPP} style={{ paddingRight: '0.800rem' }} />
                            <span><strong>{Pesquisa.outros}</strong> Outro{Pesquisa.outros > 1 ? 's' : ''}</span>
                        </div>
                    </IconColumn>
                </Flex>
                <Flex justifyContent={'end'} marginTop={'1rem'} gap={'1rem'}>
                    <GreenButton
                        Size='lg'
                        isNav={true}
                        Caminho={`/Rh/${pathname.split('/')[2]}/Gestao`}
                    >Gestão de pesquisas</GreenButton>
                </Flex>
            </WhiteBox>
        </Body>
  )
}
