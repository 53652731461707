import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
:root{
 --DegradeAzul: linear-gradient(90deg, #1961E0 0%, #66046B 100%);
 --DegradeFundo:linear-gradient(to right,rgba(233,233,233,100), rgba(248,248,248,100));
 --AzulNavBar:linear-gradient(269.74deg, #EAF1FF 6.46%, #C6D4F0 22.67%, #849FD0 37.39%, #5A7DBC 71.27%, #3863B0 100.73%);
 --a1:#4578D6;
 --a2:#3863B0;
 --a3:#2C4D8A;
 --a4:#203864;
 --a5:#14223D;
 --v1:#6FEF63;
 --v2:#89DB81;
 --v3:#72B66B;
 --v4:#598F54;
 --v5:#41693D;
 --v125:#6FEF6350;
 --vm1:#89FFD8;
 --vm2:#7BE5C2;
 --vm3:#67C0A2;
 --vm4:#529981;
 --vm5:#3E7361;
 --vg1:#A6FEFF;
 --vg2:#9FF3F5;
 --vg3:#87CECF;
 --vg4:#6DA7A8;
 --vg5:#558182;
 --c1:#FFFFFF;
 --c2:#F0F0F0;
 --c3:#E3E3E3;
 --c4:#D6D6D6;
 --c5:#C9C9C9;
 --c6:#BBBBBB;
 --gray200:#E2E8F0;
 --c7:#606060;
 --secundario1:#C7EDEE;
 --secundario2:#A6D2FF;
 --secundario3:#CCFF66;
 --secundario4:#FFCC00;
 --secundario5:#FF9900;
 --terc1:#E18787;
 --terc2:#B265FF;
 --terc3:#FF5050;
 --terc4:#00B45A;
 --terc5:#F2D751;
 --terc6:#02EA8D;
 --terc7:#00B496;
 --terc8:#66FFFF;
 --terc9:#9BB3FF;
 --terc10:#B265FF;
 --AzulClaro2:#6DA0FF;
 --Vermelho2:#B23838;
 --Mostarda:#E5C12E;
 --MostardaOpaco:#E5C12E60;
 --Iris100:#5D5FEF;
 --Iris80:#7879F1;
 --Iris60:#A5A6F6;
 
 --Roxo:#66046B;
 --RoxoClaro:#9B51E0;
 --RoxoOpaco25:#66046B32;
 --RoxoOpaco15:#66046B15;
 --Azul:#1961E0;
 --Alaranjado: #D9B62B;
 --Alaranjado25: #D9B62B25;
 --Orange:#F2994A;
 --OrangeOpaco:#F2994A90;
 --Green1:#219653;
 --Green2:#27AE60;
 --Green2Opaco:#27AE6050;
 --Green3:#6FCF97;
 --Green3Opaco:#6FCF9765;
 --Preto:#000000;
 --AzulOpaco2:#1961E092;
 --AzulOpaco:#3984C470;
 --Musgo:#69B89C;
 --Rosa:#CC42A2;
 --Gray1:#333333;
 --Gray2:#4F4F4F;
 --Gray3:#828282;
 --Gray4:#BDBDBD;
 --Gray5:#E0E0E0;
 --Gray6:#F2F2F2;
 --Blue1:#2F80ED;
 --Blue2:#2D9CDB;
 --Blue3:#56CCF2;
 --Red:#EB5757;
 --Purple1:#9B51E0;
 --Yellow5:#CCFF66;
 --Yellow:#F2C94C;
 --VerdeAgua: #85CBCC;

--smFontBtn:.75rem;
--mdFontBtn:1rem;
--lgFontBtn:1.25rem;

--smIconSize:3.125rem;
--mdIconSize:3.844rem;
--lgIconSize:4.25rem;

--smPaddingBtn:0rem 1.5rem 0rem 1.5rem;
--mdPaddingBtn:0rem 2rem 0rem 2rem;
--lgPaddingBtn:0rem 2rem 0rem 2rem;

--smHeigthBtn:2.375rem;
--mdHeigthBtn:2.688rem;
--lgHeigthBtn:2.938rem;

--smLabel:1rem;
--mdLabel:1.25rem;
--lgLabel:1.5rem;

 --RadiusModal:0.625rem;
 --transitionBtns:0.2s;
 --SombraBtns:0rem 0.125rem 0.5rem rgba(0, 0, 0, 0.25);
 --SombraBackground:0rem 0.125rem 1.25rem rgba(0, 0, 0, 0.2);
 --SombraMenuDropdown:-1px 3px 6px rgba(0, 0, 0, 0.25);
}

*{
  margin: 0;
  padding: 0;
  box-sizing:border-box;
}

html{
  @media(max-width: 1080px){
    font-size:93.75%;
  }

  @media(max-width: 720px){
    font-size:87.5%;
  }
}

.IconLeft{
  margin-right:.75rem;
}

.IconRigth{
  margin-left:.75rem;
}

.react-modal-overlay{
  background-color: rgba(0, 0, 0, 0.5);

  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-y: auto;

  width: 100vw;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 0rem;
  }
}

.react-modal-content-poupUp{
  width:37rem;
  height: 256px;
  background: white;
  padding: 1rem;
  position: relative;
  border-radius:1rem;
  box-shadow: var(--SombraBackground);
  outline: 0;

  svg:nth-child(1){
    cursor: pointer;
    color: var(--a2);
  }

  div:nth-child(1){
   justify-content: end;
  }

  div:nth-child(2){
    justify-content: space-between;
    height: 90%;
    align-items: center;
  }

  a:first-child{
    margin-right: 1.5rem;
  }

  span{ 
    font-weight: 700;
    font-size: 20px;
    color: #2C4D8A;
  }

}

.react-modal-content-default{
  background: white;
  position: absolute;
  border-radius:1rem;
  box-shadow: var(--SombraBackground);
  outline: 0;
}

.react-modal-content-scroll{
  background: white;

  overflow-y: auto;
  position: relative;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);

  ::-webkit-scrollbar {
    width: 0rem;
  }

  border-radius:1rem;
  box-shadow: var(--SombraBackground);
  outline: 0;
}

a{
  text-decoration: inherit;
  color: inherit;
}

h1{
font-family: 'Poppins','sans-serif';
line-height: 3rem;
font-weight: 400;
font-size: 2.5rem;
}

h2{
  font-family: 'poppins', sans-serif;
  font-weight:600;
  font-size: 2rem;
  line-height: 2.5rem;
}

h3{
  font-size:1.5rem;
  line-height: 1.75rem;
  font-weight: bold;
}

//lineheigth sempre meio rem a mais que o font size

body{
    background: var(--DegradeFundo)
}

label,input[type=text],a,button,h3,h4,span,textarea,p{
  font-family: 'Roboto','sans-serif';
}


span{
  line-height: 115%;
}

button,a{
  cursor: pointer;

}

button {
border:none;
color: var(--c1);
}

[disabled]{
  opacity: 0.6;
  cursor:not-allowed ;
}

.disabled{
  opacity: 0.6;
  pointer-events: none;
}

.p2{
  font-weight: 600;
  color: var(--a3);
  font-size: 1rem;
}
`
