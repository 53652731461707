import { Avatar, Flex, Tooltip } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
import { iCardAvaliador } from '../../../../../interfaces'
import { Container, EndIcon, ColumnText } from './styles'
import { RiSendPlaneFill } from 'react-icons/ri'

interface iCardProps {
  AvaliacaoId: string
  enviado: boolean
  tipo: number
  Avaliador: iCardAvaliador
  onDelete: (avaliacaoId: string, avaliadorId: string) => void
  onReenviar: (avaliacaoId: string, avaliadorId?: string) => void
}

export const CardAssociado: React.FC<iCardProps> = ({ Avaliador, AvaliacaoId, tipo, enviado, onDelete, onReenviar }) => {
  function ObterTipo(type: number): string {
    switch (type) {
      case 1:
        return 'Líder'

      case 2:
        return 'Par'

      case 3:
        return 'Liderados'

      case 4:
        return 'Outros'

      case 6:
        return 'Alta liderança'

      default:
        return 'null'
    }
  }
  return (
    <Container>
      <Flex alignItems={'center'} margin={'9px 0px 9px 13px'}>
        <Avatar name={Avaliador.nome} src={Avaliador.avatar} />
        <ColumnText>
          {
            Avaliador.nome?.length > 17
              ? <span title={Avaliador.nome}>{Avaliador.nome.substring(0, 18)}...</span>
              : <span>{Avaliador.nome}</span>
          }
          <strong>{ObterTipo(tipo)}</strong>
          <span>{ }</span>
        </ColumnText>
      </Flex>
      <EndIcon>
        <MdDelete onClick={() => onDelete(AvaliacaoId, Avaliador.id)} />
        {enviado &&
          <Tooltip hasArrow label={'Reenviar convite'} bg={'var(--Green3)'} color={'var(--c1)'} placement={'top'} padding={'.9rem'}>
            <span><RiSendPlaneFill style={{ color: 'var(--Green3)' }} onClick={() => onReenviar(AvaliacaoId, Avaliador.id)} /></span>
          </Tooltip>
        }
    </EndIcon>
    </Container >
  )
}
