import { Flex, Text } from '@chakra-ui/react'
import { FaClock, FaUser } from 'react-icons/fa'
import { useState, useEffect } from 'react'
import { appApi } from '../../../../../../../services/appApi'
import { useParams } from 'react-router-dom'
import { iEtapaColaborador } from '../../../../../../../interfaces'
import { PropInvalida } from '../../../../../../../Utils/Helper'

interface iModel {
  tipoResponsavel: number
  tempoInserir: number
}

interface iProps {
  etapa: iEtapaColaborador
  isOpen: boolean
}
export const CardPDI: React.FC<iProps> = ({ etapa, isOpen }) => {
  const { colaboradorId } = useParams<{colaboradorId: string}>()
  const [Model, setModel] = useState<iModel>()

  const ObterResponsavel = (): string => {
    switch (Model?.tipoResponsavel) {
      case 0:
        return 'Gestor'
      case 1:
        return 'Colaborador'
      case 2:
        return 'Rh'
      default: return ''
    }
  }

  const obterPDI = (): void => {
    appApi.get(`PDIEtapaColaborador/${colaboradorId ?? ''}/Etapa/${etapa.id}/Detalhe`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Model)) {
      obterPDI()
    }
  }, [isOpen])

  return (
        <Flex flexDir={'column'} gap={'1rem'}>
            <Flex gap={'.5rem'} alignItems='center'>
                <FaUser color='var(--Gray2)' size={24} />
                <Flex flexDir={'column'}>
                    <Text color={'var(--Gray2)'} fontWeight={'700'} fontSize='1.125rem'>Responsável por inserir PDI</Text>
                    <Text fontWeight={'700'} color={'var(--terc3)'}>{ObterResponsavel()}</Text>
                </Flex>
            </Flex>

            <Flex gap={'.5rem'} alignItems='center'>
                <FaClock color='var(--Gray2)' size={24} />
                <Flex flexDir={'column'}>
                    <Text color={'var(--Gray2)'} fontWeight={'700'} fontSize='1.125rem'>Tempo para inserir</Text>
                    <Text fontWeight={'700'} color={'var(--terc3)'}>{Model?.tempoInserir} dias</Text>
                </Flex>
            </Flex>
        </Flex>
  )
}
