import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Flex } from '@chakra-ui/layout'
import { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { MdOutlineStopCircle } from 'react-icons/md'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'
import { MultiColaborador } from '../../../../../components/MultiColaborador'
import { iNomeId } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'

interface iProps {
  participantes?: string[]
  onClose: () => void
  onCreate: (participantes: string[]) => void
  onUpdate: (participantes: string[]) => void
  onEncerrar: () => void
}

export const ModalComite: React.FC<iProps> = ({ participantes, onUpdate, onEncerrar, onClose, onCreate }) => {
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Selecionados, setSelecionados] = useState<string[]>([])

  function getColaboradores(): void {
    appApi.get('Colaborador')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getColaboradores()
    if (participantes) {
      setSelecionados(participantes)
    }
  }, [])

  return (
    <ModalBase Titulo='Comitê de calibração' styleHeader={{ color: 'var(--Azul)' }} Width='62.5rem' isOpen onClose={onClose}>
      <Flex padding={'1rem'} borderTop='1px solid var(--c4)'>
        <FormControl>
          <FormLabel color={'var(--Gray1)'} fontSize='1.25rem'>Participante do comitê</FormLabel>
          <MultiColaborador
            placeholder='Digite o nome ou e-mail do usuário'
            colaboradores={Colaboradores}
            selecionados={Selecionados}
            onChoice={(e) => !PropInvalida(e) && setSelecionados([...Selecionados, e])}
            onDelete={(e) => setSelecionados([...Selecionados.filter(r => r !== e)])}
          />
        </FormControl>
      </Flex>
      <Flex padding={'0 1rem 1rem 1rem'} gap='1rem' justifyContent={'end'}>
        <Button VarColor='c6' onClick={onClose}>Cancelar</Button>
        {PropInvalida(participantes) && <Button
          leftIcon={<FaSave />}
          VarColor='Green2'
          onClick={() => onCreate(Selecionados)}
        >Salvar e iniciar comitê</Button>}
        {participantes &&
          <>
            <Button
              leftIcon={<FaSave />}
              VarColor='Green2'
              onClick={() => onUpdate(Selecionados)}
            >Salvar alteração</Button>

            <Button
              leftIcon={<MdOutlineStopCircle />}
              VarColor='Red'
              onClick={onEncerrar}
            >Encerrar comitê</Button>
          </>
        }
      </Flex>
    </ModalBase>
  )
}
