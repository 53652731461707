import { Flex, Text, Tooltip } from '@chakra-ui/react'
import { FaCopy, FaEdit, FaTrash, FaUsers } from 'react-icons/fa'
import { MdChecklist, MdMoreVert } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { iGrupoTrilha } from '../../../../../interfaces'
import { parseJwt } from '../../../../../services/token'
import { Background, Descricao, DropMenu, Menu } from './styles'

interface ICard {
  trilha: iGrupoTrilha
  onDelete: (id: string) => void
  onEdit: (e: iGrupoTrilha) => void
  onCopy: (id: string) => void
}

export const Card: React.FC<ICard> = ({ trilha, onDelete, onEdit, onCopy }) => {
  const nav = useNavigate()
  return (
    <Tooltip isDisabled={(trilha.padrao !== null && !trilha.padrao)}
      width='18rem'
      placement='top'
      hasArrow
      label='Essa é uma trilha padrão para visualização, porém para customiza-la  você deve duplica-la.' bg='var(--Roxo)'>

      <Background theme={trilha.cor}>
        <Flex justifyContent={'end'}>
          <Menu>
            <MdMoreVert size={24} color='white' />
            <Flex marginTop={'.1rem'} width={'100%'} justifyContent='end'>
              <DropMenu>
                <li data-menu={'Duplicar'} onClick={() => onCopy(trilha.id)}><FaCopy /> Duplicar</li>
                {
                  ((trilha.padrao !== null && !trilha.padrao) || parseJwt().uid === '252110c3-295e-4c81-987c-b260b528bdf0') && (
                    <Flex flexDir={'column'}>
                      <li data-menu={'Editar'} onClick={() => onEdit(trilha)}><FaEdit />Editar</li>
                      <li data-menu={'Excluir'} onClick={() => onDelete(trilha.id)}><FaTrash /> Excluir</li>
                    </Flex>
                  )
                }
              </DropMenu>
            </Flex>
          </Menu>
        </Flex>
        <Flex onClick={() => nav(`/Jornada/Configuracao/Trilha/${trilha.id}`)} flexDir={'column'} justifyContent='center' alignItems={'center'} gap='1rem' height='80%'>
          <Text fontSize={'1rem'} fontWeight={'bold'}>{trilha.nome}</Text>
          <Flex flexDir={'column'}>
            <Descricao><MdChecklist fontSize={24}/><strong>{trilha.countEtapas}</strong> Etapas</Descricao>
            <Descricao><FaUsers fontSize={24}/><strong>{trilha.countProcessos}</strong> Porcessos ativos</Descricao>
          </Flex>
        </Flex>
      </Background>
    </Tooltip>
  )
}
