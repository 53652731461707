import PesquisaImg from '../../../../../../../assets/Pesquisa.svg'
import { Container } from './styles'
import { FaChartPie, FaCog, FaEnvelope, FaEye } from 'react-icons/fa'
import React, { useEffect, useState } from 'react'
import { appApi } from '../../../../../../../services/appApi'
import { Flex, useToast } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../../../../components/Button'
import { iEtapaColaborador } from '../../../../../../../interfaces'
import { PropInvalida } from '../../../../../../../Utils/Helper'

interface iCardPesquisa {
  etapa: iEtapaColaborador
  isOpen: boolean
}

interface iPesquisaInternaStatus {
  respondente: string
  totalQuestoes: number
  formularioAprovado: boolean
  liberarResultado: boolean
  respondenteId: string
  linkResposta?: string
}

export const CardPesquisa: React.FC<iCardPesquisa> = ({ etapa, isOpen }) => {
  const toast = useToast()
  const [Pesquisa, setPesquisa] = useState<iPesquisaInternaStatus>()
  const { pathname } = useLocation()
  const nav = useNavigate()

  function ReenviarConvite(): void {
    appApi.post(`PesquisaInternaConvite/${etapa.interacoes[0].idExterno}/Enviar`, [etapa.conviteId])
      .then(() => {
        toast({
          title: 'Convite reenviado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  function ObterPesquisaPorId(): void {
    appApi.get(`PesquisaInterna/${etapa.interacoes[0].idExterno}/Status?etapaId=${etapa.id}`)
      .then(response => { setPesquisa(response.data) }).catch(err => console.error(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Pesquisa)) {
      ObterPesquisaPorId()
    }
  }, [isOpen])

  return (
    <Flex flexDir={'column'} gap={'1rem'}>
      <Container>
        <Flex onClick={() => nav(`/Jornada/PesquisaInterna/Gestao/${etapa.interacoes[0].idExterno}/Previsualizar`)}>
          <img src={PesquisaImg} />
          <div>
            <FaEye color='var(--terc10)' size={45}/>
          </div>
        </Flex>
        <div>
          <span>Respondente: <strong>{Pesquisa?.respondente}</strong></span>
          <span>Total de questões:<strong>{Pesquisa?.totalQuestoes}</strong></span>
        </div>

      </Container>
      <Flex justifyContent={'center'} flexDir='column' gap={'1rem'}>
        <Button
          VarColor='Azul'
          leftIcon={<FaCog />}
          onClick={() => nav(`/Jornada/Processo/${pathname.substring(21)}/PesquisaInterna/Configuracao/${etapa.interacoes[0].idExterno}`)}
        >Configurações de envio</Button>

        {
          (Pesquisa?.linkResposta) && (
            <Button
              VarColor='Azul'
              onClick={() => window.open(Pesquisa.linkResposta, '_blank')}
            >Responder pesquisa</Button>
          )
        }

        <Button
          VarColor='Azul'
          isDisabled={!Pesquisa?.liberarResultado}
          leftIcon={<FaChartPie />}
          onClick={() => nav(`/Jornada/PesquisaInterna/Resultado/${etapa.interacoes[0].idExterno}?colaboradorId=${Pesquisa?.respondenteId ?? ''}`)}
        >Resultado</Button>

        <Button
          VarColor='Azul'
          isDisabled={PropInvalida(etapa.conviteId) || (etapa.status !== 0 && etapa.status !== 6 && etapa.status !== 7) }
          leftIcon={<FaEnvelope />}
          onClick={ReenviarConvite}
        >Reenviar convite</Button>
      </Flex>
    </Flex>
  )
}
