import { Checkbox, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp, FaPaperPlane } from 'react-icons/fa'
import { Button } from '../../../../../../components/Button'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'
import { iConvite } from '../../../../../../interfaces'
import { CircleText } from '../CircleText'
import { TableDiv } from './styles'

interface iTabelaprops {
  rows: iConvite[]
  anonima?: boolean
  selecionados?: string[]
  onCheck: (id: string, all?: boolean) => void
  onSend: (id: string) => void
  isEdit?: boolean
}

export const TableConvites: React.FC<iTabelaprops> = ({ rows, isEdit, selecionados, anonima, onCheck, onSend }) => {
  const [Rows, setRows] = useState<iConvite[]>([])

  useEffect(() => {
    setRows(rows)
  }, [rows])

  function SelecionarTodos(check: boolean): void {
    onCheck('', check)
  }

  const [Ordenacao, setOrdenacao] = useState(false)
  const [UltimoFiltro, setUltimoFiltro] = useState<number>()

  function OrdenarTabela(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
  }

  useEffect(() => {
    const copy = [...rows]
    if (UltimoFiltro === 1) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.nome && b.nome) {
          return b.nome.localeCompare(a.nome)
        } else if (Ordenacao && a.nome && b.nome) {
          return a.nome.localeCompare(b.nome)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.email && b.email) {
          return b.email.localeCompare(a.email)
        } else if (Ordenacao && a.email && b.email) {
          return a.email.localeCompare(b.email)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 3) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.area && b.area) {
          return b.area.localeCompare(a.area)
        } else if (Ordenacao && a.area && b.area) {
          return a.area.localeCompare(b.area)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 4) {
      copy.sort((a, b) => {
        if (!Ordenacao && a.status && b.status) {
          return b.status.localeCompare(a.status)
        } else if (Ordenacao && a.status && b.status) {
          return a.status.localeCompare(b.status)
        } else {
          return 0
        }
      })
    }
    setRows(copy)
  }, [Ordenacao, UltimoFiltro, rows])

  function ObterCor(e: iConvite): string {
    if (!anonima) {
      return e.status === 'Não enviado' ? 'c5' : e.status === 'Enviado' ? 'Rosa' : e.status === 'Respondido' ? 'v2' : ''
    } else {
      return e.status === 'Respondido' || e.status === 'Enviado' ? 'Rosa' : 'c5'
    }
  }

  return (
    <TableDiv>
      <table>
        <thead>
          <tr>
            {isEdit && <th>
              <Flex gap={'0.50rem'}>
                <span>Selecionar todos </span>
                <Checkbox marginLeft={'1rem'} borderColor={'var(--Gray4)'} onChange={(e) => SelecionarTodos(e.target.checked)}/>
              </Flex></th>}
            <th onClick={() => OrdenarTabela(1)}>
              <div>Nome {UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp/> : <FaChevronDown/>}</div>
            </th>
            <th onClick={() => OrdenarTabela(2)}>
              <div>Email {UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp/> : <FaChevronDown/>}</div>
            </th>
            <th onClick={() => OrdenarTabela(3)}>
              <div>Área {UltimoFiltro === 3 && !Ordenacao ? <FaChevronUp/> : <FaChevronDown/>}</div>
            </th>
            {!isEdit &&
              <th onClick={() => OrdenarTabela(4)}>
                <div>Status {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp/> : <FaChevronDown/>}</div>
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {
            Rows.map((e, i) => {
              return (
                <tr key={i}>
                  {isEdit &&
                    (<td>
                      {e.etapaVinculada
                        ? (
                          <ElementoTooltip label='Não é possível selecionar convite de etapa' bg={'red'}>
                            <Checkbox isDisabled={e.etapaVinculada} isChecked={selecionados?.find(r => r === e.id) !== undefined} onChange={() => onCheck(e.id)} borderColor={'var(--a4)'} />
                          </ElementoTooltip>
                          )
                        : (
                          <Checkbox isDisabled={e.etapaVinculada} isChecked={selecionados?.find(r => r === e.id) !== undefined} onChange={() => onCheck(e.id)} borderColor={'var(--a4)'} />
                          )}
                    </td>
                    )
                  }
                  <td>{e.nome}</td>
                  <td>{e.email}</td>
                  <td>{e.area}</td>
                  {!isEdit && <td>
                    <Flex justifyContent={'center'} gap={'1rem 1rem'} flexWrap={'wrap'}>
                      <CircleText fontsize='14px'
                        varColorCircle={ObterCor(e)}
                        varColorText=''
                      >
                        {!anonima ? e.status : e.status === 'Respondido' || e.status === 'Enviado' ? 'Enviado' : 'Não enviado'}
                      </CircleText>
                      {
                        (e.status === 'Não enviado' && !anonima) && (
                          <Button
                            onClick={() => onSend(e.id)}
                            leftIcon={<FaPaperPlane />}
                            color={'var(--a5)'}
                            VarColor='Yellow5'
                            isDisabled={!e.liberado}
                          >Enviar</Button>
                        )
                      }

                      {
                        (e.status === 'Enviado' && !anonima) && (
                          <Button
                            onClick={() => onSend(e.id)}
                            leftIcon={<FaPaperPlane />}
                            color={'var(--a5)'}
                            VarColor='secundario2'
                            isDisabled={!e.liberado}
                          >Reenviar</Button>
                        )
                      }
                    </Flex>
                  </td>}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </TableDiv>
  )
}
