import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { MetaButton } from '../../../../../../components/Buttons/MetaButton'
import { InputFormControl } from '../../../../../../components/InputFormControl'
import { IJobTitle } from '../../../../../../interfaces'
import { ModalBody, ModalContent, ModalFooter } from './styles'

Modal.setAppElement('#root')

interface IUpdateJobTitleModalProps {
  isOpen: boolean
  initialData: IJobTitle
  onRequestClose: () => void
  onUpdateJobTittle: (updatedJobTitle: IJobTitle) => void
}

export const UpdateJobTitleModal: React.FC<IUpdateJobTitleModalProps> = ({
  isOpen,
  initialData,
  onRequestClose,
  onUpdateJobTittle
}) => {
  const [Nome, setNome] = useState('')

  useEffect(() => {
    setNome(initialData.nome)
  }, [initialData.nome])

  function ClearFields(): void {
    setNome('')
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => { ClearFields(); onRequestClose() }}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-default"
    >
      <ModalContent>
        <h1>Editar função</h1>
        <ModalBody>
          <InputFormControl
            label='Nome da função'
            onChange={(e) => setNome(e.target.value)}
            placeholder='Digite um nome'
            value={Nome}
          />
        </ModalBody>
        <ModalFooter>
          <MetaButton bg='c4' size='md' onClick={() => { ClearFields(); onRequestClose() }}>Fechar</MetaButton>
          <MetaButton bg='v2' size='md' onClick={() => {
            onUpdateJobTittle({
              id: initialData.id,
              nome: Nome
            }); ClearFields()
          }} >Salvar</MetaButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
