/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState } from 'react'
import { Body } from '../../../Layouts/Body'
import { CountBar } from '../Components/CountBar'
import { IDepartment, iPerfilColaborador, iPostColaborador, ISquad, iStatusOrganograma } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { NavBar } from '../Components/NavBar'
import { DepartmentsOrgChart } from './Components/DepartmentsOrgChart'
import { Main, ScrollContainer, StyledButton } from './styles'
import { Flex, Image, Select } from '@chakra-ui/react'
import { SquadOrgChart } from './Components/SquadOrgChart'
import { parseJwt } from '../../../../services/token'
import { useAuth } from '../../../../contexts/AuthContext'
import { ZoomContainer } from '../../../../components/ZoomContainer'
import { CreateColaboradorModal } from '../../../../components/ModalCreateColaborador'

interface INavLink {
  to: string
  linkTitle: string
  exact?: boolean
}
export const DashboardDesenvolvimento: React.FC = () => {
  const { permitirFuncao } = useAuth()
  function ObterRotas(): INavLink[] {
    const rotas: INavLink[] = []
    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'visao-geral')) {
      rotas.push({
        to: '/Organograma/Dashboard',
        linkTitle: 'Visão geral'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'areas')) {
      rotas.push({
        to: '/Organograma/Departamentos',
        linkTitle: 'Áreas'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'funcoes')) {
      rotas.push({
        to: '/Organograma/Cargos',
        linkTitle: 'Funções'
      })
    }

    if (parseJwt().adm === 'True' || permitirFuncao('Organograma', 'colaboradores')) {
      rotas.push({
        to: '/Organograma/Colaboradores',
        linkTitle: 'Colaboradores'
      })
    }
    return rotas
  }
  const [Status, setStatus] = useState<iStatusOrganograma>()
  const [departmentList, setDepartmentList] = useState<IDepartment[]>([])
  const [Organograma, setOrganograma] = useState<IDepartment[]>([])
  const [Colaborador, setColaborador] = useState<iPerfilColaborador>()
  const [Squads, setSquads] = useState<ISquad[]>([])
  const [View, setView] = useState(0)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalDemografiaId, setDemografiaId] = useState<string>()

  function getStatus(): void {
    appApi.get('Organograma/ObterStatus')
      .then(res => setStatus(res.data))
      .catch(err => console.log(err))
  }

  function getOrganograma(): void {
    appApi.get('Departamento/Populado')
      .then(res => setDepartmentList(res.data))
      .catch(err => console.log(err))
    appApi.get('Organograma/VisualizarOrganograma')
      .then(res => setOrganograma(res.data))
      .catch(err => console.log(err))
  }

  function getSquads(): void {
    appApi.get('Organograma/VisualizarSquads')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }
  function getColaborador(): void {
    appApi.get('Colaborador/Perfil')
      .then(res => setColaborador(res.data))
      .catch(err => console.log(err))
  }

  function onOpenModalCreate(id: string): void {
    setModalCreateIsOpen(true)
    setDemografiaId(id)
  }

  function onCreateColaborador(form: iPostColaborador): void {
    appApi.post('Colaborador', form).then(res => {
      setModalCreateIsOpen(false)
      if (Colaborador?.id) {
        getOrganograma()
      }
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getStatus()
    getSquads()
    getColaborador()
  }, [])

  useEffect(() => {
    if (Colaborador?.id) {
      getOrganograma()
    }
  }, [Colaborador])

  const [currentArea, setCurrentArea] = useState<IDepartment>({
    id: '',
    nome: '',
    departamentoFilhos: []
  })

  const [valueSelect, setValueSelect] = useState<string>('todos')
  function guardaCurrent(e: any): void {
    departmentList.forEach((deps) => {
      if (deps.id === e) {
        setCurrentArea(deps)
      }
    })
  }
  // eslint-disable-next-line prefer-const
  let listaTodosDepartamentos: IDepartment[] = []
  function listaTodosDeps(): void {
    departmentList.forEach((deps) => {
      for (let i = 0; i < Organograma.length; i++) {
        if (deps.id === Organograma[i].id) {
          listaTodosDepartamentos.push(deps)
        }
      }
    })
  }
  listaTodosDeps()
  useEffect(() => {
    listaTodosDeps()
    if (departmentList.length === 1) {
      guardaCurrent(departmentList[0].id)
      setValueSelect(departmentList[0].id)
    }
  }, [departmentList])
  useEffect(() => {
    guardaCurrent(valueSelect)
  }, [valueSelect])

  return (
    <Body>
      <Main>
        {
          (ModalCreateIsOpen) && (
            <CreateColaboradorModal
              departamentoId={ModalDemografiaId}
              isOpen={ModalCreateIsOpen}
              onCreateEmployee={onCreateColaborador}
              onRequestClose={() => setModalCreateIsOpen(false)}
            />
          )
        }
        <NavBar
          title="Organograma"
          navLinks={ObterRotas()}
        />

        <CountBar
          countItems={[
            {
              value: Status?.colaboradores ?? 0,
              title: 'Colaboradores'
            },
            {
              value: Status?.cargos ?? 0,
              title: 'Funções'
            },
            {
              value: Status?.departamentos ?? 0,
              title: 'Áreas'
            }
          ]}
        />

        <main>
          <Flex marginBottom={'1rem'} alignItems={'end'} justifyContent={'space-between'} flexDir={'row'} marginLeft={'2rem'} marginRight={'2rem'}>
            <Flex>
              {departmentList.length > 1 && View === 0 && (<Select bg={'white'} borderColor={'#828282'} onChange={(e) => { setValueSelect(e.target.value) }}>
                { departmentList.length !== 1 && <option value='todos'>Todos</option> }
                { departmentList.map((e) => {
                  return (
                    <option value={e.id}>{e.nome}</option>
                  )
                })}
              </Select>)}
            </Flex>
            <Flex flexDir={'column'}>
              <strong onClick={() => { console.log(currentArea) }}>Tipo de visualização</strong>
              <Flex marginTop={'.5rem'} gap={'1rem'}>
                <StyledButton onClick={() => setView(0)} selected={View === 0}>Matricial</StyledButton>
                <StyledButton onClick={() => setView(1)} selected={View === 1}>Squad</StyledButton>
              </Flex>
            </Flex>
          </Flex>
          {(!!departmentList.length && View === 0) && (
            <ScrollContainer>
              <ZoomContainer>
                <DepartmentsOrgChart onOpenModalAddEmploye={onOpenModalCreate} departmentTrees={listaTodosDepartamentos} valueSelect={valueSelect} currentArea={currentArea}/>
              </ZoomContainer>
            </ScrollContainer>
          )}

          {(!!Squads.length && View === 1) && (
            <SquadOrgChart reload={getSquads} squads={Squads} />
          )}

          {
            (Squads.length === 0 && View === 1) && (
              <Flex alignItems={'center'} color={'var(--Azul)'} justifyContent={'center'}>
                <Image className='sah_Img' height={'20rem'} src='https://sigah.blob.core.windows.net/onboard/bbb6990f-d313-488a-af99-5383c9bd1c82.png' />
                <h3>Ainda não há nenhum colaborador atribuído a um Squad...</h3>
              </Flex>
            )
          }

        </main>
      </Main>
    </Body>
  )
}
