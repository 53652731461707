import styled from 'styled-components'

export const ModalHeader = styled.div`
display: flex;
margin-bottom:1rem;
justify-content: space-between;

h2{
    color: var(--a3);
}
`

export const ModalBody = styled.div`
display: flex;
flex-direction: column;
padding:1rem 2rem 0rem 2rem;

section{
    display: flex;
    flex-wrap: wrap;
    border: 0.063rem solid var(--c7);
    border-style: dashed;
    padding: 1rem .5rem .5rem 1rem;
    gap:1rem;

    img{
        height: 3.75rem;
        width: 3.75rem;
        border-radius: 1.875rem;
        border: 0.125rem solid var(--c5);
        margin-right:.5rem;
        margin-bottom: .5rem;
        &:hover{
            transition: all .2s ease;
            border: 0.25rem solid var(--v1);
            cursor: pointer; 
        }
    }
}

overflow: auto;
max-height:60vh;
::-webkit-scrollbar {
  width: .5rem;
}
/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`
