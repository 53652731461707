/* eslint-disable no-return-assign */
import { Body } from '../../Layouts/Body'
import { GreenButton } from '../../../components/Buttons/GreenButton'
import { GreyButton } from '../../../components/Buttons/GreyButton'
import { WhiteBox } from '../../../components/WhiteBox'
import { Main } from './styles'
import { useState, useEffect } from 'react'
import { appApi } from '../../../services/appApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Checkbox, Divider, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, useToast } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { EditorTexto } from '../../../components/EditorTexto/Index'
import { PropInvalida, SubstringRoute } from '../../../Utils/Helper'
import { ButtonTag } from './Components/ButtonTag'
import { ModalTag } from './Components/ModalTag'
import { FindUser } from '../../../components/FindBox'
import { iNomeId } from '../../../../src/interfaces'
import { BoxMultiText } from './Components/BoxMultiText'
import { FaSave } from 'react-icons/fa'

type iParamProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
  editar: string
}

interface iModel {
  dataInicio?: string
  dataFim?: string
  encerrarAutomaticamente: boolean
  tipo: number
  ciclo?: number
  validade?: number
  baseCiclo?: number
  reenviarDias?: number[]
  pdi?: iPDIPesquisa
  textosAbertura: iTextosPesquisa[]
  textosConvite: iTextosPesquisa[]
  textosEncerramento: iTextosPesquisa[]
}

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

interface iPDIPesquisa {
  dias: number
  destinatarioEmail: number
  notificacaoCadastro: number[]
  responsavelId?: string
  notificadoId?: string
  textoCriacao: string
  pdiGerado?: boolean
}

export const EnviarPesquisa: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const path = useLocation().pathname
  const { pesquisaId } = useParams<iParamProps>()

  const textPadraoEncerramento = '<strong>Obrigado por participar!</strong>'
  const textPadraoAbertura = `<p>Bem-vindo a pesquisa de ${path.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'percepção'} do [NOME DO AVALIADO].</p><br/> <p>A pesquisa é rápida e fácil, mas seja sincero e atento para o melhor desenvolvimento do avaliado.</p><br/> <p>Contamos com sua participação!</p>`
  const textPadraoEmail = `<p>Olá, [NOME DO RESPONDENTE]!</p><p>Você está recebendo o convite para o preenchimento da pesquisa de ${path.includes('AnaliseDesempenho') ? 'análise de desempenho por competência' : 'perpepção'} do(a) [NOME DO AVALIADO].</p><p>A pesquisa é simples e rápida.</p><p>O prazo para preenchimento é até o dia [DATA FIM]</p><p>[Link]</p><p>Qualquer problema no preenchimento da pesquisa, favor contatar o responsável.</p><p>A sua participação é fundamental para o sucesso dessa iniciativa.</p><p>Contamos com você!</p>`
  const textPadraoPDI = '<p>Olá, [NOME DO RESPONSAVEL]!</p><p>A pesquisa do(a) [NOME DO AVALIADO] foi concluída e você já pode iniciar o cadastro do PDI para o desenvolvimento dele!</p><p>É muito simples, basta acessa o link com os resultados do avaliado e Clicar no botão Adicionar PDI, criar as ações especificando o que, por que e como ele deve trabalhar cada uma das competências definidas.</p><p>Você tem até o dia [DATA FIM PDI] para cadastrar o PDI.</p><p>Link dos resultados da pesquisa do(a) [NOME DO AVALIADO]:</p><p>[LINK]</p><p>Se tiver qualquer dúvida pode entrar em contato com nosso RH.</p><p>Atenciosamente,</p><p>Equipe [NOME DA EMPRESA]</p>'

  const pdiDefault: iPDIPesquisa = {
    dias: 0,
    destinatarioEmail: 0,
    notificacaoCadastro: [],
    textoCriacao: textPadraoPDI,
    pdiGerado: false
  }

  const [isLoading, setIsLoading] = useState(true)
  const [Model, setModel] = useState<iModel>({
    encerrarAutomaticamente: false,
    reenviarDias: [],
    tipo: 0,
    textosAbertura: [{ texto: textPadraoAbertura, participantes: [0, 1, 2, 3, 4, 6] }],
    textosEncerramento: [{ texto: textPadraoEncerramento, participantes: [0, 1, 2, 3, 4, 6] }],
    textosConvite: [{ texto: textPadraoEmail, participantes: [0, 1, 2, 3, 4, 6] }],
    pdi: {
      dias: 0,
      destinatarioEmail: 0,
      notificacaoCadastro: [0],
      textoCriacao: textPadraoPDI,
      pdiGerado: false
    }
  })

  const [ConfirmIsLoading, setConfirmIsLoading] = useState(false)

  const [DataInicioIsInvalid, setDataInicioIsInvalid] = useState(false)
  const [DataFimIsInvalid, setDataFimIsInvalid] = useState(false)
  const [ValidadeIsInvalid, setValidadeIsInvalid] = useState(false)
  const [CicloIsInvalid, setCicloIsInvalid] = useState(false)
  const [EnviarPdi, setEnviarPdi] = useState(false)
  const [ReenviarPesquisa, setReenviarPesquisa] = useState(false)
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [OutroPDI, setOutroPDI] = useState(false)
  const [PesquisaNaoIniciada, setPesquisaNaoIniciada] = useState<boolean>(false)

  function EnviarPesquisa(): void {
    setConfirmIsLoading(true)
    setDataInicioIsInvalid(false)
    setDataFimIsInvalid(false)
    setValidadeIsInvalid(false)
    setCicloIsInvalid(false)

    if (Model.tipo === 1 && PropInvalida(Model.ciclo)) {
      setCicloIsInvalid(true)
      toast({
        title: 'Selecione um ciclo',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 1 && PropInvalida(Model.validade)) {
      setValidadeIsInvalid(true)
      toast({
        title: 'Preencha a validade',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 1 && PropInvalida(Model.baseCiclo)) {
      toast({
        title: 'Escolha uma base para definir o ciclo da pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if ((Model.tipo === 0 && (Model.dataInicio === undefined || Model.dataInicio === '')) || (Model.tipo === 1 && Model.baseCiclo === 1 && PropInvalida(Model.dataInicio))) {
      setDataInicioIsInvalid(true)
      toast({
        title: 'Preencha a data de início',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
      return
    }

    if (Model.tipo === 0 && (Model.dataFim === undefined || Model.dataFim === '')) {
      setDataFimIsInvalid(true)
      toast({
        title: 'Preencha a data de fim',
        status: 'error',
        isClosable: false,
        position: 'top-right'
      })
    }

    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      reenviarDias: Model.reenviarDias?.length ? Model.reenviarDias : null,
      tipo: Model.tipo,
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite,
      pdi: EnviarPdi ? Model.pdi : null,
      baseCiclo: Model.baseCiclo,
      ciclo: Model.ciclo,
      validade: Model.validade
    }

    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Enviar`, form)
      .then(() => navigate(`${path.replace('EnviarPesquisa', 'ResumoPesquisa')}`))
      .catch(() => {
        setConfirmIsLoading(false)
        toast({
          title: 'Ocorreu um erro, tente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  function onChangeTipo(value: string): void {
    if (value === '0') {
      setModel({
        ...Model,
        tipo: parseInt(value),
        baseCiclo: undefined,
        ciclo: undefined,
        validade: undefined
      })
    } else if (value === '1') {
      setModel({
        ...Model,
        encerrarAutomaticamente: false,
        tipo: parseInt(value),
        dataFim: undefined
      })
    }
  }

  function UpdateTexto(tipo: number): void {
    if (tipo === 0) {
      appApi.put(`PesquisaPercepcao/TextoEmail/${pesquisaId as string}`, Model.textosConvite)
        .then(response => {
          if (response.status === 200) {
            toast({
              title: 'Texto salvo com sucesso',
              status: 'success',
              isClosable: false,
              position: 'top-right'
            })
          } else {
            alert('Erro ao alterar o texto')
          }
        }).catch(err => console.log(err))
    } else if (tipo === 1) {
      appApi.put(`PesquisaPercepcao/TextoAbertura/${pesquisaId as string}`, Model.textosAbertura)
        .then(response => {
          if (response.status === 200) {
            toast({
              title: 'Texto salvo com sucesso',
              status: 'success',
              isClosable: false,
              position: 'top-right'
            })
          } else {
            alert('Erro ao alterar o texto')
          }
        }).catch(err => console.log(err))
    } else if (tipo === 2) {
      appApi.put(`PesquisaPercepcao/TextoEncerramento/${pesquisaId as string}`, Model.textosEncerramento).then(response => {
        if (response.status === 200) {
          toast({
            title: 'Texto salvo com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          alert('Erro ao alterar o texto')
        }
      }).catch(err => console.log(err))
    } else if (tipo === 3) {
      const data = {
        texto: Model.pdi ? Model.pdi.textoCriacao : ''
      }
      appApi.put(`PesquisaPercepcao/TextoPDI/${pesquisaId as string}`, data).then(response => {
        if (response.status === 200) {
          toast({
            title: 'Texto salvo com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          alert('Erro ao alterar o texto')
        }
      }).catch(err => console.log(err))
    }
  }

  function UpdateDestinatarioPDI(value: string): void {
    setModel((prevModel) => ({
      ...prevModel,
      pdi: {
        ...prevModel.pdi ?? pdiDefault,
        destinatarioEmail: parseInt(value)
      }
    }))
    if (value === '3') {
      setOutroPDI(true)
      return
    }
    setOutroPDI(false)
  }

  function handleNotificacaoCadastroChange(value: number): void {
    const copy = [...Model.pdi?.notificacaoCadastro ?? pdiDefault.notificacaoCadastro]

    if (copy.some(e => e === value)) {
      setModel({
        ...Model,
        pdi: {
          ...Model.pdi ?? pdiDefault,
          notificacaoCadastro: copy.filter(e => e !== value)
        }
      })
    } else {
      setModel({
        ...Model,
        pdi: {
          ...Model.pdi ?? pdiDefault,
          notificacaoCadastro: [...copy, value]
        }
      })
    }
  }

  function SelecionarEnvioDias(value: string): void {
    if (Model.reenviarDias === null) {
      const copy = []
      copy.push(parseInt(value))
      setModel({ ...Model, reenviarDias: copy })
    } else if (Model.reenviarDias?.find(e => e.toString() === value) === undefined) {
      const copy = [...Model.reenviarDias ?? []]
      copy.push(parseInt(value))
      setModel({ ...Model, reenviarDias: copy })
    } else {
      const copy = [...Model.reenviarDias ?? []].filter(e => e.toString() !== value)
      setModel({ ...Model, reenviarDias: copy })
    }
  }

  function getPesquisa(): void {
    appApi.get(`PesquisaPercepcao/${pesquisaId as string}/Configuracao`)
      .then(response => {
        const data: iModel = response.data
        setModel(data)
        const DataString = `${data?.dataInicio ?? ''}`
        setPesquisaNaoIniciada(Date.parse(DataString) > new Date().getTime())
        if (!PropInvalida(data.pdi) && !data.pdi?.pdiGerado) {
          setEnviarPdi(true)
        }
        if (data.reenviarDias !== null) {
          setReenviarPesquisa(true)
        }
        setIsLoading(false)
      }).catch(err => console.log(err))
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoPesquisaEtapa(): void {
    const form = {
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite
    }
    const splRoute = path.split('/')
    appApi.put(`PesquisaPercepcaoEtapa/Pesquisa/${pesquisaId as string}`, form)
      .then(() => {
        if (path.includes('Trilha')) {
          navigate(`/Jornada/Configuracao/Trilha/${splRoute[splRoute.length - 1]}`)
        } else if (path.includes('Processo')) {
          navigate(`/Rh/VisualizarPerfil/${splRoute[splRoute.length - 1]}`)
        }
      })
      .catch(err => console.log(err))
  }

  function ReenviarPesquisaEtapaColaborador(): void {
    const form = {
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite
    }
    appApi.put(`PesquisaPercepcaoEtapaColaborador/${pesquisaId as string}/Reenviar`, form)
      .then(() => {
        navigate(-1)
      })
      .catch(err => console.log(err))
  }

  function ReenviarNaoRespondidos(): void {
    setConfirmIsLoading(true)
    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      pdi: EnviarPdi ? Model.pdi : null,
      reenviarDias: Model.reenviarDias?.length ? Model.reenviarDias : null
    }
    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Reenviar`, form).then(response => {
      if (response.status === 200) {
        navigate(-1)
      } else {
        alert('Erro')
      }
    }).catch(err => {
      console.log(err)
      setConfirmIsLoading(false)
    })
  }

  function SalvarNovaDataFinal(): void {
    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente
    }
    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/AlterarDataFim`, form).then(response => {
      if (response.status === 200) {
        toast({
          title: 'Data atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getPesquisa()
      } else {
        alert('Erro ao alterar data')
      }
    }).catch(err => console.log(err))
  }

  function VoltarPagina(salvar: boolean): void {
    setConfirmIsLoading(true)
    const form = {
      dataInicio: Model.dataInicio,
      dataFim: Model.dataFim,
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      reenviarDias: Model.reenviarDias?.length ? Model.reenviarDias : null,
      tipo: Model.tipo,
      textosAbertura: Model.textosAbertura,
      textosEncerramento: Model.textosEncerramento,
      textosConvite: Model.textosConvite,
      pdi: EnviarPdi ? Model.pdi : null,
      baseCiclo: Model.baseCiclo,
      ciclo: Model.ciclo,
      validade: Model.validade
    }

    appApi.put(`PesquisaPercepcao/${pesquisaId as string}/Salvar`, form)
      .then(() => {
        toast({
          title: !salvar ? 'Pesquisa atualizada com sucesso' : 'Dados da pesquisa salvos',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        if (salvar) {
          navigate(-1)
        } else {
          navigate(SubstringRoute(path, '/EnviarPesquisa') + '/Gestao')
        }
      })
      .catch(() => {
        setConfirmIsLoading(false)
        toast({
          title: 'Ocorreu um erro, tente mais tarde',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  function FluxoPDI(): any {
    if (path.includes('PesquisaPercepcao')) return
    return (
      <Flex flexDir={'column'}>
        <Flex direction={'column'}>
          <FormLabel marginRight={'.5rem'}>
            Adicionar fluxo de PDI após a pesquisa encerrar?</FormLabel>
          <RadioGroup value={EnviarPdi ? '1' : '2'} defaultValue='1' onChange={() => setEnviarPdi(!EnviarPdi)}>
            <Stack spacing={5} direction='row'>
              <Radio value='1'>
                Sim
              </Radio>
              <Radio value='2'>
                Não
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        {(EnviarPdi) &&
          <Flex direction={'column'}>
            <Divider my={'1rem'} />
            <h3 style={{ color: 'var(--a4)' }}>PDI</h3>

            <FormLabel my={'1rem'} fontSize={'lg'}>Tempo para preencher PDI após pesquisa encerrada</FormLabel>
            <FormControl alignItems={'center'} marginBottom={'1rem'} as={Flex}>
              <Input
                type={'number'}
                width={'5rem'}
                value={Model?.pdi?.dias}
                onChange={(e) => setModel((prevState) => ({
                  ...prevState,
                  pdi: {
                    ...prevState.pdi ?? pdiDefault,
                    dias: e.target.valueAsNumber
                  }
                }))}
                borderColor='var(--Gray4)'
              />
              <FormLabel width={'11rem'} marginLeft={'1rem'} fontSize={'lg'}>Dias</FormLabel>
            </FormControl>

            <FormLabel my={'1rem'} marginRight={'.5rem'}>
              Quem irá inserir o PDI do avaliado?</FormLabel>
            <RadioGroup value={Model?.pdi?.destinatarioEmail.toString()} defaultValue='1'
              onChange={UpdateDestinatarioPDI}>
              <Stack spacing={5} direction='row'>
                <Radio value='0'>Gestor</Radio>
                <Radio value='1'>Colaborador</Radio>
                <Radio value='2'>RH</Radio>
                <Radio value='3'>Outro:</Radio>
                {OutroPDI &&
                  <Flex flexDir={'column'}>
                    <FindUser
                      lista={Colaboradores}
                      onChoice={(e) => setModel((prevState) => ({
                        ...prevState,
                        pdi: {
                          ...prevState.pdi ?? pdiDefault,
                          responsavelId: e
                        }
                      }))}
                      placeholder='Digite o nome do participante'
                      selecionado={Model.pdi?.responsavelId}
                    />
                  </Flex>
                }
              </Stack>
            </RadioGroup>

            <FormLabel my={'1rem'} marginRight={'.5rem'}>
              Quem deve receber notificações de cadastro de PDI em atraso?</FormLabel>
            <Stack spacing={5} direction='row'>
              <Checkbox
                isChecked={Model.pdi?.notificacaoCadastro.includes(0)}
                onChange={() => handleNotificacaoCadastroChange(0)}
              >
                Gestor
              </Checkbox>
              <Checkbox
                isChecked={Model.pdi?.notificacaoCadastro.includes(2)}
                onChange={() => handleNotificacaoCadastroChange(2)}
              >
                RH
              </Checkbox>
              <Checkbox
                isChecked={Model.pdi?.notificacaoCadastro.includes(3)}
                onChange={() => handleNotificacaoCadastroChange(3)}
              >
                Outro:
              </Checkbox>
              {Model?.pdi?.notificacaoCadastro.includes(3) &&
                <Flex flexDir={'column'}>
                  <FindUser
                    lista={Colaboradores}
                    onChoice={(e) => setModel((prevState) => ({
                      ...prevState,
                      pdi: {
                        ...prevState.pdi ?? pdiDefault,
                        notificadoId: e
                      }
                    }))}
                    placeholder='Digite o nome do participante'
                    selecionado={Model.pdi.notificadoId}
                  />
                </Flex>
              }

            </Stack>
          </Flex>
        }
      </Flex>
    )
  }

  function AtualizarCiclica(): void {
    const form = {
      encerrarAutomaticamente: Model.encerrarAutomaticamente,
      pdi: EnviarPdi ? Model.pdi : null
    }
    appApi.put(`PesquisaPercepcao/${pesquisaId ?? ''}/Ciclica`, form)
      .then(() => {
        toast({
          title: 'Configuração atualizada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getColaboradores()
    getPesquisa()
  }, [])

  return (
    <Body>
      <ModalTag
        onClose={() => setModalTagIsOpen(false)}
        isOpen={ModalTagIsOpen}
      />
      <Main>
        <h1 style={{ margin: '2rem 0rem' }}>Enviar pesquisa</h1>
        {
          (!PropInvalida(Model.ciclo) && path.includes('Editar')) && (
            <WhiteBox>
              <Flex flexDir={'column'} gap='2rem'>
                <Flex alignItems={'center'} >
                  <input
                    checked={Model.encerrarAutomaticamente}
                    onChange={() => setModel({ ...Model, encerrarAutomaticamente: !Model.encerrarAutomaticamente })}
                    type={'checkbox'}
                  />
                  <small>Encerrar automaticamente quando todas as pesquisas do avaliado forem respondidas</small>
                </Flex>
                {FluxoPDI()}
                <Flex justifyContent={'end'}>
                  <Button
                    rightIcon={<FaSave />}
                    VarColor='Green2'
                    onClick={AtualizarCiclica}
                  >Salvar</Button>
                </Flex>
              </Flex>
            </WhiteBox>
          )
        }
        {
          (!path.includes('Trilha') && !path.includes('Processo') && PropInvalida(Model.ciclo) && path.includes('Editar')) && (
            <WhiteBox>
              <Flex flexDir={'column'} gap='2rem'>
                <Flex flexDir={'column'}>
                  <h2 style={{ color: 'var(--a4)' }}>Configuração da pesquisa</h2>

                  <FormControl alignItems={'center'} margin={'1rem 0'} as={Flex}>
                    <FormLabel width={'11rem'} marginRight={'1rem'} fontSize={'xl'}>Início da pesquisa</FormLabel>
                    <Input
                      width={'15rem'}
                      type={'datetime-local'}
                      min={new Date().toISOString().split('T')[0]}
                      value={Model?.dataInicio ?? ''}
                      onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                      borderColor='var(--Gray4)'
                      isInvalid={DataInicioIsInvalid}
                    />
                  </FormControl>

                  <FormControl alignItems={'center'} marginBottom={'1rem'} as={Flex}>
                    <FormLabel width={'11rem'} marginRight={'1rem'} fontSize={'xl'}>Final da pesquisa</FormLabel>
                    <Input
                      width={'15rem'}
                      type={'datetime-local'}
                      min={new Date().toISOString().split('T')[0]}
                      value={Model?.dataFim ?? ''}
                      onChange={(e) => setModel({ ...Model, dataFim: e.target.value })}
                      borderColor='var(--Gray4)'
                      isInvalid={DataFimIsInvalid}
                    />
                    {
                      (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                        <GreenButton margin-left='5px' OnClick={() => SalvarNovaDataFinal()} Size='md' isNav={false}>Salvar</GreenButton>
                      </Flex>)
                    }
                  </FormControl>

                  <Flex marginBottom={'1rem'} alignItems={'center'} >
                    <input
                      checked={Model.encerrarAutomaticamente}
                      onChange={(e) => setModel({ ...Model, encerrarAutomaticamente: e.target.checked })}
                      type={'checkbox'}
                    />
                    <small>Encerrar automaticamente quando todas as pesquisas do avaliado forem respondidas</small>
                  </Flex>
                </Flex>

                {!path.includes('PesquisaIndicativa') && (
                  <>
                    <Flex direction={'column'}>
                      <FormControl>

                        <FormLabel marginRight={'.5rem'}>Reenviar convites para quem não respondeu</FormLabel>
                        <RadioGroup value={ReenviarPesquisa ? '1' : '2'} defaultValue='1' onChange={() => { if (ReenviarPesquisa) { setModel({ ...Model, reenviarDias: [] }) }; setReenviarPesquisa(!ReenviarPesquisa) }}>
                          <Stack spacing={5} direction='row'>
                            <Radio value='1'>
                              Sim
                            </Radio>
                            <Radio value='2'>
                              Não
                            </Radio>
                          </Stack>
                        </RadioGroup>

                      </FormControl>
                      {ReenviarPesquisa &&
                        <Stack mt={'1rem'} direction={'column'} gap='.5rem'>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(5)}
                              value={'5'}
                              onChange={(e) => { SelecionarEnvioDias(e.target.value) }
                              }
                              type={'checkbox'}
                            />
                            <small>5 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(3)}
                              value={'3'}
                              onChange={(e) => { SelecionarEnvioDias(e.target.value) }
                              }
                              type={'checkbox'}
                            />
                            <small>3 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(2)}
                              value={'2'}
                              onChange={(e) => { SelecionarEnvioDias(e.target.value) }
                              }
                              type={'checkbox'}
                            />
                            <small>2 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(1)}
                              value={'1'}
                              onChange={(e) => { SelecionarEnvioDias(e.target.value) }
                              }
                              type={'checkbox'}
                            />
                            <small>1 dia antes de encerrar a pesquisa</small>
                          </Flex>
                        </Stack>
                      }
                    </Flex>

                    {FluxoPDI()}
                  </>
                )}
              </Flex>
            </WhiteBox>
          )
        }
        {
          (!path.includes('Trilha') && !path.includes('Processo') && !path.includes('Editar')) && (
            <WhiteBox>
              <FormControl as={Flex} alignItems='center'>
                <FormLabel margin={0} marginRight={'.5rem'}>Tipo da pesquisa</FormLabel>
                <Select
                  onChange={(e) => onChangeTipo(e.target.value)}
                  value={Model.tipo.toString()}
                  width={'20rem'}
                >
                  <option value={'0'}>Envio único</option>
                  <option value={'1'}>Ciclica</option>
                </Select>
              </FormControl>
              {
                (Model.tipo === 0) && (
                  <Flex flexDir={'column'}>
                    <FormControl alignItems={'center'} margin={'1rem 0'} as={Flex}>
                      <FormLabel width={'11rem'} marginRight={'1rem'} fontSize={'xl'}>Início da pesquisa</FormLabel>
                      <Input
                        width={'15rem'}
                        type={'datetime-local'}
                        min={new Date().toISOString().split('T')[0]}
                        value={Model?.dataInicio ?? ''}
                        onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                        borderColor='var(--Gray4)'
                        isInvalid={DataInicioIsInvalid}
                      />
                    </FormControl>

                    <FormControl alignItems={'center'} marginBottom={'1rem'} as={Flex}>
                      <FormLabel width={'11rem'} marginRight={'1rem'} fontSize={'xl'}>Final da pesquisa</FormLabel>
                      <Input
                        type={'datetime-local'}
                        width={'15rem'}
                        min={new Date().toISOString().split('T')[0]}
                        value={Model?.dataFim ?? ''}
                        onChange={(e) => setModel({ ...Model, dataFim: e.target.value })}
                        borderColor='var(--Gray4)'
                        isInvalid={DataFimIsInvalid}
                      />
                      {
                        (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                          <GreenButton margin-left='5px' OnClick={() => SalvarNovaDataFinal()} Size='md' isNav={false}>Salvar</GreenButton>
                        </Flex>)
                      }
                    </FormControl>

                    <Flex my={'.7rem'} alignItems={'center'} >
                      <input
                        checked={Model.encerrarAutomaticamente}
                        onChange={(e) => setModel({ ...Model, encerrarAutomaticamente: e.target.checked })}
                        type={'checkbox'}
                      />
                      <small>Encerrar automaticamente quando todas as pesquisas do avaliado forem respondidas</small>
                    </Flex>

                    <Flex my='2rem' direction={'column'}>
                      <FormControl>
                        <FormLabel marginRight={'.5rem'}>Reenviar convites para quem não respondeu</FormLabel>
                        <RadioGroup
                          value={ReenviarPesquisa ? '1' : '2'}
                          defaultValue='1'
                          onChange={() => { if (ReenviarPesquisa) { setModel({ ...Model, reenviarDias: [] }) }; setReenviarPesquisa(!ReenviarPesquisa) }}
                        >
                          <Stack spacing={5} direction='row'>
                            <Radio value='1'>
                              Sim
                            </Radio>
                            <Radio value='2'>
                              Não
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                      {ReenviarPesquisa &&
                        <Stack mt={'1rem'} direction={'column'} gap='.5rem'>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(5)}
                              onChange={() => SelecionarEnvioDias('5')}
                              type={'checkbox'}
                            />
                            <small>5 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(3)}
                              onChange={() => SelecionarEnvioDias('3')}
                              type={'checkbox'}
                            />
                            <small>3 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(2)}
                              onChange={() => SelecionarEnvioDias('2')}
                              type={'checkbox'}
                            />
                            <small>2 dias antes de encerrar a pesquisa</small>
                          </Flex>
                          <Flex alignItems={'center'} >
                            <input
                              checked={Model?.reenviarDias?.includes(1)}
                              onChange={() => SelecionarEnvioDias('1')}
                              type={'checkbox'}
                            />
                            <small>1 dia antes de encerrar a pesquisa</small>
                          </Flex>
                        </Stack>
                      }
                    </Flex>
                    {FluxoPDI()}
                  </Flex>
                )
              }

              {
                (Model.tipo === 1) && (
                  <Flex flexDir={'column'} gap='1rem'>
                    <FormControl marginTop={'1rem'} as={Flex} alignItems='center'>
                      <FormLabel margin={0} marginRight={'.5rem'}>Ciclo</FormLabel>
                      <Select
                        onChange={(e) => setModel({ ...Model, ciclo: parseInt(e.target.value) })}
                        value={Model.ciclo?.toString() ?? ''}
                        width={'20rem'}
                        placeholder='Selecione um ciclo'
                        isInvalid={CicloIsInvalid}
                      >
                        <option value={'0'}>Mensal</option>
                        <option value={'1'}>Trimestral</option>
                        <option value={'2'}>Semestral</option>
                        <option value={'3'}>Anual</option>
                        <option value={'4'}>Bianual</option>
                      </Select>
                    </FormControl>

                    <FormControl alignItems='center' as={Flex}>
                      <FormLabel margin={0} marginRight={'.5rem'}>Tempo limite para resposta</FormLabel>
                      <Input
                        width={'12rem'}
                        type={'number'}
                        placeholder={'Digite a validade'}
                        value={Model.validade?.toString() ?? ''}
                        onChange={(e) => setModel({ ...Model, validade: parseInt(e.target.value) })}
                        isInvalid={ValidadeIsInvalid}
                      />
                    </FormControl>

                    <FormControl flexDir={'column'} as={Flex}>
                      <FormLabel margin={0}>Definir o ciclo baseado na data de</FormLabel>
                      <RadioGroup
                        marginTop={'.5rem'}
                        onChange={(e) => setModel({ ...Model, baseCiclo: parseInt(e) })}
                        value={Model.baseCiclo?.toString() ?? ''}
                      >
                        <Stack direction='column'>
                          <Radio value='0'>ingresso do avaliado</Radio>
                          <Flex gap={'1rem'}>
                            <Radio value='1'>início da pesquisa</Radio>
                            {
                              (Model.baseCiclo === 1) && (
                                <Input
                                  width={'15rem'}
                                  type={'datetime-local'}
                                  min={new Date().toISOString().split('T')[0]}
                                  value={Model?.dataInicio ?? ''}
                                  onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                                  borderColor='var(--Gray4)'
                                  isInvalid={DataInicioIsInvalid}
                                />
                              )
                            }
                          </Flex>
                        </Stack>
                      </RadioGroup>
                    </FormControl>

                    {FluxoPDI()}

                    <Flex alignItems={'center'} >
                      <input
                        checked={Model.encerrarAutomaticamente}
                        onChange={(e) => setModel({ ...Model, encerrarAutomaticamente: e.target.checked })}
                        type={'checkbox'}
                      />
                      <small>Encerrar automaticamente quando todas as pesquisas do avaliado forem respondidas</small>
                    </Flex>
                  </Flex>
                )
              }
            </WhiteBox>
          )
        }

        <WhiteBox margin='1rem 0rem'>
          <BoxMultiText
            descricao='Esse texto será apresentado como e-mail ou notificação antes do respondente abrir a pesquisa'
            onChange={(e) => setModel({ ...Model, textosConvite: e })}
            openModalTag={() => setModalTagIsOpen(true)}
            textoPadrao={textPadraoEmail}
            model={Model.textosConvite}
            titulo='Texto do convite para preenchimento da pesquisa'
            onSave={() => UpdateTexto(0)}
          />
        </WhiteBox>

        <WhiteBox margin='1rem 0rem'>
          <BoxMultiText
            descricao='O respondente irá visualizar este texto antes de começar a responder a pesquisa.'
            onChange={(e) => setModel({ ...Model, textosAbertura: e })}
            openModalTag={() => setModalTagIsOpen(true)}
            textoPadrao={textPadraoAbertura}
            model={Model.textosAbertura}
            titulo='Texto de abertura da pesquisa'
            onSave={() => UpdateTexto(1)}
          />
        </WhiteBox>

        <WhiteBox margin='1rem 0rem'>
          <BoxMultiText
            descricao='Esse texto será apresentado quando o respondente terminar de responder uma pesquisa.'
            openModalTag={() => setModalTagIsOpen(true)}
            titulo='Texto de encerramento da pesquisa'
            textoPadrao={textPadraoEncerramento}
            model={Model.textosEncerramento}
            onChange={(e) => setModel({ ...Model, textosEncerramento: e })}
            onSave={() => UpdateTexto(2)}
          />
        </WhiteBox>

        {
          (EnviarPdi) && (
            <WhiteBox margin='1rem 0rem'>
              <Flex justifyContent={'space-between'}>
                <div style={{ maxWidth: '55rem', marginBottom: '1rem' }}>
                  <h3>Texto para criação do PDI</h3>
                  <small>Esse é o texto que será enviado ao e mail do responsável por subir o PDI do avaliado</small>
                </div>
                <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
              </Flex>

              <EditorTexto isLoading={isLoading} initialValue={Model.pdi?.textoCriacao ?? textPadraoPDI}
                onChange={(e: string) => setModel((prevState) => ({
                  ...prevState,
                  pdi: {
                    ...prevState.pdi ?? pdiDefault,
                    textoCriacao: e
                  }
                }))} />
              <Flex justifyContent={'space-between'} marginTop='1rem'>
                <Flex gap={'1rem'}>
                  <GreyButton
                    onClick={() => {
                      setIsLoading(true)
                      setModel((prevState) => ({
                        ...prevState,
                        pdi: {
                          ...prevState.pdi ?? pdiDefault,
                          textoCriacao: textPadraoPDI
                        }
                      }))
                      setTimeout(() => setIsLoading(false), 1000)
                    }}
                    Size='md'
                    isNav={false}
                  >Restaurar texto padrão</GreyButton>
                  {
                    (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
                      {EnviarPdi && <GreenButton OnClick={() => UpdateTexto(3)} Size='md' isNav={false}>Salvar</GreenButton>}
                    </Flex>)
                  }
                </Flex>
              </Flex>
            </WhiteBox>)
        }
      </Main>
      <Flex gap={'1rem'} justifyContent='end' marginTop={'1rem'}>
        {
          (path.includes('Editar')) && (<Flex marginLeft={'1rem'}>
            <Button size='lg' VarColor='c6' onClick={() => navigate(-1)}>Voltar</Button>
          </Flex>)
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && !path.includes('Editar')) && (
            <Flex marginLeft={'1rem'}>
              <Button size='lg' VarColor='c6' onClick={() => VoltarPagina(true)}>Voltar e salvar</Button>
            </Flex>)
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && !path.includes('Editar')) && (
            <Button
              size='lg'
              VarColor='v2'
              isLoading={ConfirmIsLoading}
              onClick={EnviarPesquisa}
            >Programar disparo</Button>
          )
        }

        {
          (!path.includes('Trilha') && !path.includes('Processo') && path.includes('Editar') && (Model.ciclo === null)) && (
            <Button
              size='lg'
              isLoading={ConfirmIsLoading}
              VarColor='v2'
              onClick={() => PesquisaNaoIniciada ? VoltarPagina(false) : ReenviarNaoRespondidos()}
            >{PesquisaNaoIniciada ? 'Salvar' : 'Salvar e reenviar convites não respondidos'} </Button>
          )
        }

        {
          ((path.includes('Trilha') || path.includes('Processo')) && !path.includes('Processo/Editar')) && (
            <Button size={'lg'} onClick={UpdateConfiguracaoPesquisaEtapa} VarColor='v2'>Atualizar detalhes da etapa</Button>
          )
        }

        {
          (path.includes('Processo/Editar')) && (
            <Button size={'lg'} onClick={ReenviarPesquisaEtapaColaborador} VarColor='v2'>Reenviar convites não respondidos</Button>
          )
        }
      </Flex>
    </Body>
  )
}
