import { Flex, FormLabel, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { iAlternativaFlexivel, iQuestaoFlexivel } from '../../../../../../../../interfaces'

interface iMultiplaEscolhaProps {
  questao: iQuestaoFlexivel
  onCreate: (id: string) => void
  onDelete: (questaoId: string, alternativaId: string) => void
  onUpdate: (questaoId: string, alternativaId: string, texto: string) => void
}

export const MultiplaEscolha: React.FC<iMultiplaEscolhaProps> = ({ questao, onCreate, onDelete, onUpdate }) => {
  const [Alternativas, setAlternativas] = useState<iAlternativaFlexivel[]>([])
  const [Editado, setEditado] = useState('')

  useEffect(() => {
    setAlternativas(questao.alternativas)
    setEditado('')
  }, [questao.alternativas])

  function UpdateArray(pos: number, texto: string): void {
    const copy = [...Alternativas]
    copy[pos].texto = texto
    setAlternativas(copy)
    setEditado(copy[pos].id)
  }

  return (
        <Flex flexDirection={'column'} width={'100%'}>
            <RadioGroup width={'100%'}>
                <FormLabel fontSize={'xl'}>Opções</FormLabel>
                <Stack direction={'column'} width={'100%'}>
                    {
                        Alternativas.map((e, i) => {
                          return (
                                <Flex
                                    key={e.id}
                                    borderRadius={'8px'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    border={'1px solid var(--Gray4)'}
                                    width={'100%'}
                                    padding={'.3rem 1rem'}
                                >
                                    <Flex width={'100%'}>
                                        <Radio marginRight={'1rem'} value={e.id}/>
                                        <Input
                                            type={'text'}
                                            border={'none'}
                                            _focus={{ border: 'none' }}
                                            padding={'0'}
                                            value={e.texto}
                                            placeholder={'Digite sua pergunta aqui'}
                                            onChange={(r) => UpdateArray(i, r.target.value)}
                                            onBlur={() => {
                                              if (e.id === Editado) { onUpdate(questao.id, e.id, e.texto) }
                                            }}
                                            // borderBottom={'2px solid var(--Gray4)'}
                                            // boxShadow='none'
                                            // borderRadius={0}
                                        />
                                    </Flex>
                                    <MdClose
                                        size={24}
                                        color='var(--Gray3)'
                                        cursor={'pointer'}
                                        onClick={() => onDelete(questao.id, e.id)}
                                    />
                                </Flex>
                          )
                        })
                    }
                </Stack>
            </RadioGroup>

            <Flex marginTop={'1rem'}>
                <Radio disabled/>
                <Input
                    border={'none'}
                    placeholder={'Adicione mais uma opção'}
                    borderBottom={'1px solid var(--Gray4)'}
                    width={'fit-content'}
                    boxShadow='none'
                    borderRadius={0}
                    _focus={{ borderColor: 'var(--Gray4)' }}
                    readOnly
                    cursor={'pointer'}
                    onClick={() => onCreate(questao.id)}
                />
            </Flex>
        </Flex>
  )
}
