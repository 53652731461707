import { useEffect, useState } from 'react'
import { Container } from './styles'
import { iCardEtapa, iItemIntegracao } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
interface iImersao {
  etapa: iCardEtapa
  isOpen: boolean
}

export const CardImersao: React.FC<iImersao> = ({ etapa, isOpen }) => {
  const [Itens, setItem] = useState<iItemIntegracao[]>([])

  function ObterImersao(): void {
    appApi.get(`Imersao/${etapa.interacoes[0].idExterno}`)
      .then(response => { setItem(response.data.itensIntegracao) }).catch(err => console.error(err))
  }

  useEffect(() => {
    if (isOpen && Itens.length === 0) {
      ObterImersao()
    }
  }, [isOpen])

  return (
    <Container>
      <h3>Lista de itens adicionados</h3>
      {
        Itens.map((e: iItemIntegracao, i): any => {
          return (
            <span key={i}>{i + 1}.  {e.titulo}</span>
          )
        })
      }
    </Container>
  )
}
