import { Checkbox, Flex, FormLabel, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { Button } from '../../../../../../components/Button'
import { EditorTexto } from '../../../../../../components/EditorTexto/Index'
import { ButtonTag } from '../../../../../PesquisaPercepcao/EnviarPesquisa/Components/ButtonTag'

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

interface iProps {
  model: iTextosPesquisa[]
  onChange: (textos: iTextosPesquisa[]) => void
  openModalTag: () => void
  onPrevisualizar?: (gestor: boolean) => void
  textoPadrao: string
  titulo: string
  descricao: string
}
export const BoxMultiText: React.FC<iProps> = ({ titulo, descricao, textoPadrao, model, onPrevisualizar, onChange, openModalTag }) => {
  console.log({ titulo, descricao, textoPadrao, model })
  const [Boxs, setBoxs] = useState<iTextosPesquisa[]>([{ texto: textoPadrao, participantes: [0, 1] }])
  const [isLoading, setIsLoading] = useState(true)

  function SelecionarEixo(e: string, index: number): void {
    if (index === 0) {
      CriarNovoEditor(e, index)
    } else if (Boxs[index].participantes.length > 0) {
      ExcluirNovoEixo(parseInt(e), index)
    }
  }

  function CriarNovoEditor(e: string, index: number): void {
    const indexEixo = (ind: number): number => {
      return Boxs[ind].participantes.findIndex(r => r === parseInt(e))
    }
    const boxs = [...Boxs, {
      texto: textoPadrao,
      participantes: Boxs[index].participantes.splice(indexEixo(index), 1)
    }]
    setBoxs(boxs)
    onChange(boxs)
  }

  function ExcluirNovoEixo(valorEixoAtual: number, index: number): void {
    const firstElement = Boxs[0]
    firstElement.participantes.push(valorEixoAtual)
    Boxs.shift()
    setBoxs({ ...Boxs.splice(index - 1, 1) })
    const copy = [...Boxs]
    copy.unshift(firstElement)
    setBoxs(copy)
    onChange(copy)
  }

  function AtualizarTexto(value: string, index: number): void {
    const copy = [...Boxs]
    copy[index].texto = value
    onChange(copy)
  }

  useEffect(() => {
    setIsLoading(true)
    setBoxs(model)
    setTimeout(() => setIsLoading(false), 1000)
  }, [model])

  return (
        <Flex flexDir={'column'}>
            {Boxs?.map((elemento, index) => {
              return (
                    <div key={index}>
                        {index > 0 && (
                            <hr style={{ marginTop: '1rem', border: '1px solid var(--c3)' }} />
                        )}
                        <div>
                            {index === 0 && (
                                <Flex justifyContent={'space-between'}>
                                    <Flex flexDir={'column'}>
                                        <h3>{titulo}</h3>
                                        <small>{descricao}</small>
                                    </Flex>
                                    <ButtonTag onClick={openModalTag}>Saiba como utilizar as TAGs</ButtonTag>
                                </Flex>
                            )}
                            <Flex width={'100%'} alignItems='center' justifyContent={'space-between'}>
                                <Flex flexDir={'column'}>
                                    <FormLabel my={'1rem'} marginRight={'.5rem'}>
                                        {index === 0 ? 'Participantes que receberão essa mensagem:' : 'Participante que receberá essa mensagem:'}
                                    </FormLabel>
                                    <Stack my={'1rem'} spacing={5} direction='row'>
                                        {elemento.participantes.includes(0) && (
                                            <Checkbox
                                                isDisabled={index === 0 && elemento.participantes.length === 1}
                                                isChecked={elemento.participantes.includes(0)}
                                                value={'0'}
                                                onChange={(e) => SelecionarEixo(e.target.value, index)}
                                            >
                                                Organizador
                                            </Checkbox>
                                        )}

                                        {elemento.participantes.includes(1) && (
                                            <Checkbox
                                                isDisabled={index === 0 && elemento.participantes.length === 1}
                                                isChecked={elemento.participantes.includes(1)}
                                                value={'1'}
                                                onChange={(e) => SelecionarEixo(e.target.value, index)}
                                            >
                                                Participante
                                            </Checkbox>
                                        )}
                                    </Stack>
                                </Flex>
                                {index > 0 && (
                                    <Button
                                        onClick={() => SelecionarEixo(elemento.participantes[0].toString(), index)}
                                        VarColor='terc3'
                                    >Excluir mensagem</Button>
                                )}
                            </Flex>
                            <FormLabel my={'.2rem'} marginRight={'.5rem'} color={'var(--c7)'} fontSize={'10pt'} pb={'.6rem'} mt={'-.1rem'}>
                                Ao desselecionar um eixo é gerado um novo editor de texto personalizado para ele
                            </FormLabel>
                        </div>
                        <EditorTexto
                            isLoading={isLoading}
                            initialValue={elemento.texto}
                            onBlur={(e: string) => AtualizarTexto(e, index)}
                        />
                        <Flex justifyContent={'space-between'} marginTop='1rem'>
                            <Flex gap={'1rem'}>
                                <Button
                                    VarColor='c6'
                                    onClick={() => {
                                      AtualizarTexto(textoPadrao, index)
                                    }}
                                >Restaurar texto padrão</Button>
                                {onPrevisualizar !== undefined &&
                                  <Button
                                      leftIcon={<FaEye/>}
                                      VarColor='vg3'
                                      onClick={() => onPrevisualizar(elemento.participantes.includes(0) || elemento.participantes.includes(2))}
                                  >Pré-visualizar</Button>
                                }
                            </Flex>
                        </Flex>
                    </div>
              )
            })}
        </Flex>
  )
}
