import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'
import { useState, useEffect } from 'react'
import { iAvaliado } from '../..'

interface iModal {
  isOpen: boolean
  avaliado?: iAvaliado
  dataFimUltimoEncerrado?: string
  onRequestClose: () => void
  onConfirm: (data: string, dataFim: string, reenviar: boolean) => void
}

export const ModalConfiguracao: React.FC<iModal> = ({ isOpen, avaliado, dataFimUltimoEncerrado, onRequestClose, onConfirm }) => {
  const [Data, setData] = useState('')
  const [DataFim, setDataFim] = useState('')

  function FormatarData(data: string): string {
    const [day, month, yearTime] = data?.split('/') ?? []
    if (yearTime === undefined || yearTime === null) return ''
    const [year, Time] = yearTime.split(' ')
    return year + '-' + month + '-' + day + ' ' + Time
  }

  useEffect(() => {
    setData(FormatarData(avaliado?.dataInicio ?? ''))
    setDataFim(FormatarData(avaliado?.dataFim ?? ''))
  }, [avaliado])

  return (
        <ModalBase
            isOpen={isOpen}
            onClose={onRequestClose}
            Width="40rem"
            Titulo='Configuracões'
        >
            <Flex flexDir={'column'} padding={'1rem'}>
                <Flex gap={'1rem'}>
                    <FormControl>
                        <FormLabel>Data de início</FormLabel>
                        <Input
                            value={Data}
                            onChange={(e) => setData(e.target.value)}
                            type={'datetime-local'}
                            // min={dataFimUltimoEncerrado !== undefined ? converterData(dataFimUltimoEncerrado.substring(0, 10), 1).toISOString().split('T')[0] + 'T00:00:00' : ''}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Data de fim</FormLabel>
                        <Input
                            value={DataFim}
                            onChange={(e) => setDataFim(e.target.value)}
                            type={'datetime-local'}
                        />
                    </FormControl>
                </Flex>
                <Flex justifyContent={'end'} gap='1rem' marginTop={'1rem'}>
                    <Button VarColor='Gray4' onClick={onRequestClose}>Cancelar</Button>
                    <Button VarColor='Green2' onClick={() => onConfirm(Data, DataFim, false)}>Salvar</Button>
                    {
                        (avaliado?.iniciada) && (
                            <Button
                                VarColor='Green2'
                                onClick={() => onConfirm(Data, DataFim, true)}
                            >Salvar e reenviar para os não respondidos</Button>
                        )
                    }
                </Flex>
            </Flex>
        </ModalBase>
  )
}
