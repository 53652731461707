/* eslint-disable no-tabs */
import { Flex, Text } from '@chakra-ui/react'
import { FaFileSignature } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { TimelineCriarPesquisa } from '../components/TimelineCriarPesquisa'
import { BoxModelo } from './Components/BoxModelo/Index'

export const SelecionarModelo: React.FC = () => {
  return (
		<Body>
			<TimelineCriarPesquisa color={[]}/>
			<h2>Selecione o estilo de pesquisa que deseja utilizar</h2>
			<Flex gap={'3.75rem'} mt={'2rem'} justifyContent={'center'}>
				<BoxModelo navegar={0} bgColor='var(--Green1)'>
					<Flex gap={'1rem'} fontSize={'24px'} fontWeight={'700'} alignItems={'center'}>
						<FaFileSignature />
						<Text>Quantitiva</Text>
					</Flex>
					<Text my={'1rem'}>Com esta metodologia, os participantes irão responder uma pesquisa com <Text as={'span'} textDecoration={'underline'} fontWeight={'800'}>escalas de níveis.</Text></Text>

				</BoxModelo>
				<BoxModelo navegar={1} bgColor='var(--Blue1)'>
					<Flex gap={'1rem'} fontSize={'24px'} fontWeight={'700'} alignItems={'center'}>
						<FaFileSignature />
						<Text>Indicativa</Text>
					</Flex>
					<Text my={'1rem'}>Com esta metodologia, os participantes irão <Text as={'span'} textDecoration={'underline'} fontWeight={'800'}>indicar as competências de destaque e para desenvolvimento.</Text></Text>

				</BoxModelo>
			</Flex>
		</Body>
  )
}
