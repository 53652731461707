import { Flex, Text } from '@chakra-ui/react'
import { iCompetenciaPDI } from '../../../../../../interfaces'
import Stonks from '../../../../../../assets/Stonks.svg'

interface iRecomendacaoProps{
  pos: number
  recomendacao: iCompetenciaPDI
}

export const Recomendacao: React.FC<iRecomendacaoProps> = ({ recomendacao, pos }) => {
  return (
        <Flex width={'100%'}>
            <Flex borderBottomLeftRadius={'1.875rem'} borderTopLeftRadius={'1.875rem'} background={'var(--c2)'}>
                <h3 style={{ padding: '.5rem 0 0 .5rem' }}>{pos}°</h3>
                <Flex width={'10rem'} textAlign={'center'} justifyContent={'center'} alignItems='center' flexDir={'column'} padding={'1rem 1rem 1rem 0'}>
                    <Flex width={'5rem'}>
                        <img style={{ width: '100%', height: '100%' }} src={recomendacao.avatar} />
                    </Flex>
                    <Text fontSize={'14px'} lineHeight='125%' fontWeight={'bold'}>{recomendacao.nome}</Text>
                </Flex>
            </Flex>
            <Flex flexDir={'column'} borderBottomRightRadius={'1.875rem'} borderTopRightRadius={'1.875rem'} padding={'1rem'} color='white' fontWeight={'600'} background={'var(--Blue2)'} width={'-webkit-fill-available'}>
                <Text fontSize={'12px'} lineHeight='115%' fontWeight={'300'}>{recomendacao.pergunta}</Text>
                <Flex height={'100%'} alignItems={'end'} justifyContent={'end'} >
                    <Flex height='1.5rem'>
                        <img src={Stonks}/>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
  )
}
