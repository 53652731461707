/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { FaFolder, FaImage } from 'react-icons/fa'
import { IEmployee, iNineBox, iPerfilColaborador, iPutColaborador, iQuadrante } from '../../interfaces'
import { appApi } from '../../services/appApi'
import { BoxOrganogram } from './Components/BoxOrganogram'
import {
  DashBox, BtnPerfil,
  RepositoryBox, ColorCircle, MainColumn, ColumnTexts, ImgPerfil
} from './styles'
import { Avatar, Button, Flex, Image, Link, Menu, MenuButton, MenuItem, MenuList, Text, Box, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ModalCropColaborador } from './Components/ModalCrop'
import { PropInvalida } from '../../Utils/Helper'
import { ObterImgPorNome } from '../NineBox'
import { UpdateColaboradorModal } from '../ModalUpdateColaborador'
import { parseJwt } from '../../services/token'

interface iPerfilColumn {
  Colaborador: iPerfilColaborador
  nineBox?: iNineBox
  margin?: string
  reload?: () => void
}

export interface iPI {
  urlSelo: string
  perfil: string
  assessmentId: string
  hide: boolean
}

export function ObterCorQuadrantePorNome(quadrantes: iQuadrante[], nome: string): string {
  switch (quadrantes.findIndex(p => p.titulo === nome).toString()) {
    case '0': return 'var(--Blue1)'
    case '1': return 'var(--Green3)'
    case '2': return 'var(--Green2)'
    case '3': return 'var(--Orange)'
    case '4': return 'var(--Blue1)'
    case '5': return 'var(--Green3)'
    case '6': return 'var(--Red)'
    case '7': return 'var(--Orange)'
    case '8': return 'var(--Blue1)'
    default: return ''
  }
}

export const PerfilColaborador: React.FC<iPerfilColumn> = ({ Colaborador, nineBox, margin, reload }) => {
  const toast = useToast()
  const [ColaboradorInterno, setColaboradorInterno] = useState<iPerfilColaborador>({} as iPerfilColaborador)
  const [PI, setPI] = useState<iPI>()
  const [ModalUpdateColaboradorIsOpen, setModalUpdateColaboradorIsOpen] = useState(false)
  const [UpdateColaborador, setUpdateColaborador] = useState<IEmployee>()

  const [ModalCropIsOpen, setModalCropIsOpen] = useState(false)

  function getColaborador(): void {
    appApi.get(`Colaborador/${Colaborador.id}`)
      .then(res => {
        setUpdateColaborador(res.data)
      }).catch(err => {
        console.log(err)
      })
  }

  function onUpdateColaborador(form: iPutColaborador): void {
    appApi.put(`Colaborador/${UpdateColaborador?.id ?? ''}`, form).then(() => {
      toast({
        title: 'Colaborador atualizado com sucesso',
        status: 'success',
        position: 'top-right',
        isClosable: false
      })
      setModalUpdateColaboradorIsOpen(false)
      if (reload !== undefined) {
        reload()
      }
    }).catch(({ response }) => {
      toast({
        title: response.data,
        status: 'error',
        position: 'top-right',
        isClosable: false
      })
    }
    )
  }

  function getPerfilPI(): void {
    appApi.get(`https://sah.icecloud.com.br/api/integracao/sahpi/?email=${Colaborador.email}`)
      .then(res => {
        setPI(res.data)
      }).catch(err => {
        console.log(err)
      })
  }

  function UpdateAvatar(form: FormData): void {
    appApi.put(`Colaborador/Avatar/${Colaborador.id}?url=${Colaborador.avatar}`, form)
      .then(res => {
        setColaboradorInterno({ ...ColaboradorInterno, avatar: res.data })
        setModalCropIsOpen(false)
      }).catch(err => {
        console.log(err)
      })
  }
  function ReenviarPrimeiroAcesso(): void {
    appApi.post(`Colaborador/ReenviarPrimeiroAcesso/${Colaborador.id}`)
      .then(() =>
        toast({
          title: 'Email reenviado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        }))
      .catch(err => console.log(err))
    setModalUpdateColaboradorIsOpen(false)
  }

  useEffect(() => {
    if (Colaborador.email !== undefined && PropInvalida(Colaborador.perfilPI)) {
      getPerfilPI()
    }
  }, [Colaborador.email])

  useEffect(() => {
    setColaboradorInterno(Colaborador)
  }, [Colaborador])

  useEffect(() => {
    if (ModalUpdateColaboradorIsOpen) {
      getColaborador()
    } else {
      setUpdateColaborador(undefined)
    }
  }, [ModalUpdateColaboradorIsOpen])

  return (
        <MainColumn padding='1rem' width='39%' margin={margin}>
            {
                (ModalCropIsOpen) && (
                    <ModalCropColaborador
                        isOpen={ModalCropIsOpen}
                        onRequestClose={() => setModalCropIsOpen(false)}
                        onConfirm={UpdateAvatar}
                    />
                )
            }
            {
                (ModalUpdateColaboradorIsOpen) && (
                    <UpdateColaboradorModal
                        ReenviarPrimeiroAcesso={ReenviarPrimeiroAcesso}
                        isOpen={ModalUpdateColaboradorIsOpen}
                        onRequestClose={() => setModalUpdateColaboradorIsOpen(false)}
                        colaborador={UpdateColaborador}
                        onUpdateEmployee={onUpdateColaborador}
                    />
                )
            }
            <Flex>
                <Flex marginBottom={'2rem'} alignItems={'center'}>
                    <ImgPerfil onClick={() => setModalCropIsOpen(true)}>
                        <Avatar
                            marginRight={'1rem'}
                            size={'2xl'}
                            name={ColaboradorInterno?.nome}
                            src={ColaboradorInterno?.avatar}
                        />
                    </ImgPerfil>
                    <ColumnTexts>
                        <h3>{ColaboradorInterno?.nome}</h3>
                        <span>Área: {ColaboradorInterno?.nomeDepartamento}</span>
                        <span>Função: {ColaboradorInterno?.nomeCargo}</span>
                        {
                            ColaboradorInterno?.email?.length > 30
                              ? <span title={ColaboradorInterno?.email}>{ColaboradorInterno?.email.substring(0, 31)}...</span>
                              : <span>{ColaboradorInterno?.email}</span>
                        }
                        <span>{ColaboradorInterno?.celular}</span>
                    </ColumnTexts>
                </Flex>
            </Flex>
            {(parseJwt().adm === 'True') && (
            <Button mb={'2rem'} fontSize={'14px'} height={'25px'} backgroundColor={'#fff'} color={'var(--Purple1)'} borderRadius={'10px'} border={'1px solid var(--Purple1)'} _hover={{ backgroundColor: 'var(--Purple1)', color: '#fff' }}
            onClick={() => setModalUpdateColaboradorIsOpen(true)}>Editar perfil</Button>)}
            {(ColaboradorInterno.avaliacaoNineBox && nineBox) && (
                <Flex mb={'2rem'} gap={'1rem'}>
                    <Box backgroundColor={ObterCorQuadrantePorNome(nineBox.quadrantes, Colaborador.avaliacaoNineBox?.tituloQuadrante ?? '')} w={'5.925rem'} h={'5.925rem'} display={'flex'} flexDirection={'column'} color={'#fff'} borderRadius={'1rem'} justifyContent={'center'} alignItems={'center'}>
                        <Text fontSize={'0.75rem'}>{ColaboradorInterno.avaliacaoNineBox.tituloQuadrante}</Text>
                        <Image height={'3rem'} width={'3rem'} src={ObterImgPorNome(nineBox.quadrantes, Colaborador.avaliacaoNineBox?.tituloQuadrante ?? '')} />
                    </Box>
                    <Box>
                        <Text color={'var(--Azul)'}>{ColaboradorInterno.avaliacaoNineBox.tituloY}: {ColaboradorInterno.avaliacaoNineBox.notaY === -1 ? '-' : ColaboradorInterno.avaliacaoNineBox.notaY}</Text>
                        <Text color={'var(--Green2)'}>{ColaboradorInterno.avaliacaoNineBox.tituloX}: {ColaboradorInterno.avaliacaoNineBox.notaX === -1 ? '-' : ColaboradorInterno.avaliacaoNineBox.notaX}</Text>
                    </Box>
                </Flex>
            )}
            <DashBox theme={'a5'}>
                {
                    (ColaboradorInterno) && (
                        <BoxOrganogram
                            Lider={ColaboradorInterno.lider}
                            Pares={ColaboradorInterno.pares}
                            liderados={ColaboradorInterno.liderados}
                        />
                    )
                }
            </DashBox>

            {
                (PI && !PI.hide) && (
                    <DashBox theme={'terc3'}>
                        <Flex justifyContent={'center'}>
                            <span className='DashTitle'>Perfil comportamental</span>
                        </Flex>
                        <Flex flexDirection={'column'}>
                            <Flex marginBottom={'1rem'} flexDir='column' width='100%' alignItems={'center'}>
                                {
                                    (PI.perfil) && (
                                        <Text marginBottom={'.5rem'} fontWeight={'500'}>{PI.perfil}</Text>
                                    )
                                }
                                {
                                    (PI.urlSelo) && (
                                        <Image width={'4rem'} src={PI.urlSelo} />
                                    )
                                }
                            </Flex>
                            <Flex justifyContent={'space-around'}>
                                {
                                    (PI.assessmentId !== undefined) && (
                                        <Image width={'18rem'} src={`https://sah.icecloud.com.br/api/Files?id=${PI.assessmentId}&tipo=2`} />
                                    )
                                }
                            </Flex>
                            <Menu>
                                <MenuButton as={Button} bgColor='var(--vg4)' _hover={{ bgColor: 'white', color: 'var(--vg4)', border: '2px', borderColor: 'var(--vg4)' }}>
                                    Baixar Relatorios
                                </MenuButton>
                                <MenuList bgColor='var(--vg4)'>
                                    <MenuItem>
                                        <Button as={Link} borderColor='var(--vg4)'
                                            href={`https://sah.icecloud.com.br/api/Files/api/pdf?id=${PI.assessmentId}&tipo=2`}
                                            color='var(--vg4)'>
                                            Perfil comportamental
                                        </Button>
                                    </MenuItem>

                                    <MenuItem>
                                        <Button as={Link} borderColor='var(--vg4)'
                                            href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=2`}
                                            color='var(--vg4)'>
                                            Quadro desenvolvimento pessoal
                                        </Button>
                                    </MenuItem>

                                    <MenuItem>
                                        <Button as={Link} borderColor='var(--vg4)'
                                            href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=3`}
                                            color='var(--vg4)'>
                                            Placard
                                        </Button>

                                    </MenuItem>
                                    <MenuItem>
                                        <Button as={Link} borderColor='var(--vg4)'

                                            href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=4`}
                                            color='var(--vg4)'>
                                            Quadro desenvolvimento gestores
                                        </Button>
                                    </MenuItem>

                                </MenuList>
                            </Menu>
                        </Flex>
                    </DashBox>
                )
            }

            {
                (!PropInvalida(Colaborador.perfilPI)) && (
                    <DashBox theme={'terc3'}>
                        <Flex justifyContent={'center'}>
                            <span className='DashTitle'>Perfil comportamental</span>
                        </Flex>
                        <Flex flexDirection={'column'}>
                            <Flex marginBottom={'1rem'} flexDir='column' width='100%' alignItems={'center'}>
                                {
                                    (Colaborador.perfilPI?.nomeSelo) && (
                                        <Text marginBottom={'.5rem'} fontWeight={'500'}>{Colaborador.perfilPI?.nomeSelo}</Text>
                                    )
                                }
                                {
                                    (Colaborador.perfilPI?.urlSelo) && (
                                        <Image width={'4rem'} src={Colaborador.perfilPI.urlSelo} />
                                    )
                                }
                            </Flex>

                            <Flex justifyContent={'space-around'}>
                                {
                                    (Colaborador.perfilPI?.urlSelf) && (
                                        <Image width={'18rem'} src={Colaborador.perfilPI.urlSelf} />
                                    )
                                }
                            </Flex>

                            <Menu>
                                <MenuButton as={Button} bgColor='var(--vg4)' _hover={{ bgColor: 'white', color: 'var(--vg4)', border: '2px', borderColor: 'var(--vg4)' }}>
                                    Baixar Relatorios
                                </MenuButton>
                                <MenuList bgColor='var(--vg4)'>
                                    {
                                        (!PropInvalida(Colaborador.perfilPI?.urlResultado)) && (
                                            <MenuItem>
                                                <Button as={Link} borderColor='var(--vg4)'
                                                    href={Colaborador.perfilPI?.urlResultado}
                                                    color='var(--vg4)'>
                                                    Perfil comportamental
                                                </Button>
                                            </MenuItem>
                                        )
                                    }

                                    {
                                        (!PropInvalida(Colaborador.perfilPI?.urlQuadro)) && (
                                            <MenuItem>
                                                <Button as={Link} borderColor='var(--vg4)'
                                                    href={Colaborador.perfilPI?.urlQuadro}
                                                    color='var(--vg4)'>
                                                    Quadro desenvolvimento pessoal
                                                </Button>
                                            </MenuItem>
                                        )
                                    }

                                    {
                                        (!PropInvalida(Colaborador.perfilPI?.urlPlacard)) && (
                                            <MenuItem>
                                                <Button as={Link} borderColor='var(--vg4)'
                                                    href={Colaborador.perfilPI?.urlPlacard}
                                                    color='var(--vg4)'>
                                                    Placard
                                                </Button>
                                            </MenuItem>
                                        )
                                    }

                                    {
                                        (!PropInvalida(Colaborador.perfilPI?.urlQuadroGestor)) && (
                                            <MenuItem>
                                                <Button as={Link} borderColor='var(--vg4)'

                                                    href={Colaborador.perfilPI?.urlQuadroGestor}
                                                    color='var(--vg4)'>
                                                    Quadro desenvolvimento gestores
                                                </Button>
                                            </MenuItem>
                                        )
                                    }

                                </MenuList>
                            </Menu>
                        </Flex>
                    </DashBox>
                )
            }

            {/* <RepositoryBox>
                <h4>Nome da pasta</h4>
                <Flex className='folder-c'>
                    <FlexAlignCenter>
                        <FaFolder size={25} color='#D7DCE1' />
                        <Column>
                            <h5>Nome da pasta</h5>
                            <small>Útima modificação: 08 de julho, 2021</small>
                        </Column>
                    </FlexAlignCenter>
                    <ColorCircle theme={'terc3'} />
                </Flex>

                <Flex className='folder-c'>
                    <FlexAlignCenter>
                        <FaFolder size={25} color='#D7DCE1' />
                        <Column>
                            <h5>Nome da pasta</h5>
                            <small>Útima modificação: 08 de julho, 2021</small>
                        </Column>
                    </FlexAlignCenter>
                </Flex>

                <Flex className='folder-c'>
                    <FlexAlignCenter>
                        <FaFolder size={25} color='#D7DCE1' />
                        <Column>
                            <h5>Nome da pasta</h5>
                            <small>Útima modificação: 08 de julho, 2021</small>
                        </Column>
                    </FlexAlignCenter>
                </Flex>

                <Flex className='folder-c'>
                    <FlexAlignCenter>
                        <FaFolder size={25} color='#D7DCE1' />
                        <Column>
                            <h5>Nome da pasta</h5>
                            <small>Útima modificação: 08 de julho, 2021</small>
                        </Column>
                    </FlexAlignCenter>
                    <ColorCircle theme={'terc3'} />
                </Flex>
            </RepositoryBox> */}
        </MainColumn>
  )
}
