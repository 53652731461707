/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { Box, Flex } from '@chakra-ui/react'
import { BsPencilSquare } from 'react-icons/bs'
import { useNavigate } from 'react-router'
import { iBox } from '../..'
import { Button } from '../../../../../../components/Button'

interface iProps {
  box: iBox
}

export const ItemNineBox: React.FC<iProps> = ({ box }) => {
  const nav = useNavigate()
  return (
    <Box as='div' display={'flex'} p={'1rem'} width={'23.75rem'} boxSizing={'border-box'} height={'11.25rem'} gap={'1rem'} border={'0.125rem solid var(--Gray4)'} borderRadius={'1rem'}>
      <Flex flexDirection={'column'} width={'10.75rem'} justifyContent={'space-between'} height={'9.375rem'}>
        <h4>{box.titulo}</h4>
        <Flex flexDirection={'row'} flexWrap={'wrap'} gap={'.4rem'}>
          {
            box.tags.map((tag, i) => {
              return (
                <Box
                  key={i}
                  as='small'
                  bg={'var(--Azul)'}
                  boxShadow={'0 0.125rem .5rem rgba (0, 0, 0, 0.25)'}
                  borderRadius={'.5rem'}
                  color={'white'}
                  px={'.5rem'}
                  fontSize={'0.75rem'}
                  py={'.25rem'}
                >{tag.substring(0, 10)}</Box>
              )
            })
          }
        </Flex>
        <Button
          color={'white'}
          VarColor={'Roxo'}
          p={'.75rem 1.25rem'}
          width={'5.75rem'}
          fontSize={'0.75rem'}
          leftIcon={<BsPencilSquare />}
          borderRadius={'0.875rem'}
          onClick={() => nav(`NineBox/${box.id}`)}
        >Editar</Button>
      </Flex>
      <Flex width={'10rem'} boxSizing={'border-box'}>
        <img src={'https://sigah.blob.core.windows.net/onboard/f4008767-1667-43cf-9c80-83cfe4e37ee9.png'} />
      </Flex>
    </Box>
  )
}
