import styled from 'styled-components'

export const OutlineContainer = styled.div`
width: 100%;
border-radius: 20px;
padding: 1rem;
display: flex;
flex-direction: column;
border: 1px solid var(--${props => props.theme});
`

export const ContainerFiltro = styled.div`
display: flex;
align-items: center;
background-color: white;
border-radius: 8px;
width: 100%;
`
