import { Button, Flex, HStack, Text, CircularProgress } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import { PropInvalida } from '../../../../Utils/Helper'
import { Link } from './styles'
import './styles.css'

interface iPDFProps {
  isVisible?: boolean
  uri?: string
}

export const PDFImersao: React.FC<iPDFProps> = ({ isVisible, uri }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const [numPages, setNumPages] = useState(1)
  const [page, setPage] = useState(1)
  const [IsLoading, setIsLoading] = useState(true)

  function onDocumentLoadSuccess(resto: any): any {
    console.log(resto)
    const { numPages } = resto
    setNumPages(numPages)
  }

  useEffect(() => {
    if (uri) {
      setIsLoading(false)
    }
    console.log(uri)
  }, [])

  if (!isVisible) {
    return null
  }

  return (
    <>
    <Flex direction={'column'}>
      <HStack justifyContent={'center'} mb={'1rem'}>
        <Button
          onClick={() => {
            if (page > 1) { setPage(page - 1) }
          }}
          bgColor={'gray.200'}
          color={'gray.800'}>
          {'<<'}
        </Button>
        <Text fontSize={'xl'} fontWeight='semibold' m='1'>{page} / {numPages}</Text>
        <Button
          onClick={() => {
            if (page < numPages) { setPage(page + 1) }
          }}
          bgColor={'gray.200'}
          color={'gray.800'}>
          {'>>'}
        </Button>
      </HStack>
      {!IsLoading && !PropInvalida(uri) && (<Document loading={<CircularProgress isIndeterminate color='green.300' width={'20rem'} />}
        file={uri}
        onLoadSuccess={onDocumentLoadSuccess}
        className={'react-pdf__Page__canvas2'}>
        <Page
          scale={1}
          key={`page_${1}`}
          pageNumber={page}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
        {/* {Array.from(new Array(numPages), (el, index) => (
          <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          />
        ))} */}
      </Document>)}
      <Flex justifyContent={'center'} margin={'0.75rem 0'} mb={'0'}>
          <p>Clique <Link href={uri}>aqui</Link> para realizar o download</p>
      </Flex>
    </Flex>
  </>
  )
}
