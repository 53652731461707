/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */

import { Flex, Input, InputGroup, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaEye, FaMobile, FaPaperPlane, FaPlus, FaQrcode, FaSearch } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { WhiteBox } from '../../../components/WhiteBox'
import { iGestaoConvite } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { CircleText } from './Components/CircleText'
import { ModalConvitePorApp } from './Components/ModalConvite'
import { ModalLinkAberto } from './Components/ModalLinkAberto'
import { TableConvites } from './Components/Table'

type iParamProps = {
  imersaoId: string
}

export const GestaoConvitesImersao: React.FC = () => {
  const toast = useToast()

  const param = useParams<iParamProps>()
  const nav = useNavigate()

  const [ModalAddApp, setModalAddApp] = useState(false)
  const [ModalLinkAbertoIsOpen, setModalLinkAbertoIsOpen] = useState(false)

  const [Model, setModel] = useState<iGestaoConvite>({} as iGestaoConvite)

  const [TxtPesquisa, setTxtPesquisa] = useState('')

  function getConvites(): void {
    appApi.get(`ImersaoExternaConvite/${param.imersaoId as string}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function ConvidarPoStatus(status: number): void {
    appApi.post(`ImersaoExternaConvite/${param.imersaoId as string}/Status?status=${status}`)
      .then(() => {
        getConvites()
        toast({
          title: 'Convites enviados com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }).catch(err => console.log(err))
  }

  function EnviarConvites(id: string): void {
    appApi.put('ImersaoExternaConvite/Enviar', [id])
      .then(() => {
        getConvites()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getConvites()
  }, [])

  return (
        <Body>
            {
                (ModalAddApp) && (
                    <ModalConvitePorApp
                        isOpen={ModalAddApp}
                        rows={Model.convites}
                        imersaoId={param.imersaoId as string}
                        onClose={() => setModalAddApp(false)}
                        Reload={getConvites}
                    />
                )
            }

            <ModalLinkAberto
                imersaoId={param.imersaoId as string}
                isOpen={ModalLinkAbertoIsOpen}
                onClose={() => setModalLinkAbertoIsOpen(false)}
            />

            <WhiteBox>
                <h1>Jornada {'>'} {Model.titulo}</h1>
                <Flex flexDirection={'column'} border={'1px solid var(--Azul)'} marginTop={'2rem'} borderRadius={'24px'} padding={'1rem'}>
                    <Flex justifyContent={'space-between'}>
                        <h3 style={{ color: 'var(--a3)' }}>Resumo de disparos</h3>
                        <Flex gap={'0rem 2rem'}>
                            <CircleText titulo={Model.countConviteUsuario?.convitesVisualizados + Model.linkAberto} fontsize='14px' varColorCircle='v1' varColorText='a1'>Visualizações</CircleText>
                            <CircleText titulo={(Model.countConviteUsuario?.convitesEnviados - Model.countConviteUsuario?.convitesVisualizados)} fontsize='14px' varColorCircle='c4' varColorText='a1'>Não visualizaram</CircleText>
                        </Flex>
                    </Flex>
                    <Flex marginTop={'1rem'} gap={'0rem 2rem'}>
                        <Flex width={'100%'} padding={'1rem'} flexDir={'column'} border={'1px solid var(--Gray4)'} borderRadius={'24px'}>
                            <Flex marginBottom={'1rem'} justifyContent={'center'}>
                                <Flex color={'var(--a4)'} flexDir={'column'} alignItems={'center'}>
                                    <FaMobile size={40} />
                                    <Text fontWeight={'bold'} style={{ marginTop: '.5rem' }}>Usuários</Text>
                                </Flex>
                                <Flex marginLeft={'.8rem'} gap={'.8rem 0rem'} flexDirection={'column'}>
                                    <CircleText
                                        fontsize='12px'
                                        varColorCircle='Azul'
                                        varColorText='a2'
                                        titulo={Model.countConviteUsuario?.disparos}
                                    >Disparos
                                    </CircleText>

                                    <CircleText
                                        fontsize='12px'
                                        varColorCircle='v1'
                                        varColorText='a2'
                                        titulo={Model.countConviteUsuario?.convitesVisualizados}
                                    >Visualizações
                                    </CircleText>

                                    <CircleText
                                        fontsize='12px'
                                        varColorCircle='c4'
                                        varColorText='a2'
                                        titulo={(Model.countConviteUsuario?.convitesEnviados - Model.countConviteUsuario?.convitesVisualizados)}
                                    >Não visualizaram
                                    </CircleText>
                                </Flex>
                            </Flex>
                            <Flex justifyContent={'center'}>
                                <Button
                                    size={'md'}
                                    VarColor={'Green2'}
                                    leftIcon={<FaPlus />}
                                    onClick={() => setModalAddApp(true)}
                                >Adicionar convite</Button>
                            </Flex>
                        </Flex>

                        <Flex width={'100%'} padding={'1rem'} flexDir={'column'} border={'1px solid var(--Gray4)'} borderRadius={'24px'}>
                            <Flex marginBottom={'1rem'} justifyContent={'center'}>
                                <Flex color={'var(--a4)'} flexDir={'column'} alignItems={'center'} marginRight={'1.5rem'}>
                                    <FaQrcode size={40} />
                                    <Text fontWeight={'700'} style={{ marginTop: '.5rem' }}>Link aberto</Text>
                                </Flex>
                                <CircleText titulo={Model.linkAberto} fontsize='14px' varColorCircle='v1' varColorText='a1'>Visualizações</CircleText>
                            </Flex>
                            <Flex justifyContent={'center'} alignItems={'end'} height={'100%'}>
                                <Button onClick={() => setModalLinkAbertoIsOpen(true)} size={'md'} VarColor={'Blue2'} leftIcon={<FaEye color='white' />}>Visualizar link</Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>

                {
                    (Model.convites?.length > 0) && (
                        <>
                            <Flex justifyContent={'space-between'} margin={'2rem 0rem'}>
                                <h3>Lista de convidados</h3>
                                <InputGroup width={'30%'}>
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<FaSearch color='var(--Gray4)' />}
                                    />
                                    <Input
                                        onChange={(e) => setTxtPesquisa(e.target.value)}
                                        borderColor={'var(--Gray4)'}
                                        type='text'
                                        placeholder='Buscar participante'
                                    />
                                </InputGroup>
                            </Flex>
                            <TableConvites
                                rows={Model.convites.filter((e) => {
                                  if (TxtPesquisa === '') return e
                                  else if (e.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) return e
                                  return null
                                })}
                                onCheck={() => { }}
                                onSend={EnviarConvites}
                            />
                        </>
                    )
                }
                <Flex margin={'1rem 0'} justifyContent={'end'}>
                    <Button onClick={() => nav(-1)} leftIcon={<FaAngleLeft />} size={'lg'} VarColor='c4'>Cancelar</Button>
                    <Button
                        leftIcon={<FaPaperPlane />}
                        size={'lg'} margin={'0 1rem'}
                        VarColor='Green2'
                        onClick={() => ConvidarPoStatus(1)}
                        isDisabled={Model.convites?.length === 0}
                    >Disparar não visualizados</Button>

                    <Button
                        leftIcon={<FaPaperPlane />}
                        size={'lg'} VarColor='Green2'
                        onClick={() => ConvidarPoStatus(0)}
                        isDisabled={Model.convites?.length === 0}
                    >Disparar novos</Button>
                </Flex>
            </WhiteBox>
        </Body>
  )
}
