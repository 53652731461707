import { Body } from '../../Layouts/Body'
import { CardParticipante } from './Components/CardParticipante'
import { OutlineGreyBox } from '../components/OutlineGreyBox'
import { AlertText, InfoBox, GridColumn } from './styles'
import { ModalAdicionarAvaliado } from './Components/ModalAdicionarAvaliado'
import { ModalCadastroEmMassa } from './Components/ModalCadastroEmMassa'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { iAvaliado, iPostAvaliador, iPostColaborador } from '../../../interfaces'
import { ModalAddParticipante } from './Components/ModalAddParticipante'
import { MetaButton } from '../../../components/Buttons/MetaButton'
import { Flex, Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { CreateColaboradorModal } from '../../../components/ModalCreateColaborador'
import { ModalDelete } from '../../../components/ModalDelete'
import { PropInvalida } from '../../../Utils/Helper'
import { ModalAdicionarPorSquad } from './Components/ModalAdicionarPorSquad'
import { FaSearch } from 'react-icons/fa'

type navProps = {
  pesquisaId: string
}

interface ISetAvaliador {
  avaliacaoId: string
  avaliadorId: string
}
interface iModel {
  nomePesquisa: string
  pesquisaIniciada: boolean
  ciclo?: string
  avaliados: iAvaliado[]
  formularioAprovado: boolean
  enviado: boolean
}
interface iConvite {
  dataInicio?: string
  dataFim?: string
  textoEmail: string
  textoAbertura: string
  textoEncerramento: string
  encerrarAutomaticamente: boolean
  tipo: number
  ciclo?: number
  validade?: number
  baseCiclo?: number
  reenviarDias: number[]
  pdi: iPDIPesquisa
  textosAbertura: iTextosPesquisa[]
  textosConvite: iTextosPesquisa[]
  textosEncerramento: iTextosPesquisa[]
}
interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

interface iPDIPesquisa {
  dias: number
  destinatarioEmail: number
  notificacaoCadastro: number[]
  responsavelId?: string
  notificadoId?: string
  textoCriacao: string
  pdiGerado?: boolean
}

export const PesquisaParticipantes: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { pesquisaId } = useParams<navProps>()
  const { pathname } = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [Convite, setConvite] = useState<iConvite>()

  const [Avaliador, setAvaliador] = useState<ISetAvaliador>()

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalDeleteAvaliacaoIsOpen, setModalDeleteAvaliacaoIsOpen] = useState(false)
  const [ModalAddAvaliadoIsOpen, setModalAddAvaliadoIsOpen] = useState(false)
  const [ModalAddMassaIsOpen, setModalAddMassaIsOpen] = useState(false)
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalAddParticipanteIsOpen, setModalAddParticipanteIsOpen] = useState(false)

  const [ModalAddSquadIsOpen, setModalAddSquadIsOpen] = useState(false)
  const [Tipo, setTipo] = useState<number>(0)
  const [AvaliacaoId, setAvaliacaoId] = useState<string>('')

  const [Search, setSearch] = useState('')

  const [Model, setModel] = useState<iModel>({
    avaliados: [],
    pesquisaIniciada: false,
    nomePesquisa: '',
    formularioAprovado: false,
    enviado: false
  })

  function onCreateColaborador(form: iPostColaborador): void {
    appApi.post('Colaborador', form).then(res => {
      setModalCreateIsOpen(false)
      console.log(res.data)
      const form = {
        pesquisaId: pesquisaId as string,
        colaboradorId: res.data
      }

      appApi.post('AvaliacaoPesquisa', form).then(() => {
        getAvalidos()
        toast({
          title: 'Avaliado adicionado com sucesso',
          position: 'top-right',
          status: 'success',
          duration: 2000,
          isClosable: false
        })
      }
      ).catch(err => console.log(err))
    }).catch(err => console.log(err))
  }

  function getAvalidos(): void {
    appApi.get(`AvaliacaoPesquisa/Pesquisa/${pesquisaId as string}`)
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        } else {
          setModel({
            avaliados: [],
            nomePesquisa: '',
            pesquisaIniciada: false,
            formularioAprovado: false,
            enviado: false
          })
        }
      })
      .catch(err => console.log(err))
  }

  function DeleteAvaliacao(): void {
    appApi.delete(`AvaliacaoPesquisa/${AvaliacaoId}`)
      .then(() => getAvalidos())
      .catch(err => console.log(err))
  }

  function DeleteAvaliador(): void {
    appApi.delete(`AvaliacaoPesquisa/${Avaliador?.avaliacaoId ?? ''}/avaliador/${Avaliador?.avaliadorId ?? ''}`)
      .then(() => getAvalidos())
      .catch(err => console.log(err))
  }

  function CreateAvaliador(form: iPostAvaliador): void {
    form.TipoAvaliador = Tipo
    appApi.post(`AvaliacaoPesquisa/${AvaliacaoId}/avaliador`, form)
      .then(() => {
        getAvalidos()
        onCloseModalAddParticipante()
      })
      .catch(({ response }) => {
        console.log(response)
        onCloseModalAddParticipante()
        toast({
          title: response.data,
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })
      })
  }

  function ReenviarConviteAvaliador(avaliacaoId: string, avaliadorId?: string): void {
    appApi.post(`AvaliacaoPesquisa/${avaliacaoId}/Reenviar?avaliadorId=${avaliadorId ?? ''}`)
      .then(() => {
        toast({
          title: 'Reenviado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
        onCloseModalAddParticipante()
      })
      .catch(() => {
        toast({
          title: 'Erro',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
      })
  }

  function AddAvaliacaoPorNome(avaliadoId: string, squadId?: string): void {
    if (Model?.avaliados.map(e => e.colaboradorId).includes(avaliadoId)) {
      toast({
        title: 'Avaliado já adicionado',
        position: 'top-right',
        status: 'warning',
        duration: 2000,
        isClosable: false
      })
    } else {
      const form = {
        pesquisaId: pesquisaId as string,
        colaboradorId: avaliadoId
      }

      appApi.post(`AvaliacaoPesquisa?squadId=${squadId ?? ''}`, form).then(() => {
        getAvalidos()
        toast({
          title: 'Avaliado adicionado com sucesso',
          position: 'top-right',
          status: 'success',
          duration: 2000,
          isClosable: false
        })
      }
      ).catch(err => console.log(err))
    }
  }

  function onOpenModalAddParticipante(tipo: number, avaliacaoId: string): void {
    setModalAddParticipanteIsOpen(true)
    setTipo(tipo)
    setAvaliacaoId(avaliacaoId)
  }

  function onCloseModalAddParticipante(): void {
    setModalAddParticipanteIsOpen(false)
    setTipo(0)
    setAvaliacaoId('')
  }

  function onOpenModalDeletarAvaliacao(id: string): void {
    setModalDeleteAvaliacaoIsOpen(true)
    setAvaliacaoId(id)
  }

  function onOpenModalDeleteAvaliador(avaliacaoId: string, avaliadorId: string): void {
    setModalDeleteIsOpen(true)
    setAvaliador({
      avaliacaoId: avaliacaoId,
      avaliadorId: avaliadorId
    })
  }

  function onRequestModalDeleteClose(): void {
    setModalDeleteIsOpen(false)
    setAvaliador(undefined)
    setAvaliacaoId('')
  }

  function onRequestModalDeleteAvaliacaoClose(): void {
    setModalDeleteAvaliacaoIsOpen(false)
    setAvaliacaoId('')
  }

  function CriarPorSquad(squadId: string, opcao: string): void {
    console.log(squadId, opcao)
    appApi.post(`AvaliacaoPesquisa/Squad?pesquisaId=${pesquisaId ?? ''}&squadId=${squadId}&tipoHierarquia=${opcao}`)
      .then(() => {
        getAvalidos()
        setModalAddSquadIsOpen(false)
      })
      .catch(() => {
        toast({
          title: 'Há campos sem preenchimento',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
      })
  }

  useEffect(() => {
    getAvalidos()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        onRequestClose={onRequestModalDeleteClose}
        onConfirm={DeleteAvaliador}
        message={'Deseja realmente excluir este avaliador?'}
      />

      <ModalDelete
        isOpen={ModalDeleteAvaliacaoIsOpen}
        onRequestClose={onRequestModalDeleteAvaliacaoClose}
        onConfirm={DeleteAvaliacao}
        message={'Deseja realmente excluir?'}
      />
      {
        (ModalAddParticipanteIsOpen) && (
          <ModalAddParticipante
            isOpen={ModalAddParticipanteIsOpen}
            onConfirm={CreateAvaliador}
            onRequestClose={onCloseModalAddParticipante}
            iniciada={Model.pesquisaIniciada}
          />
        )
      }

      {
        (ModalAddSquadIsOpen) && (
          <ModalAdicionarPorSquad
            isOpen={ModalAddSquadIsOpen}
            onConfirm={CriarPorSquad}
            onRequestClose={() => setModalAddSquadIsOpen(false)}
          />
        )
      }

      {
        (ModalCreateIsOpen) && (
          <CreateColaboradorModal
            isOpen={ModalCreateIsOpen}
            onCreateEmployee={onCreateColaborador}
            onRequestClose={() => setModalCreateIsOpen(false)}
          />
        )
      }

      <ModalAdicionarAvaliado
        isOpen={ModalAddAvaliadoIsOpen}
        onConfirm={AddAvaliacaoPorNome}
        onRequestClose={() => setModalAddAvaliadoIsOpen(false)}
      />

      <ModalCadastroEmMassa
        onRequestClose={() => setModalAddMassaIsOpen(false)}
        isOpen={ModalAddMassaIsOpen}
      />

      {
        (!pathname.includes('Processo')) && (
          <>
            <Flex justifyContent={'space-between'} flexWrap='wrap' marginTop={'1rem'}>
              <Flex flexDir={'column'}>
                <h2 style={{ color: 'var(--a3)' }}>Selecione os participantes de sua pesquisa</h2>
                <Flex gap={'1rem'} marginTop={'.5rem'}>
                  <MetaButton size='md' bg='Azul' onClick={() => setModalAddAvaliadoIsOpen(true)}>Adicionar avaliados</MetaButton>
                  <MetaButton size='md' bg='Azul' onClick={() => setModalAddSquadIsOpen(true)}>Adicionar por squad</MetaButton>
                  <MetaButton size='md' bg='Azul' onClick={() => setModalCreateIsOpen(true)}>Adicionar colaborador</MetaButton>
                </Flex>
              </Flex>
              <InfoBox>
                <span>{Model.avaliados?.length}</span>
                <h3>Avaliados adicionados</h3>
              </InfoBox>
            </Flex>
          </>
        )
      }

      {
        (!pathname.includes('Processo')) && (
          <h2 style={{ color: 'var(--a3)', marginBottom: '.5rem' }}
          >Participantes selecionados</h2>
        )
      }

      {
        (pathname.includes('Processo')) && (
          <h2 style={{ color: 'var(--a3)', marginBottom: '.5rem' }}
          >Pesquisa: {Model.nomePesquisa}</h2>
        )
      }
      <OutlineGreyBox>
        {
          (Model.avaliados?.length === 0) && (
            <Flex justifyContent={'center'}>
              <AlertText>Nenhum participante selecionado</AlertText>
            </Flex>
          )
        }

        {
          (Model.avaliados?.length > 0) && (
            <GridColumn>
              <Flex>
                <InputGroup width={'17rem'}>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<FaSearch color='var(--Gray4)' />}
                  />
                  <Input
                    onChange={(e) => setSearch(e.target.value)}
                    borderColor={'var(--Gray4)'}
                    type='text'
                    placeholder='Digite o nome do avaliado'
                    bg={'white'}
                  />
                </InputGroup>
              </Flex>
              {
                Model.avaliados.filter((e) => {
                  if (Search === '') {
                    return e
                  } else if (e.nome.toLowerCase().includes(Search.toLowerCase())) {
                    return e
                  } else {
                    return null
                  }
                }).map((e: iAvaliado) => {
                  return (
                    <CardParticipante
                      key={e.id}
                      ciclica={!PropInvalida(Model.ciclo)}
                      enviado={Model.enviado}
                      Avaliacao={e}
                      onDeleteAvaliacao={onOpenModalDeletarAvaliacao}
                      onDeleteAvaliador={onOpenModalDeleteAvaliador}
                      onOpenModal={onOpenModalAddParticipante}
                      onReenviarAvaliador={ReenviarConviteAvaliador}
                    />
                  )
                })
              }
            </GridColumn>
          )
        }
      </OutlineGreyBox>
      <Flex marginTop={'1rem'} justifyContent={'end'}>
        <Button marginRight={'1rem'} onClick={() => navigate(-1)} VarColor='c5' size={'lg'}>Voltar</Button>
        {
          (!pathname.includes('Processo') && PropInvalida(Model.ciclo) && Model.avaliados?.length > 0 && Model.formularioAprovado) && (
            <Button onClick={() => navigate(`${pathname.replace('Participantes', 'EnviarPesquisa')}`)} size={'lg'} VarColor='v2'>Próximo</Button>
          )
        }

        {
          (pathname.includes('Processo') || !PropInvalida(Model.ciclo)) && (
            <Button onClick={() => navigate(`${pathname.replace('Participantes', 'EnviarPesquisa')}/Editar`)} size={'lg'} VarColor='v2'>Editar envio</Button>
          )
        }
      </Flex>
    </Body>
  )
}
