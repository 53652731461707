/* eslint-disable array-callback-return */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-tabs */
import { Avatar, Box, Flex, FormControl, FormLabel, Text, Textarea, useToast } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { WhiteBox } from '../../../components/WhiteBox'
import { DeleteElementByPosition, UpdateElementByPosition } from '../../../Utils/Helper'
import { Button } from '../../../components/Button'
import { FaChevronRight, FaFrown, FaHeart, FaMedal, FaTools } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { ModalConfirm } from './components/ModalConfirm'
import { appApi } from '../../../services/appApi'
import { iRespostaEscala } from '../../../interfaces'

interface iPesquisa {
  nome: string
  id: string
  questoes: iQuestao[]
  questoesIndicativa: iQuestao[]
  questoesIndicativaAutoAvaliacao: iQuestao[]
  autoAvaliacao: boolean
  maximoCompetencias: number
  avaliado: iAvaliado
  comentario: boolean
  avaliacaoId?: string
  aparecerNomeCompetencia: boolean
}

interface iQuestao {
  competencia: string
  nome: string
  id: string
  resposta?: number
  autoAvaliacao?: boolean
}

interface iAvaliado {
  nome: string
  area: string
  avatar: string
}

interface iResposta {
  questaoId: string
  respostaId: string
  autoAvaliacao: boolean
}

interface iRespostaDiscursiva {
  questaoId?: string
  observacao: string
}

interface iFormQuestao extends iQuestao {
  isError: boolean
  observacao?: string
}

interface iPostResposta {
  respostas: iResposta[]
  respostasDiscursivas?: iRespostaDiscursiva[]
}

interface LocationProps {
  tokenId: string
  idioma?: string
}

export const RespostaIndicativa: React.FC = () => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  const toast = useToast()

  const [Block, setBlock] = useState<boolean>(false)
  const [Questoes, setQuestoes] = useState<iFormQuestao[]>([])
  const [Respostas, setRespostas] = useState<iResposta[]>([])
  const [Discursiva, setDiscursiva] = useState<iRespostaDiscursiva[]>([])
  const [NomePesquisa, setNomePesquisa] = useState('')
  const [NomeAvaliado, setNomeAvaliado] = useState('')
  const [ExibirCompetencia, setExibirCompetencia] = useState(false)
  const [isRespondido, setisRespondido] = useState(false)
  const [TxtEncerramento, setTxtEncerramento] = useState('')
  const [Obrigatorias, setObrigatorias] = useState<string[]>([])
  const [LogoEmpresa, setLogoEmpresa] = useState<string>('')
  const [Justificativa, setJustificativa] = useState(false)
  const [EscalaRespostas, setEscalaRespostas] = useState<iRespostaEscala[]>([])
  const [Estagio, setEstagio] = useState(1)
  const [Comentario, setComentario] = useState('')
  const [ModalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)
  const [Model, setModel] = useState<iPesquisa>({
    nome: '',
    id: '',
    questoes: [],
    questoesIndicativa: [],
    questoesIndicativaAutoAvaliacao: [],
    autoAvaliacao: false,
    comentario: false,
    maximoCompetencias: 0,
    avaliado: { area: '', nome: '', avatar: '' },
    aparecerNomeCompetencia: false
  })
  const { tokenId, idioma } = useLocation().state as LocationProps

  console.log(Model)

  const { pesquisaId } = useParams<{ pesquisaId: string }>()

  function ToggleResposta(quest: iQuestao, resp: number, auto: boolean): void {
    const respostaId = EscalaRespostas.find((e) => e.peso === resp && (auto ? e.autoAvaliacao === auto : e.autoAvaliacao === null))?.id
    console.log({ quest, resp, auto })

    if (IsSelecionada(quest, auto, resp)) {
      const posQuestao = Respostas.findIndex(e => e.respostaId === respostaId && e.questaoId === quest.id && e.autoAvaliacao === auto)
      if (posQuestao !== -1) {
        setRespostas(DeleteElementByPosition([...Respostas], posQuestao))
      }
      setBlock(false)
    } else if (BlockSelecao(auto, resp)) {
      console.log(':)')
    } else if (IsSelecionada(quest, auto)) {
      const posQuestao = Respostas.findIndex(e => e.respostaId !== respostaId && e.questaoId === quest.id && e.autoAvaliacao === auto)
      if (posQuestao !== -1) {
        setRespostas([...DeleteElementByPosition([...Respostas], posQuestao), {
          autoAvaliacao: auto,
          questaoId: quest.id,
          respostaId: respostaId ?? ''
        }])
      }
    } else {
      setRespostas([...Respostas, {
        autoAvaliacao: auto,
        questaoId: quest.id,
        respostaId: respostaId ?? ''
      }])
    }
  }

  function IsSelecionada(quest: iQuestao, auto: boolean, resp?: number): boolean {
    if (resp) {
      const respostaId = EscalaRespostas.find((e) => e.peso === resp && (auto ? e.autoAvaliacao === auto : e.autoAvaliacao === null))?.id

      const respostaSimples = Respostas.find(e => e.respostaId === respostaId && e.questaoId === quest.id && e.autoAvaliacao === auto)

      return respostaSimples !== undefined
    } else {
      const respostaSimples = Respostas.find(e => e.questaoId === quest.id && e.autoAvaliacao === auto)

      return respostaSimples !== undefined
    }
  }

  function BlockSelecao(auto: boolean, resp: number): boolean {
    const respostaId = EscalaRespostas.find((e) => e.peso === resp && (auto ? e.autoAvaliacao === auto : e.autoAvaliacao === null))?.id

    const respostasSelecionadas = Respostas.filter(e => EscalaRespostas.some(r => r.id === e.respostaId))

    if (respostasSelecionadas.filter(e => e.respostaId === respostaId).length >= Model.maximoCompetencias) {
      setBlock(true)
      toast({
        title: `Não é possível selecionar mais de ${Model.maximoCompetencias} competências para destaque ou desenvolvimento. Desmarque uma para que você possa selecionar esta.`,
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return true
    }
    setBlock(false)
    return false
  }

  function getFormulario(): void {
    appApi.get(`TokenPesquisa/${pathname.includes('RespostaPesquisa') ? `PreenchimentoIndicativa/${tokenId}` : `Previsualizar/${pesquisaId ?? ''}`}`)
      .then(resp => {
        setModel(resp.data)
        setQuestoes(resp.data.questoes)
        setEscalaRespostas(resp.data.respostas)
        setNomePesquisa(resp.data.nomePesquisa)
        setTxtEncerramento(resp.data.txtEncerramento)
        setObrigatorias(resp.data.obrigatorias)
        setLogoEmpresa(resp.data.logoEmpresa)
        setNomeAvaliado(resp.data.nomeAvaliado)
        setJustificativa(resp.data.justificativa)
        setExibirCompetencia(resp.data.exibirCompetencia)
      }).catch(() => setisRespondido(true))
  }

  function responderPesquisa(): void {
    const PostRespostas: iPostResposta = {
      respostas: Respostas,
      respostasDiscursivas: Discursiva
    }

    if (Model.comentario && Comentario.length < 5) {
      toast({
        title: 'O comentário deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Respostas?.length !== Model.maximoCompetencias * (Model.autoAvaliacao ? 4 : 2)) {
      toast({
        title: 'Responda todas as perguntas',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    setModalConfirmIsOpen(true)

    const promises = []

    promises.push(appApi.post(`RespostaPesquisa/Responder/${tokenId}`, PostRespostas))

    Promise.all(promises)
      .then(() => {
        toast({
          title: 'Obrigado por responder a pesquisa!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        nav('/Colaborador')
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function AvancarResposta(estagio: number): void {
    if (Respostas?.length !== Model.maximoCompetencias * 2) {
      toast({
        title: 'Responda todas as perguntas',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    setEstagio(estagio)
  }

  function AdicionarComentario(texto: string): void {
    setDiscursiva([{ observacao: texto }])
    setComentario(texto)
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <Body>
      <ModalConfirm
        isOpen={ModalConfirmIsOpen}
        onRedirect={() => setModalConfirmIsOpen(false)}
      />
      <WhiteBox>
        <h1>{Model.nome}</h1>
        <Flex gap={'1rem'} my={'1.5rem'}>
          <Avatar size={'lg'} src={Model?.avaliado?.avatar} />
          <Box color={'var(--a4)'}>
            <Text>Avaliado</Text>
            <Text my={'-.6rem'} fontWeight={'700'} fontSize={'22px'}>{Model?.avaliado?.nome}</Text>
            <Text>{Model?.avaliado?.area}</Text>
          </Box>
        </Flex>
        {(Estagio === 1 || Estagio === 2) &&
          <Box fontSize={'16px'} fontWeight={'600'}>
            <Text onClick={() => console.log(Respostas)} mb={'1rem'}>Na lista das competências abaixo, escolha:</Text>
            <Text display={'flex'} alignItems={'center'}>{Model.maximoCompetencias} competências que você considera que são os maiores destaques positivos do avaliado ({(Model.autoAvaliacao && Estagio === 2) ? <FaHeart style={{ color: 'var(--Azul)' }} /> : <FaMedal style={{ color: 'var(--Green2)' }} />})</Text>
            <Text display={'flex'} alignItems={'center'}>e {Model.maximoCompetencias} competências que você considera que o avaliado tem mais necessidade de desenvolvimento ({(Model.autoAvaliacao && Estagio === 2) ? <FaFrown style={{ color: '#66046B' }} /> : <FaTools style={{ color: '#66046B' }} />})</Text>
          </Box>}
        {(Estagio === 1) && Model.questoesIndicativa?.map((e, i) => {
          return (
            <>
              <Box
                key={e.id}
                boxShadow={'0 4px 4px #00000050'}
                my={'1rem'} borderRadius={'1.25rem'}
                gap={'1rem'} p={'1rem'} pr={'2rem'} display={'flex'} alignItems={'center'}>
                {Estagio === 1 && (
                  <Box
                    onClick={() => ToggleResposta(e, 1, false)}
                    bgColor={IsSelecionada(e, false, 1) ? 'var(--Green2)' : '#fff'}
                    boxShadow={IsSelecionada(e, false, 1) ? '0 4px 6px #00000050' : ''}
                    display={'flex'}
                    alignItems={'center'}
                    padding={'.3rem'}
                    fontSize={'20px'}
                    borderRadius={'50%'}
                  >

                    <FaMedal style={{ color: IsSelecionada(e, false, 1) ? '#fff' : 'var(--Green2)' }} />
                  </Box>
                )}
                {Estagio === 1 && (
                  <Box
                    onClick={() => ToggleResposta(e, -1, false)}
                    bgColor={IsSelecionada(e, false, -1) ? '#66046B' : '#fff'}
                    boxShadow={IsSelecionada(e, false, -1) ? '0 4px 6px #00000050' : ''}
                    display={'flex'}
                    alignItems={'center'}
                    padding={'.3rem'}
                    fontSize={'20px'}
                    borderRadius={'50%'}
                  >
                    <FaTools style={{ color: IsSelecionada(e, false, -1) ? '#fff' : '#66046B' }} />
                  </Box>
                )}
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                  {Model.aparecerNomeCompetencia && (
                    <Text fontSize={'12px'} fontWeight={'400'}>{e.competencia}</Text>
                  )}
                  <Text fontSize={'18px'} fontWeight={'600'}>{e.nome}</Text>
                </Box>
              </Box>
            </>
          )
        })}

        {(Estagio === 2) && Model.questoesIndicativaAutoAvaliacao?.map((e, i) => {
          return (
            <>
              <Box key={e.id} backgroundColor={'var(--Gray6)'} boxShadow={'0 4px 4px #00000050'} my={'1rem'} borderRadius={'1.25rem'} gap={'1rem'} p={'1rem'} pr={'2rem'} display={'flex'} alignItems={'center'}>
                {Estagio === 2 && (
                  <Box
                    onClick={() => ToggleResposta(e, 1, true)}
                    bgColor={IsSelecionada(e, true, 1) ? 'var(--Azul)' : '#fff'}
                    boxShadow={IsSelecionada(e, true, 1) ? '0 4px 6px #00000050' : ''}
                    display={'flex'}
                    alignItems={'center'}
                    padding={'.3rem'}
                    fontSize={'20px'}
                    borderRadius={'50%'}>
                    <FaHeart style={{ color: IsSelecionada(e, true, 1) ? '#fff' : 'var(--Azul' }} />
                  </Box>
                )}
                {Estagio === 2 && (
                  <Box
                    onClick={() => ToggleResposta(e, -1, true)}
                    bgColor={IsSelecionada(e, true, -1) ? '#66046B' : '#fff'}
                    boxShadow={IsSelecionada(e, true, -1) ? '0 4px 6px #00000050' : ''}
                    display={'flex'}
                    alignItems={'center'}
                    padding={'.3rem'}
                    fontSize={'20px'}
                    borderRadius={'50%'}>
                    <FaFrown style={{ color: IsSelecionada(e, true, -1) ? '#fff' : '#66046B' }} />
                  </Box>
                )}

                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                  {Model.aparecerNomeCompetencia && (
                    <Text fontSize={'12px'} fontWeight={'400'}>{e.competencia}</Text>
                  )}
                  <Text fontSize={'18px'} fontWeight={'600'}>{e.nome}</Text>
                </Box>
              </Box>
            </>
          )
        })}

        {Block && (
          <>
            <Text color={'var(--Red)'}>Não é possível selecionar mais de {Model.maximoCompetencias} competências para destaque ou desenvolvimento. Desmarque uma para que você possa selecionar esta.</Text>
          </>
        )}
        {Estagio === 3 && Model.comentario && (
          <FormControl>
            <FormLabel>Deixe um comentário para auxiliar no desenvolvimento do avaliado:</FormLabel>
            <Textarea h={'8.725rem'} placeholder='Digite seu comentário' onChange={(e) => AdicionarComentario(e.target.value)} />
          </FormControl>
        )}
        <Flex justifyContent={'end'} mt={'1.5rem'} gap={'1rem'}>

          {(Estagio === 1 && !Model.autoAvaliacao) && <Button
            h={'3rem'}
            onClick={() => {
              pathname.includes('Previsualizar') ? nav(-1) : Model.comentario ? AvancarResposta(3) : responderPesquisa()
            }}
            size={'lg'}
            VarColor={'v2'}
            rightIcon={<FaChevronRight />}
          >{pathname.includes('Previsualizar') ? 'Finalizar pré-visualização' : Model.comentario ? 'Continuar' : 'Finalizar e responder'}</Button>}

          {(Estagio === 1 && Model.autoAvaliacao) && <Button
            h={'3rem'}
            onClick={() => {
              AvancarResposta(2)
            }}
            size={'lg'}
            VarColor={'v2'}
            rightIcon={<FaChevronRight />}
          >{'Continuar'}</Button>}

          {(Estagio === 2 && Model.autoAvaliacao) && <Button
            h={'3rem'}
            onClick={() => {
              pathname.includes('Previsualizar') ? nav(-1) : Model.comentario ? setEstagio(3) : responderPesquisa()
            }}
            size={'lg'}
            VarColor={'v2'}
            rightIcon={<FaChevronRight />}
          >{pathname.includes('Previsualizar') ? 'Finalizar pré-visualização' : Model.comentario ? 'Continuar' : 'Finalizar e responder'}</Button>}

          {(Estagio === 3 && Model.comentario) &&
            <>
              <Button h={'3rem'} VarColor='Gray3' onClick={() => { Model.autoAvaliacao ? setEstagio(2) : setEstagio(1) }}>
                Voltar
              </Button>
              <Button
                h={'3rem'}
                onClick={() => {
                  pathname.includes('Previsualizar') ? nav(-1) : responderPesquisa()
                }}
                size={'lg'}
                VarColor={'v2'}
                rightIcon={<FaChevronRight />}
              >Finalizar e responder</Button>
            </>}
        </Flex>
      </WhiteBox>
    </Body>
  )
}
