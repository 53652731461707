import styled from 'styled-components'

export const BoxProxReuniao = styled.div`
  padding: 1rem;
  border-radius: 1.5rem;
  border: 1px solid var(--Azul);
  display: flex;
  align-items: center;
  .gradText{
  background: var(--DegradeAzul);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  }
`
export const BoxAzul = styled.div`
  background-color: var(--Azul);
  color: #fff;
  padding: 1rem 2rem 1rem 2rem;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
export const OutlineListaContainer = styled.div`
  border: 1px solid var(--Azul);
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 1.5rem;
`
