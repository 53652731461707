import { Flex, FormControl, FormLabel, Select, SelectProps } from '@chakra-ui/react'

interface iFormProps extends SelectProps {
  label: string
  rightAction?: React.ReactNode
  actionArea?: React.ReactNode
  actionAreaShow?: boolean
}

export const SelectFormControl: React.FC<iFormProps> = ({ label, children, rightAction, actionArea, actionAreaShow, ...rest }) => {
  return (
    <FormControl width={'100%'} marginRight={'1rem'}>
      {rightAction && (
<Flex direction={'column'}>

<Flex alignItems={'center'} mb={'1rem'}>
  <FormLabel mb={0} mr={'1rem'} color={'var(--Gray1)'} fontSize={'xl'}>{label}</FormLabel>
{rightAction}
</Flex>
{actionAreaShow && actionArea}
</Flex>

      )}
      {!rightAction && (
      <FormLabel color={'var(--Gray1)'} fontSize={'xl'}>{label}</FormLabel>
      )}
      <Select {...rest}>
        {children}
      </Select>
    </FormControl>
  )
}
