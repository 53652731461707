import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'

export const Nome = styled.span`
color: var(--a5);
margin-bottom: .5rem;
`

export const Sobrenome = styled.span`
color: var(--c7);
`

export const Container = styled(Flex)`
border-radius: 0.2rem;
&:hover{
    filter: brightness(.9);
    cursor: pointer;
}
`
