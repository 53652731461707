import { ButtonProps } from '@chakra-ui/button'
import { FaFileCsv } from 'react-icons/fa'
import { Button } from '../../Button'

export const CsvButton: React.FC<ButtonProps> = ({ ...rest }) => {
  return (
    <Button {...rest} VarColor='Azul' px={'1.5rem'} fontSize={'12px'} pt={'.1rem'} leftIcon={<FaFileCsv />}>
      Baixar CSV
    </Button>
  )
}
