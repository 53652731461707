import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { BoxEscala } from '../../../components/BoxEscala'
import { WhiteBox } from '../../../components/WhiteBox'
import { iEscala, iEtapa } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Main, StyledLabel } from './styles'
import { Checkbox, Flex, FormControl, FormLabel, Input, Stack, useToast } from '@chakra-ui/react'
import { ValidarEtapa } from '../../../Utils/Helper'
import { CreateEtapaBox } from '../../../components/CreateEtapaBox'
import { Button } from '../../../components/Button'

interface iPesquisaPercepcao {
  nomePesquisa: string
  escalaId: string
  participantes: number[]
}

type iLocationProps = {
  trilhaId: string
  processoId: string
  colaboradorId: string
  etapaId: string
}

export const CriarPesquisaQuantitativa: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()
  const path = useLocation().pathname
  const { trilhaId, processoId, etapaId, colaboradorId } = useParams<iLocationProps>()

  const ctDefault = path.includes('Desempenho') ? 'AnaliseDesempenho' : path.includes('Percepcao') ? 'Percepcao' : ''
  const trilha = path.includes('Trilha') ? `Trilha/${trilhaId as string}` : path.includes('Processo') ? `Processo/${colaboradorId as string}` : undefined

  const [Pesquisa, setPesquisa] = useState<iPesquisaPercepcao>({
    nomePesquisa: '',
    escalaId: '',
    participantes: []
  })

  const [Etapa, setEtapa] = useState<iEtapa>({})

  const urlPai = processoId ? 'PesquisaPercepcaoEtapaColaborador' : 'PesquisaPercepcaoEtapa'

  const [isValid, setisValid] = useState(true)
  const [Escalas, setEscalas] = useState<iEscala[]>([])
  const [TemResposta, setTemResposta] = useState(false)

  const handleCheckboxParticipanteClick = (value: number): void => {
    if (Pesquisa.participantes.find(e => e === value) !== undefined) {
      if (value === 7) {
        setPesquisa({ ...Pesquisa, participantes: [] })
      } else {
        const copy = [...Pesquisa.participantes].filter(e => e !== value)
        setPesquisa({ ...Pesquisa, participantes: copy })
      }
    } else {
      if (value === 7) {
        setPesquisa({ ...Pesquisa, participantes: [0, 1, 2, 3, 4, 6, 7] })
      } else {
        const copy = [...Pesquisa.participantes]
        copy.push(value)
        setPesquisa({ ...Pesquisa, participantes: copy })
      }
    }
  }

  const isRadioEscalaSelected = (value: string): boolean => Pesquisa.escalaId === value

  function criarPesquisa(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.participantes.length === 0) {
      toast({
        title: 'Selecione no mínimo um stakeholder',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.escalaId === '') {
      toast({
        title: 'Selecione uma escala para esta pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    appApi.post(`Pesquisa${ctDefault}`, Pesquisa)
      .then(response => {
        nav(`${path.replace('Criar', 'Formularios')}/${response.data as string}`)
      }).catch(({ response }) => {
        console.log(response)
        toast({
          title: response.data,
          status: 'error',
          position: 'top-right',
          isClosable: false
        })
      })
  }

  function CreateEtapaComposta(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.participantes.length === 0) {
      toast({
        title: 'Selecione no mínimo um stakeholder',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Pesquisa.escalaId === '') {
      toast({
        title: 'Selecione uma escala para esta pesquisa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Pesquisa,
      etapa: Etapa
    }

    appApi.post(`${urlPai}/${trilhaId ?? processoId ?? ''}`, form)
      .then(res => {
        nav(`/Rh/PesquisaPercepcao/Formularios/${res.data as string}${trilha !== undefined ? `/${trilha}` : ''}`)
      })
      .catch(err => console.log(err))
  }

  function getEscalas(): void {
    appApi.get('Escala')
      .then(res => setEscalas(res.data))
      .catch(err => console.log(err))
  }

  function getById(): void {
    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.get(`${urlPai}/${param}`)
      .then(res => {
        setPesquisa(res.data.pesquisa)
        setEtapa(res.data.etapa)
      })
      .catch(err => console.log(err))
  }

  function UpdateEtapaComposta(): void {
    setisValid(true)

    if (Pesquisa.nomePesquisa.length < 5) {
      setisValid(false)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Pesquisa,
      etapa: Etapa
    }

    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.put(`${urlPai}/${param}`, form)
      .then(() => {
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function AtualizarEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  useEffect(() => {
    getEscalas()

    if (etapaId) {
      getById()
    }

    if (path.includes('Processo')) {
      appApi.get(`PesquisaPercepcaoEtapaColaborador/${processoId ?? ''}/Etapa/${etapaId ?? ''}/Resposta`)
        .then(res => setTemResposta(res.data))
        .catch(err => console.log(err))
    }
  }, [])

  return (
    <Body>
      <Main>
        <h1>Criar pesquisa</h1>
        <WhiteBox>
          <FormControl marginBottom={'1rem'} isInvalid={!isValid} >
            <FormLabel fontSize={'x-large'}>Nome</FormLabel>
            <Input
              borderColor={'var(--Gray4)'}
              type={'text'}
              value={Pesquisa.nomePesquisa}
              onChange={(e) => setPesquisa({ ...Pesquisa, nomePesquisa: e.target.value })}
            />
          </FormControl>
        </WhiteBox>

        {
          (path.includes('Criar') || path.includes('Trilha')) && (
            <WhiteBox margin='2rem 0rem'>
              <Flex flexDir={'column'}>
                <StyledLabel>Defina os stakeholders</StyledLabel>
                <Stack direction={'row'}>
                  <Checkbox
                    borderColor={'var(--Gray4)'}
                    isChecked={Pesquisa.participantes.includes(0)}
                    onChange={() => handleCheckboxParticipanteClick(0)}
                  >Autoavaliação</Checkbox>

                  <Checkbox
                    borderColor={'var(--Gray4)'}
                    isChecked={Pesquisa.participantes.includes(1)}
                    onChange={() => handleCheckboxParticipanteClick(1)}
                  >Líder</Checkbox>

                  <Checkbox
                    borderColor={'var(--Gray4)'}
                    isChecked={Pesquisa.participantes.includes(2)}
                    onChange={() => handleCheckboxParticipanteClick(2)}
                  >Pares</Checkbox>

                  <Checkbox
                    borderColor={'var(--Gray4)'}
                    isChecked={Pesquisa.participantes.includes(3)}
                    onChange={() => handleCheckboxParticipanteClick(3)}
                  >Liderados</Checkbox>

                  <Checkbox
                    borderColor={'var(--Gray4)'}
                    isChecked={Pesquisa.participantes.includes(4)}
                    onChange={() => handleCheckboxParticipanteClick(4)}
                  >Outros</Checkbox>

                  <Checkbox
                    borderColor={'var(--Gray4)'}
                    isChecked={Pesquisa.participantes.includes(6)}
                    onChange={() => handleCheckboxParticipanteClick(6)}
                  >Alta liderança</Checkbox>

                  <Checkbox
                    borderColor={'var(--Gray4)'}
                    isChecked={Pesquisa.participantes.includes(7)}
                    onChange={() => handleCheckboxParticipanteClick(7)}
                  >Todos</Checkbox>
                </Stack>
              </Flex>
            </WhiteBox>
          )
        }

        {
          (trilhaId ?? processoId) && (
            <WhiteBox margin='2rem 0'>
              <CreateEtapaBox
                EditEtapa={Etapa}
                ignoreId={etapaId}
                variant='Etapa'
                onModelChange={AtualizarEtapa}
                keyWord='pesquisa'
              />
            </WhiteBox>
          )
        }

        <WhiteBox>
          <StyledLabel>Escolha uma escala</StyledLabel>
          <Flex className={TemResposta ? 'disabled' : ''} justifyContent={'space-around'} flexWrap={'wrap'}>
            {
              Escalas.map((e: iEscala, i) => {
                if (e.fator !== 1) {
                  return (
                    <BoxEscala
                      key={i}
                      Escala={e}
                      Selecionado={isRadioEscalaSelected(e.id)}
                      onClick={() => setPesquisa({ ...Pesquisa, escalaId: e.id })}
                    />
                  )
                } else {
                  return (<></>)
                }
              })
            }
          </Flex>
        </WhiteBox>

        <Flex justifyContent={'end'} marginTop='1rem' gap={'1rem'}>
          <Button
            size={'lg'}
            VarColor={'c6'}
            onClick={() => nav(-1)}
          >Voltar</Button>
          {
            (trilhaId === undefined && etapaId === undefined && processoId === undefined) && (
              <Button
                onClick={criarPesquisa}
                size={'lg'}
                VarColor={'v2'}
              >Criar pesquisa</Button>
            )
          }

          {
            ((trilhaId ?? processoId) && etapaId === undefined) && (
              <Button
                onClick={CreateEtapaComposta}
                size={'lg'}
                VarColor={'v2'}
              >Criar etapa</Button>
            )
          }

          {
            (etapaId) && (
              <Button
                onClick={UpdateEtapaComposta}
                size={'lg'}
                VarColor={'v2'}

              >Atualizar etapa</Button>
            )
          }
        </Flex>
      </Main>
    </Body>
  )
}
