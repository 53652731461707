import { Avatar, Flex, FormControl, FormLabel, Grid, Image, Menu, MenuButton, MenuItem, MenuList, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Link, Box, useToast, Checkbox, Input } from '@chakra-ui/react'
import { FaCheck, FaClipboard, FaEye, FaEyeSlash, FaFileUpload } from 'react-icons/fa'
import { Body } from '../../Layouts/Body'
import { ComentariosContainer, InfoBox, Titulo } from './styles'
import { RadarChartJs } from './components/RadarChart'
import { Recomendacao } from './components/Recomendacao'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../services/appApi'
import { useEffect, useState } from 'react'
import { NavLine } from '../../../components/NavLine'
import { iColaboradorBase, iCompetencia, iCompetenciaPDI, iEscala, iNomeId } from '../../../interfaces'
import IconeAvaliadores from '../../../assets/IconeAvaliadores.svg'
import PDFRadarChart from './components/RelatorioPDF/Components/RadarChartJs'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { RelatorioPDF } from './components/RelatorioPDF'
import { ModalPDI } from './components/ModalPDI'
import { ComparativoChart } from './components/ComparativoChart'
import { parseJwt } from '../../../services/token'
import { DistinctString, PropInvalida } from '../../../Utils/Helper'
import { DashBox } from '../../../components/PerfilColaborador/styles'
import { Button } from '../../../components/Button'
import { ModalDataDevolutiva } from '../Resultado/Components/ModalData'
import { Tabela } from './components/Tabela'
import { ChartPercentual } from './components/ChartPercentual'

export interface iDiscursiva {
  tipoAvaliador: number
  titulo: string
  texto: string
}

export interface IDadoGrafico {
  medias: number[]
  nome: string
}

export interface IGrafico {
  competencias: string[]
  dados: IDadoGrafico[]
  definicoesCompetencias?: string[]
}

export interface iComparativoRespostas {
  total: number
  melhor: number
}

export interface iCorTabela {
  eixo: string
  notasPositivas: number[]
  notasNegativas: number[]
  recomendacoes: number[]
}

export interface iLinhaTabela {
  competencia: string
  autoAvaliacao?: string
  gestor?: string
  pares?: string
  outros?: string
  liderados?: string
  altalideranca?: string
  mediaSemAuto: string
  questoes: iLinhaTabela[]
}

export interface iPI {
  urlSelo: string
  perfil: string
  assessmentId: string
  hide: boolean
}

interface iModel {
  pesquisaId: string
  avaliado: iColaboradorBase
  nomePesquisa: string
  totalAvaliadores: number
  totalRespostas: number
  mediaGeral: number
  participantes: number[]
  graficoHierarquia?: IGrafico
  graficoInvertido?: IGrafico
  graficoIndividual?: IGrafico
  graficosPercentual?: IDadoGrafico[]
  discursivas: iDiscursiva[]
  recomendacoes: iCompetenciaPDI[]
  tabela: iLinhaTabela[]
  filtro: iNomeId[]
  comparativoId?: string
  pdf?: iPDF
  pdiNormal?: string
  pdiAvaliacao?: string
  liberarResultado: number
  cores: iCorTabela[]
  monitorarDevolutiva: boolean
  entregaDevolutiva: boolean
  dataDevolutiva?: string
  escala: iEscala
}

export interface iPDF {
  textoDeConclusao?: string
  competencias: iCompetencia[]
  melhorCompetencia: iCompetencia
  assinaturaGestor: boolean
  assinaturaColaborador: boolean
  comentarios: boolean
}

export interface iResultadoComparativo {
  graficoComparativo: IGrafico
  graficoComparativoAutoavaliacao: IGrafico
}

export const ResultadoIndividual: React.FC = () => {
  const toast = useToast()
  const { avaliacaoId } = useParams<{ avaliacaoId: string }>()
  const { pathname } = useLocation()
  const nav = useNavigate()

  const [RelatorioComparativo, setRelatorioComparativo] = useState(false)
  const [ModoEdicao, setModoEdicao] = useState(false)
  const [IsLoadingChart, setIsLoadingChart] = useState(false)

  const [Graficos, setGraficos] = useState<string[]>([])

  const [Grafico1, setGrafico1] = useState<string>()
  const [Grafico2, setGrafico2] = useState<string>()
  const [Grafico3, setGrafico3] = useState<string>()
  const [Grafico4, setGrafico4] = useState<string>()

  const [PI, setPI] = useState<iPI>()

  const [Model, setModel] = useState<iModel>({
    graficosPercentual: [],
    pdiAvaliacao: '',
    cores: [],
    liberarResultado: 1,
    avaliado: {
      id: '',
      nome: ''
    },
    pdf: {
      melhorCompetencia: {
        discursiva: false,
        doSistema: false,
        icone: '',
        id: '',
        nome: '',
        questoes: []
      },
      competencias: [],
      assinaturaColaborador: false,
      assinaturaGestor: false,
      comentarios: false
    },
    discursivas: [],
    filtro: [],
    mediaGeral: 0,
    nomePesquisa: '',
    participantes: [],
    pesquisaId: '',
    recomendacoes: [],
    tabela: [],
    totalAvaliadores: 0,
    totalRespostas: 0,
    monitorarDevolutiva: false,
    entregaDevolutiva: false,
    escala: {
      id: '',
      fator: 0,
      nome: '',
      respostas: []
    }
  })
  const [NotaMinima, setNotaMinima] = useState(0)
  const [NotaMaxima, setNotaMaxima] = useState(0)

  const [Comparativo, setComparativo] = useState<iResultadoComparativo>()

  const [TabGrafico, setTabGrafico] = useState(0)
  const [GraficoComparativoAutoavaliacao, setGraficoComparativoAutoavaliacao] = useState(true)
  const [ModalDataOpen, setModalDataOpen] = useState(false)

  const [ModalPDIsOpen, setModalPDIsOpen] = useState(false)
  const [ComparativoRespostas, setComparativoRespostas] = useState<iComparativoRespostas>()

  const [AtualComparativo, setAtualComparativo] = useState<string>('')

  const [FiltroCompetencia, setFiltroCompetencia] = useState<string>('')
  const [FiltroTipo, setFiltroTipo] = useState<string>('')

  const [IsLoading, SetIsLoading] = useState(false)

  const colors = [
    'var(--Azul)',
    'var(--Green2)',
    'var(--Gray4)',
    'var(--Yellow)',
    'var(--Orange)'
  ]

  function getResultado(): void {
    SetIsLoading(true)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId as string}/ResultadoIndividual`)
      .then(res => {
        setModel(res.data)
        setNotaMaxima(res.data.escala.fator)
        SetIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function FiltrarComaparativo(id: string): void {
    setAtualComparativo(id)
    setComparativo(undefined)
    appApi.get(`AvaliacaoPesquisa/${avaliacaoId as string}/Comparativo/?comparativoId=${id}`)
      .then(res => {
        setComparativo(res.data)
      })
      .catch(err => { console.log(err); setAtualComparativo(''); setRelatorioComparativo(false) })
  }

  function TemColuna(tipo: number): boolean {
    return Model.participantes.includes(tipo)
  }

  function CriarBasePDI(opcao: string): void {
    if (opcao === '1') {
      nav(`/Desempenho/PDI/Criar?avaliacaoId=${avaliacaoId ?? ''}`)
    } else {
      appApi.post(`PDI/${Model.pdiNormal ?? ''}/Avaliacao/${avaliacaoId ?? ''}/Continuar`)
        .then(() => nav(`/Desempenho/PDI/Editar/${Model.pdiNormal ?? ''}`))
        .catch(err => console.log(err))
    }
  }

  function onAlterarDevolutiva(Data: string | null, bool: boolean): void {
    const form = { dataDevolutiva: Data, devolutivaEntregue: bool }
    if (bool && (Data === '' || Data === null)) {
      toast({
        title: 'Preencha o campo de data',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId ?? ''}/devolutiva`, form)
      .then(() => { setModalDataOpen(false); getResultado() })
      .catch(err => console.log(err))
  }

  function AlterarVisibiliade(visibilidade: boolean): void {
    appApi.patch(`AvaliacaoPesquisa/${avaliacaoId ?? ''}/AlterarVisibilidade?visibilidade=${visibilidade ? 0 : 1}`)
      .then(() => {
        setModel({ ...Model, liberarResultado: Model.liberarResultado === 0 ? 1 : 0 })
      })
      .catch(err => console.log(err))
  }

  function getPerfilPI(): void {
    SetIsLoading(true)
    appApi.get(`https://sah.icecloud.com.br/api/integracao/sahpi/?email=${Model.avaliado.email ?? ''}`)
      .then(res => {
        if (res.data.assessmentId !== '5b9802ac-8d59-4f70-93a3-4716ed4cf8a0') {
          setPI(res.data)
        }
      }).catch(err => {
        console.log(err)
      })
    SetIsLoading(false)
  }

  function getRespostasMelhoradas(): void {
    let melhor = 0
    let total = Comparativo?.graficoComparativo.dados[0].medias.length ?? 0
    if (Comparativo?.graficoComparativo) {
      for (let i = 0; i < Comparativo?.graficoComparativo.dados[0].medias.length; i++) {
        if (Comparativo?.graficoComparativo.dados[0].medias[i] < Comparativo?.graficoComparativo.dados[1].medias[i]) {
          melhor += 1
        }
        if (Comparativo?.graficoComparativo.dados[0].medias[i] === -1) {
          melhor = melhor - 1
          total = total - 1
        }
      }
    }
    setComparativoRespostas({ melhor: melhor, total: total ?? 0 })
  }

  function ChangeInputNota(value: string, type: string): void {
    setIsLoadingChart(true)
    if (type === 'max') {
      if (value === '') {
        setNotaMaxima(GetMaiorMenorNota('>'))
      } else if (!Model.graficoHierarquia?.dados.some(b => b.medias.some(a => a > parseFloat(value)))) {
        setNotaMaxima(parseFloat(value))
      } else {
        toast({
          title: 'Valor de limite máximo deve ser maior que a melhor nota do gráfico!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }
    }
    if (type === 'min') {
      if (value === '') {
        setNotaMinima(0)
      } else if (!Model.graficoHierarquia?.dados.some(b => b.medias.some(a => a < parseFloat(value)))) {
        setNotaMinima(parseFloat(value))
      } else {
        toast({
          title: 'Valor de limite mínimo deve ser menor que a pior nota do gráfico!',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      }
    }
  }

  function GetMaiorMenorNota(parameter: string): number {
    const Dados: number[] = []
    Model.graficoHierarquia?.dados.forEach(e => e.medias.forEach(a => Dados.push(a)))
    if (parameter === '>') {
      Dados.sort((a, b) => { return b - a })
      return Dados[0]
    } else {
      Dados.sort((a, b) => { return a - b })
      return Dados[0]
    }
  }

  function ObterFiltroQuestao(): string[] {
    return DistinctString(Model.discursivas.map((e) => {
      if (e.titulo.split('-').length > 1) {
        return e.titulo.split('-')[1].trim()
      } else {
        return e.titulo.split('-')[0].trim()
      }
    }))
  }

  useEffect(() => {
    getResultado()
  }, [])

  useEffect(() => {
    getRespostasMelhoradas()
  }, [Comparativo])

  useEffect(() => {
    if (!PropInvalida(Model.comparativoId)) {
      FiltrarComaparativo(Model.comparativoId ?? '')
    }

    if (Model.graficoInvertido) {
      setTabGrafico(2)
    } else if (Model.graficoHierarquia?.dados.length === 2) {
      setTabGrafico(0)
    }

    if (!PropInvalida(Model.avaliado.email) && PropInvalida(Model.avaliado.perfilPI)) {
      getPerfilPI()
    }

    if (!PropInvalida(Model.escala.respostas[0])) {
      setNotaMinima(1)
    }
  }, [Model])

  useEffect(() => {
    setIsLoadingChart(false)
  }, [NotaMaxima, NotaMinima, RelatorioComparativo])

  return (
    <Body isLoading={IsLoading}>
      <ModalDataDevolutiva
        check={Model.entregaDevolutiva}
        data={Model.dataDevolutiva}
        isOpen={ModalDataOpen}
        onConfirm={(Data, Checkbox) => onAlterarDevolutiva(Data, Checkbox)}
        onRequestClose={() => setModalDataOpen(false)}
      />

      <ModalPDI
        recomendacoes={Model.recomendacoes.length}
        isOpen={ModalPDIsOpen}
        onCreate={CriarBasePDI}
        onRequestClose={() => setModalPDIsOpen(false)}
      />

      <Flex opacity={'0'} flexDir='column' position={'absolute'} zIndex={-1} justifyContent={'center'} width={'100%'} maxWidth='1168px'>
        <Flex width={'100%'} flexDir='column'>
          {(Comparativo?.graficoComparativo && GraficoComparativoAutoavaliacao) && (
            <ComparativoChart getBlob={(a) => setGrafico3(a)} fator={Model.escala.fator} grafico={{
              competencias: Comparativo?.graficoComparativo.competencias,
              dados: Comparativo?.graficoComparativo.dados
            }} id='gPDF-1' />
          )}

          {(Comparativo?.graficoComparativoAutoavaliacao && !GraficoComparativoAutoavaliacao) && (
            <ComparativoChart getBlob={(a) => setGrafico3(a)} fator={Model.escala.fator} grafico={{
              competencias: Comparativo?.graficoComparativoAutoavaliacao.competencias,
              dados: Comparativo?.graficoComparativoAutoavaliacao.dados
            }} id='gPDF-0' />
          )}

          {Model.graficosPercentual?.map((e, i) => {
            return (
              <ChartPercentual
                key={i}
                id={`grf-pdf-${i}`}
                grafico={e}
                escala={Model.escala}
                getBlob={(e) => setGraficos(old => [...old, e])}
              />
            )
          })}
        </Flex>

        <Flex width={'60%'} flexDir='column'>
          {!IsLoadingChart && Model.graficoIndividual && <PDFRadarChart
            setGrafico={(e) => setGrafico1(e)}
            notaMinimaEscala={NotaMinima}
            notaMaximaEscala={NotaMaxima}
            stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model?.escala?.respostas[1]?.peso ?? 0)) ?? 0}
            competencias={Model.graficoIndividual.competencias}
            dados={Model.graficoIndividual.dados}
          />}

          {!IsLoadingChart && Model.graficoHierarquia && <PDFRadarChart
            setGrafico={(e) => setGrafico2(e)}
            notaMinimaEscala={NotaMinima}
            notaMaximaEscala={NotaMaxima}
            stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
            competencias={Model.graficoHierarquia.competencias}
            dados={Model.graficoHierarquia.dados}
          />}

          {!IsLoadingChart && Model.graficoInvertido && <PDFRadarChart
            setGrafico={(e) => setGrafico4(e)}
            notaMinimaEscala={NotaMinima}
            notaMaximaEscala={NotaMaxima}
            stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
            competencias={Model.graficoInvertido.competencias}
            dados={Model.graficoInvertido.dados}
          />}
        </Flex>

      </Flex>

      <Flex margin={'1rem 0'}>
        <NavLine options={[
          {
            nome: 'Gestão de pesquisas',
            path: `${pathname.replace(`ResultadoIndividual/${avaliacaoId as string}`, 'Gestao')}`
          },
          {
            nome: Model?.nomePesquisa ?? '',
            path: -1
          }, {
            nome: Model?.avaliado?.nome ?? '',
            path: ''
          }
        ]} />
      </Flex>

      <Flex marginBottom={'2rem'} justifyContent={'space-between'} flexFlow='nowrap'>
        <Flex flexDir={'column'} marginBottom='1rem'>
          <Flex>
            <Avatar
              marginRight={'1rem'}
              size={'2xl'}
              name={Model?.avaliado.nome}
              src={Model?.avaliado.avatar}
            />
            <div>
              <h3>Avaliado</h3>
              <h1>{Model?.avaliado.nome}</h1>
              <strong>{Model?.avaliado?.nomeDepartamento}</strong>
            </div>
          </Flex>
        </Flex>
        <Flex gap={'1rem'}>
          {
            (PI && !PI.hide) && (
              <DashBox style={{ position: 'relative', width: '10.925rem', height: '8.625rem', border: '2px solid var(--Azul)', backgroundColor: 'var(--c1)' }}>
                <Flex justifyContent={'center'}>
                  <Text fontSize={'14px'} color={'var(--Gray2)'} fontWeight={'700'}>Perfil comportamental</Text>
                </Flex>
                <Flex flexDirection={'column'}>
                  <Flex flexDir='column' width='100%' alignItems={'center'}>
                    {
                      (PI.perfil) && (
                        <Text fontSize={'14px'} fontWeight={'500'}>{PI.perfil}</Text>
                      )
                    }
                    {
                      (PI.urlSelo) && (
                        <Image mt={'.3rem'} width={'2.5rem'} src={PI.urlSelo} />
                      )
                    }
                  </Flex>
                  <Menu>
                    <MenuButton display={'flex'} alignItems={'center'} py={'.5rem'} as={Button} border={'1px solid var(--Blue2)'} color={'var(--Blue2)'} bgColor='var(--c1)' height={'18px'} fontSize={'12px'} _active={{ bgColor: 'var(--Azul)', color: 'white' }} _hover={{ bgColor: 'var(--Azul)', color: 'white' }} mt={'.5rem'}>
                      Baixar Relatorios
                    </MenuButton>
                    <MenuList bgColor='var(--Azul)' mt={'8.6rem'}>
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={`https://sah.icecloud.com.br/api/Files/api/pdf?id=${PI.assessmentId}&tipo=2`}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Perfil comportamental
                        </Box>
                      </MenuItem>
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=2`}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Quadro desenvolvimento pessoal
                        </Box>
                      </MenuItem>
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=3`}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Placard
                        </Box>
                      </MenuItem>
                      <MenuItem>
                        <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=4`}
                          _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                          Quadro desenvolvimento gestores
                        </Box>
                      </MenuItem>

                    </MenuList>
                  </Menu>
                </Flex>
              </DashBox>
            )
          }
          {(Model.avaliado.perfilPI) && (
            <DashBox style={{ position: 'relative', width: '10.925rem', height: '8.625rem', border: '2px solid var(--Azul)', backgroundColor: 'var(--c1)' }}>
              <Flex justifyContent={'center'}>
                <Text fontSize={'14px'} color={'var(--Gray2)'} fontWeight={'700'}>Perfil comportamental</Text>
              </Flex>
              <Flex flexDirection={'column'}>
                <Flex flexDir='column' width='100%' alignItems={'center'}>
                  {
                    (Model.avaliado.perfilPI?.nomeSelo) && (
                      <Text fontWeight={'500'}>{Model.avaliado.perfilPI?.nomeSelo}</Text>
                    )
                  }
                  {
                    (Model.avaliado.perfilPI?.urlSelo) && (
                      <Image mt={'.3rem'} width={'2.5rem'} src={Model.avaliado.perfilPI.urlSelo} />
                    )
                  }
                </Flex>
                <Menu>
                  <MenuButton display={'flex'} alignItems={'center'} py={'.5rem'} as={Button} border={'1px solid var(--Blue2)'} color={'var(--Blue2)'} bgColor='var(--c1)' height={'18px'} fontSize={'12px'} _active={{ bgColor: 'var(--Azul)', color: 'white' }} _hover={{ bgColor: 'var(--Azul)', color: 'white' }} mt={'.5rem'}>
                    Baixar Relatorios
                  </MenuButton>
                  <MenuList bgColor='var(--Azul)' mt={'8.6rem'}>
                    {
                      (!PropInvalida(Model.avaliado.perfilPI?.urlResultado)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Model.avaliado.perfilPI?.urlResultado}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Perfil comportamental
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Model.avaliado.perfilPI?.urlQuadro)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Model.avaliado.perfilPI?.urlQuadro}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento pessoal
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Model.avaliado.perfilPI?.urlPlacard)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Model.avaliado.perfilPI?.urlPlacard}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Placard
                          </Box>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Model.avaliado.perfilPI?.urlQuadroGestor)) && (
                        <MenuItem>
                          <Box as={Link} borderColor='var(--Azul)' p={'.5rem'} fontSize={'16px'} fontWeight={'700'} textDecoration={'none'} color='var(--Azul)' borderRadius={'2rem'} href={Model.avaliado.perfilPI?.urlQuadroGestor}
                            _hover={{ boxShadow: '0 3px 12px 1px #00000020', textDecoration: 'none' }}>
                            Quadro desenvolvimento gestores
                          </Box>
                        </MenuItem>
                      )
                    }

                  </MenuList>
                </Menu>
              </Flex>
            </DashBox>
          )}
          <InfoBox style={{ flexDirection: 'column' }}>
            <span>Média geral</span>
            <h3>{Model?.mediaGeral.toFixed(2)}</h3>
          </InfoBox>

          <InfoBox>
            <Flex width={'2rem'}>
              <Image src={IconeAvaliadores} />
            </Flex>
            <div>
              <h3>{Model?.totalRespostas}/{Model.totalAvaliadores}</h3>
              <span>Avaliadores</span>
            </div>
          </InfoBox>
        </Flex>
      </Flex>

      {
        (Model.recomendacoes.length > 0) && (
          <>
            <Flex justifyContent={'start'}>
              <h3>Potencial para melhorias</h3>
            </Flex>

            <Grid templateColumns='repeat(2, 1fr)' gap={10} margin={'1rem'} bg={'white'} padding='2rem' borderRadius={'1rem'}>
              {
                Model?.recomendacoes.map((e, i) => {
                  return (
                    <Recomendacao pos={i + 1} recomendacao={e} key={i} />
                  )
                })
              }
            </Grid>
          </>
        )
      }

      <Flex gap={'1rem'} marginTop={'1rem'}>
        {!IsLoadingChart && Model.tabela.length > 0 && <PDFDownloadLink
          document={
            <RelatorioPDF
              comparativo={Model.filtro ? Model.filtro.filter(e => e.id === AtualComparativo)[0] ? Model.filtro.filter(e => e.id === AtualComparativo)[0].nome : '' : ''}
              comparativoRespostas={ComparativoRespostas ?? { melhor: 0, total: 0 }}
              temColuna={TemColuna}
              Grafico1={Grafico1}
              Grafico2={Grafico2}
              Grafico3={Grafico3}
              Grafico4={Grafico4}
              graficosPercentual={Graficos}
              comparativoOn={RelatorioComparativo}
              model={Model}
              tipoPesquisa={pathname.includes('AnaliseDesempenho') ? 'Pesquisa de análise e desempenho' : 'Pesquisa de percepção'}
            />
          }
          fileName={`Relatorio_Pesquisa_Percepcao_${Model.avaliado.nome.replace(/\s/g, '_')}.pdf`}
        >
          {({ loading }) =>
            (loading || ((PropInvalida(Comparativo) && RelatorioComparativo)))
              ? (
                <Button VarColor='Azul' isLoading>Baixar relatório</Button>
                )
              : <Button VarColor='Azul'>Baixar relatório</Button>

          }
        </PDFDownloadLink>}
        {(pathname.includes('AnaliseDesempenho') && PropInvalida(Model.pdiAvaliacao)) && (
          <Button
            fontWeight={'500'}
            onClick={() => {
              !PropInvalida(Model.pdiNormal) ? setModalPDIsOpen(true) : CriarBasePDI('1')
            }}
            leftIcon={<FaClipboard />} VarColor='Azul'
          >Adicionar PDI</Button>
        )}

        {(pathname.includes('AnaliseDesempenho') && !PropInvalida(Model.pdiAvaliacao)) && (
          <Button
            fontWeight={'500'}
            onClick={() => nav(`/Desempenho/PDI/Editar/${Model.pdiAvaliacao ?? ''}`)}
            leftIcon={<FaClipboard />} VarColor='Azul'
          >Editar PDI</Button>
        )}

        {
          (Model.liberarResultado === 0 && (parseJwt().adm === 'True' || parseJwt().cid === Model.avaliado.gestorId)) && (
            <Button
              borderRadius={'.5rem'}
              VarColor='Orange'
              leftIcon={<FaEyeSlash />}
              onClick={() => AlterarVisibiliade(false)}
            >Ocultar resultado</Button>
          )
        }

        {
          (Model.liberarResultado === 1 && (parseJwt().adm === 'True' || parseJwt().cid === Model.avaliado.gestorId)) && (
            <Button
              leftIcon={<FaEye />}
              borderRadius={'.5rem'}
              VarColor='Orange'
              onClick={() => AlterarVisibiliade(true)}
            >Liberar resultado</Button>
          )
        }
        {Model.monitorarDevolutiva && parseJwt().adm === 'True' &&
          (
            <Button VarColor={Model.entregaDevolutiva ? 'Green1' : 'Red'} leftIcon={!Model.entregaDevolutiva ? <FaFileUpload /> : <FaCheck />} onClick={() => setModalDataOpen(true)}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                <span>Devolutiva</span>
                <small style={{ fontSize: '10px', fontWeight: 400 }}>{Model.entregaDevolutiva ? Model.dataDevolutiva : ''}</small>
              </Box>
            </Button>
          )
        }
      </Flex>
      <Flex gap={'1rem'} my={'1rem'}>
        <Checkbox disabled={AtualComparativo === ''} isChecked={RelatorioComparativo} onChange={(e) => { setIsLoadingChart(true); setRelatorioComparativo(e.target.checked) }} borderColor={'var(--Gray4)'}>Incluir comparativo no relatório</Checkbox>
      </Flex>
      <Tabs variant='enclosed'>
        <TabList >
          <Tab backgroundColor={'white'} color={'var(--c6)'}>Gráfico</Tab>
          <Tab backgroundColor={'white'} color={'var(--c6)'}>Tabela</Tab>
          {Model.graficosPercentual && <Tab backgroundColor={'white'} color={'var(--c6)'}>Percentual de respostas</Tab>}
          <Tab backgroundColor={'white'} color={'var(--c6)'}>Comparativo</Tab>
          {(Model && Model?.discursivas.length > 0) && <Tab backgroundColor={'white'} color={'var(--c6)'}>Comentários</Tab>}
        </TabList>
        <TabPanels background={'white'} borderBottomLeftRadius='1rem' borderBottomRightRadius='1rem'>
          <TabPanel>
            <Flex flexDir={'column'} width={'100%'}>
              {TabGrafico === 0 && Model.graficoHierarquia && (
                <Flex flexDir={'column'} width='100%'>
                  <Flex justifyContent={'space-between'}>
                    <Titulo>Gráfico de hierarquia</Titulo>
                    <Box display={'flex'} gap={'1rem'}>
                      <Button VarColor='Roxo' onClick={() => setModoEdicao(!ModoEdicao)}>Modo de edição do eixo</Button>
                      {
                        (Model.graficoIndividual) && (
                          <Button onClick={() => setTabGrafico(1)} VarColor='Azul'>Alterar tipo do gráfico</Button>
                        )
                      }
                    </Box>
                  </Flex>

                  <Flex justifyContent={'center'} width='100%' position={'relative'}>
                    <Flex width={'60%'}>
                      <RadarChartJs
                        competencias={Model?.graficoHierarquia.competencias ?? []}
                        dados={Model?.graficoHierarquia.dados ?? []}
                        notaMinimaEscala={NotaMinima}
                        notaMaximaEscala={NotaMaxima}
                        stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                      />
                    </Flex>
                  </Flex>
                  {(ModoEdicao) &&
                    <Flex fontSize={'18px'} fontWeight={'600'} flexDirection={'column'} mb={'1rem'} alignItems={'center'} justifyContent={'center'} gap={'1.5rem'}>
                      <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite mínimo: <Input max={GetMaiorMenorNota('<')} step={'0.5'} type={'number'} value={NotaMinima} onChange={(e) => ChangeInputNota(e.target.value, 'min')} w={'5rem'} /></Box>
                      <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite máximo: <Input min={GetMaiorMenorNota('>')} step={'0.5'} type={'number'} value={NotaMaxima} onChange={(e) => ChangeInputNota(e.target.value, 'max')} w={'5rem'} /></Box>
                    </Flex>
                  }
                </Flex>)
              }

              {TabGrafico === 1 && Model.graficoIndividual && (
                <Flex flexDir={'column'} width='100%'>
                  <Flex justifyContent={'space-between'}>
                    <Titulo>Gráfico individual</Titulo>
                    <Box display={'flex'} gap={'1rem'}>
                      <Button VarColor='Roxo' onClick={() => setModoEdicao(!ModoEdicao)}>Modo de edição do eixo</Button>
                      {
                        (Model.graficoHierarquia) && (
                          <Button
                            onClick={() => setTabGrafico(0)}
                            VarColor='Azul'
                          >Alterar tipo do gráfico</Button>
                        )
                      }
                    </Box>
                  </Flex>
                  <Flex justifyContent={'center'} width='100%' position={'relative'}>
                    <Flex width={'70%'}>
                      <RadarChartJs
                        competencias={Model.graficoIndividual.competencias ?? []}
                        dados={Model.graficoIndividual.dados ?? []}
                        notaMinimaEscala={NotaMinima}
                        notaMaximaEscala={NotaMaxima}
                        stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala.respostas[1]?.peso ?? 0)) ?? 0}
                      />
                    </Flex>
                  </Flex>
                  {(ModoEdicao) &&
                    <Flex fontSize={'18px'} fontWeight={'600'} flexDirection={'column'} mb={'1rem'} alignItems={'center'} justifyContent={'center'} gap={'1.5rem'}>
                      <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite mínimo: <Input max={GetMaiorMenorNota('<')} step={'0.5'} type={'number'} value={NotaMinima} onChange={(e) => ChangeInputNota(e.target.value, 'min')} w={'5rem'} /></Box>
                      <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite máximo: <Input min={GetMaiorMenorNota('>')} step={'0.5'} type={'number'} value={NotaMaxima} onChange={(e) => ChangeInputNota(e.target.value, 'max')} w={'5rem'} /></Box>
                    </Flex>}
                </Flex>)
              }

              {TabGrafico === 2 && Model.graficoInvertido && (
                <Flex flexDir={'column'} width='100%'>
                  <Titulo>Gráfico de hierarquia</Titulo>
                  <Flex justifyContent={'flex-end'}>
                    <Button
                      VarColor='Roxo'
                      onClick={() => setModoEdicao(!ModoEdicao)}
                    >Modo de edição do eixo</Button>
                  </Flex>
                  <Flex justifyContent={'center'} width='100%' position={'relative'}>
                    <Flex width={'60%'}>
                      <RadarChartJs
                        competencias={Model?.graficoInvertido.competencias ?? []}
                        dados={Model?.graficoInvertido.dados ?? []}
                        notaMinimaEscala={NotaMinima}
                        notaMaximaEscala={NotaMaxima}
                        stepSize={((Model.escala?.respostas[0]?.peso ?? 0) - (Model.escala?.respostas[1]?.peso ?? 0)) ?? 0}
                      />
                    </Flex>
                  </Flex>
                  {(ModoEdicao) &&
                    <Flex fontSize={'18px'} fontWeight={'600'} flexDirection={'column'} mb={'1rem'} alignItems={'center'} justifyContent={'center'} gap={'1.5rem'}>
                      <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite mínimo: <Input max={GetMaiorMenorNota('<')} step={'0.5'} type={'number'} value={NotaMinima} onChange={(e) => ChangeInputNota(e.target.value, 'min')} w={'5rem'} /></Box>
                      <Box w={'13.5rem'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>Limite máximo: <Input min={GetMaiorMenorNota('>')} step={'0.5'} type={'number'} value={NotaMaxima} onChange={(e) => ChangeInputNota(e.target.value, 'max')} w={'5rem'} /></Box>
                    </Flex>
                  }
                </Flex>)
              }
            </Flex>
            <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'}>
              {Model.escala?.respostas.map((e, i) => {
                return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
              })}
            </Flex>
          </TabPanel>

          <TabPanel>
            <Titulo>Tabela dos resultados por hierarquia</Titulo>
            <Tabela
              cores={Model.cores}
              discursivas={Model.discursivas}
              linhas={Model.tabela}
              recomendacoes={Model.recomendacoes}
              temColuna={TemColuna}
            />
            <Flex mt={'1rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'}>
              {Model.escala?.respostas.map((e, i) => {
                return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
              })}
            </Flex>
          </TabPanel>

          {Model.graficosPercentual &&
            <TabPanel>
              <Flex flexDir={'column'} width='100%'>
                <Titulo>Gráfico de percentual</Titulo>
                <Flex flexWrap={'wrap'} justifyContent={'center'} gap={'1rem'} my='1rem'>
                  {Model.escala.respostas.filter(e => e.peso !== -1).map((e, i) => {
                    return (
                      <Flex key={e.id} gap={'1rem'}>
                        <Box
                          w={'1.625rem'}
                          h={'1.625rem'}
                          bgColor={colors[i]}
                        /> {e.resposta}</Flex>
                    )
                  })}
                </Flex>
                <Flex flexDir={'column'} gap='2rem'>
                  {Model.graficosPercentual.map((e, i) => {
                    return (
                      <Flex key={i} justifyContent={'center'} width='100%' position={'relative'}>
                        <Flex width={'80%'}>
                          <ChartPercentual
                            id={`grf-${i}`}
                            grafico={e}
                            escala={Model.escala} />
                        </Flex>
                      </Flex>
                    )
                  })}
                </Flex>

                <Flex flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'}>
                  {Model.escala?.respostas.map((e, i) => {
                    return (<span key={i}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
                  })}
                </Flex>
              </Flex>
            </TabPanel>
          }

          <TabPanel>
            <Flex width={'100%'} flexDir='column'>
              {
                (Comparativo?.graficoComparativo) &&
                <Flex justifyContent={'space-between'}>
                  <Titulo>{GraficoComparativoAutoavaliacao ? 'Gráfico de comparativo das médias sem autoavaliação' : 'Gráfico comparativo - autoavaliacão'}</Titulo>
                  <Button
                    onClick={() => setGraficoComparativoAutoavaliacao(!GraficoComparativoAutoavaliacao)}
                    VarColor='Azul'
                  >Alterar tipo do gráfico</Button>
                </Flex>
              }
              <Flex flexDir={'column'} width='100%'>
                <FormControl marginBottom={'1rem'}>
                  <FormLabel>Pesquisa comparada</FormLabel>
                  <Select value={AtualComparativo ?? ''} onChange={(e) => { FiltrarComaparativo(e.target.value) }}>
                    <option value={''}>Mais recente</option>
                    {
                      Model?.filtro?.filter(e => e.id !== avaliacaoId).map((e) => {
                        return (
                          <option key={e.id} value={e.id}>{e.nome}</option>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Flex>
              {
                (Comparativo?.graficoComparativo && GraficoComparativoAutoavaliacao) && (
                  <Flex justifyContent={'center'} width='100%'>
                    <Flex width={'100%'}>
                      <ComparativoChart fator={Model.escala.fator} grafico={{
                        competencias: Comparativo?.graficoComparativo.competencias,
                        dados: Comparativo?.graficoComparativo.dados
                      }} id='g-1' />
                    </Flex>
                  </Flex>
                )
              }
              {
                (Comparativo?.graficoComparativoAutoavaliacao && !GraficoComparativoAutoavaliacao) && (
                  <Flex justifyContent={'center'} width='100%'>
                    <Flex width={'100%'}>
                      <ComparativoChart fator={Model.escala.fator} grafico={{
                        competencias: Comparativo?.graficoComparativoAutoavaliacao.competencias,
                        dados: Comparativo?.graficoComparativoAutoavaliacao.dados
                      }} id='g-0' />
                    </Flex>
                  </Flex>
                )
              }
            </Flex>
            <Flex mt={'.5rem'} flexWrap={'wrap'} columnGap={'.2rem'} fontSize={'12px'} fontWeight={'400'}>
              {Model.escala?.respostas.map(e => {
                return (<span key={e.id}>{e.resposta}{e.peso !== -1 && <strong style={{ marginLeft: '.2rem' }}>({e.peso})</strong>};</span>)
              })}
            </Flex>
          </TabPanel>

          {(Model && Model.discursivas.length > 0) && (
            <TabPanel>
              {!Model.discursivas.every(e => PropInvalida(e.tipoAvaliador)) && <Flex gap={'1rem'} marginBottom='1rem' width='50%'>
                <FormControl>
                  <FormLabel>Questões</FormLabel>
                  <Select onChange={(e) => setFiltroCompetencia(e.target.value)}>
                    <option value={''}>Todos</option>
                    {
                      ObterFiltroQuestao().map((e, i) => {
                        return (
                          <option value={e} key={i}>{e}</option>
                        )
                      })
                    }
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Tipo do avaliador</FormLabel>
                  <Select onChange={(e) => setFiltroTipo(e.target.value)}>
                    <option value={''}>Todos</option>
                    {TemColuna(0) && <option value={'0'}>Autoavaliação</option>}
                    {TemColuna(1) && <option value={'1'}>Líder</option>}
                    {TemColuna(2) && <option value={'2'}>Par</option>}
                    {TemColuna(3) && <option value={'3'}>Liderado</option>}
                    {TemColuna(6) && <option value={'6'}>Alta liderança</option>}
                    {TemColuna(4) && <option value={'4'}>Outro</option>}
                  </Select>
                </FormControl>
              </Flex>}
              <h3>Comentários: {Model.discursivas.filter((e) => {
                if (FiltroCompetencia === '' && FiltroTipo === '') {
                  return e
                } else if (!PropInvalida(e.tipoAvaliador) && FiltroCompetencia !== '' && FiltroTipo !== '') {
                  if (e.titulo.includes(FiltroCompetencia) && FiltroTipo === e.tipoAvaliador.toString()) {
                    return e
                  }
                  return null
                } else if (!PropInvalida(e.tipoAvaliador) && ((FiltroCompetencia !== '' && e.titulo.includes(FiltroCompetencia)) || (FiltroTipo !== '' && FiltroTipo === e.tipoAvaliador.toString()))) {
                  return e
                } else if (PropInvalida(e.tipoAvaliador) && FiltroCompetencia === 'Comentários') {
                  return e
                }
                return null
              }).length}</h3>
              <Flex marginTop={'1rem'} flexDir={'column'} gap='1rem'>
                {
                  Model.discursivas.filter((e) => {
                    if (FiltroCompetencia === '' && FiltroTipo === '') {
                      return e
                    } else if (!PropInvalida(e.tipoAvaliador) && FiltroCompetencia !== '' && FiltroTipo !== '') {
                      if (e.titulo.includes(FiltroCompetencia) && FiltroTipo === e.tipoAvaliador.toString()) {
                        return e
                      }
                      return null
                    } else if (!PropInvalida(e.tipoAvaliador) && ((FiltroCompetencia !== '' && e.titulo.includes(FiltroCompetencia)) || (FiltroTipo !== '' && FiltroTipo === e.tipoAvaliador.toString()))) {
                      return e
                    } else if (PropInvalida(e.tipoAvaliador) && FiltroCompetencia === 'Comentários') {
                      return e
                    }
                    return null
                  }).map((e, i) => {
                    return (
                      <ComentariosContainer key={i}>
                        <strong>{e.titulo}</strong>
                        <span>{e.texto}</span>
                      </ComentariosContainer>
                    )
                  })
                }
              </Flex>
            </TabPanel>)
          }
        </TabPanels>
      </Tabs>
    </Body>
  )
}
