import { Flex } from '@chakra-ui/react'
import { FaAngleRight, FaCheck } from 'react-icons/fa'
import { iEtapaColaborador } from '../../../../../interfaces'
import { ObterCorEtapaPeloTipo } from '../../../../../Utils/Helper'
import { Card, Circle, Content, FlexElipse, Container } from './styles'

interface CardProps {
  Etapa: iEtapaColaborador
  Last: boolean
  onClick: () => void
}

export const CardEtapa: React.FC<CardProps> = ({ Etapa, Last, onClick }) => {
  let Cor = ObterCorEtapaPeloTipo(Etapa.tipo)

  function ObterChildrenPorStatus(status: number): any {
    if (status === 0) { // Liberado
      return (
        <span>Liberado</span>
      )
    }

    if (status === 1) { // Etapa anterior
      return (
        <span>Após a <br/>anterior</span>
      )
    }

    if (status === 2) { // Finalizado
      return (
        <FaCheck color='var(--terc6)' size={28} />
      )
    }

    if (status === 3) { // Data indefinida
      return (
        <span>Pendente</span>
      )
    }

    if (status === 4) { // Data de inicio
      return (
        <span style={{ fontSize: '1.3rem' }}>{Etapa.dataInicio}</span>
      )
    }

    if (status === 6) { // Atrasado
      return (
        <span>Atrasada</span>
      )
    }

    if (status === 7) { // Em andamento
      return (
        <span style={{ fontSize: '1rem' }}>Em <br/> andamento</span>
      )
    }
  }

  function ObterCorDoCirculo(): string {
    let CorPadrao = '02EA8D'
    if (Etapa.status === 5 || Etapa.status === 4) {
      CorPadrao = 'C9C9C9'
      Cor = 'C9C9C9'
    } else if (Etapa.status === 6) {
      CorPadrao = 'FF5050'
    } else if (Etapa.status === 3) {
      CorPadrao = 'FF9900'
    }

    return CorPadrao
  }

  function ObterCorContainer(): string {
    let CorPadrao = '02EA8D'
    if (Etapa.status !== 0 && Etapa.status !== 2 && Etapa.status !== 6 && Etapa.status !== 7) {
      CorPadrao = 'C9C9C9'
      Cor = 'C9C9C9'
    }
    return CorPadrao
  }

  return (
    <Container theme={ObterCorContainer()} >
      <Content>
        <Circle theme={ObterCorDoCirculo()}>{ObterChildrenPorStatus(Etapa.status)}</Circle>
        <FlexElipse color={Cor}>
          <div></div>
          <div></div>
          <div></div>
        </FlexElipse>
        <Card
          disabled={Etapa.status === 2}
          onClick={onClick}
          theme={Cor}>
          <Flex flexDir={'column'}>
            <h3>{Etapa.nome}</h3>
            <span>{Etapa.descricao}</span>
          </Flex>
          <FaAngleRight size={24} />
        </Card>
      </Content>
      {
        (!Last) && (<hr />)
      }
    </Container>
  )
}
