/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, FormControl, FormLabel, Input, Textarea, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Button } from '../../../../../components/Button'
import { appApi } from '../../../../../services/appApi'
import { ModalBase } from '../../../../../components/ModalBase'
import { FileInput } from './styles'

interface iModalComunicacao{
  isOpen: boolean
  imersaoId: string
  onClose: () => void
}

interface iComunicao{
  descricao: string
  uri: string
}

export const ModalComunicao: React.FC<iModalComunicacao> = ({ isOpen, imersaoId, onClose }) => {
  const toast = useToast()

  const [Url, setUrl] = useState('')
  const [Descriacao, setDescriacao] = useState('')
  const [isEdit, setisEdit] = useState(false)

  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    if (Url !== undefined && Url.length > 0) {
      appApi.post(`UploadFile?url=${Url}`, formdata).then(resposnse => {
        setUrl(resposnse.data.uri)
        toast({
          title: 'Arquivo atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }).catch(e => console.log(e))
    } else {
      appApi.post('UploadFile', formdata).then(resposnse => {
        setUrl(resposnse.data.uri)
        toast({
          title: 'Arquivo cadastrado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      }).catch(e => console.log(e))
    }
  }

  const getComunicao = (): void => {
    appApi.get(`ImersaoExterna/${imersaoId}/Comunicacao`).then(res => {
      if (res.status === 200) {
        setUrl(res.data.uri)
        setDescriacao(res.data.descricao)
        setisEdit(true)
      }
    }).catch(err => console.log(err))
  }

  const PostComunicacao = (): void => {
    const form: iComunicao = {
      descricao: Descriacao,
      uri: Url ?? ''
    }

    appApi.post(`ImersaoExterna/${imersaoId}/Comunicacao`, form)
      .then(() => {
        toast({
          title: 'Comunicação criada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        getComunicao()
      })
      .catch(err => console.log(err))
  }

  const PutComunicacao = (): void => {
    const form: iComunicao = {
      descricao: Descriacao,
      uri: Url ?? ''
    }
    appApi.put(`ImersaoExterna/${imersaoId}/Comunicacao`, form)
      .then(() => {
        toast({
          title: 'Comunicação atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getComunicao()
  }, [])

  return (
    <ModalBase
        Titulo="Adicionar comunicação"
        isOpen={isOpen}
        onClose={onClose}
        Width='70rem'
        Subtitulo='Adicione uma imagem, vídeo ou texto para ser exbido no aplicativo juntamente com a pesquisa a ser realizada'
    >
        <Flex padding={'1rem'} flexDir={'column'}>
            <Flex onClick={() => console.log(Url.length)} boxShadow={'var(--SombraBackground)'} flexDir={'column'} marginBottom={'1rem'} bg={'var(--c2)'} padding={'1rem'} borderRadius={'1rem'}>
                <span>Selecione um arquivo de imagem (*.jpg ou *.png) ou de vídeo(*.mp4) com o vídeo de sensibilização (tamanho max. 30MB)</span>
                <FileInput>
                    <input
                        id='i-Com'
                        type={'file'}
                        onChange={AtualizarArquivo}
                        placeholder={'https://sigah.blob.core.windows.net/onboard/16a0fa59-bef8-4590-9db1-3c80885470d3.png'}
                        style={{ display: Url.length > 0 ? 'none' : 'inherit' }}
                    />
                    {Url.length > 0 &&
                        <Flex alignItems={'center'}>
                            <label htmlFor='i-Com'>Trocar arquivo</label>
                            <span>Arquivo selecionado</span>
                        </Flex>
                    }
                </FileInput>
            </Flex>
            <FormControl>
                <FormLabel>Digite o texto que será exibido na tela de início:</FormLabel>
                <Textarea
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setDescriacao(e.target.value)}
                    value={Descriacao}
                />
            </FormControl>
        </Flex>
        <Flex justifyContent={'end'} padding={'0 1rem 1rem 1rem'}>
            <Button marginRight={'1rem'} size={'lg'} VarColor='c5' onClick={onClose}>Fechar</Button>
            {
                isEdit
                  ? <Button onClick={PutComunicacao} VarColor='v2' size={'lg'}>Editar</Button>
                  : <Button onClick={PostComunicacao} VarColor='v2' size={'lg'}>Adicionar</Button>
            }
        </Flex>
    </ModalBase>
  )
}
