/* eslint-disable no-return-assign */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Flex, Switch, Text, Box, FormControl, FormLabel, Select, Input, InputRightElement, InputGroup, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/Button'
import { WhiteBox } from '../../../../../components/WhiteBox'
import { Body } from '../../../../Layouts/Body'
import { BoxAzul, OutlineListaContainer } from './styles'
import { FaPeopleArrows, FaSearch } from 'react-icons/fa'
import { ReuniaoOneChart } from './Components/PieChart'
import { CardProximaReuniao } from './Components/CardProximaReuniao'
import { appApi, urlApi } from '../../../../../services/appApi'
import { converterData, ObterToken } from '../../../../../Utils/Helper'
import { iModalAgendamentoInput } from '../../Assunto'
import { ModalReagendar } from '../../Assunto/components/ModalReagendar'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { TabListaPorReuniao } from '../Corporativo/Components/TabListaPorReuniao'
import { CsvButton } from '../../../../../components/Buttons/CsvButton'
import { parseJwt } from '../../../../../services/token'

interface iModel {
  principal?: iPrincipal
  tabela: iLinhaTabela[]
}

export interface iPrincipal {
  id: string
  nomeParticipante: string
  avatar: string
  dataInicio: string
  diaSemana: string
  assunto: string
}

export interface iLinhaTabela {
  id: string
  criador: iAvatarXInformacoes
  convidado: iAvatarXInformacoes
  tipo: string
  assunto: string
  status: number
  dataInicio: string
}

export interface iAvatarXInformacoes {
  id: string
  avatar: string
  info1: string
  info2?: string
  info3?: string
}

interface iFiltro {
  search: string
  dataInicio: string
  dataFim: string
  status: string
}

export const DashboardIndividualReuniaoOne: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [Model, setModel] = useState<iModel>({
    tabela: []
  })

  const [Filtro, setFiltro] = useState<iFiltro>({
    dataFim: '',
    dataInicio: '',
    search: '',
    status: ''
  })
  const [IsLoading, setIsLoading] = useState(false)

  const [Id, setId] = useState<string>()
  const [ReagendarIsOpen, setReagendarIsOpen] = useState(false)
  const [DeletarIsOpen, setDeletarIsOpen] = useState(false)

  function Carregar(): void {
    setIsLoading(true)
    appApi.get('ReuniaoOne/Dashboard/Individual')
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function ConverterDataToBr(data: string): string {
    const [ano, mes, dia] = data.split('-')
    return `${dia}-${mes}-${ano}`
  }

  function ReenviarConvite(id: string): void {
    appApi.post(`ReuniaoOne/${id}/ReenviarNotificacao`)
      .then(() => toast({
        title: 'Notificação reenviada com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      }))
      .catch(err => console.log(err))
  }

  function ReagendarReuniao(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${Id ?? ''}/Reagendar`, form)
      .then(() => {
        toast({
          title: 'Solicitação de reagendamento com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setReagendarIsOpen(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    appApi.delete(`ReuniaoOne/${Id ?? ''}`)
      .then(() => {
        toast({
          title: 'Reunião excluida com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModel({ ...Model, tabela: [...Model.tabela.filter(e => e.id !== Id)] })
      })
      .catch(err => console.log(err))
  }

  function onOpenReagendar(id: string): void {
    setReagendarIsOpen(true)
    setId(id)
  }

  function onCloseReagendar(): void {
    setReagendarIsOpen(false)
    setId(undefined)
  }

  function onOpenDelete(id: string): void {
    setDeletarIsOpen(true)
    setId(id)
  }

  function onCloseDelete(): void {
    setDeletarIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    Carregar()
  }, [])

  return (
		<Body isLoading={IsLoading}>
			{ReagendarIsOpen &&
				<ModalReagendar
					alvo={Model.tabela.find(e => e.id === Id)?.convidado ?? {
					  avatar: '',
					  id: '',
					  info1: ''
					}}
					onClose={onCloseReagendar}
					onReagendar={ReagendarReuniao}
				/>
			}

			{DeletarIsOpen &&
				<ModalDelete
					isOpen
					onConfirm={Deletar}
					onRequestClose={onCloseDelete}
					message='Deseja realmente excluir essa reunião 1:1?'
				/>
			}

			<WhiteBox>
				<Flex mb={'1rem'} justifyContent={'space-between'}>
					<h1>Reunião 1:1</h1>
					<Button onClick={() => nav(`${pathname.replace('Dashboard/Individual', 'Criar')}`)} VarColor='Azul' size={'lg'}>Solicitar reunião 1:1</Button>
				</Flex>
				{(parseJwt().adm === 'True' || parseJwt().role === '2') &&
					<Flex alignItems={'center'} gap={'.5rem'}>
						<Text color={'var(--Azul)'} fontWeight={'medium'}>Visão individual</Text>
						<Switch
							size={'md'}
							onChange={() => nav(`${pathname.replace('Individual', 'Corporativo')}`)}
						/>
						<Text fontWeight={'medium'}>Visão corporativa</Text>
					</Flex>
				}
				<Flex justifyContent={'space-between'} mt='1rem' maxH={'10rem'} gap={'1rem'}>
					<CardProximaReuniao model={Model.principal} />
					<Box borderRadius={'1rem'} border={'1px solid var(--Azul)'}>
						<Flex alignItems={'center'} borderRadius={'1rem'} justifyContent={'center'} gap={'1rem'} backgroundColor={'#fff'} padding={'1rem'} overflow={'hidden'}>
							{!IsLoading && <ReuniaoOneChart
								id={'i-chart'}
								model={{
								  aguardando: Model.tabela.filter(e => e.status === 0).length,
								  aceita: Model.tabela.filter(e => e.status === 1).length,
								  recusada: Model.tabela.filter(e => e.status === 2).length,
								  reagendada: Model.tabela.filter(e => e.status === 3).length,
								  atrasada: Model.tabela.filter(e => e.status === 4).length,
								  finalizada: Model.tabela.filter(e => e.status === 5).length
								}} />}
						</Flex>
					</Box>
					<BoxAzul>
						<Flex alignItems={'center'} justifyContent={'center'} gap={'1rem'} px={'2rem'}>
							<FaPeopleArrows size={40} />
							<Box>
								<Text fontSize={'28px'} fontWeight={'700'}>{Model.tabela.filter(e => e.status === 5).length}</Text>
								<Text fontSize={'20px'} fontWeight={'400'} mt={'-5px'}>Realizadas</Text>
							</Box>
						</Flex>
					</BoxAzul>
				</Flex>
				<Flex gap={'2rem'} alignItems={'flex-end'} mt={'1rem'}>
					<FormControl>
						<FormLabel>Status</FormLabel>
						<Select onChange={(e) => setFiltro({ ...Filtro, status: e.target.value })}>
							<option value="">Todos</option>
							<option value="0">Aguardando resposta</option>
							<option value="1">Aceita</option>
							<option value="2">Recusada</option>
							<option value="3">Reagendar</option>
							<option value="4">Atrasada</option>
							<option value="5">Finalizada</option>
						</Select>
					</FormControl>
					<FormControl>
						<FormLabel>Periodo</FormLabel>
						<Flex alignItems={'center'} gap={'1rem'}>
							De: <Input onChange={(e) => setFiltro({ ...Filtro, dataInicio: e.target.value })} type={'date'} />
							Até: <Input onChange={(e) => setFiltro({ ...Filtro, dataFim: e.target.value })} type={'date'} />
						</Flex>
					</FormControl>
					<InputGroup width={'100%'}>
						<Input
							borderColor={'var(--Gray4)'}
							type='text'
							placeholder='Pesquisa'
							onChange={(e) => setFiltro({ ...Filtro, search: e.target.value })}
						/>
						<InputRightElement
							pointerEvents='none'
							children={<FaSearch color='var(--Gray4)' />}
						/>
					</InputGroup>
				</Flex>
				<OutlineListaContainer>
					<Flex justifyContent={'space-between'}>
						<Text fontWeight={'700'} fontSize={'24px'}>Listas 1:1</Text>
						<CsvButton onClick={() => window.location.href = `${urlApi}/ReuniaoOne/Csv/Colaborador?token=${ObterToken()}`} />
					</Flex>
					<TabListaPorReuniao
						onReenviar={ReenviarConvite}
						onRemarcar={onOpenReagendar}
						onExcluir={onOpenDelete}
						reunioes={Model.tabela.filter((r) => {
						  if (Filtro.status !== '' && Filtro.status !== r.status.toString()) {
						    return null
						  }
						  if (Filtro.search !== '' && (!r.assunto.toLowerCase().includes(Filtro.search.toLowerCase()) && !r.convidado.info1.toLowerCase().includes(Filtro.search.toLowerCase()) && !r.criador.info1.toLowerCase().includes(Filtro.search.toLowerCase()))) {
						    return null
						  }

						  if ((Filtro.dataInicio !== '' || Filtro.dataFim !== '') && r.dataInicio === 'Data não definida') {
						    return null
						  }

						  if (Filtro.dataInicio !== '' && converterData(r.dataInicio.split(' ')[0]) < converterData(ConverterDataToBr(Filtro.dataInicio).replaceAll('-', '/'))) {
						    return null
						  }
						  if (Filtro.dataFim !== '' && converterData(r.dataInicio.split(' ')[0]) > converterData(ConverterDataToBr(Filtro.dataFim).replaceAll('-', '/'))) {
						    return null
						  }
						  return r
						})}
						individual={true}
					/>
				</OutlineListaContainer>
			</WhiteBox>
		</Body>
  )
}
