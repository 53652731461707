/* eslint-disable no-tabs */
import styled from 'styled-components'

export const OutlineContainer = styled.div`
  border-radius: 0 24px 24px 24px;
`
export const OutlineTable = styled.div`
  border: 1px solid var(--Gray5);
  padding: 1rem;
  border-radius: 12px;
  margin-top: 1rem;
	th {
		padding: .5rem 0 .5rem 0;
		font-size: 12px;
		font-weight: 700;
		color: #4A5568;
		:first-child{
			text-align: start;
			padding-left: 1.5rem;
		}
	}
	td {
		background-color: var(--Gray6);
		padding: 1rem 0 1rem 0;
		border-bottom: 8px solid #fff;
		border-top: 5px solid #fff;
		:last-child {
			padding-top: 0;
			height: 0;
		}
	}
`
export const StyledSpan = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .5rem;
`
