import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex, Grid, Switch, Text, useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { FaFilter, FaRegPlayCircle, FaUsers } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { ModalDelete } from '../../../../components/ModalDelete'
import { Tag } from '../../../../components/Tag'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iColaboradorBase } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { DrawerFiltro } from './components/DrowerFiltro'
import { PieChart } from './components/PieChart'
import { Tabela } from './components/Tabela'
import { TabelaCompetencias } from './components/TabelaCompetencias'
import { ContainerFiltro, OutlineContainer } from './styles'

interface iPieChart {
  concluidos: number
  emAndamento: number
  atrasados: number
  emAprovacao: number
}

interface iDadoTabela {
  id: string
  colaborador: iColaboradorBase
  origem: string
  totalAcoesRealizadas: number
  totalAcoes: number
  prazo: string
  notaUltimaAvaliacao?: number
  status: number
  totalAcoesAtrasadas: number
}

interface iDadoTabelaCompetencia {
  assunto: string
  totalPessoas: number
}

interface iModel {
  totalPDIEmAndamento: number
  totalAcoes: number
  grafico: iPieChart
  competencias: iDadoTabelaCompetencia[]
  dados: iDadoTabela[]
}

interface iFiltro {
  dataInicio?: string
  dataFim?: string
  status?: string
  area?: string
  squad?: string
  cargo?: string
  origem?: string
  acao?: string
}

interface iPendente {
  colaborador: iColaboradorBase
  pdi: {
    id: string
  }
}

export const DashboardCorporativoPDI: React.FC = () => {
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [IdAtual, setIdAtual] = useState<string>('')
  const [Model, setModel] = useState<iModel>()
  const { pathname } = useLocation()
  const nav = useNavigate()
  const [DrowerIsOpen, setDrowerIsOpen] = useState(false)
  const [Pendente, setPendente] = useState<iPendente>()
  const [Tags, setTags] = useState<string[]>([])
  const toast = useToast()

  function ObterTags(model: iFiltro): void {
    setTags([])
    if (model.area) {
      setTags(oldArray => [...oldArray, 'Área'])
    }

    if (model.cargo) {
      setTags(oldArray => [...oldArray, 'Cargo'])
    }

    if (model.dataFim ?? model.dataInicio) {
      setTags(oldArray => [...oldArray, 'Data de início e fim'])
    }

    if (model.origem) {
      setTags(oldArray => [...oldArray, 'Origem'])
    }

    if (model.squad) {
      setTags(oldArray => [...oldArray, 'Squad'])
    }

    if (model.status) {
      setTags(oldArray => [...oldArray, 'Status'])
    }

    if (model.acao) {
      setTags(oldArray => [...oldArray, 'Acao'])
    }
  }

  function getDashboard(): void {
    appApi.get('PDI/Dashboard/Corporativo')
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }
  function DeletarPDI(): void {
    appApi.delete(`PDI/${IdAtual}`).then(() => {
      toast({
        title: 'PDI removido com sucesso!',
        position: 'top-right',
        status: 'success',
        duration: 5000,
        isClosable: false
      })
      onClose()
      getDashboard()
    }).catch(err => err)
  }

  function onClose(): void {
    setModalDeleteIsOpen(false)
    setIdAtual('')
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setIdAtual(id)
  }

  function FiltrarDashboard(params: iFiltro): void {
    ObterTags(params)

    const filter = `a=1${params.dataInicio === '' ? '' : `&dataInicio=${params.dataInicio ?? ''}`}${params.dataFim === '' ? '' : `&dataFim=${params.dataFim ?? ''}`}${params.origem === '' ? '' : `&origem=${params.origem ?? ''}`}${params.status === '' ? '' : `&status=${params.status ?? ''}`}${params.acao === '' ? '' : `&acao=${params.acao ?? ''}`}${params.area === '' ? '' : `&area=${params.area ?? ''}`}${params.squad === '' ? '' : `&squad=${params.squad ?? ''}`}${params.cargo === '' ? '' : `&cargo=${params.cargo ?? ''}`}`
    appApi.get(`PDI/Dashboard/Corporativo/?${filter}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function getEnvioPendente(): void {
    appApi.get('PDI/Dashboard/Corporativo/Pendente')
      .then(res => setPendente(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getDashboard()
    getEnvioPendente()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir o PDI deste colaborador?'
        onRequestClose={onClose}
        onConfirm={DeletarPDI}
      />
      <DrawerFiltro
        isOpen={DrowerIsOpen}
        onClose={() => setDrowerIsOpen(false)}
        onFilter={FiltrarDashboard}
      />
      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <h1>Desempenho {'>'} PDI</h1>
          <Button onClick={() => nav('/Desempenho/PDI/Criar')} VarColor='Azul' size={'lg'}>Criar PDI</Button>
        </Flex>
        <Flex alignItems={'center'} gap={'.5rem'} margin={'1rem 0'}>
          <Text color={pathname.includes('Individual') ? 'var(--Azul)' : ''} fontWeight={'medium'}>Visão individual</Text> <Switch size={'md'} onChange={(e) => e.target.checked ? nav('/Desempenho/PDI/Dashboard/Corporativo') : nav('/Desempenho/PDI/Dashboard/Individual')} isChecked={pathname.includes('Corporativo')} /> <Text color={pathname.includes('Corporativo') ? 'var(--Azul)' : ''} fontWeight={'medium'}>Visão corporativa</Text>
        </Flex>

        {Pendente?.colaborador &&
          <Alert marginBottom={'1rem'} borderRadius={'8px'} justifyContent='space-between' status='warning'>
            <Flex>
              <AlertIcon color={'#FF9900'} />
              <AlertTitle>PDI aguardando aprovação!</AlertTitle>
              <AlertDescription>{Pendente.colaborador.nome} está aguardando a aprovação de seu plano. <AlertDescription cursor={'pointer'} textDecoration={'underline'} onClick={() => nav(`/Rh/VisualizarPerfil/${Pendente.colaborador.id ?? ''}/?pdiId=${Pendente.pdi.id ?? ''}`)}>Clique aqui para visualizar o perfil</AlertDescription></AlertDescription>
            </Flex>
            <MdClose onClick={() => setPendente(undefined)} cursor={'pointer'} size={24} />
          </Alert>
        }

        <OutlineContainer theme={'Gray3'}>
          <ContainerFiltro>
            <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
              <Flex alignItems={'center'}>
                <Text fontWeight={'500'} marginRight={'1rem'}>Filtros aplicados</Text>
                <Flex padding={'0 1rem'} borderLeft={'2px solid var(--Gray4)'} gap={'1rem'}>
                  {Tags.length === 0 && <Tag>Todos</Tag>}

                  {
                    Tags.map((e) => {
                      return (
                        <Tag>{e}</Tag>
                      )
                    })
                  }
                </Flex>
              </Flex>
              <FaFilter onClick={() => setDrowerIsOpen(true)} color='var(--c6)' cursor={'pointer'} size={22} />
            </Flex>
          </ContainerFiltro>
        </OutlineContainer>
        <Grid gap={'1rem'} templateColumns='repeat(2, 1fr)' margin={'1rem 0'}>
          <Flex flexDir={'column'}>
            <Grid marginBottom={'1rem'} gap={'2rem'} templateColumns='repeat(2, 1fr)'>
              <OutlineContainer theme={'Azul'}>
                <Flex height={'100%'} justifyContent={'space-between'} color={'var(--Azul)'} padding={'1rem 0'} flexDir={'column'}>
                  <Text textAlign={'center'} fontWeight={'500'}>Colaboradores com PDI em andamento</Text>
                  <Flex gap={'1rem'} justifyContent={'center'} alignItems={'center'}>
                    <FaUsers size={70} />
                    <Text fontWeight={'500'} fontSize={'3.8rem'}>{Model?.totalPDIEmAndamento ?? '0'}</Text>
                  </Flex>
                </Flex>
              </OutlineContainer>

              <OutlineContainer theme={'Azul'}>
                <Flex height={'100%'} justifyContent={'space-between'} color={'var(--Azul)'} padding={'1rem 0'} flexDir={'column'}>
                  <Text textAlign={'center'} fontWeight={'500'}>Total de ações</Text>
                  <Flex gap={'1rem'} justifyContent={'center'} alignItems={'center'}>
                    <FaRegPlayCircle size={70} />
                    <Text fontWeight={'500'} fontSize={'3.8rem'}>{Model?.totalAcoes ?? '0'}</Text>
                  </Flex>
                </Flex>
              </OutlineContainer>
            </Grid>
            {
              (Model && (Model.grafico.atrasados > 0 || Model.grafico.concluidos > 0 || Model.grafico.emAndamento > 0 || Model.grafico.emAprovacao > 0)) && (
                <OutlineContainer theme={'Azul'}>
                  <Flex alignItems={'center'} width={'100%'} color={'var(--Azul)'} padding={'1rem 0'} flexDir={'column'}>
                    <Text marginBottom={'1rem'} textAlign={'center'} fontWeight={'700'}>Total de ações</Text>
                    <PieChart
                      atrasados={Model.grafico.atrasados}
                      concluidos={Model.grafico.concluidos}
                      emAndamento={Model.grafico.emAndamento}
                      emAprovacao={Model.grafico.emAprovacao}
                      id='chart-1'
                    />
                  </Flex>
                </OutlineContainer>
              )
            }
          </Flex>
          {
            (Model && Model.competencias.length > 0) && (
              <OutlineContainer theme={'Azul'}>
                <Text fontSize={'1.375rem'} fontWeight={'bold'}>Competências em desenvolvimento</Text>
                <Flex marginTop={'1rem'} height={'100%'} borderRadius={'12px'} border='1px solid var(--gray200)'>
                  <TabelaCompetencias dados={Model.competencias} />
                </Flex>
              </OutlineContainer>
            )
          }
        </Grid>
        {
          (Model && Model?.dados.length > 0) && (
            <OutlineContainer theme={'Azul'}>
              <Tabela dados={Model.dados} onOpenModalDelete={onOpenModalDelete}/>
            </OutlineContainer>
          )
        }
      </WhiteBox>
    </Body>
  )
}
