/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { CardOnboard } from './Components/CardOnboard'
import { InfoBox } from './styles'
import { CardCriarOnboard } from './Components/CardCriarOnboard'
import { useEffect, useState } from 'react'
import { ModalAdicionarEtapa } from './Components/ModalAdicionarEtapa'
import { iCardEtapa, iGrupoTrilha } from '../../../interfaces'
import { appApi, urlApi } from '../../../services/appApi'
import { useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../Layouts/Body'
import { WhiteBox } from '../../../components/WhiteBox'
import { ModalDelete } from '../../../components/ModalDelete'
import { TagContainer } from '../../../components/TagContainer'
import { Alert, AlertIcon, Flex, Input, InputGroup, InputLeftElement, Text, useToast } from '@chakra-ui/react'
import { parseJwt } from '../../../services/token'
import { Button } from '../../../components/Button'
import { ObterToken, PropInvalida } from '../../../Utils/Helper'
import { ModalChangeTrilha } from './Components/ModalChangeTrilha'
import { FaFileCsv, FaSearch } from 'react-icons/fa'

export interface iPutOrdenacao {
  etapaId: string
  tempoInicio?: number
  tempoDuracao?: number
  etapaSuperior?: string
  etapaInferior?: string
  etapaPai?: string
}

type LocationProps = {
  trilhaId: string
}

export const ConfigurarTrilha: React.FC = () => {
  const { trilhaId } = useParams<LocationProps>()

  const toast = useToast()
  const nav = useNavigate()
  const [Search, setSearch] = useState('')
  const [Trilha, setTrilha] = useState<iGrupoTrilha>({
    categoria: 0,
    cor: '',
    id: '',
    nome: '',
    tags: [],
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  })

  const [Etapas, setEtapas] = useState<iCardEtapa[]>([])

  const redirectPage = (url: string): any => { window.location.href = url }
  const [ShowModalAddEtapa, setShowModalAddEtapa] = useState(false)
  const [ModalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)
  const [Id, setId] = useState('')
  const [ModalTrilhaIsOpen, setModalTrilhaIsOpen] = useState(false)
  const [Etapa, setEtapa] = useState<iCardEtapa>()
  const [BkpEtapas, setBkpEtapas] = useState<iCardEtapa[]>([])

  useEffect(() => {
    if (trilhaId) {
      ObterTrilha()
      ObterEtapas()
    }
  }, [trilhaId])

  function ObterEtapas(): void {
    appApi.get(`Etapa?idTrilha=${trilhaId as string}`)
      .then(response => { setEtapas(response.data) })
      .catch(e => console.log(e))
  }

  function DeletarEtapa(): void {
    appApi.delete(`Etapa/${Id}`)
      .then(() => {
        toast({
          title: 'Etapa deletada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        ObterEtapas()
      })
      .catch(response => console.log(response))
  }

  function CopiarTrilha(id: string): void {
    appApi.post(`GrupoTrilha/Copy/${id}`)
      .then(() => {
        toast({
          title: 'Sucesso!',
          description: 'Trilha duplicada com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function ObterTrilha(): void {
    appApi.get(`GrupoTrilha/${trilhaId as string}`)
      .then(response => {
        setTrilha(response.data)
      })
      .catch(err => console.error(err))
  }

  function AtualizarSelecao(tags: string[]): void {
    appApi.put(`GrupoTrilha/${trilhaId as string}/Tags`, tags)
      .then(() => ObterTrilha())
      .catch(err => console.log(err))
  }

  function addTag(tag: string): void {
    const tags = [...Trilha?.tags ?? []]
    tags.push(tag)
    setTrilha({ ...Trilha, tags: tags })
    AtualizarSelecao(tags)
  }

  function removeTag(id: string): void {
    const filter = Trilha?.tags?.filter(r => r !== id)
    setTrilha({ ...Trilha, tags: filter })
    AtualizarSelecao(filter ?? [])
  }

  function closeModalConfirm(): void {
    setModalConfirmIsOpen(false)
    setId('')
  }

  function onOpenModalDelete(id: string): void {
    setModalConfirmIsOpen(true)
    setId(id)
  }

  function AtualizarEtapas(etapaSelecionada: iCardEtapa, etapas: iCardEtapa[]): void {
    if (BkpEtapas.length === 0) {
      setBkpEtapas(Etapas)
    }
    setEtapa(etapaSelecionada)
    setEtapas(etapas)
  }

  function SalvarOrdenacao(etapa: iPutOrdenacao): void {
    appApi.put(`Etapa/${trilhaId ?? ''}/AtualizarOrdem`, {
      etapa: etapa,
      listaOrdenada: Etapas.filter(e => e.tipo !== 0).map(e => e.id)
    })
      .then(() => {
        toast({
          title: 'Trilha ordenada com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        setModalTrilhaIsOpen(false)
        ObterEtapas()
        setEtapa(undefined)
        setBkpEtapas([])
      })
      .catch(err => console.log(err))
  }

  function CancelarEdicao(): void {
    setEtapas(BkpEtapas)
    setEtapa(undefined)
  }

  function BaixarCsvTrilha(): void {
    redirectPage(`${urlApi}/Etapa/${trilhaId}/EtapasTrilhaCsv?token=${ObterToken()}`)
  }

  return (
    <Body>
      {ModalTrilhaIsOpen &&
        <ModalChangeTrilha
          onClose={() => setModalTrilhaIsOpen(false)}
          etapa={Etapa}
          etapas={Etapas}
          onSave={SalvarOrdenacao}
        />
      }
      <ModalDelete
        isOpen={ModalConfirmIsOpen}
        onRequestClose={closeModalConfirm}
        onConfirm={DeletarEtapa}
        message='Realmente deseja excluir essa etapa?'
      />

      <ModalAdicionarEtapa
        OnCloseModal={() => { setShowModalAddEtapa(false) }}
        isOpen={ShowModalAddEtapa}
        tipo={Trilha.categoria}
      />

      <Flex marginBottom={'1rem'}>
        <Button VarColor='c6' onClick={() => nav(`/Jornada/Configuracao?tipo=${Trilha.categoria}`)} size='lg'>Voltar</Button>
      </Flex>

      {Trilha.padrao && <Alert my={4} status='warning'>
        <AlertIcon />
        Essa é uma trilha padrão para visualização, porém para customiza-la  você deve duplica-la.
        <Button onClick={() => CopiarTrilha(Trilha.id)} ml={4} size='sm' VarColor='Roxo'> Clique aqui para duplicar</Button>
      </Alert>}

      <WhiteBox>
        <Flex mb={'2rem'} justifyContent='space-between'>
          <Flex gap='.5rem' flexDir={'column'}>
            <h1>{Trilha.categoria === 0 ? 'Trilha de onboarding' : Trilha.categoria === 1 ? 'Trilha de desenvolvimento' : Trilha.categoria === 3 ? 'Offboarding' : ''} - {Trilha?.nome}</h1>
            <TagContainer
              Selecionadas={Trilha?.tags ?? []}
              addTag={addTag}
              removeTag={removeTag}
              tipo={0}
              isGrey
            />
          </Flex>
          <InfoBox theme={Trilha.categoria === 3 ? 'Red' : 'a1'}>
            <span>{Etapas.length}</span>
            <h3>Etapas adicionadas</h3>
          </InfoBox>
        </Flex>

        <Flex marginBottom={'2rem'} justifyContent={'space-between'}>
          <Flex flexDir={'column'}>
            <h2>Defina as etapas da trilha de {Trilha.categoria === 0 ? 'onboarding' : Trilha.categoria === 1 ? 'desenvolvimento' : Trilha.categoria === 3 ? 'offboarding' : ''}</h2>
            <Text fontWeight={700} fontSize={'1.25rem'}>Selecione e configure as etapas para personalizar a trilha.</Text>
          </Flex>
          <InputGroup width={'18rem'}>
            <InputLeftElement
              pointerEvents='none'
              children={<FaSearch color='var(--Gray4)' />}
            />
            <Input
              onChange={(e) => { setSearch(e.target.value) }}
              borderColor={'var(--Gray4)'}
              type='text'
              placeholder='Busca'
              bg={'white'}
            />
          </InputGroup>
          <Button VarColor='Azul' leftIcon={<FaFileCsv />} onClick={BaixarCsvTrilha}>
            Baixar CSV
          </Button>
        </Flex>

        <div>
          {
            Etapas.filter(e => { if (Search === '') { return true } else { return e.nome.includes(Search) } }).map((e: iCardEtapa, i) => {
              return (
                <CardOnboard
                  key={i}
                  etapa={e}
                  selecionada={Etapa}
                  isLast={i === Etapas.length - 1}
                  trilhaPadrao={Trilha.padrao}
                  EtapaAnterior={!PropInvalida(Etapas[i - 1]?.nome) ? Etapas[i - 1].nome : undefined}
                  onDeleteEtapa={onOpenModalDelete}
                  etapas={Etapas}
                  onAtualizarEtapas={AtualizarEtapas}
                  onRestaurar={CancelarEdicao}
                  onSalvar={() => setModalTrilhaIsOpen(true)}
                />
              )
            })
          }
          {
            (!Trilha.padrao || parseJwt().uid === '252110c3-295e-4c81-987c-b260b528bdf0') && (
              <CardCriarOnboard
                OnOpenModal={() => setShowModalAddEtapa(true)}
              />
            )
          }
        </div>
      </WhiteBox>
    </Body>

  )
}
