import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { MetaButton } from '../../../../../../components/Buttons/MetaButton'
import { InputFormControl } from '../../../../../../components/InputFormControl'
import { SelectFormControl } from '../../../../../../components/SelectFormControl'
import { ModalBody, ModalContent, ModalFooter } from './styles'

Modal.setAppElement('#root')

interface IDepartment {
  id: string
  nome: string
  departamentoPaiId?: string
}

interface IUpdateDepartmentModalProps {
  isOpen: boolean
  initialData: IDepartment
  departmentList: IDepartment[]
  onRequestClose: () => void
  onUpdateDepartment: (updatedDepartment: IDepartment) => void
}

export const UpdateDepartmentModal: React.FC<IUpdateDepartmentModalProps> = ({
  isOpen,
  initialData,
  departmentList,
  onRequestClose,
  onUpdateDepartment
}) => {
  const [Nome, setNome] = useState('')
  const [IdPai, setIdPai] = useState<string>()

  useEffect(() => {
    setNome(initialData.nome)
    setIdPai(initialData.departamentoPaiId)
  }, [initialData.nome])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-default"
    >
      <ModalContent>
        <h1>Editar área</h1>
        <ModalBody>
        <InputFormControl
            label='Nome da área'
            onChange={(e) => setNome(e.target.value)}
            placeholder='Digite um nome'
            value={Nome}
        />

        <SelectFormControl value={IdPai} label='Área superior' onChange={(e) => setIdPai(e.target.value)}>
          <option value={undefined}>Nenhum</option>
          {
            departmentList.filter((e) => {
              if (e.departamentoPaiId !== initialData.id) {
                return e
              }
              return null
            }).map((e: IDepartment, i) => {
              return (
                <option value={e.id} key={i}>{e.nome}</option>
              )
            })
          }
        </SelectFormControl>
        </ModalBody>
        <ModalFooter>
          <MetaButton bg='c4' size='md' onClick={onRequestClose}>Fechar</MetaButton>
          <MetaButton bg='v2' size='md' onClick={() => onUpdateDepartment({ nome: Nome, departamentoPaiId: IdPai, id: initialData.id })} >Salvar</MetaButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
