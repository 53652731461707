/* eslint-disable @typescript-eslint/no-unused-vars */
export interface Position {
  x: number
  y: number
} export interface CroppedRect extends Position {
  width: number
  height: number
}

const maxW = 200
const maxH = 200

export async function getCroppedImg(this: any,
  imageSrc: string,
  pixelCrop: CroppedRect,
  scaleCanvas?: HTMLCanvasElement,
  position?: Position,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
): Promise<string> {
  const image = await createImage(imageSrc)
  const iw = image.width
  const ih = image.height
  if (scaleCanvas?.width === undefined) {
    return ''
  }
  if (scaleCanvas?.height === undefined) {
    return ''
  }
  const scale = Math.min((scaleCanvas.width / iw), (scaleCanvas.height / ih))
  const iwScaled = iw * scale
  const ihScaled = ih * scale
  if (scaleCanvas !== undefined) {
    pixelCrop.height = pixelCrop.height * ih
    pixelCrop.width = pixelCrop.width * iw
  }
  if (position === undefined) {
    return ''
  }
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  if (!ctx) {
    return ''
  }
  // const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  // const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
  //   image.width,
  //   image.height,
  //   rotation
  // )

  // set canvas size to match the bounding box
  canvas.width = iwScaled
  canvas.height = ihScaled

  // translate canvas context to a central location to allow rotating and flipping around the center
  // ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  // ctx.rotate(rotRad)
  // ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  // ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  // ctx.drawImage(image, 0, 0, iwScaled, ihScaled)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  // const data = ctx.getImageData(
  //   pixelCrop.x,
  //   pixelCrop.y,
  //   pixelCrop.width,
  //   pixelCrop.height
  // )

  // // // set canvas width to final desired crop size - this will clear existing context
  canvas.width = scaleCanvas.width
  canvas.height = scaleCanvas.height
  ctx.drawImage(
    image, pixelCrop.x * iw, pixelCrop.y * ih, pixelCrop.width, pixelCrop.height, 0, 0, scaleCanvas.width, scaleCanvas.height
  )
  //
  /// / // paste generated rotate image at the top left corner
  // ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  // return await new Promise((resolve, reject) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file))
  //   }, 'image/jpeg')
  // })
  // ctx.drawImage(image, 0, 0, iwScaled, ihScaled)
  // console.log(canvas.toDataURL())
  console.log(canvas.toDataURL())
  return canvas.toDataURL()
}

const createImage = async (url: string): Promise<any> =>
  await new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue: number): number {
  return (degreeValue * Math.PI) / 180
}

function rotateSize(width: number, height: number, rotation: number): any {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
          Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
          Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  }
}
