import { Flex, Tooltip, Box } from '@chakra-ui/react'
import { CircleDiv, CircleCard, Container, CardDiv, BackgroundCard, BodyCard, FlexElipse, EditMenu } from './styles'
import { CardReuniao } from '../CardReuniao'
import { CardIntegracao } from '../CardIntegracao'
import { CardPesquisa } from '../CardPesquisa'
import { CardPesquisaStackholder } from '../CardPesquisaStackholder'
import { CardImersao } from '../CardImersao'
import { CardPrimeiroAcesso } from '../CardPrimeiroAcesso'
import { iCardEtapa, iInteracaoEtapa } from '../../../../../interfaces'
import { FaAngleDown, FaAngleUp, FaPen, FaSave, FaTrash } from 'react-icons/fa'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { InsertElementByPosition, ObterCorEtapaPeloTipo, ObterCorFundoEtapaPeloTipo, PropInvalida } from '../../../../../Utils/Helper'
import { CardEnps } from '../CardEnps'
import { parseJwt } from '../../../../../services/token'
import { CardReuniaoOne } from '../CardReuniaoOne'
import { CardPDI } from '../CardPDI'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { IoArrowUndoSharp } from 'react-icons/io5'
import { Button } from '../../../../../components/Button'
import { CardNovaReuniaoOne } from '../CardNovaReuniaoOne'

interface iCardOboard {
  etapa: iCardEtapa
  EtapaAnterior?: string
  isLast: boolean
  trilhaPadrao?: boolean
  etapas: iCardEtapa[]
  selecionada?: iCardEtapa
  onDeleteEtapa?: (id: string) => void
  onAtualizarEtapas?: (etapaSelecionada: iCardEtapa, etapas: iCardEtapa[]) => void
  onSalvar?: () => void
  onRestaurar?: () => void
}

export const CardOnboard: React.FC<iCardOboard> = ({ etapa, EtapaAnterior, isLast, trilhaPadrao, onDeleteEtapa, etapas, onAtualizarEtapas, selecionada, onRestaurar, onSalvar }) => {
  const { trilhaId } = useParams<{ trilhaId: string }>()
  const [isOpen, setisOpen] = useState(false)

  function ObterComponentePeloTipoEtapa(key: number): any {
    if (PropInvalida(etapa.interacoes)) return ''

    if (etapa.interacoes[0].tipo === 0) {
      return (
        <CardPrimeiroAcesso
          key={key}
          isOpen={isOpen}
        />
      )
    } else if (etapa.interacoes[0].tipo === 1) {
      return (
        <CardIntegracao
          key={key}
          isOpen={isOpen}
          etapa={etapa}
        />
      )
    } else if (etapa.interacoes[0].tipo === 2) {
      return (
        <CardReuniao
          key={key}
          isOpen={isOpen}
          etapa={etapa}
        />
      )
    } else if (etapa.interacoes[0].tipo === 3) {
      return (
        <CardImersao
          key={key}
          isOpen={isOpen}
          etapa={etapa}
        />
      )
    } else if (etapa.interacoes[0].tipo === 4) {
      return (
        <CardPesquisa
          key={key}
          etapaAnterior={EtapaAnterior}
          etapa={etapa}
          isOpen={isOpen}
        />
      )
    } else if (etapa.interacoes[0].tipo === 5) {
      return (
        <CardPesquisaStackholder
          key={key}
          isOpen={isOpen}
          etapa={etapa}
        />
      )
    } else if (etapa.interacoes[0].tipo === 6 && (parseJwt().adm === 'True' || parseJwt().role !== '0')) {
      return (
        <CardEnps
          key={key}
          isOpen={isOpen}
          etapa={etapa}
        />
      )
    } else if (etapa.interacoes[0].tipo === 7) {
      return (
        <CardReuniaoOne
          key={key}
          isOpen={isOpen}
          etapa={etapa}
        />
      )
    } else if (etapa.interacoes[0].tipo === 9) {
      return (
        <CardPDI
          key={key}
          isOpen={isOpen}
          etapa={etapa}
        />
      )
    } else if (etapa.interacoes[0].tipo === 10) {
      return (
        <CardNovaReuniaoOne
          key={key}
          etapa={etapa}
          isOpen={isOpen}
        />
      )
    }
  }

  function ObterRedirectPeloTipo(): string {
    if (PropInvalida(etapa.interacoes)) return ''
    const rota = '/Jornada/Configuracao/Trilha'
    if (etapa.tipo === 0) {
      return '/PrimeiroAcesso'
    } else if (etapa.tipo === 1) {
      return `${rota}/${trilhaId ?? ''}/Integracao/${etapa.interacoes[0].idExterno}/Editar/${etapa.id}`
    } else if (etapa.tipo === 2) {
      return `${rota}/${trilhaId ?? ''}/Reuniao/Editar/${etapa.id}`
    } else if (etapa.tipo === 3) {
      return `${rota}/${trilhaId ?? ''}/Imersao/${etapa.interacoes[0].idExterno}/Editar/${etapa.id}`
    } else if (etapa.tipo === 4) {
      return `${rota}/${trilhaId ?? ''}/PesquisaInterna/Editar/${etapa.id}`
    } else if (etapa.tipo === 5) {
      return `/Rh/PesquisaPercepcao/Editar/${etapa.id}/Trilha/${trilhaId ?? ''}`
    } else if (etapa.tipo === 6) {
      return `${rota}/${trilhaId ?? ''}/eNps/Editar/${etapa.id}`
    } else if (etapa.tipo === 7) {
      return `${rota}/${trilhaId ?? ''}/ReuniaoOne/Configuracao/${etapa.id}`
    } else if (etapa.tipo === 9) {
      return `${rota}/${trilhaId ?? ''}/PDI/${etapa.id}`
    } else if (etapa.tipo === 10) {
      return `${rota}/${trilhaId ?? ''}/NovaReuniaoOne/Editar/${etapa.id}`
    }
    return ''
  }

  function DefinirTextoCirculo(): string {
    if (!PropInvalida(etapa.etapaPai)) {
      return 'Após a anterior'
    } else if (!PropInvalida(etapa.tempoInicio)) {
      return `${etapa.tempoInicio ?? ''} ${etapa.tempoInicio === 1 ? 'dia' : 'dias'}`
    } else if (etapa.tipo === 8) {
      return 'Dia do desliga-mento'
    } else if (!PropInvalida(etapa.tempoAntesDesligamento)) {
      return `${etapa.tempoAntesDesligamento ?? ''} ${etapa.tempoAntesDesligamento === 1 ? 'dia' : 'dias antes'}`
    } else if (!PropInvalida(etapa.tempoAposDesligamento)) {
      return `${etapa.tempoAposDesligamento ?? ''} ${etapa.tempoAposDesligamento === 1 ? 'dia' : 'dias após'}`
    } else {
      return ''
    }
  }

  function PossuiSeta(direcao: string): boolean {
    if (etapa.tipo === 0 || (selecionada && selecionada.id !== etapa.id)) return false

    if (direcao === 'down' && isLast) return false

    if (etapas.some(r => r.etapaPai === etapa.id) && direcao === 'down' && etapas.length > 0) {
      const filhos: iCardEtapa[] = []
      let etapaInferior = etapas.find(e => e.etapaPai === etapa.id)
      while (etapaInferior != null) {
        filhos.push(etapaInferior)
        etapaInferior = etapas.find(e => e.etapaPai === etapaInferior?.id)
      }
      if (filhos.length > 0 && filhos[filhos.length - 1].id === etapas[etapas.length - 1].id) {
        return false
      }
    }

    const posEtapa = etapas.findIndex(r => r.id === etapa.id)
    if (posEtapa === -1) return false

    if (direcao === 'up' && (PropInvalida(etapas[posEtapa - 1]) || etapas[posEtapa - 1].tipo === 0)) {
      return false
    }

    return true
  }

  function InternalSubirEtapa(): void {
    const posEtapa = etapas.findIndex(r => r.id === etapa.id)
    if (posEtapa === -1) return

    if (onAtualizarEtapas !== undefined) {
      if (PropInvalida(etapas[posEtapa + 1]?.etapaPai)) {
        const result = InsertElementByPosition(
          [...etapas.filter(e => e.id !== etapa.id)],
          (posEtapa - 1),
          etapa
        )
        onAtualizarEtapas(etapa, result)
      } else {
        const filhos: iCardEtapa[] = []
        let etapaInferior = etapas.find(e => e.etapaPai === etapa.id)
        while (etapaInferior != null) {
          filhos.push(etapaInferior)
          etapaInferior = etapas.find(e => e.etapaPai === etapaInferior?.id)
        }
        const start = [...etapas.slice(0, posEtapa - 1)]
        const midle = [etapa, ...filhos]
        const end = [...etapas.filter(r => !start.some(t => t.id === r.id) && !midle.some(t => t.id === r.id))]

        onAtualizarEtapas(etapa, [...start, ...midle, ...end])
      }
    }
  }

  function InternalDescerEtapa(): void {
    const posEtapa = etapas.findIndex(r => r.id === etapa.id)
    if (posEtapa === -1) return

    if (onAtualizarEtapas !== undefined) {
      if (PropInvalida(etapas[posEtapa + 1]?.etapaPai)) {
        const result = InsertElementByPosition(
          [...etapas.filter(e => e.id !== etapa.id)],
          (posEtapa + 1),
          etapa
        )
        onAtualizarEtapas(etapa, result)
      } else {
        const filhos: iCardEtapa[] = []
        let etapaInferior = etapas.find(e => e.etapaPai === etapa.id)
        while (etapaInferior != null) {
          filhos.push(etapaInferior)
          etapaInferior = etapas.find(e => e.etapaPai === etapaInferior?.id)
        }

        const etapaAbaixo = etapas[posEtapa + filhos.length + 1]
        const start = [...etapas.slice(0, posEtapa), etapaAbaixo]
        const midle = [etapa, ...filhos]
        const end = [...etapas.filter(r => !start.some(t => t.id === r.id) && !midle.some(t => t.id === r.id))]
        onAtualizarEtapas(etapa, [...start, ...midle, ...end])
      }
    }
  }

  return (
    <Container isDisabled={selecionada && selecionada.id !== etapa.id}>
      <Flex>
        {!(etapas.length === 0 || etapas.some(r => r.tipo === 8) || trilhaPadrao) && <Box as='span' position={'absolute'} left={'-1.7rem'} top={'1.3rem'} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'start'}>
          {PossuiSeta('up') && <AiFillCaretUp cursor={'pointer'} onClick={InternalSubirEtapa} />}
          {PossuiSeta('down') && <AiFillCaretDown cursor={'pointer'} onClick={InternalDescerEtapa} />}
        </Box>}
        <CircleDiv>
          <CircleCard
            fontSize={etapa.tempoInicio === null ? '0.875' : '1'}
          >
            <span>{DefinirTextoCirculo()}</span>
          </CircleCard>
          {
            (!isLast) && (
              <hr />
            )
          }
        </CircleDiv>
        <FlexElipse color={ObterCorEtapaPeloTipo(etapa.tipo)}>
          <div></div>
          <div></div>
          <div></div>
        </FlexElipse>
        <BackgroundCard isOpen={isOpen} theme={ObterCorFundoEtapaPeloTipo(etapa.tipo)}>

          <CardDiv onClick={() => setisOpen(PropInvalida(etapa.interacoes) ? false : !isOpen)} theme={ObterCorEtapaPeloTipo(etapa.tipo)}>
            <Flex justifyContent={'space-between'}>
              <Flex alignItems={'center'}>
                <h3>{etapa.nome}</h3>
              </Flex>
              {!PropInvalida(etapa.interacoes) &&
                <>
                  {
                    !isOpen
                      ? <FaAngleDown size={'1.5rem'} style={{ marginLeft: '1rem', position: 'relative', top: '40%' }} />
                      : <FaAngleUp size={'1.5rem'} style={{ marginLeft: '1rem', position: 'relative', top: '40%' }} />
                  }
                </>}
            </Flex>
            <span>{etapa.descricao}</span>
          </CardDiv>

          <BodyCard isOpen={isOpen}>
            {
              !PropInvalida(etapa.interacoes) && etapa.interacoes.map((obj: iInteracaoEtapa, i) => {
                return ObterComponentePeloTipoEtapa(i)
              })
            }
          </BodyCard>
        </BackgroundCard>
        {!PropInvalida(etapa.interacoes) && ((trilhaPadrao === null || trilhaPadrao === false)) && (
          <>
            {isOpen &&
              <EditMenu isOpen={isOpen}>
                <Tooltip
                  padding='2'
                  borderRadius='4'
                  placement='top'
                  color={'white'}
                  background={'var(--a4)'}
                  label='Clique para editar a etapa'
                  hasArrow arrowSize={15}>
                  <Link to={ObterRedirectPeloTipo()}><FaPen style={{ marginLeft: '1rem' }} /></Link>
                </Tooltip>
                {
                  etapa.interacoes[0].tipo > 0 &&
                  <Tooltip padding='2'
                    borderRadius='4'
                    placement='top'
                    color={'white'}
                    background={'var(--a4)'}
                    label='Clique para excluir a etapa'
                    hasArrow arrowSize={15}>
                    <a onClick={() => onDeleteEtapa !== undefined ? onDeleteEtapa(etapa.id) : ''}><FaTrash /></a>
                  </Tooltip>
                }
              </EditMenu>}
            {
            selecionada && selecionada.id === etapa.id &&
              <EditMenu isOpen={true} style={{ marginTop: '1rem', gap: '1rem' }}>
                <Button
                  variant={'solid'}
                  border={'2px solid var(--Green2)'}
                  VarColor={'White'}
                  color={'var(--Green2)'}
                  leftIcon={<FaSave />}
                  onClick={onSalvar}
                >Salvar ordem</Button>

                <Button
                  variant={'solid'}
                  border={'2px solid var(--Gray3)'}
                  VarColor={'White'}
                  color={'var(--Gray3)'}
                  leftIcon={<IoArrowUndoSharp />}
                  onClick={onRestaurar}
                >Restaurar</Button>
              </EditMenu>
            }
          </>
        )}
      </Flex>
    </Container >
  )
}
