import { useEffect, useState } from 'react'
import { Body } from '../../Layouts/Body'
import { WhiteBox } from '../../../components/WhiteBox'
import { iPerguntasFrequentes } from '../../../interfaces'
import { ModalTopico } from './Components/ModalTopico'
import { TextBox } from './Components/TextBox'
import { TextBoxAdd } from './Components/TextBoxAdd'
import { Main } from './styles'
import { appApi } from '../../../services/appApi'
import { Flex } from '@chakra-ui/react'

export const PerguntasFrequentesRh: React.FC = () => {
  const [ModalIsOPen, setModalIsIOpen] = useState(false)
  const [Pergunta, setPergunta] = useState<iPerguntasFrequentes>()
  const [Perguntas, setPerguntas] = useState<iPerguntasFrequentes[]>([])

  function onRequestClose(): void {
    setModalIsIOpen(false)
    setPergunta(undefined)
  }

  function onRequestOpen(pergunta: iPerguntasFrequentes): void {
    setPergunta(pergunta)
    setModalIsIOpen(true)
  }

  function obterPerguntas(): void {
    appApi.get('PerguntasFrequentes').then(res => setPerguntas(res.data)).catch(err => console.log(err))
  }

  function CriarPergunta(titulo: string, descricao: string): void {
    const form = {
      Titulo: titulo,
      Descricao: descricao
    }

    appApi.post('PerguntasFrequentes', form)
      .then(() => {
        onRequestClose()
        obterPerguntas()
      })
      .catch(err => console.log(err))
  }

  function AtualizarPergunta(titulo: string, descricao: string): void {
    const form = {
      titulo: titulo,
      descricao: descricao
    }
    appApi.put(`PerguntasFrequentes/${Pergunta?.id ?? ''}`, form)
      .then(() => {
        onRequestClose()
        obterPerguntas()
      })
      .catch(err => console.log(err))
  }

  function DeletarPergunta(): void {
    appApi.delete(`PerguntasFrequentes/${Pergunta?.id ?? ''}`)
      .then(() => {
        onRequestClose()
        obterPerguntas()
      }).catch(err => console.log(err))
  }

  useEffect(() => {
    obterPerguntas()
  }, [])

  return (
    <Body>
      {
        (ModalIsOPen) && (
          <ModalTopico
            onRequestClose={onRequestClose}
            Create={CriarPergunta}
            Delete={DeletarPergunta}
            Update={AtualizarPergunta}
            isOpen={ModalIsOPen}
            pergunta={Pergunta}
          />
        )
      }
      <h1 style={{ margin: '2rem 0rem' }}>Configure e personalize as perguntas mais frequentes dentro de sua organização</h1>
      <WhiteBox>
        <Main>
          <img src='https://sigah.blob.core.windows.net/onboard/93e163ff-dc29-4ffb-9c95-cd44d3a2d68d.png' />
          <section>
            <h2>Perguntas frequentes</h2>
            <Flex direction={'column'} overflowY={'auto'} maxHeight={'26rem'} pr={'0.5rem'}>
              {
                Perguntas.map((e: iPerguntasFrequentes, i) => {
                  return (
                    <TextBox
                      key={i}
                      pergunta={e}
                      isFirst={i === 0}
                      openModal={onRequestOpen}
                    />
                  )
                })
              }
            </Flex>
            <TextBoxAdd
              onClick={() => setModalIsIOpen(true)}
            />
          </section>
        </Main>
      </WhiteBox>
    </Body>
  )
}
