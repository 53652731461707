import { Checkbox, Flex, FormLabel, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { Button } from '../../../../../components/Button'
import { EditorTexto } from '../../../../../components/EditorTexto/Index'
import { ButtonTag } from '../ButtonTag'

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

interface iProps {
  model: iTextosPesquisa[]
  onChange: (textos: iTextosPesquisa[]) => void
  onSave: () => void
  openModalTag: () => void
  textoPadrao: string
  titulo: string
  descricao: string
}
export const BoxMultiText: React.FC<iProps> = ({ titulo, descricao, textoPadrao, model, onChange, openModalTag, onSave }) => {
  const { pathname } = useLocation()
  const [Boxs, setBoxs] = useState<iTextosPesquisa[]>([{ texto: textoPadrao, participantes: [0, 1] }])
  const [isLoading, setIsLoading] = useState(true)

  function SelecionarEixo(e: string, index: number): void {
    if (!Boxs[index].participantes.includes(parseInt(e))) {
      AdicionarNovoEixo(e, index)
    } else {
      if (index === 0) {
        CriarNovoEditor(e, index)
      } else if (Boxs[index].participantes.length > 0) {
        ExcluirNovoEixo(parseInt(e), index)
      }
    }
  }

  function AdicionarNovoEixo(e: string, index: number): void {
    const copy = Boxs[index]
    copy.participantes.push(parseInt(e))
    const lista = [...Boxs]
    lista.shift()
    lista.unshift(copy)
    for (let a = 1; a < lista.length; a++) {
      if (lista[a].participantes[0] === parseInt(e)) {
        lista.splice(a, 1)
      }
    }
    setBoxs(lista)
  }

  function CriarNovoEditor(e: string, index: number): void {
    const indexEixo = (ind: number): number => {
      return Boxs[ind].participantes.findIndex(r => r === parseInt(e))
    }
    setBoxs(
      [...Boxs, {
        texto: textoPadrao,
        participantes: Boxs[index].participantes.splice(indexEixo(index), 1)
      }]
    )
  }

  function ExcluirNovoEixo(valorEixoAtual: number, index: number): void {
    const firstElement = Boxs[0]
    firstElement.participantes.push(valorEixoAtual)
    Boxs.shift()
    setBoxs({ ...Boxs.splice(index - 1, 1) })
    const copy = [...Boxs]
    copy.unshift(firstElement)
    setBoxs(copy)
  }

  function AtualizarTexto(value: string, index: number): void {
    const copy = [...Boxs]
    copy[index].texto = value
    onChange(copy)
  }

  useEffect(() => {
    setIsLoading(true)
    setBoxs(model)
    setTimeout(() => setIsLoading(false), 1000)
  }, [model])

  return (
    <Flex flexDir={'column'}>
      {Boxs?.map((elemento, index) => {
        return (
          <div key={index}>
            {index > 0 && (
              <hr style={{ marginTop: '1rem', border: '1px solid var(--c3)' }} />
            )}
            <div>
              {index === 0 && (
                <Flex justifyContent={'space-between'}>
                  <Flex flexDir={'column'}>
                    <h3>{titulo}</h3>
                    <small>{descricao}</small>
                  </Flex>
                  <ButtonTag onClick={openModalTag}>Saiba como utilizar as TAGs</ButtonTag>
                </Flex>
              )}
              <Flex width={'100%'} alignItems='center' justifyContent={'space-between'}>
                <Flex flexDir={'column'}>
                  <FormLabel my={'1rem'} marginRight={'.5rem'}>
                    {index === 0 ? 'Participantes que receberão essa mensagem:' : 'Participante que receberá essa mensagem:'}
                  </FormLabel>
                  <Stack my={'1rem'} spacing={5} direction='row'>
                    {elemento.participantes.includes(0) && (
                      <Checkbox
                        isDisabled={index === 0 && elemento.participantes.length === 1}
                        isChecked={elemento.participantes.includes(0)}
                        value={'0'}
                        onChange={(e) => SelecionarEixo(e.target.value, index)}
                      >
                        Autoavaliação
                      </Checkbox>
                    )}

                    {elemento.participantes.includes(1) && (
                      <Checkbox
                        isChecked={elemento.participantes.includes(1)}
                        value={'1'}
                        onChange={(e) => SelecionarEixo(e.target.value, index)}
                      >
                        Gestor
                      </Checkbox>
                    )}

                    {elemento.participantes.includes(2) && (
                      <Checkbox
                        isChecked={elemento.participantes.includes(2)}
                        value={'2'}
                        onChange={(e) => SelecionarEixo(e.target.value, index)}
                      >
                        Pares
                      </Checkbox>
                    )}

                    {elemento.participantes.includes(3) && (
                      <Checkbox
                        isChecked={elemento.participantes.includes(3)}
                        value={'3'}
                        onChange={(e) => SelecionarEixo(e.target.value, index)}
                      >
                        Liderados
                      </Checkbox>
                    )}

                    {elemento.participantes.includes(6) && (
                      <Checkbox
                        isChecked={elemento.participantes.includes(6)}
                        value={'6'}
                        onChange={(e) => SelecionarEixo(e.target.value, index)}
                      >
                        Alta liderança
                      </Checkbox>
                    )}

                    {elemento.participantes.includes(4) && (
                      <Checkbox
                        isChecked={elemento.participantes.includes(4)}
                        value={'4'}
                        onChange={(e) => SelecionarEixo(e.target.value, index)}
                      >
                        Outros
                      </Checkbox>
                    )}
                  </Stack>
                </Flex>
                {index > 0 && (
                  <Button
                    onClick={() => SelecionarEixo(elemento.participantes[0].toString(), index)}
                    VarColor='terc3'
                  >Excluir mensagem</Button>
                )}
              </Flex>
              <FormLabel my={'.2rem'} marginRight={'.5rem'} color={'var(--c7)'} fontSize={'10pt'} pb={'.6rem'} mt={'-.1rem'}>
                Ao desselecionar um eixo é gerado um novo editor de texto personalizado para ele
              </FormLabel>
            </div>
            <EditorTexto
              isLoading={isLoading}
              initialValue={elemento.texto}
              onBlur={(e: string) => AtualizarTexto(e, index)}
            />
            <Flex justifyContent={'space-between'} marginTop='1rem'>
              <Flex gap={'1rem'} justifyContent='space-between' w={'99%'}>
                <Button
                  VarColor='c6'
                  onClick={() => {
                    AtualizarTexto(textoPadrao, index)
                  }}
                >Restaurar texto padrão</Button>

                {pathname.includes('Editar') && <Button
                  VarColor='Green2'
                  onClick={onSave}
                  rightIcon={<FaSave/>}
                >Salvar</Button>}
              </Flex>
            </Flex>
          </div>
        )
      })}
    </Flex>
  )
}
