import { Flex, Text, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaPlus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/Button'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { OutlineMetaButton } from '../../../../../components/Buttons/OutlineMetaButton'
import { FindUser } from '../../../../../components/FindBox'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { ModalReabrir } from '../../../../../components/ModalReabrir'
import { iNomeId, iPerfilColaborador, iPostProcessoOnboard, iProcessoOnboard, iPutDesligamento } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { parseJwt } from '../../../../../services/token'
import { useQuery } from '../../../../../Utils/Helper'
import { PopUpDesligarColaborador } from '../../../../Organograma/Desenvolvimento/Colaboradores/Components/PopUpDesligarColaborador'
import { ModalAdicionarEtapa } from '../../../ConfigurarTrilha/Components/ModalAdicionarEtapa'
import { ArrowButton } from '../../styles'
import { BoardBox } from '../BoardBox'
import { CardOnboard } from '../CardOnboard'
import { ContainerTrilha } from '../ContainerTrilha'
import { ColumnTitle, SectionOnboard } from './styles'

interface iProps {
  processoEmAndamento?: iProcessoOnboard
  processosFinalizados: iProcessoOnboard[]
  colaborador: iPerfilColaborador
  Load: () => void
  rh: iNomeId[]
}

export const TabOnboard: React.FC<iProps> = ({ processoEmAndamento, rh, processosFinalizados, colaborador, Load }) => {
  const nav = useNavigate()
  const query = useQuery()
  const toast = useToast()

  const [ModalDesligamentoIsOpen, setModalDesligamentoIsOpen] = useState(false)
  const [ModalAddEtapaIsOpen, setModalAddEtapaIsOpen] = useState(false)
  const [ModalDeleteEtapaIsOpen, setModalDeleteEtapaIsOpen] = useState(false)
  const [ModalReabrirEtapaIsOpen, setModalReabrirEtapaIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [ResponsavelId, setResponsavelId] = useState<string>()

  function onCloseModalDeleteEtapa(): void {
    setModalDeleteEtapaIsOpen(false)
    setId(undefined)
  }

  function onOpenModalDeleteEtapa(id: string): void {
    setModalDeleteEtapaIsOpen(true)
    setId(id)
  }

  function onCloseModalReabrirEtapa(): void {
    setModalReabrirEtapaIsOpen(false)
    setId(undefined)
  }

  function onOpenModalReabrirEtapa(id: string): void {
    setModalReabrirEtapaIsOpen(true)
    setId(id)
  }

  function onDeleteEtapa(): void {
    appApi.delete(`EtapaColaborador/${processoEmAndamento?.id ?? ''}/Etapa/${Id ?? ''}`)
      .then(Load)
      .catch(err => console.log(err))
  }

  function onReabrirEtapa(dataFim: string): void {
    appApi.put(`EtapaColaborador/${colaborador.id}/${Id ?? ''}/Reabrir?dataFim=${dataFim}`)
      .then(() => {
        toast({
          title: 'Pesquisa reagendada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        Load()
      })
      .catch(err => { console.log(err) })
  }

  function onConcludeEtapa(Id: string): void {
    appApi.patch(`EtapaColaborador/${processoEmAndamento?.id ?? ''}/Etapa/${Id}/Concluir`)
      .then(res => console.log(res)).then(Load).catch(err => console.log(err))
  }

  function CriarProcesso(form: iPostProcessoOnboard): void {
    appApi.post('ProcessoOnboard', form).then(() => {
      Load()
      nav(`/Rh/VisualizarPerfil/${colaborador.id ?? ''}`)
      toast({
        title: 'Trilha cadastrada com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      })
    }).catch(err => console.log(err))
  }

  function onUpdateVisibilidade(Id: string): void {
    appApi.patch(`EtapaColaborador/${processoEmAndamento?.id ?? ''}/Etapa/${Id}/Visibilidade`)
      .then(res => console.log(res)).then(Load).catch(err => console.log(err))
  }

  function SelecionarResponsavel(id: string): void {
    if (id === '') {
      setResponsavelId(undefined)
    } else {
      setResponsavelId(id)
    }
  }

  function AtualizarResponsavel(): void {
    appApi.put(`ProcessoOnboard/${processoEmAndamento?.id ?? ''}/Responsavel/?responsavelId=${ResponsavelId as string}`)
      .then(() => {
        setResponsavelId(undefined)
        toast({
          title: 'Responsável pelo onboarding atualizado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
      })
      .catch(err => {
        console.log(err)
        toast({
          title: 'Erro ao atualizar o responsável pelo onboarding',
          status: 'error',
          isClosable: false,
          position: 'top-right'
        })
      })
  }

  function InterromperOnboard(form: iPutDesligamento): void {
    appApi
      .put(`Colaborador/${colaborador.id ?? ''}/Desligar`, form)
      .then(() => {
        if (form.manterAcesso) {
          Load()
          nav('?criar=true')
          setModalDesligamentoIsOpen(false)
        } else {
          toast({
            title: 'Colaborador desligado com sucesso',
            status: 'success',
            position: 'top-right',
            isClosable: false
          })
        }
        window.location.reload()
      }).catch(err => {
        setModalDesligamentoIsOpen(false)
        console.log(err)
      })
  }

  return (
    <Flex flexDir={'column'}>
      <ModalDelete
        isOpen={ModalDeleteEtapaIsOpen}
        message='Deseja realmente excluir esta etapa?'
        onRequestClose={onCloseModalDeleteEtapa}
        onConfirm={onDeleteEtapa}
      />

      <ModalReabrir
        isOpen={ModalReabrirEtapaIsOpen}
        message='Para reabrir a pesquisa, você precisa alterar a data de término, deseja continuar?'
        onRequestClose={onCloseModalReabrirEtapa}
        onConfirm={(e) => onReabrirEtapa(e)}
        status={true}
      />

      <ModalAdicionarEtapa
        ProcessoId={processoEmAndamento?.id}
        colabId={colaborador.id ?? ''}
        OnCloseModal={() => setModalAddEtapaIsOpen(false)}
        isOpen={ModalAddEtapaIsOpen}
        tipo={processoEmAndamento?.trilha.categoria === 'Offboarding' ? 3 : undefined}
      />

      {ModalDesligamentoIsOpen &&
        <PopUpDesligarColaborador
          onDesligar={InterromperOnboard}
          onClose={() => setModalDesligamentoIsOpen(false)}
          mensagemAlternativa='Gostaria de adicionar uma trilha de offboarding para acompanhar esse deligamento?'
        />
      }
      {processoEmAndamento &&
        <>
          {parseJwt().adm === 'True' && processoEmAndamento?.trilha.categoria !== 'Offboarding' &&
            <Flex justifyContent={'end'}>
              <OutlineMetaButton
                color='terc3'
                size='sm'
                margin='1rem 1rem'
                onClick={() => setModalDesligamentoIsOpen(true)}
              ><AiOutlineCloseCircle className='IconLeft' />Desligar colaborador</OutlineMetaButton>

            </Flex>}

          <ColumnTitle>
            {
              (processoEmAndamento?.trilha !== null) && (
                <h3>{processoEmAndamento?.trilha.categoria} - {processoEmAndamento?.trilha.nome}</h3>
              )
            }

            {(parseJwt().adm === 'True' || processoEmAndamento.adicionarEtapa) &&
              <Flex>
                <OutlineMetaButton
                  color='v2'
                  size='sm'
                  margin='1rem 0rem'
                  onClick={() => setModalAddEtapaIsOpen(true)}
                ><FaPlus className='IconLeft' />Adicionar etapa</OutlineMetaButton>
              </Flex>
            }
          </ColumnTitle>

          <Flex alignItems={'start'} gap='1rem' margin='1rem 0'>
            <Flex flexDir={'column'}>
              <Text fontSize={'1.125rem'}
                fontWeight={'500'} marginBottom={'.5rem'}
              >Responsável do RH por esta trilha:
              </Text>
              <FindUser
                selecionado={processoEmAndamento?.responsavelId}
                onChoice={SelecionarResponsavel}
                lista={rh}
                responsavelTrilha={parseJwt().adm === 'False'}
              />
            </Flex>
            <Button
              isDisabled={ResponsavelId === undefined}
              VarColor='Green1'
              marginTop={'2rem'}
              onClick={AtualizarResponsavel}
            >Atualizar</Button>
          </Flex>

          <Flex flexDir={'column'}>
            {
              processoEmAndamento?.etapas.map((e, i) => {
                return (
                  <BoardBox
                    key={i}
                    etapa={e}
                    email={colaborador.email ?? ''}
                    processoId={processoEmAndamento.id}
                    colaboradorId={colaborador.id ?? ''}
                    isLast={i === processoEmAndamento.etapas.length - 1}
                    onDeleteEtapa={onOpenModalDeleteEtapa}
                    onReabrirEtapa={onOpenModalReabrirEtapa}
                    onConcludeEtapa={onConcludeEtapa}
                    onUpdateVisibility={onUpdateVisibilidade}
                    onReload={Load}
                  />
                )
              })
            }
          </Flex>
        </>}

      {
        (!processoEmAndamento && !query.get('criar')) && (
          <ArrowButton>
            <MetaButton bg='v2' size='lg' onClick={() => nav('?criar=true')}>Começar nova trilha</MetaButton>
          </ArrowButton>
        )
      }

      {
        (query.get('criar') && !processoEmAndamento) && (
          <ContainerTrilha
            onCancel={() => nav(-1)}
            onCreate={CriarProcesso}
            rh={rh}
            temOnboard={processosFinalizados.length > 0}
            dataDesligamento={colaborador.dataDesligamento}
          />
        )
      }

      {
        (!query.get('criar') && processosFinalizados.length > 0) && (
          <Flex flexDir={'column'}>
            <h3>trilhas realizadas</h3>
            <SectionOnboard>
              {
                processosFinalizados.map((e, i) => {
                  return (
                    <CardOnboard
                      key={i}
                      processo={e}
                    />
                  )
                })
              }
            </SectionOnboard>
          </Flex>
        )
      }
    </Flex>
  )
}
