/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Checkbox, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaEnvelope, FaSave } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { FindUser } from '../../../../components/FindBox'
import { NavLine } from '../../../../components/NavLine'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { FluxoEtapa, PropInvalida, SubstringRoute } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { BoxMultiText } from './components/BoxMultiText'
import { ModalTag } from './components/ModalTag'

interface iModel {
  participanteId: string
  reuniaoId?: string
  dataInicio: string
  tipoRespondente: number
  visualizarResposta: boolean
  edicaoResposta: number[]
  textosEmail: iTextosPesquisa[]
  textosAbertura: iTextosPesquisa[]
}

interface iTextosPesquisa {
  texto: string
  participantes: number[]
}

export const ConfigurarFeedback: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const textoPadraoEmail = '<p><strong>Olá, [NOME DO RESPONDENTE]!</strong></p><p><strong>Você está recebendo um link para preenchimento relacionado ao feedback entre você e o [NOME DO AVALIADO].</strong></p><p><strong>Para preencher o feedback clique no link abaixo:</strong></p><p><strong><a>[LINK]</a></strong></p><p><strong>Qualquer dúvida contacte o responsável!</strong></p>'
  const textoPadraoAbertura = '<p><strong>Bem vindo ao questionário relacionado ao feedback entre o colaborador [NOME DO AVALIADO] e o colaborador [NOME DO RESPONDENTE]</strong></p>'
  const { pathname } = useLocation()
  const isTrilha = FluxoEtapa(pathname)

  const { feedbackId } = useParams<{ feedbackId: string }>()
  const [Model, setModel] = useState<iModel>({
    dataInicio: '',
    participanteId: '',
    textosAbertura: [{ texto: textoPadraoAbertura, participantes: [0, 1] }],
    textosEmail: [{ texto: textoPadraoEmail, participantes: [0, 1] }],
    edicaoResposta: [],
    tipoRespondente: 0,
    visualizarResposta: false
  })
  const [IsLoading, setIsLoading] = useState(false)
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Reunioes, setReunioes] = useState<iNomeId[]>([])

  function ObterColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterReunioes(id: string): void {
    appApi.get(`ReuniaoOne/Select?colaboradorId=${id}`)
      .then(res => setReunioes(res.data))
      .catch(err => console.log(err))
  }

  function ObterFeedback(): void {
    setIsLoading(false)
    appApi.get(`Feedback/${feedbackId ?? ''}/Configuracao`)
      .then(res => {
        const data: iModel = res.data
        setModel({
          ...Model,
          dataInicio: data.dataInicio.substring(0, 16),
          edicaoResposta: data.edicaoResposta,
          participanteId: data.participanteId,
          textosAbertura: data.textosAbertura,
          textosEmail: data.textosEmail,
          tipoRespondente: data.tipoRespondente,
          reuniaoId: data.reuniaoId,
          visualizarResposta: data.visualizarResposta
        })
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function Salvar(salvar: boolean): void {
    appApi.put(`Feedback/${feedbackId ?? ''}/Configuracao?salvar=${salvar}`, Model)
      .then(() => {
        toast({
          title: 'Configuração do feedback atualizada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        if (!isTrilha && pathname.includes('NovaReuniaoOne')) {
          nav(`${SubstringRoute(pathname, 'Feedback')}Visualizar/${Model.reuniaoId ?? ''}`)
        } else if (isTrilha && pathname.includes('Trilha')) {
          nav(`${SubstringRoute(pathname, 'NovaReuniaoOne')}`)
        } else if (isTrilha && pathname.includes('Processo')) {
          nav(`/Rh/VisualizarPerfil/${Model.participanteId ?? ''}`)
        } else {
          nav(-1)
        }
      }
      )
      .catch(err => console.log(err))
  }

  function Previsualizar(gestor: boolean): void {
    appApi.put(`Feedback/${feedbackId ?? ''}/Configuracao?salvar=true`, Model)
      .then(() => {
        nav(`/Feedback/Abertura/${feedbackId ?? ''}/Previsualizar`,
          {
            state: {
              gestor: gestor
            }
          })
      }
      )
      .catch(err => console.log(err))
  }

  function AdicionarRespondente(tipoRespondente: number): void {
    if (Model.edicaoResposta.includes(tipoRespondente)) {
      setModel({ ...Model, edicaoResposta: [...Model.edicaoResposta.filter(t => t !== tipoRespondente)] })
    } else {
      setModel({ ...Model, edicaoResposta: [...Model.edicaoResposta, tipoRespondente] })
    }
  }

  useEffect(() => {
    ObterColaboradores()
    ObterFeedback()
  }, [])

  useEffect(() => {
    if (!PropInvalida(Model.participanteId)) {
      ObterReunioes(Model.participanteId)
    }
  }, [Model.participanteId])

  return (
    <Body isLoading={IsLoading}>
      <ModalTag
        isOpen={ModalTagIsOpen}
        onClose={() => setModalTagIsOpen(false)}
      />

      <NavLine
        options={[
          { nome: 'Reuniao 1:1', path: `${SubstringRoute(pathname, 'Feedback')}Dashboard/Individual` },
          { nome: 'Feedback' },
          { nome: 'Formularios' },
          { nome: 'Editar formulário', path: -1 },
          { nome: 'Configuração feedback' }
        ]}
      />
      <Flex gap={'1rem'} flexDir='column'>
        <WhiteBox>
          <h2>Configuração do feedback</h2>
          <Flex mt={'2rem'} flexDir={'column'} gap={'2rem'}>
            {!isTrilha &&
              <FormControl width={'33.625rem'}>
                <FormLabel>Participante</FormLabel>
                <FindUser
                  isDisabled={!PropInvalida(Model.reuniaoId)}
                  selecionado={Model.participanteId}
                  lista={Colaboradores}
                  onChoice={() => { }}
                />
              </FormControl>
            }

            {!isTrilha &&
              <FormControl>
                <FormLabel>Reunião vinculada</FormLabel>
                <Select
                  isDisabled={!PropInvalida(Model.reuniaoId)}
                  value={Model.reuniaoId} width={'20rem'}>
                  <option value={''}>Nenhuma</option>
                  {Reunioes.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>{e.nome}</option>
                    )
                  })}
                </Select>
              </FormControl>
            }

            {!isTrilha &&
              <FormControl alignItems={'center'} as={Flex} gap={'1rem'}>
                <FormLabel margin={0}>Data para o envio</FormLabel>
                <Input
                  onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                  width={'30%'}
                  type={'datetime-local'}
                  value={Model.dataInicio}
                />
              </FormControl>
            }

            <Flex flexDir={'column'}>
              <FormLabel>Quem deve responder ao feedback?</FormLabel>
              <RadioGroup
                value={Model.tipoRespondente?.toString() ?? '0'}
                onChange={(e) => setModel({ ...Model, tipoRespondente: parseInt(e) })}
              >
                <Stack gap={'1rem'} direction={'row'}>
                  <Radio value={'0'}>Somente eu</Radio>
                  <Radio value={'1'}>Somente o convidado</Radio>
                  <Radio value={'2'}>Eu e o convidado</Radio>
                </Stack>
              </RadioGroup>
            </Flex>

            <FormControl as={Flex} gap='.5rem'>
              <Checkbox
                onChange={() => setModel({ ...Model, visualizarResposta: !Model.visualizarResposta })}
                isChecked={Model.visualizarResposta}
              />
              <FormLabel margin={0}>O participante pode visualizar respostas do formulário</FormLabel>
            </FormControl>

            <Flex flexDir={'column'}>
              <FormLabel>Quem pode editar as respostas</FormLabel>
              <Flex gap={'1rem'}>
                <FormControl w={'auto'} gap={'.5rem'} as={Flex}>
                  <Checkbox isChecked={Model.edicaoResposta.includes(0)} onChange={() => AdicionarRespondente(0)} />
                  <FormLabel margin={0}>Eu</FormLabel>
                </FormControl>

                <FormControl w={'auto'} gap={'.5rem'} as={Flex}>
                  <Checkbox isChecked={Model.edicaoResposta.includes(1)} onChange={() => AdicionarRespondente(1)} />
                  <FormLabel margin={0}>Convidado</FormLabel>
                </FormControl>
              </Flex>
            </Flex>
          </Flex>
        </WhiteBox>

        <WhiteBox>
          <BoxMultiText
            titulo='Texto para o convite por e-mail'
            descricao='Esse texto será apresentado no e-mail ou notificação antes do respondente abrir a pesquisa.'
            model={Model.textosEmail}
            textoPadrao={textoPadraoEmail}
            onChange={(e) => setModel({ ...Model, textosEmail: e })}
            openModalTag={() => setModalTagIsOpen(true)}
          />
        </WhiteBox>

        <WhiteBox>
          <BoxMultiText
            titulo='Texto de abertura antes do preenchimento'
            descricao='Esse texto será apresentado quando o respondente abrir o link antes de começar a responder a pesquisa, para ver exatamente como irá ficar, você pode pré-visualizar clicando no botão a baixo da caixa de edição de texto.'
            model={Model.textosAbertura}
            textoPadrao={textoPadraoAbertura}
            onChange={(e) => setModel({ ...Model, textosAbertura: e })}
            openModalTag={() => setModalTagIsOpen(true)}
            onPrevisualizar={Previsualizar}
          />
        </WhiteBox>

        <Flex justifyContent={'end'} gap='1rem'>
          <Button
            onClick={() => nav(-1)}
            size={'lg'}
            VarColor='Gray3'
            leftIcon={<FaAngleLeft />}
          >Voltar</Button>
          {!pathname.includes('Editar') &&
            <Button
              onClick={() => Salvar(false)}
              size={'lg'}
              VarColor='Green2'
              rightIcon={<FaSave />}
            >Salvar e programar disparo</Button>
          }
          {pathname.includes('Editar') &&
            <Button
              onClick={() => Salvar(false)}
              size={'lg'}
              VarColor='Green2'
              rightIcon={Date.parse(Model.dataInicio) > new Date().getTime() ? <FaSave/> : <FaEnvelope />}
            >Salvar {Date.parse(Model.dataInicio) > new Date().getTime() ? '' : 'e reenviar'}</Button>
          }
        </Flex>
      </Flex>
    </Body>
  )
}
