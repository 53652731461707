/* eslint-disable no-tabs */
import { Box, Flex, Img, Text } from '@chakra-ui/react'
import Calendario from './Assets/calendarWeek.svg'
import Pastas from './Assets/pastas.svg'
import UserPlus from './Assets/userPlus.svg'
import Engrenagem from './Assets/engrenagens.svg'
import Clipboard from './Assets/clipboardCheck.svg'

interface iProps {
  color: string[]
}

export const TimelineCriarPesquisa: React.FC<iProps> = ({ color }) => {
  return (
		<Flex alignItems={'center'} mb={'5rem'} ml={'.7rem'}>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={'var(--Green1)'} border={'1px solid var(--Green1)'}>
					<Img src={Engrenagem} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} top={'65px'} right={'-12px'} textAlign={'center'}>Selecionando o modelo</Text>
			</Flex>
			<Box bgColor={'var(--Green1)'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[0] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[0] ? color[0] : '#BBBBBB'} border={color[0] ? '1px solid var(--Green1)' : 'none' }>
					<Img src={Engrenagem} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} top={'65px'} right={'-16px'} textAlign={'center'}>Configurações iniciais</Text>
			</Flex>
			<Box bgColor={color[0] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[1] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[1] ? color[1] : '#BBBBBB'} border={color[1] ? '1px solid var(--Green1)' : 'none' }>
					<Img src={Pastas} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} top={'65px'} right={'-4px'} textAlign={'center'}>Formulário</Text>
			</Flex>
			<Box bgColor={color[1] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[2] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[2] ? color[2] : '#BBBBBB'} border={color[2] ? '1px solid var(--Green1)' : 'none' }>
					<Img src={UserPlus} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} top={'65px'} right={'-8px'} textAlign={'center'}>Adicionar participante</Text>
			</Flex>
			<Box bgColor={color[2] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[3] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[3] ? color[3] : '#BBBBBB'} border={color[3] ? '1px solid var(--Green1)' : 'none' }>
					<Img src={Calendario} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} top={'65px'} right={'14px'} textAlign={'center'}>Textos</Text>
			</Flex>
			<Box bgColor={color[3] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Box bgColor={color[4] ? 'var(--Green1)' : '#BBBBBB'} height={'5px'} w={'78px'}></Box>
			<Flex flexDirection={'column'} w={'65px'} position={'relative'}>
				<Box w={'65px'} h={'65px'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bgColor={color[4] ? color[4] : '#BBBBBB'} border={color[4] ? '1px solid var(--Green1)' : 'none' }>
					<Img src={Clipboard} w={'44px'} h={'35px'}/>
				</Box>
				<Text position={'absolute'} top={'65px'} right={'0px'} textAlign={'center'}>Pesquisa criada</Text>
			</Flex>
		</Flex>
  )
}
