import styled, { css } from 'styled-components'

export const Container = styled.div`
background: white;
border-radius: 0.938rem;
display: flex;
width: 100%;
box-shadow: var(--SombraBtns);
margin-bottom: 1.5rem;
`

export const CompetenciaMenu = styled.div`
min-height: 15.625rem;
width: 20%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
border-right: 0.188rem solid var(--a2);
border-right-style: dashed;
`

export const CompetenciaContent = styled.div`
display: flex;
flex-direction: column;
padding: 1.5rem;
width: 100%;
`

export const CompetenciaLine = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 0.938rem;
border: 0.188rem solid white;
cursor: pointer;

svg {    
    min-width: 25px;
    min-height: 25px;
}

span{
    font-weight: 700;
    font-size: 1.125rem;
    color: var(--a4);
}
`
export const StyledQuestion = styled.div`
 &:hover{
        background: #D1FBCD;
        transition: all .4s ease;
    }

    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;

    svg{
        margin-right: .5rem;
    } 
    
    span{
    font-weight: 700;
    font-size: 1.125rem;
    color: var(--a4);
}
`

export const CollapseText = styled.div`
padding: 1rem;
display: flex;
align-items: center;
cursor: pointer;
color: var(--a1);
svg{
    margin-right: .5rem;
}

&:Hover{
    font-weight: 500;
}
`

export const CollapseLine = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 0.938rem;
border: 0.188rem solid white;
cursor: pointer;

&:first-child{
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;

    &:hover{
        background: #D1FBCD;
        transition: all .4s ease;
    }
}

span{
font-family: 'Roboto';
font-weight: 700;
font-size: 1.125rem;
color: var(--a4);
}

`

export const ColumnIcons = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 1rem;

span{
    cursor: pointer;
    &:hover{
        font-weight: bold;
    }
}
`

export const SectionQuestoes = styled.section<{active: boolean}>`
overflow-y: hidden;
max-height: 0rem;


${({ active }) =>
    active &&
    css`
    transition: max-height 5s;
    max-height: 600rem;
`}
`

export const ImgText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
        font-weight: 400;
        font-size: 0.875rem;
        color:var(--a3);
        text-align: center;
        width: 8rem;
    }
    img{
        max-height: 5.50rem;
        max-width: 5.50rem;
        border-radius: 3.125rem;
        margin-bottom: .5rem;
        border: 0.188rem solid var(--a2);
    }
`
