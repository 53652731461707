import { Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdOutlinePictureAsPdf, MdOutlineVideoLibrary, MdOutlineCollections } from 'react-icons/md'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Body } from '../../../Layouts/Body'
import { GreyButton } from '../../../../components/Buttons/GreyButton'
import { GreenButton } from '../../../../components/Buttons/GreenButton'
import { TimeLineOnboard } from '../../../../components/TimeLineOnboard'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iItemIntegracao, iEtapa } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { BodyCardRoxo, BoxIntegracao, CardRoxo, Title, Main } from './styles'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { CardVideo } from './Components/CardVideo'
import { CardImagem } from './Components/CardImagem'
import { CardPdf } from './Components/CardPdf'
import { Button } from '../../../../components/Button'
import { FaEye } from 'react-icons/fa'
import { ValidarEtapa } from '../../../../Utils/Helper'

type LocationProps = {
  trilhaId: string
  imersaoId: string
  etapaId?: string
  processoId?: string
  nomeImersao?: string
}

export const ModifyImersao: React.FC = () => {
  const { pathname } = useLocation()
  const toast = useToast()
  const navigate = useNavigate()
  const { nomeImersao } = useLocation().state as LocationProps || {}
  const [NomeImersao, setNomeImersao] = useState(nomeImersao ?? '')
  const { trilhaId, imersaoId, etapaId, processoId } = useParams<LocationProps>()
  const [Imersoes, setImersoes] = useState<iItemIntegracao[]>([])

  const [Selecionar, setSelecionar] = useState(false)

  const [EditEtapa, setEditEtapa] = useState<iEtapa>()

  function AdicionarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    const url = trilhaId ? `ImersaoEtapa/${trilhaId}` : processoId ? `ImersaoEtapaColaborador/${processoId}` : ''

    appApi.post(`${url}?imersaoId=${imersaoId ?? ''}`, form).then(() => {
      toast({
        title: 'Etapa cadastrada com sucesso',
        status: 'success',
        isClosable: false
      })
      navigate(-2)
    }).catch(err => {
      console.error(err)
      toast({
        title: 'Erro ao cadastrar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function GetImersoesById(): void {
    appApi.get(`Imersao/${imersaoId ?? ''}`)
      .then(response => { setImersoes(response.data.itensIntegracao) }).catch(err => console.error(err))
  }

  function CreateItemImersao(Tipo: number): void {
    appApi.post(`Imersao/${imersaoId ?? ''}/Item`, {
      titulo: '',
      descricao: '',
      tipo: Tipo
    })
      .then(() => { GetImersoesById() }).catch(err => console.error(err))
  }

  function DeltarItemIntegracao(Id: string): void {
    appApi.delete(`Imersao/${imersaoId ?? ''}/Item/${Id}`)
      .then(response => {
        if (response.status === 200) {
          const imersoes = [...Imersoes.filter(e => e.id !== Id)]
          setImersoes([])
          setImersoes(imersoes)
        }
      }).catch(err => console.error(err))
  }

  function AtualizarItemIntegracao(item: iItemIntegracao): void {
    const idItem: string = item.id
    const form: Omit<iItemIntegracao, 'id'> = {
      titulo: item.titulo,
      descricao: item.descricao,
      tipo: item.tipo,
      checklist: item.checklist
    }
    appApi.put(`Imersao/${imersaoId ?? ''}/Item/${idItem}`, form).then(() => {
    }).catch(e => console.log(e))
  }

  function ObterEtapaEdit(): void {
    const url = trilhaId ? `Etapa/${etapaId ?? ''}` : processoId ? `EtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId ?? ''}` : ''
    appApi.get(url)
      .then(res => {
        const data: iEtapa = res.data
        setEditEtapa(data)
        setNomeImersao(data?.nome ?? '')
      }).catch(err => console.log(err))
  }

  function AtualizarEtapa(form: iEtapa): void {
    if (!ValidarEtapa(form)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    form.nome = NomeImersao
    const url = trilhaId
      ? `ImersaoEtapa/${etapaId as string}`
      : processoId
        ? `ImersaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId}`
        : ''
    appApi.put(`${url}/`, form).then(() => {
      toast({
        title: 'Etapa atualizada com sucesso',
        status: 'success',
        isClosable: false
      })
      if (trilhaId) {
        navigate(`/Jornada/Configuracao/Trilha/${trilhaId}`)
      } else {
        navigate(`/Rh/VisualizarPerfil/${processoId as string}`)
      }
    }).catch(() => {
      toast({
        title: 'Não foi possível atualizar a etapa',
        status: 'error',
        isClosable: false
      })
    })
  }

  function EditarImersao(Texto: string): void {
    const dados = {
      url: ''
    }
    dados.url = !pathname.includes('Editar') ? `Integracao/${imersaoId ?? ''}` : processoId ? `IntegracaoEtapaColaborador/${etapaId ?? ''}/ProcessoOnboard/${processoId ?? ''}` : ''
    if (pathname.includes('Trilha')) {
      dados.url = `IntegracaoEtapa/${etapaId ?? ''}`
    }
    const form = {
      ...EditEtapa,
      nome: Texto
    }
    appApi.put(dados.url, form).then(response => {
    }).catch(e => console.log(e))
  }

  useEffect(() => {
    if (imersaoId !== undefined) {
      GetImersoesById()
    }
    if (etapaId !== undefined) {
      ObterEtapaEdit()
    }
  }, [imersaoId])

  return (
    <Body>
      <Main>
        <TimeLineOnboard hide={processoId !== undefined} />
        {
          !Selecionar
            ? <WhiteBox>
              <Title>
                <h1>Imersão cultural</h1>
                <span>Adicione vídeos, fotos, apresentações ou artigos para serem apresentados aos colaboradores. Você também pode configurar um evento para aprofundar a cultura de sua organização com seus colaboradores </span>
              </Title>
              <BoxIntegracao >
                  <FormControl marginBottom={'1rem'}>
                    <FormLabel fontSize={'xl'}>Nome da Imersão</FormLabel>
                    <Input
                      value={NomeImersao}
                      borderColor={'var(--Gray4)'}
                      onChange={(e) => { setNomeImersao(e.target.value) }}
                      type="text"
                      placeholder='Digite o nome'
                      onBlur={(e) => {
                        EditarImersao(e.target.value)
                        setNomeImersao(e.target.value)
                      }}
                    />
                  </FormControl>
                </BoxIntegracao>
              <Flex display={'grid'} gap={'1rem 0rem'}>
                {
                  Imersoes.map((e, i) => {
                    if (e.tipo === 1) {
                      return (
                        <CardVideo
                          key={e.id}
                          id={imersaoId ?? ''}
                          position={i + 1}
                          ItemIntegracao={e}
                          onDelete={DeltarItemIntegracao}
                          onUpdateInput={AtualizarItemIntegracao}
                        />
                      )
                    } else if (e.tipo === 2) {
                      return (
                        <CardImagem
                          key={e.id}
                          position={i + 1}
                          id={imersaoId ?? ''}
                          ItemIntegracao={e}
                          onDelete={DeltarItemIntegracao}
                          onUpdateInput={AtualizarItemIntegracao}
                        />
                      )
                    } else if (e.tipo === 3) {
                      return (
                        <CardPdf
                          key={e.id}
                          position={i + 1}
                          id={imersaoId ?? ''}
                          ItemIntegracao={e}
                          onDelete={DeltarItemIntegracao}
                          onUpdateInput={AtualizarItemIntegracao}
                        />
                      )
                    }
                    return null
                  })
                }
              </Flex>

              <h2>Adicione mais arquivos</h2>
              <BoxIntegracao>
                <BodyCardRoxo>
                  <CardRoxo onClick={() => { CreateItemImersao(1) }}>
                    <MdOutlineVideoLibrary size={42} />
                    <span>Adicionar um vídeo</span>
                  </CardRoxo>

                  <CardRoxo onClick={() => { CreateItemImersao(2) }}>
                    <MdOutlineCollections size={42} />
                    <span>Adicionar uma imagem e texto</span>
                  </CardRoxo>

                  <CardRoxo onClick={() => { CreateItemImersao(3) }}>
                    <MdOutlinePictureAsPdf size={42} />
                    <span>Adicionar um arquivo PDF</span>
                  </CardRoxo>
                </BodyCardRoxo>
              </BoxIntegracao>
              <Flex justifyContent={'end'} marginTop={'1rem'} gap={'1rem'}>
                <GreyButton
                  Size='lg'
                  isNav={true}
                >Voltar</GreyButton>
                <Button
                  VarColor='Rosa'
                  size={'lg'}
                  leftIcon={<FaEye />}
                  fontWeight={'500'}
                  onClick={() => navigate(pathname + '/Previsualizar')}
                  marginRight='1rem'
                >
                  Pré-visualização completa da integração
                </Button>
                <GreenButton
                  Size='lg'
                  OnClick={() => setSelecionar(true)}
                  isNav={false}>Continuar
                </GreenButton>
              </Flex>
            </WhiteBox>
            : <>
              {
                EditEtapa === undefined
                  ? <CreateEtapaBox
                    keyWord='imersão'
                    onGreenAction={AdicionarEtapa}
                    onGreyAction={() => setSelecionar(false)}
                    EditEtapa={EditEtapa}
                  />
                  : <CreateEtapaBox
                    keyWord='imersão'
                    onGreenAction={AtualizarEtapa}
                    onGreyAction={() => setSelecionar(false)}
                    EditEtapa={EditEtapa}
                    ignoreId={etapaId}
                  />
              }
            </>
        }
      </Main>
    </Body>
  )
}
