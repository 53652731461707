import { Input, InputGroup, InputRightElement } from '@chakra-ui/input'
import { Flex } from '@chakra-ui/layout'
import { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { Button } from '../Button'
import { FindUser } from '../FindBox'
import { iNomeId } from '../../interfaces'

interface iProps {
  colaboradores: iNomeId[]
  selecionados: string[]
  placeholder?: string
  onChoice: (value: string) => void
  onDelete: (id: string) => void
}

export const MultiColaborador: React.FC<iProps> = ({ placeholder, colaboradores, selecionados, onChoice, onDelete }) => {
  const [Add, setAdd] = useState(true)

  function InternalChoice(e: string): void {
    setAdd(false)
    onChoice(e)
  }
  return (
        <Flex flexDir={'column'} gap='.5rem'>
            <Flex flexDir={'column'} gap={'.5rem'}>
                {colaboradores.filter(r => selecionados?.some(t => t === r.id)).map((e, i) => {
                  return (
                        <InputGroup width={'100%'} key={i}>
                            <InputRightElement
                                cursor={'pointer'}
                                children={<MdClose onClick={() => onDelete(e.id)} color='var(--terc3)' />}
                            />
                            <Input
                                readOnly
                                value={e.nome}
                                borderColor={'var(--Gray4)'}
                            />
                        </InputGroup>
                  )
                })}
            </Flex>
            {(Add || selecionados.length === 0) &&
                <FindUser
                    mt={'.5rem'}
                    lista={colaboradores.filter(r => !selecionados?.some(t => t === r.id))}
                    onChoice={InternalChoice}
                    placeholder={placeholder}
                />
            }
            <Flex>
                <Button onClick={() => setAdd(true)} mt={'1rem'} VarColor='Azul'>Adicionar</Button>
            </Flex>
        </Flex>
  )
}
