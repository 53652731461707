import styled, { css } from 'styled-components'

export const Container = styled.div<{active?: boolean}>`
display: flex;
flex-direction: column;
border: 1px solid var(--Rosa);
border-radius: 24px;
background: var(--c2);

${props => props.active && css`
    & > div:last-child{
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding: .25rem 0;
        color: white;
        font-weight: 500;
        cursor: pointer;
    }
`}
`

export const ColumnTitulo = styled.div`
display: flex;
flex-direction: column;
span:nth-child(1){
    font-size: .825rem;
    color: var(--Gray2);
    margin-bottom: .2rem;
}

span:nth-child(2){
    color: var(--Gray1);
    font-size: 1.25rem;
    font-weight: 500;
}
`

export const ColumnRespostaRh = styled.div`
display: flex;
flex-direction: column;
span:nth-child(1){
    font-size: .825rem;
    color: var(--Gray2);
    margin-bottom: .2rem;
}

span:nth-child(2){
    color: var(--Azul);
    font-size: 1.25rem;
    font-weight: 500;
}
`

export const ContainerCollapse = styled.div<{active?: boolean}>`
display: flex;
overflow-y: hidden;
max-height: 0rem;
flex-direction: column;
gap: 1rem;

${props => props.active && css`
    transition: max-height 7.2s;
    max-height: 800rem;
`}

`

export const CardColaborador = styled.div`
display: flex;
align-items: center;
background: var(--c2);
border-radius: 1rem;
border: 0.125rem solid var(--Azul);
width: 20rem;
padding: 1rem;
`
