/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createContext, ReactNode, useContext, useState } from 'react'
import { appApi, SigahApi, TestApi } from '../services/appApi'
import { parseJwt } from '../services/token'

interface AuthProviderProps {
  children: ReactNode
}

export interface iPermissao {
  empresaId: string
  jornada: boolean
  desempenho: boolean
  engajamento: boolean
  organograma: boolean
  permissoes: iPermissaoFuncionalidade[]
  id: string
  contrato?: iContrato
  contratoAtual?: iContrato
  teams: boolean
  zoom: boolean
  adicionarTipo: boolean
}

export interface iContrato {
  inicio: Date
  fim: Date
  jornada: boolean
  desempenho: boolean
  engajamento: boolean
  opp: string
  plano: boolean
  consultor?: iResponsavel
  fee?: number
}

export interface iResponsavel{
  nome: string
  email: string
}

export interface iPermissaoFuncionalidade {
  modulo: string
  funcionalidade: string
  tipoUsuario: number
  permitir: boolean
}

interface iAuthContext {
  Permissoes: iPermissao
  isAuth: () => boolean
  logOut: () => void
  refreshToken: () => void
  obterVersao: () => void
  recarregarPermissoes: () => void
  permitirFuncao: (modulo: string, funcionalidade: string) => boolean
  Atualizar: (modulo: string, funcionalidade: string, permitir: boolean, tipo: number) => void
  modoFree: () => boolean
}

export const AuthContext = createContext<iAuthContext>({} as iAuthContext)
export function AuthProvider({ children }: AuthProviderProps): any {
  const [Permissao, setPermissao] = useState<iPermissao>({
    desempenho: false,
    engajamento: false,
    empresaId: '',
    id: '',
    jornada: false,
    organograma: false,
    permissoes: [],
    teams: false,
    zoom: false,
    adicionarTipo: false
  })

  function getPermissoes(): void {
    if (VerificarSePossuiToken()) {
      appApi.get('Permissoes')
        .then(res => setPermissao(res.data))
        .catch(err => console.log(err))
    } else {
      console.log('Não Autenticado - Permissao ZERO')
    }
  }

  function AtualizarPermissoes(modulo: string, funcionalidade: string, permitir: boolean, tipo: number): void {
    const form = {
      modulo: modulo,
      funcionalidade: funcionalidade,
      permitir: permitir,
      tipoUsuario: tipo
    }
    console.log(form)

    appApi.put('Permissoes', form)
      .then(res => {
        const copy = [...Permissao.permissoes]
        if (res.status === 200) {
          const pos = copy.findIndex(e => e.modulo === modulo && e.funcionalidade === funcionalidade && e.tipoUsuario === tipo)
          if (pos !== -1) {
            copy[pos].permitir = permitir
            setPermissao({ ...Permissao, permissoes: copy })
          }
        }
      }
      )
      .catch(err => console.log(err))
  }

  function modoFree(): boolean {
    return (Permissao.contratoAtual === undefined || Permissao.contratoAtual === null)
  }

  function verificarPermissao(modulo: string, funcionalidade: string): boolean {
    const jwt = parseJwt()
    const permissao = Permissao.permissoes.find(e => e.modulo === modulo && (jwt.adm === 'True' || e.funcionalidade === funcionalidade) && e.tipoUsuario === parseInt(jwt.role === '2' ? '1' : jwt.role))
    if (permissao !== undefined) {
      return permissao.permitir
    }

    return false
  }

  function RefreshToken(): void {
    appApi.get('Auth/Refresh')
      .then(res => {
        localStorage.setItem('token', res.data)
      })
      .catch(err => {
        console.log(err)
        LogOut()
      })
  }

  function ObterVersao(): void {
    appApi.get('Auth/Versao')
      .then(res => {
        const versaoLocal = localStorage.getItem('version')
        if (versaoLocal !== res.data) {
          localStorage.setItem('version', res.data ?? '')
          window.location.reload()
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  function VerificarSePossuiToken(): boolean {
    const tk = localStorage.getItem('token')
    if (tk !== null && tk !== undefined && tk !== '') {
      appApi.defaults.headers.common.Authorization = `bearer ${tk}`
      SigahApi.defaults.headers.common.Authorization = `bearer ${tk}`
      TestApi.defaults.headers.common.Authorization = `bearer ${tk}`
      const { depid, cid, adm } = parseJwt()
      if (depid === '' && cid !== '' && !adm) return false
      return true
    } else {
      return false
    }
  }

  function LogOut(): void {
    localStorage.removeItem('logo')
    localStorage.removeItem('token')
    appApi.defaults.headers.common.Authorization = ''
    SigahApi.defaults.headers.common.Authorization = ''
    TestApi.defaults.headers.common.Authorization = ''
    window.location.reload()
  }

  return (
      <AuthContext.Provider value={{
        Permissoes: Permissao,
        isAuth: VerificarSePossuiToken,
        permitirFuncao: verificarPermissao,
        Atualizar: AtualizarPermissoes,
        logOut: LogOut,
        refreshToken: RefreshToken,
        obterVersao: ObterVersao,
        recarregarPermissoes: getPermissoes,
        modoFree: modoFree
      }}>
        {
          children
        }
      </AuthContext.Provider>
  )
}

export function useAuth(): iAuthContext {
  const context = useContext(AuthContext)
  const { refreshToken, obterVersao, logOut, isAuth, recarregarPermissoes, Permissoes, permitirFuncao, Atualizar, modoFree } = context
  return { refreshToken, obterVersao, logOut, isAuth, recarregarPermissoes, Permissoes, permitirFuncao, Atualizar, modoFree }
}
