/* eslint-disable no-return-assign */
import { Alert, AlertIcon, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Stack, Text, useToast } from '@chakra-ui/react'
import { FaAngleLeft, FaArrowRight } from 'react-icons/fa'
import { Button } from '../../../../components/Button'
import { InputFormControl } from '../../../../components/InputFormControl'
import { WhiteBox } from '../../../../components/WhiteBox'
import { Body } from '../../../Layouts/Body'
import { useState, useEffect } from 'react'
import { iEtapa } from '../../../../interfaces'
import { appApi, urlSigah } from '../../../../services/appApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { OutlineContainer } from './styles'
import { ObterToken, PropInvalida, ValidarEtapa } from '../../../../Utils/Helper'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'

interface iPutConfiguracao {
  nomePesquisa: string
  textoAbertura: string
  tipoEncerramentoPesquisa: number
  textoEncerramento: string
  linkRedirect?: string
}

export const CriarEnpsJornada: React.FC = () => {
  const nav = useNavigate()
  const path = useLocation().pathname
  const toast = useToast()

  const { id, etapaId } = useParams<{
    id: string
    etapaId: string
  }>()

  const urlPai = path.includes('Trilha') ? 'EnpsEtapa' : path.includes('Processo') ? 'EnpsEtapaColaborador' : ''

  const [Model, setModel] = useState<iPutConfiguracao>({
    nomePesquisa: '',
    textoAbertura: '<p>Bem-vindo à pesquisa eNPS da [EMPRESA]</p>',
    tipoEncerramentoPesquisa: 0,
    textoEncerramento: 'Obrigado por participar!'
  })
  const [NomePesquisaIsInvalid, setNomePesquisaIsInvalid] = useState(false)

  const [IsLoading, setIsLoading] = useState(true)

  const [Etapa, setEtapa] = useState<iEtapa>({})

  function CriarEtapaComposta(): void {
    setNomePesquisaIsInvalid(false)
    if (Model?.nomePesquisa.length < 5) {
      setNomePesquisaIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Model,
      etapa: Etapa
    }

    appApi.post(`${urlPai}/${id as string}`, form)
      .then(res => window.location.href = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}&ct=PesquisaPulse&at=TelaDemografia&paramId=${res.data as string}&backRoute=${path.includes('Trilha') ? `Jornada/Configuracao/Trilha/${id as string}` : `Rh/VisualizarPerfil/${id as string}`}`)
      .catch(err => console.log(err))
  }

  function AtualzarEtapaComposta(): void {
    setNomePesquisaIsInvalid(false)
    if (Model?.nomePesquisa.length < 5) {
      setNomePesquisaIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Model,
      etapa: Etapa
    }

    appApi.put(`${urlPai}${urlPai === 'EnpsEtapaColaborador' ? `/${id ?? ''}` : ''}/Etapa/${etapaId ?? ''}`, form)
      .then(() => {
        toast({
          title: 'Etapa atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function ObterEtapaPesquisa(): void {
    appApi.get(`${urlPai}${urlPai === 'EnpsEtapaColaborador' ? `/${id ?? ''}` : ''}/Etapa/${etapaId as string}`)
      .then(res => {
        setIsLoading(true)
        setModel(res.data.pesquisa)
        setEtapa(res.data.etapa)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function AtualizarEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  useEffect(() => {
    if (etapaId) {
      ObterEtapaPesquisa()
    }
  }, [])

  return (
    <Body>
      <WhiteBox>
        {
          (path.includes('Editar')) && (<Alert my={4} status='warning'>
            <AlertIcon />
            Ao editar as informações dessa pesquisa você editará todas as etapas vinculadas a ela!
          </Alert>)
        }
        <InputFormControl
          value={Model.nomePesquisa}
          onChange={(e) => setModel({ ...Model, nomePesquisa: e.target.value })}
          label='Nome da pesquisa'
          isInvalid={NomePesquisaIsInvalid}
        />

        <Flex margin={'1rem 0'}>
          <CreateEtapaBox
            EditEtapa={Etapa}
            ignoreId={etapaId}
            variant='Etapa'
            onModelChange={AtualizarEtapa}
            keyWord='pesquisa eNPS'
          />
        </Flex>

        <Flex gap={'1rem'} flexDir={'column'}>
          <OutlineContainer>
            <Flex flexDir={'column'}>
              <h3>Texto de abertura da pesquisa</h3>
              <Text color={'var(--a4)'} fontWeight='500' fontSize={'0.875rem'}>O respondente irá visualizar esse texto antes de começar a preencher a pesquisa, para visualizar exatamente como irá ficar, você pode pré-visualizar clicando no botão abaixo da caixa de edição de texto.</Text>
              <EditorTexto
                isLoading={IsLoading}
                initialValue={Model.textoAbertura ?? ''}
                onChange={(e: string) => setModel({ ...Model, textoAbertura: e })}
              />

            </Flex>
          </OutlineContainer>

          <OutlineContainer>
            <Flex flexDir={'column'}>
              <h3>Opções após encerrar pesquisa</h3>
              <Text color={'var(--a4)'} fontWeight='500' fontSize={'0.875rem'}>Escolha uma opção abaixo para o encerramento da pesquisa</Text>
              <RadioGroup
                onChange={(e) => setModel({ ...Model, tipoEncerramentoPesquisa: parseInt(e) })}
                value={Model.tipoEncerramentoPesquisa.toString()} margin={'1rem'}>
                <Stack color={'var(--a4)'} direction='column'>
                  <Radio borderColor={'var(--Gray4)'} size={'lg'} value='0'>Redirecione o usuário para o site do sistema.</Radio>
                  <Radio borderColor={'var(--Gray4)'} size={'lg'} value='1'>Redirecione o usuário para o site de sua empresa.</Radio>
                  <Radio borderColor={'var(--Gray4)'} size={'lg'} value='2'>Exiba uma mensagem.</Radio>
                </Stack>
              </RadioGroup>
              {
                (Model.tipoEncerramentoPesquisa === 1) && (
                  <FormControl>
                    <FormLabel fontSize={'xl'}>Escreva o endereço do site:</FormLabel>
                    <Input
                      placeholder={'www.meusite.com.br'}
                      borderColor={'var(--Gray4)'}
                      onChange={(e) => setModel({ ...Model, linkRedirect: e.target.value })}
                      value={Model.linkRedirect}
                    />
                  </FormControl>
                )
              }

              {
                (Model.tipoEncerramentoPesquisa === 2) && (
                  <EditorTexto isLoading={IsLoading} initialValue={Model.textoEncerramento ?? ''}
                    onChange={(e: string) => setModel({ ...Model, textoEncerramento: e })}
                  />
                )
              }
            </Flex>
          </OutlineContainer>
        </Flex>

      </WhiteBox>
      <Flex justifyContent={'end'} gap='1rem' marginTop={'1rem'}>
        <Button leftIcon={<FaAngleLeft />} onClick={() => nav(-1)} VarColor='c5' size={'lg'}>Voltar</Button>
        {PropInvalida(etapaId) && <Button VarColor='Green3' size={'lg'} rightIcon={<FaArrowRight />} onClick={CriarEtapaComposta}>Próximo</Button>}
        {!PropInvalida(etapaId) && <Button VarColor='Green3' size={'lg'} onClick={AtualzarEtapaComposta}>AtualizarEtapa</Button>}

      </Flex>
    </Body>
  )
}
