import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { appApi } from '../../../../../../../services/appApi'
import { FaCheck, FaClipboardList, FaCog, FaComment, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
import { Flex, useToast } from '@chakra-ui/react'
import { Button } from '../../../../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { PropInvalida } from '../../../../../../../Utils/Helper'
import { ElementoTooltip } from '../../../../../../../components/ElementoTooltip'
import { iEtapaColaborador, iReuniao } from '../../../../../../../interfaces'
interface ITipoCardConfigReuniao {
  processoId: string
  etapa: iEtapaColaborador
  isOpen: boolean
  onReload: () => void
}

export const CardReuniao: React.FC<ITipoCardConfigReuniao> = ({ etapa, isOpen, processoId, onReload }) => {
  const nav = useNavigate()
  const [Reuniao, setReuniao] = useState<Omit<iReuniao, 'id'>>()
  const toast = useToast()

  function GetReuniaoPorId(): void {
    appApi.get(`Reuniao/${etapa.interacoes[0].idExterno}`)
      .then(response => { setReuniao(response.data) }).catch(err => console.error(err))
  }

  function ObterNomePeloTipo(Tipo: number): string {
    if (Tipo === 0) {
      return 'RH'
    } else if (Tipo === 1) {
      return 'Colaborador'
    } else if (Tipo === 2) {
      return 'Líder'
    } else if (Tipo === 3) {
      return 'Pares'
    } else if (Tipo === 4) {
      return 'Superiores'
    } else if (Tipo === 5) {
      return 'Alta liderança'
    } else {
      return ''
    }
  }

  function FinalizarReuniao(): void {
    appApi.put(`ReuniaoEtapaColaborador/Concluir/${etapa.interacoes[0].idExterno}`)
      .then(() => {
        toast({
          title: 'Reunião finalizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        onReload()
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Reuniao)) {
      GetReuniaoPorId()
    }
  }, [isOpen])

  return (
    <Container>
      <FlexIcon>
        <FaUserAlt size={30} />
        <div>
          <h3>Responsável</h3>
          <strong>{Reuniao?.responsavel === 0 ? 'RH' : Reuniao?.responsavel === 1 ? 'Gestor' : Reuniao?.emailResponsavel}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaUserAlt size={30} />
        <div>
          <h3>Participantes</h3>
          {
            Reuniao?.participantes !== undefined && Reuniao.participantes.length > 0
              ? <div>
                {
                  Reuniao.participantes?.map((e: any, i) => {
                    return (
                      <strong key={i}>{ObterNomePeloTipo(e)}{Reuniao.participantes !== undefined && i === Reuniao.participantes?.length - 1 ? '' : '/'}</strong>
                    )
                  })
                }
              </div>
              : <strong>O responsável ainda não selecionou os participantes</strong>
          }

        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={30} />
        <div>
          <h3>Local da reunião</h3>
          <strong>{Reuniao?.tipo === 2 ? 'Definido pelo responsável' : Reuniao?.local}</strong>
        </div>
      </FlexIcon>

      {
        (Reuniao?.descricao !== null) && (
          <FlexIcon>
            <FaClipboardList size={30} />
            <div>
              <h3>Descrição</h3>
              <small dangerouslySetInnerHTML={{ __html: Reuniao?.descricao ?? '' }}></small>
            </div>
          </FlexIcon>
        )
      }
      {
        (!PropInvalida(Reuniao?.comentario)) && (
          <FlexIcon>
            <FaComment size={30} />
            <div>
              <h3>Comentário</h3>
              <small>{Reuniao?.comentario}</small>
            </div>
          </FlexIcon>
        )
      }

      <Flex justifyContent={'center'} direction={'column'} gap='1rem'>
        {
          !Reuniao?.concluido && (
            <ElementoTooltip
              label={etapa.status === 1 ? 'Configuração da reunião será liberada quando a etapa anterior for finalizada.' : ''}
            >
              <Button
                disabled={etapa.status === 1}
                onClick={() => nav(`/Rh/Reuniao/Config/${etapa.interacoes[0].idExterno}`)}
                VarColor='Azul'
                rightIcon={<FaCog />}
              >Configurar reunião</Button>
            </ElementoTooltip>

          )}
        {
          !Reuniao?.concluido && Reuniao?.agendado && (
            <Button onClick={FinalizarReuniao} VarColor='Rosa' rightIcon={<FaCheck />}>Finalizar reunião</Button>
          )}
      </Flex>
    </Container>
  )
}
