import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: var(--Azul);
color: var(--c1);
border-radius: 0.75rem;
height: 8.625rem;
width: 10.925rem;
box-shadow: var(--SombraBtns);

div{
    width: min-content;
    margin-left: .5rem;
}
`

export const ComentariosContainer = styled.div`
display: flex;
justify-content: start;
width: 100%;
background: var(--c2);
padding: .5rem;
width: 100%;
flex-direction: column;

h3{
    color: var(--Gray3);
    margin-bottom: 1rem;
}
`

export const Titulo = styled.h2`
font-family: 'Poppins';
font-weight: 500;
font-size: 28px;
color: #2C4D8A;
margin-bottom: 1rem;
`
