import { Flex } from '@chakra-ui/layout'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { iTabelasPDIs } from '../..'
import { Button } from '../../../../../components/Button'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { appApi } from '../../../../../services/appApi'
import { useQuery } from '../../../../../Utils/Helper'
import { ArrowButton } from '../../styles'
import { Acao } from '../Acao'
import { TabelaAprovacao } from '../TabelaAprovacao'
import { TabelaEmAndamento } from '../TabelaEmAndamento'

interface iAcao {
  id: string
  dataInicio: string
  dataFim: string
  hora: string
  assunto: string
  titulo: string
  descricao: string
  motivo: string
  comentario?: string
  solicitarAjustes: boolean
}

interface iPDIProviderProps {
  Acoes: iAcao[]
  ExluirAcao: (id: string) => void
  SolicitarAjustes: () => void
  AprovarPDI: () => void
  AtualizarAcao: (acao: iAcao) => void
}

interface iAtualizacaoPDI {
  id: string
  comentario?: string
}

interface iProps {
  pdis?: iTabelasPDIs
  getPdis: () => void
}

function usePDIState(pathname: string, nav: any, reload: () => void, pdiId?: string): iPDIProviderProps {
  const [Acoes, setAcoes] = useState<iAcao[]>([])

  function InternalReset(): void {
    reload()
    nav(`/${pathname.split('/').splice(1, 3).join('/')}`)
  }

  function ExluirAcao(id: string): void {
    appApi.delete(`PDI/${pdiId as string}/Acao/${id}`)
      .then(() => {
        const copy = [...Acoes.filter(e => e.id !== id)]
        setAcoes([])
        setAcoes(copy)
        if (copy.length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function SolicitarAjustes(): void {
    const form: iAtualizacaoPDI[] = Acoes.filter(r => r.solicitarAjustes).map((e) => {
      return {
        id: e.id,
        comentario: e.comentario
      }
    })

    appApi.post(`PDI/${pdiId as string}/Acao/SolicitarAjuste`, form)
      .then(() => {
        const copy = [...Acoes.filter(e => !e.solicitarAjustes)]
        setAcoes([])
        setAcoes(copy)
        if (copy.length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function AprovarPDI(): void {
    const form: iAtualizacaoPDI[] = Acoes.filter(r => !r.solicitarAjustes).map((e) => {
      return {
        id: e.id,
        comentario: e.comentario
      }
    })
    appApi.post(`PDI/${pdiId as string}/Acao/Aprovar`, form)
      .then(() => {
        const copy = [...Acoes.filter(e => e.solicitarAjustes)]
        setAcoes([])
        setAcoes(copy)
        if (copy.length === 0) {
          InternalReset()
        }
      })
      .catch(err => console.log(err))
  }

  function AtualizarAcao(acao: iAcao): void {
    const copy = [...Acoes]
    const pos = copy.findIndex(e => e.id === acao.id)
    if (pos !== -1) {
      copy[pos] = acao
      setAcoes(copy)
    }
  }

  function ObterAcoesPorPdiId(): void {
    appApi.get(`PDI/${pdiId as string}/Acao?status=1`)
      .then(res => setAcoes(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (pdiId) {
      ObterAcoesPorPdiId()
    }
  }, [pdiId])

  return { Acoes: Acoes, AprovarPDI: AprovarPDI, AtualizarAcao: AtualizarAcao, ExluirAcao: ExluirAcao, SolicitarAjustes: SolicitarAjustes }
}

export const TabPDI: React.FC<iProps> = ({ pdis, getPdis }) => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  const query = useQuery()

  const { Acoes, AprovarPDI, AtualizarAcao, ExluirAcao, SolicitarAjustes } = usePDIState(pathname, nav, getPdis, query.get('pdiId') ?? undefined)

  const [ModalAcaoIsOpen, setModalAcaoIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [View, setView] = useState('0')

  function onOpenModalDeleteAcao(id: string): void {
    setModalAcaoIsOpen(true)
    setId(id)
  }

  function onCloseModalDeleteAcao(): void {
    setModalAcaoIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    if (query.get('pdiId')) {
      setView('1')
    } else {
      setView('0')
    }
  }, [query])

  return (
    <Flex flexDir={'column'}>
      <ModalDelete
        isOpen={ModalAcaoIsOpen}
        message='Deseja realmente excluir esta ação?'
        onRequestClose={onCloseModalDeleteAcao}
        onConfirm={() => ExluirAcao(Id ?? '')}
      />
      {
        (View === '0') && (
          <Flex gap='1rem' flexDirection='column' padding={'0 1rem'}>
            {pdis && pdis?.emAndamento?.length === 0 && pdis?.aprovacao.length === 0 && (
              <ArrowButton>
                <MetaButton bg='v2' size='lg' onClick={() => nav('/Desempenho/PDI/Criar')}>Começar novo PDI</MetaButton>
              </ArrowButton>
            )}
            {
              (pdis && pdis?.aprovacao.length > 0) && (
                <Flex flexDir={'column'}>
                  <h3>PDI aguardando aprovação</h3>
                  <Flex marginTop={'.5rem'} flexDir={'column'}>
                    <TabelaAprovacao slice={4} dados={pdis?.aprovacao} />
                  </Flex>
                </Flex>
              )
            }
            {
              (pdis && pdis?.emAndamento.length > 0) && (
                <Flex flexDir={'column'}>
                  <h3>PDI em andamento</h3>
                  <Flex marginTop={'.5rem'} flexDir={'column'}>
                    <TabelaEmAndamento slice={4} dados={pdis.emAndamento} />
                  </Flex>
                </Flex>
              )
            }
            {
              (pdis && pdis?.emEdicao.length > 0) && (
                <Flex flexDir={'column'}>
                  <h3>PDI aguardando alteração</h3>
                  <Flex marginTop={'.5rem'} flexDir={'column'}>
                    <TabelaEmAndamento slice={4} dados={pdis.emEdicao} />
                  </Flex>
                </Flex>
              )
            }
          </Flex>
        )
      }

      {
        (View === '1') && (
          <Flex flexDir={'column'}>
            <Button
              width={'fit-content'}
              VarColor='Azul'
              size={'md'}
              marginBottom='1rem'
              onClick={() => nav(`/${pathname.split('/').splice(1, 3).join('/')}`)}
            >Visualizar PDIs</Button>

            <h3>Aprovando PDI</h3>
            <Flex marginTop={'1rem'} flexDir={'column'} gap='1rem'>
              {
                Acoes.map((e, i) => {
                  return (
                    <Acao
                      key={i}
                      onRemove={onOpenModalDeleteAcao}
                      onUpdate={AtualizarAcao}
                      acao={e}
                    />
                  )
                })
              }
              <Flex gap={'1rem'}>
                <Button
                  VarColor='Azul' onClick={AprovarPDI}
                >Aprovar PDI</Button>
                <Button
                  onClick={SolicitarAjustes}
                  isDisabled={Acoes.find(e => e.solicitarAjustes) === undefined}
                  VarColor='Rosa'
                >Solicitar ajustes</Button>
              </Flex>
            </Flex>
          </Flex>
        )
      }
    </Flex>
  )
}
