/* eslint-disable no-tabs */
import { Box } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'

interface iProps {
  bgColor: string
  navegar: number
}

export const BoxModelo: React.FC<iProps> = ({ bgColor, navegar, children }) => {
  const nav = useNavigate()
  const { pathname } = useLocation()

  function ObterTipoPesquisa(): string {
    if (pathname.includes('PesquisaPercepcao')) { return 'PesquisaPercepcao' }
    return 'AnaliseDesempenho'
  }

  function NavegarCriacao(tipo: number): void {
    if (tipo === 0) {
      nav(`/Rh/${ObterTipoPesquisa()}/PesquisaQuantitativa/Criar`)

      return
    }

    nav(`/Rh/${ObterTipoPesquisa()}/PesquisaIndicativa/Criar`)
  }

  return (
		<Box onClick={() => NavegarCriacao(navegar)} px={'2rem'} py={'2rem'} w={'32.5rem'} bgColor={bgColor} color={'#fff'} gap={'1rem'} borderRadius={'1rem'}>
			{children}
		</Box>
  )
}
