import styled, { css } from 'styled-components'

export const ButtonMenu = styled.button<{selected?: boolean}>`
background: #FFFFFF;
border: 4px solid var(--Azul);
border-radius: 12px;
font-family: 'Roboto';
font-weight: 500;
font-size: 1rem;
color: #000000;
padding: 1rem 2rem;


 ${props => !props.selected && css`
    &:hover{
      transition:var(--transitionBtns);
      background:var(--Azul);
      color: white;
    }
 `}

 ${props => props.selected && css`
     background: var(--Azul);
     color:white;
 `}
`
