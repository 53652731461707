import styled, { css } from 'styled-components'

interface ISmallColumnProps {
  isActive?: boolean
}
interface iProps {
  color: string
}

export const Container = styled.div`
  table {
    width: 100%;
    border-spacing: 0 1rem;


    th {
      font-weight: normal;
      font-size: 1.5rem;
      color: var(--blue-600);
      border-bottom: 6px solid var(--c4);
      text-align: left;

      padding: 1rem 1.5rem;
    }
    
    tbody{
      tr{
        border-radius: .5rem;
        box-shadow:var(--SombraBackground);
      }
    }

    td {
      background: var(--c1);
      padding: 2rem 1.5rem;
      

      strong {
        font-size: 1.5rem;
        font-weight: normal;
        color: var(--a4);
      }

      span {
        font-size: 1rem;
        color: var(--c5);
      }
    }
  }
`

export const SmallColumnHeader = styled.th`
  text-align: center !important;
`

export const AvatarColumn = styled.td<iProps>`
  height: 7rem;
  width: 6rem;
  padding: 0.25rem !important;
  background: var(--c2);

  div {
    display: flex;


    img:hover{
      border: 2px solid ${props => props.color};
      transition: all .2s ease;
      cursor: pointer;
    }
    /* img {
      height: 7rem;
      width: 6rem;
      border: 2px solid var(--c2);
    } */
  }
`

export const SmallColumn = styled.td<ISmallColumnProps>`
  text-align: center;

  > svg {
    font-size: 1.5rem;
    color: var(--c5);

    ${(props) =>
      props.isActive &&
      css`
        color: var(--v2);
      `}
  }
  div {
    display: flex;
    justify-content: flex-end;

    button {
      background: transparent;
      padding: 0.25rem;
      border: 0;
      outline: 0;

      font-size: 2rem;
      color: var(--c4);

      svg {
        display: block;
      }

      & + button {
        color: var(--terc3);

        margin-left: 0.5rem;
      }
    }
  }
`
