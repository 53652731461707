import styled from 'styled-components'

interface iProps {
  varColor: string
  selected: boolean
  isDisabled?: boolean
}

export const Legenda = styled.small`
  font-size: 14px;
  color: var(--Gray3);
  font-weight: 499;
  margin-top: .5rem;
`
export const BotaoSocial = styled.button<iProps>`
  color: ${(props) => props.varColor};
  border: 2px solid ${(props) => props.varColor};
  ${({ selected, varColor }) => selected && `
        box-shadow: 0 0 5px ${varColor};
  `}

  ${({ isDisabled }) => isDisabled && `
        opacity:.5;
        pointer-events:none;
  `}
  outline: 5px;
  width: 6.7rem;
  height: 2.5rem;
  border-radius: 6px;
  background-color: #fff;
`
