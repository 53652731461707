/* eslint-disable no-tabs */
import { Flex, FormControl, FormLabel, Img, Text, Textarea, useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import gifCheck from './assets/checkGif.gif'

export const ReuniaoRealizada: React.FC = () => {
  const { processoId, etapaId } = useParams<{ processoId: string, etapaId: string }>()
  const [Comentario, setComentario] = useState('')
  const { pathname } = useLocation()
  const nav = useNavigate()
  const toast = useToast()
  const rota = pathname.includes('ReuniaoOne') ? `/ReuniaoOneOldEtapaColaborador/${processoId ?? ''}/Etapa/${etapaId ?? ''}/Concluir` : `/ReuniaoEtapaColaborador/${processoId ?? ''}/Etapa/${etapaId ?? ''}/Concluir`

  function ConcluirReuniao(): void {
    appApi.patch(rota, { texto: Comentario }).then(() => {
      toast({
        title: 'Concluído com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      nav('/')
    }
    )
      .catch(err => console.log(err))
  }
  return (
		<Body>
			<Flex width={'75rem'}flexDirection={'column'} fontSize={'24px'} color={'var(--c6)'} p={'1rem'} mt={'3rem'} fontWeight={'700'} mx={'auto'} alignItems={'center'} justifyContent={'center'} bgColor={'#fff'} borderRadius={'1rem'}>
				<Text color={'var(--Azul)'} fontSize={'24px'} mb={'1rem'}>Reunião concluída com sucesso!</Text>
        <Img w={'5.5rem'} h={'5.5rem'} src={gifCheck} mb={'1rem'}/>
        <FormControl gap={'1rem'} color={'#000'}>
          <FormLabel>Deixe um comentário para registro</FormLabel>
          <Textarea placeholder='Escreva o comentário aqui' onChange={(e) => setComentario(e.currentTarget.value)}/>
          <Text fontSize={'12px'} fontWeight={'400'}>Opcional</Text>
        </FormControl>
        <Flex justifyContent={'center'} my={'1rem'}>
          <Button VarColor='v2' onClick={ConcluirReuniao}>Concluir e enviar comentário</Button>
        </Flex>
			</Flex>
		</Body>
  )
}
