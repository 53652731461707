import styled from 'styled-components'

export const Main = styled.div`
h2,h4{
    color: var(--a4);
}
`

export const SubtitleDiv = styled.div`
margin-bottom: .5rem;
display: flex;
flex-direction: column;
span{
    color: var(--a4);
    font-weight: bold;
}
`

export const LabelTitle = styled.label`
    color: var(--a4);
    font-weight: 500;
    margin-bottom: 0.25rem;
    font-size:1.5rem;
    line-height: 1.75rem;
    font-weight: bold;
`
