import styled from 'styled-components'

export const Container = styled.div`
background-color: white;
padding: 1rem;
`

export const HeaderContainer = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 3px solid var(--c4);
padding: 2rem;
background-color: white;
align-items: center;

img{
  max-height: 10rem;
  max-width: 10rem;
  object-fit: scale-down;
}
`
