import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { MetaButton } from '../Buttons/MetaButton'
import { TagContainer } from '../TagContainer'
import { TextRadio } from '../TextRadio'
import { IJobTitle, iNomeId, iPostColaborador } from '../../interfaces'
import { appApi } from '../../services/appApi'
import { ColumnLeft, DashedBox, ModalBody, ModalContent, ModalFooter, ModalHeader, TitleTipoUser } from './styles'
import { Avatar, Button, Checkbox, Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react'
import { FindUser } from '../FindBox'
import { MdClose } from 'react-icons/md'
import { CompleteTagContainer } from '../CompleteTagContainer'
import { InputFormControl } from '../InputFormControl'

import { FaBriefcase, FaPlus, FaTimes } from 'react-icons/fa'
import { CreateCargo } from '../createCargo'

interface ICreateEmployeeModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onCreateEmployee: (newEmployee: iPostColaborador) => void
  departamentoId?: string
}

export const CreateColaboradorModal: React.FC<ICreateEmployeeModalProps> = ({
  isOpen,
  onRequestClose,
  onCreateEmployee,
  departamentoId
}) => {
  Modal.setAppElement('#root')
  const toast = useToast()
  const [Adicionar, setAdicionar] = useState(false)
  const [NomeIsInValid, setNomeIsInValid] = useState(false)
  const [CPFIsInValid, setCPFIsInValid] = useState(false)
  const [EmailIsInValid, setEmailIsInValid] = useState(false)
  const [EmailExist, setEmailExist] = useState(false)
  const [CpfExist, setCpfExist] = useState(false)
  const [DataIngressoIsInValid, setDataIngressoIsInValid] = useState(false)
  const [NovaFuncao, setNovaFuncao] = useState(false)

  const [Departamentos, setDepartamentos] = useState<iNomeId[]>([])
  const [Cargos, setCargos] = useState<iNomeId[]>([])
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Model, setModel] = useState<iPostColaborador>({
    nome: '',
    sobrenome: '',
    email: '',
    cpf: '',
    celular: '',
    departamentoId: '',
    dataIngresso: '',
    tipoUsuario: 0,
    administrador: false,
    temUsuario: false,
    cargoId: '',
    primeiroAcesso: false
  })

  function onCreateCargo(obj: IJobTitle): void {
    setAdicionar(true)
    const form = {
      nome: obj.nome
    }
    appApi.post('Cargo', form).then(() => {
      getCargos()
      setNovaFuncao(false)
      toast({
        title: 'Cargo criado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
    }).catch(err => {
      toast({
        title: 'Não foi possível criar o cargo',
        description: 'Tente novamente',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      console.log(err)
    }
    )
  }

  function SelecionarGestor(id: string): void {
    setModel({ ...Model, gerenteId: id })
  }
  function SelecionarCargo(id: string): void {
    setModel({ ...Model, cargoId: id })
  }
  function SelecionarArea(id: string): void {
    setModel({ ...Model, departamentoId: id })
  }
  const [TagsSelecionadas, setTagsSelecionadas] = useState<string[]>([])
  const AtualizarArquivo = (event: any): void => {
    const formdata = new FormData()
    formdata.append('request', event.target.files[0])

    if (Model.avatar !== undefined) {
      appApi.post(`Colaborador/uploadfile?url=${Model.avatar}`, formdata).then(resposnse => {
        setModel({ ...Model, avatar: resposnse.data.uri })
      }).catch(e => console.log(e))
    } else {
      appApi.post('Colaborador/uploadfile', formdata).then(resposnse => {
        setModel({ ...Model, avatar: resposnse.data.uri })
      }).catch(e => console.log(e))
    }
  }

  function VerificarPreenchimento(): boolean {
    setNomeIsInValid(false)
    setCPFIsInValid(false)
    setEmailIsInValid(false)
    setDataIngressoIsInValid(false)
    if (Model.nome === '') {
      setNomeIsInValid(true)
      toast({
        title: 'Nome não foi informado',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    if (Model.email === '') {
      setEmailIsInValid(true)
      toast({
        title: 'E-mail não foi informado',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    if (Model.cpf === '') {
      setCPFIsInValid(true)
      toast({
        title: 'CPF não foi informado',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (Model.dataIngresso === '') {
      setDataIngressoIsInValid(true)
      toast({
        title: 'Data de admissão não foi informada',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (EmailExist) {
      setEmailIsInValid(true)
      toast({
        title: 'E-mail já cadastrado. Cadastre um novo e-mail ou edite o colaborador já cadastrado.',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }

    if (CpfExist) {
      setCPFIsInValid(true)
      toast({
        title: 'CPF já cadastrado. Verifique o colaborador e procure o administrador caso necessário.',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    return true
  }

  function InternalOnCreate(): void {
    if (VerificarPreenchimento()) {
      onCreateEmployee({
        email: Model.email.toLowerCase(),
        nome: Model.nome,
        sobrenome: Model.sobrenome,
        avatar: Model.avatar,
        departamentoId: Model.departamentoId === '' ? Departamentos[0].id : Model.departamentoId,
        cargoId: Model.cargoId === '' ? Cargos[0].id : Model.cargoId,
        gerenteId: Model.gerenteId && Model.gerenteId !== '' ? Model.gerenteId : undefined,
        celular: Model.celular,
        cpf: Model.cpf.replace('.', '').replace('-', ''),
        dataIngresso: Model.dataIngresso,
        tipoUsuario: Model.tipoUsuario,
        tags: TagsSelecionadas,
        administrador: Model.administrador,
        temUsuario: Model.temUsuario,
        primeiroAcesso: Model.primeiroAcesso
      })
    }
  }

  function VerificarEmail(): void {
    setEmailExist(false)
    setEmailIsInValid(false)
    appApi.get(`Colaborador/Email?email=${Model.email.toLowerCase()}`)
      .then(res => {
        if (res.data === true) {
          setEmailExist(true)
          setEmailIsInValid(true)
        }
      })
      .catch(err => console.log(err))
  }

  function VerificarCPF(): void {
    setCpfExist(false)
    setCPFIsInValid(false)
    appApi.get(`Colaborador/Cpf?Cpf=${Model.cpf.replace('.', '').replace('-', '')}`)
      .then(res => {
        if (res.data === true) {
          setCpfExist(true)
          setCPFIsInValid(true)
        }
      })
      .catch(err => console.log(err))
  }

  const handleRadioTipoUsuarioClick = (e: React.ChangeEvent<HTMLInputElement>): void => setModel({ ...Model, tipoUsuario: parseInt(e.currentTarget.value) })
  const isRadioTipoUsuarioelected = (value: string): boolean => Model.tipoUsuario === parseInt(value)

  function getDepartamentos(): void {
    appApi.get('Departamento')
      .then(res => {
        setDepartamentos(res.data)
      })
      .catch(err => console.log(err))
  }

  function getCargos(): void {
    appApi.get('Cargo')
      .then(res => {
        setCargos(res.data)
      })
      .catch(err => console.log(err))
  }

  function getColaboradores(): void {
    appApi.get(`Colaborador/Select/Gestor/?departamentoId=${Model.departamentoId}`)
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function addTag(tag: string): void {
    setTagsSelecionadas(oldArray => [...oldArray, tag])
  }

  function removeTag(id: string): void {
    setTagsSelecionadas(TagsSelecionadas.filter(r => r !== id))
  }

  useEffect(() => {
    getDepartamentos()
    getCargos()
  }, [])

  useEffect(() => {
    if (Departamentos.length > 0) {
      setModel({ ...Model, departamentoId: departamentoId ?? '' })
    }
  }, [Departamentos, departamentoId])

  useEffect(() => {
    if (Model.departamentoId) {
      getColaboradores()
    }
  }, [Model.departamentoId])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-scroll"
    >
      <ModalContent>
        <Flex justifyContent={'end'}>
          <MdClose cursor={'pointer'} size={24} onClick={onRequestClose} />
        </Flex>
        <ModalHeader>
          <h2>Novo colaborador</h2>
          <DashedBox htmlFor='m-file'>
            <Avatar src={Model.avatar} />
            <small>Clique para selecionar ou arraste e solte uma imagem aqui</small>
            <input id='m-file' onChange={(e) => { AtualizarArquivo(e) }} type={'file'} />
          </DashedBox>
        </ModalHeader>
        <ModalBody>
          <InputFormControl
            label='Nome'
            isInvalid={NomeIsInValid}
            onChange={(e: any) => setModel({ ...Model, nome: e.target.value })}
            placeholder='Digite o nome'
          />

          <InputFormControl
            label='Sobrenome'
            onChange={(e: any) => setModel({ ...Model, sobrenome: e.target.value })}
            placeholder='Digite o sobrenome'
          />

          <InputFormControl
            label='E-mail'
            isInvalid={EmailIsInValid}
            onBlur={() => Model.email && VerificarEmail()}
            onChange={(e: any) => setModel({ ...Model, email: e.target.value })}
            placeholder='Digite o e-mail'
            error={EmailExist}
            errorMessage='O email já foi cadastrado'
          />

          <InputFormControl
            label='CPF'
            isInvalid={CPFIsInValid}
            onChange={(e: any) => setModel({ ...Model, cpf: e.target.value })}
            onBlur={() => Model.cpf && VerificarCPF()}
            placeholder='Digite o CPF'
            error={CpfExist}
            errorMessage='O CPF já foi cadastrado'
          />

          <InputFormControl
            label='Celular'
            onChange={(e: any) => setModel({ ...Model, celular: e.target.value })}
            placeholder='Digite o celular'
          />

          {
            Cargos && (
              <Flex flexDir={'column'}>
                <Flex>
                <FormLabel fontSize={'1.3rem'} marginBottom='0.25rem' >Função
                </FormLabel>

                <Button ml={'1rem'} onClick={() => setNovaFuncao(!NovaFuncao)}
                leftIcon={NovaFuncao ? <FaTimes/> : <FaPlus/>} size={'xs'} backgroundColor={NovaFuncao ? '#ea2010' : '#1aa0bb'}>{NovaFuncao ? 'Cancelar' : 'Nova função'}</Button>
                </Flex>
                {!NovaFuncao && (<FindUser
                  lista={Cargos}
                  onChoice={SelecionarCargo}
                  adicionar={Adicionar}
                  placeholder='Digite o nome da função'
                  isNotUser
                  leftIcon={<FaBriefcase size={'1.3rem'}/>}
                />)}
                {NovaFuncao && (<CreateCargo
                 onCreateJobTitle={onCreateCargo}
                 />)}
              </Flex>
            )}
          {
            Departamentos && (
              <Flex flexDir={'column'}>
                <Flex>
                <FormLabel fontSize={'1.3rem'} marginBottom='0.25rem' >Área
                </FormLabel>

                {/* <Button ml={'1rem'} onClick={() => setNovaFuncao(!NovaFuncao)}
                leftIcon={NovaFuncao ? <FaTimes/> : <FaPlus/>} size={'xs'} backgroundColor={NovaFuncao ? '#ea2010' : '#1aa0bb'}>{NovaFuncao ? 'Cancelar' : 'Nova função'}</Button> */}
                </Flex>
                <FindUser
                  lista={Departamentos}
                  selecionado={Model.departamentoId ?? Departamentos[0].id ?? ''}
                  onChoice={SelecionarArea}
                  placeholder='Digite o nome da área'
                  isNotUser
                  leftIcon={<FaBriefcase size={'1.3rem'}/>}
                />
                {/* {NovaFuncao && (<CreateCargo
                 onCreateJobTitle={onCreateCargo}
                 />)} */}
              </Flex>
            )}

          {
            (Colaboradores) && (
              <Flex flexDir={'column'}>
                <FormLabel fontSize={'1.125rrem'} marginBottom='0.25rem'>Gestor</FormLabel>
                <FindUser
                  lista={Colaboradores}
                  onChoice={SelecionarGestor}
                  placeholder='Digite o nome do gestor'
                />
              </Flex>
            )
          }

          <TagContainer
            isGrey
            addTag={addTag}
            removeTag={removeTag}
            Selecionadas={TagsSelecionadas}
            tipo={0}
          />

          <FormControl>
            <FormLabel fontSize={'xl'}>Data de admissão</FormLabel>
            <Input
              width={'15rem'}
              isInvalid={DataIngressoIsInValid}
              type={'date'}
              borderColor={'var(--Gray4)'}
              onChange={(e) => setModel({ ...Model, dataIngresso: e.target.value })}
            />
          </FormControl>

          <Flex flexDir={'column'}>
            <TitleTipoUser>Nível de usuário</TitleTipoUser>
            <ColumnLeft>

              <TextRadio
                label='Gestor da área'
                name='lb-gestorarea'
                checked={isRadioTipoUsuarioelected('2')}
                onChange={handleRadioTipoUsuarioClick}
                value='2'
              />

              <TextRadio
                label='Gestor'
                name='lb-gestor'
                checked={isRadioTipoUsuarioelected('1')}
                onChange={handleRadioTipoUsuarioClick}
                value='1'
              />

              <TextRadio
                label='Colaborador'
                name='lb-colab'
                checked={isRadioTipoUsuarioelected('0')}
                onChange={handleRadioTipoUsuarioClick}
                value='0'
              />
            </ColumnLeft>
          </Flex>

          <FormControl marginBottom={'1rem'}>
            <FormLabel>Squads</FormLabel>
            <TagContainer
              isGrey
              addTag={addTag}
              removeTag={removeTag}
              Selecionadas={TagsSelecionadas}
              tipo={2}
              placeholder={'Digite o nome do squad'}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Papéis</FormLabel>
            <CompleteTagContainer
              isGrey
              addTag={addTag}
              removeTag={removeTag}
              selecionadas={TagsSelecionadas}
            />
          </FormControl>

          <Flex alignItems={'center'} marginTop={'1rem'}>
            <Checkbox borderColor={'var(--Gray4)'} size='lg' onChange={(e) => setModel({ ...Model, administrador: e.target.checked })} isChecked={Model.administrador} marginRight={'1rem'} />
            <FormLabel margin={0}>Marque para dar permissão de administrador ao usuário</FormLabel>
          </Flex>

          <Flex alignItems={'center'} marginTop={'1rem'}>
            <Checkbox borderColor={'var(--Gray4)'} size='lg' onChange={(e) => setModel({ ...Model, temUsuario: e.target.checked })} isChecked={Model.temUsuario} marginRight={'1rem'} />
            <FormLabel margin={0}>Já possui usuário</FormLabel>
          </Flex>
          <Flex alignItems={'center'} marginTop={'1rem'}>
            <Checkbox borderColor={'var(--Gray4)'} size='lg' onChange={(e) => setModel({ ...Model, primeiroAcesso: e.target.checked })} isChecked={Model.primeiroAcesso} marginRight={'1rem'} />
            <FormLabel margin={0}>Não enviar email de primeiro acesso</FormLabel>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <MetaButton bg='v2' size='md' onClick={InternalOnCreate}>Cadastrar colaborador</MetaButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
