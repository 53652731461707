import { Avatar, AvatarBadge, Flex, Tooltip } from '@chakra-ui/react'
import { ReactNode, useState } from 'react'
import { FaAngleDown, FaAngleUp, FaRegComment } from 'react-icons/fa'
import { iCorTabela, iDiscursiva, iLinhaTabela } from '../..'
import { iCompetenciaPDI } from '../../../../../interfaces'
import { ModalComentarios } from './components/ModalComentarios'
import { StyledTd, TableDiv } from './styles'

export interface iMediaAbsoluta {
  mediaAltaLideranca: number
  mediaPares: number
  mediaAutoAval: number
  mediaGestor: number
  mediaLiderados: number
  mediaOutros: number
  mediaSemAuto: number
}

interface iProps {
  linhas: iLinhaTabela[]
  discursivas: iDiscursiva[]
  cores: iCorTabela[]
  recomendacoes: iCompetenciaPDI[]
  temColuna: (tipo: number) => boolean
}

export const Tabela: React.FC<iProps> = ({ discursivas, linhas, cores, recomendacoes, temColuna }) => {
  const [open, setOpen] = useState<number[]>([])
  const [Competencia, setCompetencia] = useState<string>()
  const [ModalComentariosIsOpen, setModalComentariosIsOpen] = useState(false)

  function CriaDropQuestoes(e: iLinhaTabela[], nomeCompetencia: string): ReactNode {
    return (
            <>
                {e.map((questao, index) => {
                  const temDiscursiva = discursivas.filter(r => r.titulo.includes(questao.competencia)).length > 0
                  return (
                        <tr key={index}>
                            <td className='dropDownTr'>
                                <Flex alignItems={'center'}>
                                    <Flex flex={'1'} marginRight={'4px'}>
                                        <Tooltip hasArrow label={questao.competencia} bg={'var(--Preto)'} color={'var(--c1)'} placement={'top'} padding={'.9rem'}>
                                            {nomeCompetencia + ` ${index + 1}`}
                                        </Tooltip>
                                    </Flex>
                                    {temDiscursiva &&
                                        <Avatar
                                            onClick={() => temDiscursiva ? onOpenModalCompetencia(questao.competencia) : {}}
                                            _hover={{ backgroundColor: 'var(--Gray4)' }}
                                            bg={'var(--c3)'}
                                            width={'2rem'}
                                            height={'2rem'} icon={<FaRegComment size={20} />}
                                        >
                                            <AvatarBadge
                                                boxSize='1.75em'
                                                bg='#b2c6ea'
                                                fontSize={'.6rem'}
                                                fontWeight='bold'
                                            >
                                                {discursivas.filter(d => d.titulo.includes(questao.competencia)).length}
                                            </AvatarBadge>
                                        </Avatar>
                                    }
                                    {!temDiscursiva && <Flex width={'1.25rem'}></Flex>}
                                </Flex>
                            </td>
                            {temColuna(0) && <StyledTd >{questao.autoAvaliacao}</StyledTd>}
                            {temColuna(1) && <StyledTd >{questao.gestor}</StyledTd>}
                            {temColuna(2) && <StyledTd >{questao.pares}</StyledTd>}
                            {temColuna(3) && <StyledTd >{questao.liderados}</StyledTd>}
                            {temColuna(4) && <StyledTd >{questao.outros}</StyledTd>}
                            {temColuna(6) && <StyledTd >{questao.altalideranca}</StyledTd>}
                            <StyledTd theme={VerificarCorRecomendacao('1', index, questao.competencia)}>{questao.mediaSemAuto}</StyledTd>
                        </tr>
                  )
                })}
            </>
    )
  }

  function TooltipLabel(e: iLinhaTabela): string {
    if (e.questoes.length > 1) {
      return `${e.questoes.length} Questões`
    } else {
      return e.questoes[0].competencia ?? 'Sem descrição'
    }
  }

  function VerificarCorV2(eixo: string, nota: string): string {
    const dado = cores.find(e => e.eixo === eixo)
    if (dado) {
      return dado.notasPositivas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : dado.notasNegativas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? 'var(--Orange)' : 'black'
    }
    return 'black'
  }

  const toggle = (e: number): void => {
    if (open.some(valor => valor === e)) {
      setOpen(open.filter(valor => valor !== e))
    } else {
      setOpen([...open, e])
    }
  }

  function VerificarCorRecomendacao(nota: string, index: number, questao?: string): string {
    const dado = cores.find(e => e.eixo === 'semAutoavaliacao')
    if (questao) {
      return recomendacoes.some(e => e.pergunta === questao) ? 'var(--Orange)' : 'black'
    }

    if (dado) {
      return dado.notasPositivas.some(r => r.toFixed(2) === nota?.replace(',', '.')) ? '#00AA00' : dado.recomendacoes.some(e => e === index) ? 'var(--Orange)' : 'black'
    }
    return 'black'
  }

  function MostrarComentario(e: iLinhaTabela): ReactNode {
    const temDiscursiva = discursivas.filter(r => r.titulo.includes(e.competencia)).length > 0
    return (
            <>
                <Flex justifyContent='flex-end'>
                    {temDiscursiva && (
                        <Avatar
                            onClick={() => onOpenModalCompetencia(e.competencia)}
                            _hover={{ backgroundColor: 'var(--Gray4)' }}
                            bg={'var(--c3)'}
                            width={'2rem'}
                            height={'2rem'} icon={<FaRegComment size={20} />}
                        >
                            <AvatarBadge
                                boxSize='1.75em'
                                bg='#b2c6ea'
                                fontSize={'.6rem'}
                                fontWeight='bold'
                            >
                                {discursivas.filter(d => d.titulo.includes(e.competencia)).length}
                            </AvatarBadge>
                        </Avatar>
                    )}
                </Flex>
            </>
    )
  }

  function onOpenModalCompetencia(nome: string): void {
    setCompetencia(nome)
    setModalComentariosIsOpen(true)
  }

  function onCloseModalCompetencia(): void {
    setCompetencia(undefined)
    setModalComentariosIsOpen(false)
  }

  function handleMedia(): iMediaAbsoluta[] {
    const medias = {
      mediaAltaLideranca: 0,
      mediaPares: 0,
      mediaAutoAval: 0,
      mediaGestor: 0,
      mediaLiderados: 0,
      mediaOutros: 0,
      mediaSemAuto: 0
    }
    linhas.forEach((e): void => {
      const parse = parseFloat(e.pares?.replace(',', '.') ?? '')
      medias.mediaPares = medias.mediaPares + (isNaN(parse) ? 0 : parse)
    })
    linhas.forEach((e): void => {
      const parse = parseFloat(e.autoAvaliacao?.replace(',', '.') ?? '')
      medias.mediaAutoAval = medias.mediaAutoAval + (isNaN(parse) ? 0 : parse)
    })
    linhas.forEach((e): void => {
      const parse = parseFloat(e.gestor?.replace(',', '.') ?? '')
      medias.mediaGestor = medias.mediaGestor + (isNaN(parse) ? 0 : parse)
    })
    linhas.forEach((e): void => {
      const parse = parseFloat(e.liderados?.replace(',', '.') ?? '')
      medias.mediaLiderados = medias.mediaLiderados + (isNaN(parse) ? 0 : parse)
    })
    linhas.forEach((e): void => {
      const parse = parseFloat(e.mediaSemAuto.replace(',', '.') ?? '')
      medias.mediaSemAuto = medias.mediaSemAuto + (isNaN(parse) ? 0 : parse)
    })
    linhas.forEach((e): void => {
      const parse = parseFloat(e.outros?.replace(',', '.') ?? '')
      medias.mediaOutros = medias.mediaOutros + (isNaN(parse) ? 0 : parse)
    })
    linhas.forEach((e): void => {
      const parse = parseFloat(e.altalideranca?.replace(',', '.') ?? '')
      medias.mediaAltaLideranca = medias.mediaAltaLideranca + (isNaN(parse) ? 0 : parse)
    })
    const mediasCalculado = [{
      mediaAltaLideranca: medias.mediaAltaLideranca / linhas.filter(r => r.altalideranca).length,
      mediaPares: medias.mediaPares / linhas.filter(r => r.pares).length,
      mediaAutoAval: medias.mediaAutoAval / linhas.filter(r => r.autoAvaliacao).length,
      mediaGestor: medias.mediaGestor / linhas.filter(r => r.gestor).length,
      mediaLiderados: medias.mediaLiderados / linhas.filter(r => r.liderados).length,
      mediaOutros: medias.mediaOutros / linhas.filter(r => r.outros).length,
      mediaSemAuto: medias.mediaSemAuto / linhas.filter(r => r.mediaSemAuto).length
    }]
    return mediasCalculado
  }

  return (
        <>
            <ModalComentarios
                isOpen={ModalComentariosIsOpen}
                onRequestClose={onCloseModalCompetencia}
                comentarios={discursivas.filter(r => r.titulo.includes(Competencia ?? ''))}
            />
            <TableDiv>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <Flex alignItems='center' justifyContent={'space-between'}>
                                    <Flex width={'100%'} justifyContent='center'>
                                        Competência
                                    </Flex>
                                    <Flex width={'1.25rem'}></Flex>
                                </Flex></th>
                            {temColuna(0) && <th>Autoavaliação </th>}
                            {temColuna(1) && <th>Gestor</th>}
                            {temColuna(2) && <th>Pares</th>}
                            {temColuna(3) && <th>Liderados</th>}
                            {temColuna(4) && <th>Outros</th>}
                            {temColuna(6) && <th>Alta liderança</th>}
                            <th>Média do avaliado sem autoavaliação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            linhas.map((e, i) => {
                              return (
                                    <>
                                        <tr onClick={() => toggle(i)} key={i}>
                                            <td>
                                                <Flex width={'100%'} alignItems='center' justifyContent={'center'}>
                                                    <Flex width={'100%'} justifyContent={'center'}>
                                                        <Tooltip hasArrow label={TooltipLabel(e)} bg={'var(--Preto)'} color={'var(--c1)'} placement={'top'} padding={'.9rem'}>
                                                            {e.competencia}
                                                        </Tooltip>
                                                    </Flex>
                                                    {e.questoes.length === 1 ? MostrarComentario(e.questoes[0]) : ''}
                                                    {e.questoes.length === 1 ? '' : !open.some(valor => valor === i) ? <FaAngleDown /> : <FaAngleUp />}
                                                </Flex>
                                            </td>
                                            {temColuna(0) && <StyledTd theme={VerificarCorV2('autoavaliacao', e.autoAvaliacao ?? '')}>{e.autoAvaliacao}</StyledTd>}
                                            {temColuna(1) && <StyledTd theme={VerificarCorV2('gestor', e.gestor ?? '')}>{e.gestor}</StyledTd>}
                                            {temColuna(2) && <StyledTd theme={VerificarCorV2('par', e.pares ?? '')} >{e.pares}</StyledTd>}
                                            {temColuna(3) && <StyledTd theme={VerificarCorV2('subordinado', e.liderados ?? '')}>{e.liderados}</StyledTd>}
                                            {temColuna(4) && <StyledTd theme={VerificarCorV2('outro', e.outros ?? '')}>{e.outros}</StyledTd>}
                                            {temColuna(6) && <StyledTd theme={VerificarCorV2('altaLideranca', e.altalideranca ?? '')}>{e.altalideranca}</StyledTd>}
                                            <StyledTd theme={VerificarCorRecomendacao(e.mediaSemAuto ?? '', i)}>{e.mediaSemAuto}</StyledTd>
                                        </tr>
                                        {open.some(valor => valor === i) && e.questoes.length > 1 && CriaDropQuestoes(e.questoes, e.competencia)}
                                    </>
                              )
                            })
                        }
                        {
                            linhas && handleMedia().map((e, i) => {
                              return (
                                    <tr key={i}>
                                        <td>
                                            Média geral
                                        </td>
                                        {temColuna(0) && <StyledTd>{e.mediaAutoAval.toFixed(2)}</StyledTd>}
                                        {temColuna(1) && <StyledTd>{e.mediaGestor.toFixed(2)}</StyledTd>}
                                        {temColuna(2) && <StyledTd>{e.mediaPares.toFixed(2)}</StyledTd>}
                                        {temColuna(3) && <StyledTd>{e.mediaLiderados.toFixed(2)}</StyledTd>}
                                        {temColuna(4) && <StyledTd>{e.mediaOutros.toFixed(2)}</StyledTd>}
                                        {temColuna(6) && <StyledTd>{e.mediaAltaLideranca.toFixed(2)}</StyledTd>}
                                        <StyledTd>{e.mediaSemAuto.toFixed(2)}</StyledTd>
                                    </tr>
                              )
                            })
                        }
                    </tbody>

                </table>
            </TableDiv>
        </>
  )
}
