import { Flex } from '@chakra-ui/react'
import styled from 'styled-components'

export const Container = styled(Flex)`
overflow-y: auto;
max-height: 90vh;
flex-direction: column;
gap: 1rem;
padding: 1rem;
border-top: 1px solid var(--Gray3);

::-webkit-scrollbar {
  width: .5rem;
}
/* heigth */
::-webkit-scrollbar {
  height: 0.375rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.625rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`
