import { Box, Button, Flex, HStack, Text, Image } from '@chakra-ui/react'
import { FaBan, FaBriefcase, FaCheck, FaCopy, FaEye, FaSync, FaUsers } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { parseJwt } from '../../../../../services/token'

import { numberFormatter } from '../../../Dashboard'
interface ICardCargoProps{
  jobId: string
  nome: string
  aderentes: number
  adaptativos: number
  naoAderentes: number
  percentualPopulacao: number
  percentualPopulacaoadap: number
  perfis: string[]
  perfisAdaptativos: string[]

}
export const CardCargo: React.FC<ICardCargoProps> = ({ jobId, nome, adaptativos, aderentes, naoAderentes, perfis, perfisAdaptativos, percentualPopulacao, percentualPopulacaoadap }: ICardCargoProps) => {
  const navigate = useNavigate()
  return (

    <Box border='1px' borderRadius='20px' borderColor='var(--Azul)' my={4} px={2} >
<Flex justify='space-between' p={2}>
  <HStack>
    <FaBriefcase size='1.4rem'/>
<Text fontSize='1.4rem' fontWeight='semibold'>{nome}</Text>
  </HStack>
  <HStack color='var(--Azul)'>
    <FaUsers size='1.4rem'/>
<Text fontSize='1.2rem' >Total de perfis: {numberFormatter.format(aderentes + adaptativos)}</Text>
  </HStack>

  </Flex>
  <Flex ml='2rem' flexDirection='column' width='18.5rem'>
<Flex justifyContent='space-between'>

        <HStack m={0} p={0}>
          <FaCheck color='var(--Green2)' size='1.0rem'/>
          <Text fontSize='sm' color='var(--Green2)'>Aderentes:</Text>
        </HStack>
          <Text fontSize='sm'>{numberFormatter.format(aderentes)} perfis</Text>
</Flex>
<Flex justifyContent='space-between'>
        <HStack m={0} p={0}>
          <FaSync color='var(--Yellow)' size='1.0rem'/>
  <Text fontSize='sm' color='var(--Yellow)'>Flexibilizados:</Text>
        </HStack>
          <Text fontSize='sm'>{numberFormatter.format(adaptativos)} perfis</Text>
</Flex>
<Flex justifyContent='space-between'>
        <HStack m={0} p={0}>
          <FaBan color='var(--Red)' size='1.0rem'/>
  <Text fontSize='sm' color='var(--Red)'>Não aderentes:</Text>
        </HStack>
          <Text fontSize='sm'>{numberFormatter.format(naoAderentes)} perfis</Text>
</Flex>

  </Flex>
<Flex ml={6} mt={2} justifyContent='space-between' p={2}>
<Box width='17rem' minHeight='8rem' p={4} rounded='xl' border='2px' mb={3} borderColor='var(--Green1)'>
<Flex minHeight='5.5rem' flexDirection='column' justifyContent='space-between'>

<Text fontSize='0.8rem' >Perfis aderentes: {perfis.sort((a, b) => {
  return parseInt(a) < parseInt(b) ? -1 : 1
}).map((s) => {
  if ((perfis.indexOf(s) > 0 && perfis.indexOf(s) < perfis.length - 1) || (perfis.indexOf(s) === 0 && perfis.length > 1)) {
    return s + ', '
  } else {
    return s
  }
})}</Text>
<HStack justifyContent='center'>
  <Image src='https://sigah.blob.core.windows.net/assets/brasil1.png'/>
  <Text color='var(--c7)' fontWeight='black'>{percentualPopulacao.toFixed(2)}%</Text>
  </HStack>
</Flex>
</Box>
<Box width='17rem' minHeight='8rem' p={4} rounded='xl' border='2px' mb={3} borderColor='var(--Yellow)'>
<Flex minHeight='5.5rem' flexDirection='column' justifyContent='space-between'>
<Text fontSize='0.8rem' >Perfis flexibilizados: {perfisAdaptativos.sort((a, b) => {
  return parseInt(a) < parseInt(b) ? -1 : 1
}).map((s) => {
  if ((perfisAdaptativos.indexOf(s) > 0 && perfisAdaptativos.indexOf(s) < perfisAdaptativos.length - 1) || (perfisAdaptativos.indexOf(s) === 0 && perfis.length > 1)) {
    return s + ', '
  } else {
    return s
  }
})}</Text>
<HStack justifyContent='center' mt={3}>
  <Image src='https://sigah.blob.core.windows.net/assets/brasil1.png'/>
<Text color='var(--c7)' fontWeight='black'>{percentualPopulacaoadap.toFixed(2)}%</Text>
</HStack>
</Flex>
</Box>
<Flex justifyContent='space-between' maxWidth='40rem' wrap='wrap'>
  <Button onClick={() => navigate(`/AssessmentPi/JobTarget/${jobId}`)}
   mx={1} backgroundColor='var(--white)' borderColor='var(--a1)' border='1px' size='sm' fontWeight='normal' color='var(--a1)' borderRadius='xl'><FaEye /><Text ml={2}>Exibir detalhes</Text></Button>
  {parseJwt().consultoria === 'False' && <Button onClick={() => navigate(`/AssessmentPi/JobTarget/Edit/${jobId}`)}
  mx={1} backgroundColor='var(--white)' borderColor='var(--Rosa)' border='1px' size='sm' fontWeight='normal' color='var(--Rosa)' borderRadius='xl'><FaCopy /><Text ml={2}>Editar cargo</Text></Button>}
  {/* <Button
  onClick={() => { window.location.href = `${urlApi}/AssessmentPI/JobTarget/${jobId}/csv/?filtro=${3}` }}
  mx={1} backgroundColor='var(--Rosa)' borderColor='var(--a1)' border='1px' size='sm' fontWeight='normal' borderRadius='xl'><FaFileCsv /><Text ml={2}>Exportar CSV</Text></Button> */}
</Flex>
</Flex>
</Box>

  )
}
