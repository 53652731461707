import { Checkbox, Flex, FormControl, FormLabel, Input, Select, Switch, Textarea, useToast } from '@chakra-ui/react'
import { FaAngleLeft, FaAngleRight, FaPlus, FaTrashAlt } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { WhiteBox } from '../../../../components/WhiteBox'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { useState, useEffect } from 'react'
import { iEtapa, iNomeId } from '../../../../interfaces'
import { PropInvalida, ValidarEtapa } from '../../../../Utils/Helper'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'

import { ContainerFormulario } from './styles'
import { ModalDelete } from '../../../../components/ModalDelete'
import { ModalCreate } from './Components/ModalCreate/ModalCreate'

interface iPostPesquisa {
  titulo: string
  descricao: string
  respondente: number
  respostaGestor: boolean
  anonima: boolean
  formId?: string
  pesquisaId?: string
}

type iParams = {
  trilhaId: string
  processoId: string
  etapaId: string
}

interface iPropsForm {
  pesquisa: iPostPesquisa
  etapa: iEtapa
}

export const CriarPesquisaInterna: React.FC = () => {
  const toast = useToast()
  const { pathname } = useLocation()
  const [Formularios, setFormularios] = useState<iNomeId[]>()
  const [TelaEtapa, setTelaEtapa] = useState(1)
  const { trilhaId, etapaId, processoId } = useParams<iParams>()
  const nav = useNavigate()
  const urlPai = processoId ? 'PesquisaInternaEtapaColaborador' : 'PesquisaInternaEtapa'

  const pesquisaDefault = {
    descricao: '',
    titulo: '',
    respondente: 0,
    respostaGestor: true,
    anonima: false
  }
  const [Pesquisa, setPesquisa] = useState<iPostPesquisa>(pesquisaDefault)
  const [NomeIsInvalid, setNomeIsInvalid] = useState(false)
  const [Etapa, setEtapa] = useState<iEtapa>({})
  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [Pesquisas, setPesquisas] = useState<iPostPesquisa[]>([])

  function VerificarCampos(): boolean {
    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return false
    }
    return true
  }

  function Criar(nomeForm: string): void {
    appApi.post('PesquisaInterna/CriarFormulario', {
      texto: nomeForm
    })
      .then(res => setPesquisa({ ...Pesquisa, formId: res.data }))
      .catch(err => console.log(err))
  }

  function onCloseModalCreateForm(): void {
    setModalCreateIsOpen(false)
    setId(undefined)
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function DeleteFormulario(): void {
    appApi.delete(`PesquisaInterna/DeletarFormulario/${Id ?? ''}`).then(() => GetFormularios()).catch(err => console.log(err))
  }

  function CriarPesquisa(): void {
    setNomeIsInvalid(false)

    appApi.post('PesquisaInterna', Pesquisa)
      .then(res => {
        nav(`/Jornada/PesquisaInterna/Formulario/${res.data as string}`, { state: { formularioEditavel: true } })
      })
      .catch(err => console.log(err))
  }

  function GetFormularios(): void {
    appApi.get('PesquisaInterna/Formularios')
      .then(res => {
        if (res.status === 200) {
          setFormularios(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function CreateEtapaComposta(): void {
    setNomeIsInvalid(false)
    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form: iPropsForm = {
      pesquisa: Pesquisa,
      etapa: Etapa
    }

    appApi.post(`${urlPai}/${trilhaId ?? processoId ?? ''}`, form)
      .then(res => {
        nav(`${pathname.replace('Criar', 'Formulario')}/${res.data as string}`, { state: { formularioEditavel: true } })
      })
      .catch(err => console.log(err))
  }

  function getById(): void {
    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.get(`${urlPai}/${param}`)
      .then(res => {
        setPesquisa(res.data.pesquisa)
        setEtapa(res.data.etapa)
      })
      .catch(err => console.log(err))
  }

  function UpdateEtapaComposta(): void {
    if (Pesquisa.titulo.length < 5) {
      setNomeIsInvalid(true)
      toast({
        title: 'O nome da pesquisa deve conter no mínimo 5 caracteres',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      pesquisa: Pesquisa,
      etapa: Etapa
    }

    const param = processoId ? `${processoId}/Etapa/${etapaId as string}` : `${etapaId as string}`
    appApi.put(`${urlPai}/${param}`, form)
      .then(() => nav(-1))
      .catch(err => console.log(err))
  }

  function AtualizarEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  function getPesquisas(): void {
    appApi.get('PesquisaInternaEtapa/Select')
      .then(response => {
        if (response.status === 200) {
          setPesquisas(response.data)
        }
      })
      .catch(err => console.log(err))
  }

  function filtroPesquisa(id: string): void {
    const index = Pesquisas.findIndex(e => e.pesquisaId === id)
    if (index !== -1) {
      const data = {
        descricao: Pesquisas[index].descricao ?? '',
        titulo: Pesquisas[index].titulo ?? '',
        respondente: Pesquisas[index].respondente,
        respostaGestor: Pesquisas[index].respostaGestor,
        anonima: Pesquisas[index].anonima,
        pesquisaId: Pesquisas[index].pesquisaId
      }
      setPesquisa(data)
    }
  }

  function handleUtilizarPesquisa(val: boolean): void {
    if (!val) {
      setPesquisa(pesquisaDefault)
      return
    }
    const data = {
      descricao: Pesquisas[0].descricao ?? '',
      titulo: Pesquisas[0].titulo ?? '',
      respondente: Pesquisas[0].respondente,
      respostaGestor: Pesquisas[0].respostaGestor,
      anonima: Pesquisas[0].anonima,
      pesquisaId: Pesquisas[0].pesquisaId
    }
    setPesquisa(data)
  }

  useEffect(() => {
    if (trilhaId ?? processoId ?? etapaId) {
      if (etapaId) {
        getById()
      }
    }
    getPesquisas()
    GetFormularios()
  }, [])

  useEffect(() => {
    if (Pesquisa.formId) {
      CriarPesquisa()
    }
  }, [Pesquisa])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir este formulário?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeleteFormulario}
      />
      <ModalCreate
        isOpen={ModalCreateIsOpen}
        onRequestClose={onCloseModalCreateForm}
        onConfirm={Criar}
      />
      {TelaEtapa === 1 && <WhiteBox>
        <Flex marginBottom={'2rem'}>
          <h1>Jornada {'>'} Pesquisas internas {'>'} Nova pesquisa</h1>
        </Flex>
        <Flex flexDir={'column'} gap='1rem' marginBottom={'2rem'}>
          {(trilhaId ?? etapaId ?? processoId) && !pathname.includes('Editar') &&
            <Flex alignItems={'center'}>
              <Checkbox
                onChange={(e) => {
                  handleUtilizarPesquisa(e.target.checked)
                }} borderColor={'var(--Gray4)'} marginRight={'.5rem'} />
              <FormLabel margin={0} fontSize={'xl'}>Utilizar pesquisa existente</FormLabel>
            </Flex>
          }

          {!PropInvalida(Pesquisa.pesquisaId) && (
            <FormControl>
              <FormLabel fontSize={'xl'}>Selecione uma pesquisa</FormLabel>
              <Select
                value={Pesquisa.pesquisaId}
                onChange={(e) => filtroPesquisa(e.target.value)}
                width={'15rem'}
                borderColor={'var(--Gray4)'}
              >
                {
                  Pesquisas?.map((e, i) => {
                    return (
                      <option key={i} value={e.pesquisaId}>{e.titulo}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
          )}

          <FormControl>
            <FormLabel fontSize={'xl'}>Nome da pesquisa</FormLabel>
            <Input
              isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
              isInvalid={NomeIsInvalid}
              placeholder='Digite o nome da pesquisa'
              value={Pesquisa.titulo}
              type={'text'}
              onChange={(e) => setPesquisa({ ...Pesquisa, titulo: e.target.value })}
              borderColor={'var(--Gray4)'}
            />
          </FormControl>

          {
            (trilhaId ?? etapaId ?? processoId) && (
              <>
                <Flex flexDir={'column'}>
                  <FormControl marginBottom={'1rem'}>
                    <FormLabel fontSize={'xl'}>Para quem será enviada a pesquisa</FormLabel>
                    <Select
                      value={Pesquisa.respondente}
                      onChange={(e) => setPesquisa({ ...Pesquisa, respondente: parseInt(e.target.value) })}
                      width={'15rem'}
                      borderColor={'var(--Gray4)'}
                      isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
                    >
                      <option value={'0'}>Gestor</option>
                      <option value={'1'}>Colaborador</option>
                    </Select>
                  </FormControl>

                  <Flex alignItems={'center'}>
                    <Checkbox
                      onChange={(e) => setPesquisa({ ...Pesquisa, respostaGestor: !e.target.checked })}
                      isChecked={!Pesquisa.respostaGestor}
                      borderColor={'var(--Gray4)'}
                      marginRight={'.5rem'}
                      isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
                    />
                    <FormLabel
                      margin={0}
                      fontSize={'sm'}
                    >Selecione caso não queira disponibilizar as respostas ao gestor</FormLabel>
                  </Flex>
                </Flex>
                <CreateEtapaBox
                  EditEtapa={Etapa}
                  ignoreId={etapaId}
                  variant='Etapa'
                  onModelChange={AtualizarEtapa}
                  keyWord='pesquisa interna'
                />
              </>
            )
          }

          <FormControl>
            <FormLabel fontSize={'xl'}>Descrição da pesquisa</FormLabel>
            <Textarea placeholder='Digite a descrição da pesquisa' value={Pesquisa.descricao}
              onChange={(e) => setPesquisa({ ...Pesquisa, descricao: e.target.value })}
              borderColor={'var(--Gray4)'}
              isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
            />
          </FormControl>

          {
            (!pathname.includes('Editar')) && (
              <FormControl gap={'1rem'} alignItems='center' as={Flex}>
                <FormLabel margin={0} fontSize={'xl'}>Pesquisa anônima</FormLabel>
                <Switch
                  isChecked={Pesquisa.anonima}
                  onChange={() => setPesquisa({ ...Pesquisa, anonima: !Pesquisa.anonima })}
                  size={'lg'}
                  isDisabled={!PropInvalida(Pesquisa.pesquisaId)}
                />
              </FormControl>
            )
          }
        </Flex>
        <Flex padding={'0 1rem 0 0'} justifyContent={'end'}>
          <Button leftIcon={<FaAngleLeft />} onClick={() => nav(-1)} VarColor='c5' size={'lg'}>Voltar</Button>
          {
            (etapaId === undefined && (trilhaId ?? processoId)) && (
              <Button marginLeft={'1rem'} rightIcon={<FaAngleRight />} VarColor='Green2' size={'lg'} onClick={CreateEtapaComposta}>Criar etapa</Button>
            )
          }

          {
            (etapaId) && (
              <Button marginLeft={'1rem'} VarColor='Green2' size={'lg'} onClick={UpdateEtapaComposta}>Atualizar</Button>
            )
          }

          {
            (trilhaId === undefined && etapaId === undefined && processoId === undefined) && (
              <Button marginLeft={'1rem'} rightIcon={<FaAngleRight />} VarColor='Green2' size={'lg'} onClick={() => { if (VerificarCampos()) { setTelaEtapa(2) } }}>Próximo</Button>
            )
          }
        </Flex>
      </WhiteBox>}
      {TelaEtapa === 2 && <WhiteBox>
        <Flex marginBottom={'2rem'}>
          <h1>Jornada {'>'} Pesquisas internas {'>'} Nova pesquisa</h1>
        </Flex>
        <Flex flexDir={'column'} gap='1rem' marginBottom={'2rem'}>
          <Button
            my={'2rem'}
            w={'16rem'}
            VarColor={'v2'}
            leftIcon={<FaPlus />}
            onClick={() => setModalCreateIsOpen(true)}
          >Criar novo Formulário</Button>
          <h2>Formulários salvos</h2>
          <section>
            {
              Formularios?.map((e, i) => (
                <ContainerFormulario key={i}>
                  <span>{e.nome}</span>
                  <div>
                    <button onClick={() => setPesquisa({ ...Pesquisa, formId: e.id })}> Selecionar</button>
                    <button onClick={() => onOpenModalDelete(e.id)}><FaTrashAlt size={11} /></button>
                  </div>
                </ContainerFormulario>
              ))
            }
          </section>
        </Flex>
        <Flex padding={'0 1rem 0 0'} justifyContent={'end'}>
          <Button leftIcon={<FaAngleLeft />} onClick={() => setTelaEtapa(1)} VarColor='c5' size={'lg'}>Voltar</Button>
          {
            (etapaId === undefined && (trilhaId ?? processoId)) && (
              <Button marginLeft={'1rem'} rightIcon={<FaAngleRight />} VarColor='Green2' size={'lg'} onClick={CreateEtapaComposta}>Criar etapa</Button>
            )
          }

          {
            (etapaId) && (
              <Button marginLeft={'1rem'} VarColor='Green2' size={'lg'} onClick={UpdateEtapaComposta}>Atualizar</Button>
            )
          }

          {
            (trilhaId === undefined && etapaId === undefined && processoId === undefined) && (
              <Button marginLeft={'1rem'} rightIcon={<FaAngleRight />} VarColor='Green2' size={'lg'} onClick={CriarPesquisa}>Próximo</Button>
            )
          }
        </Flex>
      </WhiteBox>}
    </Body>
  )
}
