/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Avatar, Flex } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { iCardOrganograma } from '../../../../interfaces'
import { parseJwt } from '../../../../services/token'

import { CardParticipante, Container, ImgAssociado, BtnPerfil } from './styles'

interface iProps {
  Lider?: iCardOrganograma
  Pares: iCardOrganograma[]
  liderados: iCardOrganograma[]
}

export const BoxOrganogram: React.FC<iProps> = ({ Lider, Pares, liderados }) => {
  const Nav = useNavigate()
  return (
        <Container>
            <Flex justifyContent={'center'}>
                <span className='DashTitle'>Organograma</span>
            </Flex>
            <Flex flexDir={'column'}>
                {
                    (Lider) && (
                        <Flex justifyContent={'center'} gap='1rem' marginBottom='.5rem'>
                            <CardParticipante onClick={() => { if (parseJwt().adm === 'True') { Nav(`/Rh/VisualizarPerfil/${Lider.id}`) } }}>
                                <Avatar height={'2.5rem'} width={'2.5rem'} marginRight={'.5rem'} size={'sm'} name={Lider.nome} src={Lider?.avatar} />
                                <Flex flexDir={'column'}>
                                    <span>{Lider?.nome.substring(0, 10)}...</span>
                                    <strong>Líder</strong>
                                </Flex>
                            </CardParticipante>
                        </Flex>
                    )
                }
                <Flex justifyContent={'center'} gap='1rem' marginBottom='.5rem'>
                    {
                        (Pares?.length <= 2) && (
                          Pares.map((e: iCardOrganograma, i) => {
                            return (
                                    <CardParticipante key={i} onClick={() => { if (parseJwt().adm === 'True') { Nav(`/Rh/VisualizarPerfil/${e.id}`) } } }>
                                        <Avatar height={'2.5rem'} width={'2.5rem'} marginRight={'.5rem'} size={'sm'} name={e.nome} src={e.avatar} />
                                        <Flex flexDir={'column'}>
                                            <span>{e.nome.substring(0, 10)}...</span>
                                            <strong>Par</strong>
                                        </Flex>
                                    </CardParticipante>
                            )
                          })
                        )
                    }

                    {
                        (Pares?.length >= 3) && (
                            <>
                                <CardParticipante onClick={() => { if (parseJwt().adm === 'True') { Nav(`/Rh/VisualizarPerfil/${Pares[0].id}`) } else { console.log(parseJwt().adm) } }}>
                                    <ImgAssociado src={Pares[0].avatar} />
                                    <Flex flexDir={'column'}>
                                        <span>{Pares[0].nome.substring(0, 10)}...</span>
                                        <strong>Par</strong>
                                    </Flex>
                                </CardParticipante>

                                <div style={{ width: '11rem', height: '0rem' }}>
                                    <CardParticipante style={{ position: 'absolute', zIndex: 4 }} onClick={() => { if (parseJwt().adm === 'True') { Nav(`/Rh/VisualizarPerfil/${Pares[1].id}`) } }}>
                                        <ImgAssociado src={Pares[1].avatar} />
                                        <Flex flexDir={'column'}>
                                            <span>{Pares[1].nome.substring(0, 10)}...</span>
                                            <strong>Par</strong>
                                        </Flex>
                                    </CardParticipante>

                                    <CardParticipante style={{ position: 'relative', left: '.5rem', zIndex: 3 }}>
                                        <ImgAssociado src='' />
                                        <Flex flexDir={'column'}>
                                            <span>Nome do líder</span>
                                            <strong>Par</strong>
                                        </Flex>
                                    </CardParticipante>

                                    <CardParticipante style={{ position: 'relative', left: '1rem', top: '-4rem', zIndex: 2 }}>
                                        <ImgAssociado src='' />
                                        <Flex flexDir={'column'}>
                                            <span>Nome do líder</span>
                                            <strong>Par</strong>
                                        </Flex>
                                    </CardParticipante>
                                </div>
                            </>
                        )
                    }
                </Flex>

                <Flex justifyContent={'center'} gap='1rem'>
                    {
                        (liderados?.length <= 2) && (
                          liderados.map((e: iCardOrganograma, i) => {
                            return (
                                    <CardParticipante key={i} onClick={() => { Nav(`/Rh/VisualizarPerfil/${e.id}`) }}>
                                        <ImgAssociado src={e.avatar} />
                                        <Flex flexDir={'column'}>
                                            <span>{e.nome.substring(0, 10)}...</span>
                                            <strong>Liderado</strong>
                                        </Flex>
                                    </CardParticipante>
                            )
                          })
                        )
                    }

                    {
                        (liderados?.length >= 3) && (
                            <>
                                <CardParticipante onClick={() => { Nav(`/Rh/VisualizarPerfil/${liderados[0].id}`) } }>
                                    <ImgAssociado src={liderados[0].avatar} />
                                    <Flex flexDir={'column'}>
                                        <span>{liderados[0].nome.substring(0, 10)}...</span>
                                        <strong>Liderado</strong>
                                    </Flex>
                                </CardParticipante>

                                <div style={{ width: '11rem', height: '0rem' }}>
                                    <CardParticipante style={{ position: 'absolute', zIndex: 4 }} onClick={() => { Nav(`/Rh/VisualizarPerfil/${liderados[1].id}`) } }>
                                        <ImgAssociado src={liderados[1].avatar} />
                                        <Flex flexDir={'column'}>
                                            <span>{liderados[1].nome.substring(0, 10)}...</span>
                                            <strong>Liderado</strong>
                                        </Flex>
                                    </CardParticipante>

                                    <CardParticipante style={{ position: 'relative', left: '.5rem', zIndex: 3 }}>
                                        <ImgAssociado src='' />
                                        <Flex flexDir={'column'}>
                                            <span>Nome do líder</span>
                                            <strong>Par</strong>
                                        </Flex>
                                    </CardParticipante>

                                    <CardParticipante style={{ position: 'relative', left: '1rem', top: '-4rem', zIndex: 2 }}>
                                        <ImgAssociado src='' />
                                        <Flex flexDir={'column'}>
                                            <span>Nome do líder</span>
                                            <strong>Par</strong>
                                        </Flex>
                                    </CardParticipante>
                                </div>
                            </>
                        )
                    }
                </Flex>
                <BtnPerfil style={{ marginTop: '10px' }} theme={'vg4'} onClick={() => Nav('/Organograma/Dashboard')}>Visualizar organograma</BtnPerfil>
            </Flex>
        </Container>
  )
}
