import { Container } from './styles'

interface iDadoTabela {
  assunto: string
  totalPessoas: number
}

interface iTabelaProps {
  dados: iDadoTabela[]
}

export const TabelaCompetencias: React.FC<iTabelaProps> = ({ dados }) => {
  return (
    <Container>
      <thead>
        <tr>
          <th>Competências/assunto</th>
          <th>Total de pessoas</th>
        </tr>
      </thead>
      <tbody>
        {
          dados.slice(0, 6).sort((a, b) => { return b.totalPessoas - a.totalPessoas }).map((e, i) => {
            return (
              <tr key={i}>
                <th>{e.assunto}</th>
                <th>{e.totalPessoas}</th>
              </tr>
            )
          })
        }
      </tbody>
    </Container>
  )
}
