/* eslint-disable no-return-assign */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Flex, Text, Box, FormControl, FormLabel, Select, Input, InputRightElement, InputGroup, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BoxAzul, OutlineListaContainer } from './styles'
import { FaComments, FaSearch } from 'react-icons/fa'
import { FeedbackChart } from './Components/PieChart'
import { CardProximoFeedback } from './Components/CardProximoFeedback'
import { CsvButton } from '../../../../components/Buttons/CsvButton'
import { ModalDelete } from '../../../../components/ModalDelete'
import { WhiteBox } from '../../../../components/WhiteBox'
import { appApi, urlApi } from '../../../../services/appApi'
import { ObterToken, converterData, PropInvalida } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { Button } from '../../../../components/Button'
import { TabListaFeedback } from './Components/TabListaFeedback'
import { parseJwt } from '../../../../services/token'

interface iModel {
  principal?: iAvatarXInformacoes
  tabela: iLinhaTabela[]
}

export interface iLinhaTabela {
  id: string
  criador: iAvatarXInformacoes
  convidado: iAvatarXInformacoes
  nome: string
  dataInicio: string
  comentarios: number
  status: number
}

export interface iAvatarXInformacoes {
  id: string
  avatar: string
  info1: string
  info2?: string
  info3?: string
}

interface iFiltro {
  search: string
  dataInicio: string
  dataFim: string
  status: string
}

export const DashboardFeedback: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [Model, setModel] = useState<iModel>({ tabela: [] })

  const [Filtro, setFiltro] = useState<iFiltro>({
    dataFim: '',
    dataInicio: '',
    search: '',
    status: ''
  })
  const [IsLoading, setIsLoading] = useState(false)

  const [Id, setId] = useState<string>()
  const [DeletarIsOpen, setDeletarIsOpen] = useState(false)

  function Carregar(): void {
    setIsLoading(true)
    appApi.get('Feedback/Dashboard')
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function ConverterDataToBr(data: string): string {
    const [ano, mes, dia] = data.split('-')
    return `${dia}-${mes}-${ano}`
  }

  function ReenviarConvite(id: string): void {
    appApi.post(`Feedback/${id}/ReenviarConvite`)
      .then(() => toast({
        title: 'Convite reenviado com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      }))
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    appApi.delete(`Feedback/${Id ?? ''}`)
      .then(() => {
        toast({
          title: 'Feedback excluido com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModel({ ...Model, tabela: [...Model.tabela.filter(e => e.id !== Id)] })
      })
      .catch(err => console.log(err))
  }

  function onOpenDelete(id: string): void {
    setDeletarIsOpen(true)
    setId(id)
  }

  function onCloseDelete(): void {
    setDeletarIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    Carregar()
  }, [])

  return (
		<Body isLoading={IsLoading}>
			{DeletarIsOpen &&
				<ModalDelete
					isOpen
					onConfirm={Deletar}
					onRequestClose={onCloseDelete}
					message='Deseja realmente excluir esse feedback?'
				/>
			}

			<WhiteBox>
				<Flex mb={'1rem'} justifyContent={'space-between'}>
					<h1>Feedback</h1>
					<Button
						onClick={() => nav(`${pathname.replace('Feedback/Dashboard', 'NovaReuniaoOne/Criar')}`)}
						VarColor='Azul'
						size={'lg'}
					>Solicitar reunião 1:1</Button>
				</Flex>
				<Flex justifyContent={'space-between'} mt='1rem' maxH={'10rem'} gap={'1rem'}>
					{!PropInvalida(Model.principal) &&
						<CardProximoFeedback model={Model.principal} />
					}
					<BoxAzul>
						<Flex alignItems={'center'} justifyContent={'center'} gap={'1rem'} px={'2rem'}>
							<FaComments size={40} style={{ color: 'white' }} />
							<Box>
								<Text fontSize={'28px'} fontWeight={'700'}>{Model.tabela.length}</Text>
								<Text fontSize={'20px'} fontWeight={'400'} mt={'-5px'}>Feedbacks</Text>
							</Box>
						</Flex>
					</BoxAzul>
					<Box borderRadius={'1rem'} border={'1px solid var(--Azul)'} w={'100%'}>
						<Flex alignItems={'center'} borderRadius={'1rem'} justifyContent={'center'} gap={'1rem'} backgroundColor={'#fff'} padding={'1rem'} overflow={'hidden'}>
							{!IsLoading && <FeedbackChart
								id={'i-chart'}
								model={Model.tabela}
							/>}
						</Flex>
					</Box>
				</Flex>
				<Flex gap={'2rem'} alignItems={'flex-end'} mt={'1rem'}>
					<FormControl>
						<FormLabel>Status</FormLabel>
						<Select onChange={(e) => setFiltro({ ...Filtro, status: e.target.value })}>
							<option value="">Todos</option>
							<option value="0">Aguardando inicio</option>
							<option value="1">Pendentes</option>
							<option value="2">Finalizadas</option>
						</Select>
					</FormControl>
					<FormControl>
						<FormLabel>Periodo</FormLabel>
						<Flex alignItems={'center'} gap={'1rem'}>
							De: <Input onChange={(e) => setFiltro({ ...Filtro, dataInicio: e.target.value })} type={'date'} />
							Até: <Input onChange={(e) => setFiltro({ ...Filtro, dataFim: e.target.value })} type={'date'} />
						</Flex>
					</FormControl>
					<InputGroup width={'100%'}>
						<Input
							borderColor={'var(--Gray4)'}
							type='text'
							placeholder='Pesquisa'
							onChange={(e) => setFiltro({ ...Filtro, search: e.target.value })}
						/>
						<InputRightElement
							pointerEvents='none'
							children={<FaSearch color='var(--Gray4)' />}
						/>
					</InputGroup>
				</Flex>
				<OutlineListaContainer>
					<Flex justifyContent={'space-between'}>
						<Text fontWeight={'700'} fontSize={'24px'}>Listas de feedbacks</Text>
						{parseJwt().adm === 'True' &&
							<CsvButton
								onClick={() => window.location.href = `${urlApi}/Feedback/Csv?token=${ObterToken()}`}
							/>
						}
					</Flex>
					<TabListaFeedback
						onReenviar={ReenviarConvite}
						onExcluir={onOpenDelete}
						feedbacks={Model.tabela.filter((r) => {
						  if (Filtro.status !== '' && Filtro.status !== r.status.toString()) {
						    return null
						  }

						  if (Filtro.search !== '' && (!r.nome.toLowerCase().includes(Filtro.search.toLowerCase()) && !r.convidado.info1.toLowerCase().includes(Filtro.search.toLowerCase()) && !r.criador.info1.toLowerCase().includes(Filtro.search.toLowerCase()))) {
						    return null
						  }

						  if ((Filtro.dataInicio !== '' || Filtro.dataFim !== '') && r.dataInicio === 'Data não definida') {
						    return null
						  }

						  if (Filtro.dataInicio !== '' && converterData(r.dataInicio.split(' ')[0]) < converterData(ConverterDataToBr(Filtro.dataInicio).replaceAll('-', '/'))) {
						    return null
						  }
						  if (Filtro.dataFim !== '' && converterData(r.dataInicio.split(' ')[0]) > converterData(ConverterDataToBr(Filtro.dataFim).replaceAll('-', '/'))) {
						    return null
						  }
						  return r
						})}
					/>
				</OutlineListaContainer>
			</WhiteBox>
		</Body>
  )
}
