
import { Checkbox, Flex, Link, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi } from '../../../../services/appApi'
import { Container, HeaderContainer } from './styles'
import { LayoutFull } from '../../../Layouts/LayoutFull'
import { Button } from '../../../../components/Button'

interface iAberturaPesquisa {
  nomePesquisa: string
  anonima: boolean
  msgAbertura?: string
  dataInicio?: string
  dataFim?: string
  status: number
}

type iParam = {
  paramId: string
}

export const AberturaPesquisaInterna: React.FC = () => {
  const param = useParams<iParam>()
  const { pathname, search } = useLocation()
  const nav = useNavigate()

  const [isAccepted, setIsAccepted] = useState<boolean>(false)
  const [isAcceptedAnonimo, setIsAcceptedAnonimo] = useState<boolean>(false)
  const [Pesquisa, setPesquisa] = useState<iAberturaPesquisa>()

  function getPesquisa(): void {
    appApi.get(`PesquisaInterna/${param.paramId ?? ''}/Abertura`)
      .then(res => setPesquisa(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getPesquisa()
    console.log(Pesquisa?.msgAbertura)
  }, [])

  return (
        <LayoutFull>
            <HeaderContainer>
                <h1>{Pesquisa?.nomePesquisa}</h1>
                <img alt='Logo da empresa' src='https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png' />
            </HeaderContainer>
            <Container>
                <div>
                    {(Pesquisa?.status === 0) && (
                        <>
                            <main>
                                <section>
                                    <span dangerouslySetInnerHTML={{ __html: Pesquisa.msgAbertura?.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">') ?? '' }}></span>
                                </section>
                                <br />

                                <p>Essa pesquisa {Pesquisa.anonima ? 'é anônima' : 'não é anônima'}!</p>
                                <br />
                                <p> Disponível de <strong>{Pesquisa?.dataInicio}{Pesquisa.dataFim && ` até ${Pesquisa.dataFim}`}.</strong>
                                </p>
                                <br />
                                <p>
                                    Para iniciar o preenchimento da pesquisa, aceite os termos e clique no botão abaixo.
                                </p>
                                <br />
                                <p>
                                    Ao clicar, você estará concordando com nossa política de
                                    privacidade, disponível nesse&nbsp;link:&nbsp;
                                    <Link
                                        href="https://sah.arquiteturahumana.com.br/Privacidade"
                                        isExternal
                                        fontWeight={'bold'}
                                        textDecoration={'underline'}
                                    > Política de privacidade
                                    </Link>
                                </p>
                                <br />
                                <Stack>
                                    <Checkbox
                                        borderColor={'var(--Gray4)'}
                                        onChange={(e) => setIsAccepted(e.target.checked)}
                                    >Aceito os termos e política de privacidade</Checkbox>
                                    {!Pesquisa.anonima &&
                                        <Checkbox
                                            borderColor={'var(--Gray4)'}
                                            onChange={(e) => setIsAcceptedAnonimo(e.target.checked)}
                                        >Estou ciente que a pesquisa não é anônima</Checkbox>}
                                </Stack>
                            </main>
                            <Flex marginTop={'1rem'}>
                                {(!pathname.includes('Previsualizar')) && (
                                    <Button
                                        onClick={() => nav(`${pathname.replace('Abertura', 'Preenchimento')}${search}`)}
                                        VarColor='v2'
                                        size={'lg'}
                                        isDisabled={(!isAccepted || (!Pesquisa.anonima && !isAcceptedAnonimo))}
                                    >Responder pesquisa</Button>
                                )}
                                {(pathname.includes('Previsualizar')) && (
                                    <Button
                                        VarColor='c4'
                                        size={'lg'}
                                        onClick={() => nav(-1)}
                                    >Voltar</Button>
                                )}
                            </Flex>
                        </>
                    )}

                    {(Pesquisa?.status === 1) && (/// Não iniciada
                        <main>
                            <section>
                                <span dangerouslySetInnerHTML={{ __html: Pesquisa.msgAbertura?.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">') ?? '' }}></span>
                                <br />
                                <br />
                            </section>
                            <p>
                                Essa pesquisa estará disponível em <strong>{Pesquisa.dataInicio}{Pesquisa.dataFim ? `e se encerrará até ${Pesquisa.dataFim}.` : '.'}</strong>
                            </p>
                            <p>
                                A partir da data de início, você poderá responder neste mesmo
                                link.
                            </p>
                            <p>Te vejo em breve</p>
                        </main>
                    )}

                    {Pesquisa?.status === 2 && (
                        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                            <h1>Pesquisa já encerrada</h1>
                        </Flex>
                    )}

                    {Pesquisa?.status === 3 && (
                        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width={'auto'} marginTop={'2rem'}>
                            <h2>Obrigado pela participação!!</h2>
                            <h3>Você já respondeu esta pesquisa</h3>
                        </Flex>
                    )}
                </div>
            </Container>
        </LayoutFull>
  )
}
