/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { useEffect, useState } from 'react'
import { IDadoGrafico } from '../..'

interface iProps {
  id: string
  grafico: IGrafico
  fator: number
  getBlob?: (blob: string) => void
}

interface IGrafico {
  competencias: string[]
  dados: IDadoGrafico[]
}

export const ComparativoChart: React.FC<iProps> = ({ id, grafico, fator, getBlob }) => {
  const [Position, setPosition] = useState('')
  const [OffsetY, setOffsetY] = useState(0)
  const [Orientation, setOrientation] = useState('')
  function AbreviarNomesGrandes(): string[] {
    return grafico.competencias.map((e, i) => {
      if (grafico.competencias.length === 4) {
        return e
      } else if (e.length > 7 && i === 0) {
        return e.substring(0, 7) + '...'
      } else if (grafico.competencias.length > 4 && e.length > 20) {
        return e.substring(0, 20) + '...'
      } else {
        return e
      }
    })
  }

  function getSeries(): any {
    if (grafico.dados[1] && grafico.dados[1].medias.length < 15) {
      setPosition('top')
      setOrientation('horizontal')
      setOffsetY(-20)
    } else {
      setPosition('top')
      setOffsetY(-40)
      setOrientation('vertical')
    }
    return grafico.dados.map((e) => {
      return {
        name: e.nome,
        data: e.medias
      }
    })
  }

  const colors = [
    'rgb(0, 227, 150)',
    'rgb(0, 143, 251)',
    '#fff'
  ]

  async function GerarGrafico(): Promise<void> {
    const options = {
      colors: colors,
      series: getSeries(),
      chart: {
        type: 'bar',
        height: 550,
        width: '100%'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded',
          dataLabels: {
            position: Position,
            orientation: Orientation
          }
        }
      },
      title: {
        text: '#  Essa questão foi alterada ou não existia',
        offsetX: 460,
        offsetY: 10,
        style: {
          fontSize: '12px',
          fontWeight: '400',
          fontFamily: 'Helvetica, Arial, sans-serif',
          color: 'rgb(55, 61, 63)'
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: OffsetY,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function(val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: false
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: AbreviarNomesGrandes()
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: 0,
        max: fator + 0.5,
        show: false
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()

    chart.updateSeries(getSeries())
    const dataURL = await chart.dataURI().then((value: any) => {
      return value
    })

    if (getBlob !== undefined) {
      getBlob(dataURL.imgURI)
    }
  }

  useEffect(() => {
    GerarGrafico()
  }, [grafico])

  return (
        <div style={{ width: '100%' }} id={`chart-${id}`} />
  )
}
