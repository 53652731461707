import { FaTrashAlt, FaPlus } from 'react-icons/fa'
import { HeadContainer, ContainerFormulario, Main, Btns } from './styles'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { iFormulario } from '../../../interfaces'
import { useEffect, useState } from 'react'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { GreenButton } from '../../../components/Buttons/GreenButton'
import { GreyButton } from '../../../components/Buttons/GreyButton'
import { ModalCreateForm } from '../../../components/ModalCreateForm'
import { ModalDelete } from '../../../components/ModalDelete'
import { useToast } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { ModalDuplicarForm } from '../../../components/ModalDuplicarForm'

type navProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
}

export const Formularios: React.FC = () => {
  const toast = useToast()
  const [IsLoading, setIsLoading] = useState(false)
  const [NovoFormId, setNovoFormId] = useState('')
  const { pesquisaId } = useParams<navProps>()
  const [Formularios, setFormularios] = useState<iFormulario[]>([])
  const [isOpen, setisOpen] = useState(false)
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()
  const [ModalDuplicarIsOpen, setModalDuplicarIsOpen] = useState(false)
  const [FormularioId, setFormularioId] = useState<string>()
  const nav = useNavigate()

  const path = useLocation().pathname

  function getFormularios(): void {
    appApi.get('Formulario').then(response => setFormularios(response.data)).catch(err => console.log(err))
  }

  function DelteFormulario(): void {
    appApi.delete(`Formulario/${Id ?? ''}`).then(() => getFormularios()).catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }
  function onCloseModalDuplicar(): void {
    setModalDuplicarIsOpen(false)
    setFormularioId(undefined)
  }

  function SelectFormulario(FormId: string): void {
    appApi.put(`FormularioPesquisa/${pesquisaId as string}/Formulario/${FormId}`)
      .then(() => nav(`${path.replace('Formularios', 'VisualizarFormulario')}`))
      .catch(err => console.log(err))
  }

  function CriarFormulario(texto: string): void {
    if (texto === '') {
      toast({
        title: 'Informe um nome para o formulário',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }
    appApi.post(`FormularioPesquisa/${pesquisaId as string}`, { nome: texto }).then(() => {
      nav(`${path.replace('Formularios', 'Formulario')}`)
    }).catch(err => console.log(err))
  }
  function DuplicarEEditarForm(FormName: string): void {
    const form = { texto: FormName }
    setIsLoading(true)
    appApi.post(`FormularioPesquisa/Copy/${FormularioId ?? ''}?pesquisaId=${pesquisaId ?? ''}`, form).then(resp => {
      setNovoFormId(resp.data)
    }).catch(err => console.log(err))
  }

  useEffect(() => {
    getFormularios()
  }, [])
  useEffect(() => {
    setIsLoading(false)
    if (NovoFormId !== '') {
      nav(`/Rh/AnaliseDesempenho/Formulario/${pesquisaId ?? ''}`)
    }
  }, [NovoFormId])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Tem certeza que deseja excluir este formulário?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DelteFormulario}
      />

      <ModalCreateForm
        isOpen={isOpen}
        onCreate={CriarFormulario}
        onClose={() => setisOpen(false)}
      />
      <ModalDuplicarForm
        isOpen={ModalDuplicarIsOpen}
        onCreate={(e) => DuplicarEEditarForm(e)}
        onClose={onCloseModalDuplicar}
      />
      <Main>
        <HeadContainer>
          <h1>Selecione um formulário já criado ou elabore um novo</h1>
          <Btns>
            <GreenButton isNav={false} Size='lg' OnClick={() => setisOpen(true)}><FaPlus className='IconLeft' size={13} />Criar novo formulário</GreenButton>
            <GreyButton Size='lg' isNav={true}>Voltar</GreyButton>
          </Btns>
        </HeadContainer>

        <h2>Formulários salvos</h2>
        <section>
          {
            Formularios.filter((e: iFormulario) => e.processoId === null && e.modoPesquisa !== 1)
              .map((e: iFormulario, i) => (
                <ContainerFormulario key={i}>
                  <span>{e.nome}</span>
                  <div>
                    <button onClick={() => SelectFormulario(e.id)}>Selecionar</button>
                    <Button
                      size='lg'
                      VarColor='Azul'
                      height={'29px'}
                      onClick={() => { setModalDuplicarIsOpen(true); setFormularioId(e.id) }}
                    >Duplicar</Button>
                    <button onClick={() => onOpenModalDelete(e.id)}><FaTrashAlt size={11} /></button>
                  </div>
                </ContainerFormulario>
              ))
          }
        </section>
      </Main>
    </Body>
  )
}
