import Modal from 'react-modal'
import { Container, ModalBody, ModalHeader } from './styles'
import { MdClose } from 'react-icons/md'
import { FaCheckCircle, FaPlus } from 'react-icons/fa'
import { GreenButton } from '../../../../../components/Buttons/GreenButton'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { iModalPadrao, iQuestao } from '../../../../../interfaces'
import { useEffect, useState } from 'react'
import { ModalQuestao } from '../ModalQuestao'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import { Flex } from '@chakra-ui/react'

interface IModalProps extends iModalPadrao {
  CompetenciaId: string
}

export const ModalPreVizualizarQuestao: React.FC<IModalProps> = ({ OnCloseModal, isOpen, CompetenciaId }) => {
  if (!isOpen) {
    return null
  }
  const { SelectQuestao, ctxFormulario, ctxCompetencias } = useFormulario()
  const [CriarIsOpen, setCriarIsOpen] = useState(false)

  const [Questoes, setQuestoes] = useState<iQuestao[]>([])

  const Selecionados = ctxFormulario.selecoes.find(e => e.competenciaId === CompetenciaId)

  function getQuestoes(): void {
    const competencia = ctxCompetencias.find(e => e.id === CompetenciaId)
    if (competencia !== undefined) {
      setQuestoes(competencia.questoes)
    }
  }

  useEffect(() => {
    getQuestoes()
  }, [ctxCompetencias])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={OnCloseModal}
      overlayClassName='react-modal-overlay'
      className={'react-modal-content-scroll'}
    >
      <ModalQuestao
        competenciaId={CompetenciaId}
        OnCloseModal={() => setCriarIsOpen(false)}
        duplicar={false}
        isOpen={CriarIsOpen}
      />

      <Container>
        <ModalHeader>
          <div>
            <img src={ctxCompetencias.find(e => e.id === CompetenciaId)?.icone} />
            <h2>{ctxCompetencias.find(e => e.id === CompetenciaId)?.nome}</h2>
          </div>
          <div>
            <MdClose
              onClick={() => OnCloseModal()}
              cursor={'pointer'}
              color={'var(--a2)'}
              size={24}
            />
          </div>
        </ModalHeader>

        <ModalBody>
          {
            Questoes.map((e: iQuestao, i) => {
              if (Selecionados?.questoesId.find(r => r === e.id) !== undefined) {
                return (
                  <div onClick={() => SelectQuestao(CompetenciaId, e.id)} key={i}>
                    <FaCheckCircle color='var(--v1)' size={28} />
                    <span>{e.enunciado}</span>
                  </div>
                )
              }
              return (
                <div onClick={() => SelectQuestao(CompetenciaId, e.id)} key={i}>
                  <FaCheckCircle size={28} />
                  <span>{e.enunciado}</span>
                </div>
              )
            })
          }
        </ModalBody>

        <Flex gap={'1rem'} justifyContent={'end'}>
          <MetaButton bg='vg3' size='md' onClick={() => setCriarIsOpen(true)}><FaPlus className='IconLeft' />Criar questão</MetaButton>
          <GreenButton Size='md' isNav={false} OnClick={() => OnCloseModal()}>Continuar</GreenButton>
        </Flex>
      </Container>
    </Modal>
  )
}
