/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Box, Flex, Grid, GridItem, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { FaChevronRight, FaEdit, FaPlus, FaTrash } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { ModalDelete } from '../../../components/ModalDelete'
import { WhiteBox } from '../../../components/WhiteBox'
import { iDemografia, iPostDemografia } from '../../../interfaces'
import { appApi, SigahApi } from '../../../services/appApi'
import { ObterToken } from '../../../Utils/Helper'
import { Body } from '../../Layouts/Body'
import { TimelineCriarPesquisa } from '../components/TimelineCriarPesquisa'
import { ModalCreateDemografia } from './Components/ModalCreateDemografia'
import { ModalUpdateDemografia } from './Components/ModalUpdateDemografia'
import { Li } from './styles'

export const PesquisaPercepcaoDemografia: React.FC = () => {
  const nav = useNavigate()
  const path = useLocation().pathname
  const toast = useToast()
  const { pesquisaId } = useParams()

  const [ModalAddDemografia, setModalAddDemografia] = useState(false)
  const [ModalUpeDemografia, setModalUpDemografia] = useState(false)
  const [Selecionados, setSelecionados] = useState<string[]>([])
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  const [DemografiaEdit, setDemografiaEdit] = useState<iDemografia>()
  const [DemografiaDelete, setDemografiaDelete] = useState<iDemografia>()
  const [Demografias, setDemografias] = useState<iDemografia[]>([])
  const [MouseIn, setMouseIn] = useState<string>()
  const [DemografiasAdd, setDemografiasAdd] = useState<string[]>([])

  function onAddDemografia(e: iDemografia): void {
    if (DemografiasAdd?.some(a => a === e.id)) {
      setDemografiasAdd(DemografiasAdd?.filter(a => a !== e.id))
    } else {
      setDemografiasAdd([...DemografiasAdd, e.id])
    }
  }

  function getDemografias(): void {
    SigahApi.get('api/Onboard/Demografia', {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    }).then(res => setDemografias(res.data))
      .catch(err => console.log(err))
  }

  function AlterarSelecaoDemografia(demId: string): void {
  }

  function PostDemografia(form: iPostDemografia): void {
    SigahApi.post('api/Onboard/Demografia', form, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => {
        setModalAddDemografia(false)
        getDemografias()
      })
      .catch(err => console.log(err))
  }

  function PutDemografia(form: iDemografia): void {
    SigahApi.put(`api/Onboard/Demografia/${form.id}`, form, {
      headers: {
        authorization: ObterToken()
      }
    })
      .then(() => {
        setModalUpDemografia(false)
        getDemografias()
      })
      .catch(err => console.log(err))
  }

  function onOpenEditDemografia(dem: iDemografia): void {
    setModalUpDemografia(true)
    setDemografiaEdit(dem)
  }

  function onCloseEditDemografia(): void {
    setModalUpDemografia(false)
    setDemografiaEdit(undefined)
  }

  function onOpenDeleteDemografia(dem: iDemografia): void {
    setModalDeleteIsOpen(true)
    setDemografiaDelete(dem)
  }

  function onCloseDeleteDemografia(): void {
    setModalDeleteIsOpen(false)
    setDemografiaDelete(undefined)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
  }

  function DeleteDemografia(Id: string): void {
    SigahApi.delete(`api/Onboard/Demografia/${Id}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    }).then(() => {
      getDemografias()
      toast({
        title: 'Removida com sucesso',
        status: 'success',
        isClosable: false
      })
    }).catch(err => console.log(err))
  }

  function getDemografiasPesquisa(): void {
    appApi.get(`PesquisaIndicativa/${pesquisaId as string}/Demografia`).then((res) => {
      setDemografiasAdd(res.data)
    }).catch((err) => console.log(err))
  }

  function handleContinuar(): void {
    appApi.put(`PesquisaIndicativa/${pesquisaId as string}/Demografia`, DemografiasAdd).then(() => {
      nav(`${path.replace('Demografia', 'Formularios')}`)
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    getDemografias()
    getDemografiasPesquisa()
  }, [])

  return (
		<Body>
			{
        (ModalAddDemografia) && (
          <ModalCreateDemografia
            isOpen={ModalAddDemografia}
            onClose={() => setModalAddDemografia(false)}
            onCreate={PostDemografia}
          />
        )
      }

			<ModalDelete
            isOpen={ModalDeleteIsOpen}
            message='Tem certeza que deseja excluir esta demografia?'
            onConfirm={onCloseModalDelete}
            onRequestClose={onCloseDeleteDemografia}
            Demografia={DemografiaDelete}
            onDelete={DeleteDemografia}
          />

      <ModalUpdateDemografia
        isOpen={ModalUpeDemografia}
        Demografia={DemografiaEdit}
        isSelecionado={Selecionados.find(r => r === DemografiaEdit?.id) !== undefined}
        onClose={onCloseEditDemografia}
        onUpdate={PutDemografia}
        onAlterSelecao={AlterarSelecaoDemografia}
      />
			<TimelineCriarPesquisa color={['var(--Green2)', 'var(--Alaranjado)']}/>
			<WhiteBox styles={{ marginTop: '2rem' }}>
				<Flex justifyContent={'space-between'} my={'1rem'}>
					<h2>Adicionar demografia do avaliado</h2>
					<Button VarColor='Azul' w={'13.7rem'} onClick={() => setModalAddDemografia(true)}>Adicionar demografia</Button>
				</Flex>
				<Text mb={'1rem'}>
					Identificação utilizada para dividir a população em grupos de afinidade, exemplos: escolaridade, tempo de empresa. Esses grupos nos ajudam a entender comportamentos específicos para ações de desenvolvimento.
				</Text>
				<Box px={'4rem'} py={'3.25rem'} borderRadius={'1rem'} border={'1px solid var(--secundario4)'}>
					<Grid templateColumns={'repeat(4, 1fr)'} gap={'2rem'}>
					{Demografias.filter(e => e.nome !== 'Área').map(e => {
					  return (
							<GridItem justifyContent={'space-between'} minH={'16rem'} color={'#2C4D8A'} key={e.id} borderRadius={'1rem'} border={'1px solid var(--Gray5)'} p={'.75rem'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
								<Grid templateColumns={'1fr auto 1fr'} mb={'1rem'} gap={'.5rem'}>
									<span></span>
									<Text fontWeight={'700'} >{e.nome}</Text>
									<Menu>
										<MenuButton
											transition='all 0.2s'
											borderRadius='md'
											borderWidth='1px'
											bg='none'
											mt={'.2rem'}
											fontSize={'20px'}
											fontWeight={'700'}
											color={'var(--Azul)'}
											_hover={{ bg: 'none' }}
											_focus={{ boxShadow: 'none' }}
										>
											<BiDotsHorizontalRounded />
										</MenuButton>
										<MenuList>
											<MenuItem gap={'.5rem'} onClick={() => onOpenEditDemografia(e)}><FaEdit /> Editar</MenuItem>
											<MenuItem gap={'.5rem'} onClick={() => onOpenDeleteDemografia(e)} color={'var(--Red)'}><FaTrash /> Excluir</MenuItem>
										</MenuList>
									</Menu>
								</Grid>
								<Box flex={'1'}>
									{e.grupos.map(grupo => {
									  return (
											<Li key={grupo.id}><span>{grupo.nome}</span></Li>
									  )
									})}
								</Box>
								{e.obtidoAutomaticamente && (
									<Box mt={'1rem'} color={'var(--Red)'} fontSize={'14px'} mb={'1.5rem'} textAlign={'center'}>Obtido automaticamente para usuários do sistema</Box>
								)}
								{!e.obtidoAutomaticamente && DemografiasAdd?.some(a => a === e.id) && (
									<Button mt={'1rem'} bgColor={'#fff'} w={'9.2rem'} color={MouseIn === e.id ? 'var(--Alaranjado)' : 'var(--Green3)' } border={MouseIn === e.id ? '1px solid var(--Alaranjado)' : '1px solid var(--v1)' } boxShadow={MouseIn ? '0 0px 10px var(--Alaranjado25)' : '0 0px 8px var(--v125)' } onMouseEnter={() => setMouseIn(e.id)} onMouseLeave={() => setMouseIn(undefined)} onClick={() => onAddDemografia(e)}>
										{MouseIn !== e.id ? 'Adicionado' : 'Remover'}
									</Button>
								)}
								{!e.obtidoAutomaticamente && !DemografiasAdd?.some(a => a === e.id) && (
									<Button mt={'1rem'} VarColor='Green3' leftIcon={<FaPlus/>} w={'9.2rem'} onClick={() => onAddDemografia(e)}>
										Adicionar
									</Button>
								)}
							</GridItem>
					  )
					})}
					</Grid>
				</Box>
			</WhiteBox>
			<Flex justifyContent={'end'} mt={'1.5rem'} gap={'1rem'}>
						<Button minH={'3rem'} VarColor='Gray3' onClick={() => nav(-1)}>
							Voltar
						</Button>
						<Button
							h={'3rem'}
							onClick={() => handleContinuar()}
							size={'lg'}
							VarColor={'v2'}
							rightIcon={<FaChevronRight />}
						>Continuar</Button>
			</Flex>
		</Body>
  )
}
