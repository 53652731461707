import { useEffect, useState } from 'react'
import { Container, FlexIcon } from './styles'
import { FaClipboardList, FaCog, FaComment, FaMapMarkerAlt, FaUserAlt } from 'react-icons/fa'
import { appApi } from '../../../../../../../services/appApi'
import { Button } from '../../../../../../../components/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { iEtapaColaborador } from '../../../../../../../interfaces'
import { PropInvalida } from '../../../../../../../Utils/Helper'
interface iCardReuniao {
  etapa: iEtapaColaborador
  isOpen: boolean
}

interface iReuniao {
  tipoReuniao: number
  participanteId?: string
  assunto: string
  descricao: string
  dataInicio?: string
  duracao?: number
  presencial: boolean
  local?: string
  feedback?: string
}

export const CardReuniaoOne: React.FC<iCardReuniao> = ({ etapa, isOpen }) => {
  const nav = useNavigate()
  const { colaboradorId } = useParams<{ colaboradorId: string }>()

  const [Model, setModel] = useState<iReuniao>()

  function ObterEtapa(): void {
    appApi.get(`ReuniaoOneOld/${etapa.interacoes[0].idExterno ?? ''}`)
      .then(res => {
        setModel(res.data)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    if (isOpen && PropInvalida(Model)) {
      ObterEtapa()
    }
  }, [isOpen])

  return (
    <Container>
      <FlexIcon>
        <FaUserAlt size={30} />
        <div>
          <h3>Tipo de reunião</h3>
          <strong>{Model?.tipoReuniao === 0 ? 'Feedback com o gestor' : 'Feedback com o RH'}</strong>
        </div>
      </FlexIcon>

      <FlexIcon>
        <FaMapMarkerAlt size={30} />
        <div>
          <h3>Local da reunião</h3>
          <strong>{Model?.local}</strong>
        </div>
      </FlexIcon>

      {
        (Model?.descricao !== null) && (
          <FlexIcon>
            <FaClipboardList size={30} />
            <div>
              <h3>Descrição</h3>
              <small dangerouslySetInnerHTML={{ __html: Model?.descricao ?? '' }}></small>
            </div>
          </FlexIcon>
        )
      }
      {
        (!PropInvalida(Model?.feedback)) && (
          <FlexIcon>
            <FaComment size={30} />
            <div>
              <h3>Comentário</h3>
              <small>{Model?.feedback}</small>
            </div>
          </FlexIcon>
        )
      }

      {
        (etapa.status !== 2) && (
          <Button VarColor='Azul' leftIcon={<FaCog />}
            onClick={() => nav(`/Processo/${colaboradorId ?? ''}/ReuniaoOne/${etapa.id}/Agendar`)}
          >Configurar envio</Button>
        )
      }
    </Container>
  )
}
