import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;
  border-radius: 0.25rem;
  background: var(--c1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    display: flex;

    svg {
      font-size: 3rem;
      color: var(--c4);
    }

    div {
      padding: 0 1rem;
      text-align: center;

      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      justify-content: center;

      strong {
        font-size: 1.25rem;
        font-weight: normal;
        color: var(--a1);
      }

      span {
        color: var(--c4);

        margin-top: 0.5rem;
      }
    }
  }

  div {

    div {
      button {
        background: transparent;
        border: 0;
        outline: 0;
  
        font-size: 1.5rem;
        color: var(--c4);
  
        & + button {
          color: var(--terc3);
  
          margin-left: 0.5rem;
        }
        :first-child {
          margin-top: 0.2rem;
        }
      }
    }
  }
`
