import styled from 'styled-components'

export const EixoUnicoNineBox = styled.div`
  border-radius: 16px;
  background:${props => props.theme};
  width: 73rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`

export const EixoNineBox = styled.div`
  width: 16rem;
  height: 12rem;
  border-radius: 1rem;
  gap: .25rem;
  display: flex;
  background:${props => props.theme};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  input{
    color: white;
    width: 10rem;
  }
`
