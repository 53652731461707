/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { iCompetenciaQuestao } from '../..'
import { Button } from '../../../../../components/Button'
import { ModalBase } from '../../../../../components/ModalBase'
import { iEnunciado, iSelecao } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Legenda } from '../../../../Jornada/ReuniaoOne/Configuracao/styles'
import { ModalBody } from './styles'

interface iProps {
  isOpen: boolean
  onClose: () => void
  titulo: string
  icones: string[]
  competencia?: iCompetenciaQuestao
  onCreate: (e: boolean) => void
}
interface iForm {
  nome: string
  icone: string
}

type iLocationProps = {
  formularioId: string

}

export const ModalCompetencia: React.FC<iProps> = ({ isOpen, onClose, titulo, icones, competencia, onCreate }) => {
  const [ImgSel, setImgSel] = useState(0)
  const [Nome, setNome] = useState(competencia?.nome)
  const [Questao, setQuestao] = useState<string>(competencia?.questao.enunciado ?? '')
  const toast = useToast()
  const { formularioId } = useParams<iLocationProps>()

  function CriarCompetencia({ nome, icone }: iForm): void {
    const questoes: iEnunciado[] = [{
      enunciado: ''
    }]

    questoes[0].enunciado = Questao

    const form = {
      nome,
      icone,
      questoes,
      sistema: false
    }

    appApi.post('Competencia', form)
      .then(res => {
        toast({
          title: 'Competência criada e selecionada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        onClose()
        onCreate(true)
        SelecionarQuestao(res?.data.id ?? '', res?.data.questao.id ?? '')
      })
      .catch((e) => {
        toast({
          title: 'Há campos sem preenchimento',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })
      })
  }

  function AtualizarCompetencia({ nome, icone }: iForm): void {
    if (competencia?.sistema && competencia.nome !== Nome) {
      CriarCompetencia({ nome, icone })
      return
    }

    const questoes: iEnunciado[] = [{
      enunciado: ''
    }]

    questoes[0].enunciado = Questao

    const form = {
      nome,
      icone,
      questoes,
      sistema: competencia?.sistema
    }

    appApi.put(`Competencia/${competencia?.id ?? ''}`, form)
      .then(res => {
        toast({
          title: 'Competência atualizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        onClose()
        onCreate(true)
        SelecionarQuestao(res?.data.id ?? '', res?.data.questao.id ?? '')
      })
      .catch((e) => {
        toast({
          title: 'Ocorreu um erro',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })
      })
  }

  function SelecionarQuestao(CompetenciaId: string, QuestaoId: string): void {
    const form: iSelecao = {
      competenciaId: CompetenciaId,
      questoesId: [
        QuestaoId
      ]
    }
    appApi.put(`Formulario/${formularioId as string}/competencia`, form)
      .catch(err => console.log(err))
  }

  useEffect(() => {
    setNome(competencia?.nome)
    setQuestao(competencia?.questao.enunciado ?? '')
    setImgSel(icones.findIndex(e => e === competencia?.icone.replace('/assets', '').replace('Icones-SISTEMA', 'Competencias').replace('.png', '.svg')))
  }, [competencia])

  return (
    <ModalBase isOpen={isOpen} onClose={onClose} Titulo={titulo} Width='55rem'>
      <hr style={{ border: '1px solid var(--Gray5)' }} />
      <ModalBody>
        <h3 style={{ color: 'var(--a3)', marginBottom: '.5rem' }}>Selecione um ícone</h3>

          <section style={{ opacity: competencia?.sistema && competencia.nome === Nome ? '0.5' : '1', pointerEvents: competencia?.sistema && competencia.nome === Nome ? 'none' : 'auto' }} >
            {
              icones.map((e: string, i) => {
                if (i === ImgSel) {
                  return (
                    <img style={{ border: '0.25rem solid var(--v1)' }} key={i} src={e} />
                  )
                } else {
                  return (
                    <img onClick={() => setImgSel(i)} key={i} src={e} />
                  )
                }
              })
            }
          </section>

        <FormControl my={'1rem'}>
          <FormLabel>Nome da competência</FormLabel>
          <Input
            value={Nome}
            onChange={(e) => setNome(e.target.value)}
            placeholder={'Digite o nome'}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Questão da competência</FormLabel>
          <Input
            value={Questao}
            onChange={(e) => setQuestao(e.target.value)}
            placeholder={'Digite a questão'}
          />
          <Legenda>Exemplo: Demonstra comprometimento e dedicação com os objetivos e os resultados da empresa</Legenda>
        </FormControl>

        <Flex gap={'1rem'} justifyContent={'end'} mt={'1rem'}>
          <Button VarColor={'Gray2'} w={'8rem'} onClick={onClose}>Cancelar</Button>
          <Button VarColor={'Azul'} rightIcon={<FaChevronRight />} w={'14.25rem'}
            onClick={() => {
              titulo === 'Editar competência'
                ? AtualizarCompetencia({
                  nome: Nome ?? '',
                  icone: icones[ImgSel]
                })
                : CriarCompetencia({
                  nome: Nome ?? '',
                  icone: icones[ImgSel]
                })
            }}>{titulo}</Button>

        </Flex>

      </ModalBody>
    </ModalBase>
  )
}
