/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Checkbox, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleLeft, FaAngleRight, FaEye, FaSave } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iConfigPesquisaInterna } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { ButtonTag } from '../../../PesquisaPercepcao/EnviarPesquisa/Components/ButtonTag'
import { ModalTag } from './Components/ModalTag/Index'
import { OutlineContainer } from './styles'

type iLocationProps = {
  pesquisaId: string
  trilhaId: string
  processoId: string
}

interface iPutConfiguracao {
  dataInicio: string
  periodoCiclo: number
  validadeDias: number
  enviarFimDeSemana: boolean
  textoAbertura: string
  textoEmail: string
  tipoEncerramentoPesquisa: number
  linkRedirect?: string
  dataFim?: string
  textoEncerramento?: string
}

interface iPutEtapaPesquisa extends Pick<iPutConfiguracao, 'textoAbertura' | 'linkRedirect' | 'tipoEncerramentoPesquisa' | 'textoEncerramento'> { }

export const ConfiguracaoPesquisaInterna: React.FC = () => {
  const textoPadraoEmail = '<p>Olá, </p><p>Você foi convidado para participar da pesquisa que abre [DATA INICIO].</p><p>A pesquisa é simples e rápida.</p><b> O prazo para preenchimento é até o dia [DATA FIM]</b><p>[LINK]</p><p>Qualquer problema no preenchimento da pesquisa, favor contatar: [EMAIL DO RESPONSÁVEL]</p><p>A sua participação é fundamental para o sucesso dessa iniciativa.</p><p>Contamos com você!</p>'
  const nav = useNavigate()
  const param = useParams<iLocationProps>()
  const { pathname } = useLocation()
  const toast = useToast()

  const [IsLoading, setIsLoading] = useState(true)
  const [ModalTagIsOpen, setModalTagIsOpen] = useState(false)

  const [Model, setModel] = useState<iConfigPesquisaInterna>({
    dataFim: '',
    dataInicio: '',
    hInicio: '',
    periodoCiclo: 0,
    textoAbertura: '',
    textoEmail: textoPadraoEmail,
    validadeDias: 0,
    textoEncerramento: 'Obrigada pela participação!',
    enviarFimDeSemana: false,
    tipoEncerramentoPesquisa: 0
  })

  const [NaoDefinirDataEncerramento, setNaoDefinirDataEncerramento] = useState(false)

  function getConfiguracao(): void {
    appApi.get(`PesquisaInterna/${param.pesquisaId as string}/Configuracao`)
      .then(res => {
        setModel(res.data)
        if (!res.data.textoEmail) {
          setModel({ ...res.data, textoEmail: textoPadraoEmail })
        }
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function UpdateConfiguracao(): void {
    UpdateConfiguracaoTextoEmail(false)
    const form: iPutConfiguracao = {
      enviarFimDeSemana: Model.enviarFimDeSemana,
      periodoCiclo: Model.periodoCiclo,
      validadeDias: Model.validadeDias,
      dataInicio: `${Model.dataInicio}T${Model.hInicio}`,
      textoAbertura: Model.textoAbertura,
      textoEmail: Model.textoEmail ? Model.textoEmail : textoPadraoEmail,
      tipoEncerramentoPesquisa: Model.tipoEncerramentoPesquisa,
      linkRedirect: Model.linkRedirect,
      textoEncerramento: Model.textoEncerramento,
      dataFim: !NaoDefinirDataEncerramento ? `${Model.dataFim}T${Model.hFim}` : undefined
    }

    setTimeout(() => {
      appApi.put(`PesquisaInterna/${param.pesquisaId}/Configuracao`, form)
        .then(() => {
          nav(`/Jornada/PesquisaInterna/Convites/${param.pesquisaId}`)
        })
        .catch(err => {
          console.log(err)
          toast({
            title: 'Há campos sem preenchimento',
            status: 'error',
            isClosable: false,
            position: 'top-right'
          })
        })
    }, 1000)
  }

  function UpdateConfiguracaoEncerramento(): void {
    const form: iPutConfiguracao = {
      enviarFimDeSemana: Model.enviarFimDeSemana,
      periodoCiclo: Model.periodoCiclo,
      validadeDias: Model.validadeDias,
      dataInicio: `${Model.dataInicio}T${Model.hInicio}`,
      textoAbertura: Model.textoAbertura,
      textoEmail: Model.textoEmail,
      tipoEncerramentoPesquisa: Model.tipoEncerramentoPesquisa,
      linkRedirect: Model.linkRedirect,
      textoEncerramento: Model.textoEncerramento
    }

    appApi.put(`PesquisaInterna/${param.pesquisaId}/Configuracao/Encerramento`, form)
      .then(() => toast({
        title: 'Encerramento atualizado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      }))
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoTextoAbertura(): void {
    appApi.put(`PesquisaInterna/${param.pesquisaId}/Configuracao/TextoAbertura`, { texto: Model.textoAbertura })
      .then(() => toast({
        title: 'Texto de abertura atualizado com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right'
      }))
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoTextoEmail(toastShow: boolean): void {
    appApi.put(`PesquisaInterna/${param.pesquisaId}/Configuracao/TextoEmail`, { texto: Model.textoEmail })
      .then(() => {
        if (toastShow) {
          toast({
            title: 'Texto de email atualizado com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
      }
      )
      .catch(err => console.log(err))
  }

  function UpdateConfiguracaoPesquisaEtapa(): void {
    const form: iPutEtapaPesquisa = {
      textoAbertura: Model.textoAbertura,
      linkRedirect: Model.linkRedirect,
      tipoEncerramentoPesquisa: Model.tipoEncerramentoPesquisa,
      textoEncerramento: Model.textoEncerramento
    }

    appApi.put(`PesquisaInternaEtapa/${param.pesquisaId as string}/Configuracao`, form)
      .then(() => {
        if (pathname.includes('Trilha')) {
          nav(`/Jornada/Configuracao/Trilha/${param.trilhaId}`)
        } else {
          nav(`/Rh/VisualizarPerfil/${param.processoId}`)
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getConfiguracao()
  }, [])

  useEffect(() => {
    if (Model.dataFim === undefined || Model.dataFim === null) {
      setNaoDefinirDataEncerramento(true)
    }
  }, [Model.dataFim])

  return (
        <Body>
            <ModalTag
                onClose={() => setModalTagIsOpen(false)}
                isOpen={ModalTagIsOpen}
            />
            <WhiteBox>
                <h1>Configure a sua pesquisa</h1>
                <Flex margin={'1rem 0 2rem 0'} flexDirection={'column'} gap={'1rem 0'}>
                    {
                        (!pathname.includes('Trilha') && !pathname.includes('Processo')) && (
                            <OutlineContainer>
                                <h3>Período</h3>
                                <Flex flexDir={'column'}>
                                    <Flex width='100%' alignItems={'end'}>
                                        <Flex flexDir={'column'} width={'50%'}>
                                            <FormControl>
                                                <FormLabel fontSize={'xl'}>Início da pesquisa</FormLabel>
                                                <Flex>
                                                    <Input
                                                        min={new Date().toISOString().split('T')[0]}
                                                        value={Model?.dataInicio ?? ''}
                                                        onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                                                        type={'date'}
                                                        marginRight={'.5rem'}
                                                        borderColor={'var(--Gray4)'}
                                                    />
                                                    <Input
                                                        value={Model.hInicio ?? ''}
                                                        onChange={(e) => setModel({ ...Model, hInicio: e.target.value })}
                                                        type={'time'} borderColor={'var(--Gray4)'}
                                                    />
                                                </Flex>
                                            </FormControl>

                                            <FormControl marginTop={'1rem'}>
                                                <FormLabel fontSize={'xl'}>Fim da pesquisa</FormLabel>
                                                <Flex>
                                                    <Input
                                                        isDisabled={NaoDefinirDataEncerramento}
                                                        min={new Date().toISOString().split('T')[0]}
                                                        value={Model.dataFim ?? ''}
                                                        onChange={(e) => setModel({ ...Model, dataFim: e.target.value })}
                                                        type={'date'}
                                                        marginRight={'.5rem'}
                                                        borderColor={'var(--Gray4)'}
                                                    />
                                                    <Input
                                                        isDisabled={NaoDefinirDataEncerramento}
                                                        value={Model.hFim ?? ''}
                                                        onChange={(e) => setModel({ ...Model, hFim: e.target.value })}
                                                        type={'time'}
                                                        borderColor={'var(--Gray4)'}
                                                    />
                                                </Flex>
                                            </FormControl>
                                        </Flex>
                                        <Checkbox
                                            isChecked={NaoDefinirDataEncerramento}
                                            onChange={(e) => setNaoDefinirDataEncerramento(e.target.checked)}
                                            borderColor={'var(--Gray4)'}
                                            marginLeft={'1rem'} size={'lg'}
                                        >Não definir data de encerramento</Checkbox>
                                    </Flex>

                                    <Flex marginTop={'2rem'} gap={'1rem 0'} width={'40%'} flexDirection={'column'}>
                                        <FormControl>
                                            <FormLabel>Ciclo pulso</FormLabel>
                                            <Select value={Model.periodoCiclo}
                                                onChange={(e) => setModel({ ...Model, periodoCiclo: parseInt(e.target.value) })}
                                                borderColor={'var(--Gray4)'}
                                            >
                                                <option value={'0'}>Único</option>
                                                <option value={'2'}>Mensal</option>
                                                <option value={'3'}>Bimestral</option>
                                                <option value={'4'}>Trimestral</option>
                                                <option value={'5'}>Semestral</option>
                                                <option value={'6'}>Anual</option>
                                            </Select>
                                        </FormControl>

                                        <FormControl isDisabled={Model.periodoCiclo === 0}>
                                            <FormLabel>Validade da pesquisa</FormLabel>
                                            <Input
                                                type={'number'}
                                                borderColor={'var(--Gray4)'}
                                                placeholder={'7'}
                                                value={Model.validadeDias}
                                                onChange={(e) => setModel({ ...Model, validadeDias: parseInt(e.target.value) })}
                                            />
                                        </FormControl>

                                        <Checkbox
                                            isChecked={Model.enviarFimDeSemana}
                                            onChange={(e) => setModel({ ...Model, enviarFimDeSemana: e.target.checked })}
                                            borderColor={'var(--Gray4)'}
                                            size={'lg'}
                                        >Enviar durante o fim de semana</Checkbox>
                                    </Flex>

                                </Flex>
                            </OutlineContainer>
                        )
                    }
                    <OutlineContainer>
                        <Flex flexDir={'column'}>
                            <Flex justifyContent={'space-between'}>
                                <Flex flexDir={'column'}>
                                    <h3>Texto do convite para preenchimento da pesquisa</h3>
                                    <Text
                                        fontWeight={'500'}
                                        fontSize='0.875rem'
                                        style={{ color: 'var(--a4)' }}
                                    >Esse texto será apresentado como e-mail ou notificação antes do respondente abrir a pesquisa.</Text>
                                </Flex>
                                <ButtonTag onClick={() => setModalTagIsOpen(true)}>Saiba como utilizar as TAGs</ButtonTag>
                            </Flex>
                                <EditorTexto isLoading={IsLoading} initialValue={Model.textoEmail ?? ''}
                                    onChange={(e: string) => setModel({ ...Model, textoEmail: e })}
                                />
                        </Flex>
                        <Flex marginTop={'1rem'} justifyContent={'end'} gap='1rem'>
                            <Button
                                VarColor={'c6'}
                                onClick={() => {
                                  setIsLoading(true)
                                  setModel((prevState) => ({
                                    ...prevState,
                                    textoEmail: textoPadraoEmail
                                  }))
                                  setTimeout(() => setIsLoading(false), 1000)
                                }}
                                >Restaurar texto padrão
                            </Button>
                            <Button
                                VarColor='Green3'
                                leftIcon={<FaSave />}
                                onClick={() => UpdateConfiguracaoTextoEmail(true)}
                            >Salvar</Button>
                        </Flex>
                    </OutlineContainer>
                    <OutlineContainer>
                        <Flex flexDir={'column'}>
                            <h3>Texto de abertura da pesquisa</h3>
                            <Text
                                fontWeight={'500'}
                                fontSize='0.875rem'
                                style={{ color: 'var(--a4)' }}
                            >O respondente irá visualizar esse texto antes de começar a preencher a pesquisa, para visualizar exatamente como irá ficar, você pode pré-visualizar clicando no botão abaixo da caixa de edição de texto.</Text>
                            <EditorTexto isLoading={IsLoading} initialValue={Model.textoAbertura ?? ''}
                                onChange={(e: string) => setModel({ ...Model, textoAbertura: e })}
                            />

                        </Flex>
                        <Flex marginTop={'1rem'} justifyContent={'end'}>
                            <Button
                                onClick={() => nav(`/Jornada/PesquisaInterna/Abertura/${param.pesquisaId as string}/Previsualizar`)}
                                marginRight={'1rem'}
                                VarColor='Rosa' leftIcon={<FaEye />}
                            >Pré-visualização do início da pesquisa</Button>
                            <Button
                                VarColor='Green3'
                                leftIcon={<FaSave />}
                                onClick={UpdateConfiguracaoTextoAbertura}
                            >Salvar</Button>
                        </Flex>
                    </OutlineContainer>
                    <OutlineContainer>
                        <Flex flexDir={'column'}>
                            <h3>Opções após encerrar pesquisa</h3>
                            <Text
                                fontWeight={'500'}
                                fontSize={'0.875rem'}
                                style={{ color: 'var(--a4)', fontSize: '14px' }}
                            >Escolha uma opção abaixo para o encerramento da pesquisa</Text>
                            <RadioGroup onChange={(e) => setModel({ ...Model, tipoEncerramentoPesquisa: parseInt(e) })} value={Model.tipoEncerramentoPesquisa.toString()} margin={'1rem'}>
                                <Stack color={'var(--a4)'} direction='column'>
                                    <Radio borderColor={'var(--Gray4)'} size={'lg'} value='0'>Redirecione o usuário para o site do sistema.</Radio>
                                    <Radio borderColor={'var(--Gray4)'} size={'lg'} value='1'>Redirecione o usuário para o site de sua empresa.</Radio>
                                    <Radio borderColor={'var(--Gray4)'} size={'lg'} value='2'>Exiba uma mensagem.</Radio>
                                </Stack>
                            </RadioGroup>
                            {
                                (Model.tipoEncerramentoPesquisa === 1) && (
                                    <FormControl>
                                        <FormLabel fontSize={'xl'}>Escreva o endereço do site:</FormLabel>
                                        <Input
                                            placeholder={'www.meusite.com.br'}
                                            borderColor={'var(--Gray4)'}
                                            onChange={(e) => setModel({ ...Model, linkRedirect: e.target.value })}
                                            value={Model.linkRedirect}
                                        />
                                    </FormControl>
                                )
                            }

                            {
                                (Model.tipoEncerramentoPesquisa === 2) && (
                                    <EditorTexto isLoading={IsLoading} initialValue={Model.textoEncerramento ?? ''}
                                        onChange={(e: string) => setModel({ ...Model, textoEncerramento: e })}
                                    />
                                )
                            }
                        </Flex>
                        <Flex marginTop={'1rem'} justifyContent={'end'}>
                            <Button VarColor='Green3' leftIcon={<FaSave />} onClick={UpdateConfiguracaoEncerramento}>Salvar</Button>
                        </Flex>
                    </OutlineContainer>
                </Flex>
                <Flex justifyContent={'end'}>
                    <Button
                        onClick={() => nav(-1)}
                        leftIcon={<FaAngleLeft />}
                        VarColor='c5'
                        size={'lg'}
                    >Voltar</Button>
                    {
                        (pathname.includes('Trilha') || pathname.includes('Processo')) && (
                            <Button
                                marginLeft={'1rem'}
                                VarColor='Green2'
                                size={'lg'}
                                onClick={UpdateConfiguracaoPesquisaEtapa}
                            >Salvar</Button>
                        )
                    }

                    {
                        (!pathname.includes('Trilha') && !pathname.includes('Processo')) && (
                            <Button
                                marginLeft={'1rem'}
                                rightIcon={<FaAngleRight />}
                                VarColor='Green2'
                                size={'lg'}
                                onClick={UpdateConfiguracao}
                            >Salvar e ir para o próximo</Button>
                        )
                    }
                </Flex>
            </WhiteBox>
        </Body>
  )
}
