import { Avatar, Flex, Image } from '@chakra-ui/react'
import { useEffect } from 'react'
import { iColaboradorReuniao } from '../../../../../../interfaces'

interface iCardProps{
  colaborador: iColaboradorReuniao
}

export const CardInformacao: React.FC<iCardProps> = ({ colaborador }) => {
  useEffect(() => {
    console.log(colaborador)
  }, [])
  return (
        <Flex width={'fit-content'} alignItems={'center'} padding={'.5rem'} borderRadius={'1rem'} bg='var(--c2)' flexDir={'column'}>
            <h4>Informações sobre o colaborador que está em onboarding</h4>
            <Flex gap='1rem' alignItems={'center'}>
                <Flex>
                    <Avatar marginRight={'.5rem'} src={colaborador.avatar} size={'md'} name='t'/>
                    <Flex flexDir={'column'}>
                        <h5>{colaborador.nome}</h5>
                        <span>{colaborador.nomeDepartamento}</span>
                    </Flex>
                </Flex>
                {(colaborador.urlSelf) && (
                  <Flex flexDir={'column'}>
                    <span>Perfil</span>
                    <Image height={'3rem'} src={colaborador.urlSelf}/>
                  </Flex>
                )}
                <Flex justifyContent={'center'} alignItems={'center'}>
                    <h5>{colaborador.diasDeOnboarding} dias de onboarding</h5>
                </Flex>
            </Flex>
        </Flex>
  )
}
