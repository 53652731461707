/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { Flex, Switch, Tooltip } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { FaArrowCircleDown, FaArrowCircleRight, FaCheckCircle, FaCopy, FaEdit, FaPlus, FaTrash } from 'react-icons/fa'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import { iCompetencia, iQuestao } from '../../../../../interfaces'
import { CollapseText, ColumnIcons, CompetenciaContent, CompetenciaLine, CompetenciaMenu, Container, ImgText, SectionQuestoes, StyledQuestion } from './styles'

interface CompetenciaProps {
  competencia: iCompetencia
  onModalQuestao: (competenciaId: string, questaoId?: string, duplicar?: boolean) => void
  onOpenModalDelete: (competenciaId: string, questaoId: string) => void
  Edit: () => void
}

export const Competencia: React.FC<CompetenciaProps> = ({ onModalQuestao, onOpenModalDelete, Edit, competencia }) => {
  const { ctxFormulario, SelectCompetencia, SelectQuestao, SetQuestaoObrigatoria } = useFormulario()
  const [isOpen, setisOpen] = useState(false)
  const selecionadas = ctxFormulario.selecoes.find(e => e.competenciaId === competencia.id)
  useEffect(() => {
    setisOpen(competencia.questoes.filter((e: iQuestao) => {
      if (selecionadas?.questoesId.find(r => r === e.id) !== undefined) {
        return e
      }
      return null
    }).length === 0)
  }, [])
  return (
    <Container>
      <CompetenciaMenu>
        <ImgText>
          <img src={competencia.icone} />
          <span>{competencia.nome}</span>
        </ImgText>
        <ColumnIcons>
          {
            (!competencia.doSistema) && (
              <span onClick={() => {
                Edit()
              }} style={{ color: '#7B61FF' }}><FaEdit style={{ marginRight: '.5rem' }}
                />Editar</span>
            )
          }
          {
            (!competencia.discursiva) && (
              <span onClick={() => SelectCompetencia(competencia.id)} style={{ color: '#D12828' }}>
                <FaTrash style={{ marginRight: '.5rem', marginTop: '.2rem' }} />Remover</span>
            )
          }
        </ColumnIcons>
      </CompetenciaMenu>
      <Flex flexDirection={'column'} width='100%' justifyContent={'space-between'}>

        <CompetenciaContent>
          {
            competencia.questoes.filter((e: iQuestao) => {
              if (selecionadas?.questoesId.find(r => r === e.id) !== undefined) {
                return e
              }
              return null
            }).map((e: iQuestao, i) => {
              if (e.tipo !== 1) {
                return (
                  <CompetenciaLine key={i}>
                    <StyledQuestion onClick={() => SelectQuestao(competencia.id, e.id)}>
                      <FaCheckCircle size={25} color='var(--v2)' />
                      <span>{e.enunciado}</span>
                    </StyledQuestion>
                    <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'} >
                    <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Azul)'} label={'Duplicar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                      <FaCopy color='var(--Azul)' onClick={() => onModalQuestao(competencia.id, e.id, true) }/>
                      </div>
                      </Tooltip>
                      {
                        (!e.doSistema) && (
                          <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Roxo)'} label={'Editar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                            <FaEdit color='var(--Roxo)' onClick={() => onModalQuestao(competencia.id, e.id)} />
                            </div>
                        </Tooltip>
                        <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'}>

                        <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--terc3)'} label={'Excluir questão'} hasArrow arrowSize={15}
                      >
                        <div>
                            <FaTrash color='var(--terc3)' onClick={() => onOpenModalDelete(competencia.id, e.id)} />
                            </div>
                            </Tooltip>
                            </Flex>
                          </Flex>

                        )
                      }
                    </Flex>
                  </CompetenciaLine>
                )
              } else {
                return (
                  <CompetenciaLine key={i}>
                    <StyledQuestion onClick={() => SelectQuestao(competencia.id, e.id)}>
                      <FaCheckCircle size={25} color='var(--v2)' />
                      <span>{e.enunciado}</span>
                    </StyledQuestion>

                    <Flex margin={'0rem 1rem'}>
                      <Switch isChecked={ctxFormulario.obrigatorias?.find(q => q === e.id) !== undefined} onChange={() => SetQuestaoObrigatoria(e.id)} colorScheme={'red'} marginRight={'0.5'} />
                      <span>Obrigatória</span>
                    </Flex>

                    <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Azul)'} label={'Duplicar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                      <FaCopy color='var(--Azul)' onClick={() => onModalQuestao(competencia.id, e.id, true) }/>
                      </div>
                      </Tooltip>
                      {
                        (!e.doSistema) && (
                          <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Roxo)'} label={'Editar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                            <FaEdit color='var(--Roxo)' onClick={() => onModalQuestao(competencia.id, e.id)} />
                            </div>
                            </Tooltip>
                            <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--terc3)'} label={'Excluir questão'} hasArrow arrowSize={15}
                      >
                        <div>
                            <FaTrash color='var(--terc3)' onClick={() => onOpenModalDelete(competencia.id, e.id)} />
                            </div>
                            </Tooltip>
                          </Flex>
                          </Flex>
                        )
                      }
                    </Flex>
                  </CompetenciaLine>
                )
              }
            })
          }

          {
            (competencia.questoes.filter((e: iQuestao) => {
              if (selecionadas?.questoesId.find(r => r === e.id) !== undefined) {
                return e
              }
              return null
            }).length < competencia.questoes.length) && (
              <CollapseText onClick={() => { setisOpen(!isOpen) }}>
                {
                  !isOpen
                    ? <FaArrowCircleDown />
                    : <FaArrowCircleRight />
                }
                <span>Clique aqui para recolher as questões não selecionadas</span>
              </CollapseText>
            )
          }

          <SectionQuestoes active={isOpen}>
            {
              competencia.questoes.filter((e: iQuestao) => {
                if (selecionadas?.questoesId.find(r => r === e.id) === undefined) {
                  return e
                }
                return null
              }).map((e: iQuestao, i) => {
                return (
                  <CompetenciaLine key={i}>
                    <StyledQuestion onClick={() => SelectQuestao(competencia.id, e.id)}>
                      <FaCheckCircle size={25} color='var(--c6)' />
                      <span>{e.enunciado}</span>
                    </StyledQuestion>
                    <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Azul)'} label={'Duplicar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                      <FaCopy color='var(--Azul)' onClick={() => onModalQuestao(competencia.id, e.id, true) }/>
                      </div>
                      </Tooltip>
                      {
                        (!e.doSistema) && (
                          <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'}>
                    <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--Roxo)'} label={'Editar questão'} hasArrow arrowSize={15}
                      >
                        <div>
                            <FaEdit color='var(--Roxo)' onClick={() => onModalQuestao(competencia.id, e.id)} />
                            </div>
                            </Tooltip>
                            <Flex gap={'1rem'} alignItems={'center'} justifyContent={'flex-end'}>
                            <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--terc3)'} label={'Excluir questão'} hasArrow arrowSize={15}
                      >
                        <div>
                            <FaTrash color='var(--terc3)' onClick={() => onOpenModalDelete(competencia.id, e.id)} />
                            </div>
                            </Tooltip>
                            </Flex>
                          </Flex>

                        )
                      }
                    </Flex>
                  </CompetenciaLine>
                )
              })
            }
          </SectionQuestoes>
        </CompetenciaContent>
        <Flex gap={'1rem'} justifyContent={'end'} alignItems={'end'} margin={'0rem 1rem 1rem 0rem'}>
          <MetaButton
            onClick={() => onModalQuestao(competencia.id)}
            bg='vg3' size='md'><FaPlus className='IconLeft'
            />Criar questão</MetaButton>
        </Flex>
      </Flex>

    </Container>
  )
}
