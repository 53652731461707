/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'

interface iPieChartData{
  nome: string
  series: string[]
  labels: string[]
}

interface PiechartProps {
  id: string
  data: iPieChartData
}

export const PieChart: React.FC<PiechartProps> = ({ id, data }) => {
  function GerarGrafico(): void {
    const options = {
      series: data.series,
      chart: {
        width: 530,
        type: 'pie'
      },
      dataLabels: {
        enabled: true
      },
      legend: {
        formatter: function(val: string, opts: { w: { globals: { series: { [x: string]: string } } }, seriesIndex: string | number }) {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
        }
      },
      labels: data.labels,
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [])
  return (
        <Flex id={`chart-${id}`} />
  )
}
