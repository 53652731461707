import { Button, Flex, Input, InputGroup, InputLeftElement, Select, FormLabel, FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { ImgSah } from '../../../components/ImgSah'
import { ModalCreateForm } from '../../../components/ModalCreateForm'
import { ModalDelete } from '../../../components/ModalDelete'
import { WhiteBox } from '../../../components/WhiteBox'
import { iIntegracao } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import { Body } from '../../Layouts/Body'
import { CardImersao } from './Components/CardImersao'

export const GestaoImersao: React.FC = () => {
  const nav = useNavigate()
  const [Imersoes, setImersoes] = useState<iIntegracao[]>([])
  const [TxtPesquisa, setTxtPesquisa] = useState('')

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  const [ModalCreateIsOpen, setModalCreateIsOpen] = useState(false)
  const [ModalCopyIsOpen, setModalCopyIsOpen] = useState(false)

  const [IsLoading, setIsLoading] = useState(false)

  const [Filter, setFilter] = useState('2')

  function getImersoes(): void {
    setIsLoading(true)
    appApi.get('ImersaoExterna')
      .then(res => {
        setImersoes(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function converterData(val: string): Date {
    const [day, month, year] = val.split('/')
    const date = new Date(+year, +month - 1, +day)
    return date
  }

  function FilterBy(tipo: number): void {
    const arr = [...Imersoes]

    if (tipo === 1) {
      console.log('Aqui')
    } else if (tipo === 2) {
      arr.sort((a, b) => {
        return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
      })
    } else if (tipo === 3) {
      arr.sort((a, b) => {
        return a.nome.toLowerCase() > b.nome.toLowerCase() ? 1 : -1
      })
      arr.reverse()
    } else if (tipo === 4) {
      arr.sort(function (a, b) {
        return new Date(converterData(b.dataCriacao as string)) > new Date(converterData(a.dataCriacao as string)) ? 1 : -1
      })
    } else if (tipo === 5) {
      arr.sort(function (a, b) {
        return new Date(converterData(b.dataCriacao as string)) > new Date(converterData(a.dataCriacao as string)) ? 1 : -1
      })
      arr.reverse()
    }
    setImersoes(arr)
  }

  function HabilitarDesabilitar(id: string): void {
    appApi.patch(`ImersaoExterna/${id}/AlterarHabilitar`).then(() => {
      getImersoes()
      setFilter('2')
    }).catch(err => console.log(err))
  }

  function DeletarImersao(): void {
    appApi.delete(`ImersaoExterna/${Id as string}`)
      .then(() => {
        getImersoes()
        setFilter('2')
      })
      .catch(err => console.log(err))
  }

  function CriarImersao(Texto: string): void {
    appApi.post('ImersaoExterna', { nome: Texto }).then(response => {
      console.log(response.data)
      nav(response.data as string)
    }
    ).catch(e => console.log(e))
  }

  function CopiarImersao(nome: string): void {
    const form = {
      texto: nome
    }
    appApi.post(`ImersaoExterna/Copy/${Id as string}`, form)
      .then(() => {
        setModalCopyIsOpen(false)
        getImersoes()
        setFilter('2')
      })
      .catch(err => console.log(err))
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function onOpenModalCopy(id: string): void {
    setModalCopyIsOpen(true)
    setId(id)
  }

  function onCloseModalCopy(): void {
    setModalCopyIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    getImersoes()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta imersão?'
        onRequestClose={onCloseModalDelete}
        onConfirm={DeletarImersao}
      />

      <ModalCreateForm
        isOpen={ModalCreateIsOpen}
        onClose={() => setModalCreateIsOpen(false)}
        onCreate={CriarImersao}
      />

      <ModalCreateForm
        isOpen={ModalCopyIsOpen}
        onClose={onCloseModalCopy}
        onCreate={CopiarImersao}
        title={'Duplicar'}
      />
      <WhiteBox>

        <Flex justifyContent={'space-between'}>
          <h1>Imersão cultural </h1>
          <Button
            size={'lg'}
            bg={'var(--Azul)'}
            borderRadius={'12px'}
            _hover={{ bg: 'var(--Azul)' }}
            onClick={() => setModalCreateIsOpen(true)}
          >
            Criar nova imersão
          </Button>
        </Flex>
        <Flex margin={'2rem 0'} justifyContent={'space-between'} alignItems={'end'} >
          <FormControl>
            <FormLabel fontSize={'xl'}>Ordenação</FormLabel>
            <Select value={Filter} onChange={(e) => {
              setFilter(e.target.value)
              if (e.target.value === '2') {
                FilterBy(2)
              } else if (e.target.value === '3') {
                FilterBy(3)
              } else if (e.target.value === '4') {
                FilterBy(4)
              } else if (e.target.value === '5') {
                FilterBy(5)
              }
            }} width={'30%'} borderColor={'var(--Gray4)'} size='md'>
              <option value={'2'}>A-Z</option>
              <option value={'3'}>Z-A</option>
              <option value={'4'}>Imersão mais recente</option>
              <option value={'5'}>Imersão mais antiga</option>
            </Select>
          </FormControl>

          <InputGroup width={'30%'}>
            <InputLeftElement
              pointerEvents='none'
              children={<FaSearch color='var(--Gray4)' />}
            />
            <Input
              onChange={(e) => setTxtPesquisa(e.target.value)}
              borderColor={'var(--Gray4)'}
              type='text'
              placeholder='Pesquisa'
            />
          </InputGroup>
        </Flex>
        {
          (Imersoes.length > 0) && (
            <Flex display={'grid'} gap={'2rem 0rem'}>
              {
                Imersoes.filter((e) => {
                  if (TxtPesquisa === '') {
                    return e
                  } else if (e.nome.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                    return e
                  }
                  return null
                }).map((e, i) => {
                  return (
                    <CardImersao
                      key={i}
                      Imersao={e}
                      onHabilitar={HabilitarDesabilitar}
                      onDelete={onOpenModalDelete}
                      onCopy={onOpenModalCopy}
                    />
                  )
                })
              }
            </Flex>
          )
        }

        {
          (Imersoes.length === 0 && !IsLoading) && (
            <ImgSah entidade='imersão'>
              <Button
                size={'lg'}
                bg={'var(--Azul)'}
                borderRadius={'12px'}
                _hover={{ bg: 'var(--Azul)' }}
                onClick={() => setModalCreateIsOpen(true)}
              >
                Criar nova imersão
              </Button>
            </ImgSah>
          )
        }
      </WhiteBox>
    </Body>
  )
}
