/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js'
import { Radar } from 'react-chartjs-2'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

interface IDadoGrafico {
  medias: number[]
  nome: string
}

interface RadarChartProps {
  competencias: string[]
  dados: IDadoGrafico[]
  notaMaximaEscala: number
  notaMinimaEscala: number
  stepSize: number
  setGrafico: (blob: string) => void
}

interface iChartProviderProps {
  getChartBlob: () => string
}

function useChartState(ChartRef: any): iChartProviderProps {
  function getChartBlob(): string {
    return ChartRef.current.canvas.toDataURL()
  }

  return { getChartBlob }
}

const PDFRadarChart: React.FC<RadarChartProps> = ({
  competencias,
  dados,
  notaMaximaEscala,
  notaMinimaEscala,
  stepSize,
  setGrafico
}) => {
  const canvasReference = useRef(null)
  const { getChartBlob } = useChartState(canvasReference)
  const series = useMemo(
    () =>
      dados.map((dado) => ({
        name: dado.nome,
        data: dado.medias
      })),
    [dados]
  )

  function ObterCor(pos: number): string {
    if (dados.length === 1) {
      return colors[pos]
    } else {
      return dados[1].nome.includes('Média sem autoavaliação') && pos === dados.length - 1 ? colors[colors.length - 1] : colors[pos]
    }
  }

  function AbreviarNomesGrandes(): string[] {
    return competencias.map((e) => {
      if (e.length > 19) {
        return e.substring(0, 19) + '...'
      } else {
        return e
      }
    })
  }

  const colors = [
    'rgb(0, 143, 251)',
    'rgb(0, 227, 150)',
    '#F2C94C',
    'rgb(255, 69, 96)',
    '#fe00f4',
    '#BB6BD9',
    '#219653',
    '#F2994A'
  ]

  const Shapes = ['circle', 'star', 'triangle', 'rectRot', 'rectRounded']

  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 16
          }
        }
      }
    },
    scales: {
      r: {
        min: notaMinimaEscala,
        max: notaMaximaEscala,
        ticks: {
          min: 0,
          max: 10,
          stepSize: stepSize,
          font: {
            size: 12
          },
          z: 1
        },
        pointLabels: {
          color: '#000',
          font: {
            size: 16
          }
        },

        angleLines: {
          color: '#bdbdbd'
        },

        grid: {
          color: '#bdbdbd'
        }
      }
    }
  }

  const data = {
    labels: AbreviarNomesGrandes(),
    datasets: series.map((e, i) => {
      return {
        label: e.name,
        data: e.data,
        fill: false,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: ObterCor(i),
        backgroundColor: ObterCor(i),
        borderColor: ObterCor(i),
        pointRadius: i === 0 ? 4 : 6,
        pointStyle: Shapes[i]
      }
    })
  }

  const plugin = {
    id: 'custom_canvas_background_color-' + uuidv4(),
    beforeDraw: (chart: any) => {
      const { ctx } = chart
      ctx.save()
      ctx.globalCompositeOperation = 'destination-over'
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, chart.width, chart.height)
      ctx.restore()
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setGrafico(getChartBlob())
    }, 1000)
  }, [dados])

  return (
    <Radar
        width={400}
        height={400}
        options={options}
        data={data}
        ref={canvasReference}
        plugins={[plugin]}
      />
  )
}

export default PDFRadarChart
