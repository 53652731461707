/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Avatar, Flex, Table, TableContainer, Tbody, Thead, Tr, Text, AvatarGroup } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { CsvButton } from '../../../../../../../components/Buttons/CsvButton'
import { urlApi } from '../../../../../../../services/appApi'
import { ObterToken } from '../../../../../../../Utils/Helper'
import { iAvatarXInformacoes, iLinhaTabela } from '../../../Individual'
import { OutlineContainer, OutlineTable, StyledSpan } from './styles'

interface iProps {
  tabela: iLinhaTabela[]
}

interface iLinhaGeral {
  gestor: iAvatarXInformacoes
  colaboradores: iAvatarXInformacoes[]
  aceitas: number
  finalizadas: number
  aguardando: number
  atrasadas: number
}
export const TabListaColaboradores: React.FC<iProps> = ({ tabela }) => {
  const [UltimoFiltro, setUltimoFiltro] = useState<number>()
  const [Ordenacao, setOrdenacao] = useState(false)
  const [Tabela, setTabela] = useState<iLinhaGeral[]>()

  function OrdenarTabela(filtro: number): void {
    if (!(!Ordenacao && UltimoFiltro !== filtro)) {
      setOrdenacao(!Ordenacao)
    }
    setUltimoFiltro(filtro)
  }

  function Ordenar(): void {
    const copy = Tabela?.length ? [...Tabela] : []
    if (UltimoFiltro === 1) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return b.gestor.info1.localeCompare(a.gestor.info1)
        } else if (Ordenacao) {
          return a.gestor.info1.localeCompare(b.gestor.info1)
        } else {
          return 0
        }
      })
    }
    if (UltimoFiltro === 2) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return a.colaboradores.length > b.colaboradores.length ? 1 : -1
        } else if (Ordenacao) {
          return b.colaboradores.length > a.colaboradores.length ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 3) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return a.finalizadas > b.finalizadas ? 1 : -1
        } else if (Ordenacao) {
          return b.finalizadas > a.finalizadas ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 4) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return a.aceitas > b.aceitas ? 1 : -1
        } else if (Ordenacao) {
          return b.aceitas > a.aceitas ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 5) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return a.aguardando > b.aguardando ? 1 : -1
        } else if (Ordenacao) {
          return b.aguardando > a.aguardando ? 1 : -1
        }
        return 0
      })
    }
    if (UltimoFiltro === 6) {
      copy.sort((a, b) => {
        if (!Ordenacao) {
          return a.atrasadas > b.atrasadas ? 1 : -1
        } else if (Ordenacao) {
          return b.atrasadas > a.atrasadas ? 1 : -1
        }
        return 0
      })
    }
    setTabela(copy)
  }

  useEffect(() => {
    Ordenar()
  }, [Ordenacao, UltimoFiltro])

  function ConstruirTabela(): void {
    const model: iLinhaGeral[] = []
    const gestores: iAvatarXInformacoes[] = []
    tabela.forEach((c) => {
      if (!gestores.some(r => r.id === c.criador.id)) {
        gestores.push(c.criador)
      }
    })

    gestores.forEach((e) => {
      model.push({
        aguardando: tabela.filter(t => t.criador.id === e.id && t.status === 0).length,
        aceitas: tabela.filter(t => t.criador.id === e.id && t.status === 1).length,
        atrasadas: tabela.filter(t => t.criador.id === e.id && t.status === 4).length,
        finalizadas: tabela.filter(t => t.criador.id === e.id && t.status === 5).length,
        gestor: e,
        colaboradores: tabela.filter(t => t.criador.id === e.id).map(r => r.convidado)
      })
    })

    setTabela(model)
  }

  useEffect(() => {
    ConstruirTabela()
  }, [tabela])
  return (
		<OutlineContainer>
			<Flex gap={'1rem'} alignItems={'end'}>
				<CsvButton onClick={() => window.location.href = `${urlApi}/ReuniaoOne/Csv/Gestor?token=${ObterToken()}`} />
			</Flex>
			<OutlineTable>
				<TableContainer>
					<Table variant='simple'>
						<Thead>
							<Tr>
								<th>Gestores {UltimoFiltro === 1 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(1)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Colaboradores {UltimoFiltro === 2 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(2)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Finalizadas {UltimoFiltro === 3 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(3)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Aceitas {UltimoFiltro === 4 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(4)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Aguardando {UltimoFiltro === 5 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(5)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(5)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Atrasadas {UltimoFiltro === 6 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(6)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(6)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
								<th>Total {UltimoFiltro === 7 && !Ordenacao ? <FaChevronUp onClick={() => OrdenarTabela(7)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} /> : <FaChevronDown onClick={() => OrdenarTabela(7)} size={'14px'} style={{ paddingTop: '5px', cursor: 'pointer' }} />}</th>
							</Tr>
						</Thead>
						<Tbody>
							{Tabela?.map((e, i) => {
							  return (
									<Tr key={i}>
										<td>
											<StyledSpan style={{ justifyContent: 'start', paddingLeft: '1rem' }}>
												<Avatar src={e.gestor.avatar} size={'sm'} />
												<Text color={'Gray1'} fontWeight={'600'} fontSize={'12px'}>
													{e.gestor.info1}
												</Text>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<AvatarGroup size={'sm'} max={3} spacing={'-3.5'}>
													{e.colaboradores.map((colaborador, i) => {
													  return (
															<Avatar key={i} name={colaborador.info1} src={colaborador.avatar} />
													  )
													})}
												</AvatarGroup>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.finalizadas}</Text>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.aceitas}</Text>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.aguardando}</Text>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.atrasadas}</Text>
											</StyledSpan>
										</td>
										<td>
											<StyledSpan>
												<Text color={'Gray1'} fontSize={'12px'} fontWeight={'400'}>{e.finalizadas + e.aceitas + e.aguardando + e.atrasadas}</Text>
											</StyledSpan>
										</td>
									</Tr>
							  )
							})}
						</Tbody>
					</Table>
				</TableContainer>
			</OutlineTable>
		</OutlineContainer>
  )
}
