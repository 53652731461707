import { Flex } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { TabListaPorReuniao } from '../../../../Jornada/ReuniaoOne/Dashboard/Corporativo/Components/TabListaPorReuniao'
import { iLinhaTabela } from '../../../../Jornada/ReuniaoOne/Dashboard/Individual'

interface iProps {
  tabela: iLinhaTabela[]
}

export const TabReuniaoOne: React.FC<iProps> = ({ tabela }) => {
  const { colaboradorId } = useParams<{colaboradorId: string}>()

  return (
    <Flex flexDirection={'column'} gap='1rem'>
      {tabela.filter(e => e.convidado.id === colaboradorId).length > 0 &&
        <Flex flexDir={'column'}>
          <h3>Reuniões que o colaborador foi convidado</h3>
          <TabListaPorReuniao
            reunioes={tabela.filter(r => r.convidado.id === colaboradorId)}
            individual={true}
            telaColaborador={'criadas'}
          />
        </Flex>
      }

      {tabela.filter(e => e.criador.id === colaboradorId).length > 0 &&
        <Flex flexDir={'column'}>
          <h3>Reuniões solicitadas pelo colaborador</h3>
          <TabListaPorReuniao
            reunioes={tabela.filter(e => e.criador.id === colaboradorId)}
            individual={true}
            telaColaborador={'solicitadas'}
          />
        </Flex>
      }
    </Flex>
  )
}
