import styled from 'styled-components'

export const InfoBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
background: var(--Azul);
color: white;
border-radius: 0.625rem;
height: 5.625rem;
width: 100%;
box-shadow: var(--SombraBtns);

div{
    width: min-content;
    margin-left: .5rem;
}
`
export const Titulo = styled.h2`
font-family: 'Poppins';
font-weight: 500;
font-size: 28px;
color: #2C4D8A;
`
export const TabelaAvaliacoes = styled.table`
    border-collapse: collapse;
    width: 100%;
    tr {
        padding: 1rem 1.5rem 1rem 1.5rem;
        :first-child {
            padding: 0;
        }
    }
    tbody > tr {
        background-color: #EDF2F7;
        padding: 1rem 1.5rem 1rem 1.5rem;
        :first-child {
            padding: 0;
        }
        :nth-child(even) {
            background: #fff;
        }
    }
    td {
        font-size: 14px;
        padding: 1rem 1.5rem 1rem 1.5rem;
        :first-child {
            text-align: start;
            padding: 1rem 2.25rem 1rem 2.25rem; 
        }
        svg {
            font-size: 22px;
        }
        text-align: center;
    }
    th {
        color: var(--Gray2);
        font-size: 12px;
        padding: 1rem 1.5rem 1rem 1.5rem; 
        :first-child {
            text-align: start;
            padding: 1rem 2.25rem 1rem 2.25rem; 
        }
        text-align: center;
    }
`
