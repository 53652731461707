import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MetaButton } from '../../../../components/Buttons/MetaButton'
import { iItemIntegracao } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { Checklist } from '../../Components/Checklist'
import { PDFImersao } from '../../Components/Pdf'
import { TextoImersao } from '../../Components/Texto'
import { VideoImersao } from '../../Components/Video'
import { FlexCenter, NavElipse } from './styles'

type ParamProps = {
  imersaoId: string
}

export const PrevisualizarImersao: React.FC = () => {
  const { imersaoId } = useParams<ParamProps>()
  const nav = useNavigate()

  const [Switch, setSwitch] = useState(0)

  const [Imersao, setImersao] = useState<iItemIntegracao[]>([])

  function GetImersoesById(): void {
    appApi.get(`ImersaoExterna/${imersaoId as string}/Item`)
      .then(response => {
        setImersao(response.data)
      }).catch(err => console.error(err))
  }

  useEffect(() => {
    GetImersoesById()
  }, [])

  return (
    <Body>
      {
        Imersao.map((e: iItemIntegracao, i) => {
          if (e.tipo === 0) {
            return (
              <Checklist
                key={i}
                item={e}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 1) {
            return (
              <VideoImersao
                key={i}
                item={e}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 2) {
            return (
              <TextoImersao
                key={i}
                item={e}
                isVisible={i === Switch}
              />
            )
          } else if (e.tipo === 3) {
            return (
              <PDFImersao
                key={i}
                isVisible={i === Switch}
                uri={e.arquivo?.uri}
              />
            )
          }
          return null
        })
      }

      <NavElipse>
        {
          Imersao.map((e: iItemIntegracao, i) => {
            return (
              <div
                key={i}
                title={e.titulo}
                onClick={() => setSwitch(i)}
                style={{ background: Switch === i ? 'var(--c7)' : 'var(--c5)' }}>
              </div>
            )
          })
        }
      </NavElipse>

      <FlexCenter>
        {
          Switch === 0
            ? <MetaButton onClick={() => nav(-1)} bg='c5' size='md' styles={{ marginRight: '2rem' }}>Voltar</MetaButton>
            : <MetaButton onClick={() => setSwitch(Switch - 1)} bg='c5' size='md' styles={{ marginRight: '2rem' }}>Voltar</MetaButton>
        }

        {
          Switch === Imersao.length - 1
            ? <MetaButton onClick={() => nav(-1)} bg='v2' size='md'>Finalizar pré-visualização</MetaButton>
            : <MetaButton onClick={() => setSwitch(Switch + 1)} bg='v2' size='md'>Próximo</MetaButton>
        }
      </FlexCenter>
    </Body>
  )
}
