import styled from 'styled-components'

export const HeadContainer = styled.div`
margin-bottom: 1rem;
`

export const Main = styled.div`
margin-top: 2rem;
h2{
    margin-bottom: 0.5rem;
    color: var(--a3);
}
`

export const Btns = styled.div`
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
`

export const ContainerFormulario = styled.div`
display: flex;
background: var(--c1);
justify-content: space-between;
margin-bottom: .5rem;
border-radius: 0.313rem;
padding: .5rem 0rem .5rem .5rem;
align-items: center;
font-size: 1.125rem;
font-weight: 500;
font-family: 'Roboto','sans-serif';
flex-wrap: wrap;
span{
    color: var(--a5);
}

button,a:nth-child(n){
    margin-right: 0.5rem;
    padding: .3rem .6rem .3rem .6rem;
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
}

button:nth-child(1){
   background: var(--v2);
   color: white;
   &:hover{
       filter: brightness(0.9);
       transition: all 0.3s ease;
    }
}


button:nth-child(3){
    color: var(--terc3);
  border: 0.063rem solid var(--terc3);
  background: none;

  &:hover{
      color: white;
      transition: all 0.3s ease;
      background: var(--terc3);
  }
}
`
