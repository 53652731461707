/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCopy, FaEye, FaPlus, FaTrash } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { ModalDelete } from '../../../../components/ModalDelete'
import { NavLine } from '../../../../components/NavLine'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { SubstringRoute } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { OutlineButton } from '../Components/OutlineButton'

export const FormulariosFeedback: React.FC = () => {
  const toast = useToast()
  const { feedbackId } = useParams<{feedbackId: string}>()
  const { pathname } = useLocation()
  const nav = useNavigate()
  const [Formularios, setFormularios] = useState<iNomeId[]>([])

  const [Id, setId] = useState<string>()
  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)

  function ObterFormularios(): void {
    appApi.get('FormularioFeedback')
      .then(res => {
        if (res.status === 200) {
          setFormularios(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    appApi.delete(`FormularioFeedback/${Id ?? ''}`)
      .then(() => {
        toast({
          title: 'Formulário excluído com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setFormularios([...Formularios.filter(e => e.id !== Id)])
      })
      .catch(err => console.log(err))
  }

  function Criar(): void {
    appApi.post('FormularioFeedback')
      .then(res => Selecionar(res.data))
      .catch(err => console.log(err))
  }

  function Duplicar(id: string): void {
    appApi.post(`FormularioFeedback/${id}/Duplicar`)
      .then(res => Selecionar(res.data as string))
      .catch(err => console.log(err))
  }

  function Selecionar(formularioId: string): void {
    appApi.patch(`Feedback/${feedbackId ?? ''}/Formulario/${formularioId}`)
      .then(() => nav(`${pathname.replace('Formularios', 'EditarFormulario')}/${formularioId}`))
      .catch(err => console.log(err))
  }

  function OnOpenModalDelete(id: string): void {
    setId(id)
    setModalDeleteIsOpen(true)
  }

  function OnCloseModalDelete(): void {
    setId(undefined)
    setModalDeleteIsOpen(false)
  }

  useEffect(() => {
    ObterFormularios()
  }, [])
  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir este formulário?'
        onConfirm={Deletar}
        onRequestClose={OnCloseModalDelete}
      />
      <NavLine
        options={
          [{ nome: 'Reuniao 1:1', path: `${SubstringRoute(pathname, 'Feedback')}Dashboard/Individual` },
            { nome: 'Feedback' },
            { nome: 'Formularios' }
          ]} />
      <WhiteBox>
        <h2>Selecione ou crie um formulário novo</h2>
        <Button
          my={'2rem'}
          w={'16rem'}
          VarColor={'v2'}
          leftIcon={<FaPlus />}
          onClick={Criar}
        >Criar novo Formulário</Button>
        <Text fontWeight={'700'} fontSize={'22px'}>Formulários de feedback salvos:</Text>
        {Formularios.map(e => {
          return (
            <Flex key={e.id} gap={'.5rem'} my={'4px'} p={'1rem'} bgColor={'var(--Gray6)'}>
              <Text flex={'1'}>{e.nome}</Text>
              <Button
                h={'2rem'}
                VarColor='v2'
                w={'6.9rem'}
                fontSize={'0.875rem'}
                onClick={() => Selecionar(e.id)}
              >Selecionar</Button>

              <OutlineButton
                onClick={() => Duplicar(e.id)}
                varColor='AzulClaro2'
                leftIcon={<FaCopy />}
                w={'6.6rem'}
              >Duplicar</OutlineButton>

              <OutlineButton
                varColor='AzulClaro2'
                leftIcon={<FaEye />}
                w={'8.75rem'}
                onClick={() => nav(`/Feedback/Preenchimento/${feedbackId ?? ''}/Previsualizar`)}
              >Pré-visualizar</OutlineButton>

              <OutlineButton
                onClick={() => OnOpenModalDelete(e.id)}
                varColor='Red'
                w={'1.75rem'}
                leftIcon={<FaTrash />}
                pl={'1.2rem'}>
              </OutlineButton>
            </Flex>
          )
        })}
      </WhiteBox>
    </Body>
  )
}
