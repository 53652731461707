import { Avatar, Box, Flex, Text } from '@chakra-ui/react'
import { iPrincipal } from '../..'
import { PropInvalida } from '../../../../../../../Utils/Helper'
import { Container } from './styles'

interface iProps{
  model?: iPrincipal
}

export const CardProximaReuniao: React.FC<iProps> = ({ model }) => {
  return (
        <Container isDisabled={PropInvalida(model)}>
            <Box>
                <Text fontSize={'24px'} fontWeight={'700'} color={'var(--Gray1)'}>Próxima reunião</Text>
                <Text fontSize={'32px'} fontWeight={'600'} className='gradText'>{model?.dataInicio}</Text>
                <Text fontSize={'16px'} fontWeight={'700'} className='gradText'>{model?.diaSemana}</Text>
            </Box>
            <Box width={'2px'} mx={'1.5rem'} mt={'.2rem'} height={'92%'} bgColor={'var(--Gray2)'}/>
            <Flex gap={'1rem'}>
                <Avatar name={model?.nomeParticipante} src={model?.avatar} size={'md'} />
                <Box display={'flex'} flexDirection={'column'} gap={'.5rem'}>
                    <Box>
                        <Text fontSize={'12px'} fontWeight={'700'}>Participante:</Text>
                        <Text mt={'-4px'} fontWeight={'400'}>{model?.nomeParticipante}</Text>
                    </Box>
                    <Box>
                        <Text fontSize={'12px'} fontWeight={'700'}>Assunto:</Text>
                        <Text mt={'-4px'} fontWeight={'400'}>{model?.assunto}</Text>
                    </Box>
                </Box>
            </Flex>
        </Container>
  )
}
