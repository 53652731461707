import {
  Drawer, DrawerBody,
  DrawerOverlay, DrawerContent, DrawerHeader,
  Flex, Select, FormControl, FormLabel,
  Input, Text, Divider
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { iFiltro } from '../..'
import { Button } from '../../../../../../../components/Button'
import { iNomeId } from '../../../../../../../interfaces'

interface iDrawProps {
  isOpen: boolean
  onClose: () => void
  onFilter: (model: iFiltro) => void
  tags: iNomeId[]
  areas: iNomeId[]
  cargos: iNomeId[]
  filtro: iFiltro
}

export const DrowerFiltroReuniaoOne: React.FC<iDrawProps> = ({ isOpen, onClose, onFilter, areas, cargos, tags, filtro }) => {
  const [Filtro, setFiltro] = useState<iFiltro>({
    cargoId: '',
    departamentoId: '',
    tagId: '',
    dataFim: '',
    dataInicio: '',
    status: ''
  })
  useEffect(() => {
    setFiltro(filtro)
  }, [])

  return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            size={'md'}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader as={Flex} width={'100%'} justifyContent={'space-between'} borderBottom={'2px solid var(--c3)'} borderBottomWidth='1px'>
                    <Text>Filtros</Text>
                    <MdClose onClick={onClose} size={24} />
                </DrawerHeader>

                <DrawerBody>
                    <Flex gap={'1rem'} flexDir={'column'}>
                        <Flex gap={'1rem'}>
                            <FormControl>
                                <FormLabel>Início</FormLabel>
                                <Input
                                    value={Filtro.dataInicio}
                                    onChange={(e) => setFiltro({ ...Filtro, dataInicio: e.target.value })}
                                    type={'date'}
                                    borderColor={'var(--Gray4)'}
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel>Fim</FormLabel>
                                <Input
                                    value={Filtro.dataFim}
                                    type={'date'}
                                    borderColor={'var(--Gray4)'}
                                    onChange={(e) => setFiltro({ ...Filtro, dataFim: e.target.value })}
                                />
                            </FormControl>
                        </Flex>
                        {/* <Divider orientation='horizontal' /> */}

                        {/* <FormControl>
                            <FormLabel>Período</FormLabel>
                            <Select>
                                <option value={'0'}>Nesta semana</option>
                                <option value={'1'}>Últimos 7 dias</option>
                                <option value={'2'}>Neste mês</option>
                                <option value={'3'}>Últimos 30 dias</option>
                                <option value={'4'}>Últimos 90 dias</option>
                                <option value={'5'}>Neste semestre</option>
                                <option value={'6'}>Neste ano</option>
                            </Select>
                        </FormControl> */}
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />
                        <FormControl>
                            <FormLabel>Status</FormLabel>
                            <Select
                                value={Filtro.status}
                                onChange={(e) => setFiltro({ ...Filtro, status: e.target.value })}
                            >
                                <option value={''}>Todos</option>
                                <option value={'5'}>Finalizadas</option>
                                <option value={'3'}>Reagendadas</option>
                                <option value={'2'}>Recusadas</option>
                                <option value={'0'}>Aguardando resposta</option>
                                <option value={'4'}>Atrasadas</option>
                            </Select>
                        </FormControl>
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Área</FormLabel>
                            <Select
                                value={Filtro.departamentoId}
                                onChange={(e) => setFiltro({ ...Filtro, departamentoId: e.target.value })}
                            >
                                <option value={''}>Todas</option>
                                {areas.map((e, i) => {
                                  return (
                                        <option key={i} value={e.id}>{e.nome}</option>
                                  )
                                })}
                            </Select>
                        </FormControl>
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Squad</FormLabel>
                            <Select
                                value={Filtro.tagId}
                                onChange={(e) => setFiltro({ ...Filtro, tagId: e.target.value })}
                            >
                                <option value={''}>Todas</option>
                                {tags.filter(e => e.nome !== '').map((e, i) => {
                                  return (
                                        <option key={i} value={e.id}>{e.nome}</option>
                                  )
                                })}
                            </Select>
                        </FormControl>
                        <Divider borderColor={'var(--c3)'} orientation='horizontal' />

                        <FormControl>
                            <FormLabel>Função</FormLabel>
                            <Select
                                value={Filtro.cargoId}
                                onChange={(e) => setFiltro({ ...Filtro, cargoId: e.target.value })}
                            >
                                <option value={''}>Todas</option>
                                {cargos.map((e, i) => {
                                  return (
                                        <option key={i} value={e.id}>{e.nome}</option>
                                  )
                                })}
                            </Select>
                        </FormControl>

                        <Flex gap={'1rem'} justifyContent={'end'}>
                            <Button
                                VarColor='c4'
                                onClick={onClose}
                            >Cancelar
                            </Button>
                            <Button
                                VarColor='v2'
                                onClick={() => onFilter(Filtro)}
                            >Filtrar
                            </Button>
                        </Flex>
                    </Flex>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
  )
}
