import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { MdClose } from 'react-icons/md'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { iNomeId, iTag } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Header } from './styles'
import { Checkbox, Flex, FormControl, FormLabel, Select } from '@chakra-ui/react'
import { FindUser } from '../../../../../components/FindBox'

interface iModalAddAvaliado {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (avaliadoId: string, squadId?: string) => void
}

export const ModalAdicionarAvaliado: React.FC<iModalAddAvaliado> = ({ isOpen, onConfirm, onRequestClose }) => {
  Modal.setAppElement('#root')

  const [Options, setOptions] = useState<iNomeId[]>([])
  const [Selecionado, setSelecionado] = useState('')
  const [Adicionado, setAdicionado] = useState<boolean>(false)

  const [Squad, setSquad] = useState(false)
  const [Squads, setSquads] = useState<iTag[]>([])

  const [SquadId, setSquadId] = useState<string>()

  function getSelectColaboradores(): void {
    appApi.get('Colaborador/Select').then(res => setOptions(res.data)).catch(err => console.log(err))
  }

  function SelecionarGestor(id: string): void {
    setSelecionado(id)
  }

  function ObterSquads(): void {
    appApi.get('Tag?tipo=2')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getSelectColaboradores()
    ObterSquads()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Header>
        <Flex justifyContent={'space-between'}>
          <div>
            <h2>Adicione um avaliado</h2>
            <span>O usuário deve estar cadastrado no sistema para ser adicionado como participante na pesquisa. No entanto, seus stakeholders podem ser adicionados apenas com o e-mail, posteriormente na lista de participantes</span>
          </div>
          <MdClose color='var(--a3)' cursor={'pointer'} onClick={onRequestClose} />
        </Flex>
      </Header>
      <Flex gap={'1rem'} flexDir={'column'} padding={'1rem'}>
        <Flex flexDir={'column'}>
          <FormLabel>Nome do colaborador</FormLabel>
          <FindUser
            lista={Options}
            onChoice={SelecionarGestor}
            placeholder='Digite o nome do participante'
            selecionado={Selecionado}
            adicionado={Adicionado}
            setAdicionado={setAdicionado}
          />
        </Flex>

        <FormControl gap={'.5rem'} as={Flex}>
          <Checkbox size={'lg'} isChecked={Squad} onChange={() => {
            setSquadId(undefined)
            setSquad(!Squad)
          }} />
          <FormLabel fontSize={'md'} margin={0}>Selecionar avaliadores baseado em um squad</FormLabel>
        </FormControl>

        {
          (Squad) && (
            <FormControl>
              <FormLabel>Squads</FormLabel>
              <Select placeholder='selecione um squad disponível na lista' onChange={(e) => setSquadId(e.target.value)}>
                {
                  Squads.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>{e.texto}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
          )
        }
      </Flex>
      <Flex alignItems={'center'} justifyContent={'end'} padding={'0rem 1rem 1rem 0rem'}>
        <MetaButton
          size='lg'
          bg='v2'
          onClick={() => {
            onConfirm(Selecionado, SquadId)
            setAdicionado(true)
          }}
        >Adicionar</MetaButton>
      </Flex>
    </Modal>
  )
}
