/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect } from 'react'
import ApexCharts from 'apexcharts'

interface iGrafico {
  aguardando: number
  aceita: number
  atrasada: number
  finalizada: number
  reagendada: number
  recusada: number
}

interface iChartProps{
  id: string
  model: iGrafico
}

export const ReuniaoOneChart: React.FC<iChartProps> = ({ id, model }) => {
  const valores: number[] = [model.aceita, model.aguardando, model.atrasada, model.finalizada, model.reagendada, model.recusada]
  const labels: string[] = ['Aceita', 'Aguardando', 'Atrasada', 'Finalizada', 'Reagendada', 'Recusada']
  const colors: string[] = ['#2F80ED', '#828282', '#EB5757', '#6FCF97', '#9B51E0', '#CC42A2']

  function GerarGrafico(): void {
    const options = {
      series: valores,
      colors: colors,
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }],
      labels: labels,
      legend: {
        show: true,
        enabled: false,
        position: 'right',
        offsetY: -20
      },
      chart: {
        width: 215,
        height: 215,
        type: 'donut',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      fill: {
        colors: colors
      },
      plotOptions: {
        pie: {
          size: 50,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '5px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 500,
                offsetY: -3,
                formatter: function (w: any) {
                  return 'Total'
                }
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                offsetY: 0
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: '12px',
                color: '#373d3f',
                formatter: function (w: any) {
                  return model.aguardando + model.atrasada + model.reagendada + model.recusada + model.finalizada + model.aceita
                }
              }
            }
          }
        }
      }
    }

    const chart = new ApexCharts(document.querySelector(`#${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [])

  return (
        <div style={{ marginTop: '2.5rem' }} id={id}></div>
  )
}
