import { Flex, Grid, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaFilter } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/Button'
import { ModalDelete } from '../../../../../components/ModalDelete'
import { Tag } from '../../../../../components/Tag'
import { WhiteBox } from '../../../../../components/WhiteBox'
import { iNomeId } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { PropInvalida } from '../../../../../Utils/Helper'
import { Body } from '../../../../Layouts/Body'
import { iModalAgendamentoInput } from '../../Assunto'
import { ModalReagendar } from '../../Assunto/components/ModalReagendar'
import { iLinhaTabela } from '../../Dashboard/Individual'
import { BoxReunioesStatus } from './Components/BoxReunioesStatus'
import { DrowerFiltroReuniaoOne } from './Components/DrowerFiltroReuniao'
import { TabListaColaboradores } from './Components/TabListaColaboradores'
import { TabListaPorReuniao } from './Components/TabListaPorReuniao'
import { ContainerFiltro, OutlineContainer } from './styles'

interface iModel {
  tabela: iLinhaTabela[]
}

export interface iFiltro {
  departamentoId: string
  cargoId: string
  tagId: string
  dataInicio: string
  dataFim: string
  status: string
}

export const DashboardCorporativoReuniaoOne: React.FC = () => {
  const nav = useNavigate()
  const toast = useToast()

  const { pathname } = useLocation()
  const [DrowerIsOpen, setDrowerIsOpen] = useState(false)
  const [Tags, setTags] = useState<iNomeId[]>([])
  const [Areas, setAreas] = useState<iNomeId[]>([])
  const [Cargos, setCargos] = useState<iNomeId[]>([])
  const [Model, setModel] = useState<iModel>({
    tabela: []
  })

  const [Filtro, setFiltro] = useState<iFiltro>({
    cargoId: '',
    dataFim: '',
    dataInicio: '',
    departamentoId: '',
    status: '',
    tagId: ''
  })

  const [Id, setId] = useState<string>()
  const [ReagendarIsOpen, setReagendarIsOpen] = useState(false)
  const [DeletarIsOpen, setDeletarIsOpen] = useState(false)

  function Carregar(): void {
    appApi.get('ReuniaoOne/Dashboard/Corporativo')
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        }
      })
      .catch(err => console.log(err))
  }

  function ObterSquads(): void {
    appApi.get('Tag/Select?tipo=2')
      .then(res => setTags(res.data))
      .catch(err => console.log(err))
  }

  function ObterAreas(): void {
    appApi.get('Departamento/Select')
      .then(res => setAreas(res.data))
      .catch(err => console.log(err))
  }

  function ObterCargos(): void {
    appApi.get('Cargo/Select')
      .then(res => setCargos(res.data))
      .catch(err => console.log(err))
  }

  function Filtrar(model: iFiltro): void {
    setFiltro(model)
    appApi.get(`ReuniaoOne/Dashboard/Corporativo?departamentoId=${model.departamentoId}&cargoId=${model.cargoId}&squadId=${model.tagId}&status=${model.status}&dataInicio=${model.dataInicio}&dataFim=${model.dataFim}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function ReenviarConvite(id: string): void {
    appApi.post(`ReuniaoOne/${id}/ReenviarNotificacao`)
      .then(() => toast({
        title: 'Notificação reenviada com sucesso!',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      }))
      .catch(err => console.log(err))
  }

  function ReagendarReuniao(form: iModalAgendamentoInput): void {
    appApi.patch(`ReuniaoOne/${Id ?? ''}/Reagendar`, form)
      .then(() => {
        toast({
          title: 'Solicitação de reagendamento com successo!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setReagendarIsOpen(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function Deletar(): void {
    appApi.delete(`ReuniaoOne/${Id ?? ''}`)
      .then(() => {
        toast({
          title: 'Reunião excluida com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModel({ ...Model, tabela: [...Model.tabela.filter(e => e.id !== Id)] })
      })
      .catch(err => console.log(err))
  }

  function onOpenReagendar(id: string): void {
    setReagendarIsOpen(true)
    setId(id)
  }

  function onCloseReagendar(): void {
    setReagendarIsOpen(false)
    setId(undefined)
  }

  function onOpenDelete(id: string): void {
    setDeletarIsOpen(true)
    setId(id)
  }

  function onCloseDelete(): void {
    setDeletarIsOpen(false)
    setId(undefined)
  }

  useEffect(() => {
    Carregar()
    ObterSquads()
    ObterAreas()
    ObterCargos()
  }, [])

  return (
    <Body>
      {ReagendarIsOpen &&
        <ModalReagendar
          alvo={Model.tabela.find(e => e.id === Id)?.convidado ?? {
            avatar: '',
            id: '',
            info1: ''
          }}
          onClose={onCloseReagendar}
          onReagendar={ReagendarReuniao}
        />
      }

      {DeletarIsOpen &&
        <ModalDelete
          isOpen
          onConfirm={Deletar}
          onRequestClose={onCloseDelete}
          message='Deseja realmente excluir essa reunião 1:1?'
        />
      }

      <DrowerFiltroReuniaoOne
        isOpen={DrowerIsOpen}
        onClose={() => setDrowerIsOpen(false)}
        areas={Areas}
        cargos={Cargos}
        tags={Tags}
        onFilter={Filtrar}
        filtro={Filtro}
      />
      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <h1>Reunião 1:1</h1>
          <Button onClick={() => nav(`${pathname.replace('Dashboard/Corporativo', 'Criar')}`)} VarColor='Azul' size={'lg'}>Solicitar reunião 1:1</Button>
        </Flex>
        <Flex alignItems={'center'} gap={'.5rem'} margin={'1rem 0'}>
          <Text
            fontWeight={'medium'}
          >Visão individual</Text>
          <Switch
            size={'md'}
            isChecked
            onChange={() => nav(`${pathname.replace('Corporativo', 'Individual')}`)}
          />
          <Text
            color={'var(--Azul)'}
            fontWeight={'medium'}
          >Visão corporativa</Text>
        </Flex>
        <OutlineContainer theme={'Gray3'}>
          <ContainerFiltro>
            <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
              <Flex alignItems={'center'}>
                <Text fontWeight={'500'} marginRight={'1rem'}>Filtros aplicados</Text>
                <Flex padding={'0 1rem'} borderLeft={'2px solid var(--Gray4)'} gap={'1rem'}>
                  {!PropInvalida(Filtro.dataInicio) &&
                    <Tag
                      padding='.5rem'
                    >Início: {Filtro.dataInicio}</Tag>
                  }

                  {!PropInvalida(Filtro.dataFim) &&
                    <Tag
                      padding='.5rem'
                    >Fim: {Filtro.dataFim}</Tag>
                  }

                  {!PropInvalida(Filtro.cargoId) &&
                    <Tag
                      padding='.5rem'
                    >{Cargos.find(e => e.id === Filtro.cargoId)?.nome ?? ''}</Tag>
                  }

                  {!PropInvalida(Filtro.departamentoId) &&
                    <Tag
                      padding='.5rem'
                    >{Areas.find(e => e.id === Filtro.departamentoId)?.nome ?? ''}</Tag>
                  }

                  {!PropInvalida(Filtro.tagId) &&
                    <Tag
                      padding='.5rem'
                    >{Tags.find(e => e.id === Filtro.tagId)?.nome ?? ''}</Tag>
                  }
                </Flex>
              </Flex>
              <FaFilter onClick={() => setDrowerIsOpen(true)} color='var(--c6)' cursor={'pointer'} size={22} />
            </Flex>
          </ContainerFiltro>
        </OutlineContainer>
        <Grid templateColumns={'repeat(5, 1fr)'} mt={'1rem'}>
          <BoxReunioesStatus
            VarColor='Green2'
            statusQuantidade={Model.tabela.filter(e => e.status === 5).length}
            statusNome='Finalizadas'
          />

          <BoxReunioesStatus
            VarColor='Purple1'
            statusQuantidade={Model.tabela.filter(e => e.status === 3).length}
            statusNome='Reagendadas'
          />

          <BoxReunioesStatus
            VarColor='Red'
            statusQuantidade={Model.tabela.filter(e => e.status === 2).length}
            statusNome='Recusadas'
          />

          <BoxReunioesStatus
            VarColor='Gray3'
            statusQuantidade={Model.tabela.filter(e => e.status === 0).length}
            statusNome='Aguardando resposta'
          />

          <BoxReunioesStatus
            VarColor='Orange'
            statusQuantidade={Model.tabela.filter(e => e.status === 4).length}
            statusNome='Atrasadas'
          />

        </Grid>
        <Tabs colorScheme={'blue'} variant='enclosed-colored' mt={'1rem'}>
          <TabList w={'80%'}>
            <Tab bgColor={'#fff'} borderRadius={'8px 8px 0 0'} _selected={{ color: 'var(--Azul)', fontWeight: '700' }} color='var(--Gray2)'>Lista por colaboradores</Tab>
            <Tab bgColor={'#fff'} borderRadius={'8px 8px 0 0'} _selected={{ color: 'var(--Azul)', fontWeight: '700' }} color='var(--Gray2)'>Lista por reunião</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={'0'}>
              <TabListaColaboradores tabela={Model.tabela} />
            </TabPanel>
            <TabPanel p={'0'}>
              <TabListaPorReuniao
                onReenviar={ReenviarConvite}
                onRemarcar={onOpenReagendar}
                onExcluir={onOpenDelete}
                reunioes={Model.tabela}
                individual={false}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </WhiteBox>
    </Body>
  )
}
