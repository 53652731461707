import { Avatar, Flex, Tooltip } from '@chakra-ui/react'
import { FaRegComment, FaUndoAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../../components/Button'
import { Container } from './styles'

interface iTableProps {
  reunioes: iReuniaoHistorico[]
  onOpenFeedback: (texto: string) => void
}

interface iReuniaoHistorico {
  id: string
  nomeParticipante: string
  nomeDepartamento: string
  tipoReuniao: string
  avatar: string
  dataConcluido: string
  feedback: string
  removido: boolean
}

export const TabelaHistorico: React.FC<iTableProps> = ({ reunioes, onOpenFeedback }) => {
  const nav = useNavigate()
  return (
    <Container>
      <thead>
        <tr>
          <th>Colaborador</th>
          <th>Tipo</th>
          <th>Data de conclusão</th>
          <th>Progresso</th>
        </tr>
      </thead>
      <tbody>
        {
          reunioes.map((e) => {
            return (
              <tr key={e.id}>
                <th>
                  <Avatar marginRight={'1rem'} src={e.avatar} size={'md'} name={e.nomeParticipante} />
                  <div>
                    <span>{e.nomeParticipante}</span>
                    <strong>{e.nomeDepartamento}</strong>
                  </div>
                </th>
                <th>{e.tipoReuniao}</th>
                <th>{e.dataConcluido}</th>
                <th>
                  <Flex width={'100%'} alignItems={'center'} justifyContent={'center'}>
                      <Tooltip padding='2'
                        borderRadius='4'
                        placement='top'
                        color={'white'}
                        background={'var(--a4)'} label={'Visualizar feedback'} hasArrow arrowSize={15}
                      >
                        <div onClick={() => onOpenFeedback(e.feedback)}><FaRegComment size={25} /></div>
                      </Tooltip>
                      <Tooltip
                      padding='2'
                      borderRadius='4'
                      placement='top'
                      color={'white'}
                      background={'var(--a4)'} label={'Marcar nova reunião'} hasArrow arrowSize={15}
                      >
                      <div>
                      <Button
                        size={'sm'}
                        width={'2.5rem'}
                        borderRadius='4'
                        color={'white'}
                        background={'var(--terc4)'}
                        isDisabled={e.removido}
                        onClick={() => nav('Configuracao/' + e.id)}><FaUndoAlt size={25} />
                      </Button>
                      </div>
                      </Tooltip>
                  </Flex>
                </th>
              </tr>
            )
          })
        }
      </tbody>
    </Container>
  )
}
