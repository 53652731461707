import styled, { css } from 'styled-components'

interface IContentProps {
  hasBrothers?: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;

  > header {
    width: 100%;
    max-width: 80rem;
    margin: 2rem auto;
    padding: 0 3rem;

    display: flex;
    justify-content: flex-end;

    button + button {
      margin-left: 0.5rem;
    }
  }
`

export const Content = styled.div<IContentProps>`
  margin-bottom: 2rem;

  ${(props) =>
    props.hasBrothers &&
    css`
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 6px solid var(--gray-200);
    `}
`
