/* eslint-disable no-return-assign */
import { Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { ImgSah } from '../../../../components/ImgSah'
import { ModalDelete } from '../../../../components/ModalDelete'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iPesquisaNps } from '../../../../interfaces'
import { SigahApi, urlSigah } from '../../../../services/appApi'
import { ObterToken } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { CardGestao } from './Components/CardGestao'

export const GestaoEnpsJornada: React.FC = () => {
  const [Pesquisas, setPesquisas] = useState<iPesquisaNps[]>([])
  const [TxtPesquisa, setTxtPesquisa] = useState('')

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalEncerrarIsOpen, setModalEncerrarIsOpen] = useState(false)
  const [Id, setId] = useState<string>()

  const [IsLoading, setIsLoading] = useState(false)

  const [Ordenacao, setOrdenacao] = useState('0')
  const [TipoPesquisa, setTipoPesquisa] = useState('')

  function getPesquisas(): void {
    setIsLoading(true)
    SigahApi.get('api/Onboard/eNps/Gestao', {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(res => {
        setPesquisas(res.data)
        setIsLoading(false)
      })
      .catch(err => console.log(err))
  }

  function FilterBy(tipo: number): void {
    const arr = [...Pesquisas]

    if (tipo === 0) {
      arr.sort(function (a, b) {
        return new Date(converterData(b.dataCriacao)) > new Date(converterData(a.dataCriacao)) ? 1 : -1
      })
    } else if (tipo === 1) {
      arr.sort(function (a, b) {
        return new Date(converterData(b.dataCriacao)) > new Date(converterData(a.dataCriacao)) ? 1 : -1
      })
      arr.reverse()
    } else if (tipo === 2) {
      arr.sort((a, b) => {
        return a.descricao.toLowerCase() > b.descricao.toLowerCase() ? 1 : -1
      })
    } else if (tipo === 3) {
      arr.sort((a, b) => {
        return a.descricao.toLowerCase() > b.descricao.toLowerCase() ? 1 : -1
      })
      arr.reverse()
    }
    setPesquisas(arr)
  }

  function onOpenModalDelete(id: string): void {
    setModalDeleteIsOpen(true)
    setId(id)
  }

  function onCloseModalDelete(): void {
    setModalDeleteIsOpen(false)
    setId(undefined)
  }

  function onOpenModalEncerrar(id: string): void {
    setModalEncerrarIsOpen(true)
    setId(id)
  }

  function onCloseModalEncerrar(): void {
    setModalEncerrarIsOpen(false)
    setId(undefined)
  }

  function EncerrarPesquisa(): void {
    SigahApi.post(`api/Onboard/eNps/Encerrar/${Id ?? ''}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function ExcluirPesquisa(): void {
    SigahApi.delete(`api/Onboard/eNps/${Id ?? ''}`, {
      headers: {
        'content-type': 'application/json',
        authorization: ObterToken()
      }
    })
      .then(getPesquisas)
      .catch(err => console.log(err))
  }

  function converterData(val: string): Date {
    const [day, month, year] = val.split('/')
    const date = new Date(+year, +month - 1, +day)
    return date
  }

  useEffect(() => {
    getPesquisas()
  }, [])

  return (
    <Body isLoading={IsLoading}>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        message='Deseja realmente excluir esta pesquisa?'
        onRequestClose={onCloseModalDelete}
        onConfirm={ExcluirPesquisa}
      />

      <ModalDelete
        isOpen={ModalEncerrarIsOpen}
        message='Deseja realmente encerrar esta pesquisa?'
        onRequestClose={onCloseModalEncerrar}
        onConfirm={EncerrarPesquisa}
      />

      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <h1>Jornada {'>'} Pesquisa eNPS</h1>
          <Button
            size={'lg'}
            bg={'var(--Azul)'}
            borderRadius={'12px'}
            _hover={{ bg: 'var(--Azul)' }}
            onClick={() => window.location.href = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}&ct=PesquisaPulse&at=WizNps`}
          >
            Criar nova pesquisa eNPS
          </Button>
        </Flex>
        <Flex margin={'2rem 0'} justifyContent={'space-between'} alignItems={'end'}>
          <Flex gap={'1rem'} width='40%'>
            <FormControl>
              <FormLabel fontSize={'xl'}>Tipo da pesquisa</FormLabel>
                <Select value={TipoPesquisa} onChange={(e) => setTipoPesquisa(e.target.value)} borderColor={'var(--Gray4)'} size='md'>
                  <option value={''}>Todas</option>
                  <option value={'0'}>Normal</option>
                  <option value={'1'}>Onboarding</option>
                </Select>
            </FormControl>

            <FormControl>
              <FormLabel fontSize={'xl'}>Ordenação</FormLabel>
                <Select value={Ordenacao} onChange={(e) => {
                  setOrdenacao(e.target.value)
                  if (e.target.value === '0') {
                    FilterBy(0)
                  } else if (e.target.value === '1') {
                    FilterBy(1)
                  } else if (e.target.value === '2') {
                    FilterBy(2)
                  } else if (e.target.value === '3') {
                    FilterBy(3)
                  }
                }} borderColor={'var(--Gray4)'} size='md'>
                  <option value={'0'}>Pesquisa mais recente</option>
                  <option value={'1'}>Pesquisa mais antiga</option>
                  <option value={'2'}>A-Z</option>
                  <option value={'3'}>Z-A</option>
                </Select>
            </FormControl>
          </Flex>

          <InputGroup width={'30%'}>
            <InputLeftElement
              pointerEvents='none'
              children={<FaSearch color='var(--Gray4)' />}
            />
            <Input
              onChange={(e) => {
                setTxtPesquisa(e.target.value)
                if (TipoPesquisa !== '') {
                  setTipoPesquisa('')
                }
              }}
              borderColor={'var(--Gray4)'}
              type='text'
              placeholder='Pesquisa'
            />
          </InputGroup>
        </Flex>

        <Flex display={'grid'} gap={'2rem 0rem'}>
          {
            Pesquisas.filter((e) => {
              if ((TipoPesquisa === '0' && e.onboarding) || (TipoPesquisa === '1' && !e.onboarding)) {
                return null
              } else if (TxtPesquisa === '') {
                return e
              } else if (e.descricao.toLowerCase().includes(TxtPesquisa.toLowerCase())) {
                return e
              }
              return null
            }).map((e, i) => {
              return (
                <CardGestao
                  key={i}
                  pesquisa={e}
                  onDelete={onOpenModalDelete}
                  encerrarPesquisa={onOpenModalEncerrar}
                />
              )
            })
          }
        </Flex>
        {
          (Pesquisas.length === 0 && !IsLoading) && (
            <ImgSah entidade='pesquisa'>
              <Button
                size={'lg'}
                bg={'var(--Azul)'}
                borderRadius={'12px'}
                _hover={{ bg: 'var(--Azul)' }}
                onClick={() => window.location.href = `${urlSigah}/Home/ExtLogin/?token=${ObterToken()}&ct=PesquisaPulse&at=WizNps`}
              >
                Criar nova pesquisa eNPS
              </Button>
            </ImgSah>
          )
        }
      </WhiteBox>
    </Body>
  )
}
