import styled from 'styled-components'

export const TableDiv = styled.div`
    border-radius:1rem;
table{
    width: 100%;
}

table, th, td {
  border: 0.063rem solid var(--c6);
  border-collapse: collapse;
  font-family: 'Roboto','sans-serif';
}

th,td{
    max-width: 12rem;
    padding: .5rem 1rem .5rem 1rem;
}

td{
    text-align: center;
}

th{
    color: var(--a4);
    font-weight: bold;
}

/* tr:hover{
background: var(--c3);
cursor: pointer;
} */

thead > tr > th:hover{
background: var(--c3);
cursor: pointer;
transition: all .3s ease;

svg{
    margin-left: 8px;
    font-size: 12px;
    transition: all .3s ease;
    opacity: 100%;
}
}

thead > tr > th > div{
display: flex;
align-items: center;
justify-content: center;

svg{
    opacity: 0%;
}
}

tr:not(:first-child){
color: var(--a4);
}
`
