import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { MetaButton } from '../../../../components/Buttons/MetaButton'
import { ModalBody, ModalFooter, ModalHeader } from './styles'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: () => void
  Titulo?: string
}

export const ModalSucess: React.FC<props> = ({ isOpen, onConfirm, onRequestClose, Titulo }) => {
  Modal.setAppElement('#root')
  return (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        overlayClassName='react-modal-overlay'
        className='react-modal-content-default'
    >
        <ModalHeader>
            <h2>{Titulo}</h2>
            <MdClose size={24} onClick={onRequestClose} />
        </ModalHeader>

        <ModalBody>
            <h2>Parabéns,</h2>
            <h2>você concluiu essa etapa!</h2>
            <span>Aguarde a liberação da próxima etapa de seu acompanhamento.</span>
        </ModalBody>

        <ModalFooter>
            <MetaButton onClick={onConfirm} size='md' bg='v2'>Avançar</MetaButton>
        </ModalFooter>
    </Modal>
  )
}
