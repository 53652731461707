/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Flex } from '@chakra-ui/react'
import ApexCharts from 'apexcharts'
import { useEffect, useState } from 'react'
import { iPropsGrafico } from '../..'

interface iProps {
  informacoes: iPropsGrafico
}

export const MediaAvaliacoesChart: React.FC<iProps> = ({ informacoes }) => {
  const [Position, setPosition] = useState('')
  const [OffsetY, setOffsetY] = useState(0)
  const [Orientation, setOrientation] = useState('')

  function AbreviarNomesGrandes(): string[] {
    return informacoes.competencias.map((e) => {
      if (informacoes.competencias.length === 4) {
        return e
      } else if (informacoes.competencias.length > 4 && e.length > 20) {
        return e.substring(0, 20) + '...'
      } else {
        return e
      }
    })
  }

  function getSeries(): any {
    if (informacoes.dados.length < 13) {
      setPosition('top')
      setOrientation('horizontal')
      setOffsetY(-25)
    } else {
      setPosition('top')
      setOffsetY(-35)
      setOrientation('vertical')
    }

    const data = [{
      name: 'Média do departamento',
      data: informacoes.dados
    }]

    return data
  }

  const colors = [
    'rgb(227, 34, 0)',
    'rgb(227, 193, 0)',
    'rgb(0, 227, 150)',
    'rgb(0, 153, 102)'
  ]

  function GerarGrafico(): void {
    const data = {
      texto: `${informacoes.escala}`
    }

    if (informacoes.dados.length === 0) {
      data.texto = 'Não há resultados nesse período'
    }

    const options = {
      colors: colors,
      series: getSeries(),
      chart: {
        type: 'bar',
        height: 450,
        width: '100%'
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          dataLabels: {
            position: Position,
            orientation: Orientation
          },
          colors: {
            ranges: [
              {
                from: 0,
                to: informacoes.fator === 10 ? 2.9 : informacoes.fator === 5 ? 1.45 : 1.74,
                color: colors[0]
              },
              {
                from: informacoes.fator === 10 ? 3 : informacoes.fator === 5 ? 1.5 : 1.8,
                to: informacoes.fator === 10 ? 5.9 : informacoes.fator === 5 ? 2.95 : 3.54,
                color: colors[1]
              },
              {
                from: informacoes.fator === 10 ? 6 : informacoes.fator === 5 ? 3 : 3.6,
                to: informacoes.fator === 10 ? 8.9 : informacoes.fator === 5 ? 4.45 : 5.34,
                color: colors[2]
              },
              {
                from: informacoes.fator === 10 ? 9 : informacoes.fator === 5 ? 4.5 : 5.4,
                to: informacoes.fator === 10 ? 10 : informacoes.fator === 5 ? 5 : 6,
                color: colors[3]
              }
            ]
          }
        }
      },
      title: {
        text: data.texto,
        align: 'center',
        style: {
          fontSize: '16px',
          fontWeight: '700',
          fontFamily: 'Helvetica, Arial, sans-serif',
          color: 'rgb(55, 61, 63)'
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: OffsetY,
        style: {
          fontSize: '12px',
          colors: ['#000']
        },
        formatter: function (val: number) {
          if (val === -1) {
            return '#'
          } else {
            return val.toFixed(2)
          }
        }
      },
      legend: {
        position: 'top',
        onItemClick: {
          toggleDataSeries: false
        }
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: AbreviarNomesGrandes()
      },
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        show: false,
        min: 0,
        max: 10,

        labels: {
          formatter: function(val: number) {
            return val.toFixed(0)
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const chart = new ApexCharts(document.querySelector(`#chart-${informacoes.id}`), options)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.render()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.updateSeries(getSeries())
  }

  useEffect(() => {
    GerarGrafico()
  }, [informacoes])

  return (
      <Flex justifyContent='center' alignItems='center' w={'100%'}>
        <Flex w={'90%'} id={`chart-${informacoes.id}`} />
      </Flex>
  )
}
