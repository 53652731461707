import styled from 'styled-components'

export const OutlineContainer = styled.div`
width: 100%;
border-radius: 20px;
padding: 1rem;
display: flex;
flex-direction: column;
justify-content:center;
border: 1px solid var(--${props => props.theme});
`

export const DashWhiteBox = styled.div`
padding: 1rem;
background: var(--c1);
display: flex;
box-shadow: var(--SombraBackground);
border-radius: 0.5rem;
align-items: center;
`

export const OutlineColumn = styled.div`
display: flex;
flex-direction:column;
padding: 0.375rem;
border: 0.063rem solid var(--c5);
border-radius: 0.625rem;
text-align: center;
margin-right: 1rem;
color: var(--a1);
height: fit-content;
`

export const InfoPDI = styled.div`
text-align: center;
color: var(--Gray4);
`

export const StatusContainer = styled.div`
display: flex;
align-items: center;
hr{
    margin: 0 1rem;
    height: 100%;
}

div:last-child{

strong{
    font-size: 1.25rem;
}

strong:nth-child(1){
color: var(--Orange);
}

strong:nth-child(2){
color: var(--Green2);
}

strong:nth-child(3){
color: var(--Azul);
}
}
`

export const MediaGeralContainer = styled.div`
display: flex;
justify-content: center;
hr{
    margin: 0 1rem;
    height: 100%;
}
& > div:first-child{
    color: var(--Azul);
    
    span{
        font-weight: 500;
    }
}

& > div:last-child{
    color: var(--Gray2);
    span{
        font-weight: 500;
    }
}
`

export const BoxColor = styled.div`
display: flex;
color: var(--${props => props.theme});
`

export const Titulo = styled.h2`
font-family: 'Poppins';
font-weight: 500;
font-size: 28px;
color: #2C4D8A;
margin-bottom: 1rem;
`
