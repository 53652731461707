import { Flex, Text } from '@chakra-ui/react'
import { Button } from '../../../../../../components/Button'
import { ModalBase } from '../../../../../../components/ModalBase'

interface iAcao {
  id: string
  assunto: string
  titulo: string
  descricao: string
  dataEntrega: string
  tempoDedicacao: number
  status: number
  dataInicio: string
  comentario?: string
}

interface iModaProps{
  onRequestClose: () => void
  isOpen: boolean
  acao?: iAcao
}

export const ModalVisualizarComentarios: React.FC<iModaProps> = ({ isOpen, acao, onRequestClose }) => {
  return (
        <ModalBase Titulo='Detalhes dessa ação' isOpen={isOpen} onClose={onRequestClose} Width='60rem'>
            <Flex flexDir={'column'} padding={'1rem'}>
              <Text color={'var(--Gray2)'} fontWeight='500' fontSize={'1.25rem'}>{acao?.assunto}</Text>
              <Flex gap={'2rem'} marginBottom={'1rem'}>
                <Text color={'var(--Gray2)'} fontWeight='500' fontSize={'1.25rem'}>Data de inicio: {acao?.dataInicio}</Text>
                <Text color={'var(--Gray2)'} fontWeight='500' fontSize={'1.25rem'}>Data de fim: {acao?.dataEntrega}</Text>
              </Flex>
              <Flex flexDir={'column'} marginBottom='1rem'>
                <Text color={'var(--Gray2)'} fontWeight='700' fontSize={'1.5rem'}>{acao?.titulo}</Text>
                <Text color={'var(--Gray2)'} fontWeight='400' fontSize={'1.125rem'}>{acao?.descricao}</Text>
              </Flex>
              <Flex flexDir={'column'}>
                <Text color={'var(--Gray2)'} fontWeight='700' fontSize={'1.5rem'}>Comentário</Text>
                <Text color={'var(--Gray2)'} fontWeight='400' fontSize={'1.125rem'}>{acao?.comentario}</Text>
              </Flex>
            </Flex>
            <Flex padding={'1rem'} justifyContent={'end'}>
              <Button onClick={onRequestClose} VarColor='c4'>Fechar</Button>
            </Flex>
        </ModalBase>
  )
}
