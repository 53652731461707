/* eslint-disable no-return-assign */
/* eslint-disable no-tabs */
import { Body } from '../../Layouts/Body'
import { CardParticipante } from '../ParticipantesIndicativa/Components/CardParticipante'
import { OutlineGreyBox } from '../components/OutlineGreyBox'
import { ModalAdicionarAvaliado } from '../ParticipantesIndicativa/Components/ModalAdicionarAvaliado'
import { ModalCadastroEmMassa } from '../ParticipantesIndicativa/Components/ModalCadastroEmMassa'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appApi, urlApi } from '../../../services/appApi'
import { iAvaliado, iPostAvaliador } from '../../../interfaces'
import { ModalAddParticipante } from '../ParticipantesIndicativa/Components/ModalAddParticipante'
import { Flex, Input, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react'
import { Button } from '../../../components/Button'
import { ModalDelete } from '../../../components/ModalDelete'
import { ObterToken, PropInvalida } from '../../../Utils/Helper'
import { ModalAdicionarPorSquad } from '../ParticipantesIndicativa/Components/ModalAdicionarPorSquad'
import { FaChevronRight, FaFileCsv, FaSearch } from 'react-icons/fa'
import { TimelineCriarPesquisa } from '../components/TimelineCriarPesquisa'
import { AlertText, GridColumn, InfoBox } from '../ParticipantesIndicativa/styles'
import { BsFillFileEarmarkArrowDownFill } from 'react-icons/bs'
import { MdGroups } from 'react-icons/md'

type navProps = {
  pesquisaId: string
}

interface ISetAvaliador {
  avaliacaoId: string
  avaliadorId: string
}

interface iModel {
  nomePesquisa: string
  pesquisaIniciada: boolean
  ciclo?: string
  avaliados: iAvaliado[]
  formularioAprovado: boolean
  enviado: boolean
}

export const PesquisaParticipantesIndicativa: React.FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { pesquisaId } = useParams<navProps>()
  const { pathname } = useLocation()

  const [Avaliador, setAvaliador] = useState<ISetAvaliador>()

  const [ModalDeleteIsOpen, setModalDeleteIsOpen] = useState(false)
  const [ModalDeleteAvaliacaoIsOpen, setModalDeleteAvaliacaoIsOpen] = useState(false)
  const [ModalAddAvaliadoIsOpen, setModalAddAvaliadoIsOpen] = useState(false)
  const [ModalAddMassaIsOpen, setModalAddMassaIsOpen] = useState(false)
  const [ModalAddParticipanteIsOpen, setModalAddParticipanteIsOpen] = useState(false)
  const [EditMode, setEditMode] = useState(false)

  const [ModalAddSquadIsOpen, setModalAddSquadIsOpen] = useState(false)
  const [Tipo, setTipo] = useState<number>(0)
  const [AvaliacaoId, setAvaliacaoId] = useState<string>('')
  const [AvaliadorId, setAvaliadorId] = useState<string>('')

  const [Search, setSearch] = useState('')

  const [Model, setModel] = useState<iModel>({
    avaliados: [],
    pesquisaIniciada: false,
    nomePesquisa: '',
    formularioAprovado: false,
    enviado: false
  })

  function getAvalidos(): void {
    appApi.get(`AvaliacaoPesquisa/PesquisaIndicativa/${pesquisaId as string}`)
      .then(res => {
        if (res.status === 200) {
          setModel(res.data)
        } else {
          setModel({
            avaliados: [],
            nomePesquisa: '',
            pesquisaIniciada: false,
            formularioAprovado: false,
            enviado: false
          })
        }
      })
      .catch(err => console.log(err))
  }

  function DeleteAvaliacao(): void {
    appApi.delete(`AvaliacaoPesquisa/${AvaliacaoId}`)
      .then(() => getAvalidos())
      .catch(err => console.log(err))
  }

  function DeleteAvaliador(): void {
    appApi.delete(`AvaliacaoPesquisa/${Avaliador?.avaliacaoId ?? ''}/avaliador/${Avaliador?.avaliadorId ?? ''}`)
      .then(() => getAvalidos())
      .catch(err => console.log(err))
  }

  function CreateAvaliador(form: iPostAvaliador): void {
    form.TipoAvaliador = Tipo
    appApi.post(`AvaliacaoPesquisa/${AvaliacaoId}/avaliador`, form)
      .then(() => {
        getAvalidos()
        onCloseModalAddParticipante()
      })
      .catch(() => {
        toast({
          title: 'Há campos sem preenchimento',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
      })
  }

  function ReenviarConviteAvaliador(avaliacaoId: string, avaliadorId?: string): void {
    appApi.post(`AvaliacaoPesquisa/${AvaliacaoId}/Reenviar?avaliadorId=${avaliadorId ?? ''}`)
      .then(() => {
        toast({
          title: 'Reenviado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
        onCloseModalAddParticipante()
      })
      .catch(() => {
        toast({
          title: 'Erro',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
      })
  }

  function AddAvaliacaoPorNome(avaliadoId: string): void {
    if (Model?.avaliados.map(e => e.colaboradorId).includes(avaliadoId)) {
      toast({
        title: 'Avaliado já adicionado',
        position: 'top-right',
        status: 'warning',
        duration: 2000,
        isClosable: false
      })
    } else {
      const form = {
        pesquisaId: pesquisaId as string,
        colaboradorId: avaliadoId
      }

      appApi.post('AvaliacaoPesquisa', form).then(() => {
        getAvalidos()
        toast({
          title: 'Avaliado adicionado com sucesso',
          position: 'top-right',
          status: 'success',
          duration: 2000,
          isClosable: false
        })
      }
      ).catch(err => console.log(err))
    }
  }

  function onOpenModalAddParticipante(tipo: number, avaliacaoId: string): void {
    setModalAddParticipanteIsOpen(true)
    setTipo(tipo)
    setAvaliacaoId(avaliacaoId)
  }

  function onCloseModalAddParticipante(): void {
    setModalAddParticipanteIsOpen(false)
    setTipo(0)
    console.log('1')
    setAvaliacaoId('')
    setEditMode(false)
  }

  function onOpenModalDeletarAvaliacao(id: string): void {
    setModalDeleteAvaliacaoIsOpen(true)
    setAvaliacaoId(id)
  }

  function onOpenModalDeleteAvaliador(avaliacaoId: string, avaliadorId: string): void {
    setModalAddParticipanteIsOpen(false)
    setModalDeleteIsOpen(true)
    setAvaliador({
      avaliacaoId: avaliacaoId,
      avaliadorId: avaliadorId
    })
  }

  function onRequestModalDeleteClose(): void {
    setModalDeleteIsOpen(false)
    setAvaliador(undefined)
    console.log('2')
    setAvaliacaoId('')
  }

  function onRequestModalDeleteAvaliacaoClose(): void {
    setModalDeleteAvaliacaoIsOpen(false)
    console.log('3')
    setAvaliacaoId('')
  }

  function CriarPorSquad(squadId: string, opcao: string): void {
    appApi.post(`AvaliacaoPesquisa/Squad?pesquisaId=${pesquisaId ?? ''}&squadId=${squadId}&tipoHierarquia=${opcao}`)
      .then(() => {
        getAvalidos()
        setModalAddSquadIsOpen(false)
      })
      .catch(() => {
        toast({
          title: 'Há campos sem preenchimento',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 2000
        })
      })
  }

  useEffect(() => {
    getAvalidos()
  }, [])

  return (
    <Body>
      <ModalDelete
        isOpen={ModalDeleteIsOpen}
        onRequestClose={onRequestModalDeleteClose}
        onConfirm={DeleteAvaliador}
        message={'Deseja realmente excluir este avaliador?'}
      />

      <ModalDelete
        isOpen={ModalDeleteAvaliacaoIsOpen}
        onRequestClose={onRequestModalDeleteAvaliacaoClose}
        onConfirm={DeleteAvaliacao}
        message={'Deseja realmente excluir?'}
      />
      {
        (ModalAddParticipanteIsOpen) && (
          <ModalAddParticipante
            isOpen={ModalAddParticipanteIsOpen}
            onConfirm={CreateAvaliador}
            onRequestClose={onCloseModalAddParticipante}
            iniciada={Model.pesquisaIniciada}
            editMode={EditMode}
            onDeleteAvaliador={() => onOpenModalDeleteAvaliador(AvaliacaoId, AvaliadorId)}
            onReenviarAvaliador={() => ReenviarConviteAvaliador(AvaliacaoId, AvaliadorId)}
          />
        )
      }

      {
        (ModalAddSquadIsOpen) && (
          <ModalAdicionarPorSquad
            isOpen={ModalAddSquadIsOpen}
            onConfirm={CriarPorSquad}
            onRequestClose={() => setModalAddSquadIsOpen(false)}
          />
        )
      }

      <ModalAdicionarAvaliado
        isOpen={ModalAddAvaliadoIsOpen}
        onConfirm={AddAvaliacaoPorNome}
        onRequestClose={() => setModalAddAvaliadoIsOpen(false)}
      />

      <ModalCadastroEmMassa
        onRequestClose={() => setModalAddMassaIsOpen(false)}
        isOpen={ModalAddMassaIsOpen}
        reload={getAvalidos}
      />

      <TimelineCriarPesquisa color={['var(--Green2)', 'var(--Alaranjado)', 'var(--terc9)']} />
      <Flex justifyContent={'space-between'} flexWrap='wrap' marginTop={'1rem'}>
        <Flex flexDir={'column'}>
          <h2 style={{ color: 'var(--a3)' }}>Selecione os participantes de sua pesquisa</h2>
          <Flex gap={'1rem'} marginTop={'.5rem'}>
            <Button
              VarColor='Azul'
              onClick={() => setModalAddAvaliadoIsOpen(true)}
              leftIcon={<MdGroups size={26}/>}
            >Adicionar avaliados
            </Button>

            <Button
              VarColor='Azul'
              onClick={() => setModalAddMassaIsOpen(true)}
              leftIcon={<FaFileCsv />}
            > Adicionar em massa</Button>

            <Button
              VarColor='Azul'
              onClick={() => window.location.href = `${urlApi}/AvaliacaoPesquisa/${pesquisaId ?? ''}/Participantes/Csv/?token=${ObterToken()}`}
              leftIcon={<BsFillFileEarmarkArrowDownFill />}
            >Exportar participantes
            </Button>
          </Flex>
        </Flex>
        <InfoBox>
          <span>{Model.avaliados?.length}</span>
          <h3>Avaliados adicionados</h3>
        </InfoBox>
      </Flex>

      <h2 style={{ color: 'var(--a3)', marginBottom: '.5rem' }}>Participantes selecionados</h2>

      <OutlineGreyBox>
        {
          (Model.avaliados?.length === 0) && (
            <Flex justifyContent={'center'}>
              <AlertText>Nenhum participante selecionado</AlertText>
            </Flex>
          )
        }

        {
          (Model.avaliados?.length > 0) && (
            <GridColumn>
              <Flex>
                <InputGroup width={'17rem'}>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<FaSearch color='var(--Gray4)' />}
                  />
                  <Input
                    onChange={(e) => setSearch(e.target.value)}
                    borderColor={'var(--Gray4)'}
                    type='text'
                    placeholder='Digite o nome do avaliado'
                    bg={'white'}
                  />
                </InputGroup>
              </Flex>
              {
                Model.avaliados.filter((e) => {
                  if (Search === '') {
                    return e
                  } else if (e.nome.toLowerCase().includes(Search.toLowerCase())) {
                    return e
                  } else {
                    return null
                  }
                }).map((e: iAvaliado) => {
                  return (
                    <CardParticipante
                      key={e.id}
                      ciclica={!PropInvalida(Model.ciclo)}
                      enviado={Model.enviado}
                      Avaliacao={e}
                      onDeleteAvaliacao={onOpenModalDeletarAvaliacao}
                      onOpenModal={onOpenModalAddParticipante}
                      onEdit={(e, i) => { setAvaliadorId(i); setEditMode(true); setModalAddParticipanteIsOpen(true) }}
                      onReenviarAvaliador={() => ReenviarConviteAvaliador(AvaliacaoId, AvaliadorId)}
                    />
                  )
                })
              }
            </GridColumn>
          )
        }
      </OutlineGreyBox>

      <Flex justifyContent={'end'} mt={'1.5rem'} gap={'1rem'}>
        <Button h={'3rem'} VarColor='Gray3' onClick={() => navigate(-1)}>
          Voltar
        </Button>
        <Button
          h={'3rem'}
          onClick={() => navigate(`${pathname.replace('Participantes', 'Textos')}`)}
          size={'lg'}
          VarColor={'v2'}
          rightIcon={<FaChevronRight />}
        >Continuar</Button>
      </Flex>
    </Body>
  )
}
