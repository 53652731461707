import { Avatar, Box, Flex, Text, Tooltip } from '@chakra-ui/react'
import { RiSendPlaneFill } from 'react-icons/ri'
import { Button } from '../../../../../../components/Button'
import { Legenda } from '../../../Configuracao/styles'
import { iAvatarXInformacoes } from '../../../Dashboard/Individual'

interface iProps{
  participante: iAvatarXInformacoes
  host: boolean
  blockReenviar?: boolean
  onReenviar?: () => void
}

export const CardFeedback: React.FC<iProps> = ({ participante, host, blockReenviar, onReenviar }) => {
  return (
        <Flex gap={'.5rem'} position={'relative'}>
            <Avatar src={participante.avatar} name={participante.info1} size={'sm'} />
            <Flex alignItems={'center'} gap={'.3rem'}>
                <Box>
                    <Text mb={'-.5rem'} fontSize={'14px'} fontWeight={'400'}>{participante.info1}</Text>
                    <Legenda>{participante.info2}</Legenda>
                </Box>
                {!participante.info2?.includes('Respondido') && !host &&
                    <Tooltip
                        hasArrow bg={'var(--Azul)'}
                        color={'white'}
                        p={'.5rem'}
                        mr={'.4rem'}
                        position={'absolute'}
                        placement={'top'}
                        label={blockReenviar ? 'O formulário ainda não está liberado para o preenchimento' : 'Reenviar convite de feedback'}
                    >
                        <Box
                            position={'relative'}
                            onClick={onReenviar}
                        >
                            <Button
                                VarColor='Blue2'
                                fontSize={'10px'}
                                isDisabled={blockReenviar}
                                p={'0rem'}
                                h={'1.6rem'}><RiSendPlaneFill />
                            </Button>
                        </Box>
                    </Tooltip>
                }
            </Flex>
        </Flex>
  )
}
