/* eslint-disable no-tabs */
import { Box, Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { Button } from '../../../../../components/Button'

interface iProps {
  isOpen: boolean
  onRedirect: () => void
}

export const ModalConfirm: React.FC<iProps> = ({ isOpen, onRedirect }) => {
  return (
		<Modal isOpen={isOpen} onClose={onRedirect}>
			<ModalOverlay />
			<ModalContent marginTop={'20rem'} borderRadius={'1rem'} h={'8rem'} w={'24rem'} textAlign={'center'} justifyContent={'center'}>
				<Box>
					<Text mb={'1rem'} as={'h3'}>Respostas enviadas</Text>
					<Button VarColor='Green2' w={'5rem'} onClick={onRedirect}>OK</Button>
				</Box>
			</ModalContent>
		</Modal>
  )
}
