import { Flex } from '@chakra-ui/react'
import { FaEdit, FaEllipsisV, FaTrash } from 'react-icons/fa'
import { Button } from '../../../../../components/Button'
import { useFormulario } from '../../../../../contexts/FormularioContext'
import { iCompetencia } from '../../../../../interfaces'
import { ImgText, Container, Menu, DropMenu } from './styles'

interface CardProps {
  Competencia: iCompetencia
  Selecionado: boolean
  onClickPreVisualizar: () => void
  Edit: () => void
  onDeleteCompetencia: (competenciaId: string) => void
}

export const CardCompetencia: React.FC<CardProps> = ({ onClickPreVisualizar, Edit, onDeleteCompetencia, Selecionado, Competencia }) => {
  const { SelectCompetencia } = useFormulario()

  return (
    <Container border={Selecionado ? 'v2' : 'c1'}>
      {
        !Competencia.doSistema
          ? <Flex justifyContent={'end'}>
            <Menu>
              <FaEllipsisV color='var(--a3)' />
              <Flex width={'5.5rem'}>
                <DropMenu>
                  <li onClick={() => Edit()} style={{ color: 'var(--terc10)' }}><FaEdit /> Editar</li>
                  <li onClick={(() => onDeleteCompetencia(Competencia.id))} style={{ color: 'var(--terc3)' }}><FaTrash /> Excluir</li>
                </DropMenu>
              </Flex>
            </Menu>
          </Flex>
          : <Flex marginTop={'1rem'}></Flex>
      }

      <ImgText>
        <img src={Competencia.icone}/>
        <span>{Competencia.nome}</span>
      </ImgText>
      <Flex flexDir={'column'} gap={'.5rem'} marginTop={'.7rem'} >
        <Button size={'xs'} fontWeight={'normal'} width={'100%'} VarColor='vg3' onClick={() => onClickPreVisualizar()}>Pré-visualizar questões</Button>
        {
          !Selecionado
            ? <Button
              size='xs'
              VarColor='v2' onClick={() => SelectCompetencia(Competencia.id)}
              >Adicionar</Button>
            : <Button
              onClick={() => SelectCompetencia(Competencia.id)}
              size='xs'
              variant={'outline'}
              colorScheme={'green'}>Remover</Button>
        }
      </Flex>
    </Container>
  )
}
