import styled from 'styled-components'

interface iProps{
  width: string
  heigth: string
  isSelect?: boolean
}

export const EixoNineBox = styled.div<iProps>`
  width: ${props => props.width};
  background:${props => props.theme};
  height: ${props => props.heigth};
  border-radius: 1rem;
  gap: .25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input{
    color: white;
    width: 10rem;
  }

  ${({ isSelect }) => !isSelect && `
    opacity:.3;
  `}
`
