import { Flex, Box, Avatar, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Textarea, MenuButton, Link, Menu, MenuItem, MenuList, Button, Image, FormLabel, FormControl, useToast, Text, Grid } from '@chakra-ui/react'
import { FaSave } from 'react-icons/fa'
import { NavLine } from '../../../../components/NavLine'
import { ColumnTexts } from '../../../../components/PerfilColaborador/styles'
import { WhiteBox } from '../../../../components/WhiteBox'
import { Body } from '../../../Layouts/Body'
import { DashBox } from './styles'
import { useEffect, useState } from 'react'
import { NineBox } from '../../../../components/NineBox'
import { iNineBoxAvaliacao, iNomeId, iPerfilColaborador } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { PropInvalida } from '../../../../Utils/Helper'
import { iPI } from '../../../../components/PerfilColaborador/indesx'
import { useNavigate, useParams } from 'react-router'
import { iPostAvliacao } from '../../../RH/VisualizarTrilhaColaborador/Components/TabNineBox'
import { MultiColaborador } from '../../../../components/MultiColaborador'
import { parseJwt } from '../../../../services/token'

interface iNota {
  notaY: number
  notaX: number
}

export const UsuarioEixo: React.FC = () => {
  const isAdm = parseJwt().adm === 'True'
  const nav = useNavigate()
  const toast = useToast()
  const { colaboradorId } = useParams<{ colaboradorId: string }>()

  const [Model, setModel] = useState<iNineBoxAvaliacao>({
    comiteAtivo: false,
    nineBox: {
      id: '',
      padrao: false,
      areas: [],
      eixosX: [],
      eixosY: [],
      quadrantes: [],
      tags: [],
      squads: [],
      tipoMeta: 0,
      titulo: '',
      configuracaoEixoX: {
        descricao: '',
        titulo: ''
      },
      configuracaoEixoY: {
        descricao: '',
        titulo: ''
      }
    },
    comites: []
  })

  const [PI, setPI] = useState<iPI>()

  const [Notas, setNotas] = useState<iNota>({
    notaX: 0,
    notaY: 0
  })
  const [Colaborador, setColaborador] = useState<iPerfilColaborador>()

  const [NomeQuadrante, setNomeQuadrante] = useState<string>()
  const [NotaXInconentente, setNotaXInconentente] = useState(false)
  const [NotaYInconentente, setNotaYInconentente] = useState(false)

  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Comite, setComite] = useState<string[]>([])
  const [Comentario, setComentario] = useState<string>()

  const [VisualizarTudo, setVisualizarTudo] = useState(false)

  function obterColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarQuadrante(nome: string, notaX?: number, notaY?: number): void {
    setNomeQuadrante(nome)
    VerificarIncoerencia(nome, notaX, notaY)
  }

  function VerificarIncoerencia(nome: string, notaX?: number, notaY?: number): void {
    setNotaXInconentente(false)
    setNotaYInconentente(false)
    const quadrante = Model.nineBox.quadrantes.find(e => e.titulo === nome)
    if (quadrante) {
      const configx = Model.nineBox.eixosX.find(e => e.id === quadrante.eixoX)
      const configy = Model.nineBox.eixosY.find(e => e.id === quadrante.eixoY)
      if (notaX && notaY && configx && configy) {
        if (!(notaX >= configx?.rangeMinimo && notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(notaY >= configy?.rangeMinimo && notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      } else if (configx && configy) {
        if (!(Notas.notaX >= configx?.rangeMinimo && Notas.notaX <= configx?.rangeMaximo)) {
          setNotaXInconentente(true)
        }
        if (!(Notas.notaY >= configy?.rangeMinimo && Notas.notaY <= configy?.rangeMaximo)) {
          setNotaYInconentente(true)
        }
      }
    }
  }

  function AlterarNota(notas: iNota): void {
    setNotas(notas)
    const configY = Model.nineBox.eixosY.find(r => notas.notaY >= r.rangeMinimo && notas.notaY <= r.rangeMaximo)
    const configX = Model.nineBox.eixosX.find(r => notas.notaX >= r.rangeMinimo && notas.notaX <= r.rangeMaximo)
    if (configY && configX) {
      const quadrante = Model.nineBox.quadrantes.find(r => r.eixoX === configX.id && r.eixoY === configY.id)
      if (quadrante) {
        setNomeQuadrante(quadrante.titulo)
        if (NotaXInconentente || NotaYInconentente) {
          VerificarIncoerencia(quadrante.titulo)
        }
      }
    }
  }

  function obterColaborador(): void {
    appApi.get(`Colaborador/${colaboradorId ?? ''}/Perfil`)
      .then(res => setColaborador(res.data))
      .catch(err => console.log(err))
  }

  function getPerfilPI(): void {
    appApi.get(`https://sah.icecloud.com.br/api/integracao/sahpi/?email=${Colaborador?.email ?? ''}`)
      .then(res => {
        setPI(res.data)
      }).catch(err => {
        console.log(err)
      })
  }

  function ObterNineBox(): void {
    appApi.get(`NineBox/Colaborador/${colaboradorId ?? ''}`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function AvaliarColaborador(): void {
    const form: iPostAvliacao = {
      boxId: Model.nineBox.id,
      colaboradorId: Colaborador?.id ?? '',
      comentario: Comentario,
      comite: Comite,
      notaX: Notas.notaX,
      notaY: Notas.notaY,
      quandranteId: Model.nineBox.quadrantes.find(e => e.titulo === NomeQuadrante)?.id ?? ''
    }

    appApi.post('Colaborador/NineBox', form)
      .then(() => {
        if (PropInvalida(Colaborador?.avaliacaoNineBox)) {
          toast({
            title: 'Avaliação computada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        } else {
          toast({
            title: 'Avaliação alterada com sucesso',
            status: 'success',
            isClosable: false,
            position: 'top-right'
          })
        }
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    obterColaborador()
    obterColaboradores()
    ObterNineBox()
  }, [])

  useEffect(() => {
    if (PropInvalida(Colaborador?.perfilPI)) {
      getPerfilPI()
    }
    if (!PropInvalida(Colaborador?.avaliacaoNineBox)) {
      setNotas({
        notaX: Colaborador?.avaliacaoNineBox?.notaX ?? 0,
        notaY: Colaborador?.avaliacaoNineBox?.notaY ?? 0
      })
      SelecionarQuadrante(Colaborador?.avaliacaoNineBox?.tituloQuadrante ?? '', Colaborador?.avaliacaoNineBox?.notaX, Colaborador?.avaliacaoNineBox?.notaY)
    }
  }, [Colaborador])

  return (
    <Body>
      <NavLine
        options={[
          { nome: 'Desempenho' },
          { nome: '9Box', path: '/Desempenho/9box' },
          { nome: 'Enigma', path: -1 },
          { nome: Colaborador?.nome ?? '' }]
        }
      />
      <Flex mt={'1.5rem'} justifyContent={'space-between'}>
        <Box as='h1' color={'var(--a4)'} flex={'1'}>9Box</Box>
      </Flex>
      <WhiteBox styles={{ marginTop: '2rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
        <Button onClick={() => nav(-1)} bg='var(--Gray4)' my={'1rem'} w={'7.375rem'}>Voltar</Button>
        <Flex justifyContent={'space-between'}>
          <Flex marginBottom={'2rem'} alignItems={'center'} w={'100%'}>
            <Avatar
              marginRight={'1rem'}
              size={'2xl'}
              name={'nome'}
              src={Colaborador?.avatar}
            />
            <ColumnTexts>
              <h3>{Colaborador?.nome}</h3>
              <span>Área: {Colaborador?.nomeDepartamento}</span>
              <span>Função: {Colaborador?.nomeCargo}</span>
              <span>{Colaborador?.email}</span>
              {Colaborador && <span>{Colaborador?.celular}</span>}
            </ColumnTexts>
          </Flex>
          {!PropInvalida(Colaborador?.perfilPI) &&
            <DashBox theme={'terc1'}>
              <Flex gap={'1rem'} flexDir={'column'} alignItems={'center'}>
                <Flex gap={'1rem'}>
                  <Image width={'4rem'} src={Colaborador?.perfilPI?.urlSelo} />
                  <Image width={'10rem'} src={Colaborador?.perfilPI?.urlSelf} />
                </Flex>
                <Menu>
                  <MenuButton as={Button} bgColor='var(--vg4)' _hover={{ bgColor: 'white', color: 'var(--vg4)', border: '2px', borderColor: 'var(--vg4)' }}>
                    Baixar Relatorios
                  </MenuButton>
                  <MenuList bgColor='var(--vg4)'>
                    {
                      (!PropInvalida(Colaborador?.perfilPI?.urlResultado)) && (
                        <MenuItem>
                          <Button as={Link} borderColor='var(--vg4)'
                            href={Colaborador?.perfilPI?.urlResultado}
                            color='var(--vg4)'>
                            Perfil comportamental
                          </Button>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Colaborador?.perfilPI?.urlQuadro)) && (
                        <MenuItem>
                          <Button as={Link} borderColor='var(--vg4)'
                            href={Colaborador?.perfilPI?.urlQuadro}
                            color='var(--vg4)'>
                            Quadro desenvolvimento pessoal
                          </Button>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Colaborador?.perfilPI?.urlPlacard)) && (
                        <MenuItem>
                          <Button as={Link} borderColor='var(--vg4)'
                            href={Colaborador?.perfilPI?.urlPlacard}
                            color='var(--vg4)'>
                            Placard
                          </Button>
                        </MenuItem>
                      )
                    }

                    {
                      (!PropInvalida(Colaborador?.perfilPI?.urlQuadroGestor)) && (
                        <MenuItem>
                          <Button as={Link} borderColor='var(--vg4)'

                            href={Colaborador?.perfilPI?.urlQuadroGestor}
                            color='var(--vg4)'>
                            Quadro desenvolvimento gestores
                          </Button>
                        </MenuItem>
                      )
                    }

                  </MenuList>
                </Menu>
              </Flex>
            </DashBox>
          }

          {PropInvalida(Colaborador?.perfilPI) && PI && !PI.hide && (
            <DashBox theme={'terc3'}>
              <Flex gap={'1rem'} flexDirection={'column'}>
                <Flex gap={'1rem'} justifyContent={'space-around'}>
                  {
                    (PI.urlSelo) && (
                      <Image width={'4rem'} src={PI.urlSelo} />
                    )
                  }
                  {
                    (PI.assessmentId !== undefined) && (
                      <Image width={'10rem'} src={`https://sah.icecloud.com.br/api/Files?id=${PI.assessmentId}&tipo=2`} />
                    )
                  }
                </Flex>
                <Menu>
                  <MenuButton as={Button} bgColor='var(--vg4)' _hover={{ bgColor: 'white', color: 'var(--vg4)', border: '2px', borderColor: 'var(--vg4)' }}>
                    Baixar Relatorios
                  </MenuButton>
                  <MenuList bgColor='var(--vg4)'>
                    <MenuItem>
                      <Button as={Link} borderColor='var(--vg4)'
                        href={`https://sah.icecloud.com.br/api/Files/api/pdf?id=${PI.assessmentId}&tipo=2`}
                        color='var(--vg4)'>
                        Perfil comportamental
                      </Button>
                    </MenuItem>

                    <MenuItem>
                      <Button as={Link} borderColor='var(--vg4)'
                        href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=2`}
                        color='var(--vg4)'>
                        Quadro desenvolvimento pessoal
                      </Button>
                    </MenuItem>

                    <MenuItem>
                      <Button as={Link} borderColor='var(--vg4)'
                        href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=3`}
                        color='var(--vg4)'>
                        Placard
                      </Button>

                    </MenuItem>
                    <MenuItem>
                      <Button as={Link} borderColor='var(--vg4)'

                        href={`https://sah.icecloud.com.br/api/Files/api/pdf2?id=${PI.assessmentId}&tipo=4`}
                        color='var(--vg4)'>
                        Quadro desenvolvimento gestores
                      </Button>
                    </MenuItem>

                  </MenuList>
                </Menu>
              </Flex>
            </DashBox>
          )}
        </Flex>
        <Flex my={'1.5rem'} gap={'1rem'}>
          <Flex flexDirection={'column'}>
            <Box mb={'.5rem'} fontWeight={'700'}>
              {Model.nineBox.configuracaoEixoY.titulo}
            </Box>
            <NumberInput
              isInvalid={NotaYInconentente}
              onChange={(e) => AlterarNota({ ...Notas, notaY: parseFloat(e) })}
              value={Notas.notaY} width={'5.9rem'}
              min={0}
              max={200}
              step={0.1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>
          <Flex flexDirection={'column'}>
            <Box mb={'.5rem'} fontWeight={'700'}>
              {Model.nineBox.configuracaoEixoX.titulo}
            </Box>
            <NumberInput
              isInvalid={NotaXInconentente}
              onChange={(e) => AlterarNota({ ...Notas, notaX: parseFloat(e) })}
              width={'5.9rem'}
              value={Notas.notaX}
              step={0.1}
              min={0}
              max={200}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Flex>
        </Flex>
        <Box mb={'2rem'} fontWeight={'700'}>
          Definição padrão
        </Box>
        <Flex ml={'3.125rem'} flexDirection={'column'} width={'35.625rem'} alignItems={'center'}>
          <NineBox
            quadranteSelecionado={NomeQuadrante}
            selecionarQuadrante={SelecionarQuadrante}
            model={Model.nineBox}
            variant='avaliar'
          />
        </Flex>

        <Flex gap={'1rem'} flexDir={'column'} mb='1rem'>
          {Model.comites.slice(0, VisualizarTudo ? Model?.comites?.length : 2).map((r, i) => {
            return (
              <Flex flexDir={'column'} borderRadius={'1rem'} border={'1px solid var(--Gray4)'} padding='1rem' key={i}>
                <Text color={'var(--Gray1)'} fontWeight={'bold'} fontSize={'0.75rem'}>Alterado por {r.responsavel.nome} em {r.dataAvaliacao}</Text>
                {PropInvalida(r.avaliacaoAnterior) && (
                    <Text
                      color={'var(--Gray1)'}
                      fontWeight={'bold'}
                      fontSize={'0.75rem'}
                    >Avaliou no quadrante {r.avaliacaoAtual}
                    </Text>
                )
                }
                {!PropInvalida(r.avaliacaoAnterior) &&
                  <Text
                    color={'var(--Gray1)'}
                    fontWeight={'bold'}
                    fontSize={'0.75rem'}
                  >Alterou de {r.avaliacaoAnterior} para {r.avaliacaoAtual}
                  </Text>
                }
                <Flex flexDir={'column'} my={'.5rem'} padding={'1rem'} bg='var(--Gray6)'>
                  <Text fontWeight={'700'} fontSize={'0.875rem'} color={'var(--Gray1)'}>Comentário</Text>
                  <Text>{r.comentario}</Text>
                </Flex>

                <Text mb={'.5rem'} fontWeight={'700'}>Comitê que realizou a calibração</Text>
                <Grid gap={4} templateColumns='repeat(3, 1fr)'>
                  {r.comite.map((c, i2) => {
                    return (
                      <Flex gap={'1rem'} key={i2}>
                        <Avatar size={'sm'} src={c.avatar} name={c.nome} />
                        <Flex flexDir={'column'}>
                          <Text>{c.nome}</Text>
                          <Text>{c.complemento}</Text>
                        </Flex>
                      </Flex>
                    )
                  })}
                </Grid>
              </Flex>
            )
          })}
        </Flex>
        {!VisualizarTudo && Model?.comites?.length > 2 &&
          <Text cursor={'pointer'} onClick={() => setVisualizarTudo(true)} fontWeight={'700'} textDecoration='underline' color={'var(--Azul)'}>Mostrar alterações anteriores </Text>
        }

        {isAdm &&
          <Flex flexDir={'column'}>
            <FormControl>
              <FormLabel>Deixe um comentário para registro da calibração</FormLabel>
              <Textarea
                color={'var(--Gray2)'}
                placeholder='Deixe seu comentário'
                height={'8.25rem'}
                borderColor='var(--Gray4)'
                onChange={(e) => setComentario(e.target.value)}
              />
            </FormControl>

            {!Model.comiteAtivo && <FormControl marginTop={'1rem'}>
              <FormLabel>Participantes do comite</FormLabel>
              <MultiColaborador
                colaboradores={Colaboradores}
                selecionados={Comite}
                onDelete={(e) => setComite([...Comite.filter(t => t !== e)])}
                onChoice={(e) => !PropInvalida(e) && setComite([...Comite])}
              />
            </FormControl>}

            <Flex justifyContent={'flex-end'} gap={'1rem'} my={'2rem'}>
              <Button
                bg='var(--Gray4)'
                px={'2rem'}
                onClick={() => nav(-1)}
              >Cancelar</Button>
              <Button
                bg={'var(--Green2)'}
                leftIcon={<FaSave />}
                px={'2rem'}
                onClick={AvaliarColaborador}
              >{PropInvalida(Colaborador?.avaliacaoNineBox) ? 'Salvar' : 'Editar nota'}</Button>
            </Flex>
          </Flex>
        }
      </WhiteBox>
    </Body>
  )
}
