import { HtmlHTMLAttributes } from 'react'
import { FaQuestion } from 'react-icons/fa'
import { Container } from './styles'

export const ButtonTag: React.FC<HtmlHTMLAttributes<HTMLElement>> = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <FaQuestion />
      <span>{children}</span>
    </Container>
  )
}
