import { Avatar } from '@chakra-ui/avatar'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { iComentarioFeedback, iQuestao, iRespostaXColaborador } from '../..'
import { ElementoTooltip } from '../../../../../../components/ElementoTooltip'
import { PropInvalida } from '../../../../../../Utils/Helper'

interface iProps {
  variant: 'editar' | 'visualizar' | 'extra'
  respostas?: iRespostaXColaborador[]
  questao?: iQuestao
  comentario?: iComentarioFeedback
}

export const QuestaoComentario: React.FC<iProps> = ({ variant, respostas, questao, comentario }) => {
  if (questao && respostas && variant === 'visualizar') {
    return (
      <Flex flexDir={'column'} gap='.5rem'>
        {respostas.map((res, i2) => {
          const resposta = res.respostas.find(e => e.questaoId === questao.id)
          if (PropInvalida(resposta)) return null
          return (
            <Box key={i2} my={'.25rem'} bgColor={'var(--Gray6)'} p={'.5rem'}>
              <Flex alignItems={'center'} gap={'.5rem'}>
                <ElementoTooltip label={res.nome}>
                  <Avatar src={res.avatar} size={'2xs'} />
                </ElementoTooltip>
                <Text fontSize={'12px'} fontWeight={'400'}>{resposta?.data}</Text>
              </Flex>
              <Text>{resposta?.comentario}</Text>
            </Box>
          )
        })}
      </Flex>
    )
  } else if (variant === 'extra') {
    return (
      <Box my={'.25rem'} bgColor={'var(--Gray6)'} p={'.5rem'}>
        <Flex alignItems={'center'} gap={'.5rem'}>
          <ElementoTooltip label={comentario?.nome}>
            <Avatar src={comentario?.avatar} size={'2xs'} />
          </ElementoTooltip>
          <Text fontSize={'12px'} fontWeight={'400'}>{comentario?.data}</Text>
        </Flex>
        <Text>{comentario?.comentario}</Text>
      </Box>
    )
  }
  return null
}
