import { Avatar, Box, Button, Flex, HStack, Text, Image, MenuList, Menu, MenuButton, MenuItem, VStack } from '@chakra-ui/react'
import { FaBan, FaCheck, FaChevronDown, FaSync } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { PropInvalida } from '../../../../../../Utils/Helper'
interface IOutrosCargos{
  nome: string
  jobId: string
}
interface ICardColaboradorProps{

  id: string
  avatar: any
  imgPerfil: any
  nome: string
  dataPesquisa: string
  cargos?: IOutrosCargos[]
  par?: boolean
  tipo?: number
  perfil?: string
  selo?: string
  urlSelo?: string

}
export const CardColaborador: React.FC<ICardColaboradorProps> = ({ imgPerfil, nome, avatar, dataPesquisa, cargos, par, tipo, perfil, id, selo, urlSelo }: ICardColaboradorProps) => {
  const navigate = useNavigate()
  if (PropInvalida(tipo)) tipo = 1
  return (
    <Box border='1px' borderColor='var(--c3)'
     my={0} bgColor={((par === undefined) || !par) ? '#EDF2F7' : 'white'} minHeight='4.6rem' alignContent='center' padding={4} borderRadius='0'>
       <Flex wrap='wrap' justifyContent='space-between' alignItems='center' height='100%'>

        <Box cursor={'pointer'}
        onClick={() => navigate(`/AssessmentPi/Perfil/${id}`)}
        alignItems='center' >
            <HStack alignItems='center' width='12rem'>
              <Avatar name={nome} size={'sm'} src={avatar}/>
            <Text width='10rem' fontSize={'xs'} fontWeight={'bold'} color='black' >{nome}</Text>
            </HStack>

          </Box>

            <Text width='6rem' textAlign={'center'} fontSize={'10px'} fontWeight={'bold'} color='black' >{dataPesquisa}</Text>
<VStack width='6rem' spacing={'0.1rem'}>
        <Image src={urlSelo} width='1.8rem'/>
<Text fontSize='10px' fontWeight={'medium'}> {selo ?? ''}</Text>
</VStack>
<HStack width='10rem'>
        <Image src={imgPerfil} width='7rem'/>
<Text fontSize='xs'>: {perfil ?? ''}</Text>
</HStack>

<HStack width='7rem' justifyContent='center' m={0} p={0}>
     { tipo === 0 && (
        <>
        <FaCheck color='var(--Green2)' size='1.0rem'/>
  <Text fontSize='sm' color='var(--Green2)'>Aderente</Text>
        </>
     )
}

{ tipo === 1 && (
        <>
          <FaSync color='var(--a1)' size='1.0rem'/>
  <Text fontSize='sm' color='var(--a1)'>Adaptativo</Text>
        </>
)}

        { tipo === 2 && (<>
          <FaBan color='var(--Red)' size='1.0rem'/>
  <Text fontSize='sm' color='var(--Red)'>Não aderente</Text>
        </>
        )}
        </HStack>

          {cargos === undefined && <Text fontSize='xs'></Text>}
          {cargos && (
            <VStack width='10rem' justifyContent='space-around'>
          {cargos.length > 0 && (

            <Text fontWeight={'bold'} fontSize='xs'>{cargos?.length} cargo{cargos?.length > 1 ? 's' : ''}</Text>
          )}
          {/* <Button size={'xs'} width='7rem' backgroundColor='var(--Rosa)'>Visualizar</Button> */}

          {cargos.length > 0 && (

            <Menu>
  <MenuButton as={Button}
     _hover={{ bg: 'var(--Roxo)' }}
     _expanded={{ bg: 'var(--Roxo)' }}
     size={'xs'} width='7rem' backgroundColor='var(--Rosa)' rightIcon={<FaChevronDown />}>
    Visualizar
  </MenuButton>
  <MenuList backgroundColor='var(--Rosa)'

  >
    {cargos.map((c) => (<MenuItem onClick={() => navigate(`/AssessmentPi/JobTarget/${c.jobId}`)}>{c.nome}</MenuItem>))}

  </MenuList>
</Menu>
          )}
          </VStack>
          )}

       </Flex>
      </Box>
  )
}
