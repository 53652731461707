import { Flex } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { MetaButton } from '../Buttons/MetaButton'
import { iDemografia } from '../../interfaces'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: () => void
  onDelete?: (id: string) => void
  message: string
  center?: boolean
  Demografia?: iDemografia
}

export const ModalDelete: React.FC<props> = ({ isOpen, center, onRequestClose, onConfirm, Demografia, message, onDelete }) => {
  Modal.setAppElement('#root')

  function handleDelete(): void {
    if (onDelete) {
      onDelete(Demografia?.id ?? '')
    }
  }
  return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName='react-modal-overlay'
            className='react-modal-content-poupUp'
        >
            <Flex>
                <MdClose size={24} onClick={onRequestClose} />
            </Flex>

            <Flex flexDir={'column'}>
                <h2 style={{ color: 'var(--secundario5)' }}>Atenção!</h2>
                <span style={{ textAlign: center ? 'center' : 'unset' }}>{message}</span>
                <Flex>
                    <MetaButton bg='c5' size='md' onClick={onRequestClose}>Cancelar</MetaButton>
                    <MetaButton bg='v2' size='md' onClick={() => {
                      onConfirm()
                      onRequestClose()
                      if (Demografia) {
                        handleDelete()
                      }
                    }}>Continuar</MetaButton>
                </Flex>
            </Flex>
        </Modal>
  )
}
