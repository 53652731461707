import { Box, Checkbox, Flex, FormControl, FormLabel, Input, useToast, Button as Botao, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Select, Grid, Text, Link } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { Button } from '../../../../components/Button'
import { WhiteBox } from '../../../../components/WhiteBox'
import { Body } from '../../../Layouts/Body'
import { appApi } from '../../../../services/appApi'
import { FaPencilAlt, FaPlus, FaSave } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { DeleteElementByPosition, PropInvalida, UpdateElementByPosition } from '../../../../Utils/Helper'
import { ModalCrop } from './components/ModalCrop'
import { ItemNineBox } from './components/ItemNineBox'
import { iNomeId } from '../../../../interfaces'
import { MultiColaborador } from '../../../../components/MultiColaborador'
import { Tag } from './components/Tag'
import { Legenda } from '../../../Jornada/ReuniaoOne/Configuracao/styles'

export interface iPutModel {
  chaveApiPi?: string
  empresa: iPutEmpresa
  responsaveis: iResponsavel[]
  nineBoxConfig: NineBoxConfig
  confiPesquisaPercepcao: iConfiPesquisaPercepcao
  configTrilha: iConfigTrilha
  configTeams: iConfigTeams
  configZoom: iConfigZoom
  configReuniao: iConfigReuniao
}

export interface iPutEmpresa extends Omit<iEmpresa, 'areas'> { }

export interface NineBoxConfig extends Omit<iNineBoxConfig, 'boxes'> { }

export interface iModel {
  chaveApiPi?: string
  empresa: iEmpresa
  responsaveis: iResponsavel[]
  contrato: string
  nineBoxConfig: iNineBoxConfig
  confiPesquisaPercepcao: iConfiPesquisaPercepcao
  configTrilha: iConfigTrilha
  configTeams: iConfigTeams
  configZoom: iConfigZoom
  configReuniao: iConfigReuniao
}

export interface iEmpresa {
  nome: string
  logo: string
  email: string
  telefone: string
  segmento: string
  numeroFuncionarios: number
  areas: iArea[]
}

export interface iArea {
  areaId: string
  nome: string
  qtd: number
}

export interface iResponsavel {
  tipoResponsabilidade: number
  colaboradorId: string
}

export interface iNineBoxConfig {
  liberarBoxGestor: boolean
  tipoMatriz: number
  boxes: iBox[]
}

export interface iBox {
  id: string
  titulo: string
  tags: string[]
  padrao: boolean
}

export interface iConfiPesquisaPercepcao {
  pares: number
  liderados: number
  outros: number
  altaLideranca: number
  liberarResultado: boolean
  monitorarDevolutiva?: boolean
  assinaturaGestor: boolean
  assinaturaColaborador: boolean
  comentarios: boolean
}

export interface iConfigTrilha {
  adicionarEtapaLiderado: boolean
  concluirEtapaLiderado: boolean
  editarEtapaLiderado: boolean
}

export interface iConfigReuniao {
  responsaveisAdicionarTipo: number[]
  tipoListaParticipante: number[]
}

interface iConfigTeams{
  tenantId: string
  clientId: string
  clientSecret: string
}

interface iConfigZoom{
  accountId: string
  clientId: string
  clientSecret: string
  email: string
}

export const ConfiguracaoEmpresa: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Tipos, setTipos] = useState<iNomeId[]>([])

  const [Model, setModel] = useState<iModel>({
    responsaveis: [],
    empresa: {
      areas: [],
      email: '',
      logo: '',
      nome: '',
      numeroFuncionarios: 0,
      segmento: '',
      telefone: ''
    },
    contrato: '',
    nineBoxConfig: {
      boxes: [],
      liberarBoxGestor: false,
      tipoMatriz: 0
    },
    configTrilha: {
      adicionarEtapaLiderado: false,
      concluirEtapaLiderado: false,
      editarEtapaLiderado: false
    },
    confiPesquisaPercepcao: {
      altaLideranca: 0,
      liderados: 0,
      outros: 0,
      pares: 0,
      liberarResultado: false,
      assinaturaColaborador: false,
      assinaturaGestor: false,
      comentarios: false
    },
    configTeams: {
      clientId: '',
      clientSecret: '',
      tenantId: ''
    },
    configZoom: {
      accountId: '',
      clientId: '',
      clientSecret: '',
      email: ''
    },
    configReuniao: {
      responsaveisAdicionarTipo: [],
      tipoListaParticipante: []
    }
  })
  console.log(Model)
  const [ModalCropIsOpen, setModalCropIsOpen] = useState(false)

  function AtualizarConfiguracao(): void {
    const UpdateModel: iPutModel = {
      chaveApiPi: Model.chaveApiPi ?? '',
      configTrilha: Model.configTrilha,
      responsaveis: Model.responsaveis,
      nineBoxConfig: Model.nineBoxConfig,
      empresa: Model.empresa,
      confiPesquisaPercepcao: Model.confiPesquisaPercepcao,
      configReuniao: Model.configReuniao,
      configTeams: {
        clientId: Model.configTeams.clientId ?? '',
        clientSecret: Model.configTeams.clientSecret ?? '',
        tenantId: Model.configTeams.tenantId ?? ''
      },
      configZoom: {
        accountId: Model.configZoom.accountId ?? '',
        clientId: Model.configZoom.clientId ?? '',
        clientSecret: Model.configZoom.clientSecret ?? '',
        email: Model.configZoom.email ?? ''
      }
    }
    appApi.put('Empresa/Configuracao', UpdateModel)
      .then(() => toast({
        title: 'Configuração atualizada com sucesso',
        status: 'success',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      }))
      .catch(err => {
        console.log(err)
        toast({
          title: 'Preencha corretamente os campos',
          status: 'error',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
      })
  }

  function getConfig(): void {
    appApi.get('Empresa/Configuracao')
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function ObterTipos(): void {
    appApi.get('TipoXEmpresa/Select?tipo=2')
      .then(res => {
        setTipos(res.data)
      })
      .catch(err => console.log(err))
  }

  function AtualizarTipo(tipo: iNomeId): void {
    appApi.put(`TipoXEmpresa/${tipo.id}`, {
      texto: tipo.nome,
      tipo: 2
    })
      .then(() => {
        toast({
          title: 'Tipo atualizado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        const pos = Tipos.findIndex(e => e.id === tipo.id)
        if (pos !== -1) {
          setTipos(UpdateElementByPosition([...Tipos], pos, tipo))
        }
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getConfig()
    getColaboradores()
    ObterTipos()
  }, [])

  return (
    <Body>
      {
        ModalCropIsOpen && (
          <ModalCrop
            isOpen={ModalCropIsOpen}
            onRequestClose={() => setModalCropIsOpen(false)}
          />
        )
      }

      <WhiteBox margin='.5rem 0 0 0'>
        <h2>Configuração da empresa</h2>
        <Flex marginBottom={'1rem'} justifyContent='end'>
          <Button size={'lg'} VarColor='Rosa' onClick={() => nav('/PrimeiroAcesso')}>Primeiro acesso</Button>
        </Flex>
        <Flex alignItems={'center'} flexDir={'column'} width={'100%'}>
          <Box display={'flex'} alignItems={'center'} borderRadius={'lg'} margin={'1rem 0'}>
            <img title={Model?.empresa.nome} src={Model?.empresa.logo} />
          </Box>
        </Flex>
        <Flex marginBottom={'.5rem'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'}>
          <h2 style={{ color: 'var(--a3)', fontSize: '1.5rem' }}>{Model?.empresa.nome}</h2>
          <Text color={'var(--a3)'} fontWeight='400'>{Model?.empresa.segmento}</Text>
        </Flex>
        <Flex justifyContent={'center'}>
          <Botao width={'11rem'} borderRadius={'3.125rem'} border={'0.063rem solid var(--terc2)'} bg={'var(--c1)'} _hover={{ bg: 'var(--terc2)', color: 'var(--c2)' }} color={'var(--terc2)'} onClick={() => setModalCropIsOpen(true)}>Trocar logo</Botao>
        </Flex>
        <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}></hr>
        <Flex flexDir={'column'}>
          <h3 style={{ marginBottom: '2.5rem' }}>Dados gerais</h3>
          <FormControl alignItems={'center'} as={Flex} justifyContent={'space-between'} mb={'1rem'}>
            <Flex flexDirection={'column'} justifyContent={'center'}>
              <FormLabel mb={'.5rem'}>E-mail</FormLabel>
              <Input
                placeholder={'empresa@gmail.com.br'}
                type={'text'}
                width={'15rem'}
                borderColor={'var(--Gray4)'}
                value={Model?.empresa.email}
                onChange={(e) => setModel(
                  {
                    ...Model,
                    empresa:
                    {
                      ...Model.empresa,
                      email: e.target.value
                    }
                  })}
              />
            </Flex>
            <Flex flexDirection={'column'} justifyContent={'center'}>
              <FormLabel mb={'.5rem'}>Telefone</FormLabel>
              <Input
                placeholder='(xx) xxxx-xxxx'
                type={'text'}
                width={'15rem'}
                borderColor={'var(--Gray4)'}
                value={Model?.empresa.telefone}
                onChange={(e) => setModel(
                  {
                    ...Model,
                    empresa:
                    {
                      ...Model.empresa,
                      telefone: e.target.value
                    }
                  })}
              />
            </Flex>
            <Flex flexDirection={'column'} justifyContent={'center'}>
              <FormLabel mb={'.5rem'}>Segmento</FormLabel>
              <Input
                value={Model?.empresa.segmento}
                placeholder='Digite o segmento' type={'text'} width={'15rem'} borderColor={'var(--Gray4)'}
                onChange={(e) => setModel(
                  {
                    ...Model,
                    empresa:
                    {
                      ...Model.empresa,
                      segmento: e.target.value
                    }
                  })}
              />
            </Flex>
            <Flex flexDirection={'column'} justifyContent={'center'}>
              <FormLabel mb={'.5rem'}>Número de funcionários</FormLabel>
              <Input
                value={Model?.empresa.numeroFuncionarios}
                onChange={(e) => setModel(
                  {
                    ...Model,
                    empresa:
                    {
                      ...Model.empresa,
                      numeroFuncionarios: e.target.valueAsNumber
                    }
                  })}
                placeholder='Digite um número'
                type={'number'}
                width={'15rem'}
                borderColor={'var(--Gray4)'}
              />
            </Flex>
          </FormControl>

          <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}/>

          <Flex margin={'1rem 0'} flexDirection={'column'}>
            <h3>Definição das áreas</h3>
            <small style={{ color: 'var(--c7)' }}>Áreas personalizadas vão constar apenas para demografia em pesquisas pulse e de engajamento e para definir a quantidade de funcionários para controle de adesão das pesquisas.</small>
            <Button mt={'1rem'} leftIcon={<FaPencilAlt style={{ marginRight: '.4rem' }} />} width={'14.8rem'} onClick={() => nav('/Organograma/Departamentos')} VarColor='Azul'>Definir/Editar Áreas</Button>
          </Flex>
          <Flex gap={'1rem'} flexDir={'column'} margin={'1rem 0'}>
            <h3>Quantidade de colaboradores cadastrados por área</h3>
            <Flex gap={'2rem'} flexWrap='wrap' alignItems={'end'}>
              {
                Model?.empresa.areas.map((e, i) => {
                  return (
                    <FormControl maxWidth={'16.7rem'} key={e.areaId}>
                      <FormLabel>{e.nome}</FormLabel>
                      <Input
                        type={'number'}
                        value={e.qtd ?? ''}
                        readOnly
                        cursor={'unset'}
                        maxWidth={'7rem'}
                        placeholder='Digite um número' borderColor={'var(--Gray4)'}
                      />
                    </FormControl>
                  )
                })
              }
            </Flex>
          </Flex>

          <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}></hr>

          <Flex margin={'1rem 0'} flexDir={'column'}>
            <h3>Responsáveis pelos chamados do RH</h3>
            <small style={{ color: 'var(--c7)' }}>
              Adicione o e-mail nos quais os chamados com RH devem ser direcionados. Esses usuários serão os únicos que receberam e-mails a cada chamado aberto, porém os administradores ainda teram acesso ao histórico através do sistema.
            </small>
            <MultiColaborador
              colaboradores={Colaboradores}
              selecionados={Model?.responsaveis.filter(r => r.tipoResponsabilidade === 0).map(r => r.colaboradorId) ?? []}
              onChoice={(e) => !PropInvalida(e) && setModel({
                ...Model,
                responsaveis: [...Model.responsaveis, {
                  colaboradorId: e,
                  tipoResponsabilidade: 0
                }]
              })}
              onDelete={(e) => setModel({
                ...Model,
                responsaveis: [...Model.responsaveis.filter(r => r.colaboradorId !== e)]
              })}
            />
          </Flex>
        </Flex>

        <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}></hr>

        <h3>Configurações {Model?.nineBoxConfig.tipoMatriz === 0 ? '9Box' : '5Box'}</h3>
        <small style={{ color: 'var(--c7)' }}>
          Configure como será os parâmetros do {Model?.nineBoxConfig.tipoMatriz === 0 ? '9Box' : '5Box'} dentro da empresa.
        </small>
        <h5 style={{ color: '#2D3748', marginTop: '2rem', marginBottom: '.5rem' }}>Tipo da matriz</h5>
        <Select
          onChange={(r) => setModel(
            {
              ...Model,
              nineBoxConfig:
              {
                ...Model.nineBoxConfig, tipoMatriz: parseInt(r.target.value)
              }
            })}
          width={'8rem'}
          mb={'2.3rem'}
        >
          <option value="0">9Box</option>
          {/* <option value="1">5Box</option> */}
        </Select>
        <FormControl display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'.4rem'} mb={'2.3rem'}>
          <Checkbox
            isChecked={Model.nineBoxConfig.liberarBoxGestor}
            onChange={(r) => setModel(
              {
                ...Model,
                nineBoxConfig:
                {
                  ...Model.nineBoxConfig, liberarBoxGestor: r.target.checked
                }
              })} borderColor={'var(--Gray4)'} />
          <FormLabel m={'0'}>Liberar visualização do 9Box para o gestor</FormLabel>
        </FormControl>
        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton _focus={{ boxShadow: 'none !important' }} color={'black'} bg={'var(--White)'} gap={'.3rem'} borderRadius={'1.25rem'} marginBottom={'1rem'} px={'0'}>
              <Flex as='span' textAlign={'left'} fontSize={'1.125rem'} fontWeight={'700'} gap={'.2rem'}>
                Definições padrão dos eixos
                <AccordionIcon />
              </Flex>
              <hr style={{ flex: '1', border: '0.063rem solid #333333' }}></hr>
            </AccordionButton>
            <AccordionPanel px={'0'}>
              <Grid templateColumns={'repeat(3, 1fr)'} gap={'1rem'} mb={'2rem'}>
                {
                  Model?.nineBoxConfig.boxes?.map(e => {
                    return (
                      <ItemNineBox
                        key={e.id}
                        box={e}
                      />
                    )
                  })
                }
              </Grid>
              <Button onClick={() => nav('NineBox')} color={'white'} bgColor={'var(--Green2)'} leftIcon={<FaPlus />} gap={'1rem'} width={'31.25rem'} fontSize={'1rem'} fontWeight={'500'}>Adicionar mais uma categoria de distribuição e descrição</Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}></hr>

        <Flex flexDirection={'column'} gap={'1rem'} margin={'1rem 0'}>
          <h4 style={{ display: 'flex' }}>Vigência de contrato até: <Text ml={'.5rem'}>{Model?.contrato}</Text></h4>
          <small style={{ color: 'var(--c7)' }}>Caso o tempo de contrato esteja no fim, entre em contato com seu representante do SAH ou <Link color={'var(--Blue2)'} textDecor='underline' isExternal href='https://arquiteturahumana.com.br/contato/'>clique aqui</Link></small>
        </Flex>

        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton _focus={{ boxShadow: 'none !important' }} bg={'var(--White)'} color={'var(--Azul)'} gap={'.3rem'} borderRadius={'1.25rem'} marginBottom={'1rem'} px={'0'}>
              <Flex as='span' textAlign={'left'} fontSize={'0.875rem'} gap={'.2rem'}>
                Configurações avançadas
                <AccordionIcon />
              </Flex>
              <hr style={{ border: '0.031rem solid var(--Azul)', flex: '1' }}></hr>
            </AccordionButton>
            <AccordionPanel gap={'1rem'}>
              <FormControl mb={'2rem'}>
                <FormLabel>Chave de API PI</FormLabel>
                <Input
                  value={Model.chaveApiPi ?? ''}
                  onChange={(e) => setModel({ ...Model, chaveApiPi: e.target.value })}
                  borderColor={'var(--Gray4)'}
                />
              </FormControl>

              <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}/>

              <Flex mb={'2rem'} flexDir={'column'} gap='1rem'>
                <h3>Integração teams</h3>
                <FormControl>
                  <FormLabel>Tenant id</FormLabel>
                  <Input
                    value={Model.configTeams.tenantId ?? ''}
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setModel({
                      ...Model,
                      configTeams:
                      { ...Model.configTeams, tenantId: e.target.value }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Client id</FormLabel>
                  <Input
                    value={Model.configTeams.clientId ?? ''}
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setModel({
                      ...Model,
                      configTeams:
                      { ...Model.configTeams, clientId: e.target.value }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Client secret</FormLabel>
                  <Input
                    value={Model.configTeams.clientSecret ?? ''}
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setModel({
                      ...Model,
                      configTeams:
                      { ...Model.configTeams, clientSecret: e.target.value }
                    })}
                  />
                </FormControl>
              </Flex>

              <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}/>

              <Flex mb={'2rem'} flexDir={'column'} gap='1rem'>
                <h3>Integração Zoom</h3>
                <FormControl>
                  <FormLabel>Accont id</FormLabel>
                  <Input
                    value={Model.configZoom.accountId ?? ''}
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setModel({
                      ...Model,
                      configZoom:
                      { ...Model.configZoom, accountId: e.target.value }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Client id</FormLabel>
                  <Input
                    value={Model.configZoom.clientId ?? ''}
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setModel({
                      ...Model,
                      configZoom:
                      { ...Model.configZoom, clientId: e.target.value }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Client secret</FormLabel>
                  <Input
                    value={Model.configZoom.clientSecret ?? ''}
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setModel({
                      ...Model,
                      configZoom:
                      { ...Model.configZoom, clientSecret: e.target.value }
                    })}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    value={Model.configZoom.email ?? ''}
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setModel({
                      ...Model,
                      configZoom:
                      { ...Model.configZoom, email: e.target.value }
                    })}
                  />
                </FormControl>
              </Flex>

              <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}/>

              <Flex flexDir={'column'}>
                <h3>Pesquisa de percepção / desempenho</h3>
                <small style={{ color: 'var(--c7)' }}>Quantidade de colaboradores máximo para adicionar automaticamente ao avaliado</small>

                <Flex flexDirection={'row'} flex='1' gap={'2rem'} margin={'1rem 0'}>
                <Flex alignItems={'center'} gap={'.7rem'}>
                  <Box as={'span'}>Pares</Box>
                  <Input
                    type={'number'}
                    px={'.5rem'}
                    min={0}
                    w={'4.2rem'}
                    maxHeight={'2rem'}
                    onChange={(e) => setModel(
                      {
                        ...Model,
                        confiPesquisaPercepcao:
                        {
                          ...Model.confiPesquisaPercepcao, pares: Number.isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                        }
                      })}
                    value={Model.confiPesquisaPercepcao.pares}>
                  </Input>
                </Flex>
                <Flex alignItems={'center'} gap={'.7rem'}>
                  <Box as={'span'}>Liderados</Box>
                  <Input
                    type={'number'}
                    px={'.5rem'}
                    min={0}
                    w={'4.2rem'}
                    maxHeight={'2rem'}
                    onChange={(e) => setModel(
                      {
                        ...Model,
                        confiPesquisaPercepcao:
                        {
                          ...Model.confiPesquisaPercepcao, liderados: Number.isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                        }
                      })}
                    value={Model.confiPesquisaPercepcao.liderados}>
                  </Input>
                </Flex>
                <Flex alignItems={'center'} gap={'.7rem'}>
                  <Box as={'span'}>Outros</Box>
                  <Input
                    type={'number'}
                    px={'.5rem'}
                    min={0}
                    w={'4.2rem'}
                    maxHeight={'2rem'}
                    onChange={(e) => setModel(
                      {
                        ...Model,
                        confiPesquisaPercepcao:
                        {
                          ...Model.confiPesquisaPercepcao, outros: Number.isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                        }
                      })}
                    value={Model.confiPesquisaPercepcao.outros}>
                  </Input>
                </Flex>
                <Flex alignItems={'center'} gap={'.7rem'}>
                  <Box as={'p'} whiteSpace='nowrap'>Alta liderança</Box>
                  <Input
                    type={'number'}
                    px={'.5rem'}
                    min={0}
                    w={'4.2rem'}
                    maxHeight={'2rem'}
                    onChange={(e) => setModel(
                      {
                        ...Model,
                        confiPesquisaPercepcao:
                        {
                          ...Model.confiPesquisaPercepcao, altaLideranca: Number.isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value)
                        }
                      })}
                    value={Model.confiPesquisaPercepcao.altaLideranca}>
                  </Input>
                </Flex>
                </Flex>

                <FormControl alignItems={'center'} as={Flex} gap='1rem'>
                <Checkbox
                  borderColor={'var(--Gray4)'}
                  isChecked={Model?.confiPesquisaPercepcao.liberarResultado}
                  onChange={(e) => setModel(
                    {
                      ...Model,
                      confiPesquisaPercepcao:
                      {
                        ...Model.confiPesquisaPercepcao, liberarResultado: e.target.checked
                      }
                    })}
                />
                <FormLabel margin={'0'}>Liberar resultados automaticamente para o avaliado</FormLabel>
                </FormControl>

                <FormControl alignItems={'center'} as={Flex} gap='1rem' marginTop={'1rem'}>
                <Checkbox
                  borderColor={'var(--Gray4)'}
                  isChecked={Model?.confiPesquisaPercepcao.monitorarDevolutiva}
                  onChange={(e) => setModel(
                    {
                      ...Model,
                      confiPesquisaPercepcao:
                      {
                        ...Model.confiPesquisaPercepcao, monitorarDevolutiva: e.target.checked
                      }
                    })}
                />
                <FormLabel margin={'0'}>Habilitar campo para monitorar o status da devolutiva</FormLabel>
                </FormControl>

                <Flex mt={'1rem'} gap={'1rem'} flexDir={'column'}>
                <Flex flexDir={'column'}>
                  <h3>Responsáveis por receber os e-mail de conclusão de pesquisa</h3>
                  <small style={{ color: 'var(--c7)' }}>Adicione os e-mails que devem ser notificados quando uma pesquisa de percepção  ou desempenho por competência for finalizada.</small>
                </Flex>
                <MultiColaborador
                  colaboradores={Colaboradores}
                  selecionados={Model?.responsaveis.filter(r => r.tipoResponsabilidade === 1).map(r => r.colaboradorId) ?? []}
                  onChoice={(e) => !PropInvalida(e) && setModel({
                    ...Model,
                    responsaveis: [...Model.responsaveis, {
                      colaboradorId: e,
                      tipoResponsabilidade: 1
                    }]
                  })}
                  onDelete={(e) => setModel({
                    ...Model,
                    responsaveis: [...Model.responsaveis.filter(r => r.colaboradorId !== e)]
                  })}
                />
                  <Flex flexDir={'column'} gap={'1.5rem'} mt={'1rem'}>
                  <Flex flexDir={'column'}>
                    <Text fontWeight={'700'} fontSize={'20px'} mb={'-.5rem'}>Configuração dos relatórios</Text>
                    <Legenda>Defina a configuração padrão para baixar os relatórios conforme o padrão de sua empresa</Legenda>
                  </Flex>
                  <Flex flexDir={'column'} gap={'1rem'}>
                    <Checkbox isChecked={Model.confiPesquisaPercepcao.comentarios} onChange={(e) => setModel({ ...Model, confiPesquisaPercepcao: { ...Model.confiPesquisaPercepcao, comentarios: e.target.checked } })}>Incluir comentários no relatório</Checkbox>
                    <Checkbox isChecked={Model.confiPesquisaPercepcao.assinaturaColaborador} onChange={(e) => setModel({ ...Model, confiPesquisaPercepcao: { ...Model.confiPesquisaPercepcao, assinaturaColaborador: e.target.checked } })}>Campo de assinatura do <Text as={'span'} fontWeight={'700'} textDecoration={'underline'}>colaborador</Text> nas páginas das notas (Gráficos e tabelas)</Checkbox>
                    <Checkbox isChecked={Model.confiPesquisaPercepcao.assinaturaGestor} onChange={(e) => setModel({ ...Model, confiPesquisaPercepcao: { ...Model.confiPesquisaPercepcao, assinaturaGestor: e.target.checked } })}>Campo de assinatura do <Text as={'span'} fontWeight={'700'} textDecoration={'underline'}>gestor</Text> nas páginas das notas (Gráficos e tabelas)</Checkbox>
                  </Flex>
                </Flex>
              </Flex>
              </Flex>

              <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}></hr>

              <Flex flexDir={'column'}>
                <Flex flexDir={'column'}>
                  <h3>Configurações de trilha</h3>
                  <small style={{ color: 'var(--c7)' }}>Defina usuários que podem editar as trilhas além dos administradores</small>

                  <MultiColaborador
                    colaboradores={Colaboradores}
                    selecionados={Model.responsaveis.filter(e => e.tipoResponsabilidade === 2).map(r => r.colaboradorId)}
                    onChoice={(e) => !PropInvalida(e) && setModel({
                      ...Model,
                      responsaveis: [...Model.responsaveis, {
                        colaboradorId: e,
                        tipoResponsabilidade: 2
                      }]
                    })}
                    onDelete={(e) => setModel({
                      ...Model, responsaveis: [...Model.responsaveis.filter(w => w.colaboradorId !== e)]
                    })}
                  />
                </Flex>

                <Flex mt={'2rem'} flexDir={'column'} gap='1rem'>
                  <h4>Permissões dos gestores</h4>
                  <FormControl alignItems={'center'} as={Flex} gap='1rem'>
                    <Checkbox
                      borderColor={'var(--Gray4)'}
                      isChecked={Model.configTrilha.adicionarEtapaLiderado}
                      onChange={(e) => setModel(
                        {
                          ...Model,
                          configTrilha:
                          {
                            ...Model.configTrilha, adicionarEtapaLiderado: e.target.checked
                          }
                        })}
                    />
                    <FormLabel margin={'0'}>Adicionar etapas a trilha de seus liderados</FormLabel>
                  </FormControl>

                  <FormControl alignItems={'center'} as={Flex} gap='1rem'>
                    <Checkbox
                      borderColor={'var(--Gray4)'}
                      isChecked={Model.configTrilha.concluirEtapaLiderado}
                      onChange={(e) => setModel(
                        {
                          ...Model,
                          configTrilha:
                          {
                            ...Model.configTrilha, concluirEtapaLiderado: e.target.checked
                          }
                        })}
                    />
                    <FormLabel margin={'0'}>Concluir etapas na trilha de seus liderados</FormLabel>
                  </FormControl>

                  <FormControl alignItems={'center'} as={Flex} gap='1rem'>
                    <Checkbox
                      borderColor={'var(--Gray4)'}
                      isChecked={Model.configTrilha.editarEtapaLiderado}
                      onChange={(e) => setModel(
                        {
                          ...Model,
                          configTrilha:
                          {
                            ...Model.configTrilha, editarEtapaLiderado: e.target.checked
                          }
                        })}
                    />
                    <FormLabel margin={'0'}>Editar etapas na trilha de seus liderados</FormLabel>
                  </FormControl>
                </Flex>
              </Flex>

              <hr style={{ border: '0.063rem solid var(--c2)', margin: '1.7rem 0' }}></hr>

              <Flex gap={'.5rem'} flexDir={'column'}>
                <h3 style={{ marginBottom: '1rem' }}>Configurações reunião 1:1</h3>

                <h4>Tipos de reunião cadastrados</h4>

                <Flex gap={'1rem'} flexWrap='wrap'>
                    {Tipos.map((tipo, i) => {
                      return (
                        <Tag
                          key={i}
                          isDisabled={i <= 2}
                          onDelete={() => setTipos(DeleteElementByPosition([...Tipos], i))}
                          onUpdate={AtualizarTipo}
                          model={tipo}
                        />
                      )
                    })}
                </Flex>

                <Flex my={'1rem'} flexDir={'column'}>
                    <h4>Quem pode adicionar novos tipos de reunião?</h4>
                    <Flex my={'1rem'} gap={'2rem'}>
                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <Checkbox
                          borderColor={'var(--Gray4)'}
                          isChecked={Model.configReuniao.responsaveisAdicionarTipo.includes(2)}
                          onChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                responsaveisAdicionarTipo: Model.configReuniao.responsaveisAdicionarTipo.includes(2)
                                  ? [...Model.configReuniao.responsaveisAdicionarTipo.filter(e => e !== 2)]
                                  : [...Model.configReuniao.responsaveisAdicionarTipo, 2]
                              }
                            })
                          }
                        />
                        <FormLabel margin={'0'}>Gestores de área</FormLabel>
                      </FormControl>

                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <Checkbox
                          borderColor={'var(--Gray4)'}
                          isChecked={Model.configReuniao.responsaveisAdicionarTipo.includes(1)}
                          onChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                responsaveisAdicionarTipo: Model.configReuniao.responsaveisAdicionarTipo.includes(1)
                                  ? [...Model.configReuniao.responsaveisAdicionarTipo.filter(e => e !== 1)]
                                  : [...Model.configReuniao.responsaveisAdicionarTipo, 1]
                              }
                            })
                          }
                        />
                        <FormLabel margin={'0'}>Gestores</FormLabel>
                      </FormControl>
                    </Flex>
                    <h4>Outros</h4>
                    <small
                      style={{ color: 'var(--c7)', marginBottom: '.5rem' }}
                    >Além dos gestores você pode liberar para colaboradores expecificos adicionando-os abaixo</small>
                    <MultiColaborador
                      colaboradores={Colaboradores}
                      selecionados={Model.responsaveis.filter(e => e.tipoResponsabilidade === 3).map(r => r.colaboradorId)}
                      onChoice={(e) => !PropInvalida(e) && setModel({
                        ...Model,
                        responsaveis: [...Model.responsaveis, {
                          colaboradorId: e,
                          tipoResponsabilidade: 3
                        }]
                      })}
                      onDelete={(e) => setModel({
                        ...Model, responsaveis: [...Model.responsaveis.filter(w => w.colaboradorId !== e)]
                      })}
                    />
                </Flex>

                <Flex flexDir={'column'}>
                  <h4>Com quem o usuário pode solicitar reunião?</h4>

                  <Flex my={'1rem'} gap={'2rem'}>
                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <Checkbox
                          borderColor={'var(--Gray4)'}
                          isChecked={Model.configReuniao.tipoListaParticipante.includes(0)}
                          isDisabled={Model.configReuniao.tipoListaParticipante.includes(3)}
                          onChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                tipoListaParticipante: Model.configReuniao.tipoListaParticipante.includes(0)
                                  ? [...Model.configReuniao.tipoListaParticipante.filter(e => e !== 0)]
                                  : [...Model.configReuniao.tipoListaParticipante, 0]
                              }
                            })
                          }
                        />
                        <FormLabel margin={'0'}>Seu gestor</FormLabel>
                      </FormControl>

                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <Checkbox
                          borderColor={'var(--Gray4)'}
                          isChecked={Model.configReuniao.tipoListaParticipante.includes(1)}
                          isDisabled={Model.configReuniao.tipoListaParticipante.includes(3)}
                          onChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                tipoListaParticipante: Model.configReuniao.tipoListaParticipante.includes(1)
                                  ? [...Model.configReuniao.tipoListaParticipante.filter(e => e !== 1)]
                                  : [...Model.configReuniao.tipoListaParticipante, 1]
                              }
                            })
                          }
                        />
                        <FormLabel margin={'0'}>Seus pares</FormLabel>
                      </FormControl>

                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <Checkbox
                          borderColor={'var(--Gray4)'}
                          isDisabled={Model.configReuniao.tipoListaParticipante.includes(3)}
                          isChecked={Model.configReuniao.tipoListaParticipante.includes(2)}
                          onChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                tipoListaParticipante: Model.configReuniao.tipoListaParticipante.includes(2)
                                  ? [...Model.configReuniao.tipoListaParticipante.filter(e => e !== 2)]
                                  : [...Model.configReuniao.tipoListaParticipante, 2]
                              }
                            })
                          }
                        />
                        <FormLabel margin={'0'}>Seus liderados</FormLabel>
                      </FormControl>

                      <FormControl width={'auto'} alignItems={'center'} as={Flex} gap='.5rem'>
                        <Checkbox
                          borderColor={'var(--Gray4)'}
                          isChecked={Model.configReuniao.tipoListaParticipante.includes(3)}
                          onChange={() =>
                            setModel({
                              ...Model,
                              configReuniao: {
                                ...Model.configReuniao,
                                tipoListaParticipante: Model.configReuniao.tipoListaParticipante.includes(3)
                                  ? [...Model.configReuniao.tipoListaParticipante.filter(e => e !== 3)]
                                  : [...Model.configReuniao.tipoListaParticipante, 3]
                              }
                            })
                          }
                        />
                        <FormLabel margin={'0'}>Todos os colaboradores</FormLabel>
                      </FormControl>

                    </Flex>
                </Flex>

                {/* <Flex>
                  <Button
                    onClick={() => setAdd(true)}
                    mt={'1rem'}
                    VarColor='Azul'
                  >Adicionar</Button>
                </Flex> */}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Flex justifyContent={'end'} marginTop={'.5rem'}>
          <Button
            leftIcon={<FaSave />}
            onClick={AtualizarConfiguracao}
            VarColor='Green1'
            width={'fit-content'}
            size={'lg'}
          >Salvar alterações</Button>
        </Flex>
      </WhiteBox>
    </Body>
  )
}
