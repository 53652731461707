/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import { Flex, Input, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaChevronLeft, FaComment, FaEye, FaPencilAlt, FaSave } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Button } from '../../../../components/Button'
import { NavLine } from '../../../../components/NavLine'
import { WhiteBox } from '../../../../components/WhiteBox'
import { appApi } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { SubstringRoute } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'
import { QuestaoAlternativa } from './components/Alternativa'
import { QuestaoCaixaSelecao } from './components/CaixaSelecao'
import { QuestaoComentario } from './components/Comentario'

interface iModel {
  titulo: string
  editarResposta: boolean
  questoes: iQuestao[]
  respostas: iRespostaXColaborador[]
  comentarios: iComentarioFeedback[]
}

export interface iQuestao {
  id: string
  tipo: number
  texto: string
  alternativas?: iAlternativa[]
}

export interface iAlternativa {
  id: string
  texto: string
}

export interface iRespostaXColaborador {
  avatar: string
  colaboradorId: string
  nome: string
  respostas: iResposta[]
  comentarios: string[]
}

export interface iComentarioFeedback {
  data: string
  avatar: string
  comentario: string
  nome: string
}

interface iResposta {
  questaoId: string
  data: string
  alternativas?: string[]
  comentario?: string
}

interface iRespostaFormulario {
  questaoId: string
  alternativaId: string
}

interface iRespostaRequest {
  questaoId: string
  tipo: number
  alternativasId?: string[]
  texto?: string
}

export const VisualizarRespostasFeedback: React.FC = () => {
  const toast = useToast()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const { feedbackId } = useParams<{ feedbackId: string }>()
  const [ModoEdicao, setModoEdicao] = useState(false)
  const [Model, setModel] = useState<iModel>({
    titulo: '',
    editarResposta: false,
    questoes: [],
    respostas: [],
    comentarios: []
  })
  const [Comentario, setComentario] = useState('')
  const [RespostasObjetiva, setRespostasObjetiva] = useState<iRespostaFormulario[]>([])
  const [RespostasCaixaSelecao, setRespostasCaixaSelecao] = useState<iRespostaFormulario[]>([])

  function Carregar(): void {
    appApi.get(`Feedback/${feedbackId ?? ''}/Visualizar`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function AdicionarComentario(): void {
    appApi.post(`Feedback/${feedbackId ?? ''}/Comentario`, {
      texto: Comentario
    })
      .then(() => {
        toast({
          title: 'Comentário adicionado com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setComentario('')
        Carregar()
      }).catch(err => console.log(err))
  }

  const isSelect = (perguntaId: string, alternativaId: string): boolean => RespostasObjetiva.find(e => e.questaoId === perguntaId && e.alternativaId === alternativaId) !== undefined
  const isSelectQuestao = (perguntaId: string): boolean => RespostasObjetiva.find(e => e.questaoId === perguntaId) !== undefined

  function SelRespostaObjetiva(questaoId: string, alternativaId: string): void {
    if (!isSelectQuestao(questaoId)) {
      setRespostasObjetiva(oldArray => [...oldArray, { questaoId: questaoId, alternativaId: alternativaId }])
    } else {
      if (!isSelect(questaoId, alternativaId)) {
        UpdateRespostaObjetiva(questaoId, alternativaId)
      }
    }
  }

  function UpdateRespostaObjetiva(questaoId: string, alternativaId: string): void {
    const respostas = [...RespostasObjetiva]
    const pos = respostas.findIndex(e => e.questaoId === questaoId)
    if (pos !== -1) {
      respostas[pos].alternativaId = alternativaId
      setRespostasObjetiva(respostas)
    }
  }

  function SelecionarCaixaSelecao(questaoId: string, alternativaId: string): void {
    if (RespostasCaixaSelecao.find(r => r.questaoId === questaoId && r.alternativaId === alternativaId) === undefined) {
      setRespostasCaixaSelecao(oldArray => [...oldArray, { questaoId: questaoId, alternativaId: alternativaId }])
    } else {
      const copy = [...RespostasCaixaSelecao].filter(e => e.alternativaId !== alternativaId)
      setRespostasCaixaSelecao(copy)
    }
  }

  function AlterarResposta(): void {
    const respostas: iRespostaRequest[] = []
    RespostasObjetiva.forEach((e) => {
      respostas.push({
        tipo: 0,
        questaoId: e.questaoId,
        alternativasId: [e.alternativaId]
      })
    })

    Model?.questoes.filter(e => e.tipo === 5).forEach((e) => {
      respostas.push({
        questaoId: e.id,
        tipo: 5,
        alternativasId: RespostasCaixaSelecao.filter(r => r.questaoId === e.id).map((r) => {
          return r.alternativaId
        })
      })
    })

    appApi.put(`Feedback/${feedbackId ?? ''}/AlterarResposta`, respostas)
      .then(() => {
        toast({
          title: 'Resposta alterada com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        setModoEdicao(false)
        Carregar()
      })
      .catch(err => console.log(err))
  }

  function AtivarModoEdicao(ativar: boolean): void {
    if (ativar) {
      setModoEdicao(true)
      const respostaColaborador = Model.respostas.find(e => e.colaboradorId === parseJwt().cid)
      if (respostaColaborador) {
        const arr: iRespostaFormulario[] = []
        const questoesObjetivas = Model.questoes.filter(w => w.tipo === 0)

        respostaColaborador.respostas.filter(q => questoesObjetivas.some(t => t.id === q.questaoId)).forEach((e) => {
          e.alternativas?.forEach((a) => {
            arr.push({
              questaoId: e.questaoId,
              alternativaId: a
            })
          })
        })
        setRespostasObjetiva(arr)

        const arrCaixa: iRespostaFormulario[] = []
        const questoesCaixaSelecao = Model.questoes.filter(w => w.tipo === 5)
        respostaColaborador.respostas.filter(q => questoesCaixaSelecao.some(t => t.id === q.questaoId)).forEach((e) => {
          e.alternativas?.forEach((a) => {
            arrCaixa.push({
              questaoId: e.questaoId,
              alternativaId: a
            })
          })
        })
        setRespostasCaixaSelecao(arrCaixa)
      }
    } else {
      setRespostasCaixaSelecao([])
      setRespostasObjetiva([])
      setModoEdicao(false)
    }
  }

  useEffect(() => {
    Carregar()
  }, [])

  return (
    <Body>
      <NavLine
        options={
          [
            { nome: 'Reuniao 1:1', path: `${SubstringRoute(pathname, 'Visualizar')}Dashboard/Individual` },
            { nome: !ModoEdicao ? 'Visualizar respostas' : 'Editar respostas' }
          ]}
      />
      <WhiteBox>
        <Flex justifyContent={'space-between'}>
          <h2>{Model.titulo}</h2>
          <Button
            w={'10rem'}
            h={'2.4rem'}
            isDisabled={!Model.editarResposta}
            fontSize={'0.75rem'}
            leftIcon={ModoEdicao ? <FaEye/> : <FaPencilAlt />}
            onClick={() => AtivarModoEdicao(!ModoEdicao)}
            VarColor={'Rosa'}
          >{ModoEdicao ? 'Visualizar respostas' : 'Editar respostas'}</Button>
        </Flex>
        <Button onClick={() => nav(-1)} my={'1.2rem'} VarColor='Gray3' leftIcon={<FaChevronLeft />} fontSize={'0.75rem'} w={'5.8rem'} h={'2.4rem'}>Voltar</Button>
        {Model.questoes.map((questao, i) => {
          return (
            <Flex key={i} flexDirection={'column'} my={'1rem'}>
              <h4>{questao.texto}</h4>
              <Flex flexDirection={'column'}>
                {questao.tipo === 0 &&
                  <QuestaoAlternativa
                    variant={ModoEdicao ? 'editar' : 'visualizar'}
                    questao={questao}
                    respostas={Model.respostas}
                    Selelecionar={SelRespostaObjetiva}
                    selecionada={RespostasObjetiva.find(e => e.questaoId === questao.id)?.alternativaId}
                  />
                }

                {questao.tipo === 5 &&
                  <QuestaoCaixaSelecao
                    variant={ModoEdicao ? 'editar' : 'visualizar'}
                    questao={questao}
                    selecionadas={RespostasCaixaSelecao.filter(e => e.questaoId === questao.id).map(e => e.alternativaId)}
                    respostas={Model.respostas}
                    selecionar={SelecionarCaixaSelecao}
                  />
                }

                {questao.tipo === 1 &&
                  <QuestaoComentario
                    questao={questao}
                    respostas={Model.respostas}
                    variant='visualizar'
                  />
                }
              </Flex>
            </Flex>
          )
        })}
        {!ModoEdicao &&
          <Flex flexDir={'column'}>
            <hr style={{ border: '1px solid #a9a9a982', margin: '.3rem 0' }}></hr>
            {Model.comentarios.length > 0 &&
              <Flex flexDir={'column'} mt={'.5rem'}>
                <Text fontWeight={'500'}>Comentários após preenchimento</Text>
                <Flex flexDir={'column'}>
                  {Model.comentarios.map((e, i) => {
                    return (
                      <QuestaoComentario
                        key={i}
                        variant='extra'
                        comentario={e}
                      />
                    )
                  })}
                </Flex>
              </Flex>
            }
            <Input
              mt={'.5rem'}
              placeholder='Digite um comentário'
              borderColor={'var(--Gray4)'}
              value={Comentario}
              onChange={(e) => setComentario(e.target.value)}
            />
            <Button
              mt={'1rem'}
              fontSize={'0.75rem'}
              VarColor='Blue2'
              leftIcon={<FaComment />}
              onClick={AdicionarComentario}
            >Adicionar comentário</Button>
          </Flex>
        }
        {ModoEdicao &&
          <Button
            mt={'1rem'}
            fontSize={'0.75rem'}
            VarColor='Green2'
            leftIcon={<FaSave />}
            onClick={AlterarResposta}
          >Salvar alteração</Button>
        }
      </WhiteBox>
    </Body>
  )
}
