/* eslint-disable @typescript-eslint/no-unused-vars */
import { MdClose } from 'react-icons/md'
import { FaRegCalendarAlt } from 'react-icons/fa'
import Modal from 'react-modal'
import { ModalBody, ModalFooter, ModalHeader, TextoDescricao, TextData } from './styles'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { useNavigate } from 'react-router-dom'

interface props {
  isOpen: boolean
  Percepcao?: boolean
  onRequestClose: () => void
  conviteId?: string
}

export const ModalPesquisa: React.FC<props> = ({ isOpen, Percepcao, onRequestClose, conviteId }) => {
  Modal.setAppElement('#root')
  const nav = useNavigate()
  const rota = Percepcao ? `/Abertura/${conviteId as string}` : `/PI/Abertura/${conviteId as string}`
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <ModalHeader>
        <h2>Pesquisa</h2>
        <MdClose size={24} onClick={onRequestClose} />
      </ModalHeader>

      <ModalBody>
        <h2>Olá,</h2>
        <TextoDescricao>sua pesquisa já pode ser repondida! Clique no botão abaixo para iniciar...</TextoDescricao>
        {/* <TextData>
          <FaRegCalendarAlt />
          <span>25/11/2021 a 23/12/2021</span>
        </TextData> */}
      </ModalBody>

      <ModalFooter>
        <MetaButton onClick={() => nav(rota)} bg='vg3' size='md'>Responder pesquisa</MetaButton>
      </ModalFooter>
    </Modal>
  )
}
