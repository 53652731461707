import { Flex, FormLabel, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiOutlineMail } from 'react-icons/ai'
import { FaTrash } from 'react-icons/fa'
import { FiSave } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { Button } from '../../../../../components/Button'
import { MetaButton } from '../../../../../components/Buttons/MetaButton'
import { FindUser } from '../../../../../components/FindBox'
import { iNomeId, iPostAvaliador } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Column } from '../../../../../styles/styledGlobal'
import { Body, Footer, Head } from './styles'

interface iModalprops {
  isOpen: boolean
  iniciada: boolean
  onRequestClose: () => void
  onConfirm: (form: iPostAvaliador) => void
  editMode: boolean
  onDeleteAvaliador: () => void
  onReenviarAvaliador: () => void
}

export const ModalAddParticipante: React.FC<iModalprops> = ({ iniciada, isOpen, onConfirm, onRequestClose, editMode, onReenviarAvaliador, onDeleteAvaliador }) => {
  Modal.setAppElement('#root')
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Selecionado, setSelecionado] = useState<string>()
  const [Email, setEmaill] = useState('')
  const [NomeColaborador, setNomeColaborador] = useState('')

  function getSelectColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarGestor(id: string): void {
    setSelecionado(id)
    if (id === '') {
      setEmaill('')
    }
  }

  function ExtrairValorInput(texto: string): void {
    setNomeColaborador(texto)
  }

  function ObterEmail(email: string): void {
    setEmaill(email)
  }

  useEffect(() => {
    getSelectColaboradores()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Head>
        <Column>
          <h2>Adicione um participante</h2>
          <span>Caso o usuário já esteja cadastrado no sistema, preencha o campo nome, caso não, preencha o campo de nome e email</span>
        </Column>
        <MdClose
          size={24}
          onClick={onRequestClose}
        />
      </Head>
      <Body>
        <Flex flexDir={'column'}>
          <FormLabel>Nome do colaborador</FormLabel>
          <FindUser
            lista={Colaboradores}
            onChoice={SelecionarGestor}
            placeholder='Digite o nome do participante'
            selecionado={Selecionado}
            onChangeInput={ExtrairValorInput}
            extrairEmail={ObterEmail}
          />
        </Flex>
        <Flex marginTop={'1rem'} flexDir={'column'}>
          <FormLabel>Email</FormLabel>
          <Input
            isDisabled={!!Selecionado}
            value={Email}
            borderColor={'var(--Gray4)'}
            onChange={(e) => setEmaill(e.target.value)}
          />
        </Flex>
      </Body>
      <Footer>
        {!editMode && (
          <MetaButton
          bg='v2'
          size='md'
          onClick={() => onConfirm({
            colaboradorId: Selecionado === '' ? undefined : Selecionado,
            email: Email,
            nome: NomeColaborador,
            TipoAvaliador: 0
          })}
        >{iniciada ? 'Adicionar e enviar' : 'Adicionar'}</MetaButton>
        )}
        {editMode && (
          <Flex gap={'1rem'}>
            <Button w={'9.1rem'} VarColor={'Red'} leftIcon={<FaTrash/>} onClick={onDeleteAvaliador}>Excluir</Button>
            <Button w={'9.1rem'} VarColor={'Azul'} leftIcon={<AiOutlineMail/>} onClick={onReenviarAvaliador}>Reenviar</Button>
            <Button w={'9.1rem'} VarColor={'Green2'} leftIcon={<FiSave />}>Salvar</Button>
          </Flex>
        )}
      </Footer>
    </Modal>
  )
}
