/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import ReactDOM from 'react-dom'
import { createServer } from 'miragejs'
import { ChakraProvider } from '@chakra-ui/react'
import App from './App'
import { iGrupoTrilha, iCardEtapa, iParticipante, iPesquisa, iTag, iReuniao, iIntegracao, iQuestao, iFormulario } from './interfaces'
const cards: iGrupoTrilha[] = [
  {
    id: '0',
    cor: 'BBBBBB',
    nome: 'Padrão',
    categoria: 0,
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  },
  {
    id: '1',
    cor: 'BBBBBB',
    nome: 'Padrão',
    categoria: 1,
    tags: [],
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  },
  {
    id: '2',
    cor: 'BBBBBB',
    nome: 'Padrão',
    categoria: 2,
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  },
  {
    id: '3',
    cor: 'E18787',
    nome: 'Comercial',
    categoria: 1,
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  },
  {
    id: '4',
    cor: '00B45A',
    nome: 'Financeiro',
    categoria: 2,
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  },
  {
    id: '5',
    cor: '6DA0FF',
    nome: 'Entrega',
    categoria: 1,
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  },
  {
    id: '6',
    cor: '5D5FEF',
    nome: 'Relações internacionalização',
    categoria: 0,
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  },
  {
    id: '7',
    cor: 'A5A6F6',
    nome: 'Marketing',
    categoria: 2,
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  },
  {
    id: '8',
    cor: 'A5A6F6',
    nome: 'Produção',
    categoria: 1,
    countEtapas: 0,
    countProcessos: 0,
    padrao: false
  }]

const tags: iTag[] = [{
  id: '1',
  texto: 'Desenvolvimento'

},
{
  id: '2',
  texto: 'Entrega'

},
{
  id: '3',
  texto: 'Comercial'
}]

const pesquisas: iPesquisa[] = [
  {
    id: '1',
    respondente: 0,
    escala: 1,
    sendRespostaGestor: false
  },
  {
    id: '2',
    respondente: 0,
    escala: 1,
    sendRespostaGestor: false
  }
]

const reunioes: iReuniao[] = [
  {
    id: '1',
    tipo: 0,
    responsavel: 0,
    titulo: ''
  }
]

const Imagens: string[] = [
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg',
  'https://cloud.icecloud.com.br/Content/fotos/98a7d318ede944009fc68d7183c38e70.jpg'
]

const participantes: iParticipante[] = [
  {
    Nome: 'Auto avaliação',
    EtapaId: '7'
  },
  {
    Nome: 'Líder',
    EtapaId: '7'
  },
  {
    Nome: 'Pares',
    EtapaId: '7'
  },
  {
    Nome: 'Liderados',
    EtapaId: '7'
  },
  {
    Nome: 'Outros',
    EtapaId: '7'
  }, {
    Nome: 'dale',
    EtapaId: '7'
  }
]

const integracoes: iIntegracao[] = [
  {
    id: '1',
    nome: 'Teste integracao',
    itensIntegracao: [
      {
        id: '1',
        titulo: 'Informações sobre a segurança do trabalho',
        tipo: 1,
        descricao: 'Uma descrição'
      },
      {
        id: '2',
        titulo: 'Liberação de equipamentos',
        tipo: 2
      },
      {
        id: '3',
        titulo: 'Liberação de acessos',
        tipo: 3
      },
      {
        id: '4',
        titulo: 'Liberação de pessoas',
        tipo: 0,
        descricao: 'Preencha corretamente',
        checklist: [
          {
            check: false,
            texto: 'O que você acha?'
          },
          {
            check: true,
            texto: 'Como estas?'
          },
          {
            check: false,
            texto: 'Hola amigo'
          },
          {
            check: true,
            texto: 'Estas perdido?'
          }
        ]
      }
    ]
  }
]

const cardEtapas: iCardEtapa[] = [
  {
    id: '1',
    nome: 'Primeiro acesso',
    descricao: 'Colaborador',
    tempoInicio: 0,
    tempoDuracao: 0,
    tipo: 0,
    interacoes: []
  },
  {
    id: '2',
    nome: 'Integração',
    descricao: 'Integração - comercial',
    tempoInicio: 0,
    tempoDuracao: 0,
    tipo: 1,
    interacoes: []
  }
]

// const Competencias: iCompetencia[] = [
//   {
//     id: '1',
//     Nome: 'Competencia 1',
//     url: 'asdadadadad',
//     Discursiva: false,
//     Selecionada: false,
//     Questoes: [
//       {
//         id: '1',
//         Texto: 'Teste questão 1',
//         Discursiva: false,
//         Selecionada: false
//       },
//       {
//         id: '2',
//         Texto: 'Teste questão 2',
//         Discursiva: false,
//         Selecionada: false
//       }
//     ]
//   },
//   {
//     id: '2',
//     Nome: 'Competencia discursiva',
//     url: 'asdadadadad',
//     Discursiva: true,
//     Selecionada: false,
//     Questoes: [
//       {
//         id: '3',
//         Texto: 'Teste questão discursiva 1',
//         Discursiva: true,
//         Selecionada: false
//       },
//       {
//         id: '4',
//         Texto: 'Teste questão 1',
//         Discursiva: true,
//         Selecionada: false
//       }
//     ]
//   },
//   {
//     id: '3',
//     Nome: 'Competencia 2',
//     url: 'asdadadadad',
//     Discursiva: false,
//     Selecionada: false,
//     Questoes: [
//       {
//         id: '5',
//         Texto: 'Teste questão',
//         Discursiva: false,
//         Selecionada: false
//       },
//       {
//         id: '6',
//         Texto: 'Teste questão',
//         Discursiva: false,
//         Selecionada: false
//       }
//     ]
//   },
//   {
//     id: '4',
//     Nome: 'Competencia criada',
//     url: 'asdadadadad',
//     Discursiva: false,
//     Selecionada: false,
//     DataCriacao: '2022-1-3',
//     Tags: [
//       '1',
//       '2'
//     ],
//     Questoes: [
//       {
//         id: '7',
//         Texto: 'Teste questão',
//         Discursiva: false,
//         Selecionada: false
//       },
//       {
//         id: '8',
//         Texto: 'Teste questão',
//         Discursiva: false,
//         Selecionada: false
//       }
//     ]
//   },
//   {
//     id: '5',
//     Nome: 'Competencia criada 2',
//     url: 'asdadadadad',
//     Discursiva: false,
//     Selecionada: false,
//     DataCriacao: '2022-1-7',
//     Tags: [
//       '1',
//       '2'
//     ],
//     Questoes: [
//       {
//         id: '9',
//         Texto: 'Teste questão',
//         Discursiva: false,
//         Selecionada: false
//       },
//       {
//         id: '10',
//         Texto: 'Teste questão',
//         Discursiva: false,
//         Selecionada: false
//       }
//     ]
//   }
// ]

// function GerarNovoEtapaId(): number {
//   let UltimoId = parseInt(cardEtapas[cardEtapas.length - 1].id)
//   UltimoId++
//   return UltimoId
// }

// function GerarNovoIntegracaoId(): number {
//   let UltimoId = parseInt(integracoes[integracoes.length - 1].id)
//   UltimoId++
//   return UltimoId
// }

// function GerarNovoTagId(): number {
//   let UltimoId = parseInt(tags[tags.length - 1].id)
//   UltimoId++
//   return UltimoId
// }

// function GerarNovoPesquisaId(): number {
//   let UltimoId = parseInt(pesquisas[pesquisas.length - 1].id)
//   UltimoId++
//   return UltimoId
// }

// function GerarNovoQuestaoId(CompetenciaId: string): number {
//   const obj = Competencias.find(e => e.id === CompetenciaId)
//   if (obj !== undefined) {
//     console.log(obj.Questoes)
//     let UltimoId = parseInt(obj.Questoes[obj.Questoes.length - 1].id)
//     UltimoId++
//     return UltimoId
//   }
//   return 0
// }

// function GerarNovoReuniaoId(): number {
//   let UltimoId = parseInt(reunioes[reunioes.length - 1].id)
//   UltimoId++
//   return UltimoId
// }

// function GerarNovasQuestoes(questoesPrimitivas: string[], competenciaId?: string): iQuestao[] {
//   const arr: iQuestao[] = []
//   for (let i = 0; i < questoesPrimitivas.length; i++) {
//     const obj: iQuestao = {
//       id: competenciaId === undefined ? GerarNovoQuestaoId('00').toString() : GerarNovoQuestaoId(competenciaId).toString(),
//       Discursiva: false,
//       Selecionada: true,
//       Texto: questoesPrimitivas[i]
//     }
//     arr.push(obj)
//   }
//   return arr
// }

// function GerarNovoCompetenciaId(): number {
//   let UltimoId = parseInt(Competencias[Competencias.length - 1].id)
//   UltimoId++
//   return UltimoId
// }

// createServer({

//   routes() {
//     this.namespace = 'api'

//     /// //Trilha
//     this.get('/GrupoTrilha', (schema, request) => {
//       return cards.filter(e => e.categoria.toString() === request.queryParams.tipo)
//     })

//     this.post('/GrupoTrilha', (schema, reuquest) => {
//       const data: iGrupoTrilha = JSON.parse(reuquest.requestBody)
//       cards.push(data)
//       return 'Ok'
//     })

//     this.get('/GrupoTrilha/:id', (schema, request) => {
//       const data = cards.find(x => x.id === request.params.id)
//       if (data !== undefined) {
//         return data
//       }
//       return 'Erro'
//     })

//     /// //Tag
//     this.get('/Tag', () => {
//       return tags
//     })

//     this.post('/Tag', (schema, request) => {
//       const data: iTag = JSON.parse(request.requestBody)
//       data.id = GerarNovoTagId().toString()
//       tags.push(data)
//       return { id: data.id }
//     })

//     this.del('/Tag/:id', (schema, request) => {
//       const TagId = request.params.id
//       let index = ''
//       for (let i = 0; i < tags.length; i++) {
//         if (tags[i].id.toString() === TagId) {
//           index = i.toString()
//         }
//       }
//       if (index !== '') {
//         tags.splice(parseInt(index), 1)
//       }
//       return 'Ok'
//     })

//     /// /Pesquisa
//     this.post('/Pesquisa', (schema, request) => {
//       const data: iPesquisa = JSON.parse(request.requestBody)
//       data.id = GerarNovoPesquisaId().toString()
//       pesquisas.push(data)
//       return 'Ok'
//     })

//     this.get('/Pesquisa', () => {
//       return pesquisas
//     })

//     this.get('/Pesquisa/:id', (u, request) => {
//       const PesquisaId = request.params.id
//       const resposta = pesquisas.find(e => e.id === PesquisaId)
//       if (resposta !== undefined) {
//         return resposta
//       }
//       return 'Error'
//     })

//     /// Reuniao

//     this.get('/Reuniao/:id', (u, request) => {
//       const ReuniaoId = request.params.id
//       const resposta = reunioes.find(e => e.id === ReuniaoId)
//       if (resposta !== undefined) {
//         return resposta
//       }
//       return 'Error'
//     })
//     /// Integracao

//     this.get('/Integracao', () => {
//       return integracoes
//     })

//     this.get('/Integracao/:id', (u, request) => {
//       const resposta = integracoes.find(e => e.id === request.params.id)
//       if (resposta !== undefined) {
//         return resposta
//       }
//       return 'Error'
//     })

//     this.post('/Integracao', () => {
//       const id = GerarNovoIntegracaoId().toString()
//       integracoes.push({
//         id: id,
//         nome: 'Sem nome',
//         itensIntegracao: []

//       })
//       return id
//     })

//     this.patch('/Integracao/:id', (schema, request) => {
//       const data = JSON.parse(request.requestBody)
//       for (let i = 0; i < integracoes.length; i++) {
//         const obj = integracoes[i].itensIntegracao.find(e => e.id === request.params.id)
//         if (obj !== undefined) {
//           obj.descricao = data.Descricao
//           obj.titulo = data.Titulo
//         }
//       }
//       return 'Ok'
//     })

//     this.del('/Integracao/:id', (schema, request) => {
//       for (let i = 0; i < integracoes.length; i++) {
//         integracoes[i].itensIntegracao = integracoes[i].itensIntegracao.filter(e => e.id !== request.params.id)
//       }
//       return 'Ok'
//     })

//     this.get('/ItemIntegracao/:id', (u, request) => {
//       for (let i = 0; i < integracoes.length; i++) {
//         const response = integracoes[i].itensIntegracao.find(e => e.id === request.params.id)
//         if (response !== undefined) {
//           return response
//         }
//       }
//       return 'Ok'
//     })

//     this.get('/participantes', () => {
//       return participantes
//     })

//     this.get('/Etapa', (u, request) => {
//       return cardEtapas.filter(x => x.trilhaId === request.queryParams.idTrilha)
//     })

//     this.patch('/cardEtapa', (schema, request) => {
//       const body = JSON.parse(request.requestBody)
//       for (let i = 0; i < cardEtapas.length; i++) {
//         if (cardEtapas[i].id === body.EtapaId) {
//           cardEtapas[i].id = body.Descricao
//         }
//       }
//       return 'Ok'
//     })

//     this.get('/Competencia', () => {
//       return Competencias
//     })

//     this.patch('/SelCompetencia/:id', (schema, request) => {
//       for (let i = 0; i < Competencias.length; i++) {
//         if (Competencias[i].id === request.params.id) {
//           Competencias[i].Selecionada = !Competencias[i].Selecionada
//         }
//       }
//       return 'Ok'
//     })

//     this.patch('/SelQuestao/:id', (schema, request) => {
//       const obj = Competencias.find(e => e.Questoes.find(t => t.id === request.params.id))
//       if (obj !== undefined) {
//         for (let i = 0; i < obj.Questoes.length; i++) {
//           if (obj.Questoes[i].id === request.params.id) {
//             obj.Questoes[i].Selecionada = !obj.Questoes[i].Selecionada
//             if (!obj.Selecionada) {
//               obj.Selecionada = true
//             }
//           }
//         }
//       }
//       return 'Ok'
//     })

//     this.post('/Questao', (schema, request) => {
//       const body = JSON.parse(request.requestBody)
//       const obj = Competencias.find(e => e.id === body.competenciaId)
//       if (obj !== undefined) {
//         obj.Questoes.push({ id: GerarNovoQuestaoId(body.competenciaId).toString(), Texto: body.texto, Discursiva: false, Selecionada: true })
//         if (!obj.Selecionada) {
//           obj.Selecionada = true
//         }
//       }
//       return 'Ok'
//     })

//     this.delete('/Questao/:id', (schema, request) => {
//       const obj = Competencias.find(e => e.Questoes.find(t => t.id === request.params.id))
//       if (obj !== undefined) {
//         obj.Questoes = obj.Questoes.filter(e => e.id !== request.params.id)
//       }
//       return 'Ok'
//     })

//     this.get('/Questao/:id', (schema, request) => {
//       const result = Competencias.find(e => e.id === request.params.id)
//       if (result !== undefined) {
//         return result.Questoes
//       }
//       return null
//     })

//     this.get('/QuestaoEdit/:CompetenciaId/:QuestaoId', (schema, request) => {
//       const result = Competencias.find(e => e.id === request.params.CompetenciaId)
//       if (result !== undefined) {
//         for (let i = 0; i < result.Questoes.length; i++) {
//           if (result.Questoes[i].id === request.params.QuestaoId) {
//             // result.Questoes[i].Texto = request.params.texto
//             return result.Questoes[i].Texto
//           }
//         }
//       }
//       return ''
//     })

//     this.get('/CompetenciaEdit/:CompetenciaId', (schema, request) => {
//       const result = Competencias.find(e => e.id === request.params.CompetenciaId)
//       if (result !== undefined) {
//         return result
//       }
//       return ''
//     })

//     this.put('/CompetenciaEdit/:CompetenciaId', (schema, request) => {
//       const body: iPostCompetencia = JSON.parse(request.requestBody)
//       for (let i = 0; i < Competencias.length; i++) {
//         if (Competencias[i].id === request.params.CompetenciaId) {
//           Competencias[i].Nome = body.Nome
//           Competencias[i].Tags = body.Tags
//           Competencias[i].url = body.url
//           Competencias[i].Questoes = GerarNovasQuestoes(body.Questoes, Competencias[i].id)
//           return 'Ok'
//         }
//       }
//       return ''
//     })

//     this.patch('/QuestaoEdit/:CompetenciaId/:QuestaoId', (schema, request) => {
//       const result = Competencias.find(e => e.id === request.params.CompetenciaId)
//       if (result !== undefined) {
//         console.log()
//         for (let i = 0; i < result.Questoes.length; i++) {
//           if (result.Questoes[i].id === request.params.QuestaoId) {
//             result.Questoes[i].Texto = JSON.parse(request.requestBody).texto
//             return 'Ok'
//           }
//         }
//       }
//       return 'Erro'
//     })

//     this.get('/Imagens', () => {
//       return Imagens
//     })

//     this.post('/Competencia', (schema, request) => {
//       const body: iPostCompetencia = JSON.parse(request.requestBody)
//       console.log(body)
//       Competencias.push({
//         id: GerarNovoCompetenciaId().toString(),
//         Nome: body.Nome,
//         Tags: body.Tags,
//         url: body.url,
//         Questoes: GerarNovasQuestoes(body.Questoes),
//         Discursiva: false,
//         Selecionada: true,
//         DataCriacao: new Date().toISOString().split('T')[0]
//       })
//       return 'Ok'
//     })

//     this.delete('/Competencia/:id', (schema, request) => {
//       Competencias = Competencias.filter(e => e.id !== request.params.id)
//       return 'Ok'
//     })
//   }
// })

ReactDOM.render(
  <ChakraProvider resetCSS={false}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root')
)
