import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { FaDoorOpen, FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { IJobTitle } from '../../../../../../interfaces'

import { Container } from './styles'

interface IJobTitleCardProps {
  jobTitle: IJobTitle
  onEdit: (jobTitle: IJobTitle) => void
  onDelete: (jobTitle: IJobTitle) => void
}

export const JobTitleCard: React.FC<IJobTitleCardProps> = ({
  jobTitle,
  onEdit,
  onDelete
}) => {
  return (
    <Container>
      <header>
        <FaDoorOpen />
        <div>
          <strong>{jobTitle.nome}</strong>
        </div>
      </header>
      <Flex mt={'.2rem'} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
        {jobTitle.colaboradores !== undefined && jobTitle.colaboradores === 0 &&
          <Text fontSize={'14px'} color={'var(--Gray3)'}><strong>{jobTitle.colaboradores}</strong> Colaboradores</Text>
        }
        {jobTitle.colaboradores !== undefined && jobTitle.colaboradores > 0 &&
          <Text fontSize={'14px'} color={'var(--Gray3)'}><strong>{jobTitle.colaboradores}</strong> Colaborador{jobTitle.colaboradores > 1 ? 'es' : ''}</Text>
        }
        <Flex alignItems={'center'}>
          <button style={{ marginTop: '.1rem' }} type="button" onClick={() => onEdit(jobTitle)}>
            <FiEdit />
          </button>

          <button type="button" onClick={() => onDelete(jobTitle)}>
            <FaTrashAlt size={20} />
          </button>
        </Flex>
      </Flex>
    </Container>
  )
}
