import React from 'react'
import { Tree } from 'react-organizational-chart'
import { EmployeeCard } from './components/EmployeeCard'
import { ChartNode } from './components/ChartNode'

import { Container } from './styles'
import { IEmployee } from '../../../../../../../../interfaces'

interface IEmployeesOrgChartProps {
  isFolded: boolean
  employees: IEmployee[]
}

export const EmployeesOrgChart: React.FC<IEmployeesOrgChartProps> = ({
  isFolded,
  employees
}) => {
  return (
    <Container>
      {employees.map((treeRoot) =>
        !treeRoot.subordinados?.length || isFolded
          ? (
          <div key={treeRoot.id}>
            <Tree
              lineWidth="4px"
              lineColor="#BDBDBD"
              lineBorderRadius="4px"
              label={<EmployeeCard employee={treeRoot} />}
            >
              {/* <></> */}
            </Tree>
          </div>
            )
          : (
          <div key={treeRoot.id}>
            <Tree
              lineWidth="4px"
              lineColor="#BDBDBD"
              lineBorderRadius="4px"
              label={<EmployeeCard employee={treeRoot} />}
            >
              <ChartNode
                isFolded={isFolded}
                employees={treeRoot.subordinados}
              />
            </Tree>
          </div>
            )
      )}
    </Container>
  )
}
