import { CircularProgress, CircularProgressLabel, Flex, Image, Text } from '@chakra-ui/react'
import { Container } from './styles'

import IconeLove from '../../../../../../assets/IconeLove.svg'
import IconeMagoado from '../../../../../../assets/IconeMagoado.svg'
import IconeFeliz from '../../../../../../assets/IconeFeliz.svg'
import IconePiscada from '../../../../../../assets/IconePiscada.svg'
import IconeTriste from '../../../../../../assets/IconeTriste.svg'

import { Button } from '../../../../../../components/Button'
import { useNavigate } from 'react-router-dom'

export interface iDadoTabela {
  id: string
  origem: string
  totalAcoes: number
  totalAcoesRealizadas: number
  dataInicio: string
  dataFim: string
  ultimaAvaliacao?: number
  status: number
  temAcaoAtrasada: boolean
}

interface iTabelaProps {
  slice: number
  dados: iDadoTabela[]
  fontSize?: string
  iconSize?: string
}

export const Tabela: React.FC<iTabelaProps> = ({ dados, slice, fontSize, iconSize }) => {
  const nav = useNavigate()
  // Status
  // Em criacao
  // Em andamento
  // Atrasado

  function getColor(numero: number): string {
    if (numero < 50) {
      return '#FE7B1E'
    } else if (numero >= 50 && numero < 75) {
      return '#6DA7A8'
    } else if (numero >= 75 && numero < 100) {
      return '#1961E0'
    } else {
      return '#89DB81'
    }
  }

  function UltimaAvaliacao(nota: number, status: number): any {
    if (status === 0) {
      return <Text fontWeight={'900'} color={'var(--Azul)'}>Em criação</Text>
    }

    if (status === 1) {
      return <Text fontWeight={'900'} color={'var(--Azul)'}>Em aprovação</Text>
    }

    if (nota === 1) {
      return <Image src={IconeTriste} />
    } else if (nota === 2) {
      return <Image src={IconeMagoado} />
    } else if (nota === 3) {
      return <Image src={IconeFeliz} />
    } else if (nota === 4) {
      return <Image src={IconePiscada} />
    } else {
      return <Image src={IconeLove} />
    }
  }
  return (
    <Flex flexDir={'column'} width={'100%'}>
      <Flex width={'100%'} justifyContent={'space-between'}>
        <h3>PDIs em andamento</h3>
      </Flex>
      <Container style={{ fontSize: fontSize ?? '16px' }}>
        <thead>
          <tr style={{ fontSize: fontSize ?? '16px' }}>
            <th>Origem</th>
            <th>Quantidade de ações</th>
            <th>Data inicial</th>
            <th>Data final</th>
            <th>Última avaliação</th>
            <th>Progresso</th>
          </tr>
        </thead>
        <tbody>
          {
            dados.slice(0, slice).map((e, i) => {
              return (
                <tr style={{ color: e.temAcaoAtrasada ? 'var(--Red)' : '' }} key={i}>
                  <th>{e.origem}</th>
                  <th>{e.totalAcoes}</th>
                  <th>{e.dataInicio}</th>
                  <th>{e.dataFim}</th>
                  <th>
                    <Flex maxHeight={iconSize ?? '2.5rem'} justifyContent={'center'}>
                      {
                        e.ultimaAvaliacao ?? (e.status === 0 || e.status === 1) ? UltimaAvaliacao(e.ultimaAvaliacao as number, e.status) : '-'
                      }
                    </Flex>
                  </th>
                  <th>
                    <Flex justifyContent={'center'} gap={'.5rem'}>
                      {
                        (e.status !== 0) && (
                          <Flex alignItems={'center'}>
                              <CircularProgress height={'3rem'} value={((e.totalAcoesRealizadas / e.totalAcoes) * 100)} color={getColor(((e.totalAcoesRealizadas / e.totalAcoes) * 100))}>
                                <CircularProgressLabel color={'black'} top={'65%'}>{((e.totalAcoesRealizadas / e.totalAcoes) * 100).toFixed(0)}%</CircularProgressLabel>
                              </CircularProgress>
                            {
                              (e.temAcaoAtrasada) && (
                                <Text fontSize={'0.813rem'} width='4rem' marginLeft={'.5rem'} fontWeight={'900'} color={'var(--Red)'}>Ação atrasada</Text>
                              )
                            }
                          </Flex>
                        )
                      }

                      {
                        (e.status === 0) && (
                          <>
                            <Button
                              size={'sm'}
                              VarColor='Azul'
                              onClick={() => nav(`/Desempenho/PDI/Editar/${e.id}`)}
                            >Continuar criação</Button>
                          </>
                        )
                      }
                    </Flex>
                  </th>
                </tr>
              )
            })
          }
        </tbody>
      </Container>
    </Flex>
  )
}
