import { Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { CreateEtapaBox } from '../../../../components/CreateEtapaBox'
import { EditorTexto } from '../../../../components/EditorTexto/Index'
import { FindUser } from '../../../../components/FindBox'
import { WhiteBox } from '../../../../components/WhiteBox'
import { iEtapa, iNomeId } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { parseJwt } from '../../../../services/token'
import { PropInvalida, ValidarEtapa } from '../../../../Utils/Helper'
import { Body } from '../../../Layouts/Body'

interface iPostReuniao {
  tipoReuniao: number
  participanteId?: string
  assunto: string
  descricao: string
  dataInicio?: string
  duracao?: number
  presencial: boolean
  local?: string
}

export const OldConfigReuniaoOne: React.FC = () => {
  const nav = useNavigate()
  const { pathname } = useLocation()
  const toast = useToast()
  const { reuniaoId, trilhaId, etapaId, processoId } = useParams<{ reuniaoId: string, trilhaId?: string, etapaId?: string, processoId?: string }>()

  const urlPai = processoId ? 'ReuniaoOneOldEtapaColaborador' : 'ReuniaoOneOldEtapa'

  const [ParticipanteIdIsInValid, setParticipanteIdIsInValid] = useState(false)
  const [AssuntoIsInValid, setAssuntoIsInValid] = useState(false)
  const [DataInicioIsInValid, setDataInicioIsInValid] = useState(false)
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Descricao, setDescricao] = useState('')
  const [IsLoading, setIsLoading] = useState(false)
  const [Model, setModel] = useState<iPostReuniao>({
    tipoReuniao: 0,
    assunto: '',
    descricao: '',
    presencial: false,
    duracao: 5
  })

  const [Etapa, setEtapa] = useState<iEtapa>({})

  function SelecionarUsuario(id: string): void {
    setModel({ ...Model, participanteId: id })
  }

  function getColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function SolicitarReuniao(): void {
    setAssuntoIsInValid(false)
    setDataInicioIsInValid(false)

    if (Model.participanteId === '') {
      setParticipanteIdIsInValid(true)
      toast({
        title: 'Insira o nome do participante',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model.assunto === '') {
      setAssuntoIsInValid(true)
      toast({
        title: 'Assunto da reunião não foi preenchido',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model.dataInicio === '') {
      setDataInicioIsInValid(true)
      toast({
        title: 'Data de início não foi preenchida',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form: iPostReuniao = {
      assunto: Model.assunto,
      dataInicio: Model.dataInicio,
      duracao: Model.duracao,
      descricao: Model.descricao.replace(/[\r\n]/gm, ''),
      presencial: Model.presencial,
      participanteId: Model.participanteId,
      tipoReuniao: Model.tipoReuniao,
      local: Model.local
    }
    appApi.post('ReuniaoOneOld', form)
      .then(() => {
        toast({
          title: 'Reunião agendada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })
        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function GetReuniao(): void {
    appApi.get(`ReuniaoOneOld/${reuniaoId as string}`)
      .then(res => {
        SelecionarUsuario(res.data.participanteId)
        const model: iPostReuniao = res.data
        setModel({
          tipoReuniao: model.tipoReuniao,
          assunto: model.assunto,
          descricao: model.descricao,
          participanteId: model.participanteId,
          presencial: model.presencial,
          duracao: model.duracao,
          dataInicio: ''
        })
      })
      .catch(err => console.log(err))
  }

  function RemarcarReuniao(): void {
    setAssuntoIsInValid(false)
    setDataInicioIsInValid(false)

    if (Model.participanteId === '') {
      setParticipanteIdIsInValid(true)
      toast({
        title: 'Insira o nome do participante',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model.assunto === '') {
      setAssuntoIsInValid(true)
      toast({
        title: 'Assunto da reunião não foi preenchido',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (Model.dataInicio === '') {
      setDataInicioIsInValid(true)
      toast({
        title: 'Data de início não foi preenchida',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form: iPostReuniao = {
      assunto: Model.assunto,
      dataInicio: Model.dataInicio,
      duracao: Model.duracao,
      descricao: Model.descricao,
      presencial: Model.presencial,
      participanteId: Model.participanteId,
      tipoReuniao: Model.tipoReuniao,
      local: Model.local

    }
    appApi.put(`ReuniaoOneOld/${reuniaoId as string}`, form)
      .then(() => {
        toast({
          title: 'Reunião reagendada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })

        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function CriarEtapaReuniao(): void {
    setAssuntoIsInValid(false)

    if (Model.assunto === '') {
      setAssuntoIsInValid(true)
      toast({
        title: 'Assunto da reunião não foi preenchido',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      reuniao: Model,
      Etapa: Etapa
    }

    appApi.post(`${urlPai}/${trilhaId ?? processoId ?? ''}`, form)
      .then(() => {
        toast({
          title: 'Etapa criada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })

        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function AtualizarEtapaReuniao(): void {
    setAssuntoIsInValid(false)

    if (Model.assunto === '') {
      setAssuntoIsInValid(true)
      toast({
        title: 'Assunto da reunião não foi preenchido',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    if (!ValidarEtapa(Etapa)) {
      toast({
        title: 'Preencha os campos de etapa',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
      return
    }

    const form = {
      reuniao: Model,
      Etapa: Etapa
    }

    const param = trilhaId ? etapaId : `${processoId ?? ''}/Etapa/${etapaId ?? ''}`
    appApi.put(`${urlPai}/${param ?? ''}`, form)
      .then(() => {
        toast({
          title: 'Etapa criada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 3000
        })

        nav(-1)
      })
      .catch(err => console.log(err))
  }

  function ObterEtapa(): void {
    const param = trilhaId ? etapaId : `${processoId ?? ''}/Etapa/${etapaId ?? ''}`
    appApi.get(`${urlPai}/${param ?? ''}`)
      .then(res => {
        setModel(res.data.reuniao)
        setIsLoading(false)
        setEtapa(res.data.etapa)
      })
      .catch(err => console.log(err))
    setDescricao('.')
  }

  function AtualizarEtapa(etapa: iEtapa): void {
    setEtapa(etapa)
  }

  useEffect(() => {
    getColaboradores()
    if (!PropInvalida(reuniaoId)) {
      GetReuniao()
    }
    if (etapaId) {
      ObterEtapa()
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
  }, [Descricao])

  return (
    <Body>
      <WhiteBox>
        <h1>Solicitação de reunião 1:1</h1>
        <Flex margin={'1.5rem 0'} alignItems='start' width={'75%'}>
          <FormControl width={'100%'} marginRight={'1rem'}>
            <FormLabel color={'var(--Gray1)'} fontSize={'xl'}>Selecione o tipo de reunião</FormLabel>
            <Flex width={'100%'}>
              <Select
                width={'20rem'}
                marginRight='1rem'
                value={Model.tipoReuniao.toString()}
                onChange={(e) => setModel({ ...Model, tipoReuniao: parseInt(e.target.value) })}
                borderColor={'var(--Gray4)'}
              >
                <option value={'0'}>Feedback com o gestor</option>
                <option value={'1'}>Feedback com o RH</option>
                {trilhaId && processoId && <option value={'2'}>Reunião de acompanhamento</option>}
              </Select>
              {
                (!trilhaId && !processoId) && (
                  <Flex width={'22rem'}>
                    <FindUser
                      lista={Colaboradores.filter((a) => {
                        if ((Model.tipoReuniao === 0 || Model.tipoReuniao === 2) && (a.gestorId === parseJwt().cid || Colaboradores.find(a => a.id === parseJwt().cid)?.gestorId === a.id)) {
                          return a
                        } else if ((Model.tipoReuniao === 1) && (a.rh === true)) {
                          return a
                        }
                        return null
                      })}
                      onChoice={SelecionarUsuario}
                      placeholder='Digite o nome do participante'
                      selecionado={Model.participanteId}
                      isInvalid={ParticipanteIdIsInValid}
                    />
                  </Flex>
                )
              }
            </Flex>
          </FormControl>
        </Flex>
        <Flex gap={'1.5rem'} flexDirection={'column'}>
          {
            (trilhaId ?? processoId) && (
              <Flex flexDir={'column'}>
                <CreateEtapaBox
                  EditEtapa={Etapa}
                  ignoreId={etapaId}
                  variant='Etapa'
                  onModelChange={AtualizarEtapa}
                  keyWord='reunião 1:1'
                />
              </Flex>
            )
          }
          <FormControl>
            <FormLabel fontSize={'xl'}>Assunto da reunião</FormLabel>
            <Input
              value={Model.assunto}
              type={'text'}
              isInvalid={AssuntoIsInValid}
              placeholder={'Digite o assunto da reunião'}
              borderColor={'var(--Gray4)'}
              onChange={(e) => setModel({ ...Model, assunto: e.target.value })}
            />
          </FormControl>
          {
            (!trilhaId && !processoId) && (
              <Flex justifyContent={'space-around'}>
                <FormControl width={'30%'}>
                  <FormLabel fontSize={'xl'}>Data e hora de início</FormLabel>
                  <Input
                    value={(Model.dataInicio === '0001-01-01T00:00:00' || Model.dataInicio === '0001-01-01T00:00:00') ? '' : Model.dataInicio}
                    type={'datetime-local'}
                    borderColor={'var(--Gray4)'}
                    onChange={(e) => setModel({ ...Model, dataInicio: e.target.value })}
                    isInvalid={DataInicioIsInValid}
                  />
                </FormControl>

                <FormControl width={'30%'}>
                  <FormLabel fontSize={'xl'}>Duração</FormLabel>
                  <Select value={Model.duracao} onChange={(e) => setModel({ ...Model, duracao: parseInt(e.target.value) })} borderColor={'var(--Gray4)'}>
                    <option value={'5'}>5 minutos</option>
                    <option value={'10'}>10 minutos</option>
                    <option value={'15'}>15 minutos</option>
                    <option value={'30'}>30 minutos</option>
                    <option value={'60'}>1 hora</option>
                    <option value={'120'}>2 horas</option>
                    <option value={'180'}>3 horas</option>
                    <option value={'240'}>4 horas</option>
                    <option value={'300'}>5 horas</option>
                    <option value={'360'}>6 horas</option>
                    <option value={'420'}>7 horas</option>
                    <option value={'480'}>8 horas</option>
                    <option value={'540'}>9 horas</option>
                    <option value={'600'}>10 horas</option>
                  </Select>
                </FormControl>
              </Flex>
            )
          }

          <RadioGroup onChange={(e) => setModel({ ...Model, presencial: e === '1' })} value={Model.presencial ? '1' : '0'}>
            <FormLabel fontSize={'xl'}>Local</FormLabel>
            <Stack direction={'row'} borderColor={'var(--Gray4)'}>
              <Radio value={'0'}>Online</Radio>
              <Radio value={'1'}>Presencial</Radio>
            </Stack>
          </RadioGroup >

          <Input
            value={Model.local}
            onChange={(e) => setModel({ ...Model, local: e.target.value })}
            placeholder={'Digite o local da reunião'}
            flexDir={'column'}
          />
          <Flex flexDir={'column'}>
            <FormControl>
              <FormLabel fontSize={'xl'}>Descrição</FormLabel>
              {((!IsLoading) || (Descricao === '')) && (
                <EditorTexto
                initialValue={Model.descricao}
                placeholder='Deixe uma descrição aqui'
                className='editorSt-v3'
                onChange={(e: string) => { setModel({ ...Model, descricao: e }) }}
              />
              )}
            </FormControl>
            <small>Digite um texto com informações importantes ou pauta para registro do que será tratado na reunião</small>
          </Flex>
        </Flex>

      </WhiteBox>
      <Flex marginTop={'2rem'} justifyContent={'end'}>
        <Button VarColor='c5' marginRight={'1rem'} size={'lg'} onClick={() => nav(-1)}>Cancelar</Button>
        {
          (etapaId) && (
            <Button onClick={AtualizarEtapaReuniao} VarColor='v2' size={'lg'}>Atualizar reunião</Button>
          )
        }

        {
          (pathname.includes('Remarcar')) && (
            <Button onClick={RemarcarReuniao} VarColor='v2' rightIcon={<FaAngleRight />} size={'lg'}>Remarcar reunião</Button>
          )
        }

        {
          ((!trilhaId && !processoId) && !pathname.includes('Remarcar')) && (
            <Button onClick={SolicitarReuniao} VarColor='v2' rightIcon={<FaAngleRight />} size={'lg'}>Solicitar</Button>
          )
        }

        {
          ((trilhaId ?? processoId) && !etapaId) && (
            <Button onClick={CriarEtapaReuniao} VarColor='v2' size={'lg'}>Criar etapa</Button>
          )
        }
      </Flex>
    </Body>
  )
}
