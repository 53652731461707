/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react'
import { IEmployee } from '../../../../../../../../../../interfaces'

import { Container, Content, AvatarContainer } from './styles'

interface IEmployeeCardProps {
  employee: IEmployee
}

export const EmployeeCard: React.FC<IEmployeeCardProps> = ({ employee }) => {
  return (
    <Container>
      <Content isActive={employee.gestorDeArea}>
        <AvatarContainer backgroundImg={employee.avatar || 'https://sigah.blob.core.windows.net/onboard/da193183-666b-42cd-ae52-da86bc76f065.png'}>
          <div>
            <p>{employee.nome}</p>
            <p>{employee.sobrenome}</p>
          </div>
        </AvatarContainer>
        <footer>
          <span>{employee?.cargo?.nome}</span>
        </footer>
      </Content>
    </Container>
  )
}
