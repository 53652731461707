import { Flex, FormLabel, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import Modal from 'react-modal'
import { Button } from '../../../../../components/Button'
import { FindUser } from '../../../../../components/FindBox'
import { iNomeId, iPostAvaliador } from '../../../../../interfaces'
import { appApi } from '../../../../../services/appApi'
import { Body, Footer, Head } from './styles'

interface iModalprops {
  isOpen: boolean
  iniciada: boolean
  onRequestClose: () => void
  onConfirm: (form: iPostAvaliador) => void
}

export const ModalAddParticipante: React.FC<iModalprops> = ({ iniciada, isOpen, onConfirm, onRequestClose }) => {
  Modal.setAppElement('#root')
  const [Colaboradores, setColaboradores] = useState<iNomeId[]>([])
  const [Selecionado, setSelecionado] = useState<string>()
  const [Email, setEmaill] = useState('')
  const [NomeColaborador, setNomeColaborador] = useState('')

  const [IsLoading, setIsLoading] = useState(false)

  function getSelectColaboradores(): void {
    appApi.get('Colaborador/Select')
      .then(res => setColaboradores(res.data))
      .catch(err => console.log(err))
  }

  function SelecionarGestor(id: string): void {
    setSelecionado(id)
    if (id === '') {
      setEmaill('')
    }
  }

  function ExtrairValorInput(texto: string): void {
    setNomeColaborador(texto)
  }

  function ObterEmail(email: string): void {
    setEmaill(email)
  }

  function InternalConfirm(): void {
    setIsLoading(true)
    onConfirm({
      colaboradorId: Selecionado === '' ? undefined : Selecionado,
      email: Email,
      nome: NomeColaborador,
      TipoAvaliador: 0
    })
  }

  useEffect(() => {
    getSelectColaboradores()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName='react-modal-overlay'
      className='react-modal-content-default'
    >
      <Head>
        <Flex flexDir={'column'}>
          <h2>Adicione um participante</h2>
          <span>Caso o usuário já esteja cadastrado no sistema, preencha o campo nome, caso não, preencha o campo de nome e email</span>
        </Flex>
        <MdClose
          size={24}
          onClick={onRequestClose}
        />
      </Head>
      <Body>
        <Flex flexDir={'column'}>
          <FormLabel>Nome do colaborador</FormLabel>
          <FindUser
            lista={Colaboradores}
            onChoice={SelecionarGestor}
            placeholder='Digite o nome do participante'
            selecionado={Selecionado}
            onChangeInput={ExtrairValorInput}
            extrairEmail={ObterEmail}
          />
        </Flex>
        <Flex marginTop={'1rem'} flexDir={'column'}>
          <FormLabel>Email</FormLabel>
          <Input
            isDisabled={!!Selecionado}
            value={Email}
            borderColor={'var(--Gray4)'}
            onChange={(e) => setEmaill(e.target.value)}
          />
        </Flex>
      </Body>
      <Footer>
        <Button
          VarColor='v2'
          isLoading={IsLoading}
          onClick={InternalConfirm}
        >{iniciada ? 'Adicionar e enviar' : 'Adicionar'}</Button>
      </Footer>
    </Modal>
  )
}
