/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Box, Flex, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Select, RadioGroup, Stack, Radio, useToast } from '@chakra-ui/react'
import { WhiteBox } from '../../../../components/WhiteBox'
import { Body } from '../../../Layouts/Body'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { FaPlus, FaSave } from 'react-icons/fa'
import { Button } from '../../../../components/Button'
import { Tag } from '../../../../components/Tag'
import { iNomeId, iPostNineBox } from '../../../../interfaces'
import { appApi } from '../../../../services/appApi'
import { PropInvalida } from '../../../../Utils/Helper'
import { NineBox } from '../../../../components/NineBox'

export const ConfigNineBox: React.FC = () => {
  const toast = useToast()
  const { id } = useParams<{ id: string }>()
  const nav = useNavigate()
  const [Model, setModel] = useState<iPostNineBox>({
    padrao: false,
    tipoMeta: 0,
    titulo: '',
    areas: [],
    squads: [],
    tags: [],
    configuracaoEixoX: {
      descricao: 'Descrição do desempenho',
      titulo: 'Desempenho'
    },
    configuracaoEixoY: {
      descricao: 'Descrição do potencial',
      titulo: 'Potencial'
    },
    eixosX: [],
    eixosY: [],
    quadrantes: []
  })

  const [Tags, setTags] = useState<iNomeId[]>([])
  const [Squads, setSquads] = useState<iNomeId[]>([])
  const [Areas, setAreas] = useState<iNomeId[]>([])
  const [ValidoX, setValidoX] = useState(false)
  const [ValidoY, setValidoY] = useState(false)
  const [Timeout, setTimeout] = useState<number>(50)

  const [ValorSelecionado, setValorSelecionado] = useState('')

  function adicionaArea(): void {
    setModel({ ...Model, areas: [...Model.areas ?? [], ValorSelecionado] })
  }

  function adicionaTag(): void {
    setModel({ ...Model, tags: [...Model.tags ?? [], ValorSelecionado] })
  }

  function adicionaSquad(): void {
    setModel({ ...Model, squads: [...Model.squads ?? [], ValorSelecionado] })
  }

  function ObterDepartamentos(): void {
    appApi.get('Departamento/Select')
      .then(res => setAreas(res.data))
      .catch(err => console.log(err))
  }

  function ObterSquads(): void {
    appApi.get('Tag/Select?tipo=2')
      .then(res => setSquads(res.data))
      .catch(err => console.log(err))
  }

  function ObterTags(): void {
    appApi.get('Tag/Select?tipo=0')
      .then(res => setTags(res.data))
      .catch(err => console.log(err))
  }

  function CriarNineBox(): void {
    appApi.post('NineBox', Model)
      .then(() => {
        toast({
          title: '9Box criado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        nav('/Usuario/Empresa')
      })
      .catch(e => console.log(e))
  }

  function ObterNineBox(): void {
    if (id) {
      appApi.get(`NineBox/${id ?? ''}`)
        .then(res => setModel(res.data))
        .catch(err => console.log(err))
    } else {
      appApi.get('NineBox/Padrao')
        .then(res => {
          const data: iPostNineBox = res.data
          setModel({
            configuracaoEixoX: data.configuracaoEixoX,
            configuracaoEixoY: data.configuracaoEixoY,
            eixosX: data.eixosX,
            eixosY: data.eixosY,
            padrao: false,
            quadrantes: data.quadrantes,
            tipoMeta: data.tipoMeta,
            titulo: '',
            areas: [],
            squads: [],
            tags: []
          })
        })
        .catch(err => console.log(err))
    }
  }

  function VerificaNineBox(): void {
    let condicionalX = 0
    let condicionalY = 0
    setValidoX(true)
    setValidoY(true)
    while (Model.eixosX[condicionalX]) {
      if ((Model.eixosX[condicionalX + 1] && Model.eixosX[condicionalX].rangeMaximo >= Model.eixosX[condicionalX + 1].rangeMinimo) || (Model.eixosX[condicionalX - 1] && Model.eixosX[condicionalX].rangeMinimo <= Model.eixosX[condicionalX - 1].rangeMaximo) || Model.eixosX[condicionalX].rangeMinimo >= Model.eixosX[condicionalX].rangeMaximo) {
        setValidoX(false)
        setTimeout(condicionalX)
        break
      }
      condicionalX++
    }
    while (Model.eixosY[condicionalY]) {
      if ((Model.eixosY[condicionalY - 1] && Model.eixosY[condicionalY].rangeMaximo >= Model.eixosY[condicionalY - 1].rangeMinimo) || (Model.eixosY[condicionalY + 1] && Model.eixosY[condicionalY].rangeMinimo <= Model.eixosY[condicionalY + 1].rangeMaximo) || Model.eixosY[condicionalY].rangeMinimo >= Model.eixosY[condicionalY].rangeMaximo) {
        setValidoY(false)
        setTimeout(condicionalY + 3)
        break
      }
      condicionalY++
    }
  }

  function AtualizarNineBox(): void {
    appApi.put(`NineBox/${id ?? ''}`, Model)
      .then(() => {
        toast({
          title: '9Box atualizado com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })
        nav(-1)
      })
      .catch(e => console.log(e))
  }

  function onChangeModel(model: iPostNineBox): void {
    setModel(model)
  }

  useEffect(() => {
    ObterDepartamentos()
    ObterSquads()
    ObterTags()
    ObterNineBox()
  }, [])

  useEffect(() => {
    console.log(ValidoX)
    console.log(ValidoY)
    if (ValidoX && ValidoY) {
      AtualizarNineBox()
    } else if (Timeout < 10) {
      toast({
        title: 'Notas inválidas, favor alterar os campos destacados em vermelho',
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
    }
  }, [Timeout])

  return (
    <Body>

      <WhiteBox>
        <h2>Configurando eixos</h2>
        <Box as={'h3'} fontSize={'1.25rem'} color={'var(--Gray2)'} mb={'.5rem'} fontWeight={'500'} mt={'2rem'}>Nome para distribuição de eixos</Box>
        <Input
          value={Model.titulo}
          onChange={(e) => setModel({ ...Model, titulo: e.target.value })}
          type="text"
          placeholder="Liderança"
          mb={'2rem'}
        />
        {!Model.padrao &&
          <Flex flexDir={'column'} mb={'2rem'}>
            <Box as={'h3'} color={'var(--Gray2)'} mb={'1rem'} fontWeight={'500'}>Parâmetros utilizados</Box>
            <Flex mb={'1rem'} gap={'1rem'}>
              {Areas.filter(r => Model.areas?.some(t => t === r.id)).map(e => {
                return (
                  <Tag key={e.id} onDelete={() => setModel({ ...Model, areas: Model.areas?.filter(tag => tag !== e.id) })} hasX>{e.nome}</Tag>
                )
              })}

              {Squads.filter(r => Model.squads?.some(t => t === r.id)).map(e => {
                return (
                  <Tag key={e.id} onDelete={() => setModel({ ...Model, squads: Model.squads?.filter(tag => tag !== e.id) })} hasX>{e.nome}</Tag>
                )
              })}

              {Tags.filter(r => Model.tags?.some(t => t === r.id)).map(e => {
                return (
                  <Tag key={e.id} onDelete={() => setModel({ ...Model, tags: Model.tags?.filter(tag => tag !== e.id) })} hasX>{e.nome}</Tag>
                )
              })}
            </Flex>
            <Tabs variant='unstyled'>
              <h3>Adicione o grupo que utilizará sua estrutura</h3>
              <Box as={'h4'} color={'var(--Gray2)'} mb={'.5rem'} fontWeight={'500'}>Parâmetro utilizado na nova definição</Box>
              <TabList gap={'1rem'} px={'0'}>
                <Tab
                  isDisabled={(Model.tags && Model.tags.length > 0) || (Model.squads && Model.squads.length > 0)}
                  color={'var(--Azul)'}
                  bg={'white'}
                  border={'0.188rem solid var(--Azul)'}
                  borderRadius={'0.75rem'}
                  _selected={{ color: 'white', bg: 'var(--Azul)' }}
                >Área</Tab>

                <Tab
                  isDisabled={(Model.areas && Model.areas.length > 0) || (Model.tags && Model.tags.length > 0)}
                  color={'var(--Azul)'}
                  bg={'white'}
                  border={'0.188rem solid var(--Azul)'}
                  borderRadius={'0.75rem'}
                  _selected={{ color: 'white', bg: 'var(--Azul)' }}
                >Squad</Tab>

                <Tab isDisabled={(Model.areas && Model.areas.length > 0) || (Model.squads && Model.squads.length > 0)}
                  color={'var(--Azul)'}
                  bg={'white'}
                  border={'0.188rem solid var(--Azul)'}
                  borderRadius={'0.75rem'}
                  _selected={{ color: 'white', bg: 'var(--Azul)' }}
                >Tag</Tab>
              </TabList>
              <TabPanels px={'0'}>
                <TabPanel px={'0'}>
                  <Box as={'h4'} color={'var(--Gray2)'} fontWeight={'500'} mb={'.5rem'}>Selecione as áreas que utilizaram essa definição</Box>
                  <Flex gap={'1rem'}>
                    <Select onChange={(e) => setValorSelecionado(e.target.value)}>
                      <option value={''}>Nenhum</option>
                      {Areas.filter(r => r.nome !== '' && !Model.areas?.some(t => t === r.id)).map((area) => {
                        return (
                          <option key={area.id} value={area.id}>{area.nome}</option>
                        )
                      })}
                    </Select>
                    <Button
                      onClick={adicionaArea}
                      VarColor='Azul'
                      fontSize={'0.75rem'}
                      width={'fit-content'}
                      leftIcon={<FaPlus />}
                      px={'2rem'}
                    >Adicionar área
                    </Button>
                  </Flex>
                </TabPanel>
                <TabPanel px={'0'}>
                  <Box as={'h4'} color={'var(--Gray2)'} fontWeight={'500'} mb={'.5rem'}>Selecione os Squads que utilizaram essa definição</Box>
                  <Flex gap={'1rem'}>
                    <Select onChange={(e) => setValorSelecionado(e.target.value)}>
                      <option value={''}>Nenhum</option>
                      {Squads.filter(r => r.nome !== '' && !Model.squads?.some(t => t === r.id)).map((squad) => {
                        return (
                          <option key={squad.id} value={squad.id}>{squad.nome}</option>
                        )
                      })}
                    </Select>
                    <Button
                      onClick={adicionaSquad}
                      VarColor='Azul'
                      fontSize={'0.75rem'}
                      width={'fit-content'}
                      leftIcon={<FaPlus />}
                      px={'2rem'}
                    >Adicionar squad
                    </Button>
                  </Flex>
                </TabPanel>
                <TabPanel px={'0'}>
                  <Box as={'h4'} color={'var(--Gray2)'} fontWeight={'500'} mb={'.5rem'}>Selecione as Tags que utilizaram essa definição</Box>
                  <Flex gap={'1rem'}>
                    <Select onChange={(e) => setValorSelecionado(e.target.value)}>
                      <option value={''}>Nenhum</option>
                      {Tags.filter(r => r.nome !== '' && !Model.tags?.some(t => t === r.id)).map((tag) => {
                        return (
                          <option key={tag.id} value={tag.id}>{tag.nome}</option>
                        )
                      })}
                    </Select>
                    <Button
                      onClick={adicionaTag}
                      VarColor='Azul'
                      fontSize={'0.75rem'}
                      width={'fit-content'}
                      leftIcon={<FaPlus />}
                      px={'2rem'}
                    >Adicionar tag
                    </Button>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        }

        {Model.padrao && <Flex flexDir={'column'}>
          <h4>Qual parâmetro será utilizado para a definição das metas?</h4>
          <RadioGroup
            mt={'.75rem'}
            mb={'2.2rem'}
            value={Model.tipoMeta.toString()}
            onChange={(e) => setModel({ ...Model, tipoMeta: parseInt(e) })}
          >
            <Stack spacing={3} direction='column'>
              <Radio value='0'>
                Percentual
              </Radio>
              <Radio value='1'>
                Escala de pontos
              </Radio>
            </Stack>
          </RadioGroup>
        </Flex>}
        <div>
          <h4 style={{ color: 'var(--c7)', marginBottom: '1rem' }}>Definição dos eixos e intervalos de valores</h4>
          <NineBox model={Model} onChangeModel={onChangeModel} />
        </div>
        <Flex justifyContent={'flex-end'} mb={'1rem'} gap={'1rem'}>
          <Button
            w={'13rem'}
            size={'md'}
            backgroundColor={'var(--Gray4)'}
            onClick={() => nav(-1)}
          >Cancelar alterações</Button>

          {id && <Button
            leftIcon={<FaSave />}
            w={'13rem'}
            size={'md'}
            backgroundColor={'var(--Green2)'}
            onClick={() => { setTimeout(Math.random() * 100); VerificaNineBox() }}
          >Atualizar definição</Button>}

          {PropInvalida(id) && <Button
            leftIcon={<FaSave />}
            w={'13rem'}
            size={'md'}
            backgroundColor={'var(--Green2)'}
            onClick={CriarNineBox}
          >Salvar definição</Button>}
        </Flex>
      </WhiteBox>
    </Body>
  )
}
