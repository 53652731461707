/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex, Input, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { iQuestao, iRespostaEscala } from '../../../interfaces'
import { appApi } from '../../../services/appApi'
import {
  Container,
  HeaderContainer,
  SkillAnswer,
  SkillQuestion,
  ComentarioContainer
} from './styles'
import { LayoutFull } from '../../Layouts/LayoutFull'
import { Button } from '../../../components/Button'

interface iResposta {
  questaoId: string
  respostaId: string
  traducoes?: iTraducao[]
  observacao?: string
}
interface iTraducao {
  idioma: string
  texto: string
}
interface iRespostaDiscursiva {
  questaoId: string
  observacao: string
}

interface iFormQuestao extends iQuestao {
  isError: boolean
  observacao?: string
}

interface iPostResposta {
  respostas: iResposta[]
  respostasDiscursivas?: iRespostaDiscursiva[]
}

interface LocationProps {
  pesquisaId: string
  tokenId: string
  idioma?: string
}

export const RespostaPesquisa: React.FC = () => {
  const nav = useNavigate()
  const { pesquisaId, tokenId, idioma } = useLocation().state as LocationProps
  const { pathname } = useLocation()
  const toast = useToast()

  const [Questoes, setQuestoes] = useState<iFormQuestao[]>([])
  const [Respostas, setRespostas] = useState<iResposta[]>([])
  const [NomePesquisa, setNomePesquisa] = useState('')
  const [NomeAvaliado, setNomeAvaliado] = useState('')
  const [ExibirCompetencia, setExibirCompetencia] = useState(false)
  const [isRespondido, setisRespondido] = useState(false)
  const [ExibirPeso, setExibitPeso] = useState(false)
  const [TxtEncerramento, setTxtEncerramento] = useState('')
  const [Obrigatorias, setObrigatorias] = useState<string[]>([])
  const [LogoEmpresa, setLogoEmpresa] = useState<string>('')
  const [Justificativa, setJustificativa] = useState(false)
  const [EscalaRespostas, setEscalaRespostas] = useState<iRespostaEscala[]>([])

  const [IsLoading, setIsLoading] = useState(false)

  // const [Referencias, setReferencias] = useState<>([])

  function obterTraduzido(ptBR: string, enUs: string, esES: string): string {
    return idioma === 'pt-BR'
      ? ptBR
      : idioma === 'en-US'
        ? enUs
        : esES
  }

  function getFormulario(): void {
    appApi.get(`TokenPesquisa/${pathname.includes('RespostaPesquisa') ? `Preenchimento/${tokenId}` : `Previsualizar/${pesquisaId ?? ''}`}`)
      .then(resp => {
        setQuestoes(resp.data.questoes)
        setEscalaRespostas(resp.data.respostas)
        setNomePesquisa(resp.data.nomePesquisa)
        setTxtEncerramento(resp.data.txtEncerramento)
        setObrigatorias(resp.data.obrigatorias)
        setLogoEmpresa(resp.data.logoEmpresa)
        setNomeAvaliado(resp.data.nomeAvaliado)
        setJustificativa(resp.data.justificativa)
        setExibirCompetencia(resp.data.exibirCompetencia)
        setExibitPeso(resp.data.exibirPeso)
      }).catch(() => setisRespondido(true))
  }

  function UpdateResposta(questaoId: string, respostaId: string): void {
    const respostas = [...Respostas]
    const pos = respostas.findIndex(e => e.questaoId === questaoId)
    if (pos !== -1) {
      respostas[pos].respostaId = respostaId
      setRespostas(respostas)
    }
  }

  function UpdateRespostaDiscursiva(questaoId: string, texto: string): void {
    const pos = Questoes.findIndex(e => e.id === questaoId)
    if (pos !== -1) {
      const quest = [...Questoes]
      quest[pos].observacao = texto
      setQuestoes([])
      setQuestoes(quest)
    }
  }

  function SelResposta(questaoId: string, resposId: string): void {
    if (!isSelectQuestao(questaoId)) {
      setRespostas(oldArray => [...oldArray, { questaoId: questaoId, respostaId: resposId }])
    } else {
      if (!isSelect(questaoId, resposId)) {
        UpdateResposta(questaoId, resposId)
      }
    }
  }

  const isSelect = (perguntaId: string, resposId: string): boolean => Respostas.find(e => e.questaoId === perguntaId && e.respostaId === resposId) !== undefined
  const isSelectQuestao = (perguntaId: string): boolean => Respostas.find(e => e.questaoId === perguntaId) !== undefined

  function AddErrorStats(questoesId: string[]): void {
    const quests = [...Questoes]
    for (let i = 0; i < questoesId.length; i++) {
      const pos = quests.findIndex(e => e.id === questoesId[i])
      if (pos !== -1) {
        quests[pos].isError = true
      }
    }
    setQuestoes([])
    setQuestoes(quests)
  }

  function postForm(): void {
    setIsLoading(true)
    const naoRespondidas = Questoes.filter(e => e.tipo !== 1).filter(e => Respostas.find(r => r.questaoId === e.id) === undefined)
    if (naoRespondidas.length > 0) {
      AddErrorStats(naoRespondidas.map((e) => {
        return e.id
      }))
      toast({
        title: obterTraduzido('Alguns campos não foram preenchidos, revise o formulário e veja se possui campos em vermelho',
          'Some fields were not filled in, review the form and see if it has fields in red',
          'Algunos campos no fueron llenados, revise el formulario y vea si tiene campos en rojo'),
        status: 'error',
        isClosable: false,
        position: 'top-right',
        duration: 4000
      })
    } else {
      const form: iPostResposta = {
        respostas: Respostas.map((e) => {
          const comentario = Questoes.find(q => q.id === e.questaoId)
          if (comentario) {
            return {
              questaoId: e.questaoId,
              respostaId: e.respostaId,
              observacao: comentario.observacao
            } as iResposta
          } else {
            return e
          }
        })
      }

      if (Questoes.some(e => e.tipo === 1)) {
        form.respostasDiscursivas = Questoes.filter(e => e.tipo === 1).map((e) => {
          return {
            questaoId: e.id,
            observacao: e.observacao
          } as iRespostaDiscursiva
        })
        const obrigatorios = form.respostasDiscursivas.filter(e => Obrigatorias.find(r => r === e.questaoId))
        if (obrigatorios.length > 0 && !obrigatorios.every(e => e.observacao !== undefined && e.observacao.length >= 3)) {
          toast({
            title: obterTraduzido('Algumas questões discursivas obrigatórias não foram preenchidas com no mínimo 3 caracteres ',
              'Some mandatory discursive questions were not filled in with at least 3 characters',
              'Algunas preguntas discursivas obligatorias no se completaron con al menos 3 caracteres'),
            status: 'error',
            isClosable: false,
            position: 'top-right',
            duration: 4000
          })
        }
      }

      appApi.post(`RespostaPesquisa/Responder/${tokenId}`, form)
        .then(() => {
          setisRespondido(true)
        })
        .catch(e => {
          setIsLoading(false)
          console.log(e)
        })
    }
  }

  useEffect(() => {
    getFormulario()
  }, [])

  return (
    <LayoutFull>
      <HeaderContainer>
        <Flex flexDir={'column'} gap='1rem'>
          <h1>{NomePesquisa}</h1>
          {!pathname.includes('Previsualizar') && <h3>Avaliado: {NomeAvaliado}</h3>}
        </Flex>
        <img alt='Logo da empresa' src={LogoEmpresa ?? 'https://sigah.blob.core.windows.net/onboard/f99445d7-d98d-48ad-9cdb-a443a9f636a2.png'} />
      </HeaderContainer>
      {
        (!isRespondido) && (
          <Container>
            {
              Questoes?.filter(e => e.tipo !== 1).map((e: iFormQuestao) => {
                return (
                  <Flex flexDir={'column'} key={e.id}>
                    <SkillQuestion>
                      <div>
                        {ExibirCompetencia
                          ? <Flex flexDirection={'column'} py={'.5rem'} fontSize={'18px'} w={'100%'}>
                              <Text color={'var(--Gray3)'} fontSize={'12px'}>{e.competencia}</Text>
                              <Text fontSize={'18px'}>{idioma ? e.traducoes?.find(e => e.idioma === idioma)?.texto ?? e.enunciado : e.enunciado}</Text>
                            </Flex>
                          : <Text fontSize={'18px'} my={'.5rem'}>{idioma ? e.traducoes?.find(e => e.idioma === idioma)?.texto ?? e.enunciado : e.enunciado}</Text>}
                        {e.isError && <span>{obterTraduzido('* resposta requerida', 'required answer', 'respuesta requerida')}</span>}
                      </div>
                      {
                        EscalaRespostas.map((r: iRespostaEscala, i2) => {
                          return (
                            <SkillAnswer
                              key={i2}
                              type="button"
                              isSelected={isSelect(e.id, r.id)}
                              onClick={() => SelResposta(e.id, r.id)}
                            >
                              <div>
                                <FaCheckCircle />
                              </div>
                              <span>{idioma ? r.traducoes?.find(re => re.idioma === idioma)?.texto ?? r.resposta : r.resposta}</span>
                              {ExibirPeso && r.peso !== -1 && (
                                <span style={{ marginLeft: '.4rem', fontWeight: 700 }}>({r.peso})</span>
                              )}
                            </SkillAnswer>
                          )
                        })
                      }
                      {Justificativa && <Input
                        marginTop={'1rem'}
                        borderColor='var(--Gray4)'
                        placeholder='Deixe sua justificativa para a nota atribuída a esta questão (opcional)'
                        onBlur={(p) => UpdateRespostaDiscursiva(e.id, p.target.value)}
                      />}
                    </SkillQuestion>
                  </Flex>
                )
              })
            }

            {
              Questoes?.filter(e => e.tipo === 1).map((e: iQuestao) => {
                return (
                  <ComentarioContainer key={e.id}>
                    <h3>{idioma ? e.traducoes?.find(e => e.idioma === idioma)?.texto ?? e.enunciado : e.enunciado}</h3>
                    <textarea
                      placeholder={obterTraduzido('Digite um comentário sobre o avaliado', 'Leave a comment about the reviewer', 'Ingrese un comentario sobre el revisor')}
                      onChange={(r) => UpdateRespostaDiscursiva(e.id, r.target.value)}
                      minLength={Obrigatorias.find(r => r === e.id) !== undefined ? 3 : 0}
                    />
                  </ComentarioContainer>
                )
              })
            }
            {!pathname.includes('Previsualizar') &&
              <Flex justifyContent={'center'} margin={'1rem 0rem'}>
                <Button isLoading={IsLoading} onClick={postForm} size='lg' VarColor='v2'>{
                  obterTraduzido('Enviar resposta', 'Send answer', 'Enviar respuesta')
                }</Button>
              </Flex>}
              { pathname.includes('Previsualizar') &&
                <Flex marginTop={'1rem'} justifyContent={'flex-end'}>
                <Button
                  VarColor='Rosa'
                  size={'lg'}
                  onClick={() => nav(-1)}
                >Finalizar pré-visualização</Button>
              </Flex>
              }
          </Container>
        )
      }

      {
        (isRespondido) && (
          <Flex background={'white'} height={'100%'}>
            <Flex padding={'3rem 3rem'} flexDirection={'column'}>
              <h3 dangerouslySetInnerHTML={{
                __html: TxtEncerramento.split('<p>').join('<p style="line-height: 200%">').split('<p style="line-height: 200%"></p>').join('<br>').split('<h1>').join('<h1 style="line-height: 200%">').split('<h1>').join('<h1 style="line-height: 200%">').split('<h2>').join('<h2 style="line-height: 200%">').split('<h3>').join('<h3 style="line-height: 230%">').split('<h4>').join('<h4 style="line-height: 250%">').split('<h5>').join('<h5 style="line-height: 300%">').split('<h6>').join('<h6 style="line-height: 300%">').split('<ul>').join('<ul style="padding-left: 32px">') ?? obterTraduzido('Obrigado pela participação!',
                  'Thank you for your participation!',
                  '¡Gracias por su participación!')
              }}></h3>
              <Text
                fontWeight={'400'}
                style={{
                  color: 'var(--c6)',
                  marginTop: '1rem'
                }}>
                {obterTraduzido('Respostas enviadas com sucesso!',
                  'Answers sent successfully!',
                  '¡Respuestas enviadas con éxito!')}</Text>
              {
                (localStorage.getItem('token')) && (
                  <Flex marginTop={'1rem'}>
                    <Button
                      VarColor='c4'
                      size={'lg'}
                      onClick={() => nav('/')}
                    >Voltar</Button>
                  </Flex>
                )
              }
            </Flex>
          </Flex>
        )
      }
    </LayoutFull>
  )
}
