import { Checkbox, Flex, FormControl, FormLabel } from '@chakra-ui/react'
import { Container } from './styles'

interface iFormQuestaoResponse {
  id: string
  tipo: number
  enunciado: string
  alternativas?: iFormAlternativaResponse[]
  minCaixaSelecao?: number
  maxCaixaSelecao?: number
}

interface iFormAlternativaResponse {
  id: string
  texto: string
}

interface iCaixaSelecaoProps {
  questao: iFormQuestaoResponse
  Selecionar: (questaoId: string, alternativaId: string) => void
  erro: boolean
  selecionadas?: number
}

export const CaixaDeSelecao: React.FC<iCaixaSelecaoProps> = ({ questao, Selecionar, erro, selecionadas }) => {
  function ValidarErro(questoesSelecionadas: number): boolean {
    if (questoesSelecionadas === 0) {
      return false
    } else if (questao.minCaixaSelecao && questoesSelecionadas < questao.minCaixaSelecao) {
      return true
    } else if (questao.maxCaixaSelecao && questoesSelecionadas > questao.maxCaixaSelecao) {
      return true
    }
    return false
  }
  return (
    <Container>
      <div>
        <h3>{questao.enunciado}</h3>
        {(erro || ValidarErro(selecionadas ?? 0)) && <span>* Selecione no máximo {questao.maxCaixaSelecao} e no mínimo {questao.minCaixaSelecao}</span>}
      </div>
      <Flex gap={'1rem'} paddingLeft={'1rem'} flexDir={'column'}>
        {
          questao.alternativas?.map((e, i) => {
            return (
              <FormControl key={i} as={Flex}>
                <Checkbox
                  onChange={() => Selecionar(questao.id, e.id)}
                  borderColor={'var(--Gray4)'}
                  marginRight={'.5rem'}
                />
                <FormLabel marginBottom={'0'} fontSize={'xl'} color={'var(--Gray2)'}>{e.texto}</FormLabel>
              </FormControl>
            )
          })
        }
      </Flex>
    </Container>
  )
}
