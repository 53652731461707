import styled from 'styled-components'

export const TextContainer = styled.div`
span,strong{
    color: var(--a4);
}

span{
    font-weight: 500;
}

`
