import { useEffect, useState } from 'react'
import { FaCheck, FaCog, FaPeopleArrows, FaRedo, FaTimes, FaUserCheck, FaUserFriends, FaUsers, FaUserTie } from 'react-icons/fa'
import { Container, IconText } from './styles'
import { appApi } from '../../../../../../../services/appApi'
import { Box, Flex, Tooltip } from '@chakra-ui/react'
import { Button } from '../../../../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { iEtapaColaborador } from '../../../../../../../interfaces'
import { parseJwt } from '../../../../../../../services/token'

interface ITipoCardConfigPesquisaStackHolder {
  etapa: iEtapaColaborador
  onReabrirEtapa: (id: string) => void
}

interface iCardResponse {
  participantes: number[]
  formularioSelecionado: boolean
  formularioAprovado: boolean
  avaliacaoEncerrada: boolean
  avaliacaoRespondida: boolean
  respostas: iResposta[]
  avaliacaoId: string
  dataInicio: string
  dataFim: string
  configuracaoLiberada: boolean
  editarTrilhaLiderado?: boolean
}
interface iResposta {
  tipo: number
  respondido: boolean
}

interface iResp {
  eixoRespondido: boolean
  respondidos: number
  total: number
}

export const CardPesquisaStackholder: React.FC<ITipoCardConfigPesquisaStackHolder> = ({ etapa, onReabrirEtapa }) => {
  const nav = useNavigate()
  const [Model, setModel] = useState<iCardResponse>()

  function ObterParticipantes(): void {
    appApi.get(`PesquisaPercepcao/${etapa.interacoes[0].idExterno}/Detalhe`)
      .then(response => { setModel(response.data) })
      .catch(err => console.error(err))
  }

  function ObterRespostas(tipo: number): iResp {
    const obj: iResp = {
      eixoRespondido: true,
      respondidos: 0,
      total: 0
    }
    Model?.respostas?.forEach(e => {
      if (e.tipo === tipo) {
        obj.total++
        if (e.respondido) {
          obj.respondidos++
        } else {
          obj.eixoRespondido = false
        }
      }
    })
    return obj
  }

  useEffect(() => {
    ObterParticipantes()
  }, [])

  return (
    <Container>
      {etapa.status !== 2 && (
        <>
          <h3>Data da pesquisa:</h3>
          <Flex mb='1rem' alignItems={'center'} justifyContent={(parseJwt().adm === 'True' || Model?.editarTrilhaLiderado) ? 'center' : 'flex-start'} ml={(parseJwt().adm === 'True' || Model?.editarTrilhaLiderado) ? '0' : '.8rem'} gap={'1rem'}>
            <span>{Model?.dataInicio ?? ''} - {Model?.dataFim ?? ''}</span>
            {(parseJwt().adm === 'True' || Model?.editarTrilhaLiderado) && <Button
              VarColor='Rosa'
              h={'28px'}
              fontSize={'12px'}
              onClick={() => { onReabrirEtapa(etapa.interacoes[0].idExterno); ObterParticipantes() }}
              gap={'.5rem'}
            >Reagendar pesquisa<FaRedo /></Button>}
          </Flex>
        </>
      )}

      <h3>Quem irá participar:</h3>
      {
        Model?.participantes.map((e: number, i) => {
          if (e === 0) {
            return (
              <IconText key={i}>
                <FaUserCheck size={25} />
                <span>Autoavaliação</span>
                {ObterRespostas(e).total > 1 ? <Flex fontWeight={'700'} ml={'.5rem'} letterSpacing={'-1px'} fontSize={'14px'}>{ObterRespostas(e).respondidos}/{ObterRespostas(e).total}</Flex> : ''}
                {ObterRespostas(e).eixoRespondido ? <FaCheck style={{ color: 'var(--Green3)' }} /> : <FaTimes style={{ color: 'var(--Red)' }} />}
              </IconText>
            )
          } else if (e === 1) {
            return (
              <IconText key={i}>
                <FaUserTie size={25} />
                <span>Gestor</span>
                {ObterRespostas(e).total > 1 ? <Flex fontWeight={'700'} ml={'.5rem'} letterSpacing={'-1px'} fontSize={'14px'}>{ObterRespostas(e).respondidos}/{ObterRespostas(e).total}</Flex> : ''}
                {ObterRespostas(e).eixoRespondido ? <FaCheck style={{ color: 'var(--Green3)' }} /> : <FaTimes style={{ color: 'var(--Red)' }} />}
              </IconText>
            )
          } else if (e === 2) {
            return (
              <IconText key={i}>
                <FaPeopleArrows size={25} />
                <span>Par</span>
                {ObterRespostas(e).total > 1 ? <Flex fontWeight={'700'} ml={'.5rem'} letterSpacing={'-1px'} fontSize={'14px'}>{ObterRespostas(e).respondidos}/{ObterRespostas(e).total}</Flex> : ''}
                {ObterRespostas(e).eixoRespondido ? <FaCheck style={{ color: 'var(--Green3)' }} /> : <FaTimes style={{ color: 'var(--Red)' }} />}
              </IconText>
            )
          } else if (e === 3) {
            return (
              <IconText key={i}>
                <FaUsers size={25} />
                <span>Subordinado</span>
                {ObterRespostas(e).total > 1 ? <Flex fontWeight={'700'} ml={'.5rem'} letterSpacing={'-1px'} fontSize={'14px'}>{ObterRespostas(e).respondidos}/{ObterRespostas(e).total}</Flex> : ''}
                {ObterRespostas(e).eixoRespondido ? <FaCheck style={{ color: 'var(--Green3)' }} /> : <FaTimes style={{ color: 'var(--Red)' }} />}
              </IconText>
            )
          } else if (e === 4) {
            return (
              <IconText key={i}>
                <FaUserFriends size={25} />
                <span>Outro</span>
                {ObterRespostas(e).total > 1 ? <Flex fontWeight={'700'} ml={'.5rem'} letterSpacing={'-1px'} fontSize={'14px'}>{ObterRespostas(e).respondidos}/{ObterRespostas(e).total}</Flex> : ''}
                {ObterRespostas(e).eixoRespondido ? <FaCheck style={{ color: 'var(--Green3)' }} /> : <FaTimes style={{ color: 'var(--Red)' }} />}
              </IconText>
            )
          } else if (e === 6) {
            return (
              <IconText key={i}>
                <FaUserFriends size={25} />
                <span>Alta liderança</span>
                {ObterRespostas(e).total > 1 ? <Flex fontWeight={'700'} ml={'.5rem'} letterSpacing={'-1px'} fontSize={'14px'}>{ObterRespostas(e).respondidos}/{ObterRespostas(e).total}</Flex> : ''}
                {ObterRespostas(e).eixoRespondido ? <FaCheck style={{ color: 'var(--Green3)' }} /> : <FaTimes style={{ color: 'var(--Red)' }} />}
              </IconText>
            )
          }
          return null
        })
      }
      <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={'1rem'}>
        {(parseJwt().adm === 'True' || Model?.editarTrilhaLiderado) &&
          (<Tooltip placement='top' label={(!Model?.configuracaoLiberada) ? 'É necessário reagendar a pesquisa' : ''} hasArrow bg={'var(--Red)'} color={'white'} p={'.5rem'} mr={'.4rem'}>
            <Box>
              <Button
                leftIcon={<FaCog />}
                VarColor='Azul'
                onClick={() => nav(`/Rh/PesquisaPercepcao/Participantes/${etapa.interacoes[0].idExterno}/Processo`)}
                isDisabled={(!Model?.configuracaoLiberada)}
              >Editar participantes</Button>
            </Box>
          </Tooltip>)
        }
        <Flex position={'relative'}>
          {
            (!Model?.avaliacaoRespondida) && (
              <Tooltip placement='top' label={'Não existem respostas disponíveis'} hasArrow bg={'var(--Red)'} color={'white'} p={'.5rem'} mr={'.4rem'}>
                <Box>
                  <Button
                    VarColor='Azul'
                    disabled
                  >Resultado</Button>
                </Box>
              </Tooltip>
            )
          }
          {
            (Model?.avaliacaoRespondida) && (
              <Button
                VarColor='Azul'
                onClick={() => nav(`/Rh/AnaliseDesempenho/ResultadoIndividual/${Model.avaliacaoId}`)}
              >Resultado</Button>
            )
          }
        </Flex>
      </Flex>
    </Container>
  )
}
