import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { ModalDelete } from '../../../../components/ModalDelete'
import { WhiteBox } from '../../../../components/WhiteBox'
import { appApi } from '../../../../services/appApi'
import { Body } from '../../../Layouts/Body'
import { Main } from './styles'

interface iResumoPesquisa{
  nomePesquisa: string
  descricao: string
  totalQuestoes: number
  totalQuestoesMultiplaEscolha: number
  totalQuestoesCaixaSelecao: number
  totalQuestoesDiscursiva: number
  aprovado: boolean
}

type iLocationProps={
  pesquisaId: string
  trilhaId: string
  processoId: string
}

export const AprovacaoPesquisaInterna: React.FC = () => {
  const param = useParams<iLocationProps>()
  const nav = useNavigate()
  const { pathname } = useLocation()
  const [Model, setModel] = useState<iResumoPesquisa>()
  const [ModalIsOpen, setModalIsOpen] = useState(false)

  function getResumo(): void {
    appApi.get(`PesquisaInterna/${param.pesquisaId as string}/Resumo`)
      .then(res => setModel(res.data))
      .catch(err => console.log(err))
  }

  function AprovarFormulario(): void {
    appApi.patch(`PesquisaInterna/${param.pesquisaId as string}/AprovarFormulario`)
      .then(() => nav(pathname.replace('Aprovacao', 'Configuracao')))
      .catch(err => console.log(err))
  }

  useEffect(() => {
    getResumo()
  }, [])
  return (
        <Body>
            <ModalDelete
                isOpen={ModalIsOpen}
                message={'Após aprovar este formulário, você não poderá mais editar as questões. Deseja continuar?'}
                onRequestClose={() => setModalIsOpen(false)}
                onConfirm={AprovarFormulario}
            />
            <WhiteBox>
                <Flex justifyContent={'space-between'} alignItems={'end'}>
                    <Flex flexDir={'column'}>
                        <h1>Aprove o formulário</h1>
                        <Text
                            fontWeight={'bold'}
                            style={{ marginTop: '.5rem' }}
                        >Após aprovar o formulário, você não poderá mais editá-lo</Text>
                    </Flex>

                    {/* <Flex>
                        <Button leftIcon={<FaFilePdf />} VarColor='Azul'>Exportar questões</Button>
                        <Button marginLeft={'1rem'} VarColor='Azul'>Pré-visualizar formulário</Button>
                    </Flex> */}
                </Flex>

                <Main>
                    <div>
                        <h3>Resumo da pesquisa</h3>
                        <span>Nome: <strong>{Model?.nomePesquisa}</strong></span>
                        <span>Descrição: <strong style={{ fontSize: '1rem' }}>{Model?.descricao}</strong></span>
                    </div>

                    <div>
                        <span>Questões: <strong>{Model?.totalQuestoes}</strong></span>
                        <span>Questões de múltipla escolha: <strong>{Model?.totalQuestoesMultiplaEscolha}</strong></span>
                        <span>Questões de caixa de seleção: <strong>{Model?.totalQuestoesCaixaSelecao}</strong></span>
                        <span>Questões discursivas: <strong>{Model?.totalQuestoesDiscursiva}</strong></span>
                    </div>
                </Main>

                <Flex justifyContent={'end'}>

                    <Button onClick={() => nav(-1)} VarColor='c5' size={'lg'}>Voltar</Button>
                    { (
                        <Button
                            onClick={() => setModalIsOpen(true)}
                            marginLeft={'1rem'}
                            rightIcon={<FaAngleRight/>}
                            VarColor='Green2' size={'lg'}
                        >{!Model?.aprovado ? 'Aprovar formulário' : 'Continuar'}</Button>
                    )}
                </Flex>
            </WhiteBox>
        </Body>
  )
}
