import { Flex, Text } from '@chakra-ui/layout'
import React from 'react'
import { FaDoorOpen, FaTrashAlt } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'

import { Container } from './styles'

interface IDepartment {
  id: string
  nome: string
  departamentoPai?: IDepartment
  qtdColaboradores?: number
}

interface IDepartmentCardProps {
  department: IDepartment
  onEdit: (department: IDepartment) => void
  onDelete: (department: IDepartment) => void
}

export const DepartmentCard: React.FC<IDepartmentCardProps> = ({
  department,
  onEdit,
  onDelete
}) => {
  return (
    <Container>
      <header>
        <FaDoorOpen />
        <div>
          <strong>{department.nome}</strong>
          <span>{department.departamentoPai?.nome}</span>
        </div>
      </header>
      <Flex mt={department.departamentoPai?.nome ? '0' : '.5rem'} alignItems={'flex-end'} justifyContent={'space-between'}>
        {department.qtdColaboradores !== undefined && department.qtdColaboradores === 0 &&
          <Text justifySelf={'flex-start'} fontSize={'14px'} color={'var(--Gray3)'}><strong>{department.qtdColaboradores}</strong> Colaboradores</Text>
        }
        {department.qtdColaboradores !== undefined && department.qtdColaboradores > 0 &&
          <Text fontSize={'14px'} color={'var(--Gray3)'}><strong>{department.qtdColaboradores}</strong> Colaborador{department.qtdColaboradores > 1 ? 'es' : ''}</Text>
        }
        <Flex alignItems={'center'}>
          <button style={{ marginBottom: '.1rem' }} type="button" onClick={() => onEdit(department)}>
            <FiEdit />
          </button>

          <button type="button" onClick={() => onDelete(department)}>
            <FaTrashAlt size={20} />
          </button>
        </Flex>
      </Flex>
    </Container>
  )
}
