import { useEffect, useState } from 'react'
import { Container } from './styles'
import { iEtapaColaborador, iItemIntegracao } from '../../../../../../../interfaces'
import { appApi } from '../../../../../../../services/appApi'
import { PropInvalida } from '../../../../../../../Utils/Helper'
import { Flex, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { FaCheck } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
interface ITipoCardConfigIntegracao {
  etapa: iEtapaColaborador
  isOpen: boolean
}

export const CardIntegracao: React.FC<ITipoCardConfigIntegracao> = ({ etapa, isOpen }) => {
  const { colaboradorId } = useParams<{colaboradorId: string}>()
  const [Itens, setItem] = useState<iItemIntegracao[]>([])

  function ObterInformacoesCheck(): void {
    appApi.get(`IntegracaoEtapaColaborador/${etapa.id}/ProcessoOnboard/${colaboradorId ?? ''}`)
      .then(response => { setItem(response.data.itensIntegracao) }).catch(err => console.error(err))
  }
  useEffect(() => {
    if (isOpen && Itens.length === 0) {
      ObterInformacoesCheck()
    }
  }, [isOpen])

  return (
    <Container>
      <h3>Informações para serem verificadas</h3>
      {
        Itens.map((e: iItemIntegracao, i): any => {
          const texto = e?.titulo ? `${e?.titulo}` : 'Sem assunto'
          return (
            <Flex key={i} flexDir={'column'}>
              <span >{i + 1}.  {texto}</span>
              {(!PropInvalida(e.checklist) && etapa.status !== 2)
                ? (
                  <Flex ml={4} mb={'2'} alignItems='center' >
                    <Text>Ainda não foi respondido</Text>
                  </Flex>
                  )
                : (
                  <Flex direction={'column'} as={'ul'} mb={'2'}>
                    {e.checklist?.map((r) => {
                      return (
                        <Flex ml={4} gap={'2'} alignItems='center' >
                          {r.check ? <FaCheck color='var(--Green2)' size='1rem' /> : <MdClose color='var(--terc3)' size='1rem' />}
                          <Text>{r.texto}</Text>
                        </Flex>
                      )
                    })}
                  </Flex>
                  )
              }
            </Flex>
          )
        })
      }

    </Container>
  )
}
