import { useState } from 'react'
import Modal from 'react-modal'
import { MetaButton } from '../../../../../../components/Buttons/MetaButton'
import { InputFormControl } from '../../../../../../components/InputFormControl'
import { ModalBody, ModalContent, ModalFooter } from './styles'

Modal.setAppElement('#root')

interface IJobTitle {
  id: string
  nome: string
}

interface ICreateJobTitleModalProps {
  isOpen: boolean
  onRequestClose: () => void
  onCreateJobTitle: (newJobTitle: IJobTitle) => void
}

export const CreateJobTitleModal: React.FC<ICreateJobTitleModalProps> = ({
  isOpen,
  onRequestClose,
  onCreateJobTitle
}) => {
  const [Nome, setNome] = useState('')
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-default"
    >
      <ModalContent>
        <h1>Nova função</h1>
        <ModalBody>
          <InputFormControl
            label='Nome da função'
            onChange={(e) => setNome(e.target.value)}
            placeholder='Digite um nome'
          />
        </ModalBody>
        <ModalFooter>
          <MetaButton bg='c4' size='md' onClick={onRequestClose}>Fechar</MetaButton>
          <MetaButton bg='v2' size='md' onClick={() => onCreateJobTitle({ id: '', nome: Nome })} >Salvar</MetaButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
