/* eslint-disable no-tabs */
import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { useState } from 'react'
import Modal from 'react-modal'
import { GreenButton } from '../../../../../../components/Buttons/GreenButton'
import { GreyButton } from '../../../../../../components/Buttons/GreyButton'
import { WhiteModal } from '../../../../../../components/ModalCreateForm/styles'

interface props {
  isOpen: boolean
  onRequestClose: () => void
  onConfirm: (nome: string) => void
}

export const ModalCreate: React.FC<props> = ({ isOpen, onRequestClose, onConfirm }) => {
  Modal.setAppElement('#root')
  const [Nome, setNome] = useState('')
  return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			overlayClassName='react-modal-overlay'
			className='react-modal-content-default'
		>
			<WhiteModal>
				<FormControl>
					<FormLabel color={'var(--a4)'} fontSize={'lg'}>Defina um nome para o seu formulário</FormLabel>
					<Input
						borderColor={'var(--Gray4)'}
						onChange={(e) => setNome(e.target.value)}
						placeholder='Digite o nome'
					/>
				</FormControl>

				<Flex justifyContent={'end'} marginTop={'1rem'} gap={'1rem'}>
					<GreyButton isNav={false} Size={'md'} onClick={onRequestClose}>Cancelar</GreyButton>
					<GreenButton isNav={false} Size={'md'} OnClick={() => onConfirm(Nome)}>Salvar</GreenButton>
				</Flex>
			</WhiteModal>
		</Modal>
  )
}
